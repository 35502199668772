import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    DialogActions,
    Button,
    withStyles,
    Typography,
} from '@material-ui/core';
import _ from 'lodash';

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UPDATE_SELECTED_LOCATION, USER_QUERY } from './gql';
import { WHITE } from '../../style/constants';
import { userSelected } from '../../data/redux/actions/user';
import { practiceSelected } from '../../data/redux/actions/practice';

const ChangeLocationDialog = ({ classes, locationId, locationName, locationOptions, practiceEncodedId, practiceName, userEncodedId }) => {
    const [currentLocation, setCurrentLocation] = useState(locationId || '');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();

    useQuery(USER_QUERY, {
        // fetchPolicy: 'cache-and-network',
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.viewer) {
                const { viewer } = res;
                dispatch(
                    userSelected({
                        id: viewer.id,
                        currentLocationId: _.get(viewer, 'locations.0.id'),
                        currentLocationName: _.get(viewer, 'locations.0.name'),
                        locationOptions: _.get(viewer, 'locations', []),
                        location: _.get(viewer, 'location', {}),
                        name: viewer.name,
                        roles: viewer.roles,
                        isSubscriptionValid: viewer.isSubscriptionValid,
                        isConsentFormSubscription: viewer.isConsentFormSubscription,
                    })
                );
                dispatch(
                    practiceSelected({
                        id: viewer.practice.id,
                        name: viewer.practice.name,
                    })
                );
            }
        },
    });

    const [updateSelectedLocation] = useMutation(UPDATE_SELECTED_LOCATION, {
        onCompleted: (res) => {
            if (res.updateSelectedLocation) {
                setIsDialogOpen(false);
                window.location.reload(true);
            }
        },
        refetchQueries: ['userQuery'],
        variables: {
            locationId: currentLocation,
            practiceEncodedId,
            userEncodedId,
        },
    });

    const handleClick = (event) => {
        const newSlot = event.currentTarget.value;
        setCurrentLocation(newSlot);
    };

    const handleToggleDialog = (e) => {
        e.preventDefault();
        setIsDialogOpen(!isDialogOpen);
    };
    const handleSave = () => {
        updateSelectedLocation();
    };

    return (
        <>
            <Button data-test-id='changeLocationButton' className={classes.white} onClick={handleToggleDialog}>
                <FontAwesomeIcon className={classes.icon} icon={faMapMarkerAlt} />
                <Typography variant='body2'>{`${locationName} (${practiceName})`}</Typography>
            </Button>
            <Dialog open={isDialogOpen} onClose={handleToggleDialog}>
                <DialogTitle>Change Location</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>Select a location:</FormLabel>

                        <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            value={currentLocation}
                            name='radio-buttons-group'
                            onChange={handleClick}>
                            {!!locationOptions &&
                                !!locationOptions.length &&
                                locationOptions.map((option) => (
                                    <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.name} />
                                ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleDialog}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const styles = {
    white: {
        padding: '8px 12px',
        borderRadius: '80px',
        color: WHITE,
        textTransform: 'none',
        backgroundColor: '#141e26',
        '&:hover': {
            backgroundColor: '#141e26',
        },
    },
    icon: {
        marginRight: 10,
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        locationOptions: user.locationOptions,
        locationId: user.currentLocationId,
        locationName: user.currentLocationName,
        practiceEncodedId: practice.id,
        practiceName: practice.name,
        userEncodedId: user.id,
    }))
)(ChangeLocationDialog);

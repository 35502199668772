import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';

import { PRIMARY, SECONDARY, WHITE } from '../../style/constants';
import { userAuthenticated } from '../../data/redux/actions/user';
import ErrorMessage from '../../components/ErrorMessage';
import Logo from '../../images/clinik-note-logo-pink.svg';
import ReactGA from 'react-ga4';

const GENERATE_USER_SESSION = gql`
    mutation generateUserSession($email: String!, $password: String!) {
        generateUserSession(email: $email, password: $password)
    }
`;

const LoginPage = ({ classes, dispatch }) => {
    const [, setCookie] = useCookies(['access_token']);
    const [form, updateForm] = useState({
        email: '',
        password: '',
    });
    // eslint-disable-next-line no-unused-vars
    const [generateUserSession, { data, loading, error }] = useMutation(GENERATE_USER_SESSION, {
        onCompleted: async (result) => {
            if (result.generateUserSession) {
                ReactGA.event({
                    category: 'Login',
                    action: 'User Logged In',
                    label: form.email,
                });
                setCookie('access_token', result.generateUserSession, { path: '/' });
                dispatch(userAuthenticated(form.email, result.generateUserSession));
            }
        },
    });

    return (
        <Grid component='main' container justifyContent='center' alignItems='center' className={classes.main}>
            <Grid component='section' container item className={classes.section}>
                <img src={Logo} className={classes.logo} alt='Clinik Note Logo' />
                <Grid
                    component='form'
                    container
                    item
                    direction='column'
                    className={classes.form}
                    onSubmit={(e) => {
                        e.preventDefault();
                        generateUserSession({
                            variables: { email: form.email, password: form.password },
                        });
                    }}>
                    <Input
                        id='email'
                        autoFocus
                        required
                        disableUnderline
                        placeholder='User Email'
                        value={form.email || ''}
                        className={classes.field}
                        onChange={(e) => updateForm({ ...form, email: e.target.value })}
                    />
                    <Input
                        id='password'
                        type='password'
                        required
                        placeholder='Password'
                        disableUnderline
                        value={form.password || ''}
                        className={classes.field}
                        onChange={(e) => updateForm({ ...form, password: e.target.value })}
                    />
                    <Button type='submit' variant='contained' disableFocusRipple disableRipple classes={{ root: classes.button }}>
                        {loading ? <CircularProgress classes={{ root: classes.loading }} size={28} /> : 'Login'}
                    </Button>
                    {error && <ErrorMessage className={classes.error} error={error} />}
                </Grid>

                <Grid container className={`${classes.postForm} line2 common-line`}>
                    <Typography variant='body2' gutterBottom className={classes.resetpassword}>
                        <a className={classes.actionText} href='/resetPassword'>
                            Forgot Password?
                        </a>
                    </Typography>
                    <Typography variant='body2' gutterBottom className={classes.signup}>
                        <a className={classes.actionText} href='/signup' target='_blank'>
                            Sign Up
                        </a>
                    </Typography>
                </Grid>
                <Grid container className={`${classes.postForm} line2 common-line`}>
                    <Typography variant='body2' gutterBottom className={classes.privacy}>
                        <a className={classes.privacyText} href='http://cliniknote.com/privacy-policy' rel='noopener noreferrer' target='_blank'>
                            Privacy Policy
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const styles = {
    main: {
        height: '100%',
        backgroundColor: PRIMARY,
    },
    section: {
        maxWidth: 448,
    },
    logo: {
        height: '100%',
        width: '100%',
    },
    loading: {
        color: WHITE,
    },
    form: {
        padding: 32,
        maxWidth: 448,
    },
    postForm: {
        paddingLeft: 32,
        paddingRight: 32,
        maxWidth: 448,
    },
    field: {
        backgroundColor: WHITE,
        flex: 1,
        height: 48,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 5,
        marginBottom: 16,
    },
    button: {
        flex: 1,
        height: 48,
        fontSize: 18,
        fontWeight: 300,
        letterSpacing: 0.5,
        textTransform: 'capitalize',
        backgroundColor: SECONDARY,
        color: WHITE,
        '&:hover': {
            backgroundColor: SECONDARY,
        },
    },
    error: {
        color: WHITE,
        paddingTop: 32,
        textAlign: 'center',
    },
    actionText: {
        color: WHITE,
    },
    resetpassword: {
        flex: 1,
        textAlign: 'center',
        fontSize: 18,
    },
    signup: {
        flex: 1,
        textAlign: 'center',
        fontSize: 18,
    },
    privacy: {
        textAlign: 'center',
        flex: 1,
        fontSize: 12,
        paddingBottom: 24,
    },
    privacyText: {
        color: WHITE,
    },
    version: {
        textAlign: 'center',
        flex: 1,
        fontSize: 12,
        paddingBottom: 24,
        color: WHITE,
    },
    disclaimer: {
        paddingLeft: 32,
        paddingRight: 32,
        fontSize: 14,
        color: 'gray',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice }) => ({
        practiceId: practice.id,
        refetchPatientList: practice.refetchPatientList,
    })),
    lifecycle({
        componentDidMount() {},
    })
)(LoginPage);

import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, SECONDARY } from '../../style/constants';
import NotesItemContainer from '../NotesListItem/notesItemContainer';

const NotesGridItem = ({ classes, icon, isAuthor, isAuth, note, showCheckBox, checkedNotesMatch, onCheckNote, onLinkPress }) => (
    <Paper className={classes.paper}>
        <ListItem
            className={classes.listItem}
            button
            onClick={onLinkPress}
            disabled={showCheckBox && (!isAuthor || !isAuth)}
            TouchRippleProps={{ classes: { root: classes.ripple } }}>
            <Grid container wrap='nowrap' alignItems='center' className={classes.header}>
                {showCheckBox && isAuthor && isAuth && (
                    <Checkbox
                        className={classes.checkbox}
                        classes={{
                            root: classes.checkboxRoot,
                        }}
                        inputProps={{
                            root: classes.checkboxInputRoot,
                        }}
                        checked={checkedNotesMatch}
                        onChange={() => onCheckNote()}
                    />
                )}
                <Grid container direction='column'>
                    <Typography variant='body2' className={classes.title} noWrap>
                        {note.title}
                    </Typography>
                    <Typography variant='caption' className={classes.author} noWrap>
                        {note.author.name}
                    </Typography>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container item xs justifyContent='center' alignItems='center'>
                <FontAwesomeIcon size='3x' icon={icon} color={SECONDARY} />
            </Grid>
            <Grid container justifyContent='space-between'>
                <Typography variant='caption' className={note.isSignedOff ? classes.signedOffText : null}>
                    Signed:
                </Typography>
                <Typography variant='caption' className={note.isSignedOff ? classes.signedOffText : null}>
                    {note.isSignedOff ? Moment(note.signedOffTime).format('ll') : '-------------------'}
                </Typography>
            </Grid>
            <Grid container justifyContent='space-between'>
                <Typography variant='caption'>Created:</Typography>
                <Typography variant='caption'>{Moment(note.createdAt).format('ll')}</Typography>
            </Grid>
            <Grid container justifyContent='space-between'>
                <Typography variant='caption'>Updated</Typography>
                <Typography variant='caption'>{Moment(note.updatedAt).format('ll')}</Typography>
            </Grid>
        </ListItem>
    </Paper>
);

const styles = {
    paper: {
        display: 'flex',
        minHeight: 238,
        minWidth: 206,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16,
    },
    checkbox: {},
    checkboxRoot: {
        width: 32,
        height: 32,
        '& > span > svg': {
            width: '0.8em',
            height: '0.8em',
        },
    },
    checkboxInputRoot: {
        width: '0.8em',
        height: '0.8em',
    },
    header: {
        overflow: 'hidden',
        marginBottom: 6,
    },
    title: {
        width: '100%',
        fontWeight: 'bold',
    },
    author: {
        width: '100%',
    },
    divider: {
        width: '100%',
    },
    signedOffText: {
        color: 'green',
    },
    ripple: {
        color: PRIMARY,
    },
};

export default compose(NotesItemContainer, withStyles(styles))(NotesGridItem);

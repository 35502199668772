import React from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import DashboardIcon from '@material-ui/icons/Dashboard';

import AppBarMenu from '../../components/AppBarMenu';

const ConsentFormThankyou = ({ classes, user }) => {
  const pageContent = (
    <Grid component="main" className={classes.main} container>
      <Grid
        component="nav"
        className={classes.thankyou}
        container
        wrap="nowrap"
        direction="column"
      >
        <React.Fragment>
          <main>
            {/* Hero unit */}
            <div className={classes.heroContent}>
              <Container maxWidth="sm">
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  Thank You!
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  paragraph
                >
                  You have been submitted data successfully.
                </Typography>
                {user.email && (
                  <div className={classes.heroButtons}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item>
                        <Link to="/consent-form-dashboard">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            <DashboardIcon className={classes.dashboardIcon} />
                            Dashboard
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Container>
            </div>
          </main>
        </React.Fragment>
      </Grid>
    </Grid>
  );

  if (user.email) {
    return <AppBarMenu>{pageContent}</AppBarMenu>;
  }
  return pageContent;
};

const styles = {
  main: {
    height: '100%',
    paddingTop: 100,
  },
  thankyou: {
    backgroundColor: 'transparent',
  },
  heroButtons: {
    marginTop: 50,
  },
  dashboardIcon: {
    marginRight: 10,
  },
  heroContent: {},
};

export default compose(
  withStyles(styles),
  connect(({ user }) => ({
    user,
  }))
)(ConsentFormThankyou);

// eslint-disable-next-line import/prefer-default-export
export const daysOfWeek = [
  {
    id: 'mon',
    name: 'Monday',
    icon: 'M',
  },
  {
    id: 'tue',
    name: 'Tuesday',
    icon: 'T',
  },
  {
    id: 'wed',
    name: 'Wednesday',
    icon: 'W',
  },
  {
    id: 'thu',
    name: 'Thursday',
    icon: 'T',
  },
  {
    id: 'fri',
    name: 'Friday',
    icon: 'F',
  },
  {
    id: 'sat',
    name: 'Saturday',
    icon: 'S',
  },
  {
    id: 'sun',
    name: 'Sunday',
    icon: 'S',
  },
];

import { compose, withHandlers, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { gql } from '@apollo/client';
import queryString from 'query-string';
import { connect } from 'react-redux';
import _ from 'lodash';
import client from '../../data/apollo/client';
import processMedias from './processFiles';

const episodeNodeQuery = gql`
    query episodeNodeQuery($episodeId: ID!) {
        node(id: $episodeId) {
            __typename
            id
            ... on Episode {
                name
                startDate
                endDate
            }
        }
    }
`;

const attachmentsListQuery = gql`
    query attachmentsList($patientId: ID!, $episodeId: ID, $limit: Int, $offset: Int, $mediaOnly: Boolean) {
        node(id: $patientId) {
            __typename
            id
            ... on Patient {
                notes(episodeId: $episodeId, limit: $limit, offset: $offset, mediaOnly: $mediaOnly) {
                    nodes {
                        ... on MediaNote {
                            __typename
                            id
                            title
                            createdAt
                            updatedAt
                            isSignedOff
                            signedOffTime
                            fileType
                            author {
                                name
                                id
                            }
                            file {
                                id
                                encodedFile
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
        }
    }
`;

export const notesListGeneralDataQuery = gql`
    query nodeQuery($patientId: ID!, $episodeId: ID, $limit: Int, $offset: Int, $noGroup: Boolean) {
        node(id: $patientId) {
            __typename
            id
            ... on Patient {
                name
                dob
                email
                address
                phone
                notes(episodeId: $episodeId, limit: $limit, offset: $offset, noGroup: $noGroup) {
                    nodes {
                        ... on Note {
                            __typename
                            id
                            title
                            createdAt
                            updatedAt
                            isSignedOff
                            signedOffTime
                            author {
                                name
                                id
                                __typename
                            }
                            ... on DrawingNote {
                                pageNumber
                                group {
                                    id
                                    __typename
                                }
                                template {
                                    id
                                    file {
                                        id
                                        encodedFile
                                        __typename
                                    }
                                    __typename
                                }
                                __typename
                            }
                            ... on TextNote {
                                text
                                __typename
                            }
                            ... on FormNote {
                                createdAt
                                rows
                                isSignedOff
                                sections {
                                    id
                                    type
                                    title
                                    rowIndexes
                                    columnIndexes
                                    ... on TextSection {
                                        text
                                        __typename
                                    }
                                    ... on SpreadsheetSection {
                                        spreadsheetData
                                        __typename
                                    }
                                    ... on YesNoSection {
                                        yesNoValue
                                        __typename
                                    }
                                    ... on NumberScale10Section {
                                        numberScale10Value
                                        __typename
                                    }
                                    ... on NumberScale100Section {
                                        numberScale100Value
                                        __typename
                                    }
                                    ... on CheckboxesSection {
                                        checkboxesData
                                        __typename
                                    }
                                    ... on DrawingSection {
                                        drawingData
                                        pageNumber
                                        group {
                                            id
                                            __typename
                                        }
                                        template {
                                            pageNumber
                                            id
                                            file {
                                                id
                                                encodedFile
                                                __typename
                                            }
                                            group {
                                                id
                                                __typename
                                            }
                                            __typename
                                        }
                                        __typename
                                    }
                                    __typename
                                }
                                signedOffTime
                                title
                                __typename
                            }
                        }
                        __typename
                    }
                    pageInfo {
                        hasNextPage
                        __typename
                    }
                    __typename
                }
                __typename
            }
        }
    }
`;

export const drawingNoteQuery = gql`
    query drawingNote($noteId: ID!) {
        node(id: $noteId) {
            __typename
            id
            ... on DrawingNote {
                createdAt
                title
                drawingData
                template {
                    pageNumber
                    id
                    file {
                        id
                        encodedFile
                        __typename
                    }
                    group {
                        id
                        __typename
                    }
                    __typename
                }
                isSignedOff
                signedOffTime
                patient {
                    id
                    name
                    __typename
                }
                __typename
            }
        }
    }
`;

const initialState = {
    loading: false,
    error: false,
    doc: null,
    data: null,
};

const onGenerate =
    ({ episodeId, patientId, state, updateState, status, practice, user }) =>
    async () => {
        updateState({ ...state, loading: true });

        try {
            const queryEpisode = await client.query({
                query: episodeNodeQuery,
                variables: {
                    episodeId,
                },
            });

            const queryNotes = await client.query({
                query: notesListGeneralDataQuery,
                variables: {
                    patientId,
                    episodeId,
                    offset: 0,
                    limit: 30,
                    noGroup: false,
                    isSignedOff: true,
                },
                fetchPolicy: 'no-cache',
            });

            let nodesNotes = _.get(queryNotes, 'data.node.notes.nodes', []);
            console.log(nodesNotes, '>>>>', queryNotes);

            const generalNoteData = queryNotes.data.node;
            if (nodesNotes && nodesNotes.length > 0) {
                for (const node of nodesNotes) {
                    if (node.__typename === 'DrawingNote' && node.id) {
                        const noteDetail = await client.query({
                            query: drawingNoteQuery,
                            variables: {
                                noteId: node.id,
                            },
                        });
                        const { drawingData } = noteDetail.data.node;
                        if (drawingData) {
                            node.drawingData = drawingData;
                        }
                    }
                }

                // Needs to sort on a copy of the array
                nodesNotes = [...nodesNotes].sort((a, b) => {
                    const aPageNumber = _.get(a, 'drawingData.pageNumber', 0);
                    const bPageNumber = _.get(b, 'drawingData.pageNumber', 0);

                    if (a.title === b.title) {
                        if (aPageNumber < bPageNumber) {
                            return -1;
                        }
                        return 1;
                    }
                    return 0;
                });
            }

            let fetchMorePages = true;
            let queryAttachmentsOffset = 0;
            let attachments = [];
            while (fetchMorePages) {
                const queryAttachments = await client.query({
                    query: attachmentsListQuery,
                    variables: {
                        patientId,
                        episodeId,
                        offset: queryAttachmentsOffset,
                        limit: 1,
                        mediaOnly: true,
                        isSignedOff: true,
                    },
                });

                if (
                    queryAttachments &&
                    queryAttachments.data &&
                    queryAttachments.data.node &&
                    queryAttachments.data.node.notes &&
                    queryAttachments.data.node.notes.nodes.length > 0
                ) {
                    attachments.push(queryAttachments.data.node.notes.nodes[0]);
                    queryAttachmentsOffset += 1;
                } else {
                    fetchMorePages = false;
                }
            }

            const episode = queryEpisode.data.node;

            // merged notes and attachments
            const nodes = nodesNotes.concat(attachments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));

            // get patient data from query
            const { dob, name, address, email, phone, id } = generalNoteData;

            // process all encodedFiles to get it metadata
            const { files, canvasImages } = await processMedias(nodes);
            const data = {
                practice,
                user,
                notes: nodes,
                episode,
                patient: { dob, name, address, email, phone, id },
                files,
                canvasImages,
            };

            updateState({ ...state, loading: false, data });
        } catch (error) {
            console.warn(error); // eslint-disable-line no-console
            updateState({ ...state, loading: false, error: true, data: null });
        }
    };

const GeneratePDFContainer = compose(
    withRouter,
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
    })),
    connect(({ user, practice }) => ({
        practice,
        user,
    })),
    withState('state', 'updateState', initialState),
    withHandlers({
        onGenerate,
    })
);

export default GeneratePDFContainer;

import filter from 'lodash/filter';

export const filterSectionsByRowAndPage = (sections, rowNumber) =>
    filter(sections, (section) => section.rowIndexes.includes(rowNumber) && (!section.pageNumber || section.pageNumber <= 1));

export const filterSectionsByRow = (sections, rowNumber) => filter(sections, (section) => section.rowIndexes.includes(rowNumber));

export const isPasswordValid = (password) => {
    if (password && password.length < 8) return false;

    return true;
};

export const featureFlags = (environment) => {
    switch (environment) {
        case 'PRODUCTION':
            return window.location.href.includes('pulse') || window.location.href.includes('app') || window.location.href.includes('www');
        case 'STAGING':
            return window.location.href.includes('staging') || window.location.href.includes('branch');
        case 'DEV':
            return window.location.href.includes('localhost:');
        default:
            return false;
    }
};

export const formatMoney = (price, currency = 'USD') => price.toLocaleString('en-US', { style: 'currency', currency });

import React from 'react';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import AddCircle from '@material-ui/icons/AddCircle';
import MoreVert from '@material-ui/icons/MoreVert';
import Check from '@material-ui/icons/Check';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import { MINOR_BLACK, WHITE } from '../../style/constants';

import PredefinedSelectionsEditorContainer from './predefinedSelectionsEditorContainer';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';

const PredefinedSelectionsEditor = ({
    state,
    categoryInput,
    openAddItemDialog,
    onCategoryNameChange,
    onDeleteChip,
    setOpenAddItemDialog,
    shouldShowDialog,
    shouldShowCreateSectionDialog,
    setShouldShowCreateSectionDialog,
    onClosePreferenceDialog,
    onClosePreference,
    classes,
    error,
    status,
    onPreferenceClick,
    onAddChipAction,
    onAddValueForPredefined,
    onInsertPredefinedValueChange,
    onSavePreferencesAction,
    selectedKeyForPredefined,
    setSectionNameToBeAdded,
    sectionTypeToBeAdded,
    onSectionTypeChange,
    onAddSectionAction,
    onRemoveSection,
    shouldShowRenameSectionDialog,
    setShouldShowRenameSectionDialog,
    onRenameSectionAction,
    sectionNameToBeRenamed,
    setSectionNewName,
    onOpenRenameSectionDialog,
    onScaleFieldMinValueChange,
    onScaleFieldMaxValueChange,
    onDialogLoading,
    anchorCategoryElement,
    setAnchorCategoryElement,
    setShouldShowRenameCategoryDialog,
    shouldShowRenameCategoryDialog,
    setCategoryNewName,
    onRenameCategoryAction,
    onMenuRenameCategoryAction,
    userPreferencesState,
    onSetDefaultCategory,
    categoryNameToBeRenamed,
    setCategoryNameToBeRenamed,
}) => (
    <div className={classes.wrapper}>
        {(() => {
            if (status.loading)
                return (
                    <Dialog fullWidth maxWidth='md' open onClose={() => {}} aria-labelledby='max-width-dialog-title'>
                        <Paper style={{ minHeight: 200, maxHeight: 200 }}>
                            <Loading overlay />
                        </Paper>
                    </Dialog>
                );
            if (status.error) return <ErrorMessage error={error} />;
            return (
                <Dialog fullWidth maxWidth='xl' open={shouldShowDialog} onClose={onClosePreference} aria-labelledby='max-width-dialog-title'>
                    {onDialogLoading && <Loading overlay />}
                    <DialogTitle id='max-width-dialog-title'>Edit Preference options</DialogTitle>
                    <DialogContent>
                        <div className={classes.root}>
                            <Grid container direction='row' item xs={12} spacing={1}>
                                <Grid item xs={6} sm={3}>
                                    <DialogContentText className={classes.panelHeader}>Categories</DialogContentText>
                                    <Paper className={classes.paper}>
                                        <List component='nav' aria-label='contacts'>
                                            {userPreferencesState.map((value) => (
                                                <ListItem
                                                    key={value.id}
                                                    button
                                                    selected={(() => {
                                                        if (state.selectedPreference) {
                                                            return value.id === state.selectedPreference.id;
                                                        }
                                                        return false;
                                                    })()}
                                                    onClick={() => onPreferenceClick('select_category', value)}>
                                                    <ListItemText primary={value.name} />
                                                    {value.isDefaultPreference === 1 && (
                                                        <ListItemIcon>
                                                            <Check />
                                                        </ListItemIcon>
                                                    )}

                                                    {state.selectedPreference && state.selectedPreference.name === value.name && (
                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                edge='end'
                                                                aria-label='more'
                                                                onClick={(event) => {
                                                                    setAnchorCategoryElement(event.target);
                                                                }}>
                                                                <MoreVert />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    )}
                                                </ListItem>
                                            ))}
                                            {state.userAddedPreferences.length > 0 &&
                                                state.userAddedPreferences.map((value, index) => (
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        onClick={() => onPreferenceClick('select_category', value)}
                                                        selected={(() => {
                                                            if (state.selectedPreference) {
                                                                return value.name === state.selectedPreference.name;
                                                            }
                                                            return false;
                                                        })()}>
                                                        <ListItemText primary={value.name} />
                                                        {value.isDefaultPreference === 1 && (
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                        )}

                                                        {state.selectedPreference && state.selectedPreference.name === value.name && (
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge='end'
                                                                    aria-label='more'
                                                                    onClick={(event) => {
                                                                        setAnchorCategoryElement(event.target);
                                                                    }}>
                                                                    <MoreVert />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        )}
                                                    </ListItem>
                                                ))}

                                            <ListItem>
                                                <TextField
                                                    id='standard-basic'
                                                    autoComplete='off'
                                                    label='Add category'
                                                    value={categoryInput}
                                                    onChange={(event) => onCategoryNameChange(event.target)}
                                                />
                                                <IconButton
                                                    color='primary'
                                                    aria-label='add_circle'
                                                    component='span'
                                                    onClick={() => onPreferenceClick('add_category', categoryInput)}>
                                                    <AddCircle />
                                                </IconButton>
                                            </ListItem>
                                        </List>

                                        {anchorCategoryElement && (
                                            <Menu
                                                id='long-menu'
                                                anchorEl={anchorCategoryElement}
                                                keepMounted
                                                open={anchorCategoryElement !== undefined}
                                                onClose={() => {
                                                    setAnchorCategoryElement(false);
                                                }}>
                                                <MenuItem
                                                    key='Set as default'
                                                    onClick={() => {
                                                        onSetDefaultCategory();
                                                    }}>
                                                    Set as default
                                                </MenuItem>
                                                <MenuItem
                                                    key='Rename'
                                                    onClick={() => {
                                                        onMenuRenameCategoryAction();
                                                    }}>
                                                    Rename
                                                </MenuItem>
                                            </Menu>
                                        )}

                                        <Dialog
                                            open={shouldShowRenameCategoryDialog}
                                            onClose={() => {
                                                setShouldShowRenameCategoryDialog(false);
                                            }}
                                            aria-labelledby='form-dialog-title'>
                                            <DialogTitle id='form-dialog-title'>Rename Category</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    required
                                                    autoFocus
                                                    margin='dense'
                                                    id='name'
                                                    autoComplete='off'
                                                    label='Category name'
                                                    fullWidth
                                                    defaultValue={categoryNameToBeRenamed}
                                                    onChange={(event) => {
                                                        setCategoryNewName(event.target.value);
                                                    }}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    color='primary'
                                                    onClick={() => {
                                                        onRenameCategoryAction();
                                                    }}>
                                                    Rename
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setShouldShowRenameCategoryDialog(false);
                                                    }}
                                                    color='secondary'>
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Paper>
                                </Grid>

                                <Grid item sm={9}>
                                    <DialogContentText className={classes.panelHeader}>Details of predefined selections</DialogContentText>

                                    {(() => {
                                        if (!state.selectedPreference) {
                                            return (
                                                <Paper className={classes.paper}>
                                                    <DialogContentText className={classes.panelHeader}>Please select a category</DialogContentText>
                                                </Paper>
                                            );
                                        }
                                        return (
                                            <Paper className={classes.paper}>
                                                {(() => {
                                                    const { settings } = state.selectedPreference;
                                                    if (settings) {
                                                        const settingsJson = JSON.parse(settings);
                                                        return settingsJson.data.map((item) => {
                                                            if (item.type === 'scale')
                                                                return (
                                                                    <Accordion key={item.key} className={classes.expansionPanel}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls='panel1c-content'
                                                                            id='panel1c-header'>
                                                                            <Typography className={classes.secondaryHeading}>{item.key}</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails className={classes.details}>
                                                                            <TextField
                                                                                label='Minimum'
                                                                                type='number'
                                                                                size='small'
                                                                                variant='outlined'
                                                                                autoComplete='off'
                                                                                style={{ marginRight: 10 }}
                                                                                defaultValue={item.value[0]}
                                                                                onChange={(event) => {
                                                                                    onScaleFieldMinValueChange(
                                                                                        item.key,
                                                                                        parseInt(event.target.value, 10)
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <TextField
                                                                                label='Maximum'
                                                                                type='number'
                                                                                size='small'
                                                                                autoComplete='off'
                                                                                variant='outlined'
                                                                                style={{ marginRight: 10 }}
                                                                                defaultValue={item.value[1]}
                                                                                onChange={(event) => {
                                                                                    onScaleFieldMaxValueChange(
                                                                                        item.key,
                                                                                        parseInt(event.target.value, 10)
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </AccordionDetails>
                                                                        <AccordionActions>
                                                                            <Button
                                                                                size='small'
                                                                                color='primary'
                                                                                onClick={() => {
                                                                                    onOpenRenameSectionDialog(item.key);
                                                                                }}>
                                                                                Rename
                                                                            </Button>
                                                                            <Button
                                                                                size='small'
                                                                                color='secondary'
                                                                                onClick={() => onRemoveSection(item.key)}>
                                                                                Remove
                                                                            </Button>
                                                                        </AccordionActions>
                                                                    </Accordion>
                                                                );
                                                            return (
                                                                <Accordion key={item.key} className={classes.expansionPanel}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls='panel1c-content'
                                                                        id='panel1c-header'>
                                                                        <Typography className={classes.secondaryHeading}>{item.key}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className={classes.details}>
                                                                        {(() => {
                                                                            if (item.value) {
                                                                                return item.value.map((value) => (
                                                                                    <Chip
                                                                                        variant='outlined'
                                                                                        color='primary'
                                                                                        key={value}
                                                                                        className={classes.chip}
                                                                                        label={value}
                                                                                        onDelete={() => onDeleteChip(item.key, value)}
                                                                                    />
                                                                                ));
                                                                            }
                                                                        })()}
                                                                        <Chip
                                                                            variant='outlined'
                                                                            color='secondary'
                                                                            icon={<AddCircle />}
                                                                            label={`Add ${item.key.toLowerCase()}`}
                                                                            onClick={() => onAddChipAction(item.key)}
                                                                            className={classes.chip}
                                                                        />
                                                                    </AccordionDetails>
                                                                    <AccordionActions>
                                                                        <Button
                                                                            size='small'
                                                                            color='primary'
                                                                            onClick={() => {
                                                                                onOpenRenameSectionDialog(item.key);
                                                                            }}>
                                                                            Rename
                                                                        </Button>
                                                                        <Button
                                                                            size='small'
                                                                            color='secondary'
                                                                            onClick={() => onRemoveSection(item.key)}>
                                                                            Remove
                                                                        </Button>
                                                                    </AccordionActions>
                                                                </Accordion>
                                                            );
                                                        });
                                                    }
                                                })()}

                                                <Button
                                                    variant='contained'
                                                    color='default'
                                                    className={classes.button}
                                                    startIcon={<AddCircle />}
                                                    onClick={() => setShouldShowCreateSectionDialog(true)}>
                                                    Add section
                                                </Button>

                                                <Dialog
                                                    open={openAddItemDialog}
                                                    onClose={() => {
                                                        setOpenAddItemDialog(false);
                                                    }}
                                                    aria-labelledby='form-dialog-title'>
                                                    <DialogTitle id='form-dialog-title'>{selectedKeyForPredefined || 'Insert a text'}</DialogTitle>
                                                    <DialogContent>
                                                        <TextField
                                                            autoFocus
                                                            margin='dense'
                                                            id='name'
                                                            label='Predefined selection'
                                                            autoComplete='off'
                                                            fullWidth
                                                            onChange={(event) => onInsertPredefinedValueChange(event.target)}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => onAddValueForPredefined()} color='primary'>
                                                            Add
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenAddItemDialog(false);
                                                            }}
                                                            color='secondary'>
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={shouldShowCreateSectionDialog}
                                                    onClose={() => {
                                                        setShouldShowCreateSectionDialog(false);
                                                    }}
                                                    aria-labelledby='form-dialog-title'>
                                                    <DialogTitle id='form-dialog-title'>Add section</DialogTitle>
                                                    <DialogContent>
                                                        <TextField
                                                            required
                                                            autoFocus
                                                            margin='dense'
                                                            id='name'
                                                            label='Section name'
                                                            autoComplete='off'
                                                            fullWidth
                                                            onChange={(event) => setSectionNameToBeAdded(event.target.value)}
                                                        />
                                                        <FormControl required style={{ minWidth: 120 }}>
                                                            <InputLabel htmlFor='section-type-input'>Section type</InputLabel>
                                                            <Select
                                                                input={<Input id='section-type-input' />}
                                                                value={sectionTypeToBeAdded === 'scale' ? 1 : 0}
                                                                onChange={(event) => {
                                                                    onSectionTypeChange(event.target.value === 1 ? 'scale' : 'text');
                                                                }}>
                                                                <MenuItem value={0}>Text</MenuItem>
                                                                <MenuItem value={1}>Numeric scale</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => onAddSectionAction()} color='primary'>
                                                            Add
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setShouldShowCreateSectionDialog(false);
                                                            }}
                                                            color='secondary'>
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={shouldShowRenameSectionDialog}
                                                    onClose={() => {
                                                        setShouldShowRenameSectionDialog(false);
                                                    }}
                                                    aria-labelledby='form-dialog-title'>
                                                    <DialogTitle id='form-dialog-title'>Rename section</DialogTitle>
                                                    <DialogContent>
                                                        <TextField
                                                            required
                                                            autoFocus
                                                            margin='dense'
                                                            id='name'
                                                            autoComplete='off'
                                                            label='Section name'
                                                            fullWidth
                                                            defaultValue={sectionNameToBeRenamed}
                                                            onChange={(event) => {
                                                                setSectionNewName(event.target.value);
                                                            }}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            color='primary'
                                                            onClick={() => {
                                                                onRenameSectionAction();
                                                            }}>
                                                            Rename
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setShouldShowRenameSectionDialog(false);
                                                            }}
                                                            color='secondary'>
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </Paper>
                                        );
                                    })()}
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onSavePreferencesAction(userPreferencesState)} color='primary'>
                            Save
                        </Button>
                        <Button onClick={onClosePreference} color='secondary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        })()}
    </div>
);

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 280,
        minWidth: 280,
        position: 'absolute',
        top: 0,
        left: 0,
    },
    disabled: {
        opacity: 0.5,
    },
    topInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 32,
        paddingBottom: 12,
        borderBottom: `1px ${MINOR_BLACK} solid`,
    },
    topInfoText: {
        paddingRight: 12,
    },
    heading: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    warning: {
        fontSize: 12,
        marginTop: 6,
    },
    input: {
        display: 'none',
    },
    icon: {
        color: WHITE,
        opacity: 0,
        transition: '0.3s',
    },
    gridList: {
        height: '100%',
        minWidth: '50%',
    },
    listItem: {
        cursor: 'pointer',
        transitionDuration: '0.3s',
        '&:hover': {
            transform: 'translateY(-4px)',
        },
        '&:hover $icon': {
            opacity: 1,
        },
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 10,
        margin: 10,
        textAlign: 'center',
    },
    panelHeader: {
        margin: 10,
        textAlign: 'left',
    },
    blankTemplate: {
        height: '100%',
        width: '100%',
        border: `1px ${MINOR_BLACK} solid`,
    },
    chip: {
        marginRight: 4,
        marginBottom: 4,
    },
    expansionPanel: {
        marginBottom: 10,
    },
    details: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
};

export default compose(withStyles(styles), PredefinedSelectionsEditorContainer)(PredefinedSelectionsEditor);

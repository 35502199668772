// action types

export const EPISODE_SELECTED = 'EPISODE_SELECTED';
export const EPISODE_CLEARED = 'EPISODE_CLEARED';

// action creators

export const episodeSelected = (id, name, startDate, endDate) => ({
  type: EPISODE_SELECTED,
  id,
  name,
  startDate,
  endDate,
});

export const episodeCleared = () => ({ type: EPISODE_CLEARED });

import React from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const SimpleDropdownFilter = ({
  classes,
  filterName,
  filterCategories,
  status,
  setStatus,
}) => {
  const handleChange = event => {
    setStatus(event.target.value);
  };

  return (
    <form>
      <FormControl>
        <InputLabel id="demo-simple-select-label">{filterName}</InputLabel>
        <Select
          className={classes.searchBar}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label={filterName}
          onChange={handleChange}
          autoWidth
        >
          <MenuItem value="">-</MenuItem>
          {filterCategories.map(item => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </form>
  );
};

const styles = {
  searchBar: {
    width: 300,
  },
};

export default compose(withStyles(styles))(SimpleDropdownFilter);

import React from 'react';
import { compose, lifecycle, withProps } from 'recompose';
import { Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { notesCleared } from '../../data/redux/actions/notes';
import AppBarMenu from '../../components/AppBarMenu';
import TemplateList from '../../components/TemplateList';

const TemplateListSelectPage = ({
  onSelectTemplate,
  atWebDrawing,
  classes,
  patientId,
}) => {
  if (patientId === 'undefined' && atWebDrawing)
    return <Redirect to="/patient/select/note/templates" />;

  return (
    <AppBarMenu>
      <Grid
        component="main"
        className={classes.main}
        container
        direction="column"
        wrap="nowrap"
        item
        xs={12}
        sm={12}
        md={12}
        lg={10}
        xl={9}
      >
        {(() => {
          if (atWebDrawing)
            return <TemplateList onSelectTemplate={onSelectTemplate} />;
          return null;
        })()}
      </Grid>
    </AppBarMenu>
  );
};

const styles = {
  main: {
    paddingTop: 92,
    paddingLeft: 88,
    paddingRight: 44,
    paddingBottom: 64,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  leftButton: {
    position: 'absolute',
    left: 0,
    marginLeft: 12,
  },
};

export default compose(
  withStyles(styles),
  withProps(({ match, location }) => ({
    patientId: match.params.patientId,
    atWebDrawing: location.pathname.includes('templates'),
  })),
  lifecycle({
    componentDidMount() {
      this.props.dispatch(notesCleared());
    },
  })
)(TemplateListSelectPage);

import React from 'react';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import GeneratePDFContainer from '../PrintNote/generatePDFContainer';
import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import EpisodeInPDF from '../EpisodeInPDF';

const GeneratePDFButton = ({ error, patientId, episodeId, state, onGenerate }) => (
    <>
        {(() => {
            if (!patientId || episodeId === 'new') return null;
            if (state.loading) return <Loading />;
            if (state.error) return <ErrorMessage error={error} />;
            if (state.data) return <EpisodeInPDF data={state.data} />;
            return (
                <Tooltip title='PDF Export Notes in Episode'>
                    <Button onClick={onGenerate}>Generate PDF</Button>
                </Tooltip>
            );
        })()}
    </>
);

export default compose(GeneratePDFContainer)(GeneratePDFButton);

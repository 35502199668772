import React, { Component } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import { Grid } from '@material-ui/core';

import './rte.css';

const EditorOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'history',
  ],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

export default class EditorConvertToHTML extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  constructor(props) {
    super(props);
    this.state = { ...props.fieldProps };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });

    this.props.fieldProps.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ minHeight: '300px', maxWidth: '700px' }}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={EditorOptions}
        />
      </Grid>
    );
  }
}

export const BLACK = '#333333';
export const WHITE = 'white';
export const SECONDARY_BLACK = 'gray';
export const MINOR_BLACK = 'lightgray';
export const ERROR = '#f44336';
export const PRIMARY = '#233d4d';
export const SECONDARY = '#ff577b';

export const IRON = 'CACECE';
export const BOULDER = '#757575';
export const WILD_SAND = '#F5F5F5';
export const WARM_PINK = '#fe567b';

export const CYAN = '#a3f4ff';
export const LIGHT_YELLOW = '#fbffa3';
export const LIGHT_GREEN = '#41e2a4';
export const VIOLET = '#cda3ff';
export const ORANGE = '#e28743';
export const LIGHT_ORANGE = '#e85815';

export const ARRIVAL_GREEN = '#19b49c';
export const ARRIVAL_RED = WARM_PINK;
export const NEW_PATIENT_SHADE = 'yellow';

// APPOINTMENT TYPE COLORS
const colors = [
    '#d0e3ff', // PERIWINKLE
    '#fbc00e', // MIMOSA
    '#d1913e', // TANGERINE
    '#7e9dc2', // BLUEBERRY
    '#9b948a', // TAUPE
    '#135390', // BLUE
    '#b4f8c8', // MINT
];

export const APPOINTMENT_COLORS = [CYAN, LIGHT_YELLOW, LIGHT_GREEN, VIOLET, ORANGE];

export const APPOINTMENT_COLOR_OBJ = [
    { name: 'Violet', code: VIOLET },
    { name: 'Cyan', code: CYAN },
    { name: 'Yellow', code: LIGHT_YELLOW },
    { name: 'Green', code: LIGHT_GREEN },
    { name: 'Orange', code: ORANGE },

    { name: 'Periwinkle', code: colors[0] },
    { name: 'Mimosa', code: colors[1] },
    { name: 'Tangerine', code: colors[2] },
    { name: 'Blueberry', code: colors[3] },
    { name: 'Taupe', code: colors[4] },
    { name: 'Blue', code: colors[5] },
    { name: 'Mint', code: colors[6] },

    { name: 'Violet', code: VIOLET },
    { name: 'LightOrange', code: LIGHT_ORANGE },
];

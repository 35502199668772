import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { noteUpsert } from '../../data/redux/actions/note';

const drawingNoteQuery = gql`
    query drawingNote($noteId: ID!) {
        node(id: $noteId) {
            __typename
            id
            ... on DrawingNote {
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                categories {
                    id
                }
                author {
                    id
                    name
                }
                createdAt
                title
                drawingData
                template {
                    pageNumber
                    id
                    file {
                        id
                        encodedFile
                    }
                    group {
                        id
                    }
                }
                isSignedOff
                signedOffTime
                patient {
                    id
                    name
                }
            }
        }
    }
`;

const signOffNotesMutation = gql`
    mutation signOffNotes($noteIds: [ID!]) {
        signOffNotes(noteIds: $noteIds) {
            signedOffTime
        }
    }
`;

const signOnNoteMutation = gql`
    mutation signOnNote($noteId: ID!) {
        signOnNote(noteId: $noteId) {
            signedOffTime
        }
    }
`;

const upsertDrawingNoteMutation = gql`
    mutation upsertDrawingNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $episodes: [ID]
        $categories: [ID]
        $title: String
        $drawingData: String
        $templateId: ID
        $deletedAt: DateTime
    ) {
        upsertDrawingNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            episodes: $episodes
            categories: $categories
            title: $title
            drawingData: $drawingData
            template: $templateId
            deletedAt: $deletedAt
        ) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
            }
        }
    }
`;

const duplicateDrawingNoteMutation = gql`
    mutation duplicateDrawingNote($existingDrawingNoteId: ID, $author: ID!) {
        duplicateDrawingNote(existingDrawingNoteId: $existingDrawingNoteId, author: $author) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
            }
        }
    }
`;

const tagNoteMutation = gql`
    mutation tagNote($noteId: ID!, $categories: [ID], $episodes: [ID]) {
        tagNote(id: $noteId, episodes: $episodes, categories: $categories) {
            note {
                id
                __typename
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                categories {
                    id
                }
                title
            }
        }
    }
`;

const initialForm = {
    id: undefined,
    title: undefined,
    episodeIdsArray: [],
    categoryIdsArray: [],
    drawingData: undefined,
    loading: false,
    error: false,
    isSignedOff: false,
    signedOffTime: undefined,
    createdAt: undefined,
    template: {
        id: undefined,
        pageNumber: undefined,
        file: {
            id: undefined,
            encodedFile: undefined,
        },
        group: {
            id: undefined,
        },
    },
    episodes: [],
    author: undefined,
    isAuthor: true,
    patientName: undefined,
    preventQuery: false,
};

const handlers = {
    onDuplicate:
        ({ form, history, patientId, updateForm, duplicateDrawingNote, userId }) =>
        async () => {
            console.log('Duplicating drawing note');
            updateForm({
                ...form,
                loading: true,
            });

            try {
                console.log(
                    'variables',
                    JSON.stringify({
                        existingDrawingNoteId: form.id,
                        author: userId,
                    })
                );

                const response = await duplicateDrawingNote({
                    variables: {
                        existingDrawingNoteId: form.id,
                        author: userId,
                    },
                });

                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error: false,
                }));

                history.push(`/patient/${patientId}/note/drawing/${response.data.duplicateDrawingNote.note.id}`);
            } catch (error) {
                console.log(error);
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSave:
        ({ dispatch, form, patientId, updateForm, upsertDrawingNote, userId }) =>
        async () => {
            if (!form.id) {
                updateForm({
                    ...form,
                    loading: true,
                });
            } else {
                updateForm({
                    ...form,
                    loading: true,
                    preventQuery: true,
                });
            }

            const editOptions = {
                variables: {
                    noteId: form.id,
                    allowUpdate: true,
                    userId,
                    patientId,
                    templateId: form.template.id,
                    title: form.title || 'Untitled Drawing Note',
                    episodes: form.episodeIdsArray,
                    categories: form.categoryIdsArray,
                    drawingData: form.drawingData,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    upsertDrawingNote: {
                        __typename: 'NewDrawingNotePayload',
                        note: {
                            __typename: 'DrawingNote',
                            id: form.id,
                            isSignedOff: form.isSignedOff,
                            signedOffTime: form.signedOffTime,
                            drawingData: form.drawingData,
                            title: form.title,
                            template: {
                                __typename: 'Template',
                                id: form.template.id,
                                pageNumber: form.template.pageNumber,
                                file: {
                                    __typename: 'File',
                                    id: form.template.file.id,
                                    encodedFile: form.template.file.encodedFile,
                                },
                                group: {
                                    __typename: 'Group',
                                    id: form.template.group.id,
                                },
                            },
                            patient: {
                                __typename: 'Patient',
                                id: patientId,
                                name: form.patientName,
                            },
                            author: {
                                __typename: 'User',
                                id: form.author && form.author.id,
                                name: form.author && form.author.name,
                            },
                            episodes: form.episodes.map((episode) => ({
                                __typename: 'Episode',
                                id: episode.id,
                                name: episode.name,
                                startDate: episode.startDate,
                                endDate: episode.endDate,
                            })),
                            categories: form.categoryIdsArray.map((categoryId) => ({
                                __typename: 'Category',
                                id: categoryId,
                            })),
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: drawingNoteQuery,
                        variables: { noteId: form.id },
                    },
                ],
            };

            try {
                const response = await upsertDrawingNote(editOptions);

                dispatch(
                    noteUpsert({
                        id: response.data.upsertDrawingNote.note.id,
                        __typename: response.data.upsertDrawingNote.note.__typename,
                        title: response.data.upsertDrawingNote.note.title,
                        isSignedOff: response.data.upsertDrawingNote.note.isSignedOff,
                        signedOffTime: response.data.upsertDrawingNote.note.signedOffTime,
                    })
                );

                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error: false,
                    preventQuery: true,
                    id: response.data.upsertDrawingNote.note.id,
                    title: prevForm.title,
                }));
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSign:
        ({ dispatch, form, signOffMutation, updateForm, signOnNote }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                let response;
                if (form.isSignedOff) {
                    response = await signOnNote({
                        variables: {
                            noteId: form.id,
                        },
                        refetchQueries: [
                            {
                                query: drawingNoteQuery,
                                variables: { noteId: form.id },
                            },
                        ],
                    });
                } else {
                    response = await signOffMutation({
                        variables: {
                            noteIds: [form.id],
                        },
                        refetchQueries: [
                            {
                                query: drawingNoteQuery,
                                variables: { noteId: form.id },
                            },
                        ],
                    });
                }

                const signedTime = form.isSignedOff ? response.data.signOnNote.signedOffTime : response.data.signOffNotes.signedOffTime;
                updateForm((prevForm) => ({
                    ...form,
                    loading: false,
                    isSignedOff: !prevForm.isSignedOff,
                    signedOffTime: signedTime,
                    error: false,
                }));
                dispatch(
                    noteUpsert({
                        isSignedOff: Boolean(signedTime),
                        signedOffTime: signedTime,
                    })
                );
            } catch (error) {
                console.log(error);
                updateForm({ ...form, loading: false, error });
            }
        },
    onTag:
        ({ form, tagNote, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                const response = await tagNote({
                    variables: {
                        noteId: form.id,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                    },
                });

                updateForm({
                    ...form,
                    loading: false,
                    error: false,
                    episodes: response.data.tagNote.note.episodes,
                });
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onArchiveNote:
        ({ form, userId, patientId, updateForm, upsertDrawingNote, history }) =>
        async () => {
            updateForm({ ...form, loading: true });
            try {
                await upsertDrawingNote({
                    variables: {
                        noteId: form.id,
                        allowUpdate: true,
                        userId,
                        patientId,
                        templateId: form.template.id,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                        title: form.title || 'Untitled Drawing Note',
                        drawingData: form.drawingData,
                        deletedAt: new Date(),
                    },
                });
                updateForm({ ...form, loading: false, error: false });
                history.goBack();
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onEditNote:
        ({ form, history, patientId }) =>
        () => {
            const templateId = form.template !== null && form.template !== undefined && form.template.id ? form.template.id : 'blankId';
            const drawingNoteId = form.id;
            const isGroup = false;
            const episodeId = form.episodes.length > 0 ? form.episodes[0].id : undefined;
            const pathName = `/patient/${patientId}/note/templates/webdrawingnote`;
            let searchString = `?isGroup=${isGroup}`;
            if (templateId) {
                searchString += `&templateId=${templateId}`;
            }
            if (episodeId) {
                searchString += `&episodeId=${episodeId}`;
            }
            if (drawingNoteId) {
                searchString += `&drawingNoteId=${drawingNoteId}`;
            }

            history.push({
                pathname: pathName,
                search: searchString,
            });
        },
};

const DrawingNoteContainer = compose(
    withRouter,
    connect(({ user }) => ({
        userId: user.id,
        isClinician: user.roles.includes('CLINICIAN'),
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withState('form', 'updateForm', initialForm),
    withProps(({ form, match, location, isClinician, isAdmin, noteIdForce, canEditForce, isConsentFormSubscription }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
        noteId: noteIdForce || match.params.noteId,
        initialSave: form.loading && !form.id,
        isHideAction: isConsentFormSubscription,
        canEdit:
            canEditForce !== undefined ? canEditForce : !form.isSignedOff && (isClinician || isAdmin) && form.isAuthor && !isConsentFormSubscription,
    })),
    graphql(upsertDrawingNoteMutation, { name: 'upsertDrawingNote' }),
    graphql(duplicateDrawingNoteMutation, { name: 'duplicateDrawingNote' }),
    graphql(signOffNotesMutation, { name: 'signOffMutation' }),
    graphql(signOnNoteMutation, { name: 'signOnNote' }),
    graphql(tagNoteMutation, { name: 'tagNote' }),
    branch(
        ({ noteId }) => noteId,
        compose(
            graphql(drawingNoteQuery, {
                name: 'query',
                options: ({ noteId }) => ({
                    variables: { noteId },
                    fetchPolicy: 'no-cache',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                ({ status }) => status.success,
                withProps(({ query }) => ({
                    __typename: query.node.__typename,
                    id: query.node.id,
                    episodeIdsArray: query.node.episodes && query.node.episodes.length ? query.node.episodes.map((episode) => episode.id) : [],
                    categoryIdsArray: query.node.categories.map((category) => category.id),
                    createdAt: query.node.createdAt,
                    title: query.node.title,
                    drawingData: query.node.drawingData,
                    isSignedOff: query.node.isSignedOff,
                    signedOffTime: query.node.signedOffTime,
                    template: query.node.template,
                    patientId: query.node.patient.id,
                    patientName: query.node.patient.name,
                    episodes: query.node.episodes,
                    author: query.node.author,
                }))
            ),
            withHandlers(handlers),
            withPropsOnChange(['onSave'], ({ onSave }) => ({
                onSave: debounce(onSave, 1000),
            })),
            lifecycle({
                componentDidMount() {
                    const {
                        id,
                        categoryIdsArray,
                        noteId,
                        episodeId,
                        episodeIdsArray,
                        isSignedOff,
                        title,
                        drawingData,
                        form,
                        status,
                        signedOffTime,
                        template,
                        updateForm,
                        createdAt,
                        author,
                        userId,
                        patientName,
                        episodes,
                    } = this.props;

                    if (!noteId && episodeId) {
                        updateForm({ ...form, episodeIdsArray: [episodeId] });
                        return;
                    }
                    if (status && status.loading) {
                        return;
                    }

                    if (status && status.success) {
                        updateForm({
                            ...form,
                            id,
                            episodeIdsArray,
                            categoryIdsArray,
                            template: {
                                id: get(template, 'id', null),
                                pageNumber: get(template, 'pageNumber', null),
                                file: {
                                    id: get(template, 'file.id', null),
                                    encodedFile: get(template, 'file.encodedFile', null),
                                },
                                group: {
                                    id: get(template, 'group.id', null),
                                },
                            },
                            title,
                            drawingData,
                            isSignedOff,
                            signedOffTime,
                            createdAt,
                            author,
                            isAuthor: userId === get(author, 'id'),
                            patientName,
                            episodes,
                        });
                    }
                },
            })
        )
    )
);

export default DrawingNoteContainer;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { useMutation, useQuery } from '@apollo/client';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert/Alert';
import { withStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@material-ui/core';

import Loading from '../../components/Loading';
import { WHITE } from '../../style/constants';
import { REMOVE_CONSENT_FORM, LOAD_LIST_CONSENT_FORM } from './gql';

const buildConsentFormLink = (key, patientId) => {
  if (patientId) {
    return `/consent-form/${key}/${patientId}`;
  }
  return `/consent-form/${key}`;
};

const ConsentFormList = ({ classes, patientId, practiceId }) => {
  const [listForm, updateListForm] = useState([]);
  const [successMessage, updateSuccessMessage] = useState('');

  // eslint-disable-next-line no-unused-vars
  const { queryLoading, queryError } = useQuery(LOAD_LIST_CONSENT_FORM, {
    variables: { practice_id: practiceId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      updateListForm(data.listConsentForm.forms);
    },
  });

  const [{ loading, error }] = useMutation(REMOVE_CONSENT_FORM, {
    onCompleted: result => {
      if (result.removeConsentForm.success === true) {
        updateSuccessMessage('You have successfully removed the form');
        listForm.forms = result.removeConsentForm.forms;
        updateListForm(listForm);
      } else {
        // updateError(
        //   'There was an error removing the form. Please try again.'
        // );
      }
    },
  });

  if (queryLoading) {
    if (loading) return <Loading overlay />;
  }
  if (queryError) {
    // eslint-disable-next-line no-alert
    alert(error);
  }

  return (
    <Grid
      component="main"
      className={classes.main}
      container
      direction="column"
      wrap="nowrap"
      item
      xs={12}
      sm={12}
      md={12}
      lg={10}
      xl={9}
    >
      {queryLoading && <Loading overlay />}

      {successMessage && (
        <Paper className={classes.paperAlert}>
          <Alert severity="success">{successMessage}</Alert>
        </Paper>
      )}

      {error && (
        <Paper className={classes.paperAlert}>
          <Alert severity="error">{error}</Alert>
        </Paper>
      )}

      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listForm.map(row => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup
                      color="primary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        href={buildConsentFormLink(row.key, patientId)}
                        target="_blank"
                        color="default"
                        variant="outlined"
                      >
                        View
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

const styles = {
  main: {
    paddingTop: 0,
    paddingBottom: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: () => window.innerWidth > 600 && '100%',
  },
  leftButton: {
    position: 'absolute',
    left: 0,
    marginLeft: 12,
  },
  patientList: {
    maxWidth: 350,
    backgroundColor: WHITE,
    overflow: 'auto',
  },
  subheading: {
    paddingBottom: 8,
    fontWeight: 'bold',
  },
  episodesPaper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  paperAlert: {
    marginBottom: 16,
  },
};

export default compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
  })),
  withStyles(styles)
)(ConsentFormList);

import React, { useState } from 'react';
import { compose } from 'recompose';
import moment from 'moment';

import { Typography, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import client from '../../../data/apollo/client';
import { WARM_PINK } from '../../../style/constants';
import { UPSERT_PUBLIC_USER } from '../gqlQueries';
import Logo from '../../../images/clinik-note-logo-pink.svg';

const LinkPatientScreen = ({ classes, booking, user }) => {
  const [status, setStatus] = useState();
  const { patientId, type, time } = booking;

  const updatePublicUser = () => {
    setStatus('inflight');
    const variables = {
      patients: [patientId],
    };

    client
      .mutate({
        mutation: UPSERT_PUBLIC_USER,
        variables,
      })
      .then(() => {
        setStatus('complete');
      });
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.titleContainer}>
          <img src={Logo} className={classes.logo} alt="Clinik Note Logo" />
          <Typography variant="h5" component="h1">
            Booking Confirmed
          </Typography>
          <Grid className={classes.textFieldGroup} container>
            <Grid item xs={12} sm={12}>
              <Typography>
                Booking {type.desc || ''}{' '}
                <strong>{moment(time.code).format('llll')}</strong>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.fieldContainer}>
          <Grid className={classes.textFieldGroup} container>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.formText}>
                Thank you for logging in and using your Cliniknote credentials!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* Question do we need another screen  if we have this?? */}
              {status === 'complete' ? (
                <Typography>
                  Patient Details successfully saved for next time!
                </Typography>
              ) : (
                <div className={classes.buttonGroup}>
                  <Button
                    className={classes.formButton}
                    variant="contained"
                    color="secondary"
                    onClick={updatePublicUser}
                    disabled={booking.newPatient}
                  >
                    Save booking details to your account for future bookings
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const styles = {
  contentContainer: {
    padding: '0 32px 32px 32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    height: '100%',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  formContainer: {
    padding: '0 32px 32px 32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    height: '100%',
    border: 'solid 0.5px #e6e6e6',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 540,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    width: 540,
  },
  textFieldGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  formButton: {
    width: 'auto',
    height: 40,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    alignSelf: 'flex-end',
  },
  logo: {
    marginBottom: 22,
    height: 89,
    // width: '100%',
  },
  formText: {
    marginBottom: '1rem',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  field: {
    display: 'flex',
    alignContent: 'center',
    width: 253,
    height: 40,
    marginBottom: 8,
  },
};

export default compose(withStyles(styles))(LinkPatientScreen);

import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import { episodeCleared } from '../../data/redux/actions/episode';
import { formTemplateCleared } from '../../data/redux/actions/formTemplate';
import { noteCleared } from '../../data/redux/actions/note';
import { notesCleared } from '../../data/redux/actions/notes';
import { patientCleared } from '../../data/redux/actions/patient';
import { practiceSearched } from '../../data/redux/actions/practice';

const userQuery = gql`
    query userQuery {
        viewer {
            name
            id
            practice {
                name
                id
            }
            roles
            isSubscriptionValid
            isConsentFormSubscription
            location {
                id
                abn
                currency {
                    currency
                    symbol
                    formattedCurrency
                }
            }
        }
    }
`;

const DashboardPageContainer = compose(
    connect(({ user, practice }) => ({
        isClinician: user.roles.includes('CLINICIAN'),
        practiceName: practice.name,
        userId: user.id,
    })),
    graphql(userQuery, { name: 'query' }),
    withProps(({ query }) => ({
        error: query.error,
        status: {
            loading: query.networkStatus === 1,
            success: query.networkStatus === 7 && Boolean(query.viewer),
            error: query.networkStatus === 8,
        },
    })),
    withHandlers({
        onLogout:
            ({ history }) =>
            () => {
                history.push('/logout');
            },
    }),
    lifecycle({
        componentDidMount() {
            this.props.dispatch(episodeCleared());
            this.props.dispatch(formTemplateCleared());
            this.props.dispatch(noteCleared());
            this.props.dispatch(notesCleared());
            this.props.dispatch(patientCleared());
        },
        componentWillUnmount() {
            this.props.dispatch(practiceSearched(null));
        },
    })
);

export default DashboardPageContainer;

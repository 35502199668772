import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas';
import lodash from 'lodash';

import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';

import styles from './form.css';

export default class FormSignaturePad extends Component {
  state = { trimmedDataURL: '', padWidth: 600, padHeight: 300 };

  sigPad = {};

  constructor(props) {
    super(props);
    this.state = { ...props.fieldProps };
    this.props.fieldProps.onChange('');
  }

  clear = () => {
    this.sigPad.clear();
  };

  updatePadSize = () => {
    const w = document.documentElement.clientWidth || document.body.clientWidth;
    if (w <= 600) {
      this.setState({
        padWidth: 200,
        padHeight: 150,
      });
    } else if (w <= 991) {
      this.setState({
        padWidth: 400,
        padHeight: 200,
      });
    } else {
      this.setState({
        padWidth: 600,
        padHeight: 300,
      });
    }
  };

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updatePadSize();
    window.addEventListener('resize', this.updatePadSize.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePadSize.bind(this));
  }

  trim = () => {
    const trimmedUrl = lodash.clone(
      this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    );
    if (trimmedUrl) {
      this.props.fieldProps.onChange(trimmedUrl);
    }
    this.setState({ trimmedDataURL: trimmedUrl });
  };

  render() {
    const { trimmedDataURL } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.sigContainer}>
          <SignaturePad
            canvasProps={{
              width: this.state.padWidth,
              height: this.state.padHeight,
              className: 'sigCanvas',
            }}
            ref={ref => {
              this.sigPad = ref;
              if (this.props.fieldProps.readonly === true && ref) {
                ref.off();
              }
            }}
            onEnd={e => {
              const trimmedUrl = lodash.clone(
                this.sigPad.getTrimmedCanvas().toDataURL('image/png')
              );
              if (trimmedUrl) {
                this.props.fieldProps.onChange(trimmedUrl);
              }
            }}
          />
        </div>
        <div>
          <IconButton
            aria-label="delete"
            size="small"
            className={styles.buttons}
            onClick={this.clear}
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            className={styles.buttons}
            onClick={this.trim}
          >
            <DoneIcon />
          </IconButton>
        </div>

        <input
          type="hidden"
          id={this.props.fieldProps.id}
          value={trimmedDataURL}
        />
      </div>
    );
  }
}

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useMutation, useLazyQuery } from '@apollo/client';

import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import EmptyData from '../EmptyData';
import Loading from '../Loading';
// import ErrorMessage from '../ErrorMessage';
import { WARM_PINK } from '../../style/constants';
import { LIST_INVOICE_ACCOUNTS, UPDATE_PRIMARY_ACCOUNT, UPSERT_INVOICE_ACCOUNT } from './gql';
import ArchiveAccountDialog from './ArchiveAccountDialog';
import CreateAccountDialog, { INVOICE_ACCOUNT_KEYS } from './CreateAccountDialog';

const InvoiceAccountDashboard = ({ selectedPatient, practiceId, classes }) => {
    const [form, setForm] = useState({});
    const [invoiceAccounts, setInvoiceAccounts] = useState([]);
    const [archivingState, setArchivingState] = useState(false);
    const [creatingState, setCreatingState] = useState(false);
    const [editingState, setEditingState] = useState(false);
    const [selectedInvoiceAccount, setSelectedInvoiceAccount] = useState();

    const [upsertInvoiceAccount] = useMutation(UPSERT_INVOICE_ACCOUNT, {
        onCompleted: (res) => {
            if (res.upsertInvoiceAccount) {
                getAccounts();
            }
        },
    });

    const [updatePrimary] = useMutation(UPDATE_PRIMARY_ACCOUNT, {
        onCompleted: (res) => {
            if (res.updatePrimaryAccount && res.updatePrimaryAccount.success) {
                getAccounts();
            }
        },
    });

    const [getAccounts, { data, loading }] = useLazyQuery(LIST_INVOICE_ACCOUNTS, {
        variables: {
            patient: selectedPatient.id,
        },
        fetchPolicy: 'network-only',
    });

    const editElement = (element) => {
        if (element.type === 'date') {
            return (
                <TextField
                    className={classes.detailInput}
                    name={element.key}
                    autoComplete='off'
                    onChange={onFieldChange}
                    type='date'
                    disabled={!element.editable}
                    value={form[element.key] || ''}
                />
            );
        }
        return (
            <TextField
                id={element.key}
                autoComplete='off'
                name={element.key}
                className={classes.detailInput}
                placeholder={element.placeholder}
                // defaultValue={form[element.key] || ''}
                onChange={onFieldChange}
                disabled={!element.editable}
                value={form[element.key] || ''}
            />
        );
    };

    useEffect(() => {
        if (selectedInvoiceAccount) {
            setForm(selectedInvoiceAccount);
        }
    }, [selectedInvoiceAccount]);

    useEffect(() => {
        if (data && data.fetchInvoiceAccount) {
            setSelectedInvoiceAccount(data.fetchInvoiceAccount[0]);
            setInvoiceAccounts(data.fetchInvoiceAccount);
        }
    }, [data]);

    useEffect(() => {
        getAccounts();
    }, []);

    const onEdit = () => {
        setEditingState(!editingState);
        setForm(selectedInvoiceAccount);
    };

    const onSaveHandler = () => {
        upsertInvoiceAccount({
            variables: {
                practice: practiceId,
                patient: selectedPatient.id,
                ...form,
            },
        });
        setEditingState(false);
    };

    const onArchiveHandler = () => {
        upsertInvoiceAccount({
            variables: {
                id: form.id,
                practice: practiceId,
                status: form.status === 'Archived' ? 'ACTIVE' : 'ARCHIVED',
            },
        });
        setEditingState(false);
    };

    const onFieldChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const onSelect = (e) => {
        setSelectedInvoiceAccount(invoiceAccounts[e.target.value]);
    };

    const onToggleCreate = () => {
        setCreatingState(!creatingState);
        if (creatingState) {
            setForm(selectedInvoiceAccount || {});
        } else {
            setForm({});
        }
    };

    const onToggleDialog = () => {
        setArchivingState(!archivingState);
    };

    const onUpdatePrimary = () => {
        if (selectedInvoiceAccount && selectedInvoiceAccount.id && selectedPatient && selectedPatient.id) {
            updatePrimary({
                variables: {
                    id: selectedInvoiceAccount.id,
                    patientEncodedId: selectedPatient.id,
                    practiceEncodedId: practiceId,
                },
            });
        }
    };

    if (loading) return <Loading />;
    // if (error) return <ErrorMessage error={error} />;

    return (
        <>
            {editingState ? (
                <Dialog open onClose={() => setEditingState(false)} className={classes.dialogContainer}>
                    <DialogTitle id='form-dialog-title'>Edit Invoice Account</DialogTitle>

                    <DialogContent>
                        <>
                            <ListItem className={classes.detailItem}>
                                <ListItemText className={classes.detailLabel} secondary='Status' />

                                <ListItemText className={classes.detailInput} primary={form.status} />
                            </ListItem>

                            {INVOICE_ACCOUNT_KEYS.map((element) => (
                                <ListItem className={classes.detailItemEditing} key={`list-item-${element.key}`}>
                                    <ListItemText className={classes.detailLabel} secondary={element.label} />
                                    <>{editElement(element)}</>
                                </ListItem>
                            ))}
                        </>
                    </DialogContent>

                    <DialogActions>
                        <div className={classes.pullLeft}>
                            <Tooltip title='This will make this the default option when creating new Invoices' arrow>
                                <span>
                                    <Button
                                        className={classes.greyButton}
                                        color='primary'
                                        disabled={selectedInvoiceAccount.status === 'PRIMARY'}
                                        onClick={onUpdatePrimary}>
                                        Make Primary
                                    </Button>
                                </span>
                            </Tooltip>

                            <Button className={classes.greyButton} color='primary' onClick={onToggleDialog}>
                                {form.status === 'Archived' ? 'Unarchive' : 'Archive'}
                            </Button>
                        </div>
                        <Button onClick={() => setEditingState(false)} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={onSaveHandler} color='primary'>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}

            <div className={classes.subheading}>
                <Typography className={classes.subheadingText} variant='subtitle1'>
                    Invoice Accounts
                </Typography>
                <Button disabled={loading} onClick={onToggleCreate}>
                    <SpeedDialIcon />
                </Button>
            </div>
            <Paper className={classes.submissionsPaper}>
                <Grid container direction='column' justifyContent='center' wrap='nowrap' className={loading ? classes.disabled : null}>
                    <List disablePadding>
                        {selectedInvoiceAccount ? (
                            <>
                                <ListItem className={classes.buttonItem} divider disableGutters>
                                    <div className={classes.accountTypeContainer}>
                                        <Typography variant='body1'>Account Type:</Typography>
                                        <Select
                                            className={classes.selectField}
                                            defaultValue={0}
                                            onChange={onSelect}
                                            inputProps={{
                                                id: 'select-multiple-native',
                                            }}>
                                            {data.fetchInvoiceAccount.map((item, index) => {
                                                let selectLabel = `${item.billingName}`;
                                                if (item.status === 'ARCHIVED') {
                                                    selectLabel += ' (Archived)';
                                                }
                                                return (
                                                    <MenuItem key={item.id} value={index}>
                                                        {selectLabel}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div>
                                        <Button onClick={onEdit}>Edit</Button>
                                    </div>
                                </ListItem>

                                <ListItem className={classes.detailItem}>
                                    <ListItemText className={classes.detailLabel} secondary='Status' />

                                    <ListItemText className={classes.detailInput} primary={form.status} />
                                </ListItem>

                                {INVOICE_ACCOUNT_KEYS.map((element) => (
                                    <ListItem className={classes.detailItem} key={`list-item-${element.key}`}>
                                        <ListItemText className={classes.detailLabel} secondary={element.label} />

                                        <ListItemText className={classes.detailInput} primary={form[element.key]} />
                                    </ListItem>
                                ))}
                            </>
                        ) : (
                            <EmptyData text='There are no Invoice Accounts available' />
                        )}
                    </List>
                </Grid>
            </Paper>

            <ArchiveAccountDialog dialogAction={onArchiveHandler} onCloseDialog={onToggleDialog} showDialog={archivingState} />

            <CreateAccountDialog
                dialogAction={onSaveHandler}
                form={form}
                onCloseDialog={onToggleCreate}
                onFieldChange={onFieldChange}
                patientName={selectedPatient.name}
                patientId={selectedPatient.id}
                setForm={setForm}
                showDialog={creatingState}
            />
        </>
    );
};

const styles = {
    buttonItem: {
        justifyContent: 'space-between',
        padding: 4,
    },
    footerButtonItem: {
        justifyContent: 'flex-end',
        padding: 4,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    accountTypeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        marginLeft: 8,
    },
    detailItem: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    detailItemEditing: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    detailLabel: {
        flex: 0.5,
    },
    detailInput: {
        flex: 1,
        paddingLeft: 8,
    },
    redButton: {
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
    selectField: {
        marginLeft: 8,
    },
    subheading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 8,
    },
    subheadingText: {
        fontWeight: 'bold',
    },
    submissionsPaper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 36,
        width: '100%',
        overflow: 'auto',
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, patient, practice }) => ({
        // headers: practice.headers,
        isClinician: user.roles.includes('CLINICIAN'),
        patientId: patient.id,
        patientName: patient.name,
        practiceId: practice.id,
        selectedPatient: patient,
        userId: user.id,
    }))
)(InvoiceAccountDashboard);

import { branch, compose, withProps, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';

import some from 'lodash/some';
import remove from 'lodash/remove';

import { noteUpsert } from '../../data/redux/actions/note';
import { notesChecked } from '../../data/redux/actions/notes';

const groupQuery = gql`
  query group($groupId: ID!) {
    node(id: $groupId) {
      __typename
      id
      ... on Group {
        notes {
          __typename
          id
        }
      }
    }
  }
`;

const groupLimitedQuery = gql`
  query groupLimited($groupId: ID!, $limit: Int) {
    node(id: $groupId) {
      __typename
      id
      ... on Group {
        notes(limit: $limit) {
          __typename
          id
          createdAt
          title
          isSignedOff
          signedOffTime
          author {
            id
            name
          }
          patient {
            id
            name
          }
          ... on DrawingNote {
            drawingData
            template {
              id
              file {
                id
                encodedFile
              }
            }
          }
        }
      }
    }
  }
`;

const GroupDrawingNotePreviewContainer = compose(
  withApollo,
  withRouter,
  connect(({ notes, user }) => ({
    showCheckBox: notes.signOff,
    checkedNotes: notes.checkedNotes,
    userId: user.id,
    isAuth: user.roles.includes('CLINICIAN') || user.roles.includes('ADMIN'),
  })),
  graphql(groupLimitedQuery, {
    name: 'group',
    options: ({ groupId }) => ({
      variables: { groupId, limit: 3 },
    }),
  }),
  withProps(({ group }) => ({
    error: group.error,
    status: {
      loading: group.networkStatus === 1,
      success: group.networkStatus === 7 && Boolean(group.node),
      error: group.networkStatus === 8,
    },
  })),
  branch(
    ({ status }) => status.success,
    withProps(({ checkedNotes, group, userId }) => ({
      checkedNotesMatch: some(checkedNotes, {
        id: group.node.notes[0].id,
        __typename: group.node.notes[0].__typename,
      }),
      __typename: group.node.__typename,
      id: group.node.id,
      groupNotesLimited: group.node.notes,
      isAuthor: userId === group.node.notes[0].author.id,
      authorName: group.node.notes[0].author.name,
    }))
  ),
  withHandlers({
    onCheckNote: ({
      client,
      checkedNotes,
      checkedNotesMatch,
      dispatch,
      groupId,
    }) => async () => {
      const newCheckedNotes = checkedNotes.map(note => note);
      const { data } = await client.query({
        query: groupQuery,
        variables: { groupId },
      });
      const groupNotes = data.node.notes;

      if (!checkedNotesMatch) {
        groupNotes.map(note =>
          newCheckedNotes.push({ id: note.id, __typename: note.__typename })
        );
      } else {
        groupNotes.map(note =>
          remove(newCheckedNotes, {
            id: note.id,
            __typename: note.__typename,
          })
        );
      }

      dispatch(notesChecked(newCheckedNotes));
    },
  }),
  withHandlers({
    onLinkPress: ({
      dispatch,
      history,
      showCheckBox,
      onCheckNote,
      groupId,
      groupNotesLimited,
      isAuthor,
      isAuth,
    }) => async () => {
      if (showCheckBox && isAuthor && isAuth) {
        onCheckNote();
      } else if (!showCheckBox) {
        await dispatch(
          noteUpsert({
            id: groupNotesLimited[0].id,
            __typename: groupNotesLimited[0].__typename,
            title: groupNotesLimited[0].__typename,
            isSignedOff: groupNotesLimited[0].isSignedOff,
            signedOffTime: groupNotesLimited[0].signedOffTime,
            groupId,
          })
        );
        history.push(
          `/patient/${groupNotesLimited[0].patient.id}/note/drawings/${groupId}`
        );
      }
    },
  })
);

export default GroupDrawingNotePreviewContainer;

import React from 'react';
import { compose, withHandlers, branch } from 'recompose';
import { connect } from 'react-redux';

import { graphql } from '@apollo/client/react/hoc';
import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import UsersTable from '../../../components/UsersTable';
import UsersTableConsentForm from '../../../components/UsersTableConsentForm';
import UserDialog from '../../../components/UserDialog';
import { FETCH_PRACTICE } from '../gql';
import { dialogOpen } from '../../../data/redux/actions/dialog';
import CustomTooltip from '../../../components/CustomTooltip';

const tooltipTitle =
    'Click to add a Clinician. Click if you want to add a free Receptionist User. Any changes will require the User to log out and back in.';

const PracticeUsers = ({ isAdmin, classes, isConsentFormSubscription, onNewUser }) => {
    return (
        <>
            {isAdmin && (
                <React.Fragment>
                    <Grid className={classes.container}>
                        <div className={classes.headingContainer}>
                            <Typography variant='subtitle1' className={classes.subheading}>
                                Practice Users
                            </Typography>
                            <CustomTooltip hidden={!isAdmin} title={tooltipTitle} />
                        </div>
                        <Button onClick={onNewUser} variant='contained' color='secondary' className={classes.practiceButton}>
                            Add User
                        </Button>
                    </Grid>
                    {!isConsentFormSubscription && <UsersTable />}
                    {isConsentFormSubscription && <UsersTableConsentForm />}
                </React.Fragment>
            )}
            <UserDialog />
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        paddingTop: 36,
        paddingBottom: 8,
        justifyContent: 'space-between',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingBottom: 8,
    },
    subheading: {
        fontWeight: 'bold',
    },
    practiceButton: {
        height: 36,
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        isClinician: user.roles.includes('CLINICIAN'),
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isSubscriptionValid: user.isSubscriptionValid,
        practiceId: practice.id,
        userName: user.name,
        userEmail: user.email,
    })),
    branch(
        ({ practiceId }) => !!practiceId,
        graphql(FETCH_PRACTICE, {
            name: 'fetchPractice',
        })
    ),
    withHandlers({
        onChangePassword:
            ({ updateChangePasswordDialog }) =>
            () => {
                updateChangePasswordDialog(true);
            },
        onChangePasswordSuccess:
            ({ updateNotifyPasswordSuccess }) =>
            () => {
                updateNotifyPasswordSuccess(true);
                setTimeout(() => updateNotifyPasswordSuccess(false), 5000);
            },
        onNewUser:
            ({ dispatch, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                } else {
                    dispatch(dialogOpen({ type: 'user' }));
                }
            },
    })
)(PracticeUsers);

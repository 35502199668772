import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import { Button, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import _ from 'lodash';
import { ToggleButton } from '@material-ui/lab';
import client from '../../data/apollo/client';

import { WARM_PINK } from '../../style/constants';
import { FETCH_LOCATIONS } from '../../components/AppBarMenu/gql';

const fetchPracticeLocations = async (
  practiceEncodedId,
  setPracticeLocations
) => {
  const res = await client
    .query({
      query: FETCH_LOCATIONS,
      variables: {
        practiceEncodedId,
      },
      fetchPolicy: 'no-cache',
    })
    .catch(() => {
      window.location.href = '/booking-error';
    });

  const practiceLocations = _.get(res, ['data', 'fetchLocation']);

  if (practiceLocations) {
    setPracticeLocations(practiceLocations);
  }
};

const PracticeLocation = ({
  classes,
  setActiveTab,
  redScheme,
  setLocationId,
}) => {
  const { practiceId } = useParams();
  const [practiceLocations, setPracticeLocations] = useState([]);
  const [selectedPracticeLocation, setSelectedPracticeLocation] = useState(
    null
  );

  useEffect(() => {
    fetchPracticeLocations(practiceId, setPracticeLocations);
  }, []);

  return (
    <div className={classes.contentContainer}>
      <Grid className={classes.headingContent}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h5" component="h1">
            Practice Locations
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.fieldContainer}>
        <Typography className={classes.title} variant="body2">
          What is the practice location you want to attend?*
        </Typography>

        <Grid container className={classes.flexContainer}>
          {practiceLocations.map(location => (
            <div key={`togglebutton-option-${location.id}`}>
              <ToggleButton
                className={
                  redScheme ? classes.redToggleButton : classes.toggleButton
                }
                value={location.id}
                selected={selectedPracticeLocation === location.id}
                onChange={() => {
                  setSelectedPracticeLocation(location.id);
                  setLocationId(location.id);
                }}
              >
                {location.name}
              </ToggleButton>
            </div>
          ))}
        </Grid>
      </div>

      <div className={classes.fieldContainer}>
        <Button
          className={classes.nextButton}
          variant="contained"
          color="secondary"
          onClick={() => setActiveTab(1)}
          disabled={!selectedPracticeLocation}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const styles = {
  contentContainer: {
    padding: '32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    border: 'solid 1px #e6e6e6',
    background: '#ffffff',
    justifyContent: 'start',
    width: '100%',
    height: '100%',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  loginButtonContainer: {
    alignSelf: 'flex-end',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  headingContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px !important',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  nextButton: {
    width: 'auto',
    height: 40,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    alignSelf: 'flex-end',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '2rem',
    gap: '1rem',
  },
};

export default compose(withStyles(styles))(PracticeLocation);

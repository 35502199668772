import React, { useState } from 'react';
import { compose } from 'recompose';

import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import './calendarEventDetail.css';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { BOULDER, ERROR } from '../../../style/constants';
import ConfirmActionDialog from '../../../components/ConfirmActionDialog';
import { DELETE_EVENT } from './gql';

const UserEventDetail = ({
  classes,
  event,
  event: {
    id,
    title,
    start,
    end,
    clinician: { name },
  },
  setModalType,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [deleteEvent] = useMutation(DELETE_EVENT, {
    variables: {
      id,
    },
  });

  const handleDelete = () => {
    deleteEvent();
  };
  const handleEdit = () => {
    setModalType('edit');
  };
  const handleToggle = () => setModalOpen(!modalOpen);

  return (
    <>
      <Grid container item xs className={classes.title}>
        <Typography variant="h6" component="h1">
          {title}
        </Typography>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item xs={6}>
          <Typography variant="body2">Clinician</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Start</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {moment(start).format('LLLL')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">End</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{moment(end).format('LLLL')}</Typography>
        </Grid>
        <Divider />
      </Grid>
      <Grid container item xs className={classes.section}>
        <Grid container item xs className={classes.buttonSection}>
          <Button
            className={classes.deleteButton}
            variant="outlined"
            onClick={handleToggle}
          >
            Delete
          </Button>
          <Button
            className={classes.greyButton}
            variant="outlined"
            onClick={handleEdit}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      <ConfirmActionDialog
        open={modalOpen}
        text="Confirming this action will delete this block out."
        onClose={handleToggle}
        onConfirm={async () => {
          await handleDelete();
        }}
        confirmText="Delete"
      />
    </>
  );
};

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px 0 !important',
  },
  section: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: 350,
    padding: '5px 20px !important',
  },
  buttonSection: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: 400,
  },
  field: {
    justifyContent: 'start',
    '& .MuiTypography-body2:first-child': {
      color: BOULDER,
    },
  },
  deleteButton: {
    width: 144,
    height: 40,
    color: ERROR,
  },
  greyButton: {
    width: 144,
    height: 40,
  },
};

export default compose(withStyles(styles))(UserEventDetail);

import React from 'react';

import Moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Lock from '@material-ui/icons/Lock';
import { withStyles } from '@material-ui/core/styles';

const SignedOffInfo = ({ classes, signedOffTime }) => (
  <div className={classes.signedOffInfo}>
    <Typography variant="subtitle1" className={classes.signText}>
      {Moment(signedOffTime).format('llll')}
    </Typography>
    <Lock color="inherit" fontSize="inherit" className={classes.icon} />
  </div>
);

const styles = {
  signedOffInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  signText: {
    color: 'green',
    fontSize: 12,
    paddingRight: 10,
  },
  icon: {
    color: 'green',
    fontSize: 18,
  },
};

export default withStyles(styles)(SignedOffInfo);

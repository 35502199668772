import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps, withState } from 'recompose';
import { useHistory, withRouter } from 'react-router-dom';
import filter from 'lodash/filter';
import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';
import { PRIMARY } from '../../style/constants';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_FORM_NOTE } from './gql';
import queryString from 'query-string';

const FormTemplateListItem = ({ classes, expanded, updateExpanded, template, formNotePage, userId, patientId, episodeId, match }) => {
    const history = useHistory();

    const [createNewFormNote] = useMutation(CREATE_NEW_FORM_NOTE, {
        onCompleted: (res) => {
            if (res && res.createNewFormNote) {
                console.log('Successfully created new note', res.createNewFormNote);
                history.push(`/patient/${match.params.patientId}/note/form/${res.createNewFormNote.note.id}`);
            }
        },
        variables: {
            templateId: template.id,
            author: userId,
            patientId: patientId,
            episodes: [episodeId],
        },
    });

    const onTemplateSelect = async (templateId) => {
        if (!formNotePage) {
            history.push(`/formTemplate/${template.id}`);
        }

        console.log(`Creating new form note from template ${templateId}`);
        await createNewFormNote();
    };

    return (
        <Grid className={classes.wrapper}>
            <ListItem
                className={classes.listItem}
                divider
                button
                onClick={() => {
                    onTemplateSelect(template.id);
                }}
                TouchRippleProps={{ style: { color: PRIMARY } }}>
                <div className={classes.template}>
                    <ListItemText
                        className={classes.label}
                        secondary={
                            template.sections && template.sections.length !== 0
                                ? `${
                                      filter(
                                          template.sections,
                                          (item) => get(item, 'template.pageNumber') === undefined || get(item, 'template.pageNumber') <= 1
                                      ).length
                                  } sections`
                                : ''
                        }
                    />
                    <ListItemText
                        className={classes.title}
                        disableTypography
                        primary={
                            <Typography variant='subtitle1'>
                                <strong>{template.name}</strong>
                            </Typography>
                        }
                    />
                </div>
                <Collapse className={classes.collapse} in={expanded} timeout='auto' unmountOnExit>
                    <div className={classes.sectionList}>
                        {template.sections &&
                            template.sections.map((section) =>
                                (() => {
                                    if (get(section, 'template.pageNumber') > 1) return null;
                                    return (
                                        <div key={section.id} className={classes.section}>
                                            <ListItemText className={classes.label} secondary={section.type} />
                                            <ListItemText
                                                className={classes.title}
                                                disableTypography
                                                primary={<Typography variant='subtitle1'>{section.title}</Typography>}
                                            />
                                        </div>
                                    );
                                })()
                            )}
                    </div>
                </Collapse>
            </ListItem>
            {template.sections.length !== 0 && (
                <IconButton
                    className={expanded ? `${classes.expandOpen} ${classes.expand}` : classes.expand}
                    onClick={() => updateExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label='Show more'>
                    <ExpandMoreIcon />
                </IconButton>
            )}
        </Grid>
    );
};

const styles = {
    wrapper: {
        display: 'flex',
    },
    link: {
        flexGrow: 1,
        outline: 'none',
    },
    listItem: {
        paddingTop: 24,
        paddingBottom: 24,
        flexDirection: 'column',
    },
    template: {
        display: 'flex',
        width: '100%',
    },
    expand: {
        position: 'absolute',
        right: 12,
        marginTop: 12,
        transform: 'rotate(0deg)',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        width: '100%',
    },
    label: {
        flex: 0.5,
    },
    title: {
        flex: 1,
    },
    sectionList: {
        paddingTop: 12,
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
    },
};

export default compose(
    connect(({ user }) => ({
        userId: user.id,
    })),
    withRouter,
    withStyles(styles),
    withState('expanded', 'updateExpanded', false),
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
    }))
)(FormTemplateListItem);

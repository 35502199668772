import React from 'react';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import filter from 'lodash/filter';
import get from 'lodash/get';

import FormTemplateContainer from './formTemplateContainer';
import DeleteNoteDialog from '../DeleteNoteDialog';
import DrawingTemplateList from '../DrawingTemplateList';
import DuplicateDialog from '../DuplicateDialog';
import EmptyData from '../EmptyData';
import FormTemplateSection from '../FormTemplateSection';
import GroupFormTemplateSection from '../GroupFormTemplateSection';
import ReorderDialog from '../ReorderDialog';
import SectionActionBar from '../SectionActionBar';

const FormTemplate = ({
    classes,
    form,
    initialSave,
    onArchiveSection,
    onChangeGroupTitle,
    onChangeTitle,
    onCloseReorderDialog,
    onDuplicate,
    onReorderSection,
    onSave,
    onSaveSection,
    onSelectSection,
    onSelectTemplate,
    reorderDialog,
    rowsRange,
    templateDialog,
    updateForm,
    updateReorderDialog,
    updateTemplateDialog,
    isAuth,
    onArchiveTemplate,
    onChangeSectionCheckbox,
    onChangeSectionSpreadsheet,
}) => {
    return (
        <Grid container direction='column'>
            <Grid container alignItems='center' className={classes.titleRow}>
                <TextField
                    id='name'
                    autoComplete='off'
                    className={classes.inputTitle}
                    label='Title'
                    disabled={initialSave || !isAuth || (form.loading && !form.optimisticResponse)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    autoFocus={form.autoFocus === 'name'}
                    InputProps={{
                        classes: {
                            disabled: classes.disabled,
                        },
                    }}
                    placeholder='Untitled Form Template'
                    value={form.name === 'Untitled Form Template' ? '' : form.name || ''}
                    onChange={async (event) => {
                        let shouldSave = false;
                        console.log('Form name', event.target.value);
                        if (event.target.value) shouldSave = true;
                        await updateForm({
                            ...form,
                            name: event.target.value,
                            autoFocus: 'name',
                        });
                        if (shouldSave) onSave();
                    }}
                />
                <Grid className={classes.buttons}>
                    {form.loading && (
                        <Typography variant='body1' className={classes.saving}>
                            Saving ...
                        </Typography>
                    )}
                    {isAuth && <DuplicateDialog formTemplateId={form.id} loading={form.loading || !form.id} onDuplicate={onDuplicate} />}
                    {isAuth && <DeleteNoteDialog loading={form.loading} noteId={form.id} onArchiveNote={() => onArchiveTemplate()} template />}
                </Grid>
            </Grid>
            <Grid container wrap='nowrap'>
                <Paper className={classes.paper}>
                    {(() => {
                        form.sections.map((section) => {
                            return section;
                        });
                        // if (status.loading) return <Loading height={100} />;
                        // if (status.error)
                        //   return (
                        //     <ErrorMessage
                        //       containerStyle={classes.error}
                        //       text={error.message}
                        //     />
                        //   );

                        if (form.sections.length > 0 && rowsRange.length > 0)
                            return (
                                <Grid container direction='column' wrap='nowrap' justifyContent='center' className={classes.content}>
                                    {rowsRange.map((rowNumber) => (
                                        <Grid key={rowNumber} className={classes.row}>
                                            {filter(form.sections, { rowIndexes: [rowNumber] }).map((section, key) =>
                                                (() => {
                                                    if (section.pageNumber > 1) return null; // Avoid duplicate render of group sections
                                                    if (get(section, 'group.id'))
                                                        return (
                                                            <GroupFormTemplateSection
                                                                key={section.id || section.title}
                                                                isAuth={isAuth}
                                                                isLoading={form.loading}
                                                                isOptimisticResponse={form.optimisticResponse}
                                                                autoFocus={form.autoFocus}
                                                                onArchiveSection={onArchiveSection}
                                                                onChangeGroupTitle={onChangeGroupTitle}
                                                                onSave={onSave}
                                                                onSaveSection={onSaveSection}
                                                                rowNumber={rowNumber}
                                                                section={section}
                                                                updateReorderDialog={updateReorderDialog}
                                                                updateTemplateDialog={updateTemplateDialog}
                                                                onSelectSection={onSelectSection}
                                                                onFocus={() =>
                                                                    updateForm({
                                                                        ...form,
                                                                        autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
                                                                    })
                                                                }
                                                                groupSections={
                                                                    get(section, 'group.id') &&
                                                                    filter(
                                                                        form.sections,
                                                                        (item) => get(item, 'group.id') === get(section, 'group.id')
                                                                    )
                                                                }
                                                            />
                                                        );

                                                    return (
                                                        <FormTemplateSection
                                                            key={key}
                                                            isAuth={isAuth}
                                                            isLoading={form.loading}
                                                            isOptimisticResponse={form.optimisticResponse}
                                                            autoFocus={form.autoFocus}
                                                            onArchiveSection={onArchiveSection}
                                                            onChangeTitle={onChangeTitle}
                                                            onChangeSectionCheckbox={onChangeSectionCheckbox}
                                                            onChangeSectionSpreadsheet={onChangeSectionSpreadsheet}
                                                            onSave={onSave}
                                                            onSaveSection={onSaveSection}
                                                            rowNumber={rowNumber}
                                                            section={section}
                                                            updateReorderDialog={updateReorderDialog}
                                                            updateTemplateDialog={updateTemplateDialog}
                                                            onSelectSection={onSelectSection}
                                                            onFocus={() =>
                                                                updateForm({
                                                                    ...form,
                                                                    autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
                                                                })
                                                            }
                                                        />
                                                    );
                                                })()
                                            )}

                                            <Grid
                                                key={rowNumber}
                                                // className={
                                                //   includes(rowsWithImages && rowsWithImages.join(), rowsNumber)
                                                //     ? classes.sideActionBarExtended
                                                //     : classes.sideActionBar
                                                // }
                                                className={classes.sideActionBarNew}>
                                                {isAuth && (
                                                    <SectionActionBar
                                                        loading={form.loading}
                                                        direction='column'
                                                        sections={form.sections}
                                                        rowNumber={rowNumber}
                                                        disabled={initialSave}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <ReorderDialog
                                        onCloseDialog={onCloseReorderDialog}
                                        dialog={reorderDialog}
                                        rowsRange={rowsRange}
                                        sections={form.sections}
                                        onReorderSection={onReorderSection}
                                        onSaveSection={onSaveSection}
                                    />
                                    <Dialog open={templateDialog} onClose={() => updateTemplateDialog(false)} maxWidth='md' fullWidth>
                                        <DrawingTemplateList onSelectTemplate={onSelectTemplate} onClose={() => updateTemplateDialog(false)} />
                                    </Dialog>
                                </Grid>
                            );

                        return <EmptyData flex={1} text='Please add a section to this form template' />;
                    })()}
                </Paper>
            </Grid>

            {isAuth && (
                <Grid className={classes.bottomActionBar}>
                    <SectionActionBar loading={form.loading} disabled={initialSave} />
                </Grid>
            )}
        </Grid>
    );
};

const styles = {
    container: {
        width: '80%',
    },
    paper: ({ rowsRange }) => ({
        flex: rowsRange.length > 0 ? 0.95 : 1,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    }),
    content: {
        padding: 24,
        paddingTop: 8,
    },
    disabled: {
        color: 'black',
    },
    inputTitle: {
        width: '65%',
        fontWeight: 'bold',
        paddingBottom: 8,
    },
    titleRow: {
        justifyContent: 'space-between',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 18,
    },
    saving: ({ rowsRange }) => ({
        marginRight: rowsRange.length > 0 ? 8 : 8,
    }),
    error: {
        flex: 1,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    actionWrapper: {
        paddingTop: 13,
        paddingLeft: 8,
    },
    sideActionBar: {
        marginBottom: 14,
        width: 44,
    },
    sideActionBarExtended: {
        marginBottom: 220,
        width: 44,
    },
    sideActionBarNew: {
        marginBottom: 14,
        width: 200,
        position: 'absolute',
        right: '-210px',
        paddingRight: '8px',
    },
    bottomActionBar: ({ rowsRange }) => ({
        marginTop: 10,
        marginRight: rowsRange.length > 0 ? 75 : 8,
    }),
};

export default compose(FormTemplateContainer, withStyles(styles))(FormTemplate);

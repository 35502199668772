/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { compose } from 'recompose';

import { Button, Typography, MenuItem, Select, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ToggleButtonGroup from './ToggleButtonGroup';
import { WARM_PINK, BOULDER } from '../../style/constants';
import { BOOKING_STATUS } from './constants';
import DateSelector from './DateSelector';
import LoginModal from './LoginModal';
import Loading from '../../components/Loading';

const DateSelectScreen = ({
  classes,
  availTimes,
  booking,
  fetchVacanciesHandler,
  setActiveTab,
  setBooking,
  clinicianEncodedId,
  clinicianOptions,
  setClinicianEncodedId,
  showLoginDialog,
  setShowLoginDialog,
  status,
}) => {
  return (
    <>
      <div className={classes.bookingType}>
        <Typography>
          Booking type: <strong>{booking.type.desc}</strong>
        </Typography>
        <a className={classes.changeLink} onClick={() => setActiveTab(0)}>
          Change
        </a>
      </div>
      <div className={classes.contentContainer}>
        <Grid className={classes.headingContent} container>
          <Grid item xs={6} sm={6}>
            <Typography variant="h5" component="h1">
              {BOOKING_STATUS[1]}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} className={classes.loginButtonContainer}>
            <LoginModal
              booking={booking}
              setBooking={setBooking}
              setShowLoginDialog={setShowLoginDialog}
              showLoginDialog={showLoginDialog}
            />
          </Grid>
        </Grid>

        <div className={classes.selectorContainer}>
          <Select
            displayEmpty
            className={classes.field}
            value={clinicianEncodedId}
            onChange={event => {
              setClinicianEncodedId(event.target.value);
            }}
          >
            <MenuItem value="">Any Clinician</MenuItem>
            {clinicianOptions &&
              clinicianOptions.map(opts => (
                <MenuItem value={opts.id} key={`menuitem-${opts.id}`}>
                  {opts.name}
                </MenuItem>
              ))}
          </Select>

          <div className={classes.dateField}>
            <DateSelector
              booking={booking}
              fetchVacanciesHandler={fetchVacanciesHandler}
              setBooking={setBooking}
            />
          </div>
        </div>

        {availTimes &&
          availTimes.map(element => (
            <div key={`subheading-day-${element.day}`}>
              <div className={classes.greyHeader}>
                <Typography>{element.day}</Typography>
              </div>

              <ToggleButtonGroup
                optionKey="time"
                booking={booking}
                setBooking={setBooking}
                options={element.schedule}
                redScheme
              />
            </div>
          ))}

        {status === 'inflight' && <Loading />}

        {availTimes.length === 0 && !status && (
          <Typography>There are no available Dates for this selection</Typography>
        )}

        <div className={classes.buttonGroup}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="secondary"
            onClick={() => setActiveTab(0)}
          >
            Back
          </Button>

          <Button
            className={classes.nextButton}
            variant="contained"
            color="secondary"
            onClick={() => setActiveTab(3)}
            disabled={!booking.time}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

const styles = {
  contentContainer: {
    padding: '32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'start',
    border: 'solid 1px #e6e6e6',
    background: '#ffffff',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  bookingType: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  loginButtonContainer: {
    alignSelf: 'flex-end',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  headingContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px !important',
  },
  field: {
    width: '40%',
  },
  dateField: {
    width: '40%',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  changeLink: {
    color: WARM_PINK,
    cursor: 'pointer',
    marginLeft: 16,
  },
  selectorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  greyHeader: {
    marginLeft: -32,
    marginRight: -32,
    background: '#f4f4f4',
    '& p': {
      marginLeft: 32,
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  backButton: {
    width: 'auto',
    height: 40,
    color: BOULDER,
    backgroundColor: '#f5f5f5',
    alignSelf: 'flex-start',
  },
  nextButton: {
    width: 'auto',
    height: 40,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    alignSelf: 'flex-end',
  },
};

export default compose(withStyles(styles))(DateSelectScreen);

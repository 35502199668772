import React from 'react';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';

import includes from 'lodash/includes';

import Check from '@material-ui/icons/Check';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { SECONDARY } from '../../style/constants';

import EpisodeListContainer from '../EpisodeList/episodeListContainer';

import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';

const EpisodeListPopover = ({ classes, error, hasNextPage, onListEnd, patientId, episodes, status, onSelectEpisode, tempEpisodes }) => (
    <InfiniteScroll
        className={classes.infinite}
        pageStart={0}
        hasMore={hasNextPage}
        useWindow={false}
        loader={<Loading key={1} height={100} />}
        loadMore={onListEnd}>
        {(() => {
            if (status.loading || status.setVariables || status.refetching) return <Loading height='100%' />;
            if (status.error) return <ErrorMessage containerStyle={styles.errorWrapper} text={error.message} />;
            if (status.success && episodes.length === 0)
                return <EmptyData href={`/patient/${patientId}/notes?episodeId=new`} text='Please create a new episode' />;

            return (
                <List disablePadding>
                    {episodes &&
                        episodes.map((episode) => (
                            <div role='button' tabIndex={0} onKeyDown={null} onClick={() => onSelectEpisode(episode.id)} key={episode.id}>
                                <ListItem button divider className={classes.episode} TouchRippleProps={{ classes: { root: classes.ripple } }}>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography variant='body1'>
                                                <strong>{episode.name}</strong>
                                            </Typography>
                                        }
                                    />
                                    <div className={classes.episodeIcons}>
                                        {includes(tempEpisodes, episode.id) && <Check className={classes.checkIcon} />}
                                    </div>
                                </ListItem>
                            </div>
                        ))}
                </List>
            );
        })()}
    </InfiniteScroll>
);

const styles = {
    infinite: {
        height: '100%',
    },
    episode: {
        minHeight: 36,
        alignItems: 'center',
    },
    episodeIcons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
    },
    checkIcon: {
        color: SECONDARY,
    },
};

export default compose(EpisodeListContainer, withStyles(styles))(EpisodeListPopover);

import { fabric } from 'fabric-with-gestures';
import { getCanvasDimension, calDistanceBetweenPositions } from './utils';
import { PSBrush } from "@arch-inc/fabricjs-psbrush"

// reset default for fabric
fabric.Object.prototype.transparentCorners = true;
fabric.Object.prototype.selectable = false;
fabric.Object.prototype.hoverCursor = 'default';

export const DEFAULT_COLOR = '#000000';
export const DEFAULT_BRUSH_SIZE = 1;
export const CANVAS_BG_COLOR = '#ffffff';

export const EXTENDED_HEIGH = 130;
export const LINE_SPACE = 25;
export const TEXT_MARGIN = 20;

export const MAX_ZOOM = 2;
export const MIN_ZOOM = 0.5;
export const ZOOM_PERCENT = 1.2;

export const frozenImageId = 'frozenTemplateImage';
export const erasedObjectId = 'ErasedObjectId';

export const DRAWING_MODE = {
  DRAGGING: 'dragging', // no drawing
  FREE_DRAW: 'free-draw',
  CIRLE: 'cirle',
  LINE: 'line',
  TEXT: 'text',
  TEXT_DRAG: 'text-drag',
  ERASER: 'eraser',
};

/*
 * Note: Might not work with versions other than 3.1.0
 *
 * Made it so that the bound is calculated on the original only
 */
const ErasedGroup = fabric.util.createClass(fabric.Group, {
  original: null,
  erasedPath: null,
  initialize: function (original, erasedPath, options, isAlreadyGrouped) {
    this.original = original;
    this.erasedPath = erasedPath;
    this.callSuper('initialize', [this.original, this.erasedPath], options, isAlreadyGrouped);
  },

  _calcBounds: function (onlyWidthHeight) {
    const aX = [],
      aY = [],
      props = ['tr', 'br', 'bl', 'tl'],
      jLen = props.length,
      ignoreZoom = true;

    let o = this.original;
    o.setCoords(ignoreZoom);
    for (let j = 0; j < jLen; j++) {
      let prop = props[j];
      aX.push(o.oCoords[prop].x);
      aY.push(o.oCoords[prop].y);
    }

    this._getBounds(aX, aY, onlyWidthHeight);
  },
});

/*
 * Note1: Might not work with versions other than 3.1.0
 * 
 * Made it so that the path will be 'merged' with other objects 
 *  into a customized group and has a 'destination-out' composition
 */
const EraserBrush = fabric.util.createClass(fabric.PencilBrush, {

  /**
   * On mouseup after drawing the path on contextTop canvas
   * we use the points captured to create an new fabric path object
   * and add it to the fabric canvas.
   */
  _finalizeAndAddPath: function () {
    var ctx = this.canvas.contextTop;
    ctx.closePath();
    if (this.decimate) {
      this._points = this.decimatePoints(this._points, this.decimate);
    }
    var pathData = this.convertPointsToSVGPath(this._points).join('');
    if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
      // do not create 0 width/height paths, as they are
      // rendered inconsistently across browsers
      // Firefox 4, for example, renders a dot,
      // whereas Chrome 10 renders nothing
      this.canvas.requestRenderAll();
      return;
    }

    // use globalCompositeOperation to 'fake' eraser
    var path = this.createPath(pathData);
    path.stroke = '#ffffff';
    path.globalCompositeOperation = 'destination-out';
    path.selectable = false;
    path.evented = false;
    path.absolutePositioned = true;

    // grab all the objects that intersects with the path
    const objects = this.canvas.getObjects().filter((obj) => {
      if (obj instanceof fabric.Textbox) return false;
      if (obj instanceof fabric.IText) return false;
      if (obj.id && obj.id.includes('iText')) return false;
      if (!obj.intersectsWithObject(path)) return false;
      return true;
    });

    if (objects.length > 0) {
      // merge those objects into a group
      const mergedGroup = new fabric.Group(objects);

      // This will perform the actual 'erasing' 
      // NOTE: you can do this for each object, instead of doing it with a merged group
      // however, there will be a visible lag when there's many objects affected by this
      const newPath = new ErasedGroup(mergedGroup, path);

      const left = newPath.left;
      const top = newPath.top;

      // convert it into a dataURL, then back to a fabric image
      const newData = newPath.toDataURL({
        withoutTransform: true,
      });
      fabric.Image.fromURL(newData, (fabricImage) => {
        fabricImage.set({
          left: left,
          top: top,
        });

        // remove the old objects then add the new image
        this.canvas.remove(...objects);
        this.canvas.add(fabricImage);
      }, {
        id: erasedObjectId
      });
    } else {
      this.canvas.isDrawingMode = false; // To remove fake eraser 
    }

    this.canvas.clearContext(this.canvas.contextTop);
      this.canvas.renderAll();
      this._resetShadow();
  },
});
export default class DrawingCanvas {
  /**
   * Instance created by fabric.Canvas
   */
  fabricInstance = null;
  isStylusModeOn = false;
  constructor(props) {
    const { id, name, template, drawingData } = props;

    this.name = name || 'Untitled Drawing Note';
    this.fabricInstance = this.createFabricInstance({ id });
    
    this.loadDrawingData({ template, drawingData });

    // Initialize default drawing setting
    this.changeDrawingMode({
      drawingMode: DRAWING_MODE.FREE_DRAW,
      color: DEFAULT_COLOR,
      brushSize: DEFAULT_BRUSH_SIZE,
    });

    // register mouse and touch event
    this.fabricInstance.on({
      'mouse:up': this.onMouseUp,
      'mouse:move': this.onMouseMove,
      'mouse:down': this.onMouseDown,
      'touch:gesture': this.touchGesture,
      // 'touch:drag': this.touchDrag,
    })

    // register event for redo & undo
    this.fabricInstance.on('object:added', () => {
      let lastObj = this.fabricInstance._objects[this.fabricInstance._objects.length - 1];
      if (lastObj.id === erasedObjectId) {
        this.addCanvasState();
      }
    });
    // setUpZoomAndPan
    this.setUpZoomAndPan();


    //this.fabricInstance.enablePointerEvents = false;// weird trick
  }

  /**
   * Create new fabric instance
   */
  createFabricInstance = ({ id }) => {
    const { width, height } = getCanvasDimension();

    let fCanvas = new fabric.Canvas(id, {
      id,
      isDrawingMode: false,
      width,
      height,
      backgroundColor: CANVAS_BG_COLOR,
      selection: false,
      enablePointerEvents: false, // Broken finger zoom on drawing if true
    });
    //fCanvas.enablePointerEvents = false;
    return fCanvas
  };

  /**
   * Load drawing data
   */
  loadDrawingData = ({ drawingData, template }) => {
    const drawingNoteRawCanvas = drawingData
      ? JSON.parse(drawingData.replace(/(?:\r\n|\r|\n)/g, '\\r\\n'))
      : undefined;

    if (drawingNoteRawCanvas && drawingNoteRawCanvas.rawCanvasJson) {
      this.fabricInstance.loadFromJSON(
        drawingNoteRawCanvas.rawCanvasJson,
        () => {
          this.fabricInstance.setWidth(drawingNoteRawCanvas.canvasSize[0]);
          this.fabricInstance.setHeight(drawingNoteRawCanvas.canvasSize[1]);
          this.fabricInstance.renderAll();

          this.originCanvasHeight = this.fabricInstance.height;
          this.loadPredefinedSymptomsSelection();
        }
      );
    } else if (template && template.file) {
      this.addBackgroundImage(template.file.encodedFile);
    }
  };

  /**
   * Adding background Image into the canvas
   */
  addBackgroundImage = image => {
    if (!image) return;
    const canvas = this.fabricInstance;
    const templateImage = new Image();
    const center = this.fabricInstance.getCenter();

    templateImage.onload = () => {
      const fImage = new fabric.Image(templateImage, {
        id: frozenImageId,
        top: center.top,
        left: center.left,
        originX: 'center',
        originY: 'center',
      });

      const scaleRatio = Math.min(canvas.width / fImage.width, canvas.height / fImage.height)
      fImage.scale(scaleRatio);
      
      canvas.setBackgroundImage(fImage).renderAll();

      this.originCanvasHeight = canvas.height;
    };

    templateImage.src = `data:image/png;base64,${image}`;
  };

  /**
   * Change drawing mode
   */
  changeDrawingMode = ({ drawingMode, color, brushSize }) => {
    this.drawingMode = drawingMode || this.drawingMode;
    this.drawingCorlor = color || this.drawingCorlor;
    this.drawingBrushSize = brushSize || this.drawingBrushSize;

    if (this.drawingMode === DRAWING_MODE.DRAGGING) {
      this.fabricInstance.isDragging = true;
    } else {
      this.fabricInstance.isDragging = false;
    }

    
    this.completeEditingText();
    this.fabricInstance.defaultCursor = this.drawingMode === DRAWING_MODE.TEXT ? 'text' : 'default';
    let isTextDraggingMode = this.drawingMode === DRAWING_MODE.TEXT_DRAG;
    this.fabricInstance.selectable = isTextDraggingMode;
    this.fabricInstance.selection = isTextDraggingMode;
    this.fabricInstance.isDrawingMode = this.drawingMode === DRAWING_MODE.DRAWING_MODE;
    var emptyTexts = [];
    for ( let i = 0; i < this.fabricInstance._objects.length; i++) {
       let object = this.fabricInstance._objects[i];
      if (object.id && object.type.includes('i-text')) {
        if (object.text.length === 0) {
          emptyTexts.push(object);
        }
        object.evented = isTextDraggingMode;
        object.selectable = isTextDraggingMode;
        object.hasBorders = isTextDraggingMode;
        object.hasControls = isTextDraggingMode;
      }
    }

    while (emptyTexts.length > 0) {
      this.fabricInstance.remove(emptyTexts.pop());
    }

  };

  /**
   * Export canvas objects to saveble data
   */
  exportToData = selectedTemplateId => {
    const canvas = this.fabricInstance;

    // reset zoom to original
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.setZoom(1);

    const canvasData = {
      name: this.name,
      fileId: canvas.id,
      data: {
        lines: [], // an array of line
        texts: [],
        canvasSize: [canvas.width, canvas.height],
        rawCanvasJson: canvas.toJSON(['id']),
      },
      drawingNoteId: undefined,
    };

    if (selectedTemplateId) {
      canvasData.selectedTemplateId = selectedTemplateId;
    }

    return canvasData;
  };

  /* ===== DRAWING SYMPTOMS SELECTIONS ===== */

  /**
   * List of fabric object which are used to draw symptoms selection
   */
  groupOfAutogeneratedSelections = [];

  /**
   *
   */
  loadPredefinedSymptomsSelection = () => {
    this.groupOfAutogeneratedSelections = [];
    const { _objects: drawingObjects } = this.fabricInstance;

    drawingObjects.forEach(element => {
      if (element.id && element.id.includes('iText#')) {
        this.groupOfAutogeneratedSelections.push(element);
      }
    });

    if (this.groupOfAutogeneratedSelections.length > 0) {
      this.originCanvasHeight = this.fabricInstance.height - this.groupOfAutogeneratedSelections.length * 30;
    }
  };

  /**
   * Adding Text into canvas for symptoms selection
   */
  createSymptomText = (item, top) => {
    const { key, type, value } = item;
    const id = `iText#${key}#${type}`;
    let text = null;

    switch (type) {
      case 'scale': {
        const [min, max] = value;
        const valueText = min === 0 || min === max ? `${max}` : `${min}-${max}`;
        if (valueText !== '0') {
          text = `${key}: ${valueText}/${item.maxValue || 10}`;
        }
        break;
      }
      case 'text': {
        if (value.length > 0) {
          text = `${key}: ${value.toString()}`.replace(/,/g, ', ');
        }
        break;
      }
      default:
      // nothing
    }

    if (text) {
      return new fabric.IText(text, {
        id,
        fontSize: 16,
        fontWeight: 300,
        fill: '#000',
        top,
        left: TEXT_MARGIN,
      });
    }

    return null;
  };

  /**
   * Draw list of selected symptom at the end of the canvas
   */
  drawSymptomSelections = (selectedData = []) => {
    const canvasInstance = this.fabricInstance;
    const { groupOfAutogeneratedSelections, originCanvasHeight } = this;

    // position of the next symptom text
    let nextTop = originCanvasHeight + LINE_SPACE;

    // clear group
    while (this.groupOfAutogeneratedSelections.length > 0) {
      canvasInstance.remove(groupOfAutogeneratedSelections.pop());
    }

    // re-drawing all selected symptoms
    selectedData.forEach(item => {
      const textField = this.createSymptomText(item, nextTop);

      if (textField) {
        groupOfAutogeneratedSelections.push(textField);
        nextTop += LINE_SPACE;
      }
    });

    if (groupOfAutogeneratedSelections.length > 0) {
      // Add dash lines
      const dashLine = new fabric.Line(
        [
          TEXT_MARGIN,
          originCanvasHeight,
          canvasInstance.width - TEXT_MARGIN,
          originCanvasHeight,
        ],
        { id: 'iText#line', stroke: '#000000' }
      );

      groupOfAutogeneratedSelections.unshift(dashLine);
      groupOfAutogeneratedSelections.forEach(object => {
        canvasInstance.add(object);
      });

      // Add selections to canvas
      canvasInstance.setHeight(nextTop);
    } else {
      canvasInstance.setHeight(originCanvasHeight);
    }
  };
  
  updateStylusMode = (stylusModeOn) => {
    this.isStylusModeOn = stylusModeOn
  };

  /* ===== FREE DRAWING ACTIONS ===== */
  beginTouch = null
  normalBrush = null
  stylusPencilBrush = null

  startFreeDrawing = (e) => {
    //console.log(e.e.pointerType);
    //if (this.isStylusModeOn && e.e.pointerType !== "pen") {return;} // weird undefined
    if (!this.beginTouch) {
      this.beginTouch = e.e
    }
  }

  updateFreeDrawing = (e) => {
    
    if (!this.normalBrush || !this.stylusPencilBrush) {
      this.normalBrush = new fabric.PencilBrush(this.fabricInstance)
      this.stylusPencilBrush = new PSBrush(this.fabricInstance)
    }
    
    if (this.isStylusModeOn && e.e.pointerType !== 'pen') {
      this.fabricInstance.freeDrawingBrush.color = 'rgba(0, 0, 0, 0.0)';
    } else {
      this.fabricInstance.freeDrawingBrush.color = this.drawingCorlor;
    }

    //if (this.isStylusModeOn && e.e.pointerType !== "pen") {return;}
    
    if (this.fabricInstance.isDrawingMode) return; // Must have

    const distance = calDistanceBetweenPositions(e.e, this.beginTouch)
    if (distance > (this.isStylusModeOn ? 1 : 2)) {
      let customBrush = this.isStylusModeOn ? this.stylusPencilBrush : this.normalBrush;
      customBrush.width = this.drawingBrushSize * (this.isStylusModeOn ? 5 : 1);
      customBrush.color = this.drawingCorlor;
      this.fabricInstance.isDrawingMode = true;
      this.fabricInstance.freeDrawingBrush = customBrush;
      // firing mouseDown again to force fabric start drawing
      // eslint-disable-next-line no-underscore-dangle
      this.fabricInstance._onMouseDown(this.beginTouch);
    }
  }

  completeFreeDrawing = (e) => {
    this.fabricInstance.isDrawingMode = false;
    this.beginTouch = null;
  }

  /* ===== FREE ERASER ACTIONS ===== */

  startFreeEraser = (e) => {
    if (!this.beginTouch) {
      this.beginTouch = e.e
    }
  }

  updateFreeEraser = (e) => {
    if (this.fabricInstance.isDrawingMode) return;
    const distance = calDistanceBetweenPositions(e.e, this.beginTouch)
    if (distance > 2) {
      const eraserBrush = new EraserBrush(this.fabricInstance);
      eraserBrush.color = 'rgba(255, 0, 0, 0.2)'; // RED with opacity 0.2
      this.fabricInstance.freeDrawingBrush = eraserBrush;
      this.fabricInstance.isDrawingMode = true;
      this.fabricInstance.freeDrawingBrush.width = 15;

      // firing mouseDown again to force fabric start drawing
      // eslint-disable-next-line no-underscore-dangle
      this.fabricInstance._onMouseDown(this.beginTouch);
    }
  }

  completeFreeEraser = () => {
    this.fabricInstance.isDrawingMode = false;
    this.beginTouch = null;
  }
  /* ===== END ERASER ACTIONS ===== */


  /* ===== DRAGGING ACTIONS ===== */

  /**
   * use this position to calculate how far should the canvas be drag
   */
  lastDragPosition = null;

  startDragCanvas = (opt) => {
    const evt = opt.e;

    const clientX = evt.touches ? Math.round(evt.touches[0].clientX) : evt.clientX;
    const clientY = evt.touches ? Math.round(evt.touches[0].clientY) : evt.clientY;

    this.lastDragPosition = {
      x: clientX,
      y: clientY
    }
  }

  updateDragCanvas = (opt) => {
    if (!this.lastDragPosition) return;

    const evt = opt.e;
    const canvas = this.fabricInstance;

    const clientX = evt.touches ? Math.round(evt.touches[0].clientX) : evt.clientX;
    const clientY = evt.touches ? Math.round(evt.touches[0].clientY) : evt.clientY;

    if (clientX && clientY) {
      canvas.viewportTransform[4] += clientX - this.lastDragPosition.x;
      canvas.viewportTransform[5] += clientY - this.lastDragPosition.y;
      
      canvas.requestRenderAll();
  
      this.lastDragPosition = {
        x: clientX,
        y: clientY
      }
    }
  }

  completeDragCanvabs = () => {
    this.lastDragPosition = null
  }

  /* ===== DRAWING LINE ACTIONS ===== */
  /**
   * Fabric line object to be drawn into the canvas
   */
  lineToDraw = null;

  readyLineDrawing = e => {
    const pointer = e
      ? this.fabricInstance.getPointer(e.e)
      : {
          x: 0,
          y: 0,
        };

    const points = [pointer.x, pointer.y, pointer.x, pointer.y];

    this.lineToDraw = new fabric.Line(points, {
      strokeWidth: this.drawingBrushSize,
      stroke: this.drawingCorlor,
    });
  };

  startDrawingLine = e => {
    this.readyLineDrawing(e);
    this.fabricInstance.add(this.lineToDraw);
  };

  updateDrawingLine = e => {
    if (!this.lineToDraw) return;

    const pointer = this.fabricInstance.getPointer(e.e);
    this.lineToDraw.set({ x2: pointer.x, y2: pointer.y });
    this.fabricInstance.renderAll();
  };

  completeDrawingLine = () => {
    this.lineToDraw = null;
  };

  /* ===== DRAWING CIRLE ACTIONS ===== */
  /**
   * Fabric cirle object to be drawn into the canvas
   */
  circleToDraw = null;

  cirleOriginPosition = {};

  readyCircleDrawing = (e) => {
    const pointer = this.fabricInstance.getPointer(e.e);
    this.cirleOriginPosition = pointer;

    this.circleToDraw = new fabric.Ellipse({
      top: pointer.y,
      left: pointer.x,
      rx: 0,
      ry: 0,
      transparentCorners: false,
      hasBorders: false,
      hasControls: false,
      stroke: this.drawingCorlor,
      strokeWidth: this.drawingBrushSize,
      fill: null,
    });
  };

  startDrawingCirle = e => {
    this.readyCircleDrawing(e)
    this.fabricInstance.add(this.circleToDraw);
  };

  updateDrawingCirle = e => {
    if (!this.circleToDraw) return;

    const pointer = this.fabricInstance.getPointer(e.e);
    const origin = this.cirleOriginPosition;

    if (origin.x > pointer.x) {
      this.circleToDraw.set({
        left: Math.abs(pointer.x),
      });
    }
    
    if (origin.y > pointer.y) {
      this.circleToDraw.set({
        top: Math.abs(pointer.y),
      });
    }
    
    this.circleToDraw.set({
      rx: Math.abs(origin.x - pointer.x) / 2,
    });

    this.circleToDraw.set({
      ry: Math.abs(origin.y - pointer.y) / 2,
    });

    this.circleToDraw.setCoords();
    
    this.fabricInstance.renderAll();
  };

  completeDrawingCirle = () => {
    this.circleToDraw = null;
  };

  /* ===== DRAWING TEXT INPUT ===== */
  /**
   * Fabric text input which is used to draw text
   */
  textInput = null;

  readyTextInput = e => {
    const pointer = e ? this.fabricInstance.getPointer(e.e) : { x: 0, y: 0 };

    this.textInput = new fabric.IText('', {
      id: 'iTextId',
      fontSize: 16,
      fontWeight: 300,
      fill: this.drawingCorlor,
      top: pointer.y,
      left: pointer.x,
      cursorDuration: 500,
      // lockMovementX: true,
      // lockMovementY: true,
      // lockRotation: true,
      // lockUniScaling: true,
      // lockScalingY: true,
      // lockScalingX: true,
      evented: false,
      selectable: false,
    });
    this.textInput.hasControls = false;
    this.textInput.hasBorders = false;
  };

  startEditingText = e => {
    this.readyTextInput(e);
    this.fabricInstance.add(this.textInput);
    this.textInput.enterEditing();
  };

  completeEditingText = () => {
    if (!this.textInput) return;
    this.textInput.exitEditing();
    if (this.textInput.text.length === 0) {
      this.fabricInstance.remove(this.textInput);
    }    
    this.textInput = null;
  };

  /* ===== MOUSE & TOUCH EVENT LISTERNERS ===== */

  onMouseDown = e => {
    if (e.e.touches && e.e.touches.length > 1) {
      // and we don't want handle multitouch here
      return;
    }
    if (this.textInput) {
      this.completeEditingText(e);
      return;
    }

    switch (this.drawingMode) {
      case DRAWING_MODE.FREE_DRAW:
        this.startFreeDrawing(e)
        break;
      case DRAWING_MODE.DRAGGING:
        this.startDragCanvas(e);
        break;
      case DRAWING_MODE.CIRLE:
        this.startDrawingCirle(e);
        break;
      case DRAWING_MODE.LINE:
        this.startDrawingLine(e);
        break;
      case DRAWING_MODE.TEXT:
        if (!this.textInput) {
          this.startEditingText(e);
        }
        break;
      case DRAWING_MODE.ERASER:
        this.startFreeEraser(e);
        break;
      default:
      // nothing todo here
    }
  };

  onMouseUp = e => {
    if (e.e.touches && e.e.touches.length > 1) {
      return;
    }

    if (this.drawingMode !== DRAWING_MODE.ERASER) {
      this.addCanvasState();
    }

    switch (this.drawingMode) {
      case DRAWING_MODE.FREE_DRAW:
        this.completeFreeDrawing(e);
        break;
      case DRAWING_MODE.DRAGGING:
        this.completeDragCanvabs(e);
        break;
      case DRAWING_MODE.CIRLE:
        this.completeDrawingCirle(e);
        break;
      case DRAWING_MODE.LINE:
        this.completeDrawingLine(e);
        break;
      case DRAWING_MODE.TEXT:
        break;
      case DRAWING_MODE.ERASER:
        this.completeFreeEraser(e);
        break;
      default:
      //
    }
  };

  onMouseMove = e => {
    if (e.e.touches && e.e.touches.length > 1) {
      return;
    }

    switch (this.drawingMode) {
      case DRAWING_MODE.FREE_DRAW:
        this.updateFreeDrawing(e);
        break;
      case DRAWING_MODE.DRAGGING:
        this.updateDragCanvas(e);
        break;
      case DRAWING_MODE.CIRLE:
        this.updateDrawingCirle(e);
        break;
      case DRAWING_MODE.LINE:
        this.updateDrawingLine(e);
        break;
      case DRAWING_MODE.TEXT:
        break;
      case DRAWING_MODE.ERASER:
        this.updateFreeEraser(e);
        break;
      default:
      // nothing to do
    }
  };

  /* ========= UNDO & REDO  ============ */
  /**
   * flag to know if the last action was redoing
   */
  isRedoing = false;
  canvasStates = [];
  mods = 0;
  addCanvasState = () => {
    let json = this.fabricInstance.toJSON(["id"]);
    if (this.canvasStates.length > 0 && JSON.stringify(json) === JSON.stringify(this.canvasStates[this.canvasStates.length - 1])) {
      return;
    }
    this.canvasStates.push(json);
  }
  
  /**
   * Undo the last drawing action
   */
  undo = () => {
    // eslint-disable-next-line
    console.log(this.mods);

    if (this.mods < this.canvasStates.length) {
      
      // clear canvas
      const drawingObjs = this.fabricInstance._objects;
      while (drawingObjs.length > 0) {
        drawingObjs.pop()
      }
      //this.fabricInstance.renderAll();
      let data = this.canvasStates[this.canvasStates.length - 1 - this.mods - 1];
      this.fabricInstance.loadFromJSON(data);
      this.fabricInstance.renderAll();
      this.mods += 1;
    }
  };

  clearCanvas = () => {
    const drawingObjs = this.fabricInstance._objects;
    while (drawingObjs.length > 0) {
      drawingObjs.pop()
    }
    this.fabricInstance.renderAll();
    this.canvasStates = [];
    this.mods = 0;
  }

  /**
   * Redo the last drawing action
   */
  redo = () => {
    console.log(this.mods);
    if (this.mods > 0) {
      this.isRedoing = true;
      // clear canvas
      const drawingObjs = this.fabricInstance._objects;
      while (drawingObjs.length > 0) {
        drawingObjs.pop()
      }
      //this.fabricInstance.renderAll();
      let data = this.canvasStates[this.canvasStates.length - 1 - this.mods + 1];
      this.fabricInstance.loadFromJSON(data);
      this.fabricInstance.renderAll();
      this.mods -= 1;
    }
  };

  /* ============ Zoom & Pan ================ */
  setUpZoomAndPan = () => {
    this.onWheelScroll();
  };

  /**
   * Setup onWheelScroll listener
   */
  onWheelScroll = () => {
    const canvas = this.fabricInstance;

    canvas.on('mouse:wheel', opt => {
      opt.e.preventDefault();
      opt.e.stopPropagation();

      const point = new fabric.Point(opt.e.offsetX, opt.e.offsetY);
      const zoom = canvas.getZoom() + opt.e.deltaY / 1000;
      this.zoom(point, zoom);
    });
  };

  /**
   * Zoom in canvas.
   */
  zoomIn = () => {
    const canvas = this.fabricInstance;
    const point = new fabric.Point(canvas.width / 2, canvas.height / 2);
    const zoom = canvas.getZoom() * ZOOM_PERCENT;
    this.zoom(point, zoom);
  };

  /**
   * Zoom out canvas.
   */
  zoomOut = () => {
    const canvas = this.fabricInstance;
    const point = new fabric.Point(canvas.width / 2, canvas.height / 2);
    const zoom = canvas.getZoom() / ZOOM_PERCENT;
    this.zoom(point, zoom);
  };

  setPointerEvent = (enable) => {
    // Not using yet
    //console.log(enable)
    this.fabricInstance.enablePointerEvents = enable;
  }

  /**
   * canvas zoom action
   */
  zoom = (point, zoom) => {
    if (!zoom) return;

    const canvas = this.fabricInstance;
    const pt = point || new fabric.Point(canvas.width / 2, canvas.height / 2);
    let zm = zoom;

    if (zm > MAX_ZOOM) zm = MAX_ZOOM;
    if (zm < MIN_ZOOM) zm = MIN_ZOOM;

    canvas.zoomToPoint(pt, zm);
  };

  /**
   * reset canvas to normal zoom
   */
  resetZoom = () => {
    const canvas = this.fabricInstance;
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.setZoom(1);
  };

  /* ============ Gesture ================ */
  zoomStartScale = null;
  
  lastX = 0;
  
  lastY = 0;
  
  touchGesture = (e) => {
    this.beginTouch = null;
    const canvas = this.fabricInstance;

    if (e.e.touches && e.e.touches.length === 2) {
      const point = new fabric.Point(e.self.x, e.self.y);
      if (e.self.state === "start") {
          this.zoomStartScale = canvas.getZoom();
      }
      this.zoom(point, this.zoomStartScale * e.self.scale)

      // dragging
      const currentX = e.self.x;
      const currentY = e.self.y;
      const xChange = currentX - this.lastX;
      const yChange = currentY - this.lastY;

      if (
        Math.abs(xChange) <= 50 &&
        Math.abs(yChange) <= 50
      ) {
        const delta = new fabric.Point(xChange, yChange);
        canvas.relativePan(delta);
      }

      this.lastX = e.self.x;
      this.lastY = e.self.y;
      
    }
  }
}

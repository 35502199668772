import React from 'react';
import { useMutation } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import Loading from '../../components/Loading';
import { REMOVE_CONSENT_FORM } from '../ConsentFormList/gql';

const DeleteConsentFormDialog = props => {
  // const state = {};
  const {
    updateDialogOpen,
    updateSuccessMessage,
    updateListForm,
    updateError,
    dialogOpen,
    row,
    practiceId,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [removeConsentForm, { data, loading, error }] = useMutation(
    REMOVE_CONSENT_FORM,
    {
      onCompleted: result => {
        // request sent
        updateDialogOpen(false);

        if (result.removeConsentForm.success === true) {
          updateSuccessMessage('You have successfully removed the form');
          updateListForm(result.removeConsentForm.forms);
        } else {
          updateError(
            'There was an error removing the form. Please try again.'
          );
        }
      },
    }
  );

  const handleClose = () => {
    updateDialogOpen(false);
  };

  if (loading) {
    if (loading) return <Loading overlay />;
  }

  if (error) {
    // eslint-disable-next-line no-alert
    alert(error);
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      overlayStyle={{ backgroundColor: 'transparent' }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
      }}
      // disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">
        Delete Form Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this form?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            removeConsentForm({
              variables: {
                key: row.key,
                practice_id: practiceId,
              },
            });
          }}
          color="primary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConsentFormDialog;

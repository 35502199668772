import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Typography } from '@material-ui/core';
import { BLACK, SECONDARY } from '../../style/constants';

import Loading from '../Loading';
import FlashMessage from '../FlashMessage';

import './subscriptionPlans.css';

import { CANCEL_SUBSCRIPTION, CREATE_CHECKOUT_SESSION, GET_PLAN_DETAILS } from './gql';
import ConfirmActionDialog from '../ConfirmActionDialog';

const TRIAL_EXPIRED_MESSAGE = 'Trial Expired';

const SubscriptionPlans = ({ classes, isLoading, currentPlan, customerToken, isSubscriptionValid, expiry, errorMessage, isOwner, practiceId }) => {
    const [currentPlanIndex, setCurrentPlanIndex] = useState(-1);
    const [isLegacyPlan, setIsLegacyPlan] = useState(false);
    const [confirmCancelDialog, setConfirmCancelDialog] = useState(false);
    const [successfullyCancelledDialog, setSuccessfullyCancelledDialog] = useState(false);

    const planListings = [
        {
            users: 'Single User',
            price: '40',
        },
        {
            users: '2 - 5 Users',
            price: '80',
        },
        {
            users: '6 - 10 Users',
            price: '120',
        },
        {
            users: '11 - 20 Users',
            price: '200',
        },
        {
            users: '20 Users',
            price: '300',
        },
    ];

    const history = useHistory();

    const [generateCheckoutSession] = useLazyQuery(CREATE_CHECKOUT_SESSION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.createStripeCheckoutSession) {
                localStorage.setItem('checkout_session', res.createStripeCheckoutSession.id);
                history.push(window.location);
                window.location.replace(res.createStripeCheckoutSession.checkoutUrl);
            }
        },
    });

    const [cancelSubscriptionQuery] = useLazyQuery(CANCEL_SUBSCRIPTION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.cancelSubscription) {
                setSuccessfullyCancelledDialog(true);
            }
        },
    });

    const [getPlanDetails] = useLazyQuery(GET_PLAN_DETAILS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.getPlanDetails) {
                setCurrentPlanIndex(res.getPlanDetails.planIndex);
            } else {
                setCurrentPlanIndex(-1);
            }
        },
    });

    const tokenIsLegacy = (planToken) => {
        if (planToken.includes('plan_')) return true;
        if (planToken.includes('price_')) return true;

        return false;
    };

    useEffect(() => {
        getPlanDetails({
            variables: {
                planToken: currentPlan,
                practiceId: practiceId,
            },
        });

        if (currentPlan && tokenIsLegacy(currentPlan)) {
            setIsLegacyPlan(true);
        }
    }, []);

    useEffect(() => {
        if (currentPlan != null && currentPlan !== '') {
            getPlanDetails({
                variables: {
                    planToken: currentPlan,
                    practiceId: practiceId,
                },
            });
        }

        if (currentPlan && tokenIsLegacy(currentPlan)) {
            setIsLegacyPlan(true);
        }
    }, [currentPlan]);

    const startSubscription = () => {
        generateCheckoutSession({
            variables: {
                customerToken,
                practiceId,
            },
        });
    };

    const cancelSubscription = () => {
        setConfirmCancelDialog(true);
    };

    const confirmCancelSubscription = () => {
        setConfirmCancelDialog(false);
        cancelSubscriptionQuery({
            variables: {
                planToken: currentPlan,
                practiceId,
            },
        });
    };

    const renderPlan = (plan, index) => {
        if (!shouldShowPlan(index)) return null;

        return (
            <div className='col-lg-4 col-md-6' key={index}>
                <div className='plan-style-3'>
                    <div className='plan-header'>
                        <h3 className='plan-title'>{plan.users}</h3>
                        <div className='plan-price'>
                            <p className='price'>
                                <span>AUD$</span>
                                {plan.price} <span> / M</span>
                            </p>
                            <p className='price'>
                                <span>Excluding tax</span>
                            </p>
                        </div>
                    </div>
                    {planFeatures(index)}
                    {index === currentPlanIndex && currentPlan && (
                        <div className='plan-bottom'>
                            <h3 className='plan-title'>Current Plan</h3>
                        </div>
                    )}
                    {index === currentPlanIndex && !currentPlan && (
                        <div className='plan-bottom'>
                            <h3 className='plan-title'>Your Plan</h3>
                        </div>
                    )}
                    {index !== currentPlanIndex && <div className='plan-bottom'></div>}
                </div>
            </div>
        );
    };

    const shouldShowPlan = (index) => {
        if (currentPlanIndex === -1 || currentPlanIndex === 0) {
            return index <= 2;
        }

        return index >= currentPlanIndex - 1 && index <= currentPlanIndex + 1;
    };

    const planFeatures = (index) => {
        const maxIntegrations = index + 1;
        return (
            <ul className='plan-list'>
                <li key='feature1'>
                    <i className='far fa-check-circle'></i>Consent Forms
                </li>
                <li key='feature2'>
                    <i className='far fa-check-circle'></i>Patient Invoicing
                </li>
                <li key='feature3'>
                    <i className='far fa-check-circle'></i>Clinical Notes
                </li>
                <li key='feature4'>
                    <i className='far fa-check-circle'></i>Reporting
                </li>
                <li key='feature5'>
                    <i className='far fa-times-circle'></i>Online Bookings
                </li>
                <li key='feature6'>
                    <i className='far fa-times-circle'></i>
                    {maxIntegrations > 1 ? `Up to ${maxIntegrations} Mindbody Integrations` : `${maxIntegrations} Mindbody Integration`}
                </li>
            </ul>
        );
    };

    let currentCostLabel = 'Price:';
    if (!currentPlan) {
        currentCostLabel = !expiry ? 'Trial' : `Expires: ${moment(expiry).format('D MMMM YYYY')} (Plan cancelled)`;
        if (!isSubscriptionValid) currentCostLabel = TRIAL_EXPIRED_MESSAGE;
    }

    if (isLoading) return <Loading />;

    return (
        <div className={classes.wrapper}>
            {currentCostLabel === TRIAL_EXPIRED_MESSAGE && (
                <FlashMessage
                    message={
                        <div>
                            Your trial has expired. Please use the export PDF function to download your data if required.
                            <br />
                        </div>
                    }
                />
            )}

            {!isLegacyPlan && <div className='plan-row'>{planListings.map((plan, index) => renderPlan(plan, index))}</div>}

            {isLegacyPlan && (
                <Typography variant='body1' className={classes.legacySubscription}>
                    You are currently on a legacy Clinik Note subscription. <br />
                    <br />
                    We invite you to continue using Clinik Note on your existing subscription until 31st December 2023 at which time you will be
                    migrated to our new subscription plans.
                </Typography>
            )}

            {isOwner && (!currentPlan || isLegacyPlan) && (
                <div className={classes.buttonWrapper}>
                    <Button variant='contained' color='secondary' className={classes.button} onClick={startSubscription}>
                        START SUBSCRIPTION
                    </Button>
                </div>
            )}
            {isOwner && (currentPlan || isLegacyPlan) && (
                <div className={classes.buttonWrapper}>
                    <Button variant='contained' color='secondary' className={classes.button} onClick={cancelSubscription}>
                        CANCEL SUBSCRIPTION
                    </Button>
                </div>
            )}

            {errorMessage && <FlashMessage message={errorMessage} />}

            <ConfirmActionDialog
                open={confirmCancelDialog}
                text='Are you sure you want to cancel your subscription?'
                onClose={() => {
                    setConfirmCancelDialog(false);
                }}
                onConfirm={async () => {
                    await confirmCancelSubscription();
                }}
                confirmText='Yes'
            />
            <ConfirmActionDialog
                open={successfullyCancelledDialog}
                text='Your subscription has been successfully cancelled'
                onClose={() => {
                    setSuccessfullyCancelledDialog(false);
                    window.location.reload();
                }}
                onConfirm={async () => {
                    setSuccessfullyCancelledDialog(false);
                    window.location.reload();
                }}
                confirmText='Ok'
                cancelText=''
            />
        </div>
    );
};

const styles = {
    wrapper: {},
    sliderLabels: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: -12,
        marginRight: -20,
        paddingBottom: 12,
    },
    sliderLabelsButtons: {
        minWidth: 'unset',
    },
    title: {
        textAlign: 'center',
    },
    text: {
        fontSize: 14,
        textAlign: 'center',
        color: BLACK,
    },
    currentPlan: {
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 768px)': {
            flexDirection: 'row',
        },
        marginBottom: 30,
    },
    selectedPlan: {
        fontSize: 18,
        textAlign: 'center',
        color: BLACK,
        marginTop: 10,
        marginBottom: 20,
    },
    usersOver10: {
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 10,
    },
    columns: {
        flex: 1,
        width: '100%',
        padding: '0 10px',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 20,
        '@media (min-width: 768px)': {
            width: '100%',
        },
    },
    cardContent: {
        minWidth: '200px',
        minHeight: 180,
    },
    cardUsers: {
        marginTop: 20,
        fontSize: 20,
        textAlign: 'center',
    },
    cardCost: {
        marginTop: 20,
        fontSize: 18,
        textAlign: 'center',
    },
    arrowContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    arrow: {
        fontSize: 38,
    },
    costTextSmall: {
        fontSize: 13,
    },
    costTextNormal: {
        // fontSize: 13
    },
    renewLine: {
        fontSize: 12,
    },
    link: {
        color: SECONDARY,
    },
    legacySubscription: {
        marginLeft: 40,
        marginRight: 40,
        textAlign: 'center',
        marginBottom: 40,
        textSize: 14,
    },
    buttonWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        alignSelf: 'center',
        justifySelf: 'center',
    },
};

SubscriptionPlans.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    currentPlan: PropTypes.string,
    expiry: PropTypes.string,
    isSubscriptionValid: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    errorMessage: PropTypes.element,
};

SubscriptionPlans.defaultProps = {
    currentPlan: '',
    expiry: null,
    isSubscriptionValid: false,
    errorMessage: null,
};

export default compose(
    withStyles(styles),
    connect(({ user, practice }) => ({
        isOwner: user.roles.includes('OWNER'),
        practiceId: practice.id,
    }))
)(SubscriptionPlans);

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';

import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { daysOfWeek } from './constants';
import AvailabilityIcon from './AvailabilityIcon';
import UpdateAvailabilityDialog from './UpdateAvailabilityDialog';
import { WARM_PINK } from '../../style/constants';
import FETCH_CLINICIAN from './gql';
import CustomTooltip from '../CustomTooltip';

const TITLE =
  'Click on a Clinician to assign days and times available. Only Admins can edit other Clinicians.';

const parseClinicians = list => {
  return list.map(clinician => ({
    ...clinician,
    blockedOut: JSON.parse(clinician.blockedOut),
  }));
};

const ClinicianAvailabilityTable = ({
  classes,
  isAdmin,
  locationId,
  locationName,
  userId,
}) => {
  const [clinicians, setClinicians] = useState([]);
  const [dialogFocus, setDialogFocus] = useState();

  useQuery(FETCH_CLINICIAN, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.fetchClinician) {
        setClinicians(parseClinicians(res.fetchClinician));
      }
    },
    variables: {
      locationId,
    },
  });

  return (
    <div className={classes.main}>
      <div className={classes.headingContainer}>
        <Typography variant="subtitle1" className={classes.subheading}>
          Availability - {locationName}
        </Typography>
        <CustomTooltip title={TITLE} />
      </div>
      <Grid className={classes.subSection}>
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Clinician</TableCell>
                <TableCell align="left">Days Available</TableCell>
                <TableCell align="left">Blocked out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clinicians &&
                clinicians.map(item => {
                  const { id, name, availability, blockedOut } = item;
                  // Only allow admins to edit availability for other clinicians
                  const handleRowClick = () => {
                    if (id === userId || isAdmin) {
                      setDialogFocus(item);
                    }
                  };

                  return (
                    <TableRow
                      hover={id === userId || isAdmin}
                      onClick={handleRowClick}
                      key={`table-row-${id}`}
                    >
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <div className={classes.availabilityContainer}>
                          {daysOfWeek.map(day => (
                            <AvailabilityIcon
                              available={
                                availability && availability.includes(day.id)
                                  ? 'true'
                                  : null
                              }
                              key={`availability-icon-${day.id}`}
                            >
                              {day.icon}
                            </AvailabilityIcon>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        {Array.isArray(blockedOut) &&
                          blockedOut.map(
                            (period, index) =>
                              `${(index ? ', ' : '') + period.start} - ${
                                period.end
                              }`
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {dialogFocus && (
          <UpdateAvailabilityDialog
            existingClinician={dialogFocus}
            onCloseDialog={() => setDialogFocus()}
            showDialog={!!dialogFocus}
          />
        )}
      </Grid>
    </div>
  );
};

const styles = {
  main: {
    width: '100%',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 36,
    paddingBottom: 8,
  },
  subheading: {
    fontWeight: 'bold',
  },
  redButton: {
    height: 36,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
  container: {
    background: 'white !important',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  availabilityContainer: {
    display: 'flex',
  },
};

export default compose(
  withStyles(styles),
  connect(({ user }) => ({
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
    locationId: user.currentLocationId,
    locationName: user.currentLocationName,
    userId: user.id,
  }))
)(ClinicianAvailabilityTable);

import { PATIENT_CLEARED, PATIENT_SELECTED } from '../actions/patient';

const patientDefaultState = {
    id: undefined,
    name: undefined,
    alerts: undefined,
};

const patientReducer = (state = patientDefaultState, action) => {
    switch (action.type) {
        case PATIENT_SELECTED:
            return {
                id: action.id,
                name: action.name,
                dob: action.dob,
                alerts: action.alerts,
            };
        case PATIENT_CLEARED:
            return {
                id: undefined,
                name: undefined,
                alerts: undefined,
            };
        default:
            return state;
    }
};

export default patientReducer;

import { gql } from '@apollo/client';

export const FETCH_LOCATIONS = gql`
    query fetchLocation($practiceEncodedId: ID!) {
        fetchLocation(practiceEncodedId: $practiceEncodedId) {
            id
            appointmentTimeSlot
            name
            paymentLine1
            paymentLine2
            paymentLine3
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            publicHolidays
            integration {
                id
            }
        }
    }
`;

export const CHECK_SITEID_VALIDITY = gql`
    query checkSiteIdValidity($encodedPracticeId: ID!, $siteId: String) {
        checkSiteIdValidity(siteId: $siteId, encodedPracticeId: $encodedPracticeId) {
            valid
        }
    }
`;

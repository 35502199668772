import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

import { FETCH_LOCATIONS, GET_PRACTICE_SUBSCRIPTION, OBLITERATE_INTEGRATION_DATA_FOR_PRACTICE } from './gql';
import { store } from '../../data/redux/store';
import { SECONDARY_BLACK } from '../../style/constants';

import IntegrationTableContainer from './integrationTableContainer';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import {
    CompleteIntegrationDialog,
    IntegrationErrorDialog,
    MindbodySiteCodeIntegrationDialog,
    RemoveIntegrationDialog,
    RemoveIntegrationSuccessDialog,
    StartIntegrationDialog,
} from '../IntegrationsDialogs';
import IntegrationService from '../../services/IntegrationService';
import { practiceSelected } from '../../data/redux/actions/practice';

const columnData = [
    { id: 'name', checkbox: false, label: 'Name' },
    { id: 'status', checkbox: true, label: 'Status' },
];

const IntegrationTable = ({ classes, status, error, practiceEncodedId, isOwner }) => {
    const [dialogState, setDialogState] = useState('');
    const [siteCode, setSiteCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [mindbodyLocations, setMindbodyLocations] = useState([]);
    const [clinikNoteLocations, setClinikNoteLocations] = useState([]);
    const [currentIntegrationId, setCurrentIntegrationId] = useState('');
    const [currentLocationId, setCurrentLocationId] = useState('');
    const [dialogErrorText, setDialogErrorText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [integrationButtonActive, setIntegrationButtonActive] = useState([]);
    const [availableMindbodyLocations, setAvailableMindbodyLocations] = useState([]);
    const [stripeSubscription, setStripeSubscription] = useState(null);
    const urlLocation = useLocation();
    const dispatch = useDispatch();

    const { REACT_APP_SHOW_ADMIN_FUNCTIONS } = process.env;

    const [getLocations] = useLazyQuery(FETCH_LOCATIONS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.fetchLocation) {
                const locations = res.fetchLocation;
                setIntegrationButtonActive(new Array(locations.length).fill(true));
                setClinikNoteLocations(
                    _.sortBy(locations, function sortByName(location) {
                        return location.name.toLowerCase();
                    })
                );
                dispatch(
                    practiceSelected({
                        locations: res.fetchLocation,
                    })
                );
                setLoading(false);
            }
        },
        variables: {
            practiceEncodedId,
        },
    });

    const [getPracticeSubscription] = useLazyQuery(GET_PRACTICE_SUBSCRIPTION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.getPracticeSubscription) {
                setStripeSubscription(res.getPracticeSubscription.stripeSubscription);
                setLoading(false);
            }
        },
        variables: {
            practiceId: practiceEncodedId,
        },
    });

    const [obliterateIntegrationData] = useMutation(OBLITERATE_INTEGRATION_DATA_FOR_PRACTICE, {
        onCompleted: (res) => {
            if (res.obliterateIntegrationDataForPractice && res.obliterateIntegrationDataForPractice.success) {
                window.location.reload();
            }
        },
        variables: {
            encodedPracticeId: practiceEncodedId,
        },
    });

    useEffect(() => {
        getLocations().then(() => {});
        getPracticeSubscription().then(() => {});
    }, []);
    useEffect(() => {
        getAvailableMindbodyLocations();
        checkForCompleteIntegrationDeepLink();
        checkPollingStatus();
    }, [clinikNoteLocations]);

    const checkForCompleteIntegrationDeepLink = () => {
        if (clinikNoteLocations != null && clinikNoteLocations.some((location) => location.status === 'Activation code generated')) {
            const queryString = urlLocation.search;
            if (queryString.includes('completeIntegration')) {
                // Get the integration we are completing
                const urlSearchParams = new URLSearchParams(urlLocation.search);
                const integrationId = urlSearchParams.get('id');
                completeIntegration(integrationId).then(() => {});
            }
        }
    };

    const startIntegrationClick = async (locationId) => {
        const state = store.getState();
        setCurrentLocationId(locationId);

        if (availableMindbodyLocations !== null && availableMindbodyLocations.length === 0) {
            setDialogErrorText(
                'There are no available locations to integrate. Please ensure a non-integrated location exists within Mindbody and try again.'
            );
            setDialogState('error');
            return;
        }

        if (availableMindbodyLocations !== null && availableMindbodyLocations.length > 0 && hasExistingIntegration()) {
            setSiteCode(getExistingIntegrationSiteId());
            const createIntegrationResult = await IntegrationService.CreateIntegration(siteCode, state.user.email, state.practice.id, locationId);
            setCurrentIntegrationId(createIntegrationResult.integrationId);
            setMindbodyLocations(availableMindbodyLocations);
            setDialogState('completeIntegration');
            return;
        }

        if (availableMindbodyLocations == null && hasExistingIntegration()) {
            setDialogErrorText('Error occurred contacting Mindbody. Please wait a moment and try again.');
            setDialogState('error');
            return;
        }

        setDialogState('startIntegration');
    };

    const createIntegration = async () => {
        const state = store.getState();
        const createIntegrationResult = await IntegrationService.CreateIntegration(siteCode, state.user.email, state.practice.id, currentLocationId);
        const activationCodeResult = await sendActivationCode(createIntegrationResult.integrationId);

        if (activationCodeResult === false) {
            setDialogErrorText('Something went wrong starting your integration. Please try again.');
            setDialogState('error');
        } else {
            setDialogState('continueIntegration');
        }

        await getIntegrations();
    };

    const sendActivationCode = async (integrationId) => {
        const result = await IntegrationService.SendActivationEmail(integrationId);

        if (result === false) {
            setDialogErrorText('Something went wrong starting your integration. Please try again.');
            setDialogState('error');
        } else {
            await getIntegrations();
        }
    };

    const removeIntegrationConfirm = async (integrationId, index) => {
        setIntegrationButtonActive((prevState) => {
            prevState[index] = false;
            return prevState;
        });
        setCurrentIndex(index);
        setCurrentIntegrationId(integrationId);
        setDialogState('removeIntegration');
    };

    const removeIntegrationCancel = () => {
        setIntegrationButtonActive((prevState) => {
            prevState[currentIndex] = true;
            return prevState;
        });
    };

    const clearIntegrationData = async () => {
        obliterateIntegrationData();
    };

    const removeIntegration = async () => {
        const result = await IntegrationService.RemoveMindbodyIntegration(currentIntegrationId);

        if (result === false) {
            setDialogErrorText('Something went wrong removing your integration. Please try again.');
            setDialogState('error');
            setIntegrationButtonActive((prevState) => {
                prevState[currentIndex] = true;
                return prevState;
            });
        } else {
            setDialogState('removeIntegrationSuccess');
            setIntegrationButtonActive((prevState) => {
                prevState[currentIndex] = true;
                return prevState;
            });
        }

        await getIntegrations();
    };

    const saveLocation = async (location) => {
        await IntegrationService.InitiateSync(currentIntegrationId, location.id);
    };

    const completeIntegration = async (integrationId, index) => {
        setCurrentIndex(index);
        setCurrentIntegrationId(integrationId);
        setIntegrationButtonActive((prevState) => {
            prevState[currentIndex] = false;
            return prevState;
        });
        const availableMindbodyLocationsToComplete = await IntegrationService.CompleteMindbodyIntegration(integrationId);

        if (availableMindbodyLocationsToComplete == null) {
            setDialogErrorText('Something went wrong completing your integration. Please try again.');
            setDialogState('error');
            setIntegrationButtonActive((prevState) => {
                prevState[currentIndex] = true;
                return prevState;
            });
        } else {
            setMindbodyLocations(availableMindbodyLocationsToComplete);
            setDialogState('completeIntegration');
            setIntegrationButtonActive((prevState) => {
                prevState[currentIndex] = true;
                return prevState;
            });
        }
    };

    const getIntegrations = async () => {
        await getLocations();
    };

    const getAvailableMindbodyLocations = async () => {
        if (hasExistingIntegration()) {
            const siteId = getExistingIntegrationSiteId();
            const locations = await IntegrationService.GetAvailableLocations(siteId);

            setAvailableMindbodyLocations(locations);
            return;
        }

        setAvailableMindbodyLocations(null);
    };

    const hasExistingIntegration = () => {
        if (clinikNoteLocations.length > 0) {
            for (let i = 0; i < clinikNoteLocations.length; i += 1) {
                if (clinikNoteLocations[i].integration != null) {
                    setSiteCode(clinikNoteLocations[i].integration.siteId);
                    return true;
                }
            }
        }

        return false;
    };

    const getExistingIntegrationSiteId = () => {
        if (clinikNoteLocations.length > 0) {
            for (let i = 0; i < clinikNoteLocations.length; i += 1) {
                if (clinikNoteLocations[i].integration != null) {
                    return clinikNoteLocations[i].integration.siteId;
                }
            }
        }
        return null;
    };

    const checkPollingStatus = () => {
        // We want to check the status, if we aren't in a success or failed status, we will poll for an update
        if (
            clinikNoteLocations !== null &&
            clinikNoteLocations.some(
                (location) =>
                    location.integration != null &&
                    location.integration.status != null &&
                    location.integration.status.toLowerCase().includes('in progress')
            )
        ) {
            setTimeout(async () => {
                await pollIntegrationStatus();
            }, 1000);
        }
    };

    const pollIntegrationStatus = async () => {
        await getIntegrations();
    };

    const tokenIsLegacy = (planToken) => {
        if (planToken.includes('plan_')) return true;
        if (planToken.includes('price_')) return true;

        return false;
    };

    const renderIntegrationRow = (location, index) => {
        if (!stripeSubscription || !stripeSubscription.planToken || stripeSubscription.planToken === '') {
            return (
                <div>
                    <Typography variant='body1' color='error'>
                        You must have an active subscription to integrate with Mindbody
                    </Typography>
                    <Typography variant='body1' color='error'>
                        You can manage your subscription <a href='/subscription'>here</a>
                    </Typography>
                </div>
            );
        }

        if (tokenIsLegacy(stripeSubscription.planToken)) {
            return (
                <Typography variant='body1' color='error'>
                    Cancel your legacy plan and re-subscribe to access MindBody Integration
                </Typography>
            );
        }

        if (location.integration == null && isOwner) {
            return (
                <Button
                    onClick={() => {
                        startIntegrationClick(location.id);
                    }}
                    variant='contained'
                    color='secondary'
                    className={classes.integrationButton}>
                    Start Integration
                </Button>
            );
        }

        if (location.integration == null && !isOwner) {
            return <Typography variant='body1'>No active integration</Typography>;
        }

        if (loading) {
            return <Typography variant='body1'>Loading...</Typography>;
        }

        if (location.integration !== null && !location.integration.status) {
            return <Typography variant='body1'>Status Unknown</Typography>;
        }

        if (location.integration !== null && location.integration.status === 'Activation code generated' && isOwner) {
            return (
                <Button
                    onClick={() => {
                        completeIntegration(location.integration.id, index).then(() => {});
                    }}
                    variant='contained'
                    color='secondary'
                    className={classes.integrationButton}
                    disabled={!integrationButtonActive[index]}>
                    Complete Integration
                </Button>
            );
        }

        if (location.integration !== null && location.integration.status === 'Activation code generated' && !isOwner) {
            return <Typography variant='body1'>Awaiting activation within Mindbody</Typography>;
        }

        if (location.integration !== null && location.integration.status === 'Preparing Sync') {
            return <Typography variant='body1'>Preparing sync...</Typography>;
        }

        if (location.integration !== null && location.integration.status === 'Sync In Progress') {
            return (
                <Typography variant='caption'>
                    <Typography variant='body1'>Syncing data...</Typography>
                    <LinearProgress variant='determinate' value={75} className={classes.progressBar} />
                </Typography>
            );
        }

        if (location.integration !== null && location.integration.status && location.integration.status.toLowerCase().includes('fail')) {
            return (
                <div>
                    <Typography variant='caption'>
                        <Typography variant='body1'>Something went wrong during your integration</Typography>
                    </Typography>
                    <Button
                        onClick={() => {
                            removeIntegrationConfirm(location.integration.id, index).then(() => {});
                        }}
                        variant='contained'
                        color='secondary'
                        className={classes.integrationButton}
                        disabled={!integrationButtonActive[index]}>
                        Remove Integration
                    </Button>
                </div>
            );
        }

        if (
            location.integration !== null &&
            location.integration.status !== 'Preparing Sync' &&
            location.integration.status !== 'Sync In Progress' &&
            isOwner
        ) {
            return (
                <Button
                    onClick={() => {
                        removeIntegrationConfirm(location.integration.id, index).then(() => {});
                    }}
                    variant='contained'
                    color='secondary'
                    className={classes.integrationButton}
                    disabled={!integrationButtonActive[index]}>
                    Remove Integration
                </Button>
            );
        }

        if (
            location.integration !== null &&
            location.integration.status !== 'Preparing Sync' &&
            location.integration.status !== 'Sync In Progress' &&
            !isOwner
        ) {
            return <Typography variant='body1'>Integration active</Typography>;
        }

        return <Typography variant='body1'>Status Unknown</Typography>;
    };

    return (
        <Paper className={classes.paper}>
            {(() => {
                if (status.loading) return <Loading height={105} />;
                if (status.error) return <ErrorMessage error={error} />;

                return (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby='tableTitle'>
                            <TableHead>
                                <TableRow>
                                    {columnData.map((column) => (
                                        <TableCell key={column.id}>{column.label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clinikNoteLocations.map((location, index) => (
                                    <TableRow hover className={classes.tableRow} key={location.id}>
                                        <TableCell component='th' scope='row' width='50%'>
                                            <Typography variant='body1'>{location.name}</Typography>
                                        </TableCell>
                                        <TableCell width='50%'>{renderIntegrationRow(location, index)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        {REACT_APP_SHOW_ADMIN_FUNCTIONS === 'true' && (
                            <Button onClick={clearIntegrationData} variant='contained' color='secondary' className={classes.clearIntegrationButton}>
                                Clear Integration Data
                            </Button>
                        )}

                        <MindbodySiteCodeIntegrationDialog
                            practiceEncodedId={practiceEncodedId}
                            onCloseDialog={async (result) => {
                                if (result === 'ok') {
                                    await createIntegration();
                                } else {
                                    setDialogState('');
                                }
                            }}
                            showDialog={dialogState === 'startIntegration'}
                            siteCodeChange={(newSiteCode) => {
                                setSiteCode(newSiteCode);
                            }}
                        />
                        <StartIntegrationDialog onCloseDialog={() => setDialogState('')} showDialog={dialogState === 'continueIntegration'} />
                        <CompleteIntegrationDialog
                            onCloseDialog={() => setDialogState('')}
                            saveMindbodyLocation={(location) => saveLocation(location)}
                            showDialog={dialogState === 'completeIntegration'}
                            locations={mindbodyLocations}
                        />
                        <RemoveIntegrationDialog
                            onCloseDialog={(result) => {
                                if (result === 'ok') {
                                    removeIntegration().then(() => {});
                                    setDialogState('');
                                    return;
                                }
                                setDialogState('');
                                removeIntegrationCancel();
                            }}
                            showDialog={dialogState === 'removeIntegration'}
                        />
                        <RemoveIntegrationSuccessDialog
                            onCloseDialog={() => {
                                setDialogState('');
                            }}
                            showDialog={dialogState === 'removeIntegrationSuccess'}
                        />
                        <IntegrationErrorDialog
                            onCloseDialog={() => {
                                setDialogState('');
                            }}
                            showDialog={dialogState === 'error'}
                            dialogText={dialogErrorText}
                        />
                    </div>
                );
            })()}
        </Paper>
    );
};

const styles = {
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeaderCheckbox: {
        padding: 16,
    },
    tableRow: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverPaper: {
        padding: 12,
    },
    popoverInfo: {
        color: SECONDARY_BLACK,
        fontSize: 12,
    },
    permissionInfo: {
        marginLeft: 8,
    },
    integrationButton: {
        marginLeft: 8,
        marginRight: 8,
    },
    tableHeader: {
        textAlign: 'center',
    },
    progressBar: {
        width: '200px',
    },
    logo: {
        height: '20px',
    },
    clearIntegrationButton: {
        marginTop: 20,
        marginBottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, practice }) => ({
        practiceEncodedId: practice.id,
        isOwner: user.roles.includes('OWNER'),
    })),
    IntegrationTableContainer
)(IntegrationTable);

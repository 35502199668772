import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

const EmptyData = ({ classes, href, text }) => (
  <div className={classes.wrapper}>
    {href ? (
      <Link href={href} rel="noreferrer" target="_blank">
        <div className={classes.link}>
          {text} <ArrowRightIcon />
        </div>
      </Link>
    ) : (
      <Typography variant="subtitle1" className={classes.text}>
        {text}
      </Typography>
    )}
  </div>
);

const styles = {
  wrapper: {
    minHeight: ({ minHeight }) => minHeight || 100,
    display: 'flex',
    flex: ({ flex }) => flex,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    color: 'lightgray',
  },
};

export default withStyles(styles)(EmptyData);

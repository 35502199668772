import React, { Component } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import BrushIcon from '@material-ui/icons/Brush';
import GestureIcon from '@material-ui/icons/Gesture';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import PanToolIcon from '@material-ui/icons/PanTool';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faICursor } from '@fortawesome/free-solid-svg-icons';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import Tooltip from '@material-ui/core/Tooltip';

import ColorPicker from './ColorPicker';
import styles from './styles';
import {
  DRAWING_MODE,
  DEFAULT_COLOR,
  DEFAULT_BRUSH_SIZE,
} from './DrawingCanvasClass';

const MenuItemStyled = withStyles({
  root: {
    paddingTop: '15px',
    paddingBottom: '15px',
    justifyContent: 'center',
  },
})(MenuItem);

const MenuWrapper = withStyles({
  root: {
    display: props => (props.hidden ? 'none' : 'inherit'),
    marginTop: '5px',
  },
})(Paper);

class BrushSize extends React.Component {
  handleBrushSizeChange = (event, newValue) => {
    const { onBrushSizeChange } = this.props;
    onBrushSizeChange(newValue);
  };

  render() {
    const { value } = this.props;

    return (
      <MenuWrapper>
        <MenuList>
          <MenuItemStyled>{value}</MenuItemStyled>
          <Divider />
          <MenuItemStyled>
            <div style={{ height: 150 }}>
              <Slider
                value={value}
                onChange={this.handleBrushSizeChange}
                min={1}
                max={10}
                orientation="vertical"
                aria-labelledby="brush-size"
              />
            </div>
          </MenuItemStyled>
        </MenuList>
      </MenuWrapper>
    );
  }
}

class TextTool extends Component {
  handleTextDraggingSelected = event => {
    const { onTextDraggingSelected } = this.props;
    onTextDraggingSelected();
  };

  handleAddTextSelected = event => {
    const { onAddTextSelected } = this.props;
    onAddTextSelected();
  };

  render() {
    return (
      <MenuWrapper>
        <MenuList>
          <Tooltip title="Type text" placement="left">
            <MenuItemStyled onClick={this.handleAddTextSelected}>
              <FontAwesomeIcon icon={faICursor} />
            </MenuItemStyled>
          </Tooltip>

          <Divider />

          <Tooltip title="Text box select" placement="left">
            <MenuItemStyled onClick={this.handleTextDraggingSelected}>
              <OpenWithIcon />
            </MenuItemStyled>
          </Tooltip>
        </MenuList>
      </MenuWrapper>
    );
  }
}

export default class DrawingMenu extends Component {
  state = {
    isMenuVisible: true,
    isBrushSizeVisible: false,
    isTextToolVisible: false,
    isColorPickerVisible: false,
    selectedColor: DEFAULT_COLOR,
    selectedBrushSize: DEFAULT_BRUSH_SIZE,
    drawingMode: DRAWING_MODE.FREE_DRAW,
  };

  handleAnchorClick = e => {
    const { isMenuVisible } = this.state;
    this.setState({
      isMenuVisible: !isMenuVisible,
    });
  };

  handlePanToolClick = e => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: false,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.DRAGGING,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.DRAGGING,
    });
  };

  handlePenClick = e => {
    const { isBrushSizeVisible } = this.state;
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: !isBrushSizeVisible,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.FREE_DRAW,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.FREE_DRAW,
    });
  };

  handleEraserClick = e => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: false,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.ERASER,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.ERASER,
    });
  };

  handleCirleClick = () => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: false,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.CIRLE,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.CIRLE,
    });
  };

  handleLineClick = () => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: false,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.LINE,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.LINE,
    });
  };

  handleTextToolClick = () => {
    const { isTextToolVisible } = this.state;

    this.setState({
      isTextToolVisible: !isTextToolVisible,
    });
  };

  handleAddTextClick = () => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: false,
      isTextToolVisible: false,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.TEXT,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.TEXT,
    });
  };

  handleAllowTextDragging = () => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      isBrushSizeVisible: false,
      isTextToolVisible: false,
      isColorPickerVisible: false,
      drawingMode: DRAWING_MODE.TEXT_DRAG,
    });

    onDrawingModeChange({
      drawingMode: DRAWING_MODE.TEXT_DRAG,
    });
  };

  handleColorPickerClick = () => {
    const { isColorPickerVisible } = this.state;

    this.setState({
      isBrushSizeVisible: false,
      isColorPickerVisible: !isColorPickerVisible,
    });
  };

  handleColorChange = color => {
    const { onDrawingModeChange } = this.props;

    this.setState({
      selectedColor: color.hex,
    });

    onDrawingModeChange({
      color: color.hex,
    });
  };

  handleBrushSizeChange = newValue => {
    const { onDrawingModeChange } = this.props;
    this.setState({
      selectedBrushSize: newValue,
    });

    onDrawingModeChange({
      brushSize: newValue,
    });
  };

  render() {
    const {
      isMenuVisible,
      isBrushSizeVisible,
      isTextToolVisible,
      isColorPickerVisible,
      selectedColor,
      selectedBrushSize,
      drawingMode,
    } = this.state;

    const btnSize = isMobileOnly ? 'small' : 'medium';
    const iconSize = 'large';

    return (
      <React.Fragment>
        <div id="drawing-menu" style={styles.toolbarContainer}>
          <Tooltip title="Collapse / expand" placement="left">
            <Button
              onClick={this.handleAnchorClick}
              variant="outlined"
              size={btnSize}
            >
              <BrushIcon fontSize={iconSize} />
            </Button>
          </Tooltip>
          {isMenuVisible && (
            <MenuWrapper>
              <MenuList>
                <Tooltip title="Pan" placement="left">
                  <MenuItemStyled
                    onClick={this.handlePanToolClick}
                    selected={drawingMode === DRAWING_MODE.DRAGGING}
                  >
                    <PanToolIcon />
                  </MenuItemStyled>
                </Tooltip>

                <Tooltip title="Drawing pen width" placement="left">
                  <MenuItemStyled
                    onClick={this.handlePenClick}
                    selected={drawingMode === DRAWING_MODE.FREE_DRAW}
                  >
                    <GestureIcon />
                  </MenuItemStyled>
                </Tooltip>

                <Tooltip title="Eraser" placement="left">
                  <MenuItemStyled
                    onClick={this.handleEraserClick}
                    selected={drawingMode === DRAWING_MODE.ERASER}
                  >
                    <FontAwesomeIcon icon={faEraser} />
                  </MenuItemStyled>
                </Tooltip>

                <Tooltip title="Colour" placement="left">
                  <MenuItemStyled
                    onClick={this.handleColorPickerClick}
                    selected={isColorPickerVisible}
                    style={styles.colorPickerContainer}
                  >
                    <input
                      disabled
                      type="color"
                      value={selectedColor}
                      id="drawing-color"
                      style={{
                        width: '24px',
                        height: '24px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '4px',
                        padding: '2px',
                        pointerEvents: 'none',
                      }}
                    />
                  </MenuItemStyled>
                </Tooltip>

                <Tooltip title="Text" placement="left">
                  <MenuItemStyled
                    id="add-textbox-canvas"
                    onClick={this.handleTextToolClick}
                    selected={drawingMode === DRAWING_MODE.TEXT}
                  >
                    <TextFieldsIcon />
                  </MenuItemStyled>
                </Tooltip>

                <Tooltip title="Line" placement="left">
                  <MenuItemStyled
                    id="drawing-line"
                    onClick={this.handleLineClick}
                    selected={drawingMode === DRAWING_MODE.LINE}
                  >
                    <LinearScaleIcon />
                  </MenuItemStyled>
                </Tooltip>

                <Tooltip title="Circle" placement="left">
                  <MenuItemStyled
                    id="drawing-circle"
                    onClick={this.handleCirleClick}
                    selected={drawingMode === DRAWING_MODE.CIRLE}
                  >
                    <RadioButtonUncheckedIcon />
                  </MenuItemStyled>
                </Tooltip>
              </MenuList>
            </MenuWrapper>
          )}
        </div>
        {isMenuVisible && isBrushSizeVisible && (
          <div style={styles.brushSizeSubmenu}>
            <BrushSize
              value={selectedBrushSize}
              onBrushSizeChange={this.handleBrushSizeChange}
            />
          </div>
        )}

        {isMenuVisible && isTextToolVisible && (
          <div style={styles.textToolSubmenu}>
            <TextTool
              onTextDraggingSelected={this.handleAllowTextDragging}
              onAddTextSelected={this.handleAddTextClick}
            />
          </div>
        )}

        {isMenuVisible && isColorPickerVisible && (
          <div style={styles.colorPicker}>
            <ColorPicker
              color={selectedColor}
              onChangeComplete={this.handleColorChange}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

import React from 'react';
import { compose } from 'recompose';

import { ToggleButton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { BOULDER, WARM_PINK } from '../../style/constants';

const ToggleButtonGroup = ({
  classes,
  breakpts = [false, false],
  options,
  booking,
  setBooking,
  optionKey,
  redScheme,
}) => (
  <Grid container className={classes.contentContainer}>
    {options.map(opt => (
      <Grid
        item
        xs={breakpts[0]}
        sm={breakpts[1]}
        key={`togglebutton-option-${opt.code}`}
      >
        <ToggleButton
          className={redScheme ? classes.redToggleButton : classes.toggleButton}
          selected={booking[optionKey] === opt}
          onChange={() => {
            setBooking({ ...booking, [optionKey]: opt });
          }}
          value={opt.code || ''}
        >
          {opt.desc}
        </ToggleButton>
      </Grid>
    ))}
  </Grid>
);

const styles = {
  contentContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'start',
    width: '100%',
    height: '100%',
    '& > *': {
      marginTop: 10,
    },
  },
  toggleButton: {
    height: 40,
    color: BOULDER,
    backgroundColor: '#ffffff',
  },
  redToggleButton: {
    marginLeft: 4,
    width: 82,
    height: 34,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    '&:hover': {
      color: BOULDER,
    },
  },
};

export default compose(withStyles(styles))(ToggleButtonGroup);

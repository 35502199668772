import { gql } from '@apollo/client';

export const CREATE_NEW_TEXT_NOTE = gql`
    mutation createNewTextNote($userId: ID!, $patientId: ID!, $episodes: [ID]) {
        createNewTextNote(author: $userId, patientId: $patientId, episodes: $episodes) {
            note {
                id
            }
        }
    }
`;

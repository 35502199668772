import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const LIST_UNSIGNED_NOTES = gql`
  query ListUnsignedNotes {
    listUnsignedNotes {
      id
      createdAt
      isSignedOff
      patient {
        id
        name
      }
      title
      updatedAt
      ... on DrawingNote {
        group {
          id
        }
      }
    }
  }
`;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import './calendarEventDetail.css';
import { ARRIVAL_GREEN, ARRIVAL_RED, BOULDER } from '../../../style/constants';
import { patientSelected } from '../../../data/redux/actions/patient';
import IntegrationService from '../../../services/IntegrationService';

const SingleEventDetail = ({ classes, appointment, updateAppointment }) => {
    const [checkedInTooltip, setCheckedInTooltip] = useState(false);
    const historyInstance = useHistory();
    const dispatch = useDispatch();

    const checkInButton = (id, patientIsCheckedIn) =>
        patientIsCheckedIn ? (
            <>
                <Button
                    className={classes.redButton}
                    variant='contained'
                    onClick={() => {
                        if (appointment.invoiced) {
                            historyInstance.push(`/invoices/${appointment.invoiced[0]}`);
                        } else {
                            historyInstance.push(`/patient/${id}/invoicing`);
                        }
                    }}>
                    {appointment.invoiced ? 'Invoiced' : 'Go to Invoicing'}
                </Button>
            </>
        ) : (
            <Button
                className={classes.greenButton}
                variant='contained'
                onClick={() => {
                    updateAppointment({
                        variables: {
                            ...appointment,
                            checkedIn: appointment.checkedIn ? [id, ...appointment.checkedIn] : [id],
                        },
                    });
                }}>
                Mark Arrived
            </Button>
        );

    const handleNotesClick = (patient) => {
        dispatch(patientSelected(patient));
        historyInstance.push(`/patient/${patient.id}`);
    };

    return (
        <React.Fragment>
            {appointment.patients.map((patient) => {
                const { name, email, phone, dob, id } = patient;
                const patientIsCheckedIn = Array.isArray(appointment.checkedIn) && appointment.checkedIn.includes(id);
                return (
                    <span key={id}>
                        <Grid container item xs className={classes.title}>
                            <Typography variant='h6' component='h1'>
                                {name}
                            </Typography>

                            {patientIsCheckedIn && (
                                <Tooltip title='Patient Checked In' arrow open={checkedInTooltip} onClose={() => setCheckedInTooltip(false)}>
                                    <FiberManualRecordIcon style={{ color: ARRIVAL_GREEN, fontSize: 18 }} onClick={() => setCheckedInTooltip(true)} />
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid container item xs className={classes.section}>
                            <Grid container item xs className={classes.field}>
                                <Typography variant='body2'>Phone</Typography>
                                <Typography variant='body2'>{phone}</Typography>
                            </Grid>

                            <Grid container item xs className={classes.field}>
                                <Typography variant='body2'>Email</Typography>
                                <Typography variant='body2'>{email}</Typography>
                            </Grid>

                            <Grid container item xs className={classes.field}>
                                <Typography variant='body2'>DOB</Typography>
                                <Typography variant='body2'>{dob}</Typography>
                            </Grid>

                            <Divider />

                            <Grid container item xs className={classes.buttonSection}>
                                {!IntegrationService.objectIsLocked(appointment) && checkInButton(id, patientIsCheckedIn)}

                                <Button className={classes.greyButton} variant='outlined' onClick={() => handleNotesClick(patient)}>
                                    Patient Notes
                                </Button>
                            </Grid>

                            <Divider />

                            <Grid container item xs className={classes.buttonSection}>
                                {patient.alerts != null && patient.alerts.length > 0 && (
                                    <>
                                        <Typography variant='subtitle1' className={classes.subheading}>
                                            Alerts
                                        </Typography>
                                        <div className={classes.alertsContainer}>
                                            {patient.alerts.map((alert, index) => (
                                                <div key={alert.text} className={classes.alertBox}>
                                                    {alert.text}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </span>
                );
            })}
        </React.Fragment>
    );
};

const styles = {
    title: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px 0 !important',
    },
    section: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: 350,
        padding: '5px 20px !important',
    },
    buttonSection: {
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: 400,
    },
    field: {
        justifyContent: 'start',
        '& .MuiTypography-body2:first-child': {
            width: '100px',
            color: BOULDER,
        },
    },
    greenButton: {
        width: 144,
        height: 40,
        color: '#ffffff',
        backgroundColor: ARRIVAL_GREEN,
    },
    redButton: {
        width: 144,
        height: 40,
        color: '#ffffff',
        backgroundColor: ARRIVAL_RED,
    },
    greyButton: {
        width: 144,
        height: 40,
    },
    alertBox: {
        padding: 10,
        paddingLeft: 15,
        backgroundColor: '#dc3545',
        color: 'white',
        borderRadius: 15,
        minHeight: 35,
        width: 300,
        marginBottom: 15,
    },
};

export default compose(withStyles(styles))(SingleEventDetail);

// action types

export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSED = 'DIALOG_CLOSED';

// action creators

export const dialogOpen = ({ data, type }) => ({
  type: DIALOG_OPEN,
  data,
  dialogType: type,
});

export const dialogClosed = () => ({ type: DIALOG_CLOSED });

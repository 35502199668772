import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ErrorAlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.onClose = this.onClose.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.isOpen === undefined) {
      return {
        ...state,
        isOpen: true,
        ignore: true,
      };
    }
    return {
      ...state,
      ignore: false,
    };
  }

  componentDidMount() {
    const { error } = this.props;
    this.setState({ isOpen: Boolean(error) });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { ignore } = nextState;
    return !ignore;
  }

  onClose() {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { error, onExisted } = this.props;
    const component = this;
    return (
      <div>
        <Dialog
          open={component.state.isOpen}
          onExited={() => {
            component.setState({ isOpen: undefined });
            onExisted();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error &&
                error.message &&
                (() => {
                  let newText = error.message;
                  if (
                    newText.includes('GraphQL error: ') ||
                    newText.includes('Network error: ')
                  ) {
                    newText = error.message.slice(15);
                  } else if (!newText.includes('Please upgrade')) {
                    newText =
                      'There has been an unexpected error, please reload the page or restart the app';
                  }
                  return newText;
                })()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ErrorAlertDialog;

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DrawingTemplateList from '../../components/DrawingTemplateList';

const ListTemplateDialog = ({ shouldShow, onSelectTemplate, onTapOutside }) => {
  return (
    <Dialog open={shouldShow} onClose={onTapOutside} maxWidth="md" fullWidth>
      <DrawingTemplateList
        onSelectTemplate={onSelectTemplate}
        onClose={() => {
          console.log('on close drawing template list');
        }}
      />
    </Dialog>
  );
};

export default ListTemplateDialog;

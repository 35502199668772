import {
  branch,
  compose,
  withHandlers,
  withProps,
  withState,
  lifecycle,
} from 'recompose';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

const GET_NOTES = gql`
  query notes(
    $patientId: ID!
    $episodeId: ID
    $categories: [ID]
    $sortBy: SortBy
    $sortDirection: SortDirection
    $limit: Int
    $offset: Int
    $mediaOnly: Boolean
    $noGroup: Boolean
  ) {
    node(id: $patientId) {
      __typename
      id
      ... on Patient {
        notes(
          episodeId: $episodeId
          categories: $categories
          sortBy: $sortBy
          sortDirection: $sortDirection
          limit: $limit
          offset: $offset
          mediaOnly: $mediaOnly
          noGroup: $noGroup
        ) {
          nodes {
            title
            __typename
            id
            episodes {
              id
            }
            ... on DrawingNote {
              group {
                id
              }
            }
            isSignedOff
            signedOffTime
            createdAt
            updatedAt
            author {
              id
              name
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
`;

const initialSort = {
  by: 'UPDATED_AT',
  direction: 'DESC',
};

const NotesSidebarContainer = compose(
  withRouter,
  connect(({ episode, user }) => ({
    episodeId: episode.id,
    showBreadCrumbs: user.showBreadCrumbs,
  })),
  withState('isSidebarOpen', 'updateSidebar', true),
  withState('sort', 'updateSort', initialSort),
  withState('categories', 'updateCategories', []),
  withProps(({ match }) => ({
    patientId: match.params.patientId,
  })),
  branch(
    ({ patientId }) => patientId,
    compose(
      graphql(GET_NOTES, {
        name: 'query',
        options: ({ categories, episodeId, patientId, sort }) => ({
          variables: {
            patientId,
            episodeId,
            categories,
            sortBy: sort.by,
            sortDirection: sort.direction,
            offset: 0,
            limit: 14,
            mediaOnly: false,
            noGroup: true,
          },
          fetchPolicy: 'cache-and-network',
        }),
      }),
      withProps(({ query }) => ({
        error: query.error,
        status: {
          loading: query.networkStatus === 1,
          setVariables: query.networkStatus === 2,
          fetching: query.networkStatus === 3,
          refetching: query.networkStatus === 4,
          success: query.networkStatus === 7 && Boolean(query.node),
          error: query.networkStatus === 8,
        },
      })),
      branch(
        ({ status }) => status.success || status.fetching || status.refetching,
        withProps(({ query }) => ({
          notes: query.node.notes.nodes,
          hasNextPage: query.node.notes.pageInfo.hasNextPage,
        }))
      )
    )
  ),
  withHandlers({
    onListEnd: ({ status, query }) => () => {
      if (!status.fetching && !status.refetching) {
        query.fetchMore({
          variables: {
            offset: query.node.notes.nodes.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const combinedItems = [
              ...previousResult.node.notes.nodes,
              ...fetchMoreResult.node.notes.nodes,
            ];
            const filteredResults = uniqBy(combinedItems, item => item.id);

            return {
              node: {
                ...previousResult.node,
                notes: {
                  ...previousResult.node.notes,
                  nodes: filteredResults,
                  pageInfo: {
                    ...previousResult.node.notes.pageInfo,
                    ...fetchMoreResult.node.notes.pageInfo,
                  },
                },
              },
            };
          },
        });
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        this.props.updateSidebar(false);
      }, 1500);
    },
  })
);

export default NotesSidebarContainer;

import React from 'react';
import { compose } from 'recompose';

import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';

import { withStyles } from '@material-ui/core/styles';
import { MINOR_BLACK, WHITE } from '../../style/constants';

import DrawingTemplateListContainer from './drawingTemplateListContainer';

import ConfirmActionDialog from '../ConfirmActionDialog';
import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';
import GroupTemplates from '../GroupTemplates';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { ImageList, ImageListItem, ImageListItemBar } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TemplateImage from '../TemplateImage/templateImage';

const DrawingTemplatePage = ({
    alertDialog,
    classes,
    error,
    fileLoading,
    onArchiveTemplate,
    onClose,
    onSelectTemplate,
    onUploadTemplate,
    status,
    templates,
    updateAlertDialog,
    colSize,
    templateFiles,
    updateTemplateFiles,
    getFileEncodedFromFileId,
    notShowGroup,
    showMaxPageLimit,
    updateShowMaxPageLimit,
}) => (
    <div className={classes.wrapper}>
        {fileLoading && <Loading overlay />}
        {(() => {
            if (status.loading) return <Loading />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <>
                    <Grid
                        component='main'
                        className={classes.main}
                        container
                        direction='column'
                        wrap='nowrap'
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth='xl' className={classes.container}>
                            <Paper variant='outlined' className={classes.banner}>
                                <div className={classes.bannerContainer}>
                                    <Typography variant='h6' gutterBottom>
                                        Select Image Template
                                    </Typography>
                                    <Typography variant='body2' gutterBottom>
                                        Please note: template loader only accepts .jpgs, .jpegs, and .png files. If you have a multi-page template,
                                        please split them into separate image files and select all pages when uploading. <br />
                                        The order of a multi-page template is determined by how they are ordered within the file picker (top - bottom
                                        = first - last). <br />
                                        Maximum of 5 pages allowed for a multi-page drawing note.
                                    </Typography>
                                    <form name='uploadForm'>
                                        <label htmlFor='file-input'>
                                            <Button component='span' color='secondary' variant='contained' size='small'>
                                                Upload New
                                            </Button>
                                            <input
                                                className={classes.input}
                                                id='file-input'
                                                type='file'
                                                accept='.jpg,.jpeg,.png'
                                                multiple
                                                onChange={(event) => onUploadTemplate(event.target.files)}
                                            />
                                        </label>
                                    </form>
                                </div>
                            </Paper>
                            <br />
                            <br />
                            <ImageList rowHeight={216} cols={colSize} gap={12} className={classes.gridList}>
                                {(!templates || templates.length === 1) && (
                                    <EmptyData text='This practice currently has no image templates. Please upload a new one.' />
                                )}

                                {templates &&
                                    templates.map((template) =>
                                        (() => {
                                            if (template.pageNumber > 1) return null;
                                            if (template.id === 'blankId') return null;
                                            if (!notShowGroup && template.pageNumber === 1 && get(template, 'group.id')) {
                                                return (
                                                    <GroupTemplates
                                                        style={{ ...template.style }} // Passing the style provided by GridList to component.
                                                        key={template.id}
                                                        template={template}
                                                        onSelectTemplate={onSelectTemplate}
                                                        onClose={onClose}
                                                        updateAlertDialog={updateAlertDialog}
                                                        groupedTemplates={orderBy(
                                                            filter(templates, (item) => {
                                                                if (item.group) {
                                                                    return item.group.id === template.group.id;
                                                                }
                                                                return null;
                                                            }),
                                                            ['pageNumber'],
                                                            ['asc']
                                                        )}
                                                        onImageLoaded={(imageData) => {
                                                            if (templateFiles.filter((file) => file.id === imageData.id).length === 0)
                                                                updateTemplateFiles([...templateFiles, imageData]);
                                                        }}
                                                        getFileEncodedFromFileId={getFileEncodedFromFileId}
                                                    />
                                                );
                                            }
                                            return (
                                                <ImageListItem
                                                    key={template.id}
                                                    className={classes.listItem}
                                                    onClick={() => {
                                                        if (onSelectTemplate) {
                                                            var encodedFile = undefined;
                                                            var files = templateFiles.filter((file) => file.id === template.file.id);
                                                            if (files.length > 0) {
                                                                encodedFile = files[0].encodedFile;
                                                            } else {
                                                                encodedFile = template.file.encodedFile;
                                                            }
                                                            onSelectTemplate(template.id, encodedFile, undefined, template.file.id, template.name);
                                                            onClose();
                                                        }
                                                    }}>
                                                    <TemplateImage fileId={template.file.id} />
                                                    <ImageListItemBar
                                                        title={template.name}
                                                        actionIcon={
                                                            <IconButton
                                                                className={classes.icon}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    updateAlertDialog({
                                                                        show: true,
                                                                        selectedTemplate: [template],
                                                                    });
                                                                }}>
                                                                <Delete />
                                                            </IconButton>
                                                        }
                                                    />
                                                </ImageListItem>
                                            );
                                        })()
                                    )}
                            </ImageList>
                        </Container>
                    </Grid>
                </>
            );
        })()}
        <ConfirmActionDialog
            open={alertDialog.show}
            text='Are you sure you want to delete template?'
            onClose={() => updateAlertDialog({ show: false, selectedTemplate: undefined })}
            onConfirm={() => onArchiveTemplate(alertDialog.selectedTemplate)}
            confirmText='Delete'
        />

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showMaxPageLimit} autoHideDuration={3000}>
            <Alert
                severity='warning'
                onClose={() => {
                    updateShowMaxPageLimit(false);
                }}>
                Maximum number of pages exceeded. Maximum of 5 allowed.
            </Alert>
        </Snackbar>
    </div>
);

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 280,
        minWidth: 280,
        padding: 32,
    },
    banner: {
        innerWidth: '100%',
        height: 200,
    },
    bannerContainer: {
        padding: 20,
    },
    disabled: {
        opacity: 0.5,
    },
    topInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 32,
        paddingBottom: 12,
        borderBottom: `1px ${MINOR_BLACK} solid`,
    },
    topInfoText: {
        paddingRight: 12,
    },
    heading: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    warning: {
        fontSize: 12,
        marginTop: 6,
    },
    input: {
        display: 'none',
    },
    icon: {
        color: WHITE,
        opacity: 0,
        transition: '0.3s',
    },
    gridList: {
        height: 450,
        minWidth: 265,
        marginLeft: 20,
        marginRight: 20,
    },
    listItem: {
        display: 'inline-block',
        cursor: 'pointer',
        transitionDuration: '0.3s',
        '&:hover': {
            transform: 'translateY(-4px)',
        },
        '&:hover $icon': {
            opacity: 1,
        },
    },
    blankTemplate: {
        height: '100%',
        width: '100%',
        border: `1px ${MINOR_BLACK} solid`,
    },
};

export default compose(withStyles(styles), DrawingTemplateListContainer)(DrawingTemplatePage);

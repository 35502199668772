import { Base64 } from 'js-base64';
import { fabric } from 'fabric-with-gestures';
import config from './config';

const { MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT } = config;

async function loadCanvasWithFabric(canvas, rawCanvasJson) {
  const wait = new Promise(resolve => {
    canvas.loadFromJSON(rawCanvasJson, () => {
      resolve();
    });
  });

  await wait;
}

export async function drawingCanvas(drawingData) {
  const data =
    typeof drawingData === 'object' ? drawingData : JSON.parse(drawingData);
  const [width, height] = data.canvasSize;
  const c = document.createElement('canvas');
  c.width = width;
  c.height = height;

  if (data.rawCanvasJson) {
    const canvas = new fabric.Canvas(c, {
      width,
      height,
    });

    // canvas.loadFromJSON(data.rawCanvasJson, () => {
    //   console.log('load canvas with fabric done');
    // });

    await loadCanvasWithFabric(canvas, data.rawCanvasJson);
  } else {
    const ctx = c.getContext('2d');
    // it may need to be transparent so we can add the template image underneath
    ctx.fillStyle = 'transparent';
    ctx.fillRect(0, 0, width, height);

    // draw all lines
    data.lines.forEach(({ color, samples }) => {
      const [r, g, b, a] = color.map(v => Math.floor(v * 255));

      var isEraserLine = a === 0;

      if (isEraserLine) {
        ctx.strokeStyle = `rgba(${r}, ${g}, ${b}, 1)`;
        ctx.lineWidth = 10;
        ctx.globalCompositeOperation = 'destination-out';
      } else {
        ctx.lineWidth = 2;
        ctx.strokeStyle = `rgba(${r}, ${g}, ${b}, ${a / 255})`;
        ctx.globalCompositeOperation = 'source-over';
      }

      if (samples[0].pl !== undefined) {
        samples.forEach(({ pl, lo }) => {
          ctx.beginPath();
          ctx.moveTo(pl[0], pl[1]);
          ctx.lineTo(lo[0], lo[1]);
          ctx.stroke();
        });
      } else {
        samples.forEach(({ previousLocation, location }) => {
          ctx.beginPath();
          ctx.moveTo(previousLocation[0], previousLocation[1]);
          ctx.lineTo(location[0], location[1]);
          ctx.stroke();
        });
      }
    });
  }

  return c.toDataURL('image/png');
}

export function getImageHeight(img, destWidth) {
  return Math.round((destWidth / img.width) * img.height);
}

/**
 * Calculate new image size based on max image width and height
 * to fit inside the pdf page
 *
 * @param  {Object} img - html Image object
 * @return {Object}     - { width: Number, height: Number}
 */
export function getImageSize(img) {
  let { height, width } = img;
  const wr = MAX_IMAGE_WIDTH / width;
  const hr = MAX_IMAGE_HEIGHT / height;

  if (wr < hr) {
    height *= wr;
    width *= wr;
  } else {
    height *= hr;
    width *= hr;
  }

  return { height, width };
}

export function getNoteHeight(noteItems) {
  let noteHeight = 0;

  for (let j = 0; j < noteItems.length; j += 1) {
    const o = noteItems[j];
    switch (o.type) {
      case 'text':
        noteHeight += o.heightPerLine * o.value.length;
        break;
      case 'image':
        noteHeight += o.height;
        break;
      case 'space':
        noteHeight += o.height;
        break;
      default:
    }
  }
  return noteHeight;
}

export function getImageExtension(encodedFile) {
  const decodedFile = Base64.decode(encodedFile);

  let extension = 'jpeg';

  // do something like this
  const lowerCase = decodedFile.toLowerCase();
  if (lowerCase.indexOf('png') !== -1) extension = 'png';
  else if (lowerCase.indexOf('jpg') !== -1 || lowerCase.indexOf('jpeg') !== -1)
    extension = 'jpg';

  if (extension === 'pdf') {
    return 'application/pdf';
  }

  return `image/${extension}`;
}

export default {
  drawingCanvas,
  getImageExtension,
};

import React, { useState } from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import _ from 'lodash';

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import AppBarMenu from '../../components/AppBarMenu';
import { UPSERT_PATIENT } from './gql';
import { ARRIVAL_RED } from '../../style/constants';

const FIELDS = [
  {
    id: 'firstName',
    label: 'First Name',
    type: 'text',
  },
  {
    id: 'middleName',
    label: 'Middle Name',
    type: 'text',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    type: 'text',
  },
  {
    id: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    id: 'phone',
    label: 'Landline',
    type: 'tel',
  },
  {
    id: 'mobile',
    label: 'Mobile',
    type: 'tel',
  },
];

const INITIAL_STATE = {
  isArchived: false,
};

const CreatePatientPage = ({ classes, history, practiceId }) => {
  const [form, setForm] = useState(INITIAL_STATE);

  const [submitForm, { error, loading }] = useMutation(UPSERT_PATIENT, {
    variables: {
      practiceId,
      ...form,
      dob: Moment(form.dob).format('YYYY-MM-DD'),
    },
    onCompleted: res => {
      const patientId = _.get(res, 'upsertPatient.patient.id');
      if (patientId) {
        history.push(`/patient/${patientId}`);
        window.location.reload(true);
      }
    },
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitForm();
  };

  return (
    <AppBarMenu>
      <Grid
        component="main"
        className={classes.main}
        container
        direction="column"
        wrap="nowrap"
        item
      >
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            wrap="nowrap"
          >
            <List disablePadding>
              {FIELDS.map(field => {
                return (
                  <ListItem
                    className={classes.detailItemEditing}
                    key={field.id}
                  >
                    <ListItemText
                      className={classes.detailLabel}
                      secondary={field.label}
                    />

                    <TextField
                      className={classes.detailInputName}
                      id={field.id}
                      autoComplete="off"
                      name={field.id}
                      onChange={handleChange}
                      value={form[field.id] || ''}
                    />
                  </ListItem>
                );
              })}

              <ListItem className={classes.detailItemEditing}>
                <ListItemText className={classes.detailLabel} secondary="DOB" />
                <div className={classes.detailInputDate}>
                  <TextField
                    id="dob"
                    className={classes.detailInputDate}
                    autoComplete="off"
                    type="date"
                    value={Moment(form.dob).format('YYYY-MM-DD')}
                    onChange={e => setForm({ ...form, dob: e.target.value })}
                  />
                </div>
              </ListItem>
              {error && (
                <ListItem>
                  <ErrorMessage error={error} />
                </ListItem>
              )}
            </List>
            <Grid className={classes.buttonContainer}>
              {loading ? (
                <Loading />
              ) : (
                <Button
                  className={classes.button}
                  disabled={loading}
                  onClick={handleSubmit}
                  variant="outlined"
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </AppBarMenu>
  );
};

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: () => window.innerWidth > 600 && '100%',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 88,
    paddingRight: 44,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: 157,
    marginBottom: 36,
    padding: 10,
    marginTop: 30,
  },
  datePicker: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
  },
  detailItemEditing: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  detailLabel: {
    flex: 0.5,
  },
  detailInputName: {
    flex: 1,
    paddingLeft: 8,
    fontWeight: 'bold',
  },
  detailInputDate: {
    flex: 1,
    height: 32,
    paddingLeft: 8,
    '& > div': {
      display: 'flex',
      zIndex: 9999,
    },
    '& > div:first-child > div:first-child': {
      flex: 1,
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  button: {
    width: 100,
    height: 40,
    color: ARRIVAL_RED,
  },
};

export default compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
  })),
  withStyles(styles)
)(CreatePatientPage);

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const NumberScale100 = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onFocus,
  onSave,
  section,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  onChange,
}) => {
  let scaleValue = [0, 0];
  if (section && section.numberScale100Value) {
    scaleValue = section.numberScale100Value.split(';');
    if (scaleValue.length === 1) {
      scaleValue = [...[0], ...scaleValue];
    }
  }
  return (
    <Grid container direction="column">
      <Typography variant="body1" className={classes.title}>
        {section.title}
      </Typography>
      <Slider
        disabled={!canEdit || initialSave}
        value={scaleValue || [0, 0]}
        min={0}
        step={1}
        max={100}
        onChangeCommitted={onChange}
        valueLabelDisplay="on"
        aria-labelledby="non-linear-slider"
      />
    </Grid>
  );
};

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
    marginBottom: 34,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles),
  withHandlers({
    onChange: ({ section, onSave, onChangeScale100Value }) => async (
      event,
      newValue
    ) => {
      let value = newValue;
      if (Array.isArray(newValue)) {
        value = newValue.join(';');
      }
      onChangeScale100Value(value, section);
      onSave();
    },
  })
)(NumberScale100);

import { gql } from '@apollo/client';

export const FETCH_LOCATIONS = gql`
    query fetchLocation($practiceEncodedId: ID!) {
        fetchLocation(practiceEncodedId: $practiceEncodedId) {
            id
            name
        }
    }
`;

export const UPDATE_LOCATION_USER = gql`
    mutation updateLocationUsers($locationIds: [ID]!, $userId: ID!) {
        updateLocationUsers(locationIds: $locationIds, userId: $userId) {
            locationIds
        }
    }
`;

export const TRANSFER_OWNERSHIP = gql`
    mutation giveOwnerRole($practiceEncodedId: ID!, $userId: ID!) {
        giveOwnerRole(practiceEncodedId: $practiceEncodedId, userId: $userId) {
            roles
            name
        }
    }
`;

export const GET_PRACTICE_SUBSCRIPTION = gql`
    query getPracticeSubscription($practiceId: String) {
        getPracticeSubscription(practiceId: $practiceId) {
            stripeSubscription {
                customerToken
                planToken
                numberOfUsers
                cardLast4
                status
                expiry
                renewDate
            }
        }
    }
`;

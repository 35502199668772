import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';

import SearchIcon from '@material-ui/icons/Search';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Link,
    TextField,
    InputAdornment,
    Popper,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

import { ARRIVAL_RED, ERROR, WARM_PINK } from '../../style/constants';
import { DELETE_INVOICE_ITEM, LIST_INVOICES, UPSERT_INVOICE_ITEM } from '../InvoicingPage/gql';
import { LIST_INVOICE_ITEMS } from '../../components/Patient/gql';
import { LIST_APPOINTMENT_TYPES, LIST_INVENTORY_ITEMS } from '../SettingsPage/gql';
import { formatMoney } from '../../utility';

const DEFAULT_STATE = {
    comments: '',
    date: Moment().format('yyyy-MM-DD'),
    price: 0.0,
    quantity: 1,
};

const InvoiceItemDialog = ({
    classes,
    encodedPatientId,
    existingItem,
    invoiceId,
    listInvoiceItems,
    locationId,
    onCloseDialog,
    practiceId,
    showDialog,
    location,
}) => {
    const [invoiceItem, setInvoiceItem] = useState(DEFAULT_STATE);
    const [isUniqueInput, setIsUniqueInput] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [showInventory, setShowInventory] = useState(false);

    useQuery(LIST_INVENTORY_ITEMS, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.listInventoryItem) {
                setInventoryOptions([...inventoryOptions, ...res.listInventoryItem]);
            }
        },
        variables: {
            locationId,
        },
    });

    // Run the query to fetch the appointment types and add it into the inventory options
    useQuery(LIST_APPOINTMENT_TYPES, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.listAppointmentTypes) {
                // for each appointment types add a new key in the object as deescription. This is to make it consistent with the inventory items
                const appointmentTypes = res.listAppointmentTypes.map((appointmentType) => {
                    return {
                        ...appointmentType,
                        description: appointmentType.desc,
                        category: 'Appointment',
                    };
                });

                setInventoryOptions([...inventoryOptions, ...appointmentTypes]);
            }
        },
        variables: {
            locationId,
        },
    });

    const [upsertItem] = useMutation(UPSERT_INVOICE_ITEM, {
        onCompleted: () => {
            listInvoiceItems();
            onCloseDialog();
        },
    });

    const [deleteItem] = useMutation(DELETE_INVOICE_ITEM, {
        onCompleted: () => {
            onCloseDialog();
            window.location.reload(true);
        },
        refetchQueries: [
            { query: LIST_INVOICES },
            {
                query: LIST_INVOICE_ITEMS,
                variables: {
                    encodedPatientId,
                    locationId,
                },
            },
        ],
    });

    useEffect(() => {
        if (existingItem && existingItem.id) {
            setInvoiceItem(existingItem);
        } else {
            setInvoiceItem(DEFAULT_STATE);
        }
    }, [existingItem]);

    const sanitiseAmount = (amount) => {
        if (!amount) {
            return 0;
        }
        return Math.abs(parseFloat(amount)).toFixed(2);
    };

    const onBlurSanitise = (event) => {
        setInvoiceItem({
            ...invoiceItem,
            [event.target.name]: sanitiseAmount(invoiceItem[event.target.name]),
        });
    };

    const CustomDropdown = (props) => <Popper {...props} style={{ width: 'fit-content' }} placement='bottom-start' />;

    const handleToggle = () => {
        setShowInventory(!showInventory);
    };

    return (
        <>
            <Dialog open={showDialog} onClose={onCloseDialog} className={classes.dialogContainer}>
                <DialogTitle id='form-dialog-title'>{existingItem ? `Edit Item` : 'Add New Item'}</DialogTitle>

                <DialogContent>
                    <TextField
                        id='appt-start-date'
                        className={classes.field}
                        fullWidth
                        label='Date'
                        margin='normal'
                        type='date'
                        autoComplete='off'
                        defaultValue={invoiceItem.date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => {
                            const clonedInvoiceItem = {
                                ...invoiceItem,
                                date: event.target.value,
                            };
                            setInvoiceItem(clonedInvoiceItem);
                            setIsUniqueInput(true);
                        }}
                    />

                    <div>
                        <TextField
                            className={classes.field}
                            type='text'
                            label='Item/Service'
                            fullWidth
                            autoFocus
                            margin='normal'
                            autoComplete='off'
                            value={invoiceItem.comments}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton aria-label='Search for inventory item' className={classes.iconButton} onClick={handleToggle}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                const clonedInvoiceItem = {
                                    ...invoiceItem,
                                    comments: event.target.value,
                                };
                                setInvoiceItem(clonedInvoiceItem);
                                setIsUniqueInput(true);
                            }}
                        />
                    </div>

                    <Typography className={classes.title} variant='body2'>
                        Quantity
                    </Typography>

                    <TextField
                        className={classes.field}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name='quantity'
                        autoComplete='off'
                        onBlur={onBlurSanitise}
                        onChange={(event) => {
                            const clonedInvoiceItem = {
                                ...invoiceItem,
                                quantity: event.target.value,
                            };
                            setInvoiceItem(clonedInvoiceItem);
                            setIsUniqueInput(true);
                        }}
                        value={invoiceItem.quantity}
                    />

                    <Typography className={classes.title} variant='body2'>
                        Price
                    </Typography>

                    <TextField
                        className={classes.field}
                        fullWidth
                        id='standard-select-currency'
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>{location ? location.currency.symbol : 'USD'}</InputAdornment>,
                        }}
                        name='price'
                        onBlur={onBlurSanitise}
                        onChange={(event) => {
                            const clonedInvoiceItem = {
                                ...invoiceItem,
                                price: event.target.value,
                            };
                            setInvoiceItem(clonedInvoiceItem);
                            setIsUniqueInput(true);
                        }}
                        value={invoiceItem.price}
                    />

                    <Typography className={classes.title} variant='body2'>
                        Subtotal
                    </Typography>

                    <Typography className={classes.title} variant='body2'>
                        {formatMoney(invoiceItem.price * invoiceItem.quantity, location ? location.currency.currency : 'USD')}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    {existingItem && (
                        <div className={classes.buttonContainer}>
                            {confirmDelete ? (
                                <React.Fragment>
                                    <Button variant='outlined' className={classes.button} onClick={() => setConfirmDelete(false)}>
                                        UNDO
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        className={classes.redButton}
                                        onClick={() => {
                                            deleteItem({
                                                variables: {
                                                    id: existingItem.id,
                                                },
                                            });
                                            onCloseDialog();
                                        }}>
                                        CONFIRM
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Button color='secondary' className={classes.deleteButton} onClick={() => setConfirmDelete(true)}>
                                    DELETE
                                </Button>
                            )}
                        </div>
                    )}

                    <div className={classes.buttonContainer}>
                        <Button color='primary' onClick={onCloseDialog}>
                            Cancel
                        </Button>

                        <Button
                            color='primary'
                            onClick={async () => {
                                await upsertItem({
                                    variables: {
                                        encodedPatientId,
                                        ...invoiceItem,
                                        invoiceId,
                                        locationId,
                                        practiceId,
                                    },
                                });
                            }}
                            disabled={!invoiceItem.comments || !invoiceItem.date || !invoiceItem.price || !invoiceItem.quantity || !isUniqueInput}>
                            SAVE
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleToggle} open={showInventory}>
                <DialogTitle>Inventory & Service Search</DialogTitle>
                <DialogContent>
                    {!!inventoryOptions.length && (
                        <>
                            <Typography variant='caption'>
                                Select an{' '}
                                <Tooltip title='Click to navigate to the Inventory & Services Settings page'>
                                    <Link target='_blank' href='/settings/inventory'>
                                        <b className={classes.pinkLink}>Inventory Item or Service</b>
                                    </Link>
                                </Tooltip>{' '}
                                to prefill details and save time (optional):
                            </Typography>
                            <Autocomplete
                                id='appt-patient-select'
                                // hiddenLabel
                                className={classes.field}
                                filterOptions={(options, state) =>
                                    options.filter(
                                        (option) =>
                                            option.description.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                                            option.code.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                                            option.category.toLowerCase().includes(state.inputValue.toLowerCase())
                                    )
                                }
                                groupBy={(option) => option.category}
                                onChange={(event, values) => {
                                    if (values) {
                                        const { id, code, description, price } = values;
                                        const clonedItem = {
                                            ...invoiceItem,
                                            code,
                                            comments: description,
                                            inventoryItemId: id,
                                            price,
                                        };
                                        setInvoiceItem(clonedItem);
                                        setIsUniqueInput(true);
                                        handleToggle();
                                    }
                                }}
                                options={inventoryOptions}
                                PopperComponent={CustomDropdown}
                                renderInput={(params) => <TextField autoComplete='off' {...params} margin='normal' className={classes.field} />}
                                renderOption={(params) => {
                                    return (
                                        <>
                                            <div className={classes.dropdownOptions}>
                                                {/* Leaving in case the groupBy category is not appropriate */}
                                                {/* <Typography className={classes.optionDetails}>
                          {params.category}
                        </Typography> */}
                                                <Typography className={classes.optionDetails}>{params.code}</Typography>
                                                <Typography className={classes.optionDetails}>{params.description}</Typography>
                                                <Typography className={classes.optionDetails}>
                                                    {formatMoney(params.price, location.currency.currency)}
                                                </Typography>
                                                {/* <Typography className={classes.optionDetails}>
                          {params.stock} in stock
                        </Typography> */}
                                            </div>
                                        </>
                                    );
                                }}
                                getOptionLabel={(option) => `${option.category} - ${option.description}`}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={handleToggle}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        margin: '0px !important',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    fieldContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    buttonFieldContainer: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    title: {
        width: 100,
        marginTop: 16,
    },
    field: {
        width: 480,
    },
    dialogContainer: {
        width: '100%',
    },
    buttonContainer: {
        '& button': {
            marginLeft: 10,
        },
    },
    redButton: {
        width: 100,
        height: 40,
        color: ARRIVAL_RED,
    },
    iconButton: {
        marginBottom: 7,
        padding: 0,
        width: 15,
        color: 'black',
    },
    button: {
        width: 100,
        height: 40,
    },
    deleteButton: {
        marginRight: 'auto',
        color: ERROR,
    },
    dropdownOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > *:not(:last-child)': {
            marginRight: 8,
        },
        width: '100%',
        borderBottom: '1px solid #c7c7c7',
    },
    optionDetails: {
        width: 150,
    },
    inventoryItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    pinkLink: {
        color: WARM_PINK,
    },
};

export default compose(
    connect(({ patient, practice, user, location }) => ({
        encodedPatientId: patient.id,
        locationId: user.currentLocationId,
        practiceId: practice.id,
        location: user.location,
    })),
    withStyles(styles)
)(InvoiceItemDialog);

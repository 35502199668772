import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useLazyQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';

import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Tooltip,
  Checkbox,
  Snackbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import moment from 'moment';

import { Alert } from '@material-ui/lab';
import { LIST_INVOICE_ITEMS } from './gql';
import { LIST_INVOICES, UPSERT_INVOICE } from '../../pages/InvoicingPage/gql';
import { formatMoney } from '../../utility';
import InvoiceItemDialog from '../../pages/InvoiceDetailPage/InvoiceItemDialog';
import Loading from '../Loading';
import { WARM_PINK } from '../../style/constants';

const columnData = [
  { id: 'createdAt', label: 'Date' },
  { id: 'userId', label: 'Clinician' },
  { id: 'name', label: 'Item/Service' },
  { id: 'invoiceNumber', label: 'Invoice #' },
  { id: 'status', label: 'Status' },
  // { id: 'dateDue', label: 'Due Date' },
  { id: 'amount', label: 'Amount' },
  // { id: 'balanceDue', label: 'Balance Due' },
  // { id: 'payment', label: '' },
];

const InvoiceItemListCard = ({
  currentLocationId,
  encodedPatientId,
  selectedPatient,
  practiceId,
  classes,
  location,
}) => {
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState();
  const [itemsToBeInvoiced, setItemsToBeInvoiced] = useState([]);
  const [showItemDialog, setShowItemDialog] = useState(false);

  const [listInvoiceItems, { loading }] = useLazyQuery(LIST_INVOICE_ITEMS, {
    onCompleted: res => {
      setInvoiceItems(res.fetchInvoiceItem);
      setItemsToBeInvoiced(res.fetchInvoiceItem.map(item => item.id));
    },
    variables: {
      encodedPatientId,
    },
    fetchPolicy: 'network-only',
  });

  const [upsertInvoice, { error }] = useMutation(UPSERT_INVOICE, {
    refetchQueries: [{ query: LIST_INVOICES }],
    onCompleted: res => {
      if (res.upsertInvoice) {
        window.location.href = `/invoices/${res.upsertInvoice.id}`;
      }
    },
  });

  const onCloseItemDialog = () => {
    setSelectedInvoiceItem(null);
    setShowItemDialog(false);
  };

  const onSelectInvoiceItem = e => {
    setSelectedInvoiceItem(e);
    setShowItemDialog(true);
  };

  const onCreateDraftInvoice = () => {
    // setShowItemDialog(true);
    upsertInvoice({
      variables: {
        encodedPatientId,
        dateDue: moment()
          .add(1, 'month')
          .format('YYYY-MM-DD'),
        invoiceDate: moment().format(),
        invoiceItemIds: itemsToBeInvoiced,
        locationId: currentLocationId,
        practiceId,
      },
    });
  };

  const onCreateNewInvoiceItem = () => {
    setShowItemDialog(true);
  };

  const onCheckboxClick = (event, id) => {
    event.stopPropagation();

    if (event.target.checked) {
      setItemsToBeInvoiced([...itemsToBeInvoiced, id]);
    } else {
      setItemsToBeInvoiced(itemsToBeInvoiced.filter(item => item !== id));
    }
  };

  const onSelectAllClick = event => {
    if (event.target.checked) {
      setItemsToBeInvoiced(invoiceItems.map(item => item.id));
    } else {
      setItemsToBeInvoiced([]);
    }
  };

  useEffect(() => {
    listInvoiceItems();
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <div className={classes.subheading}>
        <Typography className={classes.subheadingText} variant="subtitle1">
          Items Yet to Be Billed
        </Typography>
        <div>
          <Tooltip title="Create New Invoice Item">
            <span>
              <Button disabled={loading} onClick={onCreateNewInvoiceItem}>
                <SpeedDialIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Create New Invoice With Selected Items">
            <span>
              <Button
                disabled={loading || itemsToBeInvoiced.length === 0}
                color="secondary"
                variant="contained"
                onClick={onCreateDraftInvoice}
                className={classes.redButton}
              >
                Create Invoice
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {error && <Alert severity="error">{error.message}</Alert>}
      </Snackbar>
      <Paper className={classes.submissionsPaper}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
          // className={form.loading ? classes.disabled : null}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell key="checkbox-cell" padding="checkbox">
                  {itemsToBeInvoiced.length > 0 ? (
                    <Checkbox
                      indeterminate={
                        itemsToBeInvoiced.length < invoiceItems.length
                      }
                      checked={
                        itemsToBeInvoiced.length > 0 &&
                        itemsToBeInvoiced.length === invoiceItems.length
                      }
                      onChange={onSelectAllClick}
                    />
                  ) : null}
                </TableCell>
                {columnData.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceItems.map(row => (
                <TableRow
                  className={classes.clickableRow}
                  hover
                  key={row.id}
                  onClick={() => onSelectInvoiceItem(row)}
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell className="selectCheckbox" padding="checkbox">
                    <Checkbox
                      checked={itemsToBeInvoiced.includes(row.id)}
                      className="selectCheckbox"
                      onClick={event => onCheckboxClick(event, row.id)}
                    />
                  </TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.clinicianName}</TableCell>
                  <TableCell>{row.comments}</TableCell>
                  <TableCell>{row.invoiceId}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>
                    {formatMoney(
                      row.price * row.quantity || 0,
                      location.currency.currency
                    )}
                  </TableCell>
                  {/* <TableCell>{formatMoney(row.balanceDue || 0)}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={classes.buttonContainer} colSpan={6}>
                  {/* {formatMoney(subtotal)} */}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Paper>
      <InvoiceItemDialog
        existingItem={selectedInvoiceItem}
        listInvoiceItems={listInvoiceItems}
        onCloseDialog={onCloseItemDialog}
        setExistingItem={setSelectedInvoiceItem}
        showDialog={showItemDialog}
      />
    </>
  );
};

const styles = {
  clickableRow: {
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  subheading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  subheadingText: {
    fontWeight: 'bold',
  },
  submissionsPaper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 36,
    width: '100%',
    overflow: 'auto',
  },
  redButton: {
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
};

export default compose(
  connect(({ patient, practice, user }) => ({
    currentLocationId: user.currentLocationId,
    encodedPatientId: patient.id,
    practiceId: practice.id,
    location: user.location,
  })),
  withStyles(styles)
)(InvoiceItemListCard);

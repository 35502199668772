import _ from 'lodash';

export const PERMISSIONS_LIST = {
    // 'upsertPatient': ['RECEPTIONIST', 'ADMIN', 'CLINICIAN', 'OWNER'],
    // 'upsertEpisode': ['RECEPTIONIST', 'ADMIN', 'CLINICIAN', 'OWNER'],
    // 'upsertTemplate': ['RECEPTIONIST', 'ADMIN', 'CLINICIAN', 'OWNER'],

    upsertAppointmentTypes: ['RECEPTIONIST', 'ADMIN', 'CLINICIAN', 'OWNER'],
    upsertInventory: ['RECEPTIONIST', 'ADMIN', 'CLINICIAN', 'OWNER'],
    // upsertTemplate: ['RECEPTIONIST', 'ADMIN', 'CLINICIAN', 'OWNER'],

    upsertNote: ['CLINICIAN', 'ADMIN', 'OWNER'],
    upsertSelfUser: ['CLINICIAN', 'ADMIN', 'OWNER'],

    upsertUser: ['ADMIN', 'OWNER'],
    viewReports: ['ADMIN', 'OWNER'],

    upsertLocation: ['ADMIN', 'OWNER'],
    upsertPractice: ['OWNER'],
    managePlan: ['OWNER'],
    viewAudit: ['OWNER'],
    upsertPayments: ['OWNER'],
    transferOwnership: ['OWNER'],
};

export const hasPermission = (roles, permission) => {
    if (roles.includes('OWNER')) return true;
    if (_.intersection(PERMISSIONS_LIST[permission], roles).length) return true;
    return false;
};

import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE, BLACK } from '../../style/constants';

const UserPopover = ({
  anchorEl,
  classes,
  onClick,
  onClose,
  onLogout,
  onMyAccount,
  practiceName,
  userName,
}) => (
  <Grid className={classes.wrapper}>
    <Button
      aria-owns={anchorEl ? 'menu-appbar' : null}
      aria-haspopup="true"
      className={classes.button}
      onClick={onClick}
      size="small"
    >
      <Typography className={classes.text} variant="body2">
        {userName}
      </Typography>
      <FontAwesomeIcon icon={faChevronDown} />
    </Button>
    <Modal
      aria-labelledby="user"
      aria-describedby="user"
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid className={classes.popover}>
          <Typography className={classes.popoverText}>
            <strong>{userName}</strong>
          </Typography>
          <Typography className={classes.popoverText}>
            {practiceName}
          </Typography>
          <Grid className={classes.popoverButtons}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.popoverButton}
              size="small"
              onClick={() => {
                onClose();
                onLogout();
              }}
            >
              Logout
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.popoverButton}
              size="small"
              onClick={() => {
                onClose();
                onMyAccount();
              }}
            >
              My Account
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Modal>
  </Grid>
);

const styles = {
  wrapper: {
    marginLeft: 'auto',
  },
  button: {
    color: WHITE,
  },
  popover: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 20,
  },
  popoverText: {
    color: BLACK,
  },
  popoverButton: {
    marginTop: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  text: {
    marginRight: 8,
  },
};

export default compose(
  withRouter,
  withApollo,
  connect(({ user, practice }) => ({
    practiceName: practice.name,
    userName: user.name,
  })),
  withStyles(styles),
  withState('anchorEl', 'updateAnchorEl', null),
  withHandlers({
    onClick: ({ updateAnchorEl }) => event =>
      updateAnchorEl(event.currentTarget),
    onClose: ({ updateAnchorEl }) => () => updateAnchorEl(null),
    onLogout: ({ dispatch, client, history }) => async () => {
      history.push('/logout');
    },
    onMyAccount: ({ history }) => () => history.push('/user'),
  })
)(UserPopover);

import React from 'react';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/styles';

import processMedias from './processFiles';

import PDFDocument from '../EpisodeInPDF/PDFDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Tooltip from '@material-ui/core/Tooltip';

const PrintNote = ({ loading, classes, onPrintClick, data, updateData }) => (
    <div>
        {!data && (
            <Tooltip title='Print Note' placement='bottom-start'>
                <IconButton color='inherit' aria-label='print' className={classes.iconButton} disable={loading} onClick={() => onPrintClick()}>
                    <FontAwesomeIcon color='primary' icon={faPrint} />
                </IconButton>
            </Tooltip>
        )}

        {data && (
            <PDFDownloadLink document={<PDFDocument data={data} />} fileName={'Printable'}>
                {({ url, loading, error: err, ...rest }) => {
                    if (url) {
                        window.open(url, '_blank');
                        updateData(undefined);
                    }
                    return (
                        <IconButton color='inherit' aria-label='print' className={classes.iconButton} disable={loading}>
                            <FontAwesomeIcon color='primary' icon={faPrint} />
                        </IconButton>
                    );
                }}
            </PDFDownloadLink>
        )}
    </div>
);

const styles = {
    iconButton: {},
};

PrintNote.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isSignedOff: PropTypes.bool,
    loading: PropTypes.bool,
    noteId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    updateOpen: PropTypes.func.isRequired,
    onArchiveNote: PropTypes.func.isRequired,
};

PrintNote.defaultProps = {
    isSignedOff: undefined,
    loading: undefined,
    noteId: undefined,
};

export default compose(
    withStyles(styles),
    withState('data', 'updateData', undefined),
    withHandlers({
        onPrintClick:
            ({ form, updateData, typename }) =>
            async () => {
                console.log(form);

                if (typename === 'DrawingNote') {
                    var groupNotes = form.groupNotes;
                    if (groupNotes) {
                        for (var node of groupNotes) {
                            node['__typename'] = typename;
                        }
                    } else {
                        var n = form;
                        n['__typename'] = typename;
                        groupNotes = [n];
                    }
                    console.log(groupNotes);
                    const { files, canvasImages } = await processMedias(groupNotes);

                    const data = {
                        notes: groupNotes,
                        files,
                        canvasImages,
                        compactMode: true,
                    };
                    updateData(data);
                } else {
                    var note = form;
                    note['__typename'] = typename;
                    const { files, canvasImages } = await processMedias([note]);

                    const data = {
                        notes: [note],
                        files,
                        canvasImages,
                        compactMode: true,
                    };
                    updateData(data);
                }
            },
    }),
    lifecycle({
        componentDidUpdate() {},
    })
)(PrintNote);

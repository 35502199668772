import { gql } from '@apollo/client';

export const FETCH_LOCATIONS = gql`
    query fetchLocation($practiceEncodedId: ID!) {
        fetchLocation(practiceEncodedId: $practiceEncodedId) {
            id
            appointmentTimeSlot
            name
            paymentLine1
            paymentLine2
            paymentLine3
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            publicHolidays
            integration {
                id
                status
                syncStatus
                siteId
                locationId
                metadata
            }
        }
    }
`;

export const GET_PRACTICE_SUBSCRIPTION = gql`
    query getPracticeSubscription($practiceId: String) {
        getPracticeSubscription(practiceId: $practiceId) {
            stripeSubscription {
                customerToken
                planToken
                numberOfUsers
                cardLast4
                status
                expiry
                renewDate
            }
        }
    }
`;

export const OBLITERATE_INTEGRATION_DATA_FOR_PRACTICE = gql`
    mutation obliterateIntegrationDataForPractice($encodedPracticeId: ID) {
        obliterateIntegrationDataForPractice(encodedPracticeId: $encodedPracticeId) {
            success
        }
    }
`;

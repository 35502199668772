/* eslint-disable */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Loading from '../../components/Loading';
import PredefinedSelectionsEditor from '../../components/PredefinedSelectionsEditor';

import SymptomsMenu, { newPredefinedSelection } from './SymptomsMenu';
import styles from './styles';
import NavigationMenu from './NavigationMenu';
import DrawingMenu from './DrawingMenu';
import { mod } from './utils';
import DrawingCanvas from './DrawingCanvasClass';
import ListTemplateDialog from './ListTemplateDiablog';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';

class DrawingToolComponent extends React.Component {
  // Init state
  state = {
    activeCanvas: 0,
    canvasInstances: [],
    canvasData: [],
    blankCanvasData: [
      { id: 'blankId' },
      { id: 'blankId2' },
      { id: 'blankId3' },
      { id: 'blankId4' },
      { id: 'blankId5' },
      { id: 'blankId6' },
      { id: 'blankId7' },
      { id: 'blankId8' },
      { id: 'blankId9' },
      { id: 'blankId10' },
      { id: 'blankId11' },
      { id: 'blankId12' },
      { id: 'blankId13' },
      { id: 'blankId14' },
      { id: 'blankId15' },
    ],
    symptopSelection: this.props.symptopSelection,
    showSelectTemplateDialog: false,
    showLoading: false,
    showPreferences: false,
    isBlank: false,
    createdCanvasInstances: false,
    isStylusEnable: false,
    showToolTip: true,
    showMaxPageLimit: false,
  };

  componentDidMount() {
    console.log('componentDidMount');
    // this.createCanvasIntances();
    // console.log('componentWillMount');
    // if (this.props.canvasData) {
    //   this.setState({canvasData: this.props.canvasData});
    this.createCanvasIntances();
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
      );

    // var $;
    // $ = require('jquery');
    // $("#drawing-root").on('touchmove',function(e){
    //   if(!$('#symptoms-menu').has($(e.originalEvent.target)).length &&
    //     !$('#drawing-menu').has($(e.originalEvent.target)).length &&
    //     !$('#add-page').has($(e.originalEvent.target)).length &&
    //     !$('#navigation-menu').has($(e.originalEvent.target)).length) {
    //       e.preventDefault();
    //   }
    //   if (e.originalEvent.scale > 1) {
    //     e.preventDefault();
    //   }
    // });
  }

  componentWillMount() {
    console.log('componentWillMount');
    if (this.props.canvasData) {
      this.setState({ canvasData: this.props.canvasData });
      // this.createCanvasIntances();
    }
  }

  componentDidUpdate = prevProps => {
    console.log('componentDidUpdate');
    if (this.props.isStandalone) {
      return;
    }
    if (prevProps.canvasData !== this.props.canvasData) {
      this.createCanvasIntances();
    }
    let {
      canvasInstances,
      canvasData,
      createdCanvasInstances,
      isStylusEnable,
    } = this.state;
    if (
      createdCanvasInstances &&
      canvasInstances.length !== canvasData.length
    ) {
      var newCanvas = new DrawingCanvas(canvasData[canvasData.length - 1]);
      newCanvas.updateStylusMode(isStylusEnable);
      canvasInstances.push(newCanvas);
      this.setState({ canvasInstances });
    }
  };

  handleNewPage = () => {
    let canvasData = [...this.state.canvasData];

    if (canvasData && canvasData.length >= 5) {
      let showMaxPageLimit = this.state.showMaxPageLimit;
      showMaxPageLimit = true;
      this.setState({ showMaxPageLimit });
      return;
    }

    // let canvasInstances = [...this.state.canvasInstances];
    const a = { id: 'blankId' + (canvasData.length + 1) };
    canvasData.push(a);
    // canvasInstances.push(new DrawingCanvas(a));
    // const canvasInstances = canvasData.map(data => new DrawingCanvas(data));
    // console.log(canvasInstances);

    this.setState({ canvasData });
  };

  createCanvasIntances = () => {
    console.log('createCanvasIntances');
    const { canvasData } = this.state;
    let canvasInstances = [];
    // let isBlank = false;
    // if (canvasData && canvasData[0].id == 'blankId') {
    //   canvasInstances = this.state.blankCanvasData.map(data => new DrawingCanvas(data));
    //   // isBlank = true;
    //   // let a = new DrawingCanvas({id: 1});
    //
    // } else {
    canvasInstances = canvasData.map(data => new DrawingCanvas(data));
    // }
    this.setState({
      canvasInstances,
      createdCanvasInstances: true,
    });
  };

  handleHomeBtnClick = () => {
    if (window.confirm('Are you sure you want to go to homepage?')) {
      window.location = '/';
    }
  };

  handleBackBtnClick = () => {
    if (window.confirm('Are you sure you want to exit?')) {
      if (
        this.props.formNoteSection ||
        this.props.formNoteGroupedSections ||
        this.props.enableClose
      ) {
        if (this.props.onCloseDrawingFormNote) {
          this.props.onCloseDrawingFormNote();
        }
      }
      window.history.back();
    }
  };

  exportCanvasToData = () => {
    const { selectedTemplateId } = this.props;
    const { canvasInstances } = this.state;
    const result = [];

    canvasInstances.forEach(drawingCanvas => {
      result.push(drawingCanvas.exportToData(selectedTemplateId));
    });

    return result;
  };

  handleSaveBtnClick = () => {
    const { onSave } = this.props;
    const canvasData = this.exportCanvasToData();

    this.setState({ showLoading: true });
    if (onSave(canvasData)) {
      this.setState({ showLoading: false });
    }
  };

  handleDiscardBtnClick = () => {
    if (confirm('Are you sure you want to discard this note?')) {
      const { canvasInstances, activeCanvas } = this.state;
      canvasInstances[activeCanvas].clearCanvas();
    }
  };

  handleUndoBtnClick = () => {
    const { canvasInstances, activeCanvas } = this.state;
    canvasInstances[activeCanvas].undo();
  };

  handleRedoBtnClick = () => {
    const { canvasInstances, activeCanvas } = this.state;
    canvasInstances[activeCanvas].redo();
  };

  handleResetBtnClick = () => {
    const { canvasInstances, activeCanvas } = this.state;
    canvasInstances[activeCanvas].resetZoom();
  };

  handleZoomInBtnClick = () => {
    const { canvasInstances, activeCanvas } = this.state;
    canvasInstances[activeCanvas].zoomIn();
  };

  handleZoomOutBtnClick = () => {
    const { canvasInstances, activeCanvas } = this.state;
    canvasInstances[activeCanvas].zoomOut();
  };

  handlePenModeClick = () => {
    const { canvasInstances, isStylusEnable } = this.state;
    this.setState({
      isStylusEnable: !isStylusEnable,
    });
    canvasInstances.forEach(canvas => {
      canvas.updateStylusMode(!isStylusEnable);
      canvas.setPointerEvent(!isStylusEnable);
    });

    var $;
    $ = require('jquery');
    if (!isStylusEnable) {
      $('#root').on('touchmove', function(e) {
        if (e.originalEvent.scale > 1) {
          e.preventDefault();
        }
      });
    } else {
      $('root').off('touchmove');
    }
  };

  handleDrawingModeChange = ({ drawingMode, color, brushSize }) => {
    const { canvasInstances } = this.state;
    canvasInstances.forEach(canvas => {
      canvas.changeDrawingMode({ drawingMode, color, brushSize });
    });
  };

  openTemplateDialog = () => {
    this.setState({
      showSelectTemplateDialog: true,
    });
  };

  closeTemplateDialog = () => {
    this.setState({
      showSelectTemplateDialog: false,
    });
  };

  handleTemplateSelect = (
    templateId,
    templateData,
    groupedTemplates,
    fileName
  ) => {
    const { onTemplateChange } = this.props;
    if (templateId !== 'blankId') {
      onTemplateChange({
        templateId,
        templateData,
        groupedTemplates,
        fileName,
      });
    }

    this.closeTemplateDialog();
  };

  handleUserPreferenceClick = () => {
    this.setState({
      showPreferences: !this.state.showPreferences,
    });
  };

  openUserPreference = () => {
    this.setState({
      showPreferences: true,
    });
  };

  closeUserPreference = () => {
    this.setState({
      showPreferences: false,
    });
  };

  handleVASSlider = ({ key, type, maxValue, value }) => {
    const { symptopSelection, canvasInstances } = this.state;
    const symptom = symptopSelection.find(s => s.key == key);

    if (symptom) {
      symptom.value = value;
      symptom.maxValue = maxValue;
    } else {
      symptopSelection.push({
        key,
        type,
        value,
        maxValue,
      });
    }

    canvasInstances[canvasInstances.length - 1].drawSymptomSelections(
      symptopSelection
    );
    this.setState({ symptopSelection });
  };

  handleSymptomClick = ({ key, type, name }) => {
    const { symptopSelection, canvasInstances } = this.state;
    const symptom = symptopSelection.find(s => s.key === key);

    if (symptom) {
      const nameIndex = symptom.value.indexOf(name);
      if (nameIndex > -1) {
        symptom.value.splice(nameIndex, 1);
      } else {
        symptom.value.push(name);
      }
    } else {
      symptopSelection.push({
        key,
        type,
        value: [name],
      });
    }

    canvasInstances[canvasInstances.length - 1].drawSymptomSelections(
      symptopSelection
    );
    this.setState({ symptopSelection });
  };

  handlePreviousClick = () => {
    // const { canvasData } = this.props;
    const { activeCanvas, canvasData } = this.state;
    this.setState({
      activeCanvas: mod(activeCanvas - 1, canvasData.length),
    });
  };

  handleNextClick = () => {
    // const { canvasData } = this.props;
    const { activeCanvas, canvasData } = this.state;

    this.setState({
      activeCanvas: mod(activeCanvas + 1, canvasData.length),
    });
  };

  handleCloseTooltip = () => {};

  render() {
    const {
      activeCanvas,
      symptopSelection,
      showSelectTemplateDialog,
      showPreferences,
      canvasData,
      isStylusEnable,
      showToolTip,
      showMaxPageLimit,
    } = this.state;

    const {
      // canvasData,
      listUserPreferences,
      shouldHasHomeBtn,
      shouldHasTemplateBtn,
      notShowAddPage,
      isInConsentForm,
      isBackBtnVisible,
    } = this.props;
    // const preparedCanvasData = isBlank ? blankCanvasData : canvasData;
    // console.log('render props canvas', this.props.canvasData);
    // console.log('render state canvas', canvasData);
    return (
      <div id="DrawingNote" style={styles.rootContainer}>
        {this.state.showLoading && <Loading height="100%" overlay />}

        <NavigationMenu
          isHomeBtnVisible={shouldHasHomeBtn && !isInConsentForm}
          isTemplateBtnVisible={shouldHasTemplateBtn && !isInConsentForm}
          handleBackBtnClick={this.handleBackBtnClick}
          handleHomeBtnClick={this.handleHomeBtnClick}
          handleTemplateBtnClick={this.openTemplateDialog}
          handleSaveBtnClick={this.handleSaveBtnClick}
          handleDiscardBtnClick={this.handleDiscardBtnClick}
          handleUndoBtnClick={this.handleUndoBtnClick}
          handleRedoBtnClick={this.handleRedoBtnClick}
          handleResetBtnClick={this.handleResetBtnClick}
          handleZoomInBtnClick={this.handleZoomInBtnClick}
          handleZoomOutBtnClick={this.handleZoomOutBtnClick}
          handlePenModeClick={this.handlePenModeClick}
          isStylusEnable={isStylusEnable}
          isStylusBtnVisible={!isInConsentForm}
          isBackBtnVisible={isBackBtnVisible}
        />

        <div id="canvasWrapper" style={styles.canvasWrapper}>
          <div id="allCanvases">
            {canvasData.map((canvas, index) => {
              const display = activeCanvas === index ? 'inline-block' : 'none';
              return (
                <div key={canvas.id} style={{ display }}>
                  <canvas id={canvas.id} />
                </div>
              );
            })}
          </div>

          <SymptomsMenu
            defaultMenuData={newPredefinedSelection}
            selectedItemsData={symptopSelection}
            onSymptomClick={this.handleSymptomClick}
            onUserPreferenceClick={this.handleUserPreferenceClick}
            onVASSliderChange={this.handleVASSlider.bind(this)}
            userPreferences={listUserPreferences}
            isInConsentForm={isInConsentForm}
          />

          <DrawingMenu onDrawingModeChange={this.handleDrawingModeChange} />

          {!notShowAddPage && (
            <Grid
              id="add-page"
              container
              justifyContent="center"
              alignItems="baseline"
              spacing={2}
            >
              {shouldHasHomeBtn && (
                <Tooltip title="Add a page" placement="left">
                  <Grid item id="previous-page">
                    <IconButton onClick={this.handleNewPage}>
                      <NoteAddIcon />
                    </IconButton>
                  </Grid>
                </Tooltip>
              )}

              <Grid item id="previous-page">
                <IconButton
                  onClick={this.handlePreviousClick}
                  disabled={activeCanvas + 1 === 1}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
              <Grid item id="page-txt">
                <div style={{ userSelect: 'none' }}>
                  Page {activeCanvas + 1} of {canvasData.length}
                </div>
              </Grid>
              <Grid item id="next-page">
                <IconButton
                  onClick={this.handleNextClick}
                  disabled={activeCanvas + 1 === canvasData.length}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </div>

        <ListTemplateDialog
          shouldShow={showSelectTemplateDialog}
          onSelectTemplate={this.handleTemplateSelect}
          onTapOutside={this.closeTemplateDialog}
        />
        {showPreferences && (
          <PredefinedSelectionsEditor
            shouldShowDialog={showPreferences}
            onClosePreferenceDialog={this.closeUserPreference}
          />
        )}

        {isInConsentForm && (
          <Snackbar open={showToolTip} autoHideDuration={3000}>
            <Alert
              severity="info"
              onClose={() => {
                this.setState({
                  showToolTip: false,
                });
              }}
            >
              Please use your mouse, stylus or finger to circle where your pain
              is on the body chart.
            </Alert>
          </Snackbar>
        )}

        {showMaxPageLimit && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showMaxPageLimit}
            autoHideDuration={3000}
          >
            <Alert
              severity="warning"
              onClose={() => {
                this.setState({
                  showMaxPageLimit: false,
                });
              }}
            >
              Maximum of 5 pages allowed for a drawing note. Please start a new
              note if needed.
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

export default DrawingToolComponent;

import React from 'react';


import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';

import { withStyles } from '@material-ui/core/styles';
import { MINOR_BLACK, PRIMARY } from '../../style/constants';
import { filterSectionsByRowAndPage } from '../../utility';

const ReorderDialog = ({
  classes,
  dialog,
  onCloseDialog,
  onReorderSection,
  onSaveSection,
  rowsRange,
  sections,
}) => (
  <Dialog open={dialog.show} onClose={onCloseDialog}>
    <Grid className={classes.modal}>
      {rowsRange.length > 0 &&
        rowsRange.map(rowNumber => (
          <Grid key={rowNumber} className={classes.row}>
            {filterSectionsByRowAndPage(sections, rowNumber).map(section => (
              <Grid
                key={section.id || section.title}
                className={classes.section}
                role="button"
                tabIndex={0}
                onKeyPress={async () => {
                  await onReorderSection(dialog.selectedSection, section);
                  onSaveSection();
                  onCloseDialog();
                }}
                onClick={async () => {
                  await onReorderSection(dialog.selectedSection, section);
                  onSaveSection();
                  onCloseDialog();
                }}
                style={
                  dialog.selectedSection &&
                  dialog.selectedSection.id === section.id
                    ? { backgroundColor: PRIMARY }
                    : null
                }
              />
            ))}
          </Grid>
        ))}
    </Grid>
  </Dialog>
);

const styles = {
  modal: {
    padding: 24,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    minHeight: 215,
    overflow: 'auto',
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  section: {
    margin: 2,
    flex: 1,
    height: 65,
    width: 125,
    border: '1px solid black',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: MINOR_BLACK,
    },
  },
};

export default withStyles(styles)(ReorderDialog);

import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withRouter } from 'react-router-dom';

import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { noteUpsert } from '../../data/redux/actions/note';

const groupNotesGeneralQuery = gql`
    query groupNotes($groupId: ID!) {
        node(id: $groupId) {
            __typename
            id
            ... on Group {
                notes {
                    __typename
                    id
                    categories {
                        id
                    }
                    createdAt
                    title
                    isSignedOff
                    signedOffTime
                    patient {
                        id
                        name
                    }
                    author {
                        id
                        name
                    }
                    episodes {
                        id
                        name
                        startDate
                        endDate
                    }
                    ... on DrawingNote {
                        __typename
                        pageNumber
                        drawingData
                        template {
                            pageNumber
                            id
                            file {
                                id
                                encodedFile
                            }
                        }
                    }
                }
            }
        }
    }
`;

const upsertDrawingNoteMutation = gql`
    mutation upsertDrawingNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $episodes: [ID]
        $categories: [ID]
        $groupId: ID
        $title: String
        $drawingData: String
        $pageNumber: Int
        $templateId: ID
        $deletedAt: DateTime
    ) {
        upsertDrawingNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            episodes: $episodes
            categories: $categories
            group: $groupId
            title: $title
            drawingData: $drawingData
            pageNumber: $pageNumber
            template: $templateId
            deletedAt: $deletedAt
        ) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
                createdAt
                author {
                    id
                    name
                }
                patient {
                    id
                    name
                }
            }
        }
    }
`;

const signOffNotesMutation = gql`
    mutation signOffNotes($noteIds: [ID!]) {
        signOffNotes(noteIds: $noteIds) {
            signedOffTime
        }
    }
`;

const signOnNotesMutation = gql`
    mutation signOnNotes($noteIds: [ID!]) {
        signOnNotes(noteIds: $noteIds) {
            signedOffTime
        }
    }
`;

const tagNoteMutation = gql`
    mutation tagNote($noteId: ID!, $categories: [ID], $episodes: [ID]) {
        tagNote(id: $noteId, episodes: $episodes, categories: $categories) {
            note {
                id
                __typename
                episodes {
                    id
                }
                categories {
                    id
                }
                title
            }
        }
    }
`;

const upsertGroupMutation = gql`
    mutation upsertGroup($practiceId: ID!) {
        upsertGroup(practice: $practiceId) {
            group {
                id
            }
        }
    }
`;

const initialForm = {
    categoryIdsArray: [],
    episodeIdsArray: [],
    loading: false,
    error: false,
    title: undefined,
    groupId: undefined,
    groupNotes: [],
    isSignedOff: false,
    signedOffTime: null,
    createdAt: undefined,
    isAuthor: true,
    patientName: undefined,
    episodes: [],
    author: undefined,
};

const groupHandlers = {
    onGroupForEachSave:
        ({ form, onSave }) =>
        () =>
            form.groupNotes.forEach((note, index) => onSave(index)),
};

const localHandlers = {
    onChevronLeft:
        ({ index, updateIndex }) =>
        () =>
            updateIndex(index - 1),
    onChevronRight:
        ({ index, updateIndex }) =>
        () =>
            updateIndex(index + 1),
    onDuplicate:
        ({ dispatch, form, practiceId, onSaveDuplicate, upsertGroup }) =>
        async () => {
            const response = await upsertGroup({ practiceId });

            for (const note of form.groupNotes) {
                const index = form.groupNotes.indexOf(note);
                await onSaveDuplicate(index, response.data.upsertGroup.group.id);
            }

            await dispatch(noteUpsert({ groupId: response.data.upsertGroup.group.id }));
        },
    onEditNote:
        ({ form, history, patientId }) =>
        () => {
            var templateId = form.groupId ? form.groupId : 'blankId';
            var drawingNoteId = form.groupId;
            var isGroup = true;
            var episodeId = form.episodes.length > 0 ? form.episodes[0].id : undefined;
            var pathName = `/patient/${patientId}/note/templates/webdrawingnote`;
            var searchString = `?isGroup=${isGroup}`;
            if (templateId) {
                searchString += `&templateId=${templateId}`;
            }
            if (episodeId) {
                searchString += `&episodeId=${episodeId}`;
            }
            if (drawingNoteId) {
                searchString += `&drawingNoteId=${drawingNoteId}`;
            }
            history.push({
                pathname: pathName,
                search: searchString,
            });
        },
};

const apiHandlers = {
    onSaveDuplicate:
        ({ dispatch, form, history, patientId, updateForm, upsertDrawingNote, userId }) =>
        async (index, groupId) => {
            updateForm({ ...form, loading: true });

            const options = {
                variables: {
                    noteId: null,
                    userId,
                    patientId,
                    groupId,
                    episodes: form.episodeIdsArray,
                    categories: form.categoryIdsArray,
                    title: form.title,
                    pageNumber: form.groupNotes[index].pageNumber,
                    templateId: form.groupNotes[index].template.id,
                    drawingData: form.groupNotes[index].drawingData,
                },
            };

            try {
                const response = await upsertDrawingNote(options);

                updateForm((prevForm) => {
                    const newGroupNotes = JSON.parse(JSON.stringify(prevForm.groupNotes));

                    newGroupNotes.splice(index, 1, {
                        ...prevForm.groupNotes[index],
                        id: response.data.upsertDrawingNote.note.id,
                        title: prevForm.title,
                    });

                    return {
                        ...prevForm,
                        loading: false,
                        error: false,
                        groupId,
                        groupNotes: newGroupNotes,
                        createdAt: response.data.upsertDrawingNote.note.createdAt,
                        isAuthor: userId === response.data.upsertDrawingNote.note.author.id,
                        author: response.data.upsertDrawingNote.note.author,
                        patientName: response.data.upsertDrawingNote.note.patient.name,
                        isSignedOff: response.data.upsertDrawingNote.note.isSignedOff,
                        signedOffTime: response.data.upsertDrawingNote.note.signedOffTime,
                    };
                });

                if (index === 0) {
                    dispatch(
                        noteUpsert({
                            id: response.data.upsertDrawingNote.note.id,
                            __typename: response.data.upsertDrawingNote.note.__typename,
                            title: response.data.upsertDrawingNote.note.title,
                            isSignedOff: response.data.upsertDrawingNote.note.isSignedOff,
                            signedOffTime: response.data.upsertDrawingNote.note.signedOffTime,
                        })
                    );
                }

                history.push(`/patient/${patientId}/note/drawings/${groupId}`);
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onSave:
        ({ dispatch, form, groupId, patientId, updateForm, upsertDrawingNote, userId }) =>
        async (index) => {
            updateForm({ ...form, loading: true });

            const editOptions = {
                variables: {
                    allowUpdate: true,
                    noteId: form.groupNotes[index].id,
                    userId,
                    patientId,
                    groupId,
                    episodes: form.episodeIdsArray,
                    categories: form.categoryIdsArray,
                    title: form.title || 'Untitled Drawing Note',
                    pageNumber: form.groupNotes[index].pageNumber,
                    templateId: form.groupNotes[index].template.id,
                    drawingData: form.groupNotes[index].drawingData,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    upsertDrawingNote: {
                        __typename: 'NewDrawingNotePayload',
                        note: {
                            __typename: 'DrawingNote',
                            id: form.groupNotes[index].id,
                            isSignedOff: form.isSignedOff,
                            signedOffTime: form.signedOffTime,
                            createdAt: form.createdAt,
                            drawingData: form.groupNotes[index].drawingData,
                            pageNumber: form.groupNotes[index].pageNumber,
                            title: form.title || 'Untitled Drawing Note',
                            group: {
                                __typename: 'Group',
                                id: groupId,
                            },
                            template: {
                                __typename: 'Template',
                                id: form.groupNotes[index].template.id,
                                pageNumber: form.groupNotes[index].template.pageNumber,
                                file: {
                                    __typename: 'File',
                                    id: form.groupNotes[index].template.file.id,
                                    encodedFile: form.groupNotes[index].template.file.encodedFile,
                                },
                            },
                            patient: {
                                __typename: 'Patient',
                                id: patientId,
                                name: form.patientName,
                            },
                            author: {
                                __typename: 'User',
                                id: form.author && form.author.id,
                                name: form.author && form.author.name,
                            },
                            categories: form.categoryIdsArray.map((categoryId) => ({
                                __typename: 'Category',
                                id: categoryId,
                            })),
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: groupNotesGeneralQuery,
                        variables: { groupId },
                    },
                ],
            };
            try {
                const response = await upsertDrawingNote(editOptions);

                updateForm((prevForm) => {
                    const newGroupNotes = JSON.parse(JSON.stringify(prevForm.groupNotes));

                    newGroupNotes.splice(index, 1, {
                        ...prevForm.groupNotes[index],
                        id: response.data.upsertDrawingNote.note.id,
                        title: prevForm.title || 'Untitled Drawing Note',
                    });

                    return {
                        ...prevForm,
                        loading: false,
                        error: false,
                        groupId,
                        groupNotes: newGroupNotes,
                        createdAt: response.data.upsertDrawingNote.note.createdAt,
                        author: response.data.upsertDrawingNote.note.author,
                        patientName: response.data.upsertDrawingNote.note.patient.name,
                    };
                });
                if (index === 0) {
                    dispatch(
                        noteUpsert({
                            id: response.data.upsertDrawingNote.note.id,
                            __typename: response.data.upsertDrawingNote.note.__typename,
                            title: response.data.upsertDrawingNote.note.title,
                            isSignedOff: response.data.upsertDrawingNote.note.isSignedOff,
                            signedOffTime: response.data.upsertDrawingNote.note.signedOffTime,
                        })
                    );
                }
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onSign:
        ({ dispatch, form, groupId, signOffNotes, signOnNotes, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                let response;
                if (form.isSignedOff) {
                    response = await signOnNotes({
                        variables: {
                            noteIds: form.groupNotes.map((note) => note.id),
                        },
                        refetchQueries: [
                            {
                                query: groupNotesGeneralQuery,
                                variables: { groupId },
                            },
                        ],
                    });
                } else {
                    response = await signOffNotes({
                        variables: {
                            noteIds: form.groupNotes.map((note) => note.id),
                        },
                        refetchQueries: [
                            {
                                query: groupNotesGeneralQuery,
                                variables: { groupId },
                            },
                        ],
                    });
                }

                const signedTime = form.isSignedOff ? response.data.signOnNotes.signedOffTime : response.data.signOffNotes.signedOffTime;
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error: false,
                    isSignedOff: !prevForm.isSignedOff,
                    signedOffTime: signedTime,
                }));
                dispatch(
                    noteUpsert({
                        isSignedOff: Boolean(signedTime),
                        signedOffTime: signedTime,
                    })
                );
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onTag:
        ({ form, tagNote, updateForm }) =>
        async (noteId) => {
            updateForm({ ...form, loading: true });

            try {
                await tagNote({
                    variables: {
                        noteId,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                    },
                });
                updateForm({ ...form, loading: false, error: false });
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onArchiveNote:
        ({ form, userId, patientId, updateForm, upsertDrawingNote, history, groupId }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                await form.groupNotes.forEach((note, index) => {
                    upsertDrawingNote({
                        variables: {
                            allowUpdate: true,
                            noteId: form.groupNotes[index].id,
                            userId,
                            patientId,
                            groupId,
                            episodes: form.episodeIdsArray,
                            categories: form.categoryIdsArray,
                            title: form.title,
                            pageNumber: form.groupNotes[index].pageNumber,
                            templateId: form.groupNotes[index].template.id,
                            drawingData: form.groupNotes[index].drawingData,
                            deletedAt: new Date(),
                        },
                    });
                });

                history.goBack();
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
};

const GroupDrawingNoteContainer = compose(
    withRouter,
    connect(({ user, practice }) => ({
        userId: user.id,
        isClinician: user.roles.includes('CLINICIAN'),
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        practiceId: practice.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withState('index', 'updateIndex', 0),
    withState('form', 'updateForm', initialForm),
    withState('tempNotes', 'updateTempNotes', undefined),
    withState('titleFocus', 'updateTitleFocus', false),
    withState('showModal', 'updateShowModal', false),
    withState('notes', 'updateNotes', []),
    withProps(({ form, match, location, isClinician, isAdmin, groupIdForce, isConsentFormSubscription }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
        groupId: groupIdForce || match.params.groupId,
        canEdit: !form.isSignedOff && (isClinician || isAdmin) && form.isAuthor && !isConsentFormSubscription,
        isHideAction: isConsentFormSubscription,
    })),
    graphql(groupNotesGeneralQuery, {
        name: 'groupQuery',
        options: ({ groupId, notes, updateNotes, updateForm }) => ({
            variables: { groupId },
            fetchPolicy: 'no-cache',
            onCompleted: (generalData) => {
                const generalNotes = generalData.node.notes;
                if (notes.length > 0) {
                    const result = notes.map((note, index) => ({
                        ...note,
                        drawingData: generalNotes[index].template,
                    }));
                    updateNotes(result);
                } else {
                    updateNotes(generalNotes);
                }
            },
        }),
    }),

    withProps(({ groupQuery }) => {
        return {
            error: groupQuery.error,
            status: {
                loading: groupQuery.networkStatus === 1,
                success: groupQuery.networkStatus === 7 && Boolean(groupQuery.node),
                error: groupQuery.networkStatus === 8,
            },
        };
    }),
    branch(
        ({ status }) => status.success,
        withProps(({ groupQuery }) => ({
            __typename: groupQuery.node.__typename,
            id: groupQuery.node.id,
        }))
    ),
    graphql(upsertDrawingNoteMutation, { name: 'upsertDrawingNote' }),
    graphql(upsertGroupMutation, { name: 'upsertGroup' }),
    graphql(signOffNotesMutation, { name: 'signOffNotes' }),
    graphql(signOnNotesMutation, { name: 'signOnNotes' }),
    graphql(tagNoteMutation, { name: 'tagNote' }),
    withHandlers(apiHandlers),
    withHandlers(groupHandlers),
    withHandlers(localHandlers),
    withPropsOnChange(['onGroupForEachSave'], ({ onGroupForEachSave }) => ({
        onGroupForEachSave: debounce(onGroupForEachSave, 1000),
    })),
    withPropsOnChange(['onSave'], ({ onSave }) => ({
        onSave: debounce(onSave, 1000),
    })),
    lifecycle({
        componentDidMount() {
            const { episodeId, groupId, notes, templates, form, updateForm, status, userId } = this.props;
            if (groupId && notes && notes.length > 0 && notes[0].episodes && status && status.success) {
                updateForm({
                    ...form,
                    episodeIdsArray: notes[0].episodes.map((episode) => episode.id),
                    categoryIdsArray: notes[0].categories.map((category) => category.id),
                    title: notes[0].title,
                    groupId,
                    groupNotes: notes.map((note) => ({
                        id: note.id,
                        drawingData: note.drawingData,
                        pageNumber: note.pageNumber,
                        template: {
                            id: get(note, 'template.id', null),
                            pageNumber: get(note, 'template.pageNumber', null),
                            file: {
                                id: get(note, 'template.file.id', null),
                                encodedFile: get(note, 'template.file.encodedFile', null),
                            },
                        },
                    })),
                    isSignedOff: notes[0].isSignedOff,
                    signedOffTime: notes[0].signedOffTime,
                    createdAt: notes[0].createdAt,
                    author: notes[0].author,
                    patientName: notes[0].patient.name,
                    isAuthor: userId === get(notes[0], 'author.id'),
                    episodes: notes[0].episodes,
                    initialFocus: 'false',
                });
            } else if (status && templates && status.success) {
                updateForm({
                    ...form,
                    title: templates[0].name,
                    episodeIdsArray: episodeId ? [episodeId] : [],
                    groupNotes: templates.map((template) => ({
                        id: undefined,
                        drawingData: undefined,
                        pageNumber: template.pageNumber,
                        template: {
                            id: template.id,
                            pageNumber: template.pageNumber,
                            file: {
                                id: get(template, 'file.id', null),
                                encodedFile: get(template, 'file.encodedFile', null),
                            },
                        },
                    })),
                });
            }
        },
        async componentDidUpdate(prevProps) {
            const { episodeId, groupId, notes, templates, form, updateForm, status, userId } = this.props;

            if (this.props.notes !== prevProps.notes) {
                if (groupId && notes && notes.length > 0 && notes[0].episodes && status && status.success) {
                    updateForm({
                        ...form,
                        episodeIdsArray: notes[0].episodes.map((episode) => episode.id),
                        categoryIdsArray: notes[0].categories.map((category) => category.id),
                        title: notes[0].title,
                        groupId,
                        groupNotes: notes.map((note) => ({
                            id: note.id,
                            drawingData: note.drawingData,
                            pageNumber: note.pageNumber,
                            template: {
                                id: get(note, 'template.id', null),
                                pageNumber: get(note, 'template.pageNumber', null),
                                file: {
                                    id: get(note, 'template.file.id', null),
                                    encodedFile: get(note, 'template.file.encodedFile', null),
                                },
                            },
                        })),
                        isSignedOff: notes[0].isSignedOff,
                        signedOffTime: notes[0].signedOffTime,
                        createdAt: notes[0].createdAt,
                        author: notes[0].author,
                        patientName: notes[0].patient.name,
                        isAuthor: userId === get(notes[0], 'author.id'),
                        episodes: notes[0].episodes,
                        initialFocus: 'false',
                    });
                } else if (status && templates && status.success) {
                    updateForm({
                        ...form,
                        title: templates[0].name,
                        episodeIdsArray: episodeId ? [episodeId] : [],
                        groupNotes: templates.map((template) => ({
                            id: undefined,
                            drawingData: undefined,
                            pageNumber: template.pageNumber,
                            template: {
                                id: template.id,
                                pageNumber: template.pageNumber,
                                file: {
                                    id: get(template, 'file.id', null),
                                    encodedFile: get(template, 'file.encodedFile', null),
                                },
                            },
                        })),
                    });
                }
            }
        },
    })
);

export default GroupDrawingNoteContainer;

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const CheckboxesSection = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onChangeCheckbox,
  onFocus,
  onSave,
  section,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  checkboxesData,
}) => (
  <Grid container direction="column">
    <Typography variant="body1" className={classes.title}>
      {section.title}
    </Typography>
    {checkboxesData &&
      Array.isArray(checkboxesData) &&
      checkboxesData.map((checkboxData, checkboxIndex) => {
        return (
          <div key={checkboxIndex}>
            <Checkbox
              InputProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              disabled={!canEdit}
              className={classes.inputText}
              checked={checkboxData.value || false}
              onChange={async event => {
                await onChangeCheckbox(
                  event.target.checked,
                  checkboxIndex,
                  section
                );
                onSave();
              }}
              // fullWidth
            />
            <label>{checkboxData.title}</label>
          </div>
        );
      })}
  </Grid>
);

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles)
)(CheckboxesSection);

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

const UPSERT_CLINICIAN_AVAIL = gql`
  mutation upsertClinician(
    $practiceEncodedId: ID!
    $id: ID
    $blockedOut: String
    $availability: [String]
  ) {
    upsertClinician(
      practiceEncodedId: $practiceEncodedId
      id: $id
      blockedOut: $blockedOut
      availability: $availability
    ) {
      name
      id
      blockedOut
      availability
    }
  }
`;

const UpdateAvailabilityDialogContainer = compose(
  connect(({ practice }) => ({
    practiceEncodedId: practice.id,
  })),
  graphql(UPSERT_CLINICIAN_AVAIL),
  withHandlers({
    onCreateAvailability: ({
      practiceEncodedId,
      mutate,
    }) => async clinicianAvail => {
      try {
        await mutate({
          variables: {
            ...clinicianAvail,
            blockedOut: JSON.stringify(clinicianAvail.blockedOut),
            practice: practiceEncodedId,
          },
        });
      } catch (error) {
        console.log('Error: ', error); // eslint-disable-line
        throw new Error(error);
      }
    },
  })
);

export default UpdateAvailabilityDialogContainer;

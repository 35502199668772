import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import get from 'lodash/get';
import lodashMap from 'lodash/map';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';

import { formTemplateCleared } from '../../data/redux/actions/formTemplate';
import { noteUpsert } from '../../data/redux/actions/note';

const FORM_NOTE_QUERY = gql`
    query formNote($noteId: ID!) {
        node(id: $noteId) {
            id
            __typename
            ... on FormNote {
                createdAt
                rows
                categories {
                    id
                }
                author {
                    id
                    name
                }
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                isSignedOff
                patient {
                    id
                    name
                    dob
                }
                sections {
                    id
                    type
                    title
                    rowIndexes
                    columnIndexes
                    ... on TextSection {
                        text
                    }
                    ... on YesNoSection {
                        yesNoValue
                    }
                    ... on NumberScale10Section {
                        numberScale10Value
                    }
                    ... on NumberScale100Section {
                        numberScale100Value
                    }
                    ... on DrawingSection {
                        drawingData
                        pageNumber
                        group {
                            id
                        }
                        template {
                            pageNumber
                            id
                            file {
                                id
                                encodedFile
                            }
                            group {
                                id
                            }
                        }
                    }
                    ... on CheckboxesSection {
                        checkboxesData
                    }
                    ... on SpreadsheetSection {
                        spreadsheetData
                    }
                }
                signedOffTime
                title
            }
        }
    }
`;

const SIGN_OFF_NOTES = gql`
    mutation signOffNotes($noteIds: [ID!]) {
        signOffNotes(noteIds: $noteIds) {
            signedOffTime
        }
    }
`;

const SIGN_ON_NOTE = gql`
    mutation signOnNote($noteId: ID!) {
        signOnNote(noteId: $noteId) {
            signedOffTime
        }
    }
`;

const UPSERT_FORM_NOTE = gql`
    mutation upsertFormNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $categories: [ID]
        $episodes: [ID]
        $title: String
        $rows: Int
        $sections: [FormNoteSectionInput]
        $deletedAt: DateTime
    ) {
        upsertFormNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            categories: $categories
            episodes: $episodes
            title: $title
            rows: $rows
            sections: $sections
            deletedAt: $deletedAt
        ) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
                sections {
                    id
                    type
                    title
                    rowIndexes
                    columnIndexes
                    ... on TextSection {
                        text
                    }
                    ... on YesNoSection {
                        yesNoValue
                    }
                    ... on NumberScale10Section {
                        numberScale10Value
                    }
                    ... on NumberScale100Section {
                        numberScale100Value
                    }
                    ... on CheckboxesSection {
                        checkboxesData
                    }
                    ... on SpreadsheetSection {
                        spreadsheetData
                    }
                    ... on DrawingSection {
                        drawingData
                        group {
                            id
                        }
                        template {
                            pageNumber
                            id
                            file {
                                id
                                encodedFile
                            }
                            group {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

const TAG_NOTE = gql`
    mutation tagNote($noteId: ID!, $categories: [ID], $episodes: [ID]) {
        tagNote(id: $noteId, episodes: $episodes, categories: $categories) {
            note {
                id
                __typename
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                categories {
                    id
                }
                title
            }
        }
    }
`;

const DUPLICATE_FORM_NOTE = gql`
    mutation duplicateFormNote($existingFormNoteId: ID, $author: ID!) {
        duplicateFormNote(existingFormNoteId: $existingFormNoteId, author: $author) {
            note {
                id
                sections {
                    ... on TextSection {
                        id
                        type
                        title
                        text
                    }
                }
            }
        }
    }
`;

const initialForm = {
    loading: false,
    error: false,
    id: undefined,
    templateId: undefined,
    title: undefined,
    categoryIdsArray: [],
    episodeIdsArray: [],
    sections: [],
    isSignedOff: false,
    signedOffTime: null,
    rows: 0,
    preventQuery: false,
    autoFocus: undefined,
    episodes: [],
    author: undefined,
    isAuthor: true,
    patientName: undefined,
    patientDob: undefined,
};

const mapArrayOmitProps = (array, props) => lodashMap(array, (key) => omit(key, props));

const apiHandlers = {
    onDuplicate:
        ({ form, patientId, updateForm, duplicateFormNoteMutation, userId, history, dispatch }) =>
        async () => {
            updateForm({
                ...form,
                loading: true,
                error: false,
            });

            try {
                const response = await duplicateFormNoteMutation({
                    variables: {
                        existingFormNoteId: form.id,
                        author: userId,
                    },
                });

                updateForm({
                    ...form,
                    loading: false,
                    error: false,
                });

                history.push(`/patient/${patientId}/note/form/${response.data.duplicateFormNote.note.id}`);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSave:
        ({ form, patientId, updateForm, noteMutation, userId, history, dispatch, notAllowSave }) =>
        async () => {
            if (notAllowSave) {
                return;
            }
            if (!form.id) {
                updateForm({
                    ...form,
                    loading: true,
                });
            } else {
                updateForm({
                    ...form,
                    loading: true,
                    preventQuery: true,
                });
            }

            // When creating new sections, provide the sections with a groupId and pageNumber
            // Using groupId of corresponding FormTemplateSection and pageNumber of Template
            if (!form.id) {
                form.sections.map((section) => {
                    if (section.group) {
                        const index = form.sections.indexOf(section);

                        form.sections.splice(index, 1, {
                            ...section,
                            groupId: section.group.id,
                            pageNumber: section.template.pageNumber,
                        });
                    }
                    return section;
                });
            }

            const newSections = JSON.parse(JSON.stringify(form.sections));
            const newSections2 = newSections.map((section) => {
                if (section.type === 'CHECKBOXES') {
                    if (Array.isArray(section.checkboxesData)) {
                        section.checkboxesData = JSON.stringify(section.checkboxesData);
                    }
                }
                if (section.type === 'SPREADSHEET') {
                    if (typeof section.spreadsheetData === 'object' && section.spreadsheetData !== null && !Array.isArray(section.spreadsheetData)) {
                        section.spreadsheetData = JSON.stringify(section.spreadsheetData);
                    }
                }
                return section;
            });

            // Remove form template section.id's so new section.id's can be generated
            const newOptions = {
                variables: {
                    userId,
                    patientId,
                    categories: form.categoryIdsArray,
                    episodes: form.episodeIdsArray,
                    title: form.title || 'Untitled Form Note',
                    rows: form.rows,
                    sections: mapArrayOmitProps(newSections2, ['id', 'template', '__typename', 'group']),
                },
            };
            const editOptions = {
                variables: {
                    noteId: form.id,
                    allowUpdate: true,
                    userId,
                    patientId,
                    categories: form.categoryIdsArray,
                    episodes: form.episodeIdsArray,
                    title: form.title || 'Untitled Form Note',
                    rows: form.rows,
                    sections: mapArrayOmitProps(newSections2, ['template', '__typename', 'group']),
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    upsertFormNote: {
                        __typename: 'NewFormNotePayload',
                        note: {
                            __typename: 'FormNote',
                            id: form.id,
                            isSignedOff: form.isSignedOff,
                            signedOffTime: form.signedOffTime,
                            rows: form.rows,
                            title: form.title,
                            patient: {
                                __typename: 'Patient',
                                id: patientId,
                                name: form.patientName,
                            },
                            episodes: form.episodes.map((episode) => ({
                                __typename: 'Episode',
                                id: episode.id,
                                name: episode.name,
                                startDate: episode.startDate,
                                endDate: episode.endDate,
                            })),
                            author: {
                                __typename: 'User',
                                id: form.author && form.author.id,
                                name: form.author && form.author.name,
                            },
                            categories: form.categoryIdsArray.map((categoryId) => ({
                                __typename: 'Category',
                                id: categoryId,
                            })),
                            sections: form.sections.map((section) => {
                                const sectionObject = {
                                    __typename: section.__typename,
                                    id: section.id,
                                    type: section.type,
                                    title: section.title,
                                    rowIndexes: section.rowIndexes,
                                    columnIndexes: section.columnIndexes,
                                };

                                switch (section.__typename) {
                                    case 'TextSection':
                                        return { ...sectionObject, text: section.text };
                                    case 'YesNoSection':
                                        return { ...sectionObject, yesNoValue: section.yesNoValue };
                                    case 'NumberScale10Section':
                                        return {
                                            ...sectionObject,
                                            numberScale10Value: section.numberScale10Value,
                                        };
                                    case 'NumberScale100Section':
                                        return {
                                            ...sectionObject,
                                            numberScale100Value: section.numberScale100Value,
                                        };
                                    case 'CheckboxesSection':
                                        return {
                                            ...sectionObject,
                                            checkboxesData: section.checkboxesData,
                                        };
                                    case 'SpreadsheetSection':
                                        return {
                                            ...sectionObject,
                                            spreadsheetData: section.spreadsheetData,
                                        };
                                    case 'DrawingSection':
                                        return {
                                            ...sectionObject,
                                            drawingData: section.drawingData,
                                            group: {
                                                __typename: 'Group',
                                                id: get(section, 'group.id', null),
                                            },
                                            template: {
                                                __typename: 'Template',
                                                id: get(section, 'template.id', null),
                                                pageNumber: get(section, 'template.pageNumber', null),
                                                file: {
                                                    __typename: 'File',
                                                    id: get(section, 'template.file.id', null),
                                                    encodedFile: get(section, 'template.file.encodedFile', null),
                                                },
                                                group: {
                                                    __typename: 'Group',
                                                    id: get(section, 'template.group.id', null),
                                                },
                                            },
                                        };
                                    default:
                                        return sectionObject;
                                }
                            }),
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: FORM_NOTE_QUERY,
                        variables: { noteId: form.id },
                    },
                ],
            };

            try {
                const response = await noteMutation(form.id ? editOptions : newOptions);

                dispatch(
                    noteUpsert({
                        id: response.data.upsertFormNote.note.id,
                        __typename: response.data.upsertFormNote.note.__typename,
                        title: response.data.upsertFormNote.note.title,
                        isSignedOff: response.data.upsertFormNote.note.isSignedOff,
                        signedOffTime: response.data.upsertFormNote.note.signedOffTime,
                    })
                );

                if (!form.id) {
                    history.replace(`/patient/${patientId}/note/form/${response.data.upsertFormNote.note.id}`);
                    // Update sections so new section.id's get carried over
                    updateForm((prevForm) => ({
                        ...prevForm,
                        loading: false,
                        preventQuery: true,
                        id: response.data.upsertFormNote.note.id,
                        sections: response.data.upsertFormNote.note.sections.map((section) => {
                            if (section.type === 'CHECKBOXES' && !Array.isArray(section.checkboxesData)) {
                                section.checkboxesData = JSON.parse(section.checkboxesData);
                            }
                            if (
                                section.type === 'SPREADSHEET' &&
                                !(
                                    typeof section.spreadsheetData === 'object' &&
                                    section.spreadsheetData !== null &&
                                    !Array.isArray(section.spreadsheetData)
                                )
                            ) {
                                section.spreadsheetData = JSON.parse(section.spreadsheetData);
                            }

                            return {
                                ...section,
                            };
                        }),
                        title: prevForm.title || 'Untitled Form Note',
                    }));
                } else {
                    updateForm((prevForm) => ({
                        ...prevForm,
                        loading: false,
                        preventQuery: true,
                        id: response.data.upsertFormNote.note.id,
                        title: prevForm.title,
                    }));
                }
                dispatch(formTemplateCleared());
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSign:
        ({ dispatch, form, signOffMutation, signOnNote, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                let response;
                if (form.isSignedOff) {
                    response = await signOnNote({
                        variables: {
                            noteId: form.id,
                        },
                        refetchQueries: [
                            {
                                query: FORM_NOTE_QUERY,
                                variables: { noteId: form.id },
                            },
                        ],
                    });
                } else {
                    response = await signOffMutation({
                        variables: {
                            noteIds: [form.id],
                        },
                        refetchQueries: [
                            {
                                query: FORM_NOTE_QUERY,
                                variables: { noteId: form.id },
                            },
                        ],
                    });
                }

                const signedTime = form.isSignedOff ? response.data.signOnNote.signedOffTime : response.data.signOffNotes.signedOffTime;
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    isSignedOff: !prevForm.isSignedOff,
                    signedOffTime: signedTime,
                    error: false,
                }));
                dispatch(
                    noteUpsert({
                        isSignedOff: Boolean(signedTime),
                        signedOffTime: signedTime,
                    })
                );
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onTag:
        ({ form, tagNote, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                const response = await tagNote({
                    variables: {
                        noteId: form.id,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                    },
                    refetchQueries: [
                        {
                            query: FORM_NOTE_QUERY,
                            variables: { noteId: form.id },
                        },
                    ],
                });
                updateForm({
                    ...form,
                    loading: false,
                    id: response.data.tagNote.note.id,
                    title: response.data.tagNote.note.title,
                    error: false,
                    episodes: response.data.tagNote.note.episodes,
                });
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onArchiveNote:
        ({ form, userId, patientId, updateForm, noteMutation, history }) =>
        async () => {
            updateForm({ ...form, loading: true });
            try {
                await noteMutation({
                    variables: {
                        noteId: form.id,
                        allowUpdate: true,
                        userId,
                        patientId,
                        categories: form.categoryIdsArray,
                        episodes: form.episodeIdsArray,
                        title: form.title || 'Untitled Form Note',
                        rows: form.rows,
                        sections: mapArrayOmitProps(form.sections, ['template', 'group', '__typename']),
                        deletedAt: new Date(),
                    },
                });
                updateForm({ ...form, loading: false, error: false });
                history.goBack();
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
};

const localHandlers = {
    onChangeText:
        ({ form, updateForm, cursors, updateCursors }) =>
        async (text, section, selectionStart) => {
            const newSections = form.sections.map((newSection) => newSection);
            const index = form.sections.indexOf(section);
            cursors[index] = selectionStart;
            updateCursors(cursors);
            newSections.splice(index, 1, {
                ...section,
                text,
            });

            await updateForm({
                ...form,
                sections: newSections,
                autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
            });
        },
    onChangeYesNoValue:
        ({ form, updateForm }) =>
        async (yesNoValue, section) => {
            const newSections = form.sections.map((newSection) => newSection);
            const index = form.sections.indexOf(section);

            newSections.splice(index, 1, {
                ...section,
                yesNoValue,
            });
            await updateForm({
                ...form,
                sections: newSections,
                autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
            });
        },
    onChangeScale10Value:
        ({ form, updateForm }) =>
        async (numberScale10Value, section) => {
            const newSections = form.sections.map((newSection) => newSection);
            const index = form.sections.indexOf(section);

            newSections.splice(index, 1, {
                ...section,
                numberScale10Value,
            });
            await updateForm({
                ...form,
                sections: newSections,
                autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
            });
        },
    onChangeScale100Value:
        ({ form, updateForm }) =>
        async (numberScale100Value, section) => {
            const newSections = form.sections.map((newSection) => newSection);
            const index = form.sections.indexOf(section);

            newSections.splice(index, 1, {
                ...section,
                numberScale100Value,
            });
            await updateForm({
                ...form,
                sections: newSections,
                autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
            });
        },
    onChangeSpreadsheetData:
        ({ form, updateForm }) =>
        async (spreadsheetData, section) => {
            const newSections = form.sections.map((newSection) => newSection);
            const index = form.sections.indexOf(section);

            newSections.splice(index, 1, {
                ...section,
                spreadsheetData,
            });
            await updateForm({
                ...form,
                sections: newSections,
                autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
            });
        },
    onChangeCheckbox:
        ({ form, updateForm }) =>
        async (checkboxValue, checkboxIndex, section) => {
            const newSections = form.sections.map((newSection) => newSection);
            const index = form.sections.indexOf(section);
            newSections[index].checkboxesData[checkboxIndex].value = checkboxValue;
            await updateForm({
                ...form,
                sections: newSections,
                autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
            });
        },
    onChangeDrawingSection:
        ({ form, updateForm }) =>
        async (updatedSection, isGroup, section) => {
            if (!isGroup) {
                const newSections = form.sections.map((newSection) => newSection);
                const index = form.sections.indexOf(section);
                newSections.splice(index, 1, {
                    ...updatedSection,
                });
                await updateForm({
                    ...form,
                    sections: newSections,
                    autoFocus: section.rowIndexes.concat(section.columnIndexes).join(),
                });
            }
        },
};

const FormNoteContainer = compose(
    withRouter,
    connect(({ user, formTemplate }) => ({
        isClinician: user.roles.includes('CLINICIAN'),
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        formTemplateId: formTemplate.id,
        formTemplateName: formTemplate.name,
        formTemplateSections: formTemplate.sections,
        formTemplateRows: formTemplate.rows,
        userId: user.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withState('form', 'updateForm', initialForm),
    withState('cursors', 'updateCursors', {}),
    withProps(({ form, match, location, isClinician, isAdmin, noteIdForce, canEditForce, isConsentFormSubscription, hideActionForce }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
        noteId: noteIdForce || match.params.noteId,
        initialSave: form.loading && !form.id,
        isHideAction: isConsentFormSubscription || hideActionForce,
        canEdit:
            canEditForce !== undefined
                ? canEditForce
                : !form.isSignedOff && (isClinician || isAdmin) && form.isAuthor && Boolean(form.id) && !isConsentFormSubscription,
    })),
    branch(
        ({ noteId, form }) => noteId && !form.preventQuery,
        compose(
            graphql(FORM_NOTE_QUERY, {
                name: 'query',
                options: ({ noteId }) => ({
                    variables: { noteId },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                (props) => props.status.success,
                withProps(({ query }) => ({
                    id: query.node.id,
                    episodeIdsArray: query.node.episodes && query.node.episodes.length ? query.node.episodes.map((episode) => episode.id) : [],
                    categoryIdsArray:
                        query.node.categories && query.node.categories.length ? query.node.categories.map((category) => category.id) : [],
                    title: query.node.title,
                    createdAt: query.node.createdAt,
                    sections: query.node.sections && JSON.parse(JSON.stringify(query.node.sections)),
                    isSignedOff: query.node.isSignedOff,
                    signedOffTime: query.node.signedOffTime,
                    rows: query.node.rows,
                    patientName: query.node.patient.name,
                    patientDob: query.node.patient.dob,
                    episodes: query.node.episodes,
                    author: query.node.author,
                }))
            )
        )
    ),
    graphql(UPSERT_FORM_NOTE, { name: 'noteMutation' }),
    graphql(SIGN_OFF_NOTES, { name: 'signOffMutation' }),
    graphql(SIGN_ON_NOTE, { name: 'signOnNote' }),
    graphql(TAG_NOTE, { name: 'tagNote' }),
    graphql(DUPLICATE_FORM_NOTE, { name: 'duplicateFormNoteMutation' }),
    withHandlers(apiHandlers),
    withHandlers(localHandlers),
    withPropsOnChange(['onSave'], (props) => ({
        onSave: debounce(props.onSave, 1000),
    })),
    lifecycle({
        componentDidMount() {
            const {
                categoryIdsArray,
                episodeId,
                episodeIdsArray,
                form,
                formTemplateName,
                formTemplateSections,
                formTemplateRows,
                id,
                isSignedOff,
                noteId,
                sections,
                signedOffTime,
                rows,
                status,
                title,
                createdAt,
                updateForm,
                author,
                userId,
                patientName,
                patientDob,
                episodes,
            } = this.props;
            if (!noteId) {
                const textSections = formTemplateSections && formTemplateSections.find((section) => section.type === 'TEXT');

                updateForm({
                    ...form,
                    episodeIdsArray: episodeId ? [episodeId] : [],
                    title: formTemplateName,
                    // Not a deep clone but not necessary as template
                    // field gets removed during mutation
                    sections: formTemplateSections.map((section) => {
                        if (section.type === 'CHECKBOXES' && !Array.isArray(section.checkboxesData)) {
                            section.checkboxesData = JSON.parse(section.checkboxesData);
                        }
                        if (
                            section.type === 'SPREADSHEET' &&
                            !(
                                typeof section.spreadsheetData === 'object' &&
                                section.spreadsheetData !== null &&
                                !Array.isArray(section.spreadsheetData)
                            )
                        ) {
                            section.spreadsheetData = JSON.parse(section.spreadsheetData);
                        }

                        return {
                            ...section,
                            templateId: get(section, 'template.id'),
                        };
                    }),
                    rows: formTemplateRows,
                    autoFocus: textSections ? textSections.rowIndexes.concat(textSections.columnIndexes).join() : 'title',
                });
            } else if (status && status.success) {
                const textSections = sections && sections.find((section) => section.type === 'TEXT');

                updateForm({
                    ...form,
                    id,
                    categoryIdsArray,
                    episodeIdsArray,
                    title,
                    sections: sections.map((section) => {
                        if (section.type === 'CHECKBOXES' && !Array.isArray(section.checkboxesData)) {
                            section.checkboxesData = JSON.parse(section.checkboxesData);
                        }
                        if (
                            section.type === 'SPREADSHEET' &&
                            !(
                                typeof section.spreadsheetData === 'object' &&
                                section.spreadsheetData !== null &&
                                !Array.isArray(section.spreadsheetData)
                            )
                        ) {
                            section.spreadsheetData = JSON.parse(section.spreadsheetData);
                        }
                        return {
                            ...section,
                        };
                    }),
                    isSignedOff,
                    signedOffTime,
                    rows,
                    createdAt,
                    author,
                    isAuthor: userId === get(author, 'id'),
                    patientName,
                    patientDob,
                    episodes,
                    autoFocus: textSections ? textSections.rowIndexes.concat(textSections.columnIndexes).join() : 'title',
                });
            }
        },
        async componentDidUpdate() {
            const { episodeId, form, formTemplateName, formTemplateSections, formTemplateRows, noteId, updateForm, formTemplateId, onSave } =
                this.props;

            const textSections = formTemplateSections && formTemplateSections.find((section) => section.type === 'TEXT');

            if (formTemplateId && !noteId && form.templateId !== formTemplateId) {
                await updateForm({
                    ...form,
                    templateId: formTemplateId,
                    autoFocus: textSections ? textSections.rowIndexes.concat(textSections.columnIndexes).join() : 'title',
                    episodeIdsArray: episodeId ? [episodeId] : [],
                    title: formTemplateName,
                    // Not a deep clone but not necessary as template
                    // field gets removed during mutation
                    sections: formTemplateSections.map((section) => ({
                        ...section,
                        templateId: get(section, 'template.id'),
                    })),
                    rows: formTemplateRows,
                });

                onSave();
            }
        },
    })
);

export default FormNoteContainer;

import React, { useEffect } from 'react';
import { compose, lifecycle, withProps } from 'recompose';
import { Redirect, useLocation } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { notesCleared } from '../../data/redux/actions/notes';

import AppBarMenu from '../../components/AppBarMenu';
import DrawingNote from '../../components/DrawingNote';
import FormNote from '../../components/FormNote';
import GroupDrawingNote from '../../components/GroupDrawingNote';
import MediaNote from '../../components/MediaNote';
import TextNote from '../../components/TextNote';
import NotesSidebar from '../../components/NotesSidebar';
import ReactGA from 'react-ga4';

const NotePage = ({ atAttachments, atDrawing, atForm, atGroupDrawing, atText, classes, patientId, ...props }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        });
    }, [location]);

    if (patientId === 'undefined' && atDrawing) return <Redirect to='/patient/select/note/drawing' />;
    if (patientId === 'undefined' && atAttachments) return <Redirect to='/patient/select/attachments' />;
    if (patientId === 'undefined' && atText) return <Redirect to='/patient/select/note/text' />;
    if (patientId === 'undefined' && atForm) return <Redirect to='/patient/select/note/form' />;

    return (
        <AppBarMenu>
            <Grid container wrap='nowrap'>
                <Grid
                    component='main'
                    className={classes.main}
                    container
                    direction='column'
                    wrap='nowrap'
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={10}
                    xl={9}
                    flex={1}>
                    {(() => {
                        if (atAttachments) return <MediaNote />;
                        if (atGroupDrawing) return <GroupDrawingNote />;
                        if (atDrawing) return <DrawingNote />;
                        if (atText) return <TextNote {...props} />;
                        if (atForm) return <FormNote />;

                        return null;
                    })()}
                </Grid>
                {(atText || atForm || atDrawing) && <NotesSidebar />}
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: 'width 0.5s',
        transitionTimingFunction: 'ease',
    },
    leftButton: {
        position: 'absolute',
        left: 0,
        marginLeft: 12,
    },
};

export default compose(
    withStyles(styles),
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        atAttachments: location.pathname.includes('attachments'),
        atDrawing: location.pathname.includes('drawing'),
        atForm: location.pathname.includes('form'),
        atGroupDrawing: location.pathname.includes('drawings'),
        atText: location.pathname.includes('text'),
    })),
    lifecycle({
        componentDidMount() {
            this.props.dispatch(notesCleared());
        },
    })
)(NotePage);

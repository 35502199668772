/* eslint-disable no-console */
import { featureFlags } from '../utility';

export class Logger {
    static LogError(message: string): void {
        const logMessage = `%c[ERROR] ${message}`;
        if (!featureFlags('PRODUCTION')) {
            console.log(logMessage, 'background: red; color: white');
        }
    }

    static LogException(message: string, error: any): void {
        const logMessage = `${message} - ${error}`;
        if (!featureFlags('PRODUCTION')) {
            console.log(logMessage);
        }
    }

    static LogMessage(message: string): void {
        const logMessage = `${message}`;
        console.log(logMessage);
    }
}

export default Logger;

import {
  SECTION_NEW_TYPE,
  SECTION_CLEARED,
  SECTION_SELECT,
} from '../actions/section';

const sectionDefaultState = {
  newType: undefined,
  rowNumber: undefined,
  selectedSection: undefined,
};

const sectionReducer = (state = sectionDefaultState, action) => {
  switch (action.type) {
    case SECTION_NEW_TYPE:
      return {
        ...state,
        newType: action.newType,
        rowNumber: action.rowNumber,
      };
    case SECTION_SELECT:
      return {
        ...state,
        selectedSection: action.selectedSection,
      };
    case SECTION_CLEARED:
      return {
        newType: undefined,
        rowNumber: undefined,
      };
    default:
      return state;
  }
};

export default sectionReducer;

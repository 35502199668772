import React from 'react';

import { compose, withState } from 'recompose';

import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';

import { WHITE } from '../../style/constants';
import TemplateImage from '../TemplateImage/templateImage';

const GroupTemplates = ({
    classes,
    template,
    onSelectTemplate,
    onClose,
    updateAlertDialog,
    style,
    groupedTemplates,
    pageNumber,
    updatePageNumber,
    hideDeleteIcon,
    onImageLoaded,
    getFileEncodedFromFileId,
}) => (
    <GridListTile
        style={style}
        className={classes.listItem}
        onClick={() => {
            groupedTemplates.forEach((temp) => {
                temp.file.encodedFile = getFileEncodedFromFileId(template.file.id);
            });

            if (onSelectTemplate) {
                onSelectTemplate(template.id, template.file.encodedFile, groupedTemplates);
                onClose();
            }
        }}>
        <TemplateImage fileId={groupedTemplates[pageNumber].file.id} onImageLoaded={onImageLoaded} />
        <GridListTileBar
            title={template.name}
            actionIcon={
                <div className={classes.allIcons}>
                    <IconButton
                        className={classes.icon}
                        disabled={pageNumber === 0}
                        onClick={(e) => {
                            e.stopPropagation();
                            updatePageNumber(pageNumber - 1);
                        }}>
                        <ChevronLeft />
                    </IconButton>
                    <IconButton
                        className={classes.icon}
                        disabled={pageNumber === groupedTemplates.length - 1}
                        onClick={(e) => {
                            e.stopPropagation();
                            updatePageNumber(pageNumber + 1);
                        }}>
                        <ChevronRight />
                    </IconButton>
                    <IconButton
                        className={classes.icon}
                        onClick={(e) => {
                            e.stopPropagation();
                            updateAlertDialog({
                                show: true,
                                selectedTemplate: groupedTemplates,
                            });
                        }}>
                        <Delete />
                    </IconButton>
                </div>
            }
        />
    </GridListTile>
);

const styles = {
    listItem: {
        cursor: 'pointer',
        transitionDuration: '0.3s',
        '&:hover': {
            transform: 'translateY(-4px)',
        },
        '&:hover $icon': {
            opacity: 1,
        },
    },
    allIcons: {
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        color: WHITE,
        opacity: 0,
        transition: '0.3s',
    },
};

export default compose(withStyles(styles), withState('pageNumber', 'updatePageNumber', 0))(GroupTemplates);

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { BOULDER, WARM_PINK, WILD_SAND } from '../../../style/constants';
import BaseSettingsPage from '../Base';
import TaxRateDialog from './TaxRateDialog';
import { FETCH_TAX_RATES } from './gql';
import Loading from '../../../components/Loading';

const columnData = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'rate',
    label: 'Rate',
  },
  {
    id: 'desc',
    label: 'Description',
  },
];

const RegionSettings = ({
  classes,
  isAdmin,
  locationId,
  practiceEncodedId,
}) => {
  if (!isAdmin) {
    return <div>Not Authorized</div>;
  }

  return <BaseSettingsPage></BaseSettingsPage>;
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  subheading: {
    fontWeight: 'bold',
  },
  subSection: {},
  greyButton: {
    marginRight: 8,
    height: 36,
    color: BOULDER,
    backgroundColor: WILD_SAND,
  },
  redButton: {
    height: 36,
    color: WARM_PINK,
    backgroundColor: WILD_SAND,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    locationId: user.currentLocationId,
    currentLocationName: user.currentLocationName,
    currentLocationId: user.currentLocationId,
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
    isConsentFormSubscription: user.isConsentFormSubscription,
    practiceEncodedId: practice.id,
    user,
  }))
)(RegionSettings);

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormTemplate from '../../../components/FormTemplate';
import FormNote from '../../../components/FormNote';

const FormNoteTemplateDialog = ({
  formNoteTemplateDialogOpen,
  updateFormNoteTemplateDialogOpen,
  subcategoryIdToCreate,
  onCloseFormTemplate,
  formTemplate,
}) => {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={formNoteTemplateDialogOpen}
      onClose={async () => {
        updateFormNoteTemplateDialogOpen(false);
        if (formTemplate && formTemplate.id) {
          return;
        }
        await onCloseFormTemplate();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {formTemplate && formTemplate.id
          ? 'Preview Template'
          : 'New Form Note Template'}
      </DialogTitle>
      <DialogContent>
        {formTemplate && formTemplate.id ? (
          <FormNote
            key={formTemplate.id}
            formTemplateSections={formTemplate.sections}
            formTemplateId={formTemplate.id}
            canEditForce={false}
            hideActionForce
            notAllowSave
          />
        ) : (
          <FormTemplate
            sharableTemplate
            templateCategory={
              subcategoryIdToCreate ? [subcategoryIdToCreate] : []
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            updateFormNoteTemplateDialogOpen(false);
            if (formTemplate && formTemplate.id) {
              return;
            }
            await onCloseFormTemplate();
          }}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
  }))
)(FormNoteTemplateDialog);

import React from 'react';
import { compose } from 'recompose';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { humanizeString } from '../../../utility/string';
import { WARM_PINK } from '../../../style/constants';

const INITIAL_HEADERS = {
  flexibleFieldHeader1: 'Medicare Number',
  flexibleFieldHeader2: 'Health Fund Company',
  flexibleFieldHeader3: 'Health Fund Number',
  flexibleFieldHeader4: 'Pension Number',
  flexibleFieldHeader5: "Veteran's Number",
};

const ReferralHeadersDialog = ({
  classes,
  isDialogOpen,
  onClose,
  referralHeaders,
  updateReferralHeaders,
  updatePractice,
  setMessage,
}) => {
  const handleReferralHeadersUpdate = (key, event) => {
    updateReferralHeaders({ ...referralHeaders, [key]: event.target.value });
  };

  const handleSave = async () => {
    await updatePractice({ ...referralHeaders });
    setMessage('Patient Data Fields updated successfully');
    onClose();
  };

  const handleReset = () => {
    updateReferralHeaders({ ...INITIAL_HEADERS });
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      onClose={onClose}
      open={isDialogOpen}
    >
      <DialogTitle>Edit Patient Data Fields</DialogTitle>

      <DialogContent className={classes.modalContent}>
        {Object.entries(referralHeaders).map(([key, value]) => (
          <Box sx={{ mb: 5 }} key={key}>
            <FormControl key={key}>
              <FormLabel>{humanizeString(key)}</FormLabel>
              <TextField
                className={classes.textField}
                autoComplete="off"
                value={value}
                onChange={e => handleReferralHeadersUpdate(key, e)}
              />
            </FormControl>
          </Box>
        ))}
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleReset}>Reset to Default</Button>
        <span>
          <Button onClick={onClose}>Cancel</Button>
          <Button className={classes.redButton} onClick={handleSave}>
            Save
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContainer: {
    width: '100%',
  },
  modalContent: {
    padding: 32,
    margin: '0px !important',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  redButton: {
    color: WARM_PINK,
  },
  textField: {
    width: 540,
  },
};

export default compose(withStyles(styles))(ReferralHeadersDialog);

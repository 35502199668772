import React, { useEffect, useState } from 'react';
import { compose, lifecycle } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { notesCleared } from '../../data/redux/actions/notes';
import SubscriptionPageContainer from './subscriptionPageContainer';
import AppBarMenu from '../../components/AppBarMenu';
import SubscriptionPlans from '../../components/SubscriptionPlans';
import SubscriptionCreditCard from '../../components/SubscriptionCreditCard';
import { GET_PRACTICE_SUBSCRIPTION, UPSERT_SUBSCRIPTION_FROM_CHECKOUT } from './gql';

const SubscriptionPage = (props) => {
    const { classes, practiceEncodedId } = props;

    const urlLocation = useLocation();
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState({});

    const [getPracticeSubscription] = useLazyQuery(GET_PRACTICE_SUBSCRIPTION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.getPracticeSubscription) {
                if (res.getPracticeSubscription.stripeSubscription) {
                    setSubscription(res.getPracticeSubscription.stripeSubscription);
                } else {
                    setSubscription({});
                }
                setLoading(false);
            }
        },
        variables: {
            practiceId: practiceEncodedId,
        },
    });

    const [upsertSubscriptionFromCheckout] = useMutation(UPSERT_SUBSCRIPTION_FROM_CHECKOUT, {
        onCompleted: (res) => {
            if (res.upsertSubscriptionFromCheckout) {
                setLoading(true);
                getPracticeSubscription();
            }
        },
    });

    useEffect(() => {
        const queryString = urlLocation.search;
        if (!queryString.includes('stripe')) {
            getPracticeSubscription().then(() => {});
        }
    }, []);

    useEffect(() => {
        const queryString = urlLocation.search;
        if (queryString.includes('stripe')) {
            setLoading(true);
            const checkoutSession = localStorage.getItem('checkout_session');
            upsertSubscriptionFromCheckout({
                variables: {
                    practiceId: practiceEncodedId,
                    checkoutSession,
                },
            });
        }
    }, []);

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container>
                <Grid className={classes.content} container direction='column' item xs={12} sm={11} md={12} lg={10} xl={10}>
                    <Card>
                        <CardContent>
                            <div className={classes.headerWrapper}>
                                <div className={classes.headerLeft}>
                                    <Typography variant='h6' className={classes.pageTitle}>
                                        Manage Plan
                                    </Typography>
                                </div>
                            </div>
                            <SubscriptionPlans
                                isLoading={loading}
                                currentPlan={subscription.planToken}
                                customerToken={subscription.customerToken}
                                numberOfUsers={subscription.numberOfUsers}
                                expiry={subscription.expiry}
                                renewDate={subscription.renewDate}
                            />
                        </CardContent>
                    </Card>
                    <Card className={classes.ccContainer}>
                        <CardContent>
                            <SubscriptionCreditCard isLoading={loading} customerToken={subscription.customerToken} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 64,
    },
    content: {
        paddingTop: 28,
        paddingBottom: 64,
        paddingLeft: 44,
        paddingRight: 44,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    pageTitle: {
        marginBottom: 20,
    },
    actionBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 25,
    },
    subscribeButton: {
        marginLeft: 8,
        marginRight: 8,
    },
    cancelShow: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 10,
        textDecoration: 'underline',
    },
    secondTitle: {
        marginTop: 40,
    },
    ccContainer: {
        marginTop: 40,
    },
    dialogTextStyle: {
        fontSize: 14,
        textAlign: 'center',
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerLeft: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
};

export default compose(
    SubscriptionPageContainer,
    withStyles(styles),
    connect(({ practice, user }) => ({
        practiceEncodedId: practice.id,
    })),
    lifecycle({
        componentDidMount() {
            this.props.dispatch(notesCleared());
        },
    })
)(SubscriptionPage);

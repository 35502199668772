import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { BOULDER, WARM_PINK, WILD_SAND } from '../../../style/constants';
import TaxRateDialog from './TaxRateDialog';
import { FETCH_TAX_RATES } from './gql';
import Loading from '../../../components/Loading';
import CustomTooltip from '../../../components/CustomTooltip';

const TITLE = 'Add a tax rate applicable to your country or state.';

const columnData = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'rate',
    label: 'Rate',
  },
  {
    id: 'desc',
    label: 'Description',
  },
];

const TaxRateCard = ({ classes, isAdmin, locationId, practiceEncodedId }) => {
  const [currentTaxRate, setCurrentTaxRate] = useState({});
  const [taxRates, setTaxRates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loading } = useQuery(FETCH_TAX_RATES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.listTaxRates) {
        setTaxRates(res.listTaxRates);
      }
    },
    variables: {
      locationId,
      practiceEncodedId,
    },
  });

  if (!isAdmin) {
    return <div>Not Authorized</div>;
  }

  const handleEditToggle = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      setCurrentTaxRate({});
    }
  };

  const handleSelectRow = row => {
    setCurrentTaxRate(row);
    handleEditToggle();
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headingContainer}>
          <Typography variant="subtitle1" className={classes.subheading}>
            Tax Rates
          </Typography>
          <CustomTooltip hidden={!isAdmin} title={TITLE} />
        </div>

        <span>
          {/* <Button
            className={classes.greyButton}
            variant="contained"
            onClick={handleTemplateLibraryClick}
          >
            Template Library
          </Button> */}
          <Button
            className={classes.redButton}
            variant="contained"
            onClick={handleEditToggle}
          >
            Add
          </Button>
        </span>
      </div>
      <Paper>
        <Grid className={classes.subSection}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columnData.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow className={classes.clickableRow}>
                  <TableCell colSpan={columnData.length}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}
              {taxRates.map(row => (
                <TableRow
                  className={classes.clickableRow}
                  hover
                  key={row.id}
                  onClick={() => handleSelectRow(row)}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.ratePercent}%</TableCell>
                  <TableCell>{row.desc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Paper>
      {isModalOpen && (
        <TaxRateDialog
          form={currentTaxRate}
          handleClose={handleEditToggle}
          setForm={setCurrentTaxRate}
          showDialog={isModalOpen}
        />
      )}
    </>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  headingContainer: {
    display: 'flex',
    paddingBottom: 8,
  },
  subheading: {
    fontWeight: 'bold',
  },
  subSection: {},
  greyButton: {
    marginRight: 8,
    height: 36,
    color: BOULDER,
    backgroundColor: WILD_SAND,
  },
  redButton: {
    height: 36,
    color: WARM_PINK,
    backgroundColor: WILD_SAND,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    locationId: user.currentLocationId,
    currentLocationName: user.currentLocationName,
    currentLocationId: user.currentLocationId,
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
    isConsentFormSubscription: user.isConsentFormSubscription,
    practiceEncodedId: practice.id,
    user,
  }))
)(TaxRateCard);

import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import clsx from 'clsx';
import lodash from 'lodash';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { createTheme, lighten, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Collapse from '@material-ui/core/Collapse';

import Loading from '../../components/Loading';
import DrawingSVG from '../../components/DrawingSVG';
import TemplateImage from '../../components/TemplateImage/templateImage';
import PdfContainer from './PdfContainer';
import Doc from './DocService';

const createPdf = (html, subId) => Doc.createPdf(html, subId);

// const tableData = [];
const headCells = [
    // { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        label: 'Created At',
        type: 'createdAt',
    },
    {
        id: 'patientName',
        numeric: true,
        disablePadding: false,
        label: 'Patient Name',
        type: 'patient_name',
    },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        // setTableData,
        // submissionsData,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.length > 0 &&
                    headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                <Tooltip title={headCell.label}>
                                    <div>{lodash.truncate(headCell.label)}</div>
                                </Tooltip>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}

                <TableCell align='right'>
                    <Button>Actions</Button>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const filterCell = lodash(headCells)
        .filter((o) => {
            return o.id !== 'createdAt' && o.id !== 'actions' && o.id !== 'patientName';
        })
        .value();

    const exportCSVData = [lodash.map(filterCell, 'label')];

    lodash.forEach(props.tableData, (o) => {
        if (props.selected.includes(o.id)) {
            const r = [];
            for (const filterCellElement of filterCell) {
                r.push(o[filterCellElement.id]);
            }
            exportCSVData.push(r);
        }
    });

    const csvFilename = `generated-csv-${moment().utc().unix()}.csv`;

    const [checked, setChecked] = React.useState(false);
    const [selectedQuestion, setSelectedQuestion] = React.useState('');
    const [keyword, setKeyword] = React.useState('');

    const showAdvanceFilter = () => {
        setChecked((prev) => !prev);
    };

    const handleSelectQuestion = (e) => {
        setSelectedQuestion(e.target.value);
    };

    const submitFilter = (e) => {
        // find data
        const results = lodash.filter(submissionsData, (o) => {
            const { details } = o;
            // filter details
            const hasDetails = lodash.some(details, (i) => {
                if (i.name === selectedQuestion) {
                    let v = i.value;
                    if (i.type === 'date') {
                        v = moment(v).format('YYYY-MM-DD');
                    }
                    return String(v).toLowerCase().includes(String(keyword).toLowerCase());
                }
                return false;
            });
            return hasDetails;
        });
        //
        props.setTableData(results);
    };
    return (
        <div>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}>
                {numSelected > 0 ? (
                    <Typography className={classes.title} color='inherit' variant='subtitle1'>
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant='h6' id='tableTitle'>
                        Submissions of {props.consentForm.title}
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title='Download CSV'>
                        <IconButton aria-label='download'>
                            <CSVLink data={exportCSVData} filename={csvFilename} style={{ color: '#ff0000' }}>
                                <GetAppIcon />
                            </CSVLink>
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title='Filter list'>
                        <IconButton aria-label='filter list' onClick={showAdvanceFilter}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
            <Toolbar>
                <div>
                    <Collapse in={checked}>
                        <TextField
                            id='question-select-native'
                            select
                            autoComplete='off'
                            label='Please select question'
                            value={selectedQuestion}
                            onChange={handleSelectQuestion}
                            SelectProps={{
                                native: true,
                            }}
                            variant='outlined'
                            style={{ width: '250px' }}>
                            <option value='' />
                            {headCells.map((headCell, i) => {
                                if (headCell.id !== 'createdAt' && headCell.id !== 'actions' && headCell.id !== 'patientName') {
                                    if (headCell.type !== 'signature') {
                                        return (
                                            <option key={`${headCell.id}-${i}`} value={headCell.id}>
                                                {headCell.label}
                                            </option>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </TextField>

                        <TextField
                            value={keyword}
                            label='Answer contains'
                            variant='outlined'
                            autoComplete='off'
                            style={{ marginLeft: '20px', width: '250px' }}
                            onChange={(e) => setKeyword(e.target.value)}
                        />

                        <Button variant='outlined' size='large' onClick={submitFilter} style={{ marginLeft: '20px', height: '100%' }}>
                            Submit
                        </Button>
                    </Collapse>
                </div>
            </Toolbar>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: '20px 40px',
        outline: 'none',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    downloadCsvBtn: {
        color: theme.palette.primary,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperWrap: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        width: '100%',
        padding: '20px 40px',
    },
    pdfWrap: {
        width: 2480,
        height: 3508,
    },
    drawing: {
        height: 400,
        width: 400,
    },
}));

const imgTableCell = (row, headCell) => {
    return (
        <TableCell align='right' key={`${row.id}-${headCell.id}`}>
            <img alt='' src={row[headCell.id]} width={100} height={75} />
        </TableCell>
    );
};

const svgCell = (data, key) => {
    const classes = useStyles();
    try {
        const drawingData = JSON.parse(data);
        if (typeof drawingData === 'object' && drawingData !== null && drawingData.fileId) {
            const [templateDataTmp, updateTemplateData] = useState(null);
            return (
                <div>
                    <div style={{ display: 'none' }}>
                        <TemplateImage
                            fileId={drawingData.fileId}
                            onImageLoaded={(imageData) => {
                                updateTemplateData(imageData.encodedFile);
                            }}
                        />
                    </div>
                    {templateDataTmp && (
                        <DrawingSVG
                            sectionId={`${new Date().getTime().toString()}-${key}`}
                            className={classes.drawing}
                            templateData={templateDataTmp}
                            drawingData={drawingData.value}
                        />
                    )}
                </div>
            );
        }
    } catch (e) {}

    return <DrawingSVG sectionId={`${new Date().getTime().toString()}-${key}`} className={classes.drawing} drawingData={data} />;
};

const themeAddFields = createTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
        },
    },
});

const submissionsData = [];

export default function EnhancedTableInfinitive({ consentForm, anchorEl, updateAnchorEl, hasNextPage, onListEnd }) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [populated, setPopulated] = React.useState(false);
    const [openDialog, updateOpenDialog] = React.useState(false);
    const [dialogValue, updateDialogValue] = React.useState('');
    if (!populated) {
        consentForm.submissions &&
            consentForm.submissions.forEach((o, i) => {
                if ('details' in o && o.details && o.details.length > 0) {
                    const rowData = {
                        id: o.id,
                        createdAt: moment(o.createdAt).format('YYYY-MM-DD'),
                        patientName: o.patient_name,
                        details: o.details,
                    };

                    o.details.forEach((d) => {
                        if (d.type !== 'label') {
                            rowData[d.name] = d.value;
                            const checkexist = lodash.some(headCells, { id: d.name });
                            if (checkexist === false) {
                                headCells.push({
                                    id: d.name,
                                    numeric: true,
                                    disablePadding: false,
                                    type: d.type,
                                    label: lodash.upperFirst(d.title),
                                });
                            }
                        }
                    });

                    if (!submissionsData || !submissionsData.length || !submissionsData.find((item) => item.id === rowData.id)) {
                        submissionsData.push(rowData);
                    }

                    if (!tableData || !tableData.length || !tableData.find((item) => item.id === rowData.id)) {
                        tableData.push(rowData);
                    }
                }
            });
        if (!hasNextPage) {
            setPopulated(true);
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = tableData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <div id='scrollableDiv' className={classes.root}>
            <InfiniteScroll pageStart={0} hasMore={hasNextPage} useWindow={false} loader={<Loading key={1} height={100} />} loadMore={onListEnd}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        selected={selected}
                        numSelected={selected.length}
                        consentForm={consentForm}
                        tableData={tableData}
                        setTableData={setTableData}
                        submissionsData={submissionsData}
                    />

                    <TableContainer>
                        <Table className={classes.table} aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'} aria-label='enhanced table'>
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={tableData.length}
                                consentForm={consentForm}
                                tableData={tableData}
                                setTableData={setTableData}
                                submissionsData={submissionsData}
                            />

                            <TableBody>
                                {stableSort(tableData, getComparator(order, orderBy)).map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}>
                                            <TableCell padding='checkbox'>
                                                <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>
                                            <TableCell align='right' component='th' id={labelId} scope='row' padding='none'>
                                                {row.createdAt}
                                            </TableCell>
                                            <TableCell align='right' component='th' id={labelId} scope='row' padding='none'>
                                                {row.patientName}
                                            </TableCell>

                                            {headCells.map((headCell, i) => {
                                                if (headCell.id !== 'createdAt' && headCell.id !== 'actions' && headCell.id !== 'patientName') {
                                                    if (headCell.type === 'signature') {
                                                        return imgTableCell(row, headCell);
                                                    }
                                                    if (headCell.type === 'date') {
                                                        return (
                                                            <TableCell align='right' key={`${row.id}-${headCell.id}`}>
                                                                {moment(row[headCell.id]).format('YYYY-MM-DD')}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (headCell.type === 'image') {
                                                        return (
                                                            <TableCell align='right' key={`${row.id}-${headCell.id}`}>
                                                                {svgCell(row[headCell.id], `${row.id}-${headCell.id}`)}
                                                            </TableCell>
                                                        );
                                                    }
                                                    return (
                                                        <TableCell align='right' key={`${row.id}-${headCell.id}`}>
                                                            {row[headCell.id]}
                                                        </TableCell>
                                                    );
                                                }
                                                return null;
                                            })}

                                            <TableCell align='right'>
                                                <Button
                                                    onClick={(event) => {
                                                        anchorEl[row.id] = event.currentTarget;
                                                        updateAnchorEl(anchorEl);
                                                    }}>
                                                    View
                                                </Button>

                                                <Grid container direction='row' wrap='nowrap' item xs={12} sm={12} md={1} lg={1} xl={1}>
                                                    <ThemeProvider theme={themeAddFields}>
                                                        <Modal
                                                            aria-labelledby='more-info'
                                                            aria-describedby='more-info'
                                                            className={classes.modal}
                                                            open={Boolean(anchorEl[row.id])}
                                                            onClose={() => {
                                                                anchorEl[row.id] = null;
                                                                updateAnchorEl(anchorEl);
                                                            }}
                                                            BackdropComponent={Backdrop}
                                                            BackdropProps={{
                                                                timeout: 500,
                                                            }}>
                                                            <Paper>
                                                                <PdfContainer
                                                                    createPdf={createPdf}
                                                                    subId={row.id}
                                                                    anchorEl={anchorEl}
                                                                    updateAnchorEl={updateAnchorEl}>
                                                                    <React.Fragment>
                                                                        <Paper id={row.id} style={{ boxShadow: 'none' }}>
                                                                            <div className={classes.paper}>
                                                                                <TableContainer>
                                                                                    <Table aria-label='caption table'>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell align='left'>Question</TableCell>
                                                                                                <TableCell align='left'>Answer</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {row.details.map((item, key) => {
                                                                                                if (item.type !== 'label') {
                                                                                                    return (
                                                                                                        <TableRow key={item.name}>
                                                                                                            <TableCell align='left'>
                                                                                                                {item.title}
                                                                                                            </TableCell>
                                                                                                            <TableCell align='left'>
                                                                                                                {(() => {
                                                                                                                    if (item.type === 'signature') {
                                                                                                                        return (
                                                                                                                            <img
                                                                                                                                alt=''
                                                                                                                                src={item.value}
                                                                                                                                width={100}
                                                                                                                                height={75}
                                                                                                                            />
                                                                                                                        );
                                                                                                                    }
                                                                                                                    if (item.type === 'date') {
                                                                                                                        return moment(
                                                                                                                            item.value
                                                                                                                        ).format('YYYY-MM-DD');
                                                                                                                    }
                                                                                                                    if (item.type === 'yesNo') {
                                                                                                                        return lodash.upperFirst(
                                                                                                                            item.value
                                                                                                                        );
                                                                                                                    }
                                                                                                                    if (item.type === 'image') {
                                                                                                                        return (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    updateOpenDialog(
                                                                                                                                        true
                                                                                                                                    );
                                                                                                                                    updateDialogValue(
                                                                                                                                        item.value
                                                                                                                                    );
                                                                                                                                }}>
                                                                                                                                {svgCell(
                                                                                                                                    item.value,
                                                                                                                                    key
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    }
                                                                                                                    return item.value;
                                                                                                                })()}
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    );
                                                                                                }
                                                                                                return null;
                                                                                            })}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                            </div>
                                                                        </Paper>
                                                                    </React.Fragment>
                                                                </PdfContainer>
                                                            </Paper>
                                                        </Modal>
                                                    </ThemeProvider>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </InfiniteScroll>
            <Dialog open={openDialog} onClose={() => updateOpenDialog(false)} maxWidth='md' fullWidth scroll='paper'>
                <DialogContent dividers>
                    {(() => {
                        if (dialogValue) {
                            // DBG: added bgcolor [..., { backgroundColor: '#fcc' }]
                            return <DrawingSVG style={{ width: '100%', height: '100%' }} drawingData={dialogValue} />;
                        }
                        return <div>No drawing input</div>;
                    })()}
                </DialogContent>
                <DialogActions>
                    <Button
                        color='secondary'
                        onClick={() => {
                            updateOpenDialog(false);
                            updateDialogValue('');
                        }}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { Fragment } from 'react';

import Moment from 'moment';
import { View, Text, Image } from '@react-pdf/renderer';
import { styles } from './styles';

const TextNoteDetailView = ({ note }) => {
  return <Text style={styles.textNote}>{note.text}</Text>;
};

const MediaNoteDetailView = ({ note, files }) => {
  const { imageSrc } = files.get(note.file.id);
  return (
    <View style={styles.mediaNote}>
      <Image src={imageSrc} style={styles.mediaImage} />
    </View>
  );
};

const FormNoteDetailView = ({ note, files, canvasImages }) => {
  return (
    <View style={styles.formNote}>
      {note.sections.map(section => {
        // get backgroung image
        var imageSrc =
          section.template && files.get(section.template.file.id).imageSrc;
        if (section.drawingData) {
          const json = JSON.parse(section.drawingData);
          if (json.rawCanvasJson !== undefined) {
            imageSrc = false;
          }
        }
        let preparedCheckboxesData = null;
        if (section.checkboxesData) {
          preparedCheckboxesData = section.checkboxesData;
          if (!Array.isArray(section.checkboxesData)) {
            preparedCheckboxesData = JSON.parse(section.checkboxesData);
          }
        }
        let preparedSpreadsheetData = null;

        let rows = [];
        let sheets = [];
        if (section.type === 'SPREADSHEET') {
          preparedSpreadsheetData = section.spreadsheetData;
          if (
            !(
              typeof section.spreadsheetData === 'object' &&
              section.spreadsheetData !== null &&
              !Array.isArray(section.spreadsheetData)
            )
          ) {
            preparedSpreadsheetData = JSON.parse(section.spreadsheetData);
          }

          if (
            preparedSpreadsheetData &&
            preparedSpreadsheetData.jsonObject &&
            preparedSpreadsheetData.jsonObject.Workbook &&
            preparedSpreadsheetData.jsonObject.Workbook.sheets[0] &&
            preparedSpreadsheetData.jsonObject.Workbook.sheets[0].rows
          ) {
            preparedSpreadsheetData.jsonObject.Workbook.sheets.map(sheet => {
              if (sheet && sheet.rows) {
                let maxCol = 0;
                sheet.rows.map(row => {
                  if (row && row.cells && row.cells.length > maxCol) {
                    maxCol = row.cells.length;
                  }
                  return '';
                });
                rows = sheet.rows.map((row, key) => {
                  return (
                    <View style={styles.row} key={key}>
                      {Array.from(Array(maxCol).keys()).map(cellKey => {
                        return (
                          <Text style={styles.description} key={cellKey}>
                            {row &&
                            row.cells &&
                            row.cells[cellKey] &&
                            row.cells[cellKey].value
                              ? !isNaN(row.cells[cellKey].value)
                                ? row.cells[cellKey].value
                                    .toString()
                                    .match(/.{1,8}/g)
                                    .join('\n')
                                : row.cells[cellKey].value
                              : ' '}
                          </Text>
                        );
                      })}
                    </View>
                  );
                });
                sheets.push({
                  rows,
                  name: sheet.name,
                });
              }
              return '';
            });
          }
        }

        // generate canvas
        const canvasSrc = canvasImages[section.id];

        return (
          <View key={section.id} style={styles.formSection} wrap={false}>
            {section.title ? (
              <Text style={styles.formSectionTitle}>{section.title}</Text>
            ) : null}
            {section.type === 'DRAWING' && (
              <View style={styles.formSectionDrawing}>
                {imageSrc && (
                  <Image src={imageSrc} style={styles.formSectionImage} />
                )}
                {canvasSrc && (
                  <Image src={canvasSrc} style={styles.formSectionImage} />
                )}
              </View>
            )}
            {section.type === 'CHECKBOXES' && (
              <View style={styles.formSectionDrawing}>
                {preparedCheckboxesData &&
                  Array.isArray(preparedCheckboxesData) &&
                  preparedCheckboxesData.map((checkboxData, checkboxIndex) => {
                    return (
                      <Text>
                        {checkboxData.title}:{' '}
                        {checkboxData.value ? 'YES' : 'NO'}
                      </Text>
                    );
                  })}
              </View>
            )}
            {section.type === 'YESNO' && (
              <Text>{section.yesNoValue ? 'YES' : 'NO'}</Text>
            )}
            {section.type === 'NUMBERSCALE10' && (
              <Text>{section.numberScale10Value}</Text>
            )}
            {section.type === 'NUMBERSCALE100' && (
              <Text>{section.numberScale100Value}</Text>
            )}
            {section.type === 'SPREADSHEET' &&
              sheets.map(sheet => {
                return (
                  <View style={styles.sheetContainer}>
                    <Text>{sheet.name}</Text>
                    <View style={styles.tableContainer}>
                      <Fragment>{sheet.rows}</Fragment>
                      <View style={styles.line} />
                    </View>
                  </View>
                );
              })}
            {section.text !== undefined &&
              section.text !== null &&
              section.text.length > 0 && (
                <Text style={styles.formSectionText}>{section.text}</Text>
              )}
          </View>
        );
      })}
    </View>
  );
};

const DrawingNoteDetailView = ({
  note,
  files,
  canvasImages,
  isWebDrawingNote,
}) => {
  var imageSrc = note.template && files.get(note.template.file.id).imageSrc;
  const canvasSrc = canvasImages[note.id];
  if (isWebDrawingNote) {
    imageSrc = false;
  }
  return (
    <View style={styles.drawingNote}>
      {imageSrc && <Image src={imageSrc} style={styles.drawingImage} />}
      {canvasSrc && <Image src={canvasSrc} style={styles.drawingImage} />}
    </View>
  );
};

const noteDetailViewMap = {
  TextNote: TextNoteDetailView,
  MediaNote: MediaNoteDetailView,
  FormNote: FormNoteDetailView,
  DrawingNote: DrawingNoteDetailView,
};

export default function NoteView({
  note,
  files,
  canvasImages,
  index,
  compactMode,
}) {
  const NoteDetailView = noteDetailViewMap[note.__typename] || null;

  var isWebDrawingNote = false;
  if (note.drawingData) {
    const json = JSON.parse(note.drawingData);
    if (json.rawCanvasJson !== undefined) {
      isWebDrawingNote = true;
    }
  }
  return (
    <View style={styles.note}>
      {!compactMode && (
        <Text style={styles.noteTitle}>{`${index + 1}. ${note.title}`}</Text>
      )}

      <View style={styles.noteMeta}>
        <Text>{`Created: ${Moment(note.createdAt).format('ll')}`}</Text>
        <Text>{'      '}</Text>
        {note.author && <Text>{`Author: ${note.author.name}`}</Text>}
        <Text>{'      '}</Text>
        {note.patientName && <Text>{`Patient Name: ${note.patientName}`}</Text>}
        <Text>{'      '}</Text>
        {note.patientDob && <Text>{`DOB: ${note.patientDob}`}</Text>}
      </View>

      <View style={styles.noteDetail}>
        <NoteDetailView
          note={note}
          files={files}
          canvasImages={canvasImages}
          isWebDrawingNote={isWebDrawingNote}
        />
      </View>
    </View>
  );
}

import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import lodash from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import Divider from '@material-ui/core/Divider';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import { Checkbox } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormSignaturePad from './signature';
import TemplateImage from '../../components/TemplateImage/templateImage';
import DrawingSVG from '../../components/DrawingSVG';
import WebDrawingTool from '../WebDrawingTool';
import eventBus from '../../components/EventBus';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    const { fieldProps, disabled } = props;
    return (
        <Switch
            onClick={() => (fieldProps ? fieldProps.onChange(!fieldProps.value) : null)}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            disabled={!!disabled}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const formBuilderUiSchema = {
    'ui:order': ['*'],
    yesNo: {
        classNames: 'yesno-container',
        'ui:widget': (props) => {
            const { label } = props;
            const marginBottom = {
                marginBottom: 5,
            };
            return (
                <div>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <IOSSwitch disabled />
                </div>
            );
        },
        'ui:readonly': true,
        yesNoValue: {
            'ui:widget': (props) => {
                const { label } = props;
                const marginBottom = {
                    marginBottom: 5,
                };
                return (
                    <div>
                        <label style={marginBottom}>
                            {label}
                            {(() => {
                                if (props.required) {
                                    return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                                }
                            })()}
                        </label>
                        <IOSSwitch disabled />
                    </div>
                );
            },
            'ui:readonly': true,
        },
    },
    signature: {
        'ui:widget': (props) => {
            const { label } = props;

            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };

            return (
                <Grid container direction='column' wrap='nowrap' className='sigBox'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>

                    <Grid container direction='row' wrap='nowrap' className='sigBoxWrap'>
                        <FormSignaturePad fieldProps={props} />
                    </Grid>
                </Grid>
            );
        },
        'ui:readonly': true,
    },
    multiLines: {
        'ui:widget': 'textarea',
        'ui:placeholder': 'Multiple line of text',
        'ui:readonly': true,
    },
    date: {
        'ui:widget': (props) => {
            const { label } = props;

            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };

            return (
                <Grid container direction='column' wrap='nowrap' className='datePickerConsent'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker style={{ width: '250px' }} clearable value={new Date()} placeholder='20/10/2000' format='dd/MM/yyyy' />
                    </MuiPickersUtilsProvider>
                </Grid>
            );
        },
        'ui:readonly': true,
    },
    email: { 'ui:placeholder': 'Email field', 'ui:readonly': true },
    checkbox: {
        'ui:widget': (props) => {
            const { label, required } = props;
            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };
            return (
                <div>
                    <Checkbox style={{ paddingLeft: 0 }} id={props.id} disabled />
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                </div>
            );
        },
    },
    string: { 'ui:placeholder': 'Text field', 'ui:readonly': true },
    label: {
        'ui:readonly': true,
    },
    multipleCheckboxes: {
        'ui:widget': 'checkboxes',
        'ui:readonly': true,
    },
    scale10: {
        'ui:widget': 'range',
        'ui:readonly': true,
    },
    scale100: {
        'ui:widget': 'range',
        'ui:readonly': true,
    },
    image: {
        'ui:widget': (props) => {
            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };
            const { label, required } = props;
            return (
                <div>
                    <div>
                        <label style={marginBottom}>
                            {label}
                            {(() => {
                                if (required) {
                                    return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                                }
                            })()}
                        </label>
                    </div>
                    {props.schema.imageTemplate ? (
                        <div style={{ width: 256, height: 224 }}>
                            <TemplateImage height={224} width={256} fileId={props.schema.imageTemplate} />
                        </div>
                    ) : (
                        <p>BLANK IMAGE</p>
                    )}
                </div>
            );
        },
    },
};

export const templateUiSchema = {
    'ui:order': ['*'],
    yesNo: {
        classNames: 'yesno-container',
        'ui:widget': (props) => {
            const { label } = props;
            const marginBottom = {
                marginBottom: 5,
            };
            return (
                <div>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <IOSSwitch fieldProps={props} />
                </div>
            );
        },
        yesNoValue: {
            'ui:widget': (props) => {
                const { label } = props;
                const marginBottom = {
                    marginBottom: 5,
                };
                return (
                    <div>
                        <label style={marginBottom}>
                            {label}
                            {(() => {
                                if (props.required) {
                                    return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                                }
                            })()}
                        </label>
                        <IOSSwitch fieldProps={props} />
                    </div>
                );
            },
        },
    },
    signature: {
        'ui:widget': (props) => {
            const { label } = props;
            const marginBottom = {
                marginBottom: 5,
            };

            return (
                <Grid container direction='column' wrap='nowrap' className='sigBox'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <Grid container direction='row' wrap='nowrap' className='sigBoxWrap'>
                        <FormSignaturePad fieldProps={props} />
                    </Grid>
                </Grid>
            );
        },
        // "ui:placeholder": "E-signature"
    },
    multiLines: {
        'ui:widget': 'textarea',
        'ui:placeholder': 'Multiple line of text',
    },
    date: {
        'ui:widget': (props) => {
            const [selectedDate, setSelectedDate] = useState(new Date());

            const handleDateChange = (date) => {
                if (date) {
                    setSelectedDate(date);
                    props.onChange(moment(date).format());
                }
            };

            const { label } = props;
            const marginBottom = {
                marginBottom: 5,
            };
            return (
                <Grid container direction='column' wrap='nowrap' className='datePickerConsent'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            clearable
                            value={selectedDate}
                            placeholder='20/10/2000'
                            onChange={(date) => handleDateChange(date)}
                            format='dd/MM/yyyy'
                            style={{ width: 250 }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            );
        },
    },
    email: { 'ui:placeholder': 'Email field' },
    string: { 'ui:placeholder': 'Text field' },
    label: {
        'ui:widget': (props) => {
            const { description } = props;
            return <div>{description}</div>;
        },
        'ui:readonly': true,
    },
    dob: {
        'ui:widget': (props) => {
            const { label } = props;

            let [selectedDate, setSelectedDate] = useState(new Date());

            if (props.value) {
                selectedDate = new Date(props.value);
            }

            const handleDateChange = (date) => {
                setSelectedDate(date);
                props.onChange(moment(date).format());
            };

            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };
            return (
                <Grid container direction='column' wrap='nowrap' className='datePickerConsent'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '250px' }}
                            clearable
                            value={selectedDate}
                            placeholder='20/10/2000'
                            onChange={(date) => handleDateChange(date)}
                            format='dd/MM/yyyy'
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            );
        },
    },
    checkbox: {
        'ui:widget': (props) => {
            const { label } = props;

            return (
                <div>
                    <Checkbox
                        style={{ paddingLeft: 0 }}
                        checked={props.value}
                        id={props.id}
                        onChange={(event) => props.onChange(event.target.checked)}
                    />

                    {label}
                    {(() => {
                        if (props.required) {
                            return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                        }
                    })()}
                </div>
            );
        },
    },
    multipleCheckboxes: {
        'ui:widget': 'checkboxes',
    },
    scale10: {
        'ui:widget': (props) => {
            const marginBottom = {
                marginBottom: 40,
            };
            // const [value, setValue] = React.useState(props.value || 1);

            const handleChange = (event, newValue) => {
                props.onChange(newValue);
            };
            return (
                <Grid container direction='column' wrap='nowrap'>
                    <label style={marginBottom}>
                        {props.label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <Slider
                        value={props.value || 1}
                        min={1}
                        step={0.1}
                        max={10}
                        onChange={handleChange}
                        valueLabelDisplay='on'
                        aria-labelledby='non-linear-slider'
                    />
                </Grid>
            );
        },
    },
    scale100: {
        'ui:widget': (props) => {
            const marginBottom = {
                marginBottom: 40,
            };
            // const [value, setValue] = React.useState(props.value || 1);

            const handleChange = (event, newValue) => {
                props.onChange(newValue);
            };
            return (
                <Grid container direction='column' wrap='nowrap'>
                    <label style={marginBottom}>
                        {props.label}
                        {(() => {
                            if (props.required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <Slider
                        value={props.value || 0}
                        min={0}
                        step={1}
                        max={100}
                        onChange={handleChange}
                        valueLabelDisplay='on'
                        aria-labelledby='non-linear-slider'
                    />
                </Grid>
            );
        },
    },
    image: {
        'ui:widget': (props) => {
            const [templateDataTmp, updateTemplateData] = useState(null);
            const [drawingData, updateDrawingData] = useState(null);
            const [openDrawingDialog, updateOpenDrawingDialog] = useState(false);
            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };
            const { label, required } = props;
            const classes = useStyles();
            return (
                <div>
                    <div>
                        <label style={marginBottom}>
                            {label}
                            {(() => {
                                if (required) {
                                    return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                                }
                            })()}
                        </label>
                    </div>
                    {props.schema.imageTemplate && (
                        <div style={{ display: 'none' }}>
                            <TemplateImage
                                fileId={props.schema.imageTemplate}
                                onImageLoaded={(imageData) => {
                                    updateTemplateData(imageData.encodedFile);
                                }}
                            />
                        </div>
                    )}
                    {
                        <Grid role='button' onKeyDown={null} tabIndex={0} onClick={() => updateOpenDrawingDialog(true)}>
                            {templateDataTmp || drawingData ? (
                                <DrawingSVG
                                    sectionId={`${new Date().getTime().toString()}`}
                                    className={classes.drawing}
                                    templateData={templateDataTmp}
                                    drawingData={drawingData}
                                />
                            ) : (
                                <div>BLANK IMAGE</div>
                            )}
                        </Grid>
                    }
                    <Dialog open={openDrawingDialog} onClose={() => updateOpenDrawingDialog(false)} fullScreen disableEnforceFocus>
                        <WebDrawingTool
                            notShowAddPage
                            isInConsentForm
                            isBackBtnVisible
                            templateData={
                                templateDataTmp
                                    ? {
                                          file: {
                                              id: props.schema.imageTemplate,
                                              encodedFile: templateDataTmp,
                                          },
                                          name: 'test',
                                      }
                                    : null
                            }
                            onCloseDrawingFormNote={() => updateOpenDrawingDialog(false)}
                            onSaveDrawingFormNote={() => updateOpenDrawingDialog(false)}
                            drawingNoteData={
                                drawingData
                                    ? {
                                          drawingData,
                                          id: props.schema.imageTemplate,
                                          title: 'test',
                                      }
                                    : null
                            }
                            saveAction={(data) => {
                                if (Array.isArray(data)) {
                                    if (data.length === 1) {
                                        let drawingData = data[0];
                                        drawingData = JSON.stringify(drawingData.data);
                                        updateDrawingData(drawingData);
                                        props.onChange(drawingData);
                                    }
                                }
                                updateOpenDrawingDialog(false);
                            }}
                            enableClose
                        />
                    </Dialog>
                </div>
            );
        },
    },
};

export const defaultSchema = {
    title: 'Consent Form Builder',
    type: 'object',
    required: ['formName'],
    properties: {
        formName: {
            type: 'string',
            title: 'Form name',
            default: '',
            name: 'formName',
        },
        allow_registration: {
            type: 'boolean',
            title: 'Allow Registration',
            default: false,
            name: 'allow_registration',
        },
    },
};

export const defaultMindbodySchema = {
    title: 'Consent Form Builder',
    type: 'object',
    required: ['formName'],
    properties: {
        formName: {
            type: 'string',
            title: 'Form name',
            default: '',
            name: 'formName',
        },
    },
};

export const defaultSchemaFormData = {
    title: 'Consent Form Builder',
    type: 'object',
    required: [],
    properties: {},
};

export const defaultUiSchema = {
    'ui:order': ['*', 'formName', 'allow_registration'],
    formName: {
        'ui:widget': ({ value, required, id, onChange }) => {
            return (
                <Grid container direction='column' wrap='nowrap' className='sigBox'>
                    <label>
                        Form Name<span>*</span>
                    </label>
                    <Input
                        type='text'
                        autoComplete='off'
                        value={value}
                        required={required}
                        id={id}
                        onChange={(event) => onChange(event.target.value)}
                    />
                </Grid>
            );
        },
    },
    dob: {
        'ui:widget': (props) => {
            const { label, onChange, required, value } = props;

            let [selectedDate, setSelectedDate] = useState(new Date());

            if (value) {
                selectedDate = new Date(value);
            }

            const handleDateChange = (date) => {
                if (date) {
                    setSelectedDate(date);
                    onChange(moment(date).format());
                }
            };

            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };

            return (
                <Grid container direction='column' wrap='nowrap' className='datePickerConsent'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '250px' }}
                            clearable
                            value={selectedDate}
                            placeholder='20/10/2000'
                            onChange={(date) => handleDateChange(date)}
                            format='dd/MM/yyyy'
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            );
        },
    },
};

export const defaultMindbodyUiSchema = {
    'ui:order': ['*', 'formName'],
    formName: {
        'ui:widget': ({ value, required, id, onChange }) => {
            return (
                <Grid container direction='column' wrap='nowrap' className='sigBox'>
                    <label>
                        Form Name<span>*</span>
                    </label>
                    <Input
                        type='text'
                        autoComplete='off'
                        value={value}
                        required={required}
                        id={id}
                        onChange={(event) => onChange(event.target.value)}
                    />
                </Grid>
            );
        },
    },
    dob: {
        'ui:widget': (props) => {
            const { label, onChange, required, value } = props;

            let [selectedDate, setSelectedDate] = useState(new Date());

            if (value) {
                selectedDate = new Date(value);
            }

            const handleDateChange = (date) => {
                if (date) {
                    setSelectedDate(date);
                    onChange(moment(date).format());
                }
            };

            const marginBottom = {
                marginBottom: 5,
                paddingRight: '40px',
            };

            return (
                <Grid container direction='column' wrap='nowrap' className='datePickerConsent'>
                    <label style={marginBottom}>
                        {label}
                        {(() => {
                            if (required) {
                                return <span className='MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444'>*</span>;
                            }
                        })()}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '250px' }}
                            clearable
                            value={selectedDate}
                            placeholder='20/10/2000'
                            onChange={(date) => handleDateChange(date)}
                            format='dd/MM/yyyy'
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            );
        },
    },
};

export const registrationSchema = {
    firstName: {
        type: 'string',
        title: 'First Name',
        default: '',
        required: true,
    },
    middleName: {
        type: 'string',
        title: 'Middle Name',
        default: '',
    },
    lastName: {
        type: 'string',
        title: 'Last Name',
        default: '',
        required: true,
    },
    address: {
        type: 'string',
        title: 'Address',
        default: '',
        required: true,
    },
    phone: {
        type: 'string',
        title: 'Phone',
        default: '',
        required: true,
    },
    email: {
        type: 'string',
        title: 'Email',
        default: '',
        required: true,
    },
    dob: {
        type: 'string',
        title: 'DOB',
        default: '',
        original_type: 'date',
        required: true,
    },
};

export const uiFieldTemplate = (fieldProps) => {
    const [fieldStyles, setFieldStyles] = useState({
        position: 'relative',
    });

    const [editIconStyles, setEditIconStyles] = useState({
        position: 'absolute',
        right: '1rem',
        top: '-1rem',
        color: '#233d4d',
    });

    const [saveIconStyles, setSaveIconStyles] = useState({
        position: 'absolute',
        right: '1rem',
        top: '-1rem',
        color: '#233d4d',
        display: 'none',
    });

    const [fieldLabelStyles, setFieldLabelStyles] = useState({
        display: 'block',
    });

    const [fieldLabelEditStyles, setFieldLabelEditStyles] = useState({
        display: 'none',
        paddingTop: '1rem',
    });

    const [fieldTitle, setFieldTitle] = useState({
        value: fieldProps.schema.title,
    });

    const [required, setRequired] = useState(!!fieldProps.required);
    const [checkboxesLabels, setCheckboxesLabels] = useState({
        items: fieldProps.schema.original_type === 'multipleCheckboxes' ? fieldProps.schema.items.enum : [],
    });

    const handleDeleteField = (fieldProps) => {
        fieldProps.schema.removed = true;
        setFieldStyles({
            position: 'relative',
            display: 'none',
        });
    };

    const handleUpdateField = (event, fieldProps) => {
        setFieldTitle({ value: event.target.value });
        eventBus.dispatch('CONSENT_FORM_FIELD_UPDATE_TITLE', {
            id: fieldProps.schema.name,
            value: event.target.value,
        });
    };

    const handleUpdateRequired = (event, fieldProps) => {
        setRequired(event.target.checked);
        eventBus.dispatch('CONSENT_FORM_FIELD_UPDATE_REQUIRED', {
            id: fieldProps.schema.name,
            required: event.target.checked,
        });
    };

    const handleUpdateCheckboxLabel = (event, index, fieldProps) => {
        const { items } = checkboxesLabels;
        items[index] = event.target.value;
        setCheckboxesLabels({ items });
        eventBus.dispatch('CONSENT_FORM_FIELD_UPDATE_CHECKBOXES_LABELS', {
            id: fieldProps.schema.name,
            items,
        });
    };

    const handleDeleteCheckboxLabel = (index, fieldProps) => {
        const { items } = checkboxesLabels;
        items.splice(index, 1);
        setCheckboxesLabels({ items });
        eventBus.dispatch('CONSENT_FORM_FIELD_UPDATE_CHECKBOXES_LABELS', {
            id: fieldProps.schema.name,
            items,
        });
    };

    const handleUpField = (fieldProps) => {
        eventBus.dispatch('CONSENT_FORM_FIELD_ORDER_UP', {
            id: fieldProps.schema.name,
        });
    };

    const handleDownField = (fieldProps) => {
        eventBus.dispatch('CONSENT_FORM_FIELD_ORDER_DOWN', {
            id: fieldProps.schema.name,
        });
    };

    const onEditorStateChange = (editorState, fieldProps) => {
        fieldProps.schema.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };

    const handleEditLabelField = (fieldProps) => {
        setFieldLabelStyles({
            display: 'none',
        });
        setFieldLabelEditStyles({
            display: 'block',
            paddingTop: '1rem',
        });
        setEditIconStyles({
            position: 'absolute',
            right: '1rem',
            top: '-1rem',
            color: '#233d4d',
            display: 'none',
        });
        setSaveIconStyles({
            position: 'absolute',
            right: '1rem',
            top: '-1rem',
            color: '#233d4d',
            display: 'inline-block',
        });
    };

    const handleSaveLabelField = (fieldProps) => {
        setFieldLabelStyles({
            display: 'block',
        });
        setFieldLabelEditStyles({
            display: 'none',
        });
        setEditIconStyles({
            position: 'absolute',
            right: '1rem',
            top: '-1rem',
            color: '#233d4d',
            display: 'inline-block',
        });
        setSaveIconStyles({
            position: 'absolute',
            right: '1rem',
            top: '-1rem',
            color: '#233d4d',
            display: 'none',
        });
        if (fieldProps.schema.original_type !== 'label') {
            fieldProps.schema.title = fieldTitle.value;
        }
    };

    if (['formName', 'allow_registration'].includes(fieldProps.schema.name) || fieldProps.id === 'root') {
        return (
            <div>
                <div className={fieldProps.classNames}>
                    {fieldProps.description}
                    {fieldProps.children}
                    {fieldProps.errors}
                    {fieldProps.help}
                </div>
                {fieldProps.schema.name === 'allow_registration' && <Divider style={{ marginTop: 10 }} />}
            </div>
        );
    }
    if (fieldProps.schema.original_type === 'label') {
        const blocksFromHTML = convertFromHTML(fieldProps.schema.description);
        const content = ContentState.createFromBlockArray(blocksFromHTML);
        return (
            <div style={fieldStyles}>
                <div style={fieldLabelStyles}>
                    <div className={fieldProps.classNames} style={{ opacity: 0.5 }}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: fieldProps.schema.description,
                            }}
                        />
                    </div>
                </div>
                <div style={fieldLabelEditStyles}>
                    <Editor
                        defaultEditorState={EditorState.createWithContent(content)}
                        onEditorStateChange={(event) => onEditorStateChange(event, fieldProps)}
                    />
                </div>
                <IconButton
                    aria-label='up'
                    style={{
                        position: 'absolute',
                        right: '5rem',
                        top: '-1rem',
                        color: '#233d4d',
                    }}
                    onClick={(event) => handleUpField(fieldProps)}>
                    <ArrowUpIcon fontSize='small' />
                </IconButton>
                <IconButton
                    aria-label='down'
                    style={{
                        position: 'absolute',
                        right: '3rem',
                        top: '-1rem',
                        color: '#233d4d',
                    }}
                    onClick={(event) => handleDownField(fieldProps)}>
                    <ArrowDownIcon fontSize='small' />
                </IconButton>
                <IconButton aria-label='edit' style={editIconStyles} onClick={(event) => handleEditLabelField(fieldProps)}>
                    <Edit fontSize='small' />
                </IconButton>
                <IconButton aria-label='save' style={saveIconStyles} onClick={(event) => handleSaveLabelField(fieldProps)}>
                    <SaveIcon fontSize='small' />
                </IconButton>

                <IconButton
                    aria-label='delete'
                    style={{
                        position: 'absolute',
                        right: '-1rem',
                        top: '-1rem',
                        color: '#233d4d',
                    }}
                    onClick={(event) => handleDeleteField(fieldProps)}>
                    <DeleteIcon fontSize='small' />
                </IconButton>
            </div>
        );
    }

    return (
        <div style={{ ...fieldStyles, ...{ marginBottom: 0 } }}>
            <div
                className={fieldProps.classNames}
                style={{
                    ...fieldLabelStyles,
                    ...{ opacity: 0.5, fontSize: '1rem' },
                }}>
                {fieldProps.description}
                {fieldProps.children}
                {fieldProps.errors}
                {fieldProps.help}
            </div>
            <div
                style={{
                    ...fieldLabelEditStyles,
                    ...{ opacity: 1, width: '100%' },
                }}>
                <TextField
                    onChange={(event) => handleUpdateField(event, fieldProps)}
                    style={{ width: '100%' }}
                    autoComplete='off'
                    value={fieldTitle.value}
                />
                <Checkbox
                    checked={required || false}
                    onChange={(event) => {
                        handleUpdateRequired(event, fieldProps);
                    }}
                    // fullWidth
                />{' '}
                Field is required?
                {fieldProps.schema.original_type === 'multipleCheckboxes' && (
                    <div style={{ paddingTop: '1rem', marginLeft: '3rem' }}>
                        <FormLabel>Checkboxes Labels</FormLabel>
                        <div>
                            {checkboxesLabels.items.map((item, index) => {
                                return (
                                    <div style={{ position: 'relative' }}>
                                        <TextField
                                            onChange={(event) => handleUpdateCheckboxLabel(event, index, fieldProps)}
                                            style={{ width: '90%' }}
                                            value={item}
                                            autoComplete='off'
                                        />
                                        <IconButton
                                            aria-label='add'
                                            style={{
                                                position: 'absolute',
                                                right: '1rem',
                                                color: '#233d4d',
                                            }}
                                            onClick={(event) => {
                                                handleDeleteCheckboxLabel(index, fieldProps);
                                            }}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ position: 'relative' }}>
                            <IconButton
                                aria-label='add'
                                style={{
                                    top: '1rem',
                                    color: '#233d4d',
                                }}
                                onClick={(event) => {
                                    event.target.value = '';
                                    handleUpdateCheckboxLabel(event, checkboxesLabels.items.length, fieldProps);
                                }}>
                                <ControlPointIcon fontSize='small' />
                            </IconButton>
                        </div>
                    </div>
                )}
            </div>

            {!['please explain', 'yesNoValue'].includes(fieldProps.label) && (
                <div>
                    <IconButton
                        aria-label='up'
                        style={{
                            position: 'absolute',
                            right: '5rem',
                            top: '-1rem',
                            color: '#233d4d',
                        }}
                        onClick={(event) => handleUpField(fieldProps)}>
                        <ArrowUpIcon fontSize='small' />
                    </IconButton>
                    <IconButton
                        aria-label='down'
                        style={{
                            position: 'absolute',
                            right: '3rem',
                            top: '-1rem',
                            color: '#233d4d',
                        }}
                        onClick={(event) => handleDownField(fieldProps)}>
                        <ArrowDownIcon fontSize='small' />
                    </IconButton>
                    <IconButton aria-label='edit' style={editIconStyles} onClick={(event) => handleEditLabelField(fieldProps)}>
                        <Edit fontSize='small' />
                    </IconButton>
                    <IconButton aria-label='save' style={saveIconStyles} onClick={(event) => handleSaveLabelField(fieldProps)}>
                        <SaveIcon fontSize='small' />
                    </IconButton>
                    <IconButton
                        aria-label='delete'
                        style={{
                            position: 'absolute',
                            right: '-1rem',
                            top: '-1rem',
                            color: '#233d4d',
                        }}
                        onClick={(event) => handleDeleteField(fieldProps)}>
                        <DeleteIcon fontSize='small' />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export function deleteRemovedField(formSchema, schema) {
    const removedFields = [];
    lodash.forIn(schema.properties, (value, key) => {
        if ('removed' in value && value.removed === true) {
            removedFields.push(key);
        }

        if (formSchema.properties[key] && formSchema.properties[key].description !== value.description) {
            formSchema.properties[key].description = value.description;
        }
    });

    for (const removedField of removedFields) {
        delete formSchema.properties[removedField];
    }
    // Delete in require
    formSchema.required = lodash.filter(schema.required, (o) => {
        return !removedFields.includes(o);
    });

    return formSchema;
}

const useStyles = makeStyles((theme) => ({
    drawing: {
        height: 212,
        width: 212,
    },
}));

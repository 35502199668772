import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';
import _ from 'lodash';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pull from 'lodash/pull';

import { userListQuery } from '../UsersTable/usersTableContainer';
import { userSelected } from '../../data/redux/actions/user';
import { dialogClosed } from '../../data/redux/actions/dialog';

const upsertUserMutation = gql`
    mutation upsertUser(
        $id: ID
        $practiceId: ID
        $locationIds: [ID]
        $name: String
        $email: String
        $providerNumber: String
        $password: String
        $roles: [UserRole]
        $deletedAt: DateTime
        $isActive: Boolean
    ) {
        upsertUser(
            id: $id
            practice: $practiceId
            locationIds: $locationIds
            name: $name
            email: $email
            password: $password
            roles: $roles
            deletedAt: $deletedAt
            providerNumber: $providerNumber
            isActive: $isActive
        ) {
            user {
                id
                name
                email
                providerNumber
                practice {
                    id
                }
                roles
                deletedAt
                isActive
                integrationMetadata
            }
        }
    }
`;

const initialForm = {
    loading: false,
    error: false,
    id: undefined,
    name: undefined,
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    showPassword: false,
    roles: [],
    deletedAt: undefined,
    providerNumber: undefined,
    integrationMetadata: undefined,
    isActive: true,
};

const localHandlers = {
    onArchiveUser:
        ({ form, onSave, updateForm }) =>
        async () => {
            await updateForm({ ...form, deletedAt: new Date() });
            onSave();
        },
    onCheckRole:
        ({ form, updateForm }) =>
        (selectedRole) => {
            const matching = form.roles.includes(selectedRole);
            const newRoles = form.roles.map((role) => role);

            if (!matching) {
                newRoles.push(selectedRole);
            } else {
                pull(newRoles, selectedRole);
            }

            updateForm({ ...form, roles: newRoles });
        },
    onClose:
        ({ dispatch }) =>
        () =>
            dispatch(dialogClosed()),
    onToggleShowPassword:
        ({ form, updateForm }) =>
        () =>
            updateForm({ ...form, showPassword: !form.showPassword }),
};

const apiHandlers = {
    onSave:
        ({ dispatch, form, mutate, practiceId, updateForm, user }) =>
        async () => {
            updateForm({ ...form, loading: true });

            const options = {
                variables: {
                    name: form.name,
                    email: form.email,
                    locationIds: form.locations,
                    password: form.password,
                    practiceId,
                    roles: form.roles,
                    providerNumber: form.providerNumber,
                    isActive: form.isActive,
                },
                refetchQueries: [
                    {
                        query: userListQuery,
                        variables: { practiceId },
                    },
                ],
            };

            if (form.id) {
                options.variables = {
                    ...options.variables,
                    id: form.id,
                    deletedAt: form.deletedAt,
                };
            }

            try {
                await mutate(options);
                if (form.id === user.id) {
                    dispatch(
                        userSelected({
                            ...user,
                            roles: form.roles,
                        })
                    );
                }
                dispatch(dialogClosed());
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
};

const UserDialogContainer = compose(
    connect(({ dialog, practice, user }) => ({
        practiceId: practice.id,
        practiceEncodedId: practice.id,
        practiceName: practice.name,
        userDialogOpen: dialog.type === 'user' && dialog.open,
        userDialogData: dialog.type === 'user' && dialog.data,
        user,
    })),
    withState('form', 'updateForm', initialForm),
    graphql(upsertUserMutation),
    withHandlers(apiHandlers),
    withHandlers(localHandlers),
    lifecycle({
        componentDidUpdate(prevProps) {
            const { userDialogData, userDialogOpen, updateForm } = this.props;

            if (!isEqual(userDialogData, prevProps.userDialogData) && userDialogOpen) {
                if (!isEmpty(userDialogData)) {
                    updateForm({
                        ...initialForm,
                        id: userDialogData.id,
                        locations: _.get(userDialogData, 'locations', []).map((location) => location.id),
                        name: userDialogData.name,
                        email: userDialogData.email,
                        roles: userDialogData.roles,
                        user: userDialogData.user,
                        providerNumber: userDialogData.providerNumber,
                        integrationMetadata: userDialogData.integrationMetadata,
                        isActive: userDialogData.isActive,
                    });
                } else {
                    updateForm(initialForm);
                }
            }
        },
    })
);

export default UserDialogContainer;

import { gql } from '@apollo/client';

export const DELETE_APPOINTMENT_TYPE = gql`
  mutation deleteAppointmentType($id: ID!) {
    deleteAppointmentType(id: $id) {
      id
      name
      color
    }
  }
`;

export const UPSERT_APPOINTMENT_TYPE = gql`
  mutation upsertAppointmentType(
    $id: ID
    $code: String
    $desc: String
    $name: String
    $color: String
    $price: Float
    $duration: Float
    $locationId: ID
    $taxables: [ID]
    $practiceEncodedId: String!
  ) {
    upsertAppointmentType(
      id: $id
      code: $code
      desc: $desc
      name: $name
      color: $color
      price: $price
      duration: $duration
      locationId: $locationId
      practiceEncodedId: $practiceEncodedId
      taxables: $taxables
    ) {
      id
      code
      desc
      name
      color
      price
      duration
    }
  }
`;

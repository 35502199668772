import React from 'react';
import { compose } from 'recompose';

import { Dialog, Button, DialogTitle, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WARM_PINK } from '../../style/constants';

const ArchiveAccountDialog = ({
  classes,
  dialogAction,
  onCloseDialog,
  showDialog,
}) => {
  return (
    <Dialog open={showDialog} onClose={onCloseDialog} maxWidth="lg">
      <DialogTitle className={classes.title}>Please confirm action</DialogTitle>

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="primary" onClick={onCloseDialog}>
          Cancel
        </Button>

        <Button
          color="primary"
          className={classes.confirmButton}
          onClick={async () => {
            dialogAction();
            onCloseDialog();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    padding: 32,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'space-between',
    width: '100%',
    height: '100%',
  },
  title: {
    textAlign: 'center',
  },
  confirmButton: {
    color: WARM_PINK,
  },
};

export default compose(withStyles(styles))(ArchiveAccountDialog);

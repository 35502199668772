import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { UPDATE_PRACTICE } from '../gql';

import ReferralHeadersDialog from '../ReferralHeadersDialog/Dialog';
import { humanizeString } from '../../../utility/string';
import CustomTooltip from '../../../components/CustomTooltip';

const FETCH_PRACTICE = gql`
  query fetchPractice {
    fetchPractice {
      id
      flexibleFieldHeader1
      flexibleFieldHeader2
      flexibleFieldHeader3
      flexibleFieldHeader4
      flexibleFieldHeader5
    }
  }
`;

const ReferralHeaders = ({ isAdmin, locationName, practiceId, classes }) => {
  const [referralHeaders, updateReferralHeaders] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { data } = useQuery(FETCH_PRACTICE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.fetchPractice) {
        // eslint-disable-next-line no-unused-vars
        const { id, __typename, ...referralHeadersFromApi } = res.fetchPractice;
        updateReferralHeaders(referralHeadersFromApi);
      }
    },
    variables: {
      practiceId,
    },
  });

  const [updatePractice] = useMutation(UPDATE_PRACTICE, {
    variables: {
      id: practiceId,
      ...referralHeaders,
    },
    refetchQueries: ['fetchPractice'],
  });

  const handleRowClick = () => {
    if (isAdmin) {
      setIsDialogOpen(true);
    }
  };

  const onClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className={classes.headingContainer}>
        <Typography variant="subtitle1" className={classes.subheading}>
          Extra Patient Data Fields - {locationName}
        </Typography>
        <CustomTooltip
          hidden={!isAdmin}
          title="Select any flexible data field to change the name displayed on the Patient Details page."
        />
      </div>
      {message && <Alert severity="success">{message}</Alert>}
      <Grid className={classes.subSection}>
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Text</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                Object.keys(referralHeaders).map(key => (
                  <TableRow
                    hover
                    onClick={handleRowClick}
                    key={`table-row-${key}`}
                  >
                    <TableCell>{humanizeString(key)}</TableCell>
                    <TableCell>{data.fetchPractice[key]}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <ReferralHeadersDialog
        isDialogOpen={isDialogOpen}
        onClose={onClose}
        referralHeaders={referralHeaders}
        updateReferralHeaders={updateReferralHeaders}
        updatePractice={updatePractice}
        setMessage={setMessage}
      />
    </>
  );
};

const styles = {
  headingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 36,
    paddingBottom: 8,
  },
  subheading: {
    fontWeight: 'bold',
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    locationName: user.currentLocationName,
    practiceId: practice.id,
  }))
)(ReferralHeaders);

import { branch, compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';

import { patientSelected } from '../../data/redux/actions/patient';
import { practiceRefetchToggle } from '../../data/redux/actions/practice';
import { UPSERT_PATIENT } from '../../pages/PatientPage/gql';

const patientNodeQuery = gql`
    query patientNodeQuery($patientId: ID!) {
        node(id: $patientId) {
            __typename
            id
            ... on Patient {
                name
                firstName
                middleName
                lastName
                address
                email
                phone
                mobile
                dob
                isArchived
                flexibleFieldData1
                flexibleFieldData2
                flexibleFieldData3
                flexibleFieldData4
                flexibleFieldData5
                integrationMetadata
                alerts {
                    id
                    type
                    text
                    createdAt
                }
                practice {
                    flexibleFieldHeader1
                    flexibleFieldHeader2
                    flexibleFieldHeader3
                    flexibleFieldHeader4
                    flexibleFieldHeader5
                }
            }
        }
    }
`;

const initialForm = {
    name: undefined,
    firstName: undefined,
    lastName: undefined,
    address: undefined,
    email: undefined,
    phone: undefined,
    mobile: undefined,
    loading: false,
    error: false,
    editing: false,
    dob: new Date(),
    integrationMetadata: undefined,
};

const onSave =
    ({ dispatch, form, mutate, patientId, practiceId, updateForm, history, isConsentFormSubscription }) =>
    async (isArchived = null) => {
        if (isConsentFormSubscription) {
            updateForm({
                ...form,
                loading: false,
                error: {
                    message: 'Please upgrade to full version plan to use this function.',
                },
            });
            return;
        }
        updateForm({ ...form, loading: true });

        const newOptions = {
            variables: {
                name: form.name,
                firstName: form.firstName,
                middleName: form.middleName,
                lastName: form.lastName,
                address: form.address,
                email: form.email,
                dob: Moment(form.dob).format('YYYY-MM-DD'),
                phone: form.phone,
                mobile: form.mobile,
                isArchived: false,
                practiceId,
                integrationMetadata: form.integrationMetadata,
            },
        };

        const editOptions = {
            variables: {
                patientId,
                name: form.name,
                firstName: form.firstName,
                middleName: form.middleName,
                lastName: form.lastName,
                address: form.address,
                email: form.email,
                dob: Moment(form.dob).format('YYYY-MM-DD'),
                phone: form.phone,
                mobile: form.mobile,
                flexibleFieldData1: form.flexibleFieldData1,
                flexibleFieldData2: form.flexibleFieldData2,
                flexibleFieldData3: form.flexibleFieldData3,
                flexibleFieldData4: form.flexibleFieldData4,
                flexibleFieldData5: form.flexibleFieldData5,
                practiceId,
                integrationMetadata: form.integrationMetadata,
            },
            refetchQueries: [
                {
                    query: patientNodeQuery,
                    variables: { patientId },
                },
            ],
        };
        if (isArchived === true || isArchived === false) {
            editOptions.variables.isArchived = isArchived;
            form.isArchived = isArchived;
        }

        try {
            const response = await mutate(patientId ? editOptions : newOptions);

            updateForm({ ...form, loading: false, editing: false, error: false });

            dispatch(patientSelected(response.data.upsertPatient));

            if (!patientId) {
                history.replace(`/patient/${response.data.upsertPatient.patient.id}`);
            }

            dispatch(practiceRefetchToggle('refetchPatientList', true));
        } catch (error) {
            updateForm({ ...form, loading: false, error });
        }
    };

const PatientContainer = compose(
    withRouter,
    connect(({ practice, user }) => ({
        practiceId: practice.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withProps(({ match }) => ({
        patientId: match.params.patientId,
    })),
    withState('form', 'updateForm', initialForm),
    withState('savedPatient', 'updateSavedPatient', initialForm),
    branch(
        ({ patientId }) => patientId,
        compose(
            graphql(patientNodeQuery, {
                name: 'query',
                options: ({ patientId }) => ({
                    variables: { patientId },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    setVariables: query.networkStatus === 2,
                    refetching: query.networkStatus === 4,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                (props) => props.status.success,
                withProps(({ query, form }) => ({
                    name: query.node.name,
                    firstName: query.node.firstName,
                    middleName: query.node.middleName,
                    lastName: query.node.lastName,
                    address: query.node.address,
                    email: query.node.email,
                    phone: query.node.phone,
                    mobile: query.node.mobile,
                    dob: query.node.dob,
                    isArchived: query.node.isArchived,
                    flexibleFieldHeader1: query.node.practice.flexibleFieldHeader1,
                    flexibleFieldHeader2: query.node.practice.flexibleFieldHeader2,
                    flexibleFieldHeader3: query.node.practice.flexibleFieldHeader3,
                    flexibleFieldHeader4: query.node.practice.flexibleFieldHeader4,
                    flexibleFieldHeader5: query.node.practice.flexibleFieldHeader5,
                    flexibleFieldData1: query.node.flexibleFieldData1,
                    flexibleFieldData2: query.node.flexibleFieldData2,
                    flexibleFieldData3: query.node.flexibleFieldData3,
                    flexibleFieldData4: query.node.flexibleFieldData4,
                    flexibleFieldData5: query.node.flexibleFieldData5,
                    integrationMetadata: query.node.integrationMetadata,
                    noChangeInData:
                        query.node.name === form.name &&
                        query.node.address === form.address &&
                        query.node.email === form.email &&
                        query.node.phone === form.phone &&
                        query.node.dob === Moment(form.dob).format('YYYY-MM-DD'),
                }))
            )
        )
    ),
    graphql(UPSERT_PATIENT),
    withHandlers({
        onSave,
        onEdit:
            ({ form, updateForm, isConsentFormSubscription }) =>
            () => {
                if (isConsentFormSubscription) {
                    updateForm({
                        ...form,
                        loading: false,
                        error: {
                            message: 'Please upgrade to full version plan to use this function.',
                        },
                    });
                    return;
                }
                updateForm({ ...form, editing: !form.editing, error: false });
            },
    }),
    lifecycle({
        componentDidMount() {
            const {
                status,
                name,
                firstName,
                middleName,
                lastName,
                address,
                email,
                dob,
                patientId,
                phone,
                mobile,
                updateForm,
                updateSavedPatient,
                form,
                isArchived,
                flexibleFieldHeader1,
                flexibleFieldHeader2,
                flexibleFieldHeader3,
                flexibleFieldHeader4,
                flexibleFieldHeader5,
                flexibleFieldData1,
                flexibleFieldData2,
                flexibleFieldData3,
                flexibleFieldData4,
                flexibleFieldData5,
                integrationMetadata,
            } = this.props;

            updateForm({ ...form, editing: false, error: false });

            if (!patientId) {
                updateForm({
                    ...form,
                    editing: true,
                    error: false,
                    firstName: undefined,
                    middleName: undefined,
                    lastName: undefined,
                    address: undefined,
                    email: undefined,
                    phone: undefined,
                    mobile: undefined,
                    dob: new Date(),
                    isArchived: false,
                    integrationMetadata: undefined,
                });
            } else if (status && status.success) {
                updateForm({
                    ...form,
                    name,
                    firstName,
                    middleName,
                    lastName,
                    address,
                    email,
                    phone,
                    mobile,
                    isArchived,
                    dob: new Date(dob),
                    editing: false,
                    error: false,
                    flexibleFieldHeader1,
                    flexibleFieldHeader2,
                    flexibleFieldHeader3,
                    flexibleFieldHeader4,
                    flexibleFieldHeader5,
                    flexibleFieldData1,
                    flexibleFieldData2,
                    flexibleFieldData3,
                    flexibleFieldData4,
                    flexibleFieldData5,
                    integrationMetadata,
                });
                // To check if the existing data only has one field for name
                updateSavedPatient({
                    ...form,
                    name,
                    firstName,
                    lastName,
                });
            }
        },
        componentDidUpdate(prevProps) {
            const {
                patientId,
                status,
                updateForm,
                form,
                name,
                firstName,
                middleName,
                lastName,
                address,
                email,
                dob,
                phone,
                mobile,
                integrationMetadata,
            } = this.props;

            if (patientId !== prevProps.patientId) {
                if (status && status.success) {
                    updateForm({
                        ...form,
                        editing: false,
                        error: false,
                        name,
                        firstName,
                        middleName,
                        lastName,
                        address,
                        email,
                        phone,
                        mobile,
                        dob: new Date(dob),
                        integrationMetadata,
                    });
                } else {
                    updateForm({
                        ...form,
                        editing: true,
                        error: false,
                    });
                }
            }
        },
    })
);

export default PatientContainer;

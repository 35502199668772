import { USER_SELECTED, USER_AUTHENTICATED, USER_CLEARED, USER_SHOW_TOGGLE } from '../actions/user';

const userDefaultState = {
    id: undefined,
    name: undefined,
    email: undefined,
    roles: [],
    token: undefined,
    showPatientList: false,
    showEpisodeList: false,
    showBreadCrumbs: true,
    isConsentFormSubscription: false,
};

const userReducer = (state = userDefaultState, action) => {
    switch (action.type) {
        case USER_SELECTED:
            return {
                ...state,
                id: action.id,
                name: action.name,
                roles: action.roles,
                isSubscriptionValid: action.isSubscriptionValid,
                isConsentFormSubscription: action.isConsentFormSubscription,
                currentLocationId: action.currentLocationId,
                currentLocationName: action.currentLocationName,
                location: action.location,
                locationOptions: action.locationOptions,
            };
        case USER_AUTHENTICATED:
            return {
                ...state,
                email: action.email,
                token: action.token,
            };
        case USER_SHOW_TOGGLE:
            return {
                ...state,
                showPatient: action.showPatient === undefined ? state.showPatient : action.showPatient,
                showPatientList: action.showPatientList === undefined ? state.showPatientList : action.showPatientList,
                showAttachments: action.showAttachments === undefined ? state.showAttachments : action.showAttachments,
                showNotes: action.showNotes === undefined ? state.showNotes : action.showNotes,
                showEpisode: action.showEpisode === undefined ? state.showEpisode : action.showEpisode,
                showEpisodeList: action.showEpisodeList === undefined ? state.showEpisodeList : action.showEpisodeList,
                showEpisodeListCard: action.showEpisodeListCard === undefined ? state.showEpisodeListCard : action.showEpisodeListCard,
                showBreadCrumbs: action.showBreadCrumbs === undefined ? state.showBreadCrumbs : action.showBreadCrumbs,
            };
        case USER_CLEARED:
            return userDefaultState;
        default:
            return state;
    }
};

export default userReducer;

import React from 'react';
import { compose } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';

import { withStyles } from '@material-ui/core/styles';
import { SECONDARY_BLACK } from '../../style/constants';

import UsersTableContainer from '../UsersTable/usersTableContainer';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';

const columnData = [
    { id: 'name', checkbox: false, label: 'Name' },
    { id: 'email', checkbox: false, label: 'Email' },
    { id: 'admin', checkbox: true, label: 'Admin' },
    { id: 'receptionist', checkbox: true, label: 'Receptionist' },
];

const UsersTableConsentForm = ({ classes, status, error, onUser, users, anchorEl, selectedRole, onOpen, onClose }) => (
    <Paper className={classes.paper}>
        {(() => {
            if (status.loading) return <Loading height={105} />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby='tableTitle'>
                        <TableHead>
                            <TableRow>
                                {columnData.map((column) => (
                                    <TableCell key={column.id}>
                                        {column.label}
                                        {column.checkbox && (
                                            <FontAwesomeIcon
                                                onClick={(event) => {
                                                    onOpen(event, column.label);
                                                }}
                                                size='lg'
                                                className={classes.permissionInfo}
                                                onMouseEnter={(event) => onOpen(event, column.label)}
                                                onMouseLeave={onClose}
                                                icon={faInfoCircle}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow hover className={classes.tableRow} key={user.id}>
                                    <TableCell component='th' scope='row'>
                                        {user.name}
                                    </TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <Checkbox disabled checked={user.roles.includes('ADMIN')} />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox disabled checked={user.roles.includes('RECEPTIONIST')} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Popover
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverPaper,
                        }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={onClose}>
                        <div className={classes.popoverInfo}>
                            <strong>{selectedRole} Permissions:</strong>
                            <br />
                            <ul>
                                {(() => {
                                    if (selectedRole === 'Admin')
                                        return (
                                            <div>
                                                <li>Edit Practice</li>
                                                {/*<li>Edit Users</li>*/}
                                            </div>
                                        );
                                    return null;
                                })()}
                                <div>
                                    <li>Create & Edit Consent Form</li>
                                </div>
                            </ul>
                        </div>
                    </Popover>
                </div>
            );
        })()}
    </Paper>
);

const styles = {
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeaderCheckbox: {
        padding: 16,
    },
    tableRow: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverPaper: {
        padding: 12,
    },
    popoverInfo: {
        color: SECONDARY_BLACK,
        fontSize: 12,
    },
    permissionInfo: {
        marginLeft: 8,
    },
};

export default compose(withStyles(styles), UsersTableContainer)(UsersTableConsentForm);

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { compose, withState } from 'recompose';
import { gql, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { SECONDARY } from '../../style/constants';
import AppBarPublic from '../../components/AppBarPublic';
import Loading from '../../components/Loading';

const VERIFY_RESET_PASSWORD = gql`
    mutation verifyResetPassword($id: String!) {
        verifyResetPassword(id: $id) {
            id
        }
    }
`;

const successContent = (classes) => (
    <Grid component='nav' container wrap='nowrap' direction='column'>
        <div className={classes.pageTitle}>Password updated</div>
        <p className={classes.pageText}>Your password is now updated. Please check your emails for further instructions.</p>
    </Grid>
);

const failContent = (classes) => (
    <Grid component='nav' container wrap='nowrap' direction='column'>
        <div className={classes.pageTitle}>Password not updated</div>
        <p className={classes.pageText}>Your password could not be changed</p>
        <p className={classes.pageText}>Please try reset password again</p>
    </Grid>
);

const ResetPasswordVerifyPage = ({ classes }) => {
    // const { match: {code }, classes,  } = props;
    // const { code } = match.params;
    const [mutateExecuted, setMutateExecuted] = useState(false);

    const { code } = useParams();

    // eslint-disable-next-line no-unused-vars
    const [verifyResetPassword, { data, loading, error }] = useMutation(
        VERIFY_RESET_PASSWORD,
        {
            variables: {
                id: code,
            },
        },
        {
            onCompleted: (result) => {
                // request sent
                // updateDialogOpen(false);
                // if (result.removeConsentForm.success === true) {
                //   updateSuccessMessage('You have successfully removed the form');
                //   updateListForm(result.removeConsentForm.forms);
                // } else {
                //   updateError('There was an error removing the form. Please try again.');
                // }
            },
        }
    );

    const content = () => {
        if (!mutateExecuted) {
            setMutateExecuted(true);
            verifyResetPassword(); // Generates - Warning: Cannot update during an existing state transition
            return <Loading />;
        }

        if (loading) return <Loading />;

        if (error) return failContent(classes);

        const verifySuccess = data && data.verifyResetPassword && data.verifyResetPassword.id;
        return verifySuccess ? successContent(classes) : failContent(classes);
    };

    return (
        <AppBarPublic title='Clinik Note'>
            <Grid component='main' className={classes.main} container>
                {content()}
            </Grid>
        </AppBarPublic>
    );
};

const styles = {
    main: {
        height: '100%',
        paddingTop: 64,
    },
    pageTitle: {
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        fontSize: 32,
        color: SECONDARY,
    },
    pageText: {
        display: 'flex',
        fontSize: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '5px 0',
    },
};

export default compose(withStyles(styles), withState('mutateExecuted', 'setMutateExecuted', false))(ResetPasswordVerifyPage);

import React from 'react';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const ConfirmActionDialog = ({
    classes,
    text,
    open,
    onClose,
    onConfirm,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    confirmStyle,
    textStyle,
}) => (
    <Dialog open={open} onClose={onClose}>
        <div className={classes.wrapper}>
            <Typography variant='subtitle1' className={textStyle || classes.text}>
                {text}
            </Typography>
            <div>
                <Button
                    variant='contained'
                    color='secondary'
                    className={confirmStyle || classes.button}
                    size='small'
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}>
                    {confirmText}
                </Button>
                {cancelText !== '' && (
                    <Button variant='outlined' color='secondary' className={classes.button} size='small' onClick={onClose}>
                        {cancelText}
                    </Button>
                )}
            </div>
        </div>
    </Dialog>
);

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
        minWidth: 300,
        padding: 32,
    },
    text: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    button: {
        margin: 4,
    },
};

export default withStyles(styles)(ConfirmActionDialog);

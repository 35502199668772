import React, { Fragment } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import { ThemeProvider } from '@material-ui/core/styles';

import client from './data/apollo/client';
import { persistor, store } from './data/redux/store';

import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import PatientInvoicingPage from './pages/PatientPage/InvoicingPage';
import SelectPatientPage from './pages/SelectPatientPage';
import SelectFormTemplatePage from './pages/SelectFormTemplatePage';
import UserPracticePage from './pages/UserPracticePage';
import NotePage from './pages/NotePage';
import WebViewNotePage from './pages/WebViewNotePage';
import TemplateListSelectPage from './pages/TemplateListSelectPage';
import NotesPage from './pages/NotesPage';
import FormTemplatePage from './pages/FormTemplatePage';
import SignUpPage from './pages/SignUpPage';
import SimpleSignUpPage from './pages/SimpleSignUpPage';
import SignupVerifyPage from './pages/SignupVerifyPage';
import SubscriptionPage from './pages/SubscriptionPage';
import SubscriptionExpiredPage from './pages/SubscriptionExpiredPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResetPasswordVerifyPage from './pages/ResetPasswordVerifyPage';
import WebDrawingNote from './components/WebDrawingNote';
import WebDrawingTool from './pages/WebDrawingTool';
import ConsentFormBuilder from './pages/ConsentFormBuilder';
import ConsentForm from './pages/ConsentForm';
import ConsentFormList from './pages/ConsentFormList';
import ConsentFormDashboard from './pages/ConsentFormDashboard';
import ConsentFormSubmissions from './pages/ConsentFormSubmissions';
import ConsentFormThankyou from './pages/ConsentFormThankyou';
import { CalendarPage } from './pages/DairyPage';
import TemplateLibrary from './pages/TemplateLibrary';
import VerifyEmailPage from './pages/VerifyEmailPage';
import {
    AppointmentTypesSettings,
    ExternalBookings,
    IntegrationSettings,
    InventorySettings,
    InvoiceSettings,
    PracticeSettings,
    RegionSettings,
    TemplateSettings,
    UsersAvailabilitySettings,
} from './pages/SettingsPage';
import { NewInvoicePage } from './pages/NewInvoicePage';
import InvoiceDetailPage from './pages/InvoiceDetailPage';
import InvoicingPage from './pages/InvoicingPage';
import MindbodyInvoicePage from './pages/MindbodyInvoicePage';
import OutOfBoxExperiencePage from './pages/OutOfBoxExperiencePage';

import Loading from './components/Loading';

import withAuth from './utility/withAuth';
import theme from './style';
import DrawingCanvasContainerWithFile from './pages/WebDrawingTool/DrawingCanvasContainerWithFile';
import AuthenticatedWebView from './pages/AuthenticatedWebView';
import BookingFormPage from './pages/BookingFormPage';
import BookingErrorPage from './pages/BookingErrorPage';
import BookingFormDashboard from './pages/SettingsPage/BookingFormDashboard';
import LogoutPage from './pages/LogoutPage';
import ReportsPage from './pages/ReportsPage';
import { AccountsPage, CreatePatientPage, DetailsScreen, PatientMindbodyInvoicesPage, PatientPage, TransactionsPage } from './pages/PatientPage';
import ReactGA from 'react-ga4';
import TemplatePage from './pages/TemplatePage/TemplatePage';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { REACT_APP_GOOGLE_PLACES_API_KEY } = process.env;

ReactGA.initialize('G-7Z4C73N4QE');

// only load google maps script once per session
if (!window.google) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places&callback=Function.prototype`;
    script.async = true;
    script.type = 'application/javascript';
    document.body.appendChild(script);
}

// <!-- Hotjar Tracking Code for https://app.cliniknote.com/ -->
(function (h, o, t, j, a, r) {
    h.hj =
        h.hj ||
        function () {
            (h.hj.q = h.hj.q || []).push(arguments);
        };
    h._hjSettings = { hjid: 3867429, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <PersistGate loading={<Loading />} persistor={persistor}>
                        <BrowserRouter>
                            <Switch>
                                <Route path='/' exact component={withAuth(LoginPage)} />
                                <Route path='/logout' exact component={LogoutPage} />
                                <Route path='/consent-form-builder/:formKey?' exact component={withAuth(ConsentFormBuilder)} />
                                <Route path='/consent-form/:formKey/:patientId?' exact component={ConsentForm} />
                                <Route path='/consent-form-submissions/:formKey' exact component={ConsentFormSubmissions} />
                                <Route path='/consent-form-list' exact component={withAuth(ConsentFormList)} />
                                <Route path='/consent-form-dashboard' exact component={withAuth(ConsentFormDashboard)} />
                                <Route path='/thankyou' exact component={ConsentFormThankyou} />
                                <Route path='/patient-dashboard' exact component={withAuth(DashboardPage)} />
                                <Route path='/user' exact component={withAuth(UserPracticePage)} />
                                <Route path='/subscription' exact component={withAuth(SubscriptionPage)} />
                                <Route path='/subscriptionExpired' exact component={withAuth(SubscriptionExpiredPage)} />
                                <Route path='/patient' exact component={withAuth(PatientPage)} />
                                <Route path='/patient/select/:route?/:routeTwo?' exact component={withAuth(SelectPatientPage)} />
                                <Route path='/patient/new' exact component={withAuth(CreatePatientPage)} />
                                <Route path='/patient/:patientId' exact component={withAuth(PatientPage)} />
                                <Route path='/patient/:patientId/details' exact component={withAuth(DetailsScreen)} />
                                <Route path='/patient/:patientId/invoicing' exact component={withAuth(PatientInvoicingPage)} />
                                <Route path='/patient/:patientId/mindbodyInvoices' exact component={withAuth(PatientMindbodyInvoicesPage)} />
                                <Route path='/patient/:patientId/transactions' exact component={withAuth(TransactionsPage)} />
                                <Route path='/patient/:patientId/accounts' exact component={withAuth(AccountsPage)} />
                                <Route path='/patient/:patientId/notes' exact component={withAuth(NotesPage)} />
                                <Route path='/patient/:patientId/note/text/:noteId?' exact component={withAuth(NotePage)} />
                                <Route path='/patient/:patientId/note/drawing/:noteId?' exact component={withAuth(NotePage)} />
                                <Route path='/patient/:patientId/note/drawings/:groupId?' exact component={withAuth(NotePage)} />
                                <Route path='/patient/:patientId/note/form/:noteId?' exact component={withAuth(NotePage)} />
                                <Route path='/patient/:patientId/attachments/:noteId?' exact component={withAuth(NotePage)} />
                                <Route path='/formTemplate/select' exact component={withAuth(SelectFormTemplatePage)} />
                                <Route path='/formTemplate/:formTemplateId?' exact component={withAuth(FormTemplatePage)} />
                                <Route path='/signup' exact component={SignUpPage} />
                                <Route path='/trial-signup' exact component={SimpleSignUpPage} />
                                <Route path='/signupVerify/:code' exact component={SignupVerifyPage} />
                                <Route path='/resetPassword' exact component={ResetPasswordPage} />
                                <Route path='/resetPasswordVerify/:code' exact component={ResetPasswordVerifyPage} />
                                <Route path='/patient/:patientId/note/templates' exact component={withAuth(TemplateListSelectPage)} />
                                <Route path='/patient/:patientId/note/templates/webdrawingnote' exact component={withAuth(WebDrawingNote)} />
                                <Route path='/WebDrawing' exact component={WebDrawingTool} />
                                <Route path='/WebDrawingWithFile' exact component={DrawingCanvasContainerWithFile} />
                                <Route path='/Webview/patient/:patientId/note/form/:noteId?' exact component={withAuth(WebViewNotePage)} />
                                <Route path='/AuthenticatedWebView' exact component={AuthenticatedWebView} />
                                <Route path='/library' exact component={withAuth(TemplateLibrary)} />
                                <Route path='/oobe' exact component={withAuth(OutOfBoxExperiencePage)} />
                                <Route path='/settings/integrations' exact component={withAuth(IntegrationSettings)} />
                                <Route path='/mindbodyinvoices' exact component={withAuth(MindbodyInvoicePage)} />
                                <Route path='/templates' exact component={withAuth(TemplatePage)} />
                                <Route path='/templates/form' exact component={withAuth(TemplatePage)} />
                                <Route path='/templates/drawing' exact component={withAuth(TemplatePage)} />
                                <Route path='/templates/library' exact component={withAuth(TemplatePage)} />
                                <Fragment>
                                    <Route path='/diary' exact component={withAuth(CalendarPage)} />
                                    <Route path='/booking/' exact component={withAuth(BookingFormDashboard)} />
                                    <Route path='/booking/:practiceId' exact component={BookingFormPage} />
                                    <Route path='/booking-error' exact component={BookingErrorPage} />
                                    <Route path='/diary/appointment/:appointmentId' exact component={withAuth(CalendarPage)} />
                                    <Route path='/settings' exact component={withAuth(PracticeSettings)} />
                                    <Route path='/settings/practice' exact component={withAuth(PracticeSettings)} />
                                    <Route path='/settings/users-availability' exact component={withAuth(UsersAvailabilitySettings)} />
                                    <Route path='/settings/region' exact component={withAuth(RegionSettings)} />
                                    <Route path='/settings/appointment-types' exact component={withAuth(AppointmentTypesSettings)} />
                                    <Route path='/settings/clinician-templates' exact component={withAuth(TemplateSettings)} />
                                    <Route path='/settings/invoicing' exact component={withAuth(InvoiceSettings)} />
                                    <Route path='/settings/inventory' exact component={withAuth(InventorySettings)} />
                                    <Route path='/settings/external-bookings' exact component={withAuth(ExternalBookings)} />
                                    <Route path='/new-invoice' exact component={withAuth(NewInvoicePage)} />
                                    <Route path='/invoices/:invoiceId' exact component={withAuth(InvoiceDetailPage)} />
                                    <Route path='/invoices' exact component={withAuth(InvoicingPage)} />
                                    <Route path='/reports' exact component={withAuth(ReportsPage)} />
                                    <Route path='/verify-email' exact component={VerifyEmailPage} />
                                </Fragment>
                                <Route path='/*' component={() => <Redirect to='/diary' />} />
                            </Switch>
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </ApolloProvider>
        </ThemeProvider>
    );
};

export default App;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import { WARM_PINK } from '../../style/constants';
import { AppointmentTypeDialog } from '../../components/BillingSettingsDialog';
import BaseSettingsPage from './Base';
import AppointmentTimeSlotDialog from './AppointmentSlot/Dialog';
import { FETCH_LOCATION, LIST_APPOINTMENT_TYPES, UPSERT_LOCATION } from './gql';
import { practiceSelected } from '../../data/redux/actions/practice';
import Restricted from '../../components/Restricted';
import CustomTooltip from '../../components/CustomTooltip';
import { formatMoney } from '../../utility';

const INITIAL_STATE = {
    taxRateIds: [],
};

const AppointmentTypesSettings = ({ classes, locationId, practiceEncodedId, location, hasIntegration }) => {
    const dispatch = useDispatch();
    const [selectedAppointmentType, setSelectedAppointmentType] = useState(INITIAL_STATE);
    const [taxRates, setTaxRates] = useState([]);
    const [dialogState, setDialogState] = useState(false);
    const [appointmentTimeSlotDialog, setAppointmentTimeSlotDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [appointmentSlot, setAppointmentSlot] = useState('');

    const { data } = useQuery(LIST_APPOINTMENT_TYPES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            locationId,
        },
    });
    const appointmentTypes = _.get(data, 'listAppointmentTypes', []);

    useQuery(FETCH_LOCATION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.fetchLocation && res.fetchLocation.length) {
                const { appointmentTimeSlot, taxRates: newTaxRates } = res.fetchLocation[0];
                setAppointmentSlot(appointmentTimeSlot);
                setTaxRates(newTaxRates);
            }
        },
        variables: {
            id: locationId,
            practiceEncodedId,
        },
    });

    const [upsertLocation] = useMutation(UPSERT_LOCATION, {
        variables: {
            id: locationId,
            appointmentTimeSlot: appointmentSlot,
            practiceEncodedId,
        },
        onCompleted: (res) => {
            if (res.upsertLocation) {
                setSuccessMessage('Settings updated successfully');
                dispatch(practiceSelected({ appointmentTimeSlot: appointmentSlot }));
                handleCloseTimeSlot();
            }
        },
        onError: (error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        },
    });

    const handleCloseTimeSlot = () => {
        setAppointmentTimeSlotDialog(false);
    };

    const handleSave = () => {
        upsertLocation();
    };

    return (
        <BaseSettingsPage>
            {successMessage && (
                <Paper>
                    <Alert severity='success'>{successMessage}</Alert>
                </Paper>
            )}
            <div className={classes.header}>
                <Typography variant='subtitle1' className={classes.subheading}>
                    Appointment Types
                </Typography>

                {!hasIntegration && (
                    <Restricted isAllowedTo='upsertAppointmentTypes'> 
                        <Button
                            className={classes.redButton}
                            variant='contained'
                            onClick={() => {
                                setSelectedAppointmentType(INITIAL_STATE);
                                setDialogState(true);
                            }}>
                            ADD NEW
                        </Button>
                    </Restricted>
                )}
            </div>
            <Grid className={classes.subSection}>
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Duration</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appointmentTypes.map((item) => {
                                const { code, desc, name, color, price, duration } = item;
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => {
                                            setSelectedAppointmentType(item);
                                            setDialogState(true);
                                        }}
                                        key={`table-row-${code}`}>
                                        <TableCell>{code}</TableCell>
                                        <TableCell>{desc}</TableCell>
                                        <TableCell>{name}</TableCell>
                                        <TableCell>
                                            <div className={classes.colorIcon} style={{ background: color }} />
                                        </TableCell>
                                        <TableCell>{formatMoney(price, location.currency.currency)}</TableCell>
                                        <TableCell>{duration}mins</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <div className={classes.headingContainer}>
                <Typography variant='subtitle1' className={classes.subheading}>
                    Time Slot
                </Typography>
                <CustomTooltip title='Select time allocated for Appointment blocks' />
            </div>

            <Grid className={classes.subSection}>
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} aria-label='simple table'>
                        <TableBody>
                            <TableRow
                                hover
                                onClick={() => {
                                    setAppointmentTimeSlotDialog(true);
                                }}>
                                <TableCell>Appointment Time Slot</TableCell>
                                <TableCell>{appointmentSlot ? `${appointmentSlot} mins` : 'Not Set'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {appointmentTimeSlotDialog && (
                <Restricted isAllowedTo='upsertLocation'>
                    <AppointmentTimeSlotDialog
                        appointmentSlot={appointmentSlot}
                        setAppointmentSlot={setAppointmentSlot}
                        handleClose={handleCloseTimeSlot}
                        handleSave={handleSave}
                    />
                </Restricted>
            )}
            {dialogState && (
                <Restricted isAllowedTo='upsertAppointmentTypes'>
                    <AppointmentTypeDialog
                        existingAppointmentType={selectedAppointmentType}
                        onCloseDialog={() => {
                            setDialogState(false);
                            setSelectedAppointmentType({});
                        }}
                        showDialog={dialogState}
                        taxRates={taxRates}
                    />
                </Restricted>
            )}
        </BaseSettingsPage>
    );
};

const styles = {
    spacedHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    subheading: {
        fontWeight: 'bold',
    },
    colorIcon: {
        width: 20,
        height: 20,
        border: 'solid 1px #e0e0e0',
    },
    subSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    redButton: {
        height: 36,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user, integration }) => ({
        locationId: user.currentLocationId,
        practiceEncodedId: practice.id,
        paymentLine1: practice.paymentLine1,
        location: user.location,
        hasIntegration: integration.id !== undefined,
    }))
)(AppointmentTypesSettings);

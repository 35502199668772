import React, { useEffect } from 'react';
import { branch, compose, lifecycle, withProps, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import PatientSubmissions from '../ConsentFormSubmissions/submissions';
import ConsentFormList from '../ConsentFormList';
import { WHITE } from '../../style/constants';

import { episodeCleared } from '../../data/redux/actions/episode';
import { formTemplateCleared } from '../../data/redux/actions/formTemplate';
import { noteCleared } from '../../data/redux/actions/note';
import { notesCleared } from '../../data/redux/actions/notes';
import { practiceSearched } from '../../data/redux/actions/practice';

import BasePatientPage from './Base';
import EpisodeList from '../../components/EpisodeList';
import FloatingActionButtons from '../../components/FloatingActionButtons';
import Notes from '../../components/Notes';
import Loading from '../../components/Loading';
import EmptyData from '../../components/EmptyData';
import ErrorMessage from '../../components/ErrorMessage';
import { GET_PATIENT_SUBMISSIONS } from './gql';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PatientPage = ({
    classes,
    history,
    location,
    match,
    patient,
    patientId,
    submissions,
    openFormDialog,
    updateOpenFormDialog,
    getPatientSubmissionsQuery,
    expandSubmission,
    updateExpandSubmission,
}) => {
    const routerLocation = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: routerLocation.pathname,
        });
    }, [routerLocation]);

    const age = moment().diff(patient.dob, 'years');

    return (
        <BasePatientPage encodedPatientId={patientId} match={match}>
            {patient.alerts != null && patient.alerts.length > 0 && (
                <>
                    <Typography variant='subtitle1' className={classes.subheading}>
                        Alerts
                    </Typography>
                    <div className={classes.alertsContainer}>
                        {patient.alerts.map((alert, index) => (
                            <div key={alert.text} className={classes.alertBox}>
                                {alert.text}
                            </div>
                        ))}
                    </div>
                </>
            )}
            <Typography variant='subtitle1' className={classes.subheading}>
                Selected Patient
            </Typography>
            <Paper className={classes.episodesPaper}>
                <Table className={classes.table} aria-label='simple table'>
                    <TableBody>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>{patient.name}</TableCell>
                        </TableRow>
                        {patient.dob && (
                            <TableRow>
                                <TableCell>Date of Birth</TableCell>
                                <TableCell>{`${moment(patient.dob).format('LL')} (${age} year${age === 1 ? '' : 's'} old)`}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>

            <Notes attachments hasCollapse location={location} match={match} />

            <Typography variant='subtitle1' className={classes.subheading}>
                All Consent Form Submissions
            </Typography>
            <Accordion className={classes.submissionsAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => updateExpandSubmission(!expandSubmission)}
                    aria-controls='panel1a-content'
                    id='panel1a-header'>
                    <Typography variant='body2' className={classes.collapseText}>
                        {submissions && !expandSubmission ? `${submissions.length} submissions` : ''}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Paper className={classes.submissionsPaper}>
                        {getPatientSubmissionsQuery && getPatientSubmissionsQuery.loading && <Loading height={100} />}
                        {getPatientSubmissionsQuery && getPatientSubmissionsQuery.error && <ErrorMessage error='Can not query submissions data.' />}
                        {getPatientSubmissionsQuery && !getPatientSubmissionsQuery.loading && (!submissions || submissions.length === 0) && (
                            <EmptyData text='There are no submissions available' />
                        )}
                        {submissions && submissions.length > 0 && <PatientSubmissions submissions={submissions} />}
                    </Paper>
                </AccordionDetails>
            </Accordion>
            <Typography variant='subtitle1' className={classes.subheading}>
                Notes Grouped by Episode / Diagnosis / Injury
            </Typography>

            <EpisodeList />

            <Dialog open={openFormDialog} onClose={() => updateOpenFormDialog(false)} maxWidth='md' fullWidth>
                <DialogTitle disableTypography id='alert-dialog-title'>
                    <Typography variant='h6' style={{ width: '60%', float: 'left' }}>
                        Consent Forms
                    </Typography>
                    <IconButton aria-label='close' style={{ float: 'right' }} onClick={() => updateOpenFormDialog(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ConsentFormList patientId={patientId} />
                </DialogContent>
            </Dialog>

            <FloatingActionButtons
                history={history}
                location={location}
                match={match}
                patient
                episode
                textNote
                mediaNote
                formNote
                webDrawingNote
                consentFormList
                onOpenConsentFormList={() => {
                    updateOpenFormDialog(true);
                }}
            />
        </BasePatientPage>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    leftButton: {
        position: 'absolute',
        left: 0,
        marginLeft: 12,
    },
    patientList: {
        maxWidth: 350,
        backgroundColor: WHITE,
        overflow: 'auto',
    },
    subheading: {
        paddingBottom: 8,
        paddingTop: 8,
        fontWeight: 'bold',
    },
    episodesPaper: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    submissionsPaper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        // marginBottom: 24,
    },
    submissionsAccordion: {
        marginBottom: 24,
    },
    collapseText: {
        alignSelf: 'center',
        flexGrow: 1,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    alertsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 20,
        marginBottom: 15,
    },
    alertBox: {
        padding: 20,
        backgroundColor: '#dc3545',
        color: 'white',
        borderRadius: 15,
        minHeight: 60,
        width: '30%',
    },
};

export default compose(
    connect(({ patient, practice, user }) => ({
        currentLocationId: user.currentLocationId,
        patient: patient,
        patientEncodedId: patient.id,
        practiceEncodedId: practice.id,
    })),
    withStyles(styles),
    withProps(({ match }) => ({
        patientId: match.params.patientId,
    })),
    withState('submissions', 'updateSubmissions', null),
    withState('openFormDialog', 'updateOpenFormDialog', false),
    withState('expandSubmission', 'updateExpandSubmission', false),
    branch(
        ({ patientId }) => Boolean(patientId),
        graphql(GET_PATIENT_SUBMISSIONS, {
            name: 'getPatientSubmissionsQuery',
            options: ({ patientId, submissions, updateSubmissions }) => ({
                variables: {
                    patientId,
                },
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    submissions = data.listSubmissionsByPatient.submissions;
                    updateSubmissions(submissions);
                },
            }),
        })
    ),
    lifecycle({
        componentDidMount() {
            this.props.dispatch(episodeCleared());
            this.props.dispatch(formTemplateCleared());
            this.props.dispatch(noteCleared());
            this.props.dispatch(notesCleared());
        },
        componentWillUnmount() {
            this.props.dispatch(practiceSearched(null));
        },
    })
)(PatientPage);

import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';

import { userShowToggle } from '../../data/redux/actions/user';

const AppBarMenuContainer = compose(
  withRouter,
  withApollo,
  connect(({ episode, formTemplate, note, patient, practice, user }) => ({
    isLoggedIn: user.id && user.currentLocationName,
    episodeId: episode.id,
    episodeName: episode.name,
    formTemplateId: formTemplate.id,
    formTemplateName: formTemplate.name,
    locationName: user.currentLocationName,
    noteId: note.id,
    noteTitle: note.title,
    patient,
    patientId: patient.id,
    patientName: patient.name,
    practiceName: practice.name,
    userId: user.id,
    userName: user.name,
    showEpisodeList: user.showEpisodeList,
    showPatientList: user.showPatientList,
    showBreadCrumbs: user.showBreadCrumbs,
  })),
  withProps(({ formTemplateId, patientId, noteId, location }) => ({
    atDiary: location.pathname === '/diary',
    atSettings: location.pathname.includes('settings'),
    atDashboard: location.pathname === '/patient-dashboard',
    atEpisode: location.pathname.includes('/notes'),
    atFormTemplate: location.pathname.includes('/formTemplate'),
    atNote:
      location.pathname.includes('note') ||
      location.pathname.includes('group') ||
      location.pathname.includes('attachments'),
    atSelectFormTemplate:
      !formTemplateId && !noteId && location.pathname.includes('/note/form'),
    atSelectPatient: location.pathname.includes('/patient/select'),
    atSubscription: location.pathname.includes('/subscription'),
    atPatient: location.pathname === `/patient/${patientId}`,
    atUserPractice: location.pathname === '/user',
  })),
  withProps(
    ({
      atDiary,
      atSettings,
      atDashboard,
      atFormTemplate,
      atSelectPatient,
      atSubscription,
      atUserPractice,
    }) => ({
      showSideBar:
        !atDiary &&
        !atSettings &&
        !atDashboard &&
        !atSelectPatient &&
        !atUserPractice &&
        !atFormTemplate &&
        !atSubscription &&
        window.innerWidth >= 600,
    })
  ),
  withHandlers({
    onBack: ({ history }) => () => history.goBack(),
    onBreadCrumbs: ({ showBreadCrumbs, dispatch }) => () =>
      dispatch(userShowToggle('showBreadCrumbs', !showBreadCrumbs)),
    onPatientList: ({ showEpisodeList, showPatientList, dispatch }) => () => {
      if (showEpisodeList)
        dispatch(userShowToggle('showEpisodeList', !showEpisodeList));
      dispatch(userShowToggle('showPatientList', !showPatientList));
    },
    onEpisodeList: ({ showEpisodeList, showPatientList, dispatch }) => () => {
      if (showPatientList)
        dispatch(userShowToggle('showPatientList', !showPatientList));
      dispatch(userShowToggle('showEpisodeList', !showEpisodeList));
    },
  })
);

export default AppBarMenuContainer;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import moment from 'moment';

import { Button, Typography, TextField, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { ToggleButton } from '@material-ui/lab';

import { WARM_PINK, BOULDER } from '../../style/constants';
import { BOOKING_STATUS } from './constants';
import LoginModal from './LoginModal';

const PatientDetails = ({
  classes,
  user,
  booking,
  setBooking,
  setActiveTab,
  showLoginDialog,
  setShowLoginDialog,
}) => {
  const [patient, setPatient] = useState(booking.patient || {});
  const [isValidEmail, setIsValidEmail] = useState(true);
  // const [isValidDOB, setIsValidDOB] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;
  // const dobRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  const validateEmail = event => {
    const email = event.target.value;
    handleChange({ email });
    setIsValidEmail(emailRegex.test(email));
  };

  const validateDOB = event => {
    const dob = event.target.value;
    handleChange({ dob });
    // setIsValidDOB(dob.length === 10 && dobRegex.test(dob));
  };

  useEffect(() => {
    const nameSplit = patient.patientName ? patient.patientName.split(' ') : '';
    if (nameSplit.length && !booking.newPatient) {
      handleChange({
        firstName: nameSplit.slice(0, -1).join(' '),
        lastName: nameSplit.slice(-1).join(' '),
      });
    }
  }, []);

  useEffect(() => {
    setBooking({ ...booking, patient });
  }, [patient]);

  const handleChange = newEntry => {
    setPatient({ ...patient, ...newEntry });
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.fieldContainer}>
          <Grid className={classes.textFieldGroup} container>
            <Typography>Book {booking.type.desc}</Typography>
            <Typography>
              <strong>{moment(booking.time.code).format('llll')}</strong>
            </Typography>
            <a className={classes.changeLink} onClick={() => setActiveTab(1)}>
              Change
            </a>
          </Grid>
        </div>
      </div>
      <div className={classes.contentContainer}>
        <Grid className={classes.headingContent} container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h6" component="h1">
              {BOOKING_STATUS[2]}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} className={classes.loginButtonContainer}>
            <LoginModal
              booking={booking}
              setBooking={setBooking}
              setShowLoginDialog={setShowLoginDialog}
              showLoginDialog={showLoginDialog}
            />
          </Grid>
        </Grid>

        <div className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body1">
            About the Patient
          </Typography>

          <div className={classes.textFieldGroup}>
            <Grid className={classes.textFieldGroup} container spacing={2}>
              {/* Commented out for now; still need to scope what is an existing/new patient */}
              {/* <div className={classes.patientButtonGroup}>
                <Typography className={classes.title} variant="body2">
                  Have you been seen at this clinic before?
                </Typography>

                <ToggleButton
                  value="existing"
                  className={classes.toggleButton}
                  selected={!booking.newPatient}
                  onChange={() => {
                    setBooking({ ...booking, newPatient: false });
                  }}
                >
                  Existing Patient
                </ToggleButton>

                <ToggleButton
                  value="new"
                  className={classes.toggleButton}
                  selected={booking.newPatient}
                  onChange={() => {
                    setBooking({ ...booking, newPatient: true });
                  }}
                >
                  New Patient
                </ToggleButton>
              </div> */}

              <TextField
                className={classes.field}
                placeholder="First name*"
                value={patient.firstName}
                disabled={!booking.newPatient}
                autoComplete="off"
                onChange={event =>
                  handleChange({ firstName: event.target.value })
                }
              />

              <TextField
                className={classes.field}
                placeholder="Last name*"
                autoComplete="off"
                disabled={!booking.newPatient}
                value={patient.lastName}
                onChange={event =>
                  handleChange({ lastName: event.target.value })
                }
              />

              <TextField
                className={classes.field}
                placeholder="Email*"
                value={patient.email || ''}
                onChange={validateEmail}
                disabled={!booking.newPatient}
                error={!isValidEmail}
                autoComplete="off"
                helperText={!isValidEmail && 'Email is invalid'}
              />

              <TextField
                className={classes.field}
                placeholder="Phone*"
                autoComplete="off"
                value={patient.phone || ''}
                onChange={event => handleChange({ phone: event.target.value })}
              />

              <div className={classes.dobField}>
                <TextField
                  id="appt-patient-dob"
                  autoComplete="off"
                  placeholder="DOB"
                  disabled={!booking.newPatient}
                  className={classes.calendar}
                  type="date"
                  value={patient.dob || ''}
                  InputProps={{
                    startAdornment: (
                      <div className={classes.adornment} position="start">
                        DOB*
                      </div>
                    ),
                  }}
                  onChange={validateDOB}
                  // error={patient.dob && !isValidDOB}
                  // helperText={!isValidDOB && 'Date of birth is invalid'}
                />
              </div>
            </Grid>
          </div>
        </div>

        <div className={classes.buttonGroup}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="secondary"
            onClick={() => setActiveTab(1)}
          >
            Back
          </Button>

          <Button
            className={classes.nextButton}
            variant="contained"
            color="secondary"
            onClick={() => setActiveTab(4)}
            disabled={
              !patient.dob ||
              !patient.firstName ||
              !patient.lastName ||
              !patient.phone ||
              !patient.email
              // need to disable things if these don't work...
            }
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

const styles = {
  contentContainer: {
    padding: '32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'start',
    border: 'solid 1px #e6e6e6',
    background: '#ffffff',
    width: '100%',
    height: '100%',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  loginButtonContainer: {
    alignSelf: 'flex-end',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textFieldGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  field: {
    display: 'flex',
    alignContent: 'center',
    width: 253,
    height: 40,
    marginBottom: 8,
  },
  dobField: {
    display: 'flex',
    alignContent: 'center',
    width: 253,
    height: 40,
    marginBottom: 8,
  },
  adornment: {
    marginRight: 8,
  },
  calendar: {
    width: 253,
    height: 40,
  },
  changeLink: {
    color: WARM_PINK,
    cursor: 'pointer',
  },
  headingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  patientButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 15,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  backButton: {
    width: 'auto',
    height: 40,
    color: BOULDER,
    backgroundColor: '#f5f5f5',
    alignSelf: 'flex-start',
  },
  nextButton: {
    width: 'auto',
    height: 40,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    alignSelf: 'flex-end',
  },
};

export default compose(withStyles(styles))(PatientDetails);

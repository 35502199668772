import { gql } from '@apollo/client';

export const DELETE_EVENT = gql`
    mutation deleteEvent($id: ID!) {
        deleteEvent(id: $id) {
            id
            start
            end
        }
    }
`;

export const UPSERT_APPT_INVOICE = gql`
    mutation upsertAppointmentInvoice(
        $price: Float
        $practiceId: ID!
        $quantity: Float
        $appointmentId: ID!
        $comments: String!
        $clinicianName: String!
        $date: Date!
    ) {
        upsertAppointmentInvoice(
            price: $price
            practiceId: $practiceId
            quantity: $quantity
            appointmentId: $appointmentId
            clinicianName: $clinicianName
            comments: $comments
            date: $date
        ) {
            id
            invoiceItem {
                id
                comments
                quantity
                price
                createdAt
                clinicianName
            }
        }
    }
`;

export const UPSERT_APPOINTMENT_ARRIVAL = gql`
    mutation upsertAppointmentArrival($id: ID, $checkedIn: [String], $invoiced: [String]) {
        upsertAppointmentArrival(id: $id, checkedIn: $checkedIn, invoiced: $invoiced) {
            appointment {
                id
                patients {
                    id
                    name
                }
                start
                end
                clinician {
                    id
                    name
                }
                notes
                checkedIn
                invoiced
            }
        }
    }
`;

export const UPSERT_APPOINTMENT = gql`
    mutation upsertAppointment(
        $id: ID
        $appointmentType: ID
        $patientIds: [ID]
        $start: DateTime
        $end: DateTime
        $clinicianEncodedId: ID!
        $locationId: ID
        $notes: String
        $checkedIn: [String]
        $invoiced: [String]
        $sendNotification: Boolean
    ) {
        upsertAppointment(
            id: $id
            appointmentType: $appointmentType
            patients: $patientIds
            start: $start
            end: $end
            clinicianEncodedId: $clinicianEncodedId
            locationId: $locationId
            notes: $notes
            checkedIn: $checkedIn
            invoiced: $invoiced
            sendNotification: $sendNotification
        ) {
            appointment {
                id
                appointmentType {
                    id
                    name
                    color
                    code
                    duration
                    price
                }
                patients {
                    id
                    name
                    email
                    phone
                    dob
                }
                start
                end
                notes
                clinician {
                    id
                    name
                }
                checkedIn
                invoiced
                newPatient
                practice
            }
        }
    }
`;

export const DELETE_APPOINTMENT = gql`
    mutation deleteAppointment($id: ID!) {
        deleteAppointment(id: $id) {
            id
            patients {
                id
                name
            }
        }
    }
`;

import React from 'react';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// import { navCalendar } from '../../images/NavBar';

const DateSelector = ({ booking, setBooking }) => (
  <>
    {/* <Grid className={classes.iconLink} onClick={onClickIconHandler}>
        <img src={navCalendar} alt="Select Date" />
      </Grid> */}
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={booking.start || ''}
        onChange={date =>
          setBooking({
            ...booking,
            start: moment(date)
              .startOf('day')
              .format(),
          })
        }
        minDate={new Date()}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  </>
);

export default DateSelector;

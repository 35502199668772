import { branch, compose, withHandlers, withProps, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';

import { dialogOpen } from '../../data/redux/actions/dialog';

export const userListQuery = gql`
    query userList($practiceId: ID!) {
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                users {
                    id
                    name
                    email
                    roles
                    providerNumber
                    locations {
                        id
                        name
                    }
                    isActive
                    integrationMetadata
                }
            }
        }
    }
`;

const UsersTableContainer = compose(
    connect(({ practice }) => ({
        practiceId: practice.id,
    })),
    graphql(userListQuery, {
        name: 'query',
        options: ({ practiceId }) => ({
            variables: { practiceId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withState('anchorEl', 'updateAnchorEl', null),
    withState('selectedRole', 'updateSelectedRole', null),
    withProps(({ query }) => ({
        error: query.error,
        status: {
            loading: query.networkStatus === 1,
            success: query.networkStatus === 7 && Boolean(query.node),
            error: query.networkStatus === 8,
        },
    })),
    branch(
        ({ status }) => status.success,
        withProps(({ query }) => ({
            users: query.node.users,
        }))
    ),
    withHandlers({
        onUser:
            ({ dispatch }) =>
            (userData, allUsers) =>
                dispatch(dialogOpen({ type: 'user', data: userData })),
        onOpen:
            ({ updateAnchorEl, updateSelectedRole }) =>
            (event, role) => {
                updateSelectedRole(role);
                updateAnchorEl(event.currentTarget);
            },
        onClose:
            ({ updateAnchorEl }) =>
            () => {
                updateAnchorEl(null);
            },
    })
);

export default UsersTableContainer;

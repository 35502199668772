import { NOTE_UPSERT, NOTE_CLEARED } from '../actions/note';

const noteDefaultState = {
  __typename: undefined,
  id: undefined,
  title: undefined,
  isSignedOff: false,
  signedOffTime: undefined,
  templateId: undefined,
  groupNotes: [],
  groupTemplates: [],
  groupId: undefined,
};

const NoteReducer = (state = noteDefaultState, action) => {
  switch (action.type) {
    case NOTE_UPSERT:
      return {
        __typename: action.__typename || state.__typename,
        id: action.id || state.id,
        title: action.title || state.title,
        isSignedOff: action.isSignedOff || state.isSignedOff,
        signedOffTime: action.signedOffTime || state.signedOffTime,
        templateId: action.templateId || state.templateId,
        groupNotes: action.groupNotes || state.groupNotes,
        groupTemplates: action.groupTemplates || state.groupTemplates,
        groupId: action.groupId || state.groupId,
      };
    case NOTE_CLEARED:
      return noteDefaultState;
    default:
      return state;
  }
};

export default NoteReducer;

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';

import BaseSettingsPage from '../Base';
import EditPractice from './EditPractice';
import ChangeReferralHeaders from './ChangeReferralHeaders';
import PracticeLocations from './PracticeLocations';
import PracticeLogo from './PracticeLogo';
import { convertToDisplay, operatingDays } from '../constants';
import { OperatingHoursDialog } from '../../DairyPage/CalendarSettingsDialog';
import Loading from '../../../components/Loading';
import { FETCH_LOCATION } from '../gql';
import { WARM_PINK } from '../../../style/constants';
import Restricted from '../../../components/Restricted';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PracticeSettings = ({ classes, locationId, locationName, onPractice, practiceEncodedId, practiceName, isAdmin, hasIntegration }) => {
    const [operatingHours, setOperatingHours] = useState({});
    const [dialogState, setDialogState] = useState('');
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        });
    }, [location]);

    const { loading } = useQuery(FETCH_LOCATION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.fetchLocation && res.fetchLocation.length) {
                setOperatingHours(res.fetchLocation[0]);
            }
        },
        variables: {
            id: locationId,
            practiceEncodedId,
        },
    });

    const handleClose = () => {
        setDialogState('');
    };

    if (loading) return <Loading />;

    return (
        <BaseSettingsPage>
            <EditPractice practiceName={practiceName} practice={practiceEncodedId} onPractice={onPractice} />
            <Restricted isAllowedTo='upsertLocation'>
                <PracticeLocations />
            </Restricted>
            <Restricted isAllowedTo='upsertPractice'>
                <PracticeLogo />
            </Restricted>
            {!hasIntegration && (
                <Restricted isAllowedTo='upsertPractice'>
                    <ChangeReferralHeaders isAdmin={isAdmin} />
                </Restricted>
            )}
            <Typography variant='subtitle1' className={classes.subheading}>
                Operating Hours - {locationName}
            </Typography>
            <Paper className={classes.paper}>
                <ListItem divider className={classes.listItem}>
                    <ListItemText secondary='Operating Hours' className={classes.label} />
                    <ListItemText
                        className={classes.detail}
                        disableTypography
                        primary={
                            <>
                                <Grid className={classes.gridButton}>
                                    <Grid className={classes.dates} container>
                                        {operatingDays.map((day, index) => (
                                            <div key={`operating-hrs-${day}`}>
                                                <Typography variant='body1'>{day}</Typography>
                                                {operatingHours && (
                                                    <Typography variant='body1' className={classes.timeField}>
                                                        {convertToDisplay(operatingHours[day.toLowerCase()])}
                                                    </Typography>
                                                )}
                                            </div>
                                        ))}
                                    </Grid>

                                    <Restricted isAllowedTo='upsertLocation'>
                                        <Button className={classes.redButton} variant='contained' onClick={() => setDialogState('operatingHours')}>
                                            Update Hours
                                        </Button>
                                    </Restricted>
                                </Grid>
                            </>
                        }
                    />
                </ListItem>
            </Paper>

            {operatingHours && (
                <OperatingHoursDialog
                    existingOperatingHours={_.omit(operatingHours, ['publicHolidays', 'closures'])}
                    onCloseDialog={handleClose}
                    showDialog={dialogState === 'operatingHours'}
                />
            )}
        </BaseSettingsPage>
    );
};

const styles = {
    paper: {
        backgroundColor: 'white !important',
    },
    subheading: {
        fontWeight: 'bold',
        paddingTop: 36,
        paddingBottom: 8,
    },
    subSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dates: {
        display: 'grid',
        width: 199,
        '& div': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    gridButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    timeField: {
        alignItems: 'center',
    },
    redButton: {
        height: 36,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user, integration }) => ({
        locationName: user.currentLocationName,
        practiceEncodedId: practice.id,
        userId: user.id,
        locationId: user.currentLocationId,
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        practiceName: practice.name,
        // userDialog: dialog.open && dialog.type === 'user',
        userName: user.name,
        userEmail: user.email,
        isConsentFormSubscription: user.isConsentFormSubscription,
        hasIntegration: integration.id !== undefined,
    }))
)(PracticeSettings);

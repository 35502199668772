import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useLazyQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import { WARM_PINK } from '../../style/constants';
import { FETCH_APPOINTMENTS, GET_APPOINTMENT_EXPORT } from './gql';
import Loading from '../../components/Loading';

const HEADERS = [
    { id: 'createdAt', label: 'Start' },
    { id: 'invoiceNumber', label: 'End' },
    { id: 'status', label: 'Appointment Type' },
    { id: 'dateDue', label: 'Clinician Name' },
    { id: 'checkedIn', label: 'Checked In' },
    { id: 'invoiced', label: 'Invoiced', hideForIntegrations: true },
];

const UpcomingAppointments = ({ classes, currentLocationId, patientEncodedId, patientName, practiceEncodedId, hasIntegration }) => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(14, 'days').format('YYYY-MM-DD'));

    const [refetchAppointments, { loading }] = useLazyQuery(FETCH_APPOINTMENTS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            patientEncodedId,
            locationId: currentLocationId,
            start: moment(startDate),
            end: moment(endDate),
        },
        onCompleted: (res) => {
            if (_.get(res, 'listAppointments.0')) {
                const sortedData = _.sortBy(res.listAppointments, 'start');
                setData(sortedData);
            }
        },
    });

    const [exportResults] = useMutation(GET_APPOINTMENT_EXPORT, {
        onCompleted: (res) => {
            if (res.requestAppointmentExport && res.requestAppointmentExport.file) {
                const b64Data = res.requestAppointmentExport.file.split(',')[1];

                const byteCharacters = atob(b64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i += 1) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const blobUrl = URL.createObjectURL(blob);

                // create <a> tag dinamically
                const fileLink = document.createElement('a');
                fileLink.href = blobUrl;

                // it forces the name of the downloaded file
                fileLink.download = `Appointments Report - ${patientName}`;

                // triggers the click event
                fileLink.click();
            }
        },
    });

    const handleExport = () => {
        const dataArray = data.map((item) => ({
            start: moment(item.start).format('LLLL'),
            end: moment(item.end).format('hh:mm A'),
            appointmentType: item.appointmentType.name,
            clinicianName: item.clinician.name,
        }));

        exportResults({
            variables: {
                data: dataArray,
                endDate,
                locationId: currentLocationId,
                patientEncodedId,
                practiceEncodedId,
                startDate,
            },
        });
    };

    const handleSearch = () => {
        refetchAppointments();
    };

    useEffect(() => {
        refetchAppointments();
    }, []);

    return (
        <>
            <div className={classes.subheading}>
                {/* <Typography className={classes.subheadingText} variant="subtitle1">
          {}
        </Typography> */}
            </div>
            <Grid
                container
                direction='column'
                justifyContent='center'
                wrap='nowrap'
                // className={form.loading ? classes.disabled : null}
            >
                <div style={{ padding: 16, display: 'flex', justifyContent: 'right' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid className={classes.container}>
                            <KeyboardDatePicker
                                id='appointment-search-start-date'
                                className={classes.field}
                                value={startDate || ''}
                                onChange={(date) => {
                                    setStartDate(moment(date).startOf('day').format());
                                }}
                                format='yyyy-MM-dd'
                            />
                            <KeyboardDatePicker
                                id='appointment-search-end-date'
                                className={classes.field}
                                value={endDate || ''}
                                onChange={(date) => {
                                    setEndDate(moment(date).endOf('day').format());
                                }}
                                format='yyyy-MM-dd'
                            />
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <Button className={classes.redButton} onClick={handleSearch}>
                                        Search
                                    </Button>
                                    <IconButton aria-label='download'>
                                        <Button onClick={handleExport}>
                                            <GetAppIcon />
                                        </Button>
                                    </IconButton>
                                </>
                            )}
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>

                <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            {HEADERS.map((column) =>
                                hasIntegration && column.hideForIntegrations === true ? null : <TableCell key={column.id}>{column.label}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow hover key={row.id}>
                                <TableCell>{moment(row.start).format('LLLL')}</TableCell>
                                <TableCell>{moment(row.end).format('hh:mm A')}</TableCell>
                                <TableCell>{row.appointmentType.name}</TableCell>
                                <TableCell>{row.clinician.name}</TableCell>
                                <TableCell>{!!row.checkedIn && 'Yes'}</TableCell>
                                {hasIntegration ? null : <TableCell>{!!row.invoiced && 'Yes'}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell className={classes.buttonContainer} colSpan={6}>
                                {/* {formatMoney(subtotal)} */}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Grid>
        </>
    );
};

const styles = {
    buttonItem: {
        justifyContent: 'space-between',
        padding: 4,
    },
    footerButtonItem: {
        justifyContent: 'flex-end',
        padding: 4,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    accountTypeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        marginLeft: 8,
    },
    detailItem: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    detailItemEditing: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    detailLabel: {
        flex: 0.5,
    },
    detailInput: {
        flex: 1,
        paddingLeft: 8,
    },
    main: {
        display: 'flex',
        justifyContent: 'center',
        height: () => window.innerWidth > 600 && '100%',
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 66,
        paddingRight: 22,
    },
    redButton: {
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
    selectField: {
        marginLeft: 8,
    },
    subheading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 8,
    },
    subheadingText: {
        fontWeight: 'bold',
    },
    submissionsPaper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 36,
        width: '100%',
        overflow: 'auto',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};

export default compose(
    connect(({ patient, practice, user, integration }) => ({
        currentLocationId: user.currentLocationId,
        patientEncodedId: patient.id,
        patientName: patient.name,
        practiceEncodedId: practice.id,
        hasIntegration: integration.id !== undefined,
    })),
    withStyles(styles)
)(UpcomingAppointments);

import React from 'react';



import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const TextSectionPreview = ({ text, title, classes }) => (
  <Grid item xs>
    <div className={classes.text}>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2">{text}</Typography>
    </div>
  </Grid>
);

const styles = {
  text: {
    height: 200,
    width: 200,
    overflow: 'hidden',
  },
};

export default withStyles(styles)(TextSectionPreview);

import { gql } from '@apollo/client';

export const FETCH_LOCATIONS = gql`
    query fetchLocation($practiceEncodedId: ID!) {
        fetchLocation(practiceEncodedId: $practiceEncodedId) {
            id
            abn
            address
            country
            email
            fax
            name
            phone
            timezone
            state
            zip
            currency {
                currency
                symbol
                formattedCurrency
            }
            integrationMetadata
        }
    }
`;

export const UPSERT_LOCATION = gql`
    mutation upsertLocation(
        $id: ID
        $name: String
        $country: String
        $practiceEncodedId: ID
        $mon: [String]
        $tue: [String]
        $wed: [String]
        $thu: [String]
        $fri: [String]
        $sat: [String]
        $sun: [String]
        $closures: String
        $publicHolidays: String
        $appointmentTimeSlot: Int
        $paymentLine1: String
        $paymentLine2: String
        $paymentLine3: String
        $address: String
        $abn: String
        $phone: String
        $fax: String
        $email: String
        $timezone: String
        $state: String
        $zip: String
    ) {
        upsertLocation(
            id: $id
            name: $name
            country: $country
            practiceEncodedId: $practiceEncodedId
            mon: $mon
            tue: $tue
            wed: $wed
            thu: $thu
            fri: $fri
            sat: $sat
            sun: $sun
            closures: $closures
            publicHolidays: $publicHolidays
            appointmentTimeSlot: $appointmentTimeSlot
            paymentLine1: $paymentLine1
            paymentLine2: $paymentLine2
            paymentLine3: $paymentLine3
            abn: $abn
            address: $address
            phone: $phone
            fax: $fax
            email: $email
            timezone: $timezone
            state: $state
            zip: $zip
        ) {
            name
        }
    }
`;

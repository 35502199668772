import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { WARM_PINK, WILD_SAND } from '../../style/constants';

const AvailabilityIcon = ({ classes, children, ...rest }) => {
  return (
    <div className={classes.availabilityIcon} {...rest}>
      {children}
    </div>
  );
};

const styles = {
  availabilityIcon: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    marginRight: 8,
    borderRadius: '4px',
    backgroundColor: props => (props.available ? WARM_PINK : WILD_SAND),
    color: props => (props.available ? 'white' : 'black'),
    lineHeight: '24px',
  },
};

export default compose(withStyles(styles))(AvailabilityIcon);

import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';
import NotesGridItem from '../NotesGridItem';

const NotesGrid = ({
  classes,
  error,
  hasNextPage,
  notes,
  onListEnd,
  status,
}) => (
  <InfiniteScroll
    className={classes.infinite}
    pageStart={0}
    hasMore={hasNextPage}
    useWindow={false}
    loader={<Loading key={1} height={100} />}
    loadMore={onListEnd}
  >
    {(() => {
      if (status.loading || status.setVariables || status.refetching)
        return <Loading height={100} />;
      if (status.success && notes.length === 0)
        return (
          <Paper>
            <EmptyData text="There are no notes available" />
          </Paper>
        );
      if (status.error)
        return (
          <Paper>
            <ErrorMessage error={error} />
          </Paper>
        );

      return (
        notes && notes.map(note => <NotesGridItem key={note.id} note={note} />)
      );
    })()}
  </InfiniteScroll>
);

const styles = {
  infinite: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(206px, 1fr))',
    gridGap: '36px',
  },
};

export default withStyles(styles)(NotesGrid);

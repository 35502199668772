import React from 'react';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

import DrawingNoteContainer from './drawingNoteContainer';
import CategoryListPopover from '../CategoryListPopover';
import DeleteNoteDialog from '../DeleteNoteDialog';
import DrawingSVG from '../DrawingSVG';
import DuplicateDialog from '../DuplicateDialog';
import EmptyData from '../EmptyData';
import EpisodePopover from '../EpisodePopover';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import MoreInfoPopover from '../MoreInfoPopover';
import SignOffDialog from '../SignOffDialog';
import ErrorAlertDialog from '../ErrorAlertDialog';
import PrintNote from '../PrintNote';

const DrawingNote = ({
    canEdit,
    classes,
    error,
    form,
    initialSave,
    isAdmin,
    isClinician,
    isReceptionist,
    onArchiveNote,
    onDuplicate,
    onSign,
    onSave,
    onTag,
    onEditNote,
    status,
    updateForm,
    isHideAction,
    isConsentFormSubscription,
}) => (
    <Grid>
        {form.error && !form.loading && (
            <ErrorAlertDialog error={form.error} onExisted={() => updateForm((prevForm) => ({ ...prevForm, error: undefined }))} />
        )}
        <Grid className={classes.titleRow}>
            <TextField
                autoComplete='off'
                id='title'
                label='Title'
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    classes: {
                        disabled: classes.disabledTextField,
                    },
                }}
                placeholder='Untitled Drawing Note'
                autoFocus
                disabled={!canEdit || initialSave || status.loading}
                className={classes.inputTitle}
                value={form.title || ''}
                onChange={(event) => {
                    let shouldSave = false;
                    if (event.target.value) shouldSave = true;
                    updateForm({ ...form, title: event.target.value });
                    if (shouldSave) onSave();
                }}
            />
            {isHideAction || (
                <Grid className={classes.buttons}>
                    {form.loading && <Typography variant='body1'>Saving ...</Typography>}

                    {((form.isAuthor && (isClinician || isAdmin)) || form.isSignedOff) && !isConsentFormSubscription && (
                        <IconButton
                            color='inherit'
                            aria-label='SignOff'
                            className={classes.iconButton}
                            disabled={form.isSignedOff || status.loading}
                            onClick={() => onEditNote()}>
                            <Edit />
                        </IconButton>
                    )}

                    {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                        <EpisodePopover
                            noteId={form.id}
                            selectedEpisodes={form.episodeIdsArray}
                            onTag={onTag}
                            loading={status.loading}
                            updateEpisodes={(episodeIdsArray) => {
                                updateForm({ ...form, episodeIdsArray });
                            }}
                        />
                    )}
                    {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                        <CategoryListPopover
                            noteId={form.id}
                            selectedCategories={form.categoryIdsArray}
                            onTag={onTag}
                            loading={status.loading}
                            updateCategories={(categoryIdsArray) =>
                                updateForm({
                                    ...form,
                                    categoryIdsArray: categoryIdsArray || [],
                                })
                            }
                        />
                    )}
                    {((form.isAuthor && (isClinician || isAdmin)) || form.isSignedOff) && (
                        <SignOffDialog
                            noteId={form.id}
                            isSignedOff={form.isSignedOff}
                            signedOffTime={form.signedOffTime}
                            onSign={onSign}
                            loading={status.loading}
                            authorName={form.authorName}
                        />
                    )}
                    {(isClinician || isAdmin) && <DuplicateDialog noteId={form.id} loading={status.loading} onDuplicate={onDuplicate} />}
                    <MoreInfoPopover
                        authorName={form.author && form.author.name}
                        createdAt={form.createdAt}
                        episodes={form.episodes}
                        loading={status.loading}
                        noteId={form.id}
                        patientName={form.patientName}
                        signedOffTime={form.signedOffTime}
                    />
                    {form.isAuthor && (isClinician || isAdmin) && (
                        <DeleteNoteDialog
                            isSignedOff={form.isSignedOff}
                            loading={status.loading}
                            noteId={form.id}
                            onArchiveNote={() => onArchiveNote()}
                        />
                    )}

                    {form.isAuthor && (isClinician || isAdmin) && (
                        <PrintNote form={form} typename='DrawingNote' loading={status.loading} noteId={form.id} />
                    )}
                </Grid>
            )}
        </Grid>
        <Paper className={classes.paper}>
            {(() => {
                if (status.loading) return <Loading />;
                if (status.error) return <ErrorMessage text={error.message} />;

                return (
                    <Grid container direction='column'>
                        {(() => {
                            if (form.drawingData || form.template.file.encodedFile)
                                return (
                                    <DrawingSVG
                                        className={classes.drawing}
                                        drawingData={form.drawingData}
                                        templateData={form.template.file.encodedFile}
                                        sectionId={form.id}
                                    />
                                );

                            return (
                                <Grid className={classes.emptyData}>
                                    <EmptyData text='No Drawing Input' />
                                </Grid>
                            );
                        })()}
                    </Grid>
                );
            })()}
        </Paper>
    </Grid>
);

const styles = {
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 18,
    },
    paper: {
        position: 'relative',
        marginBottom: 36,
        padding: 20,
    },
    disabled: {
        opacity: 0.5,
    },
    disabledTextField: {
        color: 'black',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 16,
    },
    inputTitle: {
        flexBasis: '65%',
        fontWeight: 'bold',
    },
    drawing: {
        height: '100%',
        width: '100%',
    },
    emptyData: {
        border: [1, 'solid', 'lightgray'],
        height: '100%',
        width: '100%',
    },
};

export default compose(DrawingNoteContainer, withStyles(styles))(DrawingNote);

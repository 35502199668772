import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IOSSwitch from '../IOSSwitch';

const YesNoSectionPreview = ({ yesNoValue, title, classes = {} }) => {
  return (
    <Grid item xs>
      <div className={classes.text}>
        <Typography variant="body1">{title}</Typography>
        <IOSSwitch
          disabled
          checked={!!yesNoValue}
          onChange={() => {}}
        ></IOSSwitch>
      </div>
    </Grid>
  );
};

export default YesNoSectionPreview;

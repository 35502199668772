import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

const GooglePlacesAutocomplete = ({ classes, form, setForm, handleFieldChange, disabled, options }) => (
    <>
        {(() => {
            const [options, setOptions] = useState([]);

            useEffect(() => {
                const service = new window.google.maps.places.AutocompleteService();
                if (form.address) {
                    const pred = service.getPlacePredictions({
                        input: form.address,
                        types: ['address'],
                    });
                    if (typeof pred === 'undefined') {
                        return;
                    }
                    pred.then((predictions) => {
                        setOptions(predictions.predictions || []);
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    setOptions([]);
                }
            }, [form.address]);

            return (
                <Autocomplete
                    freeSolo
                    disabled={disabled}
                    options={options.map((option) => option.description)}
                    onChange={(event, value) => {
                        const data = options.find((option) => option.description === value);
                        // search for the placeID and get country name
                        const map = new window.google.maps.Map(document.createElement('div'));
                        if (data) {
                            new window.google.maps.places.PlacesService(map).getDetails({ placeId: data.place_id }, (place, status) => {
                                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                    const country = place.address_components.find((component) => component.types[0] === 'country');
                                    const state = place.address_components.find((component) =>
                                        component.types.includes('administrative_area_level_1')
                                    );
                                    const zip = place.address_components.find((component) => component.types.includes('postal_code'));

                                    setForm({
                                        ...form,
                                        address: place.formatted_address,
                                        country: country.long_name,
                                        zip: zip ? zip.long_name : '',
                                        state: state ? state.long_name : '',
                                    });
                                }
                            });
                        }
                        // setForm({ ...form, address: value });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={form.address || 'Address (including Postcode/Zipcode)'}
                            disabled={disabled}
                            name='address'
                            type='text'
                            fullWidth
                            margin='normal'
                            required
                            onChange={handleFieldChange}
                            className={classes.field}
                        />
                    )}
                />
            );
        })()}
    </>
);

export default GooglePlacesAutocomplete;

import React from 'react';

import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import includes from 'lodash/includes';
import some from 'lodash/some';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, SECONDARY } from '../../style/constants';
import CategoryListContainer from './categoryListContainer';
import ConfirmActionDialog from '../ConfirmActionDialog';
import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';

const CategoryList = ({
    classes,
    categories,
    error,
    hasNextPage,
    onArchiveCategory,
    onCreateCategory,
    onListEnd,
    onRefetch,
    onSelectCategory,
    searchText,
    status,
    tempCategories,
    dialog,
    updateDialog,
}) => (
    <InfiniteScroll
        className={classes.infinite}
        pageStart={0}
        hasMore={hasNextPage}
        useWindow={false}
        loader={<Loading key={1} height={100} />}
        loadMore={onListEnd}>
        {(() => {
            if (status.loading || status.setVariables || status.refetching) return <Loading height='100%' />;
            if (status.error) return <ErrorMessage containerStyle={styles.errorWrapper} text={error.message} />;
            if (status.success && categories.length === 0 && !searchText) return <EmptyData text='Please create a new category' />;

            return (
                <List disablePadding>
                    {categories &&
                        categories.map((category) => (
                            <Grid className={classes.category} key={category.id}>
                                <ListItem
                                    button
                                    divider
                                    onClick={() => onSelectCategory(category.id)}
                                    TouchRippleProps={{ style: { color: PRIMARY } }}>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography variant='body1'>
                                                <strong>{category.name}</strong>
                                            </Typography>
                                        }
                                    />
                                    {includes(tempCategories, category.id) && <Check className={classes.checkIcon} />}
                                </ListItem>
                                {!includes(tempCategories, category.id) && (
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateDialog({
                                                open: true,
                                                itemToDelete: category.id,
                                            });
                                        }}
                                        aria-label='Clear'
                                        color='inherit'
                                        className={classes.deleteButton}>
                                        <Clear />
                                    </IconButton>
                                )}
                            </Grid>
                        ))}
                    {searchText && !some(categories, ['name', searchText]) && (
                        <ListItem
                            button
                            divider
                            className={classes.category}
                            onClick={async () => {
                                await onCreateCategory();
                                onRefetch();
                            }}>
                            <Typography variant='body2' className={classes.createCategory}>
                                Create category:
                            </Typography>
                            <Typography variant='body1'>
                                <strong> {searchText}</strong>
                            </Typography>
                        </ListItem>
                    )}
                </List>
            );
        })()}
        <ConfirmActionDialog
            open={dialog.open}
            text='Any notes tagged with this category will lose this tag'
            onClose={() => updateDialog({ open: false, itemToDelete: undefined })}
            onConfirm={async () => {
                await onArchiveCategory(dialog.itemToDelete);
                onRefetch();
            }}
            confirmText='Delete'
        />
    </InfiniteScroll>
);

const styles = {
    infinite: {
        height: '100%',
        overflow: 'auto',
    },
    iconButton: {
        marginLeft: 4,
    },
    deleteButton: {
        position: 'absolute',
        right: 12,
    },
    createCategory: {
        paddingRight: 4,
    },
    category: {
        display: 'flex',
        minHeight: 36,
        alignItems: 'center',
    },
    checkIcon: {
        color: SECONDARY,
    },
};

export default compose(withStyles(styles), CategoryListContainer)(CategoryList);

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ErrorMessage from '../../../components/ErrorMessage';
import { WARM_PINK } from '../../../style/constants';
import { REQUEST_EMAIL_LIST } from '../gql';
import CsvDownload from '../../../components/CsvDownload';

const Emails = ({ classes, practiceEncodedId }) => {
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState('');

  const [requestEmails, { error }] = useLazyQuery(REQUEST_EMAIL_LIST, {
    variables: {
      practiceEncodedId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      setCsvData([]);
      setCsvName('');
      const data = _.get(res, 'node.patients.nodes', []);
      if (data.length) {
        setCsvData(data);
        setCsvName(`Emails Report - ${moment().format('YYYY-MM-DD-HH:mm:ss')}`);
      }
      return null;
    },
  });

  const handleRequestEmails = () => {
    requestEmails();
  };

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Grid item xs={3}></Grid>
      <Grid item xs={9}>
        <Button
          className={classes.warmPinkButton}
          onClick={handleRequestEmails}
        >
          Generate Report
        </Button>
      </Grid>
      {csvData.length > 0 && csvName && (
        <CsvDownload data={csvData} filename={csvName} separator="," />
      )}
    </>
  );
};

const styles = {
  warmPinkButton: {
    padding: 10,
    height: 40,
    background: WARM_PINK,
    color: 'white',
    marginTop: 12,
  },
};

export default compose(
  withStyles(styles),
  connect(({ user, practice }) => ({
    practiceEncodedId: practice.id,
  }))
)(Emails);

import { gql } from '@apollo/client';

export const FETCH_LOCATIONS = gql`
    query fetchLocation($practiceEncodedId: ID!) {
        fetchLocation(practiceEncodedId: $practiceEncodedId) {
            id
            name
        }
    }
`;

export const UPDATE_SELECTED_LOCATION = gql`
    mutation updateSelectedLocation($practiceEncodedId: ID!, $locationId: ID!, $userEncodedId: ID!) {
        updateSelectedLocation(practiceEncodedId: $practiceEncodedId, locationId: $locationId, userEncodedId: $userEncodedId) {
            locationId
        }
    }
`;

export const WHATS_NEW_QUERY = gql`
    query whatsNewQuery {
        whatsNewQuery {
            whatsNew {
                releaseDate
                content
            }
            hasUnread
        }
    }
`;

export const USER_QUERY = gql`
    query userQuery {
        viewer {
            name
            id
            practice {
                name
                id
            }
            roles
            isSubscriptionValid
            isConsentFormSubscription
            location {
                id
                abn
                currency {
                    currency
                    symbol
                    formattedCurrency
                }
            }
            locations {
                id
                name
                integration {
                    id
                }
            }
        }
    }
`;

import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useMutation } from '@apollo/client';
import client from '../../data/apollo/client';
import StatusBar from './StatusBar';
import AppointmentDetails from './AppointmentDetails';
import DateSelectScreen from './DateSelectScreen';
import PatientDetails from './PatientDetails';
import ConfirmationScreen from './ConfirmationScreen';
import {
  COMPILE_VACANCIES,
  FETCH_CLINICIAN,
  UPSERT_APPT_GUEST,
} from './gqlQueries';
import { parseTimezone, processTimes } from './constants';
import { LIST_APPOINTMENT_TYPES } from '../SettingsPage/gql';
import BookingSummary from './BookingSummary';
import { fetchAndStorePublicUser } from './LoginModal';
import PracticeLocations from './PracticeLocations';

const fetchBookingFormData = async (
  locationId,
  setClinicianOptions,
  setAppointmentTypes,
  setPracticeEncodedId
) => {
  const [resClinicians, resTypes] = await Promise.all([
    client.query({
      query: FETCH_CLINICIAN,
      variables: {
        locationId,
      },
      fetchPolicy: 'no-cache',
    }),
    client.query({
      query: LIST_APPOINTMENT_TYPES,
      variables: {
        locationId,
      },
      fetchPolicy: 'no-cache',
    }),
  ]).catch((e) => {
    window.location.href = '/booking-error';
  });

  const clinicianData = _.get(resClinicians, ['data', 'fetchClinician']);
  const apptTypesData = _.get(resTypes, ['data', 'listAppointmentTypes']);

  if (clinicianData) {
    setClinicianOptions(clinicianData);
    setPracticeEncodedId(clinicianData[0].practiceEncodedId);
  }
  if (apptTypesData) {
    setAppointmentTypes(apptTypesData);
  }
};

const fetchAllVacancies = async (variablesArray, setAvailTimes, setStatus) => {
  const promisesArray = variablesArray.map(variables =>
    client.query({
      query: COMPILE_VACANCIES,
      variables,
      fetchPolicy: 'no-cache',
    })
  );
  const vacancyResults = await Promise.all(promisesArray);

  let vacancies = [];
  vacancyResults.forEach(resultsBatch => {
    const vacancyBatch = _.get(resultsBatch, ['data', 'compileVacancies']);
    vacancies = [...vacancies, ...vacancyBatch];
  });

  setAvailTimes(processTimes(vacancies));
  setStatus();
};

const BookingFormPage = ({
  classes,
  // match: {
  //   params: { locationId },
  // },
  user,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [locationId, setLocationId] = useState(null); // [locationId, setLocationId
  const [booking, setBooking] = useState({
    myself: true,
    start: moment().utc(),
    newPatient: true,
    // newPatient: false,
    // patient: {
    //   patientName: 'Charles Testing',
    //   firstName: 'Charles',
    //   lastName: 'Testing1',
    //   email: 'charles.zhang+6@sentia.com.au',
    //   phone: '0400226711',
    //   dob: '2021-11-16',
    // },
    // type: {
    //   id: '695aa272-a01b-4747-a9ec-2e2ef75b03b3',
    //   code: '102',
    //   desc: 'General Consultation',
    //   name: 'GC',
    //   color: '#a3f4ff',
    //   price: 120,
    //   duration: 90,
    //   __typename: 'AppointmentType',
    // },
    // time: {
    //   desc: '3:30PM',
    //   code: '2022-03-27T04:30:00.000Z',
    //   slotDetails: {
    //     start: '2022-03-27T04:30:00.000Z',
    //     end: '2022-03-27T05:30:00.000Z',
    //     clinicianEncodedId: {
    //       id: 'VXNlcjo5OTgyNmFjMS1iOGMyLTQzYzUtOWRiOS1mMmY1ZjI5MzI0YmE=',
    //       name: 'Michael Statton',
    //       __typename: 'Clinician',
    //     },
    //     __typename: 'VacancyPayload',
    //   },
    // },
  });
  const [clinicianEncodedId, setClinicianEncodedId] = useState('');
  const [practiceEncodedId, setPracticeEncodedId] = useState('');
  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [availTimes, setAvailTimes] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [status, setStatus] = useState();
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const timezoneOffset = parseTimezone();

  const [upsertAppointment] = useMutation(UPSERT_APPT_GUEST);

  useEffect(() => {
    if (locationId) {
      fetchBookingFormData(
        locationId,
        setClinicianOptions,
        setAppointmentTypes,
        setPracticeEncodedId
      );

      if (user.email) {
        fetchAndStorePublicUser(
          locationId,
          booking,
          setBooking,
          setShowLoginDialog,
          user
        );
      }
    }
  }, [locationId]);

  // Clear errors/inflight after Booking Changes
  useEffect(() => {
    setStatus();
  }, [booking]);

  useEffect(() => {
    if (clinicianOptions.length && booking.type && booking.start) {
      let variablesArray = [];
      if (clinicianEncodedId === '') {
        variablesArray = clinicianOptions.map(clinician => ({
          clinicianEncodedId: clinician.id,
          start: booking.start,
          duration: booking.type.duration || 30,
          locationId,
          offset: timezoneOffset,
        }));
      } else {
        variablesArray = [
          {
            clinicianEncodedId,
            start: booking.start,
            duration: booking.type.duration || 30,
            locationId,
            offset: timezoneOffset,
          },
        ];
      }
      setStatus('inflight');

      if (variablesArray.length) {
        fetchAllVacancies(variablesArray, setAvailTimes, setStatus);
      }
    }
  }, [clinicianEncodedId, booking.start, clinicianOptions, booking.type]);

  const createAppointmentHandler = async payload => {
    setStatus('inflight');
    upsertAppointment({
      variables: { ...payload, locationId, practiceEncodedId },
    })
      .then(res => {
        const patient = _.get(res, ['appointment', 'patients', '0'], {});
        setBooking({
          ...booking,
          // appointmentType: booking.type.id,
          clinicianEncodedId,
          locationId,
          patientId: patient.id,
          patientName: patient.name,
        });
        setActiveTab(5);
      })
      .catch(e => {
        setStatus(e);
      });
  };

  let activeFormElement = null;
  switch (activeTab) {

    case 0:
      activeFormElement = (
        <PracticeLocations
          setActiveTab={setActiveTab}
          setLocationId={setLocationId}
        />
      );
      break;

    case 1:
      activeFormElement = (
        <AppointmentDetails
          booking={booking}
          setBooking={setBooking}
          setActiveTab={setActiveTab}
          appointmentTypes={appointmentTypes}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      );
      break;

    case 2:
      activeFormElement = (
        <DateSelectScreen
          booking={booking}
          setBooking={setBooking}
          setActiveTab={setActiveTab}
          clinicianEncodedId={clinicianEncodedId}
          setClinicianEncodedId={setClinicianEncodedId}
          clinicianOptions={clinicianOptions}
          availTimes={availTimes}
          setAvailTimes={setAvailTimes}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
          status={status}
        />
      );
      break;
    case 3:
      activeFormElement = (
        <PatientDetails
          booking={booking}
          setBooking={setBooking}
          setActiveTab={setActiveTab}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      );
      break;
    case 4:
      activeFormElement = (
        <ConfirmationScreen
          booking={booking}
          status={status}
          setBooking={setBooking}
          setActiveTab={setActiveTab}
          createAppointmentHandler={createAppointmentHandler}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      );
      break;
    case 5:
      activeFormElement = (
        <BookingSummary
          locationId={locationId}
          booking={booking}
          setBooking={setBooking}
          user={user}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      );
      break;
    default:
      activeFormElement = (
        <PracticeLocations
          setActiveTab={setActiveTab}
          setLocationId={setLocationId}
        />
      );

      break;
  }

  // eslint-disable-next-line no-console
  console.log('booking details >>>', booking);

  return (
    <Grid
      component="main"
      className={classes.main}
      container
      justifyContent="center"
    >
      <Grid
        className={classes.content}
        container
        direction="column"
        wrap="nowrap"
        item
        xs={12}
        sm={11}
        md={10}
        lg={8}
        xl={6}
      >
        {activeTab !== 4 && <StatusBar activeTab={activeTab} />}

        {activeFormElement}
      </Grid>
    </Grid>
  );
};

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: () => window.innerWidth > 600 && '100%',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
  },
  content: {
    maxWidth: 650,
  },
};

export default compose(
  withStyles(styles),
  connect(({ user }) => ({
    user,
  }))
)(BookingFormPage);

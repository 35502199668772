import { gql } from '@apollo/client';

export const PATIENT_NODE_QUERY = gql`
    query patientNodeQuery($patientId: ID!) {
        node(id: $patientId) {
            __typename
            id
            ... on Patient {
                name
                firstName
                middleName
                lastName
                address
                email
                phone
                mobile
                dob
                integrationMetadata
                alerts {
                    id
                    type
                    text
                    createdAt
                }
            }
        }
    }
`;

export const LIST_INVOICE_ITEMS = gql`
    query fetchInvoiceItem($encodedPatientId: ID, $locationId: ID) {
        fetchInvoiceItem(encodedPatientId: $encodedPatientId, locationId: $locationId) {
            id
            clinicianName
            code
            comments
            invoice {
                id
            }
            locationId
            price
            quantity
            date
            createdAt
            appointmentId
        }
    }
`;

export const LIST_INVOICE_ACCOUNTS = gql`
    query fetchInvoiceAccount($patient: ID) {
        fetchInvoiceAccount(patient: $patient) {
            id
            status
            billingName
            billingAddress
            billingPhone
            billingEmail
            billingInsuranceCompany
            billingClaimNumber
            billingReferrer
            billingDateInjury
        }
    }
`;

export const UPSERT_INVOICE_ACCOUNT = gql`
    mutation upsertInvoiceAccount(
        $id: ID
        $patient: ID
        $status: InvoiceAccountState
        $practice: ID!
        $billingName: String
        $billingAddress: String
        $billingPhone: String
        $billingEmail: String
        $billingInsuranceCompany: String
        $billingClaimNumber: String
        $billingReferrer: String
        $billingDateInjury: String
    ) {
        upsertInvoiceAccount(
            id: $id
            patient: $patient
            status: $status
            practice: $practice
            billingName: $billingName
            billingAddress: $billingAddress
            billingPhone: $billingPhone
            billingEmail: $billingEmail
            billingInsuranceCompany: $billingInsuranceCompany
            billingClaimNumber: $billingClaimNumber
            billingReferrer: $billingReferrer
            billingDateInjury: $billingDateInjury
        ) {
            id
            status
            billingName
            billingAddress
            billingPhone
            billingEmail
            billingInsuranceCompany
            billingClaimNumber
            billingReferrer
            billingDateInjury
        }
    }
`;

export const UPDATE_PRIMARY_ACCOUNT = gql`
    mutation updatePrimaryAccount($id: ID!, $patientEncodedId: ID!, $practiceEncodedId: ID!) {
        updatePrimaryAccount(id: $id, patientEncodedId: $patientEncodedId, practiceEncodedId: $practiceEncodedId) {
            success
        }
    }
`;

export const CREATE_PATIENT_ALERT = gql`
    mutation createPatientAlert($encodedPatientId: ID!) {
        createPatientAlert(encodedPatientId: $encodedPatientId) {
            id
            type
            text
            createdAt
        }
    }
`;

export const GET_PATIENT_ALERTS = gql`
    query fetchPatientAlerts($encodedPatientId: ID!) {
        fetchPatientAlerts(encodedPatientId: $encodedPatientId) {
            id
            type
            text
            createdAt
        }
    }
`;

export const DELETE_PATIENT_ALERT = gql`
    mutation deletePatientAlert($alertId: ID!) {
        deletePatientAlert(alertId: $alertId) {
            id
            deletedAt
            deletedBy
        }
    }
`;

export const UPSERT_PATIENT_ALERT = gql`
    mutation upsertPatientAlert($alertId: ID!, $text: String!) {
        upsertPatientAlert(alertId: $alertId, text: $text) {
            id
            type
            text
            createdAt
        }
    }
`;

import React from 'react';
import { compose } from 'recompose';

import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import get from 'lodash/get';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { withStyles } from '@material-ui/core/styles';
import { MINOR_BLACK, WHITE } from '../../style/constants';

import TemplateListContainer from './templateListContainer';

import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';
import GroupTemplates from '../GroupTemplates';
import TemplateImage from '../TemplateImage/templateImage';

const TemplateList = ({
    classes,
    error,
    fileLoading,
    onSelectTemplate,
    status,
    templates,
    updateAlertDialog,
    colSize,
    getFileEncodedFromFileId,
    templateFiles,
    updateTemplateFiles,
}) => (
    <div className={classes.wrapper}>
        {fileLoading && <Loading overlay />}
        {(() => {
            if (status.loading) return <Loading />;
            if (status.error) return <ErrorMessage error={error} />;
            if (!templates || templates.length === 0) return <EmptyData text='This practice currently has no image templates available' />;
            return (
                <div className={fileLoading ? classes.disabled : null}>
                    <GridList cellHeight={216} cols={colSize} spacing={12} className={classes.gridList}>
                        {templates &&
                            templates.map((template) =>
                                (() => {
                                    if (template.pageNumber > 1) return null;
                                    if (template.pageNumber === 1 && get(template, 'group.id'))
                                        return (
                                            <GroupTemplates
                                                style={{ ...template.style }} // Passing the style provided by GridList to component.
                                                key={template.id}
                                                template={template}
                                                onSelectTemplate={onSelectTemplate}
                                                onClose={() => {}}
                                                updateAlertDialog={updateAlertDialog}
                                                hideDeleteIcon={true}
                                                groupedTemplates={orderBy(
                                                    filter(templates, (item) => {
                                                        if (item.group) {
                                                            return item.group.id === template.group.id;
                                                        }
                                                        return null;
                                                    }),
                                                    ['pageNumber'],
                                                    ['asc']
                                                )}
                                                onImageLoaded={(imageData) => {
                                                    if (templateFiles.filter((file) => file.id === imageData.id).length === 0)
                                                        updateTemplateFiles([...templateFiles, imageData]);
                                                }}
                                                getFileEncodedFromFileId={getFileEncodedFromFileId}
                                            />
                                        );
                                    return (
                                        <GridListTile
                                            key={template.id}
                                            className={classes.listItem}
                                            onClick={() => {
                                                onSelectTemplate(template.id, template.file.encodedFile);
                                            }}>
                                            {(() => {
                                                if (template.id === 'blankId') return <div className={classes.blankTemplate} />;
                                                return (
                                                    <TemplateImage
                                                        fileId={template.file.id}
                                                        onImageLoaded={(imageData) => {
                                                            if (templateFiles.filter((file) => file.id === imageData.id).length === 0)
                                                                updateTemplateFiles([...templateFiles, imageData]);
                                                        }}
                                                    />
                                                );
                                            })()}
                                            <GridListTileBar title={template.name} />
                                        </GridListTile>
                                    );
                                })()
                            )}
                    </GridList>
                </div>
            );
        })()}
    </div>
);

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 280,
        minWidth: 280,
        padding: 32,
    },
    disabled: {
        opacity: 0.5,
    },
    topInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 32,
        paddingBottom: 12,
        borderBottom: `1px ${MINOR_BLACK} solid`,
    },
    topInfoText: {
        paddingRight: 12,
    },
    heading: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    warning: {
        fontSize: 12,
        marginTop: 6,
    },
    input: {
        display: 'none',
    },
    icon: {
        color: WHITE,
        opacity: 0,
        transition: '0.3s',
    },
    gridList: {
        height: 450,
        minWidth: 265,
    },
    listItem: {
        cursor: 'pointer',
        transitionDuration: '0.3s',
        '&:hover': {
            transform: 'translateY(-4px)',
        },
        '&:hover $icon': {
            opacity: 1,
        },
    },
    blankTemplate: {
        height: '100%',
        width: '100%',
        border: `1px ${MINOR_BLACK} solid`,
    },
};

export default compose(withStyles(styles), TemplateListContainer)(TemplateList);

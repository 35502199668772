import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WARM_PINK } from '../../../style/constants';

const Cashflow = ({
  classes,
  clinicianOptions,
  start,
  end,
  setClinician,
  setStart,
  setEnd,
}) => {
  const [startTemp, setStartTemp] = useState(start);
  const [endTemp, setEndTemp] = useState(end);
  const [clinicianTemp, setClinicianTemp] = useState('All Clinicians');

  const onGenerate = () => {
    setStart(startTemp);
    setEnd(endTemp);
    setClinician(clinicianTemp);
  };

  const onSelectClinician = e => {
    setClinicianTemp(e.target.value);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item xs={3}>
          <Typography className={classes.descriptor}>From Date</Typography>
        </Grid>
        <Grid item xs={9}>
          <KeyboardDatePicker
            className={classes.field}
            value={startTemp || ''}
            onChange={date => {
              setStartTemp(
                moment(date)
                  .startOf('day')
                  .format()
              );
            }}
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.descriptor}>To Date</Typography>
        </Grid>
        <Grid item xs={9}>
          <KeyboardDatePicker
            className={classes.field}
            value={endTemp || ''}
            onChange={date => {
              setEndTemp(
                moment(date)
                  .endOf('day')
                  .format()
              );
            }}
            maxDate={moment()
              .endOf('month')
              .format()}
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.descriptor}>
            Select Clinician
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Select
            className={classes.field}
            value={clinicianTemp}
            fullWidth
            onChange={onSelectClinician}
          >
            <MenuItem value="All Clinicians">All</MenuItem>
            {clinicianOptions.map(clinician => (
              <MenuItem
                value={clinician}
                key={`menuitem-clinician-b-${clinician}`}
              >
                {clinician}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <Button className={classes.warmPinkButton} onClick={onGenerate}>
            Generate Report
          </Button>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  descriptor: {
    marginTop: 6,
  },
  field: {
    marginBottom: 20,
    width: '100%',
  },
  warmPinkButton: {
    padding: 10,
    maxWidth: 172,
    height: 40,
    background: WARM_PINK,
    color: 'white',
  },
};

export default compose(
  connect(({ practice }) => ({
    practiceEncodedId: practice.id,
  })),
  withStyles(styles)
)(Cashflow);

import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import GeneratePDFButton from '../GeneratePDFButton';

import EpisodeContainer from './episodeContainer';

const Episode = ({
    classes,
    endDate,
    error,
    form,
    name,
    onCancelEndDate,
    onChangeName,
    onEdit,
    onNewEndDate,
    onSave,
    onSelectEndDate,
    onSelectStartDate,
    startDate,
    state,
}) => (
    <Paper className={classes.paper}>
        {state && state.loading && <Loading linear />}
        {(() => {
            if (state.loading || state.setVariables || state.refetching) return <Loading />;
            if (state.error) return <ErrorMessage error={error} />;

            return (
                <Grid container direction='column' justifyContent='center' wrap='nowrap' className={state.loading ? classes.disabled : null}>
                    <List disablePadding>
                        <ListItem className={classes.buttonItem} disableGutters>
                            <Button
                                disabled={state.loading || !form.name || !form.startDate || Moment(form.endDate).isBefore(form.startDate, 'day')}
                                onClick={
                                    !state.editing ||
                                    (name === form.name &&
                                        startDate === Moment(form.startDate).format('YYYY-MM-DD') &&
                                        endDate === Moment(form.endDate).format('YYYY-MM-DD')) ||
                                    (name === form.name && startDate === Moment(form.startDate).format('YYYY-MM-DD') && !form.endDate && !endDate)
                                        ? onEdit
                                        : onSave
                                }>
                                {state.editing ? 'Done' : 'Edit'}
                            </Button>

                            <GeneratePDFButton />
                        </ListItem>
                        <ListItem className={!state.editing ? classes.detailItem : classes.detailItemEditing} divider>
                            <ListItemText className={classes.detailLabel} secondary='Name' />
                            {state.editing ? (
                                <TextField
                                    id='name'
                                    autoComplete='off'
                                    autoFocus
                                    className={classes.detailInputName}
                                    value={form.name}
                                    onChange={(event) => onChangeName(event.target.value)}
                                />
                            ) : (
                                <ListItemText
                                    disableTypography
                                    className={classes.detail}
                                    primary={
                                        <Typography variant='subtitle1' className={classes.detailName}>
                                            {name}
                                        </Typography>
                                    }
                                />
                            )}
                        </ListItem>
                        <ListItem className={!state.editing ? classes.detailItem : classes.detailItemEditing} divider>
                            <ListItemText className={classes.detailLabel} secondary='Start Date' />
                            {state.editing ? (
                                <div className={classes.detailInputDate}>
                                    <TextField
                                        id='startDate'
                                        type='date'
                                        autoComplete='off'
                                        className={classes.detailInputDate}
                                        value={Moment(form.startDate).format('YYYY-MM-DD')}
                                        onChange={(event) => onSelectStartDate(event.target.value)}
                                    />
                                </div>
                            ) : (
                                <ListItemText className={classes.detail} primary={Moment(startDate).format('DD MMMM, YYYY')} />
                            )}
                        </ListItem>
                        <ListItem className={!state.editing ? classes.detailItem : classes.detailItemEditing} divider>
                            <ListItemText className={classes.detailLabel} secondary='End Date' />
                            {(() => {
                                if (state.editing && form.endDate) {
                                    return (
                                        <div className={classes.detailInputDate}>
                                            <TextField
                                                id='endDate'
                                                autoComplete='off'
                                                type='date'
                                                className={classes.detailInputDate}
                                                value={Moment(form.endDate).format('YYYY-MM-DD')}
                                                onChange={(event) => onSelectEndDate(event.target.value)}
                                            />
                                            {/* <Button onClick={onCancelEndDate}>
                        <FontAwesomeIcon
                          className={classes.iconClose}
                          icon={faTimesCircle}
                          style={{ marginRight: '10px', display: 'contents' }}
                        />
                      </Button> */}
                                        </div>
                                    );
                                }
                                if (state.editing && !form.endDate) {
                                    return (
                                        <Button className={classes.endDateButton} onClick={onNewEndDate}>
                                            Select End Date
                                        </Button>
                                    );
                                }

                                return (
                                    <ListItemText
                                        className={classes.detail}
                                        primary={endDate ? Moment(endDate).format('DD MMMM, YYYY') : 'In Progress'}
                                    />
                                );
                            })()}
                        </ListItem>
                        {state.error && (
                            <ListItem>
                                <ErrorMessage error={state.error} />
                            </ListItem>
                        )}
                    </List>
                </Grid>
            );
        })()}
    </Paper>
);

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 191,
        marginBottom: 36,
        position: 'relative',
    },
    disabled: {
        opacity: 0.5,
    },
    buttonItem: {
        justifyContent: 'flex-end',
        padding: 4,
    },
    detailItem: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    detailItemEditing: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    detailLabel: {
        flex: 0.5,
    },
    detail: {
        flex: 1,
    },
    detailInput: {
        flex: 1,
        paddingLeft: 8,
    },
    detailInputDate: {
        flex: 1,
        height: 32,
        paddingLeft: 8,
        '& > div': {
            display: 'flex',
            zIndex: 9999,
        },
        '& > div:first-child > div:first-child': {
            flex: 1,
        },
    },
    detailInputName: {
        flex: 1,
        paddingLeft: 8,
        fontWeight: 'bold',
    },
    detailName: {
        fontWeight: 'bold',
    },
    datePicker: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
    },
    endDateButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    iconClose: {
        cursor: 'pointer',
        position: 'absolute',
        top: 12,
        right: 32,
        fontSize: '1rem',
    },
};

export default compose(withStyles(styles), EpisodeContainer)(Episode);

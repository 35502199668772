import React, { useEffect, useState } from 'react';
import { branch, compose, withProps } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorMessage from '../ErrorMessage';

const fileDetailQuery = gql`
    query fileDetails($fileId: ID!) {
        node(id: $fileId) {
            __typename
            id
            ... on File {
                id
                encodedFile
            }
        }
    }
`;

const TemplateImage = ({ status, error, height, file }) => {
    const [fileData, setFileData] = useState(null);
    useEffect(() => {
        if (file) {
            setFileData(file.encodedFile);
        }
    }, [file]);
    if (status.loading || fileData === null) {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <CircularProgress color='secondary' />
            </div>
        );
    }

    if (status.error) return <ErrorMessage error={error} />;

    return <img style={{ width: '100%', height: height || '100%' }} src={`data:image/png;base64,${fileData}`} alt='template' />;
};

export default compose(
    graphql(fileDetailQuery, {
        name: 'query',
        options: ({ fileId }) => ({
            variables: { fileId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withProps(({ query }) => ({
        error: query.error,
        status: {
            loading: query.networkStatus === 1,
            success: query.networkStatus === 7 && Boolean(query.node),
            error: query.networkStatus === 8,
        },
    })),
    branch(
        ({ status }) => status.success,
        withProps(({ query, onImageLoaded }) => {
            //onImageLoaded(query.node);
            return {
                file: query.node,
            };
        })
    )
)(TemplateImage);

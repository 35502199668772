import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { gql } from '@apollo/client';

import range from 'lodash/range';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import maxBy from 'lodash/maxBy';
import lodashMap from 'lodash/map';
import get from 'lodash/get';
import last from 'lodash/last';

import { BLANK_TEMPLATE_ID } from '../DrawingTemplateList/drawingTemplateListContainer';

import { sectionCleared, sectionSelect } from '../../data/redux/actions/section';
import { formTemplateCleared, formTemplateUpsert } from '../../data/redux/actions/formTemplate';

const formTemplateQuery = gql`
    query formTemplate($formTemplateId: ID!) {
        node(id: $formTemplateId) {
            __typename
            id
            ... on FormTemplate {
                name
                rows
                sections {
                    id
                    type
                    title
                    rowIndexes
                    columnIndexes
                    ... on DrawingSection {
                        pageNumber
                        group {
                            id
                        }
                        template {
                            id
                            file {
                                id
                                encodedFile
                            }
                            group {
                                id
                            }
                            pageNumber
                        }
                    }
                    ... on CheckboxesSection {
                        checkboxesData
                    }
                    ... on SpreadsheetSection {
                        spreadsheetData
                    }
                }
            }
        }
    }
`;

const upsertFormTemplateMutation = gql`
    mutation upsertFormTemplate(
        $formTemplateId: ID
        $allowUpdate: Boolean
        $practiceId: ID!
        $name: String
        $rows: Int
        $deletedAt: DateTime
        $sections: [FormTemplateSectionInput]!
        $sharableTemplate: Boolean
        $templateCategory: [ID]
    ) {
        upsertFormTemplate(
            id: $formTemplateId
            allowUpdate: $allowUpdate
            practiceId: $practiceId
            name: $name
            sections: $sections
            rows: $rows
            deletedAt: $deletedAt
            sharableTemplate: $sharableTemplate
            templateCategory: $templateCategory
        ) {
            formTemplate {
                id
                name
                rows
                sections {
                    id
                    type
                    title
                    rowIndexes
                    columnIndexes
                    ... on DrawingSection {
                        pageNumber
                        group {
                            id
                        }
                        template {
                            id
                            file {
                                id
                                encodedFile
                            }
                            group {
                                id
                            }
                            pageNumber
                        }
                    }
                    ... on CheckboxesSection {
                        checkboxesData
                    }
                    ... on SpreadsheetSection {
                        spreadsheetData
                    }
                }
            }
        }
    }
`;

const upsertGroupMutation = gql`
    mutation upsertGroup($practiceId: ID!) {
        upsertGroup(practice: $practiceId) {
            group {
                id
            }
        }
    }
`;

const initialForm = {
    id: undefined,
    name: undefined,
    rows: 0,
    sections: [],
    preventQuery: false,
    loading: false,
    error: false,
    autoFocus: '1,1',
};

const dialogState = {
    show: false,
    selectedSection: null,
};

const mapArrayOmitProps = (array, props) => lodashMap(array, (key) => omit(key, props));

const validateTitle = (sections) =>
    sections.map((section) => ({
        ...section,
        title: section.title || 'Untitled Section',
    }));

const localHandlers = {
    onNewColumnSection:
        ({ form, updateForm }) =>
        (type, rowNumber) => {
            const rowSections = filter(
                form.sections,
                (section) => section.rowIndexes.includes(rowNumber) && (!section.pageNumber || section.pageNumber <= 1)
            );

            if (rowSections.length < 3) {
                const columnIndexValue = rowSections.length > 0 ? maxBy(rowSections, (item) => last(item.columnIndexes)).columnIndexes[0] + 1 : 1;

                updateForm({
                    ...form,
                    loading: true,
                    sections: [
                        ...form.sections,
                        {
                            type,
                            title: 'Untitled Section',
                            rowIndexes: [rowNumber],
                            columnIndexes: [columnIndexValue],
                        },
                    ],
                });
            }
        },

    onNewRowSection:
        ({ form, updateForm }) =>
        (type) => {
            const number = form.rows + 1; // + 1 because we are adding a new row to the form.
            updateForm({
                ...form,
                loading: true,
                rows: number,
                sections: [
                    ...form.sections,
                    {
                        type,
                        title: 'Untitled Section',
                        columnIndexes: [1],
                        rowIndexes: [number],
                    },
                ],
            });
        },

    onArchiveSection:
        ({ form, updateForm }) =>
        (section, rowNumber) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));
            remove(updatedSections, { id: section.id });
            const rowSections = filter(updatedSections, { rowIndexes: [rowNumber] });

            // If the section being archived belongs to a group, remove all associated sections.
            if (get(section, 'group.id')) {
                remove(updatedSections, (item) => get(item, 'group.id') === get(section, 'group.id'));
            }

            // if there are no other sections within the selected row.
            if (rowSections.length === 0) {
                // reduce the amount of rows by 1, effectively removing a row.
                const updatedRows = form.rows - 1;
                // Grab all the sections and reduce their index by 1.
                // This will cause them to "jump" up a row when the selected row is deleted.
                updatedSections.map((sect) => {
                    if (sect.rowIndexes[0] > rowNumber) {
                        sect.rowIndexes[0] -= 1;
                    }
                    return sect;
                });

                updateForm({
                    ...form,
                    loading: true,
                    rows: updatedRows,
                    sections: updatedSections,
                });
            } else {
                updateForm({ ...form, loading: true, sections: updatedSections });
            }
        },

    onChangeGroupTitle:
        ({ form, updateForm }) =>
        (title, groupSections) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));

            groupSections.map((section) => {
                const index = form.sections.indexOf(section);

                return updatedSections.splice(index, 1, {
                    ...section,
                    title,
                });
            });

            updateForm({
                ...form,
                sections: updatedSections,
            });
        },

    onChangeTitle:
        ({ form, updateForm }) =>
        (title, section) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));
            const index = form.sections.indexOf(section);

            updatedSections.splice(index, 1, {
                ...section,
                title,
            });

            updateForm({
                ...form,
                sections: updatedSections,
            });
        },
    onChangeSectionCheckbox:
        ({ form, updateForm }) =>
        (checkboxesData, section) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));
            const index = form.sections.indexOf(section);

            updatedSections.splice(index, 1, {
                ...section,
                checkboxesData,
            });

            updateForm({
                ...form,
                sections: updatedSections,
            });
        },
    onChangeSectionSpreadsheet:
        ({ form, updateForm }) =>
        (spreadsheetData, section) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));
            const index = form.sections.indexOf(section);

            updatedSections.splice(index, 1, {
                ...section,
                spreadsheetData,
            });

            updateForm({
                ...form,
                sections: updatedSections,
            });
        },

    onSelectSection:
        ({ dispatch }) =>
        (section) => {
            dispatch(sectionSelect(section));
        },

    onSelectTemplate:
        ({ form, updateForm, selectedSection, onSaveSection, upsertGroup, practiceId }) =>
        async (templateId, templateData, groupedTemplates) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));
            const index = form.sections.indexOf(selectedSection);

            // Create a new group if groupTemplates are provided. This group belongs to the section
            let groupId;
            if (groupedTemplates) {
                const response = await upsertGroup({ practiceId });
                groupId = response.data.upsertGroup.group.id;
            }

            // Add selected template and replace section
            updatedSections.splice(index, 1, {
                ...selectedSection,
                groupId, // Only populates if groupId exists
                pageNumber: 1,
                templateId: templateId !== BLANK_TEMPLATE_ID ? templateId : null,
                template: {
                    // Required for DrawingSVG to render during mutation
                    // Is not used for mutation however
                    pageNumber: 1,
                    file: {
                        encodedFile: templateData,
                    },
                },
            });

            // Add additional sections if part of a group
            if (groupedTemplates) {
                remove(groupedTemplates, { id: templateId }); // Avoid duplicating the first section

                groupedTemplates.map((template) =>
                    updatedSections.push({
                        type: 'DRAWING',
                        title: selectedSection.title,
                        rowIndexes: selectedSection.rowIndexes, // Additional sections use the same positioning index
                        columnIndexes: selectedSection.columnIndexes, // Additional sections use the same positioning index
                        groupId, // Additional sections belong to the same group
                        pageNumber: template.pageNumber,
                        templateId: template.id,
                        template: {
                            pageNumber: template.pageNumber,
                            file: {
                                encodedFile: template.file.encodedFile,
                            },
                        },
                    })
                );
            }

            await updateForm({
                ...form,
                sections: updatedSections,
            });

            onSaveSection();
        },

    onReorderSection:
        ({ form, updateForm }) =>
        (sourceSection, targetSection) => {
            const updatedSections = JSON.parse(JSON.stringify(form.sections));
            const targetIndex = form.sections.indexOf(targetSection);
            const sourceIndex = form.sections.indexOf(sourceSection);

            updatedSections.splice(sourceIndex, 1, {
                ...sourceSection,
                columnIndexes: targetSection.columnIndexes,
                rowIndexes: targetSection.rowIndexes,
            });

            updatedSections.splice(targetIndex, 1, {
                ...targetSection,
                columnIndexes: sourceSection.columnIndexes,
                rowIndexes: sourceSection.rowIndexes,
            });

            updateForm({
                ...form,
                loading: true,
                autoFocus: targetSection.rowIndexes.concat(targetSection.columnIndexes).join(),
                sections: updatedSections,
            });
        },
};

const apiHandlers = {
    onDuplicate:
        ({ dispatch, form, history, upsertFormTemplate, practiceId, updateForm }) =>
        async () => {
            updateForm({
                ...form,
                loading: true,
            });

            // When creating new sections, provide the sections with a groupId and templateId
            // Using groupId from the previous section and template
            form.sections.map((section) => {
                const templateId = get(section, 'template.id');
                if (templateId) {
                    const index = form.sections.indexOf(section);

                    form.sections.splice(index, 1, {
                        ...section,
                        groupId: section.group ? section.group.id : null,
                        templateId,
                    });
                }

                return section;
            });

            const newSections = JSON.parse(JSON.stringify(form.sections));
            const newSections2 = newSections.map((section) => {
                if (section.type === 'CHECKBOXES') {
                    if (Array.isArray(section.checkboxesData)) {
                        section.checkboxesData = JSON.stringify(section.checkboxesData);
                    }
                }
                if (section.type === 'SPREADSHEET') {
                    if (typeof section.spreadsheetData === 'object' && section.spreadsheetData !== null && !Array.isArray(section.spreadsheetData)) {
                        section.spreadsheetData = JSON.stringify(section.spreadsheetData);
                    }
                }
                return section;
            });

            // Remove form template section.id's so new section.id's can be generated
            const options = {
                variables: {
                    formTemplateId: null,
                    practiceId,
                    categories: form.categoryIdsArray,
                    episodes: form.episodeIdsArray,
                    rows: form.rows,
                    name: form.name,
                    sections: mapArrayOmitProps(newSections2, ['id', 'template', 'group', '__typename']),
                },
            };

            try {
                const response = await upsertFormTemplate(options);

                history.replace(`/formTemplate/${response.data.upsertFormTemplate.formTemplate.id}`);

                // Form needs to be updated to give newly added sections their ids.
                // Using response to update form adds the '__typename' field into each section.
                // This is removed when running out upsert mutation.
                updateForm((prevForm) => ({
                    ...prevForm,
                    id: response.data.upsertFormTemplate.formTemplate.id,
                    sections: response.data.upsertFormTemplate.formTemplate.sections,
                    loading: false,
                    name: prevForm.name,
                }));
                dispatch(
                    formTemplateUpsert({
                        id: response.data.upsertFormTemplate.formTemplate.id,
                        name: response.data.upsertFormTemplate.formTemplate.name,
                        sections: response.data.upsertFormTemplate.formTemplate.sections,
                        rows: response.data.upsertFormTemplate.formTemplate.rows,
                    })
                );
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSave:
        ({
            dispatch,
            form,
            formTemplateId,
            history,
            upsertFormTemplate,
            newSectionType,
            practiceId,
            updateForm,
            sharableTemplate,
            templateCategory,
        }) =>
        async () => {
            if (!form.id) {
                updateForm({
                    ...form,
                    loading: true,
                    optimisticResponse: true,
                });
            } else {
                updateForm({
                    ...form,
                    loading: true,
                    optimisticResponse: true,
                    preventQuery: true,
                });
            }

            const newSections = JSON.parse(JSON.stringify(form.sections));
            const sections2 = [];
            newSections.forEach((section) => {
                if (section.checkboxesData && Array.isArray(section.checkboxesData)) {
                    section.checkboxesData = JSON.stringify(section.checkboxesData);
                }
                if (
                    section.spreadsheetData &&
                    typeof section.spreadsheetData === 'object' &&
                    section.spreadsheetData !== null &&
                    !Array.isArray(section.spreadsheetData)
                ) {
                    section.spreadsheetData = JSON.stringify(section.spreadsheetData);
                }
                if (section.id) {
                    sections2.push(section);
                }
            });

            const newOptions = {
                variables: {
                    practiceId,
                    name: form.name || 'Untitled Form Template',
                    rows: sections2.length,
                    sections: validateTitle(mapArrayOmitProps(sections2, ['template', '__typename', 'group'])),
                    sharableTemplate: !!sharableTemplate,
                    templateCategory,
                },
            };

            const editOptions = {
                variables: {
                    formTemplateId: formTemplateId || form.id,
                    allowUpdate: true,
                    practiceId,
                    name: form.name || 'Untitled Form Template',
                    rows: sections2.length,
                    sections: validateTitle(mapArrayOmitProps(sections2, ['template', '__typename', 'group'])),
                    sharableTemplate: !!sharableTemplate,
                    templateCategory,
                },
                // This does not seem to be working, however the mutation works if optimisitcResponse is removed.
                // Am I implementing it incorrectly? - Marlon 17/10/18
                optimisticResponse: {
                    __typename: 'Mutation',
                    upsertFormTemplate: {
                        __typename: 'upsertFormTemplatePayload',
                        formTemplate: {
                            __typename: 'FormTemplate',
                            id: form.id,
                            rows: sections2.length,
                            name: form.name,
                            sections: sections2.map((section) => {
                                const sectionObject = {
                                    __typename: section.__typename,
                                    id: section.id,
                                    type: section.type,
                                    title: section.title,
                                    rowIndexes: section.rowIndexes,
                                    columnIndexes: section.columnIndexes,
                                };

                                switch (section.__typename) {
                                    case 'TextSection':
                                        return { ...sectionObject, text: section.text };
                                    case 'DrawingSection':
                                        return {
                                            ...sectionObject,
                                            pageNumber: section.pageNumber,
                                            template: {
                                                __typename: 'Template',
                                                id: get(section, 'template.id', null),
                                                pageNumber: get(section, 'template.pageNumber', null),
                                                file: {
                                                    __typename: 'File',
                                                    id: get(section, 'template.file.id', null),
                                                    encodedFile: get(section, 'template.file.encodedFile', null),
                                                },
                                                group: {
                                                    __typename: 'Group',
                                                    id: get(section, 'template.group.id', null),
                                                },
                                            },
                                            group: {
                                                __typename: 'Group',
                                                id: get(section, 'group.id', null),
                                            },
                                        };
                                    default:
                                        return sectionObject;
                                }
                            }),
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: formTemplateQuery,
                        variables: { formTemplateId },
                    },
                ],
            };

            try {
                const response = await upsertFormTemplate(formTemplateId || form.id ? editOptions : newOptions);

                if (!formTemplateId && !sharableTemplate) {
                    history.replace(`/formTemplate/${response.data.upsertFormTemplate.formTemplate.id}`);
                }

                // Form needs to be updated to give newly added sections their ids.
                // Using response to update form adds the '__typename' field into each section.
                // This is removed when running out upsert mutation.
                updateForm((prevForm) => ({
                    ...prevForm,
                    id: response.data.upsertFormTemplate.formTemplate.id,
                    loading: false,
                    optimisticResponse: false,
                    preventQuery: true,
                    name: prevForm.name || 'Untitled Form Template',
                }));
                dispatch(
                    formTemplateUpsert({
                        id: response.data.upsertFormTemplate.formTemplate.id,
                        name: response.data.upsertFormTemplate.formTemplate.name,
                        sections: response.data.upsertFormTemplate.formTemplate.sections,
                        rows: response.data.upsertFormTemplate.formTemplate.rows,
                    })
                );
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    optimisticResponse: false,
                    error,
                }));
            }
            if (newSectionType) {
                dispatch(sectionCleared());
            }
        },
    onSaveSection:
        ({
            dispatch,
            form,
            formTemplateId,
            history,
            upsertFormTemplate,
            newSectionType,
            practiceId,
            updateForm,
            sharableTemplate,
            templateCategory,
        }) =>
        async () => {
            const newSections = JSON.parse(JSON.stringify(form.sections));
            const sections2 = newSections.map((section) => {
                if (section.checkboxesData && Array.isArray(section.checkboxesData)) {
                    section.checkboxesData = JSON.stringify(section.checkboxesData);
                }
                if (
                    section.spreadsheetData &&
                    typeof section.spreadsheetData === 'object' &&
                    section.spreadsheetData !== null &&
                    !Array.isArray(section.spreadsheetData)
                ) {
                    section.spreadsheetData = JSON.stringify(section.spreadsheetData);
                }
                return section;
            });
            const newOptions = {
                variables: {
                    practiceId,
                    name: form.name || 'Untitled Form Template',
                    rows: form.rows,
                    sections: mapArrayOmitProps(sections2, ['template', '__typename', 'group']),
                    sharableTemplate: !!sharableTemplate,
                    templateCategory,
                },
            };

            const editOptions = {
                variables: {
                    formTemplateId: formTemplateId || form.id,
                    allowUpdate: true,
                    practiceId,
                    name: form.name || 'Untitled Form Template',
                    rows: form.rows,
                    sections: mapArrayOmitProps(sections2, ['template', '__typename', 'group']),
                    sharableTemplate: !!sharableTemplate,
                    templateCategory,
                },
                refetchQueries: [
                    {
                        query: formTemplateQuery,
                        variables: { formTemplateId: formTemplateId || form.id },
                    },
                ],
            };

            try {
                const response = await upsertFormTemplate(formTemplateId || form.id ? editOptions : newOptions);

                if (!formTemplateId && !sharableTemplate) {
                    history.replace(`/formTemplate/${response.data.upsertFormTemplate.formTemplate.id}`);
                }

                // Form needs to be updated to give newly added sections their ids.
                // Using response to update form adds the '__typename' field into each section.
                // This is removed when running out upsert mutation.
                updateForm((prevForm) => ({
                    ...prevForm,
                    id: response.data.upsertFormTemplate.formTemplate.id,
                    loading: false,
                    preventQuery: true,
                    name: prevForm.name || 'Untitled Form Template',
                    sections: response.data.upsertFormTemplate.formTemplate.sections.map((section) => {
                        if (section.checkboxesData && !Array.isArray(section.checkboxesData)) {
                            section.checkboxesData = JSON.parse(section.checkboxesData);
                        }
                        if (
                            section.spreadsheetData &&
                            !(
                                typeof section.spreadsheetData === 'object' &&
                                section.spreadsheetData !== null &&
                                !Array.isArray(section.spreadsheetData)
                            )
                        ) {
                            section.spreadsheetData = JSON.parse(section.spreadsheetData);
                        }
                        return section;
                    }),
                }));
                dispatch(
                    formTemplateUpsert({
                        id: response.data.upsertFormTemplate.formTemplate.id,
                        name: response.data.upsertFormTemplate.formTemplate.name,
                        sections: response.data.upsertFormTemplate.formTemplate.sections,
                        rows: response.data.upsertFormTemplate.formTemplate.rows,
                    })
                );
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
            if (newSectionType) {
                dispatch(sectionCleared());
            }
        },
    onArchiveTemplate:
        ({ formTemplateId, practiceId, form, updateForm, upsertFormTemplate, history }) =>
        async () => {
            updateForm({ ...form, loading: true });
            try {
                await upsertFormTemplate({
                    variables: {
                        formTemplateId,
                        allowUpdate: true,
                        practiceId,
                        name: form.name || 'Untitled Form Template',
                        rows: form.rows,
                        sections: validateTitle(mapArrayOmitProps(form.sections, ['template', '__typename', 'group'])),
                        deletedAt: new Date(),
                    },
                });
                updateForm({ ...form, loading: false, error: false });
                history.goBack();
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
};

const FormTemplateContainer = compose(
    withRouter,
    connect(({ practice, section, user }) => ({
        isAuth: user.roles.includes('RECEPTIONIST') || user.roles.includes('ADMIN') || user.roles.includes('CLINICIAN'),
        practiceId: practice.id,
        newSectionType: section.newType,
        newSectionRowNumber: section.rowNumber,
        selectedSection: section.selectedSection,
    })),
    withState('form', 'updateForm', initialForm),
    withState('reorderDialog', 'updateReorderDialog', dialogState),
    withState('templateDialog', 'updateTemplateDialog', false),
    withProps(({ form, match, updateReorderDialog }) => ({
        formTemplateId: match.params.formTemplateId,
        initialSave: Boolean(form.loading && !form.id),
        onCloseReorderDialog: () => updateReorderDialog({ show: false, selectedSection: null }),
        rowsRange: range(1, form.rows + 1),
        rowsWithImages: lodashMap(filter(form.sections, ['type', 'DRAWING']), 'rowIndexes'),
    })),
    branch(
        ({ formTemplateId, form }) => formTemplateId && !form.preventQuery,
        compose(
            graphql(formTemplateQuery, {
                name: 'query',
                options: ({ formTemplateId }) => ({
                    variables: {
                        formTemplateId,
                    },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                ({ status }) => status && status.success,
                withProps(({ query }) => ({
                    id: query.node.id,
                    name: query.node.name,
                    rows: query.node.rows,
                    sections: query.node.sections,
                }))
            )
        )
    ),
    graphql(upsertFormTemplateMutation, { name: 'upsertFormTemplate' }),
    graphql(upsertGroupMutation, { name: 'upsertGroup' }),
    withHandlers(apiHandlers),
    withHandlers(localHandlers),
    withPropsOnChange(['onSave'], ({ onSave }) => ({
        onSave: debounce(onSave, 300),
    })),
    withPropsOnChange(['onChangeSectionCheckbox'], ({ onChangeSectionCheckbox }) => ({
        onChangeSectionCheckbox: debounce(onChangeSectionCheckbox, 300),
    })),
    lifecycle({
        componentDidMount() {
            const { dispatch, form, formTemplateId, status, id, name, rows, sections, updateForm } = this.props;

            if (!formTemplateId) {
                dispatch(formTemplateCleared());
            } else if (status && status.success) {
                let newSections = JSON.parse(JSON.stringify(sections));
                newSections = newSections.map((section) => {
                    if (section.type === 'CHECKBOXES' && !Array.isArray(section.checkboxesData)) {
                        section.checkboxesData = JSON.parse(section.checkboxesData);
                    }
                    if (
                        section.type === 'SPREADSHEET' &&
                        !(typeof section.spreadsheetData === 'object' && section.spreadsheetData !== null && !Array.isArray(section.spreadsheetData))
                    ) {
                        section.spreadsheetData = JSON.parse(section.spreadsheetData);
                    }
                    return section;
                });

                updateForm({ ...form, id, name, rows, sections: newSections });
                dispatch(
                    formTemplateUpsert({
                        sections: newSections,
                        rows,
                    })
                );
            }
        },
        async componentDidUpdate(prevProps) {
            const { newSectionType, newSectionRowNumber, onNewRowSection, onNewColumnSection, onSaveSection } = this.props;

            if (newSectionType && newSectionType !== prevProps.newSectionType && !newSectionRowNumber) {
                await onNewRowSection(newSectionType);
                onSaveSection();
            }

            if (newSectionType && newSectionType !== prevProps.newSectionType && newSectionRowNumber) {
                await onNewColumnSection(newSectionType, newSectionRowNumber);
                onSaveSection();
            }
        },
    })
);

export default FormTemplateContainer;

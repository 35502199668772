import { isMobileOnly } from 'react-device-detect';

export default {
  rootContainer: {
    maxWidth: '100%'
  },
  main: {
    // paddingTop: 92,
    // paddingLeft: 44,
    // paddingRight: 44,
    // paddingBottom: 64,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // transition: 'width 0.5s',
    // transitionTimingFunction: 'ease',
  },
  canvasWrapper: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#aeaeae',
    width: '100%',
    height: '100%',
    backgroundColor: '#f5f5f5',
    overflow: 'hidden',
  },
  topMenu: {
    padding: '5px',
    marginBottom: '10px',
  },
  paging: {
    margin: '5px 20px 5px 5px',
    display: 'none',
  },
  toolsWrapper: {
    marginTop: '5px',
    width: isMobileOnly ? '41px' : '58px',
    display: isMobileOnly ? 'none' : 'inline-block',
    overflow: 'hidden',
  },
  toolbarContainer: {
    position: 'absolute',
    // width: isMobileOnly ? '40px' : '58px',
    // height: isMobileOnly ? '30px' : '40px',
    right: '10px',
    top: '10px',
    zIndex: '999',
  },
  colorPickerContainer: {

  },
  collapsibleBtn: {
    width: isMobileOnly ? '42px' : '58px',
    height: isMobileOnly ? '35px' : '38px',
    textAlign: 'center',
  },
  preselectionsMenu: {
    position: 'absolute',
    // width: '56px',
    // height: '22px',
    left: '10px',
    top: '10px',
    zIndex: '999',
  },
  brushSizeSubmenu: {
    position: 'absolute',
    top: '53px',
    right: '84px',
    zIndex: '999',
  },
  textToolSubmenu: {
    position: 'absolute',
    top: '220px',
    right: '84px',
    zIndex: '999',
  },
  colorPicker: {
    position: 'absolute',
    top: '80px',
    right: '84px',
    zIndex: '999',
  },
  toolbarItem: {
    padding: isMobileOnly ? '10px' : '15px',
  },
  zoomButtons: {
    position: 'absolute',
    right: '80px',
    top: '10px',
    zIndex: '999',
  },
};

// action types

export const INTEGRATION_SELECTED = 'INTEGRATION_SELECTED';
export const INTEGRATION_CLEARED = 'INTEGRATION_CLEARED';

// action creators

export const integrationSelected = ({
  id
}) => ({
  type: INTEGRATION_SELECTED,
  id
});

export const integrationCleared = () => ({ type: INTEGRATION_CLEARED });

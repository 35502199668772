import {
  compose,
  withProps,
  branch,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';

export const userPreferencesListQuery = gql`
  query userPreferencesList($practiceId: ID!) {
    node(id: $practiceId) {
      __typename
      id
      ... on Practice {
        userPreferences {
          id
          name
          type
          settings
          deletedAt
          isDefaultPreference
        }
      }
    }
  }
`;

const upsertUserPreferencesMutation = gql`
  mutation upsertUserPreferences(
    $userPreferencesId: ID
    $allowUpdate: Boolean
    $practiceId: ID!
    $name: String
    $type: String
    $settings: String
    $isDefaultPreference: Int
  ) {
    upsertUserPreferences(
      id: $userPreferencesId
      allowUpdate: $allowUpdate
      practice: $practiceId
      name: $name
      type: $type
      settings: $settings
      isDefaultPreference: $isDefaultPreference
    ) {
      userPreferences {
        id
        name
        type
        settings
        isDefaultPreference
      }
    }
  }
`;

const stateInit = {
  selectedPreference: undefined,
  userAddedPreferences: [],
};

const localHandlers = {
  onPreferenceClick: ({ setCategoryInput, state, setState }) => (
    action,
    preference
  ) => {
    if (action === 'add_category') {
      if (preference === undefined || preference.length === 0) return;
      var userAddedPreferences = state.userAddedPreferences;
      userAddedPreferences.push({
        id: undefined,
        name: preference ? preference : 'Untitled category',
        type: 'drawing_setting',
        settings: '{"data":[]}',
      });
      setState({
        selectedPreference: state.selectedPreference,
        userAddedPreferences: userAddedPreferences,
      });
      setCategoryInput('');
    } else {
      setState({
        selectedPreference: preference,
        userAddedPreferences: state.userAddedPreferences,
      });
    }
  },
  onCategoryNameChange: ({ setCategoryInput }) => target => {
    setCategoryInput(target.value);
  },
  onDeleteChip: ({ state, setState }) => (key, value) => {
    var selectedPreference = state.selectedPreference;
    var selectedSettings = JSON.parse(selectedPreference.settings);
    var categoryArray = selectedSettings.data;
    if (categoryArray && Array.isArray(categoryArray)) {
      categoryArray = categoryArray.map(category => {
        if (category.key === key) {
          category.value = category.value.filter(e => e !== value);
        }
        return category;
      });
      selectedPreference.settings = JSON.stringify({ data: categoryArray });
      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: state.userAddedPreferences,
      });
    }
  },
  onAddChipAction: ({
    setOpenAddItemDialog,
    setSelectedKeyForPredefined,
  }) => key => {
    setOpenAddItemDialog(true);
    setSelectedKeyForPredefined(key);
  },
  onAddValueForPredefined: ({
    state,
    setState,
    selectedKeyForPredefined,
    setSelectedKeyForPredefined,
    setOpenAddItemDialog,
    chipToBeAdded,
    setChipToBeAdded,
  }) => () => {
    if (selectedKeyForPredefined && selectedKeyForPredefined.length > 0) {
      var selectedPreference = state.selectedPreference;
      var selectedSettings = JSON.parse(selectedPreference.settings);
      var categoryArray = selectedSettings.data;
      if (categoryArray && Array.isArray(categoryArray)) {
        categoryArray = categoryArray.map(category => {
          if (
            chipToBeAdded &&
            chipToBeAdded.length > 0 &&
            category.key === selectedKeyForPredefined &&
            category.value.filter(e => e === chipToBeAdded).length === 0
          ) {
            category.value.push(chipToBeAdded);
          }
          return category;
        });
        selectedPreference.settings = JSON.stringify({ data: categoryArray });

        setState({
          selectedPreference: selectedPreference,
          userAddedPreferences: state.userAddedPreferences,
        });
      }
    }
    setOpenAddItemDialog(false);
    setSelectedKeyForPredefined('');
    setChipToBeAdded('');
  },
  onInsertPredefinedValueChange: ({ setChipToBeAdded }) => target => {
    setChipToBeAdded(target.value);
  },
  onAddSectionAction: ({
    sectionNameToBeAdded,
    sectionTypeToBeAdded,
    state,
    setState,
    setShouldShowCreateSectionDialog,
  }) => () => {
    var selectedPreference = state.selectedPreference;
    var selectedSettings = JSON.parse(selectedPreference.settings);
    var categoryArray = selectedSettings.data;
    if (
      sectionNameToBeAdded &&
      sectionNameToBeAdded.length > 0 &&
      sectionTypeToBeAdded &&
      sectionTypeToBeAdded.length > 0 &&
      categoryArray.filter(e => e.key === sectionNameToBeAdded).length === 0
    ) {
      categoryArray.push({
        key: sectionNameToBeAdded,
        type: sectionTypeToBeAdded,
        value: [],
      });
      selectedPreference.settings = JSON.stringify({ data: categoryArray });

      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: state.userAddedPreferences,
      });
      setShouldShowCreateSectionDialog(false);
    }
  },
  onSectionTypeChange: ({ setSectionTypeToBeAdded }) => type => {
    setSectionTypeToBeAdded(type);
  },
  onRemoveSection: ({ state, setState }) => sectionName => {
    var selectedPreference = state.selectedPreference;
    var selectedSettings = JSON.parse(selectedPreference.settings);
    var categoryArray = selectedSettings.data;
    if (
      sectionName &&
      categoryArray.filter(e => e.key === sectionName).length > 0
    ) {
      categoryArray = categoryArray.filter(e => e.key !== sectionName);
      selectedPreference.settings = JSON.stringify({ data: categoryArray });

      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: state.userAddedPreferences,
      });
    }
  },
  onOpenRenameSectionDialog: ({
    setShouldShowRenameSectionDialog,
    setSectionNameToBeRenamed,
  }) => sectionToRename => {
    setSectionNameToBeRenamed(sectionToRename);
    setShouldShowRenameSectionDialog(true);
  },
  onRenameSectionAction: ({
    setShouldShowRenameSectionDialog,
    sectionNewName,
    setSectionNewName,
    state,
    setState,
    sectionNameToBeRenamed,
  }) => () => {
    var selectedPreference = state.selectedPreference;
    var selectedSettings = JSON.parse(selectedPreference.settings);
    var categoryArray = selectedSettings.data;
    if (
      sectionNewName.length > 0 &&
      sectionNameToBeRenamed.length > 0 &&
      categoryArray.filter(e => e.key === sectionNameToBeRenamed).length > 0
    ) {
      categoryArray = categoryArray.map(category => {
        if (category.key === sectionNameToBeRenamed) {
          category.key = sectionNewName;
        }
        return category;
      });

      selectedPreference.settings = JSON.stringify({ data: categoryArray });

      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: state.userAddedPreferences,
      });
    }

    setShouldShowRenameSectionDialog(false);
    setSectionNewName('');
  },
  onScaleFieldMinValueChange: ({ state, setState }) => (key, value) => {
    var selectedPreference = state.selectedPreference;
    var selectedSettings = JSON.parse(selectedPreference.settings);
    var categoryArray = selectedSettings.data;
    if (categoryArray && Array.isArray(categoryArray)) {
      categoryArray = categoryArray.map(category => {
        if (category.key === key) {
          category.value[0] = value;
        }
        return category;
      });
      console.log(categoryArray);
      selectedPreference.settings = JSON.stringify({ data: categoryArray });
      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: state.userAddedPreferences,
      });
    }
  },
  onScaleFieldMaxValueChange: ({ state, setState }) => (key, value) => {
    var selectedPreference = state.selectedPreference;
    var selectedSettings = JSON.parse(selectedPreference.settings);
    var categoryArray = selectedSettings.data;
    if (categoryArray && Array.isArray(categoryArray)) {
      categoryArray = categoryArray.map(category => {
        if (category.key === key) {
          category.value[1] = value;
        }
        return category;
      });
      selectedPreference.settings = JSON.stringify({ data: categoryArray });
      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: state.userAddedPreferences,
      });
    }
  },
  onMenuRenameCategoryAction: ({ setShouldShowRenameCategoryDialog }) => () => {
    setShouldShowRenameCategoryDialog(true);
  },
  onRenameCategoryAction: ({
    anchorCategoryElement,
    categoryNewName,
    userPreferencesState,
    setUserPreferencesState,
    state,
    setAnchorCategoryElement,
    setShouldShowRenameCategoryDialog,
  }) => () => {
    if (anchorCategoryElement && categoryNewName.length > 0) {
      var categoryArray = userPreferencesState;
      categoryArray = categoryArray.map(category => {
        if (category.name === state.selectedPreference.name) {
          category.name = categoryNewName;
        }
        return category;
      });
      setUserPreferencesState(categoryArray);
      setShouldShowRenameCategoryDialog(false);
      setAnchorCategoryElement(undefined);
    }
  },
  onSavePreferencesAction: ({
    state,
    setState,
    onUpsertUserPreferences,
    practiceId,
    onClosePreferenceDialog,
    setOnDialogLoading,
  }) => preferences => {
    // Sumarize all preferences
    setOnDialogLoading(true);
    var allPreferences = [];
    if (state.userAddedPreferences && state.userAddedPreferences.length > 0) {
      state.userAddedPreferences.forEach(function(item) {
        allPreferences.push(item);
      });
    }
    if (preferences && preferences.length > 0) {
      preferences.forEach(function(item) {
        allPreferences.push(item);
      });
    }
    if (allPreferences.length > 0) {
      var requests = allPreferences.map(async (preference, index) => {
        var variables = {};
        if (preference.id) {
          variables.userPreferencesId = preference.id;
          variables.allowUpdate = true;
        } else {
          variables.allowUpdate = false;
        }
        variables.name = preference.name;
        variables.type = preference.type;
        variables.settings = preference.settings;
        variables.practiceId = practiceId;
        variables.isDefaultPreference = preference.isDefaultPreference
          ? preference.isDefaultPreference
          : 0;

        return await onUpsertUserPreferences(variables);
      });

      Promise.all(requests).then(() => {
        console.log('Success update preferences');
        stateInit.userAddedPreferences = [];
        stateInit.selectedPreference = undefined;
        setState(stateInit);
        setOnDialogLoading(false);
        onClosePreferenceDialog();
      });
    }
  },
  onClosePreference: ({
    onClosePreferenceDialog,
    state,
    setState,
    setUserPreferencesState,
  }) => () => {
    stateInit.userAddedPreferences = [];
    stateInit.selectedPreference = undefined;
    setState(stateInit);
    setUserPreferencesState([]);
    onClosePreferenceDialog();
  },
  onSetDefaultCategory: ({
    state,
    setState,
    userPreferencesState,
    setUserPreferencesState,
    setAnchorCategoryElement,
  }) => () => {
    if (state.selectedPreference && userPreferencesState) {
      var newUserPreferencesState = userPreferencesState.map(preference => {
        preference.isDefaultPreference = 0;
        return preference;
      });
      setUserPreferencesState(newUserPreferencesState);
      var userAddedPreferences = state.userAddedPreferences.map(preference => {
        preference.isDefaultPreference = 0;
        return preference;
      });

      var selectedPreference = state.selectedPreference;
      selectedPreference.isDefaultPreference = 1;
      setState({
        selectedPreference: selectedPreference,
        userAddedPreferences: userAddedPreferences,
      });
    }
    setAnchorCategoryElement(undefined);
  },
};

const apiHandlers = {
  onUpsertUserPreferences: ({
    upsertUserPreferences,
  }) => async variablesData => {
    return await upsertUserPreferences({
      variables: variablesData,
    });
  },
};

const PredefinedSelectionsEditorContainer = compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
  })),
  withState('openAddItemDialog', 'setOpenAddItemDialog', false),
  withState('categoryInput', 'setCategoryInput', ''),
  withState('chipToBeAdded', 'setChipToBeAdded', ''),
  withState('sectionNameToBeAdded', 'setSectionNameToBeAdded', ''),
  withState('sectionTypeToBeAdded', 'setSectionTypeToBeAdded', 'text'),
  withState('selectedKeyForPredefined', 'setSelectedKeyForPredefined', ''),
  withState(
    'shouldShowCreateSectionDialog',
    'setShouldShowCreateSectionDialog',
    false
  ),
  withState(
    'shouldShowRenameSectionDialog',
    'setShouldShowRenameSectionDialog',
    false
  ),
  withState('sectionNameToBeRenamed', 'setSectionNameToBeRenamed', ''),
  withState('sectionNewName', 'setSectionNewName', ''),
  withState('onDialogLoading', 'setOnDialogLoading', false),
  withState('anchorCategoryElement', 'setAnchorCategoryElement', undefined),
  withState(
    'shouldShowRenameCategoryDialog',
    'setShouldShowRenameCategoryDialog',
    false
  ),
  withState('categoryNewName', 'setCategoryNewName', ''),
  withState('categoryNameToBeRenamed', 'setCategoryNameToBeRenamed', ''),
  graphql(userPreferencesListQuery, {
    name: 'query',
    options: ({ practiceId }) => ({
      variables: { practiceId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(upsertUserPreferencesMutation, { name: 'upsertUserPreferences' }),
  withProps(({ query }) => ({
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    ({ status }) => status.success,
    withProps(({ query }) => ({
      userPreferences: query.node.userPreferences,
    }))
  ),
  withState('userPreferencesState', 'setUserPreferencesState', props => {
    return props.userPreferences;
  }),
  withState('state', 'setState', stateInit),
  withHandlers(apiHandlers),
  withHandlers(localHandlers),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate() {},
  })
);

export default PredefinedSelectionsEditorContainer;

import React, { useEffect, useState } from 'react';

import { compose } from 'recompose';
import _ from 'lodash';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Moment from 'moment';
import { useMutation } from '@apollo/client';

import { Button, ButtonGroup, Grid, NativeSelect, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { connect } from 'react-redux';
import { ARRIVAL_GREEN, BOULDER, WARM_PINK, WILD_SAND } from '../../style/constants';
import { RESCHEDULE_EVENT } from './gqlQueries';
import Loading from '../../components/Loading';

const CalendarSchedule = ({
    classes,
    appointmentTimeSlot,
    changeCalendarView,
    currentView,
    dayCalendar,
    handleDateSelect,
    handleEventClick,
    listAppointments,
    listClinicians,
    closures,
    resources,
    selectedClinicianId,
    selectedDay,
    setAppointmentId,
    setCurrentView,
    setSelectedDay,
    setSelectedEnd,
    setSelectedClinicianId,
    setModalType,
    timeNow,
    collapseSidebar,
    setCollapseSidebar,
    integrationId,
    openingTime,
    closingTime,
}) => {
    const today = Moment().format('YYYY-MM-DD');
    const [dateTitle, setDateTitle] = useState('');

    const [isItToday, setIsItToday] = useState(sessionStorage.getItem('selectedDay') === today);

    const parseCalendarEvents = (appointments) => {
        if (!appointments) return [];
        const parsed = appointments.map((appointment) => {
            const { appointmentType, patients, clinician, invoiced, checkedIn, newPatient, __typename } = appointment;

            if (__typename === 'Event') {
                return {
                    ...appointment,
                    backgroundColor: '#a3a0a0',
                    textColor: 'black',
                    borderColor: 'rgba(0,0,0,0)',
                    resourceId: clinician.id,
                };
            }
            if (!patients || !patients.length || !clinician) {
                return null;
            }
            const cssClasses = [];
            let rightFlagColor;
            if (invoiced && invoiced.length > 0 && invoiced.length === checkedIn.length) {
                cssClasses.push('invoiced');
                rightFlagColor = BOULDER;
            } else if (checkedIn && checkedIn.length > 0) {
                cssClasses.push('checked-in');
                rightFlagColor = ARRIVAL_GREEN;
            }
            if (newPatient) {
                cssClasses.push('new-patient');
            }

            let patientStr = '';
            if (integrationId) {
                if (appointment.integrationMetadata.mindbodyAppointmentType === 'Class') {
                    patientStr = `${patients.length} Attendees`;
                } else {
                    patientStr = patients.length === 1 ? patients[0].name : `${patients.length} Attendees`;
                }
            } else {
                patientStr = patients.length === 1 ? patients[0].name : `${patients.length} Attendees`;
            }

            const classDescriptionName =
                appointment.integrationMetadata !== undefined && appointment.integrationMetadata !== null
                    ? appointment.integrationMetadata.classDescriptionName
                    : null;

            let appointmentTypeName = appointmentType.name;
            if (classDescriptionName !== null && classDescriptionName !== undefined && classDescriptionName !== '')
                appointmentTypeName = classDescriptionName;

            let appointmentTitle = '';
            if (integrationId) {
                appointmentTitle = `${patientStr} - ${appointmentTypeName}`;
            } else {
                appointmentTitle = `${patientStr} - ${appointmentTypeName} - ${appointmentType.code}`;
            }

            return {
                ...appointment,
                title: appointmentTitle,
                backgroundColor: appointmentType.color,
                textColor: 'black',
                className: cssClasses,
                fontSize: integrationId ? '14px' : '16px',
                borderColor: rightFlagColor || 'rgba(0,0,0,0)',
                resourceId: clinician.id,
            };
        });
        return [...parsed.filter((p) => p), ...closures];
    };

    const updateHeaderState = () => {
        // Extracts the Date title and what day/week view is currently rendered
        if (!dayCalendar || !dayCalendar.current) return;

        const { viewTitle, currentViewType } = _.get(dayCalendar.current.getApi(), ['currentDataManager', 'data'], '');
        if (dayCalendar) {
            setDateTitle(viewTitle);
            setIsItToday(Moment(today).format('MMMM D, YYYY') === viewTitle);
            setCurrentView(currentViewType);
        }
    };

    const [rescheduleEvent] = useMutation(RESCHEDULE_EVENT, {
        refetchQueries: ['listAppointments', 'listEvents'],
    });

    useEffect(() => {
        if (dayCalendar && dayCalendar.current) {
            updateHeaderState();
        }
    }, [dayCalendar, selectedDay]);

    const { REACT_APP_FULL_CALENDAR_LICENSE_KEY } = process.env;

    return (
        <Grid className={classes.scheduleContainer}>
            <div className={classes.headerContainer}>
                <div className={classes.headerGroup}>
                    <Button
                        onClick={() => {
                            setCollapseSidebar(!collapseSidebar);
                        }}>
                        {collapseSidebar ? <ArrowBack style={{ transform: 'rotate(180deg)' }} /> : <ArrowBack />}
                    </Button>
                    <Typography variant='h6' component='h1'>
                        {dateTitle}
                    </Typography>

                    <ButtonGroup>
                        <Button
                            className={isItToday ? classes.highlightedCalendarControls : classes.calendarControls}
                            onClick={() => {
                                handleDateSelect({ startStr: today });
                                updateHeaderState();
                            }}>
                            TODAY
                        </Button>
                    </ButtonGroup>
                </div>

                <div className={classes.headerGroup}>
                    <NativeSelect
                        className={classes.selectField}
                        onChange={(event) => {
                            const selectedClinician = listClinicians.find((clinician) => clinician.id === event.target.value);

                            if (event.target.value === 'all') {
                                setSelectedClinicianId(null);
                                sessionStorage.setItem('clinicianFilter', null);
                            } else if (selectedClinician) {
                                setSelectedClinicianId(selectedClinician.id);
                                sessionStorage.setItem('clinicianFilter', selectedClinician.id);
                            }
                        }}
                        value={sessionStorage.getItem('clinicianFilter') || 'all'}>
                        <option value='all'>All Clinicians</option>
                        {listClinicians.map((item) => (
                            <option key={item.name} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </NativeSelect>

                    <ButtonGroup>
                        <Button
                            className={currentView === 'resourceTimeGridDay' ? classes.highlightedCalendarControls : classes.calendarControls}
                            onClick={() => {
                                changeCalendarView('resourceTimeGridDay');
                                setCurrentView('resourceTimeGridDay');
                                updateHeaderState();
                            }}>
                            DAY
                        </Button>

                        <Button
                            className={currentView === 'resourceTimeGridWeek' ? classes.highlightedCalendarControls : classes.calendarControls}
                            onClick={() => {
                                changeCalendarView('resourceTimeGridWeek');
                                setCurrentView('resourceTimeGridWeek');
                                updateHeaderState();
                            }}>
                            WEEK
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

            {resources.length === 0 && (
                <div className={classes.noClinicians}>
                    <Typography variant='h6' component='h1'>
                        <Loading />
                    </Typography>
                </div>
            )}

            {!!resources.length && (
                <FullCalendar
                    businessHours
                    schedulerLicenseKey={REACT_APP_FULL_CALENDAR_LICENSE_KEY}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin, scrollGridPlugin]}
                    headerToolbar={false}
                    displayEventTime={false}
                    initialView={currentView}
                    editable
                    dayMaxEvents
                    dayMinWidth={resources.length === 1 ? undefined : 180}
                    selectable
                    ref={dayCalendar}
                    allDaySlot={false}
                    slotMinTime={openingTime}
                    slotMaxTime={closingTime}
                    slotLabelInterval='01:00'
                    nowIndicator='true'
                    dayHeaderContent={(args) => {
                        const options = {
                            day: 'numeric',
                            month: 'numeric',
                        };
                        const locale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
                        return Moment(args.date).format('ddd') + ' ' + args.date.toLocaleDateString(locale, options);
                    }}
                    scrollTime={sessionStorage.getItem('scheduleViewPosition') || timeNow}
                    initialDate={selectedDay || null}
                    resources={resources}
                    events={parseCalendarEvents(listAppointments)}
                    // Saves location of the clicked spot relative to the schedule
                    // (if event starts at 3pm, page will jump back to 1pm; roughly the middle of the page)
                    dateClick={(info) => sessionStorage.setItem('scheduleViewPosition', Moment(info.dateStr).subtract(2, 'hours').format('HH:mm'))}
                    // Activates Appointment Detail dialog
                    eventClick={(info) => {
                        handleEventClick(info.jsEvent);
                        setAppointmentId(info.event.id);
                        // Saves location of the clicked event relative to the schedule
                        // (if event starts at 3pm, page will jump back to 1pm; roughly the middle of the page)
                        sessionStorage.setItem('scheduleViewPosition', Moment(info.event.start).subtract(2, 'hours').format('HH:mm'));
                        window.history.pushState(null, null, `/diary/appointment/${info.event.id}`);
                    }}
                    eventResize={(info) => {
                        const confirmMove = window.confirm('Please confirm changing duration');
                        if (confirmMove) {
                            const { id, start, end } = info.event;
                            rescheduleEvent({
                                variables: {
                                    id,
                                    clinician: info.event.getResources()[0].id,
                                    start,
                                    end,
                                    userId: info.event.getResources()[0].id,
                                },
                            });
                        } else {
                            info.revert();
                        }
                    }}
                    eventDrop={(info) => {
                        const confirmMove = window.confirm('Please confirm reschedule');
                        if (confirmMove) {
                            const { id, start, end } = info.event;
                            rescheduleEvent({
                                variables: {
                                    id,
                                    clinician: info.event.getResources()[0].id,
                                    start,
                                    end,
                                    userId: info.event.getResources()[0].id,
                                },
                            });
                        } else {
                            info.revert();
                        }
                    }}
                    firstDay={1}
                    height='calc(100vh - 200px)'
                    // Activates Create Appointment dialog
                    select={(info) => {
                        if (integrationId) {
                            setModalType('integrationPresentError');
                            return;
                        }
                        setSelectedClinicianId(info.resource.id);
                        setSelectedDay(Moment(info.startStr).toISOString(true));
                        setSelectedEnd(Moment(info.endStr).toISOString(true));
                        setModalType('create');
                    }}
                    eventContent={(arg) => {
                        let hasAlerts = false;
                        if (arg.event.extendedProps.patients) {
                            for (const patient of arg.event.extendedProps.patients) {
                                if (patient.alerts !== null && patient.alerts.length > 0) {
                                    hasAlerts = true;
                                    break;
                                }
                            }
                        }

                        let display = `<strong>${arg.event.title}</strong>`;

                        if (hasAlerts) {
                            display +=
                                '<svg data-prefix="fas" data-icon="triangle-exclamation" class="svg-inline--fa fa-triangle-exclamation fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="#dc3545" style="margin-left: 10px;" aria-labelledby="svg-inline--fa-title-jguTHzv1g0KW"><title id="svg-inline--fa-title-jguTHzv1g0KW">Patient has alerts</title><path fill="currentColor" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path></svg>';
                        }

                        return { html: display };
                    }}
                    slotDuration={appointmentTimeSlot ? `00:${appointmentTimeSlot}:00` : '00:15:00'}
                />
            )}
        </Grid>
    );
};

const styles = {
    scheduleContainer: {},
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingLeft: 22,
        paddingRight: 22,
        paddingBottom: 10,
        width: '100%',
        backgroundColor: '#F5F5F5',
        '& .MuiTypography-h6': {
            fontSize: 16,
        },
    },
    headerGroup: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiButtonGroup-root': {
            marginLeft: 22,
        },
    },
    calendarControls: {
        width: 74,
        height: 36,
        color: BOULDER,
        backgroundColor: '#ffffff',
    },
    highlightedCalendarControls: {
        width: 74,
        height: 36,
        color: WILD_SAND,
        backgroundColor: WARM_PINK,
    },
    selectField: {
        width: 146,
    },
    noClinicians: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 200px)',
        color: WARM_PINK,
    },
};

export default compose(
    withStyles(styles),
    connect(({ integration }) => ({
        integrationId: integration.id,
    }))
)(CalendarSchedule);

import { branch, compose, withHandlers, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';

export const templateListQuery = gql`
    query templateList($practiceId: ID!) {
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                templates {
                    id
                    name
                    deletedAt
                    pageNumber
                    file {
                        id
                    }
                    group {
                        id
                    }
                }
            }
        }
    }
`;

const alertDialogState = {
    show: false,
    selectedTemplate: undefined,
};

const apiHandlers = {};

const localHandlers = {
    onSelectTemplate:
        ({ history }) =>
        async (templateId, groupedTemplates) => {
            let templateIdentifier = templateId;
            if (groupedTemplates) {
                if (Array.isArray(groupedTemplates) && groupedTemplates.length > 0) {
                    templateIdentifier = groupedTemplates[0].group.id;
                }
            }

            // Create new drawing note wiiiiith the template identifier??
            console.log('Selected template with templateId', templateId);
            console.log('Selected template with groupedTemplates', groupedTemplates);

            let searchString = `?templateId=${templateIdentifier}&isGroup=${groupedTemplates != null}`;

            if (history.location.search !== undefined && history.location.search.length > 0) {
                searchString += history.location.search.replace('?', '&');
            }
            history.push({
                pathname: history.location.pathname + '/webdrawingnote',
                search: searchString,
            });
        },
    getFileEncodedFromFileId:
        ({ templateFiles }) =>
        (fileId) => {
            if (fileId) {
                const filteredFile = templateFiles.filter((file) => file.id === fileId);
                if (filteredFile.length > 0) {
                    return filteredFile[0].encodedFile;
                }
            }
            return undefined;
        },
};

export const BLANK_TEMPLATE_ID = 'blankId';

const blankTemplate = {
    id: BLANK_TEMPLATE_ID,
    name: 'Blank',
    file: {
        id: undefined,
        encodedFile: undefined,
    },
};

const TemplateListContainer = compose(
    withRouter,
    connect(({ practice, section }) => ({
        practiceId: practice.id,
        newSectionRowNumber: section.rowNumber,
    })),
    graphql(templateListQuery, {
        name: 'query',
        options: ({ practiceId }) => ({
            variables: { practiceId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withState('fileLoading', 'updateFileLoading', false),
    withState('alertDialog', 'updateAlertDialog', alertDialogState),
    withState('colSize', 'updateColSize', 3),
    withState('templateFiles', 'updateTemplateFiles', []),
    withHandlers(apiHandlers),
    withHandlers(localHandlers),
    withProps(({ query }) => ({
        error: query.error,
        status: {
            loading: query.networkStatus === 1,
            success: query.networkStatus === 7 && Boolean(query.node),
            error: query.networkStatus === 8,
        },
    })),
    branch(
        ({ status }) => status.success,
        withProps(({ query }) => ({
            templates: [blankTemplate, ...query.node.templates],
        }))
    )
);

export default TemplateListContainer;

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import PracticeDialogContainer from './practiceDialogContainer';
import ErrorMessage from '../ErrorMessage';

const PracticeDialog = ({ form, onChangeName, onClose, onSave, practiceDialogOpen }) => (
    <Dialog open={practiceDialogOpen} onClose={onClose} fullWidth>
        <DialogTitle id='form-dialog-title'>Edit Practice</DialogTitle>
        <DialogContent>
            <TextField id='name' margin='normal' label='Name' autoComplete='off' autoFocus fullWidth value={form.name} onChange={onChangeName} />
            {form.error && <ErrorMessage error={form.error} />}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color='primary'>
                Cancel
            </Button>
            <Button onClick={onSave} color='primary'>
                Save
            </Button>
        </DialogActions>
    </Dialog>
);

export default PracticeDialogContainer(PracticeDialog);

import React from 'react';
import { compose, withProps } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import BasePatientPage from './Base';
import FloatingActionButtons from '../../components/FloatingActionButtons';
import { Patient } from '../../components/Patient';
import UpcomingAppointments from './UpcomingAppointments';
import PatientAlerts from '../../components/Patient/PatientAlerts';

const DetailsScreen = ({ classes, history, location, match, patientId, updateOpenFormDialog }) => (
    <BasePatientPage encodedPatientId={patientId} match={match}>
        <Typography variant='subtitle1' className={classes.subheading}>
            Alerts
        </Typography>
        <PatientAlerts />

        <Typography variant='subtitle1' className={classes.subheading}>
            Selected Patient
        </Typography>
        <Patient />

        <Typography variant='subtitle1' className={classes.subheading}>
            Appointments
        </Typography>

        <Paper className={classes.episodesPaper}>
            <UpcomingAppointments />
        </Paper>

        <FloatingActionButtons
            history={history}
            location={location}
            match={match}
            patient
            episode
            textNote
            mediaNote
            formNote
            webDrawingNote
            consentFormList
            onOpenConsentFormList={() => {
                updateOpenFormDialog(true);
            }}
        />
    </BasePatientPage>
);

const styles = {
    subheading: {
        paddingBottom: 8,
        fontWeight: 'bold',
    },
};

export default compose(
    withStyles(styles),
    withProps(({ match }) => ({
        patientId: match.params.patientId,
    }))
)(DetailsScreen);

import React from 'react';
import { compose, withState } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { BLACK } from '../../style/constants';

import ConfirmActionDialog from '../ConfirmActionDialog';

const DuplicateDialog = ({
  classes,
  formTemplateId,
  groupId,
  loading,
  noteId,
  onDuplicate,
  open,
  updateOpen,
}) => (
  <React.Fragment>
    <Tooltip title="Duplicate Note" placement="bottom-start">
      <span>
        <IconButton
          color="inherit"
          aria-label="Duplicate"
          disabled={(!noteId && !groupId && !formTemplateId) || loading}
          onClick={() => updateOpen(true)}
        >
          <FontAwesomeIcon
            icon={faCopy}
            className={classes.copyIcon}
            size="sm"
          />
        </IconButton>
      </span>
    </Tooltip>

    <ConfirmActionDialog
      open={open}
      text={`A duplicate ${formTemplateId ? 'form template' : 'note'} will be
          created`}
      onClose={() => updateOpen(false)}
      confirmText="Duplicate"
      onConfirm={() => {
        onDuplicate();
        updateOpen(false);
      }}
    />
  </React.Fragment>
);

const styles = {
  copyIcon: ({ loading }) => ({
    color: BLACK,
    opacity: loading ? 0.26 : 1,
  }),
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    minWidth: 300,
    padding: 32,
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default compose(
  withStyles(styles),
  withState('open', 'updateOpen', false)
)(DuplicateDialog);

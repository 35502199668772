import React from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import BasePatientPage from './Base';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import MindbodyInvoiceTable from '../MindbodyInvoicePage/MindbodyInvoiceTable';
import { LIST_MINDBODY_INVOICES_FOR_PATIENT } from './gql';

const PatientMindbodyInvoicesPage = ({ match, practiceId }) => {
    const encodedPatientId = match.params.patientId;
    const { data, loading, error } = useQuery(LIST_MINDBODY_INVOICES_FOR_PATIENT, {
        variables: {
            encodedPracticeId: practiceId,
            encodedPatientId,
        },
        fetchPolicy: 'cache-and-network',
    });

    return (
        <BasePatientPage match={match}>
            {loading && <Loading backdrop />}
            {error && <ErrorMessage error={error} />}
            {data && <MindbodyInvoiceTable data={data.fetchMindbodyInvoicesForPatient} hideSearchBar={true} />}
        </BasePatientPage>
    );
};

const styles = {};

export default compose(
    withStyles(styles),
    connect(({ practice }) => ({
        practiceId: practice.id,
    }))
)(PatientMindbodyInvoicesPage);

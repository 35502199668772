import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ERROR } from '../../style/constants';

import Loading from '../Loading';

import './subscriptionCreditCard.css';
import { CREATE_UPDATE_PAYMENT_METHOD_SESSION, GET_CARD_DETAILS, UPDATE_DEFAULT_PAYMENT_METHOD } from './gql';
import { useLocation } from 'react-router-dom';

const SubscriptionCreditCard = ({ classes, isLoading, customerToken, isOwner }) => {
    const [cardDetails, setCardDetails] = useState({});
    const urlLocation = useLocation();

    useEffect(() => {
        checkForCheckoutSessionDeepLink().then();
    }, [customerToken]);

    const [generateUpdatePaymentMethodSession] = useLazyQuery(CREATE_UPDATE_PAYMENT_METHOD_SESSION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.createStripeUpdatePaymentMethodSession) {
                window.location.replace(res.createStripeUpdatePaymentMethodSession.checkoutUrl);
            } else {
                // Something went wrong, we should display a dialog
            }
        },
    });

    const [updateDefaultPaymentMethod] = useLazyQuery(UPDATE_DEFAULT_PAYMENT_METHOD, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.updateDefaultPaymentMethod) {
                // Success
            } else {
                // Something went wrong, we should display a dialog
            }
        },
    });

    const { loading } = useQuery(GET_CARD_DETAILS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.getCreditCardDetails) {
                setCardDetails(res.getCreditCardDetails);
            } else {
                // Something went wrong, we should display a dialog
            }
        },
        variables: {
            customerToken,
        },
    });

    const checkForCheckoutSessionDeepLink = async () => {
        const queryString = urlLocation.search;
        if (queryString.includes('session_id')) {
            const urlSearchParams = new URLSearchParams(urlLocation.search);
            const checkoutSessionToken = urlSearchParams.get('session_id');
            await updateDefaultPaymentMethod({
                variables: {
                    customerToken,
                    checkoutSessionToken,
                },
            });
        }
    };

    const updatePaymentMethod = async () => {
        await generateUpdatePaymentMethodSession({
            variables: {
                customerToken,
            },
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.headerWrapper}>
                <div className={classes.headerLeft}>
                    <Typography variant='h6' className={classes.secondTitle}>
                        Payment Method
                    </Typography>
                </div>
                <div className={classes.headerRight}>
                    {isOwner && (
                        <Button variant='contained' color='secondary' className={classes.button} onClick={updatePaymentMethod}>
                            UPDATE
                        </Button>
                    )}
                </div>
            </div>

            {(isLoading || loading) && <Loading />}

            {!isLoading && isOwner && cardDetails && cardDetails.last4 && (
                <div className='credit-card'>
                    <div className='front'>
                        <div className='strip-bottom'></div>
                        <div className='strip-top'></div>
                        <svg className='logo' viewBox='178.984 156.605 43.548 36.709' width='43.548' height='36.709'>
                            <path
                                className='cls-1'
                                d='M 197.284 193.314 C 183.149 193.242 174.389 177.896 181.516 165.688 C 184.665 160.301 190.332 156.88 196.565 156.605 L 197.284 156.605 C 200.907 156.586 204.456 157.649 207.476 159.655 C 207.915 159.954 208.099 160.509 207.927 161.01 C 207.792 161.547 207.306 161.921 206.754 161.915 C 206.519 161.918 206.289 161.851 206.092 161.725 C 195.902 154.94 182.186 161.734 181.407 173.952 C 181.369 174.536 181.367 175.121 181.393 175.705 C 181.834 183.801 188.249 190.298 196.339 190.84 C 196.656 190.84 196.972 190.867 197.284 190.867 C 200.422 190.872 203.488 189.943 206.097 188.201 C 206.778 187.785 207.666 188.119 207.903 188.879 C 208.075 189.402 207.867 189.976 207.399 190.267 C 204.4 192.257 200.883 193.316 197.284 193.314 Z'
                                style={{ fill: 'rgb(211, 216, 219)' }}></path>
                            <path
                                className='cls-2'
                                d='M 193.662 176.105 C 192.774 176.105 192.217 175.142 192.662 174.371 C 192.868 174.013 193.252 173.792 193.662 173.792 L 221.302 173.792 C 222.192 173.792 222.748 174.756 222.302 175.529 C 222.096 175.887 221.715 176.105 221.302 176.105 L 193.662 176.105 Z'
                                style={{ fill: 'rgb(255, 87, 123)' }}></path>
                            <path
                                className='cls-2'
                                d='M 193.662 168.821 C 192.774 168.821 192.217 167.857 192.662 167.087 C 192.868 166.726 193.252 166.508 193.662 166.508 L 221.302 166.508 C 222.192 166.508 222.748 167.471 222.302 168.242 C 222.096 168.6 221.715 168.821 221.302 168.821 L 193.662 168.821 Z'
                                style={{ fill: 'rgb(255, 87, 123)' }}></path>
                            <path
                                className='cls-2'
                                d='M 193.662 183.393 C 192.774 183.336 192.28 182.338 192.772 181.597 C 192.971 181.296 193.302 181.106 193.662 181.082 L 221.302 181.082 C 222.19 181.026 222.806 181.954 222.408 182.753 C 222.204 183.168 221.765 183.422 221.302 183.393 L 193.662 183.393 Z'
                                style={{ fill: 'rgb(255, 87, 123)' }}></path>
                        </svg>
                        <div className='investor'>Credit Card</div>
                        <div className='chip'>
                            <div className='chip-line'></div>
                            <div className='chip-line'></div>
                            <div className='chip-line'></div>
                            <div className='chip-line'></div>
                            <div className='chip-main'></div>
                        </div>
                        <svg className='wave' viewBox='0 3.71 26.959 38.787' width='26.959' height='38.787' fill='white'>
                            <path d='M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z'></path>
                            <path d='M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z'></path>
                            <path d='M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z'></path>
                        </svg>
                        <div className='card-number'>
                            <div className='section'>XXXX</div>
                            <div className='section'>XXXX</div>
                            <div className='section'>XXXX</div>
                            <div className='section'>{cardDetails.last4}</div>
                        </div>
                        <div className='end'>
                            <span className='end-text'>Expiry:</span>
                            <span className='end-date'>
                                {' '}
                                {cardDetails.expiryMonth.padStart(2, '0')}/{cardDetails.expiryYear.substring(2, 4)}
                            </span>
                        </div>
                        <div className='card-holder'>{cardDetails.name}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    wrapper: {
        // backgroundColor: 'white'
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerLeft: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    noCardWarning: {
        fontSize: 16,
        fontWeight: 'bold',
        color: ERROR,
        paddingTop: 25,
        textAlign: 'center',
    },
    existingCardLine: {
        fontSize: 16,
        paddingTop: 25,
        textAlign: 'left',
    },
    creditCardContainer: {
        paddingTop: 25,
        paddingBottom: 25,
    },
    button: {
        marginLeft: 8,
        marginRight: 8,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user }) => ({
        isOwner: user.roles.includes('OWNER'),
    }))
)(SubscriptionCreditCard);

import { store } from '../data/redux/store';

export default class ServiceBase {
    static BaseUrl() {
        const { REACT_APP_DOTNET_API_URL } = process.env;
        return REACT_APP_DOTNET_API_URL;
    }

    static async GetAuth() {
        const state = store.getState();
        if (state && state.user && state.user.token) {
            return state.user.token;
        }
        return '';
    }

    static async GetRequest(url: string) {
        const token = await this.GetAuth();
        return fetch(`${this.BaseUrl()}/${url}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async PostRequest(url: string, content: any) {
        const token = await this.GetAuth();
        return fetch(`${this.BaseUrl()}/${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: content == null ? null : JSON.stringify(content),
        });
    }
}

import { gql } from '@apollo/client';

export const COMPILE_VACANCIES = gql`
    query compileVacancies($clinicianEncodedId: ID!, $locationId: ID!, $start: String!, $duration: Float!, $offset: String!) {
        compileVacancies(clinicianEncodedId: $clinicianEncodedId, locationId: $locationId, start: $start, duration: $duration, offset: $offset) {
            start
            end
            clinician {
                id
                name
            }
            sortingIndex
            timezone
        }
    }
`;

export const UPSERT_APPT_GUEST = gql`
    mutation upsertApptGuest(
        $appointmentType: ID!
        $clinicianEncodedId: ID!
        $dob: Date!
        $email: String!
        $end: DateTime!
        $locationId: ID
        $newPatient: Boolean
        $notes: String
        $offset: String!
        $practiceEncodedId: ID!
        $firstName: String!
        $lastName: String!
        $phone: String!
        $start: DateTime!
    ) {
        upsertApptGuest(
            appointmentType: $appointmentType
            clinicianEncodedId: $clinicianEncodedId
            dob: $dob
            email: $email
            end: $end
            locationId: $locationId
            newPatient: $newPatient
            notes: $notes
            offset: $offset
            firstName: $firstName
            lastName: $lastName
            phone: $phone
            practiceEncodedId: $practiceEncodedId
            start: $start
        ) {
            appointment {
                id
                patients {
                    id
                    name
                }
                start
                end
                clinician {
                    id
                    name
                }
                notes
                checkedIn
            }
        }
    }
`;

export const FETCH_CLINICIAN = gql`
    query fetchClinician($locationId: ID!) {
        fetchClinician(locationId: $locationId) {
            id
            name
            availability
            blockedOut
            practiceEncodedId
        }
    }
`;

export const UPSERT_PUBLIC_USER = gql`
    mutation upsertPublicUser($id: ID, $name: String, $email: String, $phone: String, $password: String, $patients: [ID], $location: ID) {
        upsertPublicUser(id: $id, name: $name, email: $email, phone: $phone, password: $password, patients: $patients, location: $location) {
            publicUser {
                name
                email
                patients {
                    name
                }
            }
        }
    }
`;

import { gql } from '@apollo/client';

const UPSERT_LOCATION = gql`
  mutation upsertLocation(
    $id: ID!
    $practiceEncodedId: ID
    $mon: [String]
    $tue: [String]
    $wed: [String]
    $thu: [String]
    $fri: [String]
    $sat: [String]
    $sun: [String]
    $publicHolidays: String
  ) {
    upsertLocation(
      id: $id
      practiceEncodedId: $practiceEncodedId
      mon: $mon
      tue: $tue
      wed: $wed
      thu: $thu
      fri: $fri
      sat: $sat
      sun: $sun
      publicHolidays: $publicHolidays
    ) {
      mon
      tue
      wed
      thu
      fri
      sat
      sun
      publicHolidays
    }
  }
`;

export default UPSERT_LOCATION;

import React from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import BasePatientPage from './Base';
import InvoiceItemListCard from '../../components/Patient/InvoiceItemListCard';
import InvoiceListCard from '../../components/Patient/InvoiceListCard';
// import InvoicePaymentListCard from '../../components/Patient/InvoicePaymentListCard';

const PatientPage = ({ match }) => (
  <BasePatientPage match={match}>
    <InvoiceItemListCard />
    <InvoiceListCard />
    {/* TODO: build invoicepaymentslistcard */}
    {/* <InvoicePaymentListCard /> */}
  </BasePatientPage>
);

const styles = {};

export default compose(withStyles(styles))(PatientPage);

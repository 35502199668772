import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, SECONDARY } from '../../style/constants';
import GroupDrawingNotePreviewContainer from './groupDrawingNotePreviewContainer';
import DrawingSVG from '../DrawingSVG';
import EmptyData from '../EmptyData';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import SignedOffInfo from '../SignedOffInfo';

const GroupDrawingNotePreview = ({
    classes,
    createdAt,
    error,
    groupNotesLimited,
    status,
    showCheckBox,
    checkedNotesMatch,
    onCheckNote,
    onLinkPress,
    isAuthor,
    isAuth,
    authorName,
}) => (
    <Paper className={classes.paper}>
        {(() => {
            if (status.loading) return <Loading />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <div
                    role='link'
                    onClick={onLinkPress}
                    className={classes.link}
                    tabIndex={showCheckBox && (!isAuthor || !isAuth) ? null : 0}
                    onKeyDown={null}>
                    <ListItem
                        className={classes.listItem}
                        button
                        disabled={showCheckBox && (!isAuthor || !isAuth)}
                        TouchRippleProps={{ classes: { root: classes.ripple } }}>
                        <Grid item xs>
                            <Grid item xs container direction='row'>
                                {showCheckBox && isAuthor && isAuth && (
                                    <Checkbox
                                        className={classes.checkbox}
                                        classes={{
                                            root: classes.checked,
                                        }}
                                        checked={checkedNotesMatch}
                                        onChange={() => onCheckNote()}
                                    />
                                )}
                                <Grid item xs>
                                    <div className={classes.titleWrapper}>
                                        <FontAwesomeIcon icon={faFileImage} color={SECONDARY} />
                                        <Typography variant='subtitle2' className={classes.title}>
                                            {groupNotesLimited.length !== 0 && groupNotesLimited[0].title}
                                        </Typography>
                                    </div>
                                    <Typography variant='caption'>{Moment(createdAt).format('llll')}</Typography>
                                    <Typography variant='caption'>Created by {authorName}</Typography>
                                </Grid>
                                {groupNotesLimited.length !== 0 && groupNotesLimited[0].isSignedOff && (
                                    <SignedOffInfo signedOffTime={groupNotesLimited.length !== 0 && groupNotesLimited[0].signedOffTime} />
                                )}
                            </Grid>
                            <Divider className={classes.divider} />
                            <div className={classes.content}>
                                {groupNotesLimited.map((note) =>
                                    note.drawingData || get(note, 'template.file.encodedFile') ? (
                                        <DrawingSVG
                                            key={note.id}
                                            className={classes.image}
                                            drawingData={note.drawingData}
                                            templateData={get(note, 'template.file.encodedFile')}
                                        />
                                    ) : (
                                        <div className={classes.emptyData}>
                                            <EmptyData text='No Drawing Input' />
                                        </div>
                                    )
                                )}
                            </div>
                        </Grid>
                    </ListItem>
                </div>
            );
        })()}
    </Paper>
);

const styles = {
    paper: {
        display: 'flex',
        minHeight: 89,
        marginBottom: 36,
    },
    link: {
        flex: 1,
    },
    listItem: {
        flex: 1,
    },
    checkbox: {
        paddingRight: 12,
    },
    checked: {
        '&$checked': {
            color: PRIMARY,
        },
    },
    divider: {
        marginTop: 12,
        marginBottom: 12,
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: 6,
    },
    ripple: {
        color: PRIMARY,
    },
    content: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    image: {
        maxHeight: 256,
        maxWidth: 256,
    },
    emptyData: {
        border: [1, 'solid', 'lightgray'],
        height: 244,
        width: 244,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default compose(GroupDrawingNotePreviewContainer, withStyles(styles))(GroupDrawingNotePreview);

import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/free-solid-svg-icons';

import get from 'lodash/get';
import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from '@material-ui/core/styles';
import { PRIMARY, SECONDARY } from '../../style/constants';

import FormNotePreviewContainer from './formNotePreviewContainer';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import DrawingSectionPreview from '../DrawingSectionPreview';
import TextSectionPreview from '../TextSectionPreview';
import SignedOffInfo from '../SignedOffInfo';
import YesNoSectionPreview from '../YesNoSectionPreview';
import NumberScale10SectionPreview from '../NumberScale10SectionPreview';
import NumberScale100SectionPreview from '../NumberScale100SectionPreview';
import TableSectionPreview from '../TableSectionPreview';
import CheckboxesSectionPreview from '../CheckboxesSectionPreview';
import SpreadsheetSectionPreview from '../SpreadsheetSectionPreview';

const FormNotePreview = ({
    classes,
    createdAt,
    error,
    patientId,
    noteId,
    sections,
    status,
    title,
    signedOffTime,
    isSignedOff,
    showCheckBox,
    checkedNotesMatch,
    onCheckNote,
    onLinkPress,
    isAuthor,
    isAuth,
    authorName,
}) => (
    <Paper className={classes.paper}>
        {(() => {
            if (status.loading) return <Loading />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <div
                    role='link'
                    onClick={onLinkPress}
                    className={classes.link}
                    tabIndex={showCheckBox && (!isAuthor || !isAuth) ? null : 0}
                    onKeyDown={null}>
                    <ListItem
                        className={classes.listItem}
                        button
                        disabled={showCheckBox && (!isAuthor || !isAuth)}
                        TouchRippleProps={{ classes: { root: classes.ripple } }}>
                        <Grid item xs>
                            <Grid item xs container direction='row'>
                                {showCheckBox && isAuthor && isAuth && (
                                    <Checkbox
                                        className={classes.checkbox}
                                        classes={{
                                            root: classes.checked,
                                        }}
                                        checked={checkedNotesMatch}
                                        onChange={() => onCheckNote()}
                                    />
                                )}
                                <Grid item xs>
                                    <div className={classes.titleWrapper}>
                                        <FontAwesomeIcon icon={faPaste} color={SECONDARY} />
                                        <Typography variant='subtitle2' className={classes.title}>
                                            {title}
                                        </Typography>
                                    </div>
                                    <Typography variant='caption'>{Moment(createdAt).format('llll')}</Typography>
                                    <Typography variant='caption'>Created by {authorName}</Typography>
                                </Grid>
                                {isSignedOff && <SignedOffInfo signedOffTime={signedOffTime} />}
                            </Grid>
                            <Divider className={classes.divider} />
                            {(() => {
                                const sectionGroups = _.values(
                                    _.groupBy(sections, (section) => {
                                        const [row] = section.rowIndexes;
                                        return row;
                                    })
                                );
                                return sectionGroups.map((sectionGroup) => (
                                    <Grid key={sectionGroup[0].id} container direction='row'>
                                        {sectionGroup.map((section) => {
                                            switch (section.__typename) {
                                                case 'TextSection':
                                                    return <TextSectionPreview key={section.id} title={section.title} text={section.text} />;
                                                case 'YesNoSection':
                                                    return (
                                                        <YesNoSectionPreview key={section.id} title={section.title} yesNoValue={section.yesNoValue} />
                                                    );
                                                case 'NumberScale10Section':
                                                    return (
                                                        <NumberScale10SectionPreview
                                                            key={section.id}
                                                            title={section.title}
                                                            numberScale10Value={section.numberScale10Value}
                                                        />
                                                    );
                                                case 'NumberScale100Section':
                                                    return (
                                                        <NumberScale100SectionPreview
                                                            key={section.id}
                                                            title={section.title}
                                                            numberScale100Value={section.numberScale100Value}
                                                        />
                                                    );
                                                case 'TableSection':
                                                    return <TableSectionPreview key={section.id} title={section.title} />;
                                                case 'CheckboxesSection':
                                                    return (
                                                        <CheckboxesSectionPreview
                                                            key={section.id}
                                                            title={section.title}
                                                            checkboxesData={section.checkboxesData}
                                                        />
                                                    );
                                                case 'DrawingSection':
                                                    return (
                                                        <DrawingSectionPreview
                                                            key={section.id}
                                                            title={section.title}
                                                            drawingData={section.drawingData}
                                                            templateData={get(section, 'template.file.encodedFile')}
                                                        />
                                                    );
                                                case 'SpreadsheetSection':
                                                    return (
                                                        <SpreadsheetSectionPreview
                                                            section={section}
                                                            key={section.id}
                                                            title={section.title}
                                                            spreadsheetData={section.spreadsheetData}
                                                        />
                                                    );
                                                default:
                                                    return <ErrorMessage text='Unsupported Section Type' />;
                                            }
                                        })}
                                    </Grid>
                                ));
                            })()}
                        </Grid>
                    </ListItem>
                </div>
            );
        })()}
    </Paper>
);

const styles = {
    paper: {
        display: 'flex',
        minHeight: 89,
        marginBottom: 36,
    },
    link: {
        flex: 1,
    },
    listItem: {
        flex: 1,
    },
    checkbox: {
        paddingRight: 12,
    },
    checked: {
        '&$checked': {
            color: PRIMARY,
        },
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: 6,
    },
    divider: {
        marginTop: 12,
        marginBottom: 12,
    },
    ripple: {
        color: PRIMARY,
    },
};

export default compose(FormNotePreviewContainer, withStyles(styles))(FormNotePreview);

import Moment from 'moment';

// converts 12h time to 24h time with MomentJS
const convertToTwentyFourHour = input =>
  Moment(input, 'hh:mm a').format('HH:mm');

const daysOfWeekStandard = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

/**
 * Helper method to determine limits of the calendar schedule view
 * DEVNOTE: NOT CURRENTLY USED
 */
export const determineCalendarLimits = operatingHrs => {
  let startLimit = '13:00';
  let endLimit = '00:00';

  daysOfWeekStandard.forEach(day => {
    if (operatingHrs[day]) {
      const availObj = operatingHrs[day];
      const [start, end] = availObj;

      if (start < startLimit) {
        startLimit = start;
      }
      if (end > endLimit) {
        endLimit = end;
      }
    }
  });
  return [
    convertToTwentyFourHour(startLimit),
    convertToTwentyFourHour(endLimit),
  ];
};

/**
 * Helper method that converts blockedOut properties on each Clinician
 * to businessHours format for FullCalendar
 */
export const generateBusinessHours = (
  availability,
  blockedOut,
  operatingHours
) => {
  // if clinician has no availability, just default to no availability array
  if (!Array.isArray(availability) || !availability.length)
    return [{ startTime: '00:00', endTime: '00:00', daysOfWeek: [1] }];

  const clinicHrs = availability.flatMap((day, index) => {
    // if neither day is in the Clinic's operating hrs, eliminate from array
    if (!operatingHours[day]) return [];
    return {
      id: index + 1,
      day,
      daysOfWeek: [index + 1],
      startTime: convertToTwentyFourHour(operatingHours[day][0]),
      endTime: convertToTwentyFourHour(operatingHours[day][1]),
    };
  });

  // if clinician has no blockedOuts, just default to clinic operating hrs
  if (!Array.isArray(blockedOut) || !blockedOut.length) {
    return clinicHrs.filter(slot => availability.includes(slot.day));
  }

  // then go through and make individual array items corresponding to
  // clinic operating hrs and times when the prac is not blockedOut
  return clinicHrs.flatMap(slot => {
    if (!availability.includes(slot.day)) return [];

    return blockedOut.flatMap((period, index) => {
      if (index === 0) {
        return [
          {
            ...slot,
            endTime: convertToTwentyFourHour(period.start),
          },
          {
            ...slot,
            startTime: convertToTwentyFourHour(period.end),
          },
        ];
      }

      if (index === blockedOut.length - 1) {
        return {
          ...slot,
          startTime: convertToTwentyFourHour(period.end),
        };
      }

      return {
        ...slot,
        startTime: convertToTwentyFourHour(period.start),
        endTime: convertToTwentyFourHour(period.end),
      };
    });
  });
};

import { compose, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  faFile,
  faFileAlt,
  faFileImage,
} from '@fortawesome/free-regular-svg-icons';
import { faPaste } from '@fortawesome/free-solid-svg-icons';

const NotesSidebarItemContainer = compose(
  withRouter,
  withState('isOpenModal', 'setModalState', false),
  connect(({ user }) => ({
    userId: user.id,
    isAuth: user.roles.includes('CLINICIAN') || user.roles.includes('ADMIN'),
  })),
  withProps(({ note, userId }) => ({
    isAuthor: note && note.author && userId === note.author.id,
  })),
  withProps(({ note }) => {
    switch (note.__typename) {
      case 'TextNote':
        return { icon: faFileAlt };
      case 'DrawingNote':
        return { icon: faFileImage };
      case 'FormNote':
        return { icon: faPaste };
      default:
        return { icon: faFile };
    }
  })
);

export default NotesSidebarItemContainer;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useQuery } from '@apollo/client';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BaseSettingsPage from './Base';
import ClinicianAvailabilityTable from '../../components/ClinicianAvailabilityTable';
import PracticeUsers from './PracticeSettings/PracticeUsers';
import PublicHolidays from './PracticeSettings/PublicHolidays';
import { FETCH_LOCATION } from './gql';
import Closures from './PracticeSettings/Closures';

const UsersAvailabilitySettings = ({ classes, isAdmin, isConsentFormSubscription, locationId, practiceEncodedId, user, hasIntegration }) => {
    const [currentHolidays, setCurrentHolidays] = useState([]);
    const [fetchedHolidays, setFetchedHolidays] = useState([]);
    const [currentRegion, setCurrentRegion] = useState('');
    const [currentClosures, setCurrentClosures] = useState([]);
    const [fetchedClosures, setFetchedClosures] = useState([]);

    useQuery(FETCH_LOCATION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            const holidaysResult = _.get(res, 'fetchLocation[0].publicHolidays');
            if (holidaysResult) {
                const { region, regionName, days } = JSON.parse(holidaysResult);
                setCurrentRegion(regionName || region || '');
                setCurrentHolidays(days || []);
                setFetchedHolidays(days || []);
            }
            const closuresResult = _.get(res, 'fetchLocation[0].closures');
            if (closuresResult) {
                const days = JSON.parse(closuresResult);
                setCurrentClosures(days || []);
                setFetchedClosures(days || []);
            }
        },
        variables: {
            id: locationId,
            practiceEncodedId,
        },
    });

    return (
        <BaseSettingsPage>
            <Grid component='main' container justifyContent='center'>
                <Grid className={classes.content} container direction='column' wrap='nowrap' item>
                    <PracticeUsers user={user} isAdmin={isAdmin} isConsentFormSubscription={isConsentFormSubscription} />
                    {!hasIntegration && <ClinicianAvailabilityTable />}
                    {!hasIntegration && (
                        <PublicHolidays
                            currentHolidays={currentHolidays}
                            setCurrentHolidays={setCurrentHolidays}
                            fetchedHolidays={fetchedHolidays}
                            setFetchedHolidays={setFetchedHolidays}
                            currentRegion={currentRegion}
                            setCurrentRegion={setCurrentRegion}
                        />
                    )}
                    {!hasIntegration && (
                        <Closures
                            currentClosures={currentClosures}
                            setCurrentClosures={setCurrentClosures}
                            fetchedClosures={fetchedClosures}
                            setFetchedClosures={setFetchedClosures}
                        />
                    )}
                </Grid>
            </Grid>
        </BaseSettingsPage>
    );
};

const styles = {
    content: {},
};

export default compose(
    withStyles(styles),
    connect(({ user, integration }) => ({
        locationId: user.currentLocationId,
        currentLocationName: user.currentLocationName,
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        isConsentFormSubscription: user.isConsentFormSubscription,
        user,
        hasIntegration: integration.id !== undefined,
    }))
)(UsersAvailabilitySettings);

/* eslint-disable no-unreachable */
import moment from 'moment';
import _ from 'lodash';

export const BOOKING_STATUS = ['Practice Location', 'Appointment Details', 'Select Date & Time', 'Personal Details'];

export const PERSONAL_DETAILS = ['First Name', 'Last Name', 'Phone', 'Email'];

export const processTimes = (unsortedInput) => {
    const input = _.orderBy(unsortedInput, ['start', 'sortingIndex'], ['asc', 'asc']);
    if (!input || !input.length) return [];
    const parsed = input.map((ele) => moment(ele.start).format('ddd D MMM/h:mmA/DMMMYYYY').split('/'));
    // interators
    const output = [];
    let lastDate = parsed[0][2];
    let currentDaySched = [];

    parsed.forEach((item, index) => {
        // if last item, check for duplicates then push the entire CurrentDaySched
        if (index === parsed.length - 1) {
            const duplicateIndex = currentDaySched.findIndex((e) => e.code === input[index].start);

            if (duplicateIndex === -1) {
                currentDaySched.push({
                    desc: item[1],
                    code: input[index].start,
                    slotDetails: input[index],
                });
            }

            // Edge case where the input array only has length 1
            if (parsed.length === 1) {
                output.push({ day: item[0], schedule: currentDaySched });
            } else {
                output.push({ day: parsed[index - 1][0], schedule: currentDaySched });
            }

            // if we're still on the same day, check for duplicates then push vacancy to sched
        } else if (item[2] === lastDate) {
            const duplicateIndex = currentDaySched.findIndex((e) => e.code === input[index].start);

            if (duplicateIndex === -1) {
                currentDaySched.push({
                    desc: item[1],
                    code: input[index].start,
                    slotDetails: input[index],
                });
            }
        } else {
            output.push({ day: parsed[index - 1][0], schedule: currentDaySched });
            // eslint-disable-next-line prefer-destructuring
            lastDate = item[2];
            currentDaySched = [{ desc: item[1], code: input[index].start, slotDetails: input[index] }];
        }
    });
    return output;
};

export const parseTimezone = () => {
    // hardcoded to AEDST for now
    return '+11:00';
    const tz = moment().utcOffset();
    if (tz > 0) {
        return `+${tz / 60}:00`;
    }
    if (tz < 0) {
        return `${tz / 60}:00`;
    }
    return '+00:00';
};

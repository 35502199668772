import React from 'react';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AppBarPublic from '../../components/AppBarPublic';

const VerifyEmailPage = props => {
  const { classes } = props;
  return (
    <AppBarPublic title="Clinik Note">
      <Grid component="main" className={classes.main} container>
        <h2 className={classes.pageTitle}>
          Please confirm your email to complete sign up
        </h2>
        <p className={classes.pageText}>
          We’ve just sent you an email with a confirmation link. <br />
          <br /> If you do not receive a confirmation email, please check your
          spam folder and make sure you have entered a valid email address.
        </p>
      </Grid>
    </AppBarPublic>
  );
};

const styles = {
  main: {
    padding: 64,
    width: '50%',
    margin: '0 auto',
    marginTop: '20px',
    marginBottom: '20px',
  },
  pageTitle: {
    fontSize: 32,
  },
  pageText: {
    fontSize: 20,
  },
};

export default compose(withStyles(styles))(VerifyEmailPage);

import React from 'react';
import { compose } from 'recompose';

import { Paper, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ContentCopy from '@material-ui/icons/FileCopy';

import { connect } from 'react-redux';
import BaseSettingsPage from './Base';
import { featureFlags } from '../../utility';

const ExternalBookings = ({ classes, locationId, practiceEncodedId }) => {
    const formBaseUrl = featureFlags('DEV') ? 'localhost:3001' : process.env.REACT_APP_BASE_URL;

    const str =
        `<iframe 
    src=` +
        `"${formBaseUrl}/booking/${locationId}"` +
        ` 
    title="iFrame1"
    style={{
      height: '900px',
      width: '100%',
      border: 'none',
      overflow: 'hidden',
    }}
  />`;

    return (
        <BaseSettingsPage>
            <div className={classes.header}>
                <Typography variant='subtitle1' className={classes.subheading}>
                    External Online Bookings - Link
                </Typography>

                <Paper className={classes.contentContainer}>
                    <Typography>Copy-paste the following link into an incognito tab to test the booking form:</Typography>

                    <OutlinedInput
                        className={classes.input}
                        id='outlined-adornment-password'
                        type='text'
                        value={`${formBaseUrl}/booking/${practiceEncodedId}`}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${formBaseUrl}/booking/${practiceEncodedId}`);
                                    }}
                                    edge='end'>
                                    <ContentCopy />
                                </IconButton>
                            </InputAdornment>
                        }
                        label='Password'
                    />
                </Paper>
                <Typography variant='subtitle1' className={classes.subheading}>
                    External Online Bookings - Embedded Code
                </Typography>
                <Paper className={classes.contentContainer}>
                    <Typography>Copy-paste the following code in your website to embed the booking form into your web app:</Typography>
                    <pre>
                        <code>{str}</code>
                    </pre>
                    <OutlinedInput
                        className={classes.input}
                        id='outlined-adornment-password'
                        type='text'
                        value={str}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => {
                                        navigator.clipboard.writeText(str);
                                    }}
                                    edge='end'>
                                    <ContentCopy />
                                </IconButton>
                            </InputAdornment>
                        }
                        label='Password'
                    />
                </Paper>
            </div>
        </BaseSettingsPage>
    );
};

const styles = {
    main: {
        display: 'flex',
        justifyContent: 'center',
        height: () => window.innerWidth > 600 && '100%',
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 88,
        paddingRight: 44,
    },
    content: {
        paddingTop: 56,
    },
    contentContainer: {
        padding: 32,
    },
    subheading: {
        paddingTop: 8,
        paddingBottom: 8,
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        user,
        locationId: user.currentLocationId,
        practiceEncodedId: practice.id,
    }))
)(ExternalBookings);

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import Holidays from 'date-holidays';
import _ from 'lodash';
import moment from 'moment';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    Popper,
    TextField,
} from '@material-ui/core';

import Restricted from '../../../components/Restricted';
import { WARM_PINK } from '../../../style/constants';
import { convertName, STATES_ARRAY } from './utils';
import { UPSERT_LOCATION } from './gql';
import { hasPermission } from '../../../utility/permissions';
import CustomTooltip from '../../../components/CustomTooltip';

const PublicHolidays = ({
    classes,
    canEdit,
    locationId,
    practiceEncodedId,
    currentHolidays,
    setCurrentHolidays,
    fetchedHolidays,
    setFetchedHolidays,
    currentRegion,
    setCurrentRegion,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const holidays = new Holidays('AU', 'NSW');

    const [updateLocation] = useMutation(UPSERT_LOCATION, {
        onCompleted: (res) => {
            const holidaysResult = _.get(res, 'upsertLocation.publicHolidays');
            if (holidaysResult) {
                const { region, days } = JSON.parse(holidaysResult);
                setCurrentRegion(region);
                setCurrentHolidays(days);
                setFetchedHolidays(days);
                handleToggle();
            }
        },
    });

    const handleCancelModal = () => {
        setCurrentHolidays(fetchedHolidays);
        setShowDialog(false);
    };

    const handleSelectRegion = (e, values) => {
        if (values) {
            setCurrentRegion(values.id);
            const stateInstance = convertName(values.id);
            holidays.init(stateInstance.countryId, stateInstance.stateId);
            const current = holidays.getHolidays();
            const next = holidays.getHolidays(moment().year() + 1);
            setCurrentHolidays([...current, ...next]);
        }
    };

    const handleToggle = () => {
        if (canEdit) {
            setShowDialog(!showDialog);
        }
    };

    const handleSubmit = () => {
        const publicHolidays = JSON.stringify({
            region: currentRegion,
            regionName: STATES_ARRAY.find((state) => state.id === currentRegion).name,
            days: currentHolidays,
        });

        updateLocation({
            variables: {
                id: locationId,
                practiceEncodedId,
                publicHolidays,
            },
        }).then(() => {
            setShowDialog(false);
        });
    };

    const CustomDropdown = (props) => <Popper {...props} style={{ width: 'fit-content' }} placement='bottom-start' />;

    return (
        <>
            <div className={classes.headerContainer}>
                <Typography variant='subtitle1' className={classes.subheading}>
                    Public Holidays
                    <CustomTooltip title='You can add Public Holidays for your practice location.' />
                </Typography>

                <Restricted isAllowedTo='upsertLocation'>
                    <Button className={classes.redButton} variant='contained' onClick={handleToggle} disabled={!locationId}>
                        Update Public Holidays
                    </Button>
                </Restricted>
            </div>
            <Paper className={classes.paper}>
                {!locationId && (
                    <Typography variant='body1' className={classes.noLocation}>
                        Please assign a location to this practice to add holidays.
                    </Typography>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentHolidays &&
                            !!currentHolidays.length &&
                            currentHolidays.map((holiday) => {
                                if (!holiday.disabled) {
                                    return (
                                        <TableRow hover key={`${holiday.date}-${holiday.name}`} onClick={handleToggle}>
                                            <TableCell>{moment(holiday.date).format('dddd, MMMM Do YYYY')}</TableCell>
                                            <TableCell>{holiday.name}</TableCell>
                                        </TableRow>
                                    );
                                }
                                return null;
                            })}
                    </TableBody>
                </Table>
            </Paper>

            <Dialog open={showDialog} onClose={handleCancelModal} maxWidth='md'>
                <DialogTitle>
                    <Grid container>
                        <Grid item>
                            <Typography variant='h6'>Public Holidays - {currentRegion}</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container>
                        <Grid item>
                            <div>
                                <Autocomplete
                                    id='appt-patient-select'
                                    className={classes.patientField}
                                    filterOptions={(options, state) =>
                                        options.filter((option) =>
                                            `${option.name} ${option.id} ${option.countryId} ${option.country} `
                                                .toLowerCase()
                                                .includes(state.inputValue.toLowerCase())
                                        )
                                    }
                                    groupBy={(option) => option.countryId}
                                    onChange={handleSelectRegion}
                                    options={STATES_ARRAY}
                                    placeholder='Search by Location/State'
                                    PopperComponent={CustomDropdown}
                                    renderInput={(params) => <TextField {...params} margin='normal' autoComplete='off' className={classes.field} />}
                                    renderOption={(params) => (
                                        <div className={classes.dropdownOptions}>
                                            <Typography className={classes.optionDetails}>{params.country}</Typography>
                                            <Typography className={classes.optionDetailsShort}>{params.id}</Typography>
                                            <Typography className={classes.optionDetails}>{params.name}</Typography>
                                        </div>
                                    )}
                                    getOptionLabel={(option) => `${option.countryId} - ${option.name}`}
                                />
                            </div>
                            <Table className={classes.table} aria-label='simple table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Active</TableCell>
                                        <TableCell align='left'>Public Holiday Name</TableCell>
                                        <TableCell align='left'>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!currentHolidays.length &&
                                        currentHolidays.map((item) => {
                                            const { disabled, name, date } = item;

                                            const handleCheck = (e) => {
                                                const clonedHolidays = [...currentHolidays];
                                                const index = clonedHolidays.findIndex((holiday) => holiday.date === date && holiday.name === name);
                                                clonedHolidays[index].disabled = !clonedHolidays[index].disabled;
                                                setCurrentHolidays(clonedHolidays);
                                            };

                                            return (
                                                <TableRow hover onClick={handleCheck} key={`table-row-${date}`}>
                                                    <TableCell>
                                                        <Checkbox checked={!disabled} defaultChecked />
                                                    </TableCell>
                                                    <TableCell>{name}</TableCell>
                                                    <TableCell>{moment(date).format('ddd DD-MM-YYYY')}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color='primary' onClick={handleCancelModal}>
                        Cancel
                    </Button>

                    <Restricted isAllowedTo='upsertLocation'>
                        <Button color='primary' onClick={handleSubmit}>
                            SAVE
                        </Button>
                    </Restricted>
                </DialogActions>
            </Dialog>
        </>
    );
};

const styles = {
    paper: {
        backgroundColor: 'white !important',
        maxHeight: 500,
        overflow: 'auto',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    dialogContent: {
        width: 650,
    },
    subheading: {
        fontWeight: 'bold',
    },
    redButton: {
        height: 36,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
    dropdownOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > *:not(:last-child)': {
            marginRight: 8,
        },
        width: '600px',
        borderBottom: '1px solid #c7c7c7',
    },
    optionDetails: {
        width: 180,
    },
    optionDetailsShort: {
        width: 40,
    },
    noLocation: {
        padding: 16,
        color: WARM_PINK,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        canEdit: hasPermission(user.roles, 'upsertLocation'),
        locationName: user.currentLocationName,
        practiceEncodedId: practice.id,
        userId: user.id,
        locationId: user.currentLocationId,
    }))
)(PublicHolidays);

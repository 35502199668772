import {
  SUBSCRIPTION_SHOW_LOADING,
  SUBSCRIPTION_SET_OPTIONS,
  SUBSCRIPTION_SELECT_PLAN,
  SUBSCRIPTION_SELECT_USERS,
  SUBSCRIPTION_ERROR_MESSAGE,
  SUBSCRIPTION_SHOW_CC,
} from '../actions/subscription';

import { USER_CLEARED } from '../actions/user';

const subscriptionDefaultState = {
  selectedPlan: '', // currently selected plan in UI
  selectedNumberOfUsers: 1,
  showCC: false,
  loading: false,

  plans: null,
  customerToken: '',
  planToken: '',
  numberOfUsers: 1,
  cardLast4: '',
  status: null,
  expiry: null,
  stripePublicKey: '',
  isSubscriptionValid: true,
  isConsentFormSubscription: false,
};

const subscriptionReducer = (state = subscriptionDefaultState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_SHOW_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SUBSCRIPTION_SET_OPTIONS:
      return {
        ...state,
        plans: action.plans,
        ...action.subscription,
      };
    case SUBSCRIPTION_SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.planid,
      };
    case SUBSCRIPTION_SELECT_USERS:
      return {
        ...state,
        selectedNumberOfUsers: action.selectedNumberOfUsers,
      };
    case SUBSCRIPTION_ERROR_MESSAGE:
      return {
        ...state,
        error: action.error,
      };
    case SUBSCRIPTION_SHOW_CC:
      return {
        ...state,
        showCC: action.showCC,
      };
    case USER_CLEARED:
      return subscriptionDefaultState;
    default:
      return state;
  }
};

export default subscriptionReducer;

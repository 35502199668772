import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { Grid, Paper, Typography, TableCell, TableRow, TableHead, TableBody, Table } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import AppBarMenu from '../../components/AppBarMenu';
// import ErrorMessage from '../../components/ErrorMessage';
import { WARM_PINK } from '../../style/constants';
import { REQUEST_REPORT } from './gql';
import { formatMoney } from '../../utility';
import ReportSelector from './ReportSelector';

const REPORTS = [
    "View Today's Transactions",
    "View Today's Appointments",
    'View Cashflow',
    'View Patient Email Addresses',
    'Upcoming Patient Birthdays',
];

const ReportsPage = ({ classes, currentLocationId, encodedPatientId, match, location, practiceId }) => {
    const [report, setReport] = useState({});
    const [breakdown, setBreakdown] = useState([]);
    const [currentBreakdown, setCurrentBreakdown] = useState([]);
    const [start, setStart] = useState(moment().startOf('month'));
    const [end, setEnd] = useState(moment().endOf('month'));
    const [reportType, setReportType] = useState(REPORTS[0]);
    const [clinician, setClinician] = useState();
    const [clinicianOptions, setClinicianOptions] = useState([]);

    useEffect(() => {
        if (clinician === 'All Clinicians') {
            setCurrentBreakdown(breakdown);
        } else {
            const filteredBreakdown = _.filter(breakdown, (item) => item.name === clinician);
            setCurrentBreakdown(filteredBreakdown);
        }
    }, [clinician, breakdown]);

    const { loading } = useQuery(REQUEST_REPORT, {
        variables: {
            locationId: currentLocationId,
            start,
            end,
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.requestReport) {
                setReport(res.requestReport);
                const clinicians = _.uniq(res.requestReport.invoiceItemsArray.map((item) => item.name));
                setClinicianOptions(clinicians);

                const breakdownHash = {};
                // TODO algo to get breakdown of invoiceItems by User's name
                res.requestReport.invoiceItemsArray.forEach((item) => {
                    const codeAsAKey = item.code.toString();
                    if (!_.get(breakdownHash, [item.name, codeAsAKey, 'sum'])) {
                        _.setWith(
                            breakdownHash,
                            [item.name, codeAsAKey],
                            {
                                sum: 0,
                                itemComment: item.comments,
                                itemCode: item.code,
                                name: item.name,
                            },
                            Object
                        );
                    }
                    _.set(breakdownHash, [item.name, codeAsAKey, 'sum'], _.get(breakdownHash, [item.name, codeAsAKey, 'sum']) + item.sum);
                });
                let breakdownArray = _.toArray(breakdownHash);
                breakdownArray = breakdownArray.map((item) => {
                    const items = _.toArray(item);
                    return {
                        name: items[0].name,
                        items,
                    };
                });
                setBreakdown(breakdownArray);
                setCurrentBreakdown(breakdownArray);
            }
            return null;
        },
    });

    return (
        <AppBarMenu>
            <Grid container component='main' className={classes.main}>
                <Grid className={classes.mainContainer} container item xs={12} sm={11} md={10} lg={8} xl={8}>
                    <Grid className={classes.titleContainer}>
                        <Typography className={classes.subheading} variant='subtitle1'>
                            Generate Report
                        </Typography>
                    </Grid>
                    <Grid className={classes.sectionContainer}>
                        <Paper className={classes.generatorContainer}>
                            <ReportSelector
                                clinicianOptions={clinicianOptions}
                                reportType={reportType}
                                reportTypeOptions={REPORTS}
                                setReportType={setReportType}
                                start={start}
                                end={end}
                                setStart={setStart}
                                setEnd={setEnd}
                                setClinician={setClinician}
                            />
                        </Paper>
                    </Grid>

                    <Grid className={classes.sectionContainer}>
                        {!loading && reportType === REPORTS[4] && (
                            <>
                                <Typography className={classes.subheading} variant='subtitle1'>
                                    {`${moment(start).format('DD MMM YY')} - ${moment(end).format('DD MMM YY')} - All Clinicians`}
                                </Typography>
                                <Grid container>
                                    <Paper className={classes.paperContainer}>
                                        <Typography variant='h6'>
                                            {formatMoney(report.sentTotal || 0, location ? location.currency.currency : 'USD')}
                                        </Typography>
                                        <Typography variant='subtitle2'>Sent Invoices</Typography>
                                    </Paper>
                                    <Paper className={classes.paperContainer}>
                                        <Typography variant='h6'>
                                            {formatMoney(report.uncollectedTotal || 0, location ? location.currency.currency : 'USD')}
                                        </Typography>
                                        <Typography variant='subtitle2'>Total Outstanding</Typography>
                                    </Paper>
                                    <Paper className={classes.paperContainer}>
                                        <Typography variant='h6'>
                                            {formatMoney(report.paymentsTotal || 0, location ? location.currency.currency : 'USD')}
                                        </Typography>
                                        <Typography variant='subtitle2'>Payment Collected</Typography>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Grid className={classes.sectionContainer}>
                        {!loading && reportType === REPORTS[4] && (
                            <>
                                <Typography className={classes.subheading} variant='subtitle1'>
                                    {`Clinician/User Breakdown - ${clinician || 'All Clinicians'}`}
                                </Typography>
                                <Paper className={classes.tableContainer}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Clinician</TableCell>
                                                <TableCell>Code</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {_.map(currentBreakdown, (object) => {
                                                return (
                                                    <Fragment key={object.name}>
                                                        {_.map(object.items, (item, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>{item.name}</TableCell>
                                                                    <TableCell>{item.itemCode}</TableCell>
                                                                    <TableCell>{item.itemComment}</TableCell>
                                                                    <TableCell>{formatMoney(item.sum || 0, location.currency.currency)}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </Fragment>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        display: 'flex',
        justifyContent: 'center',
        height: () => window.innerWidth > 600 && '100%',
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 66,
        paddingRight: 22,
    },
    mainContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 12,
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 36,
        width: '100%',
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 20,
        padding: 20,
        width: 240,
        height: 100,
    },
    tableContainer: {
        width: '100%',
        padding: 20,
    },
    generatorContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 20,
        width: '100%',
    },
    subheading: {
        fontWeight: 'bold',
        paddingTop: 36,
        paddingBottom: 8,
    },
    amountText: {
        fontWeight: 'bold',
    },
    warmPinkButton: {
        padding: 10,
        height: 40,
        background: WARM_PINK,
        color: 'white',
        marginLeft: 12,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, patient, practice }) => ({
        currentLocationId: user.currentLocationId,
        practiceId: practice.id,
        location: user.location,
    }))
)(ReportsPage);

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from '@material-ui/core';

import Loading from '../../../components/Loading';
import { FETCH_LOCATIONS } from './gql';
import LocationDetailDialog from './LocationDetailDialog';
import { WARM_PINK } from '../../../style/constants';

const HEADERS = [
  { id: 'name', label: 'Location Name' },
  { id: 'address', label: 'Address' },
  { id: 'country', label: 'Country' },
  { id: 'phone', label: 'Phone' },
  { id: 'email', label: 'Practice Email' },
  { id: 'currency', label: 'Currency' },
];

const PracticeLocations = ({ classes, practiceEncodedId }) => {
  const [practiceLocations, setPracticeLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const { loading } = useQuery(FETCH_LOCATIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.fetchLocation) {
        setPracticeLocations(res.fetchLocation);
      }
    },
    variables: {
      practiceEncodedId,
    },
  });

  const handleAddNewClick = () => {
    handleToggleDialog();
    setCurrentLocation({});
    handleToggleDialog();
  };

  const handleRowClick = row => {
    setCurrentLocation(row);
    handleToggleDialog();
  };

  const handleToggleDialog = () => {
    setShowDialog(!showDialog);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="subtitle1" className={classes.subheading}>
          Practice Locations
        </Typography>

        <Button
          className={classes.redButton}
          variant="contained"
          onClick={handleAddNewClick}
        >
          ADD NEW
        </Button>
      </div>
      <Paper className={classes.submissionsPaper}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
        >
          {(() => {
            if (loading) return <Loading />;
            if (!practiceLocations.length) return <div>No Locations found</div>;

            return (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {HEADERS.map(column => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {practiceLocations.map(row => (
                    <TableRow
                      className={classes.clickableRow}
                      hover
                      key={row.id}
                      name={row.id}
                      onClick={() => handleRowClick(row)}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.address || '-'}</TableCell>
                      <TableCell>{row.country || '-'}</TableCell>
                      <TableCell>{row.phone || '-'}</TableCell>
                      <TableCell>{row.email || '-'}</TableCell>
                      <TableCell>{row.currency.currency || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className={classes.buttonContainer} colSpan={6}>
                      Practice Location Count: {practiceLocations.length}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            );
          })()}
        </Grid>
      </Paper>
      <LocationDetailDialog
        currentLocation={currentLocation}
        handleClose={handleToggleDialog}
        showDialog={showDialog}
      />
    </>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  subheading: {
    fontWeight: 'bold',
  },
  field: {
    width: 240,
  },
  redButton: {
    height: 36,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    practiceEncodedId: practice.id,
  }))
)(PracticeLocations);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import SearchBar from './SearchBar';
import { formatMoney } from '../../utility';

const columnData = [
  { id: 'created_at', label: 'Date' },
  { id: 'id', label: 'Invoice #' },
  { id: 'name', label: 'Patient Name' },
  { id: 'status', label: 'Status' },
  { id: 'dateDue', label: 'Due Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'balanceDue', label: 'Balance Due' },
  // { id: 'payment', label: '' },
];

const InvoicingTable = ({ classes, data, user }) => {
  const [filteredData, setFilteredData] = useState(data);

  // eslint-disable-next-line no-unused-vars
  const handleSearch = e => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = data.filter(
      invoice =>
        invoice.id.toLowerCase().includes(searchTerm) ||
        invoice.name.toLowerCase().includes(searchTerm) ||
        invoice.status.toLowerCase().includes(searchTerm) ||
        invoice.dateDue.toLowerCase().includes(searchTerm) ||
        invoice.amount.toLowerCase().includes(searchTerm) ||
        invoice.balanceDue.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const onSelectInvoice = invoiceId => {
    window.location.href = `invoices/${invoiceId}`;
  };

  return (
    <div className={classes.tableWrapper}>
      <TableContainer className={classes.container} component={Paper}>
        <SearchBar data={data} setFilteredData={setFilteredData} />
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {columnData.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map(invoice => (
              <TableRow
                className={classes.tableRow}
                hover
                key={invoice.id}
                onClick={() => onSelectInvoice(invoice.id)}
              >
                <TableCell>{invoice.createdAt}</TableCell>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{_.get(invoice, 'patient.name')}</TableCell>
                <TableCell>{invoice.status}</TableCell>
                <TableCell>{invoice.dateDue}</TableCell>
                <TableCell>
                  {formatMoney(invoice.amount, user.location.currency.currency)}
                </TableCell>
                <TableCell>
                  {formatMoney(
                    invoice.balanceDue,
                    user.location.currency.currency
                  )}
                </TableCell>
                {/* <TableCell>+ Payment</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const styles = {
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeaderCheckbox: {
    padding: 16,
  },
  tableRow: {
    cursor: 'pointer',
  },
};

export default compose(
  withStyles(styles),
  connect(({ user }) => ({ user }))
)(InvoicingTable);

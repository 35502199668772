import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import Description from '@material-ui/icons/Description';
import Image from '@material-ui/icons/Image';
import Ballot from '@material-ui/icons/Ballot';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import TableChartIcon from '@material-ui/icons/TableChart';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, SECONDARY_BLACK } from '../../style/constants';
import { sectionNewType } from '../../data/redux/actions/section';
import { filterSectionsByRowAndPage } from '../../utility';

const SectionActionBar = ({
  classes,
  onNewSection,
  rowNumber,
  disableConditions,
}) => (
  <Paper className={classes.paper}>
    <Tooltip title="1-10 Scale" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('NUMBERSCALE10', rowNumber)}
        disabled={disableConditions}
        aria-label="Number Scale 1-10 Section"
        classes={{ root: classes.icon }}
      >
        <span style={{ fontSize: '12px' }}>1-10</span>
      </IconButton>
    </Tooltip>

    <Tooltip title="0-100 Scale" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('NUMBERSCALE100', rowNumber)}
        disabled={disableConditions}
        aria-label="Number Scale 0-100 Section"
        classes={{ root: classes.icon }}
      >
        <span style={{ fontSize: '12px' }}>0-100</span>
      </IconButton>
    </Tooltip>

    <Tooltip title="Spreadsheet" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('SPREADSHEET', rowNumber)}
        disabled={disableConditions}
        aria-label="Table"
        classes={{ root: classes.icon }}
      >
        <TableChartIcon />
      </IconButton>
    </Tooltip>

    <Tooltip title="Yes/No" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('YESNO', rowNumber)}
        disabled={disableConditions}
        aria-label="Yes/No Section"
        classes={{ root: classes.icon }}
      >
        <ToggleOffIcon />
      </IconButton>
    </Tooltip>

    <Tooltip title="Multiple Check Box" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('CHECKBOXES', rowNumber)}
        disabled={disableConditions}
        aria-label="Checkboxes Section"
        classes={{ root: classes.icon }}
      >
        <Ballot />
      </IconButton>
    </Tooltip>

    <Tooltip title="Text Field" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('TEXT', rowNumber)}
        disabled={disableConditions}
        aria-label="Text Section"
        classes={{ root: classes.icon }}
      >
        <Description />
      </IconButton>
    </Tooltip>

    <Tooltip title="Add image" placement="bottom-start">
      <IconButton
        onClick={() => onNewSection('DRAWING', rowNumber)}
        disabled={disableConditions}
        aria-label="Drawing Section"
        classes={{ root: classes.icon }}
      >
        <Image />
      </IconButton>
    </Tooltip>
  </Paper>
);

const styles = {
  paper: ({ direction }) => ({
    color: SECONDARY_BLACK,
    display: direction === 'column' ? null : 'flex',
    flexDirection: direction || 'row',
    justifyContent: direction === 'column' ? 'flex-start' : 'space-evenly',
    alignItems: 'center',
    maxWidth: '100%',
    flexWrap: 'wrap',
    marginTop: direction === 'column' ? '30px' : '0px',
  }),
  icon: ({ direction }) => ({
    color: PRIMARY,
    maxWidth: direction === 'column' ? '33.33%' : '100%',
  }),
};

export default compose(
  withStyles(styles),
  connect(({ section }) => ({
    newType: section.newType,
  })),
  withProps(({ loading, newType, sections, rowNumber, disabled }) => ({
    disableConditions:
      Boolean(newType) ||
      filterSectionsByRowAndPage(sections, rowNumber).length >= 3 ||
      loading ||
      disabled,
  })),
  withHandlers({
    onNewSection: ({ dispatch }) => (sectionType, rowNumber) => {
      dispatch(sectionNewType(sectionType, rowNumber));
    },
  })
)(SectionActionBar);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UPSERT_LOCATION from './gql';
import { hoursArray, operatingDays } from './constants';

const OperatingHoursDialog = ({
  classes,
  existingOperatingHours,
  locationId,
  onCloseDialog,
  practiceEncodedId,
  showDialog,
}) => {
  const [invalidInput, setInvalidInput] = useState([]);
  const [operatingHours, setOperatingHours] = useState({});
  const [isUniqueInput, setIsUniqueInput] = useState(false);
  const HOURS_OPTIONS = hoursArray();

  const [updateLocation] = useMutation(UPSERT_LOCATION, {
    onCompleted: res => {
      if (res.upsertLocation) {
        onCloseDialog();
      }
    },
    refetchQueries: ['fetchLocation'],
    variables: {
      ...operatingHours,
      id: locationId,
      practiceEncodedId,
    },
  });

  const validateChanges = () => {
    const invalidDetected = [];

    Object.keys(operatingHours).forEach(key => {
      const startEnd = operatingHours[key];
      if (startEnd) {
        const start = startEnd[0];
        const end = startEnd[1];

        if (start === end) {
          // If both times are the same, it is invalid (except when they're both null)
          if (start !== null) {
            invalidDetected.push(key);
          }
          // If either value is null
        } else if (!start || !end) {
          invalidDetected.push(key);
          // If start is after end
        } else {
          const indexOfStart = HOURS_OPTIONS.findIndex(item => item === start);
          const indexOfEnd = HOURS_OPTIONS.findIndex(item => item === end);
          if (indexOfEnd < indexOfStart) {
            invalidDetected.push(key);
          }
        }
      }
    });

    return setInvalidInput(invalidDetected);
  };

  const handleSubmit = () => {
    updateLocation();
  };

  useEffect(() => {
    validateChanges();
  }, [operatingHours]);

  useEffect(() => {
    const operatingHrsOnly = _.pick(
      existingOperatingHours,
      operatingDays.map(day => day.id)
    );
    setOperatingHours(operatingHrsOnly);
  }, [existingOperatingHours]);

  return (
    <Dialog
      open={showDialog}
      onClose={onCloseDialog}
      maxWidth="lg"
      className={classes.modal}
    >
      <DialogTitle>Operating Hours</DialogTitle>

      <DialogContent>
        {operatingDays.map(day => (
          <Grid
            container
            item
            xs
            className={classes.fieldContainer}
            key={`grid-container-${day.id}`}
          >
            <Typography className={classes.title} variant="body2">
              {day.label}
            </Typography>

            <Select
              className={classes.field}
              value={_.get(operatingHours, [day.id, 0], '')}
              onChange={event => {
                const clonedOpHours = { ...operatingHours };
                if (clonedOpHours[day.id] && clonedOpHours[day.id].length > 0) {
                  clonedOpHours[day.id] = [
                    event.target.value,
                    clonedOpHours[day.id][1],
                  ];
                } else {
                  clonedOpHours[day.id] = [event.target.value, undefined];
                }
                setOperatingHours(clonedOpHours);
                setIsUniqueInput(true);
              }}
              error={_.includes(invalidInput, day.id)}
            >
              {HOURS_OPTIONS.map(time => (
                <MenuItem value={time} key={`menuitem-time-a-${time}`}>
                  {time}
                </MenuItem>
              ))}
            </Select>

            <div>-</div>

            <Select
              className={classes.field}
              value={_.get(operatingHours, [day.id, 1], '')}
              onChange={event => {
                const clonedOpHours = { ...operatingHours };
                if (clonedOpHours[day.id] && clonedOpHours[day.id].length > 0) {
                  clonedOpHours[day.id] = [
                    clonedOpHours[day.id][0],
                    event.target.value,
                  ];
                } else {
                  clonedOpHours[day.id] = [undefined, event.target.value];
                }
                setOperatingHours(clonedOpHours);
                setIsUniqueInput(true);
              }}
              error={_.includes(invalidInput, day.id)}
            >
              {HOURS_OPTIONS.map(time => (
                <MenuItem value={time} key={`menuitem-time-b-${time}`}>
                  {time}
                </MenuItem>
              ))}
            </Select>

            {_.get(operatingHours, [day.id, 0], false) ? (
              <div>
                <Tooltip title="Delete these hours">
                  <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    onClick={() => {
                      const clonedOpHours = { ...operatingHours };
                      clonedOpHours[day.id] = null;
                      setOperatingHours(clonedOpHours);
                      setIsUniqueInput(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Copy these hours to all days">
                  <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    onClick={() => {
                      const clonedOpHours = { ...operatingHours };
                      operatingDays.map(opDay => {
                        clonedOpHours[opDay.id] = operatingHours[day.id];
                      });
                      setOperatingHours(clonedOpHours);
                      setIsUniqueInput(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </Tooltip> */}
              </div>
            ) : (
              <div style={{ width: 45, height: 48 }} />
            )}
            {/* <Checkbox
              checked={!!_.get(operatingHours, [day.id, 0], false)}
              onChange={() => {
                const clonedOpHours = { ...operatingHours };
                clonedOpHours[day.id] = null;
                setOperatingHours(clonedOpHours);
                setIsUniqueInput(true);
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            /> */}
          </Grid>
        ))}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onCloseDialog}>
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={
            invalidInput.length > 0 ||
            _.isEqual(operatingHours, {}) ||
            !isUniqueInput
          }
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    padding: 32,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  fieldContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 540,
  },
  title: {
    width: 100,
  },
  field: {
    width: 120,
  },
};

export default compose(
  connect(({ practice, user }) => ({
    locationId: user.currentLocationId,
    practiceEncodedId: practice.id,
  })),
  withStyles(styles)
)(OperatingHoursDialog);

import { gql } from '@apollo/client';

export const PATIENT_LIST_QUERY = gql`
    query patientListQuery($locationId: ID!, $practiceId: ID!, $limit: Int, $offset: Int, $searchText: String, $includeArchived: Boolean) {
        listAppointmentTypes(locationId: $locationId) {
            id
            code
            desc
            name
            color
            price
            duration
        }
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                patients(limit: $limit, offset: $offset, searchText: $searchText, includeArchived: $includeArchived) {
                    nodes {
                        name
                        email
                        id
                        phone
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
        }
    }
`;

// Used in same endpoint as above
// export const LIST_APPOINTMENT_TYPES = gql`
//   query listAppointmentTypes($locationId: ID!) {
//     listAppointmentTypes(locationId: $locationId) {
//       id
//       code
//       desc
//       name
//       color
//       price
//       duration
//     }
//   }
// `;

export const UPSERT_APPOINTMENT = gql`
    mutation upsertAppointment(
        $id: ID
        $appointmentType: ID
        $patientIds: [ID]
        $start: DateTime
        $end: DateTime
        $clinicianEncodedId: ID!
        $locationId: ID
        $notes: String
        $checkedIn: [String]
        $invoiced: [String]
        $sendNotification: Boolean
    ) {
        upsertAppointment(
            id: $id
            appointmentType: $appointmentType
            patients: $patientIds
            start: $start
            end: $end
            clinicianEncodedId: $clinicianEncodedId
            locationId: $locationId
            notes: $notes
            checkedIn: $checkedIn
            invoiced: $invoiced
            sendNotification: $sendNotification
        ) {
            appointment {
                id
                appointmentType {
                    id
                    name
                    color
                    code
                    duration
                    price
                }
                patients {
                    id
                    name
                    email
                    phone
                    dob
                }
                start
                end
                notes
                clinician {
                    id
                    name
                }
                checkedIn
                invoiced
                newPatient
                locationId
            }
        }
    }
`;

export const UPSERT_APPT_PLUS_PATIENT = gql`
    mutation upsertApptPlusPatient(
        $id: ID
        $appointmentType: ID
        $locationId: ID
        $practiceEncodedId: ID!
        $start: DateTime!
        $end: DateTime
        $clinicianEncodedId: ID!
        $notes: String
        $patients: [ID]
        $firstNames: [String]!
        $lastNames: [String]!
        $email: [String]
        $phone: [String]
        $dob: [Date]!
    ) {
        upsertApptPlusPatient(
            id: $id
            appointmentType: $appointmentType
            locationId: $locationId
            practiceEncodedId: $practiceEncodedId
            start: $start
            end: $end
            clinicianEncodedId: $clinicianEncodedId
            notes: $notes
            patients: $patients
            firstNames: $firstNames
            lastNames: $lastNames
            email: $email
            phone: $phone
            dob: $dob
        ) {
            appointment {
                id
                patients {
                    id
                    name
                }
                start
                end
                clinician {
                    id
                    name
                }
                notes
                checkedIn
            }
        }
    }
`;

export const UPSERT_EVENT = gql`
    mutation upsertEvent($id: ID, $title: String!, $start: DateTime!, $end: DateTime!, $locationId: ID!, $clinicianEncodedId: ID!, $notes: String) {
        upsertEvent(
            id: $id
            title: $title
            start: $start
            end: $end
            locationId: $locationId
            clinicianEncodedId: $clinicianEncodedId
            notes: $notes
        ) {
            id
            title
            start
            end
            notes
            clinician {
                id
                name
            }
        }
    }
`;

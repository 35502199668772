import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
// or
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';

const SpreadsheetSectionPreview = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onChangeCheckbox,
  onFocus,
  onSave,
  title,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  spreadsheetData,
  section,
}) => {
  let preparedSpreadsheetData = spreadsheetData;
  if (
    !(
      typeof section.spreadsheetData === 'object' &&
      section.spreadsheetData !== null &&
      !Array.isArray(section.spreadsheetData)
    )
  ) {
    preparedSpreadsheetData = JSON.parse(section.spreadsheetData);
  }
  let sheets = [];

  if (
    preparedSpreadsheetData &&
    preparedSpreadsheetData.jsonObject &&
    preparedSpreadsheetData.jsonObject.Workbook &&
    preparedSpreadsheetData.jsonObject.Workbook.sheets[0] &&
    preparedSpreadsheetData.jsonObject.Workbook.sheets[0].rows
  ) {
    preparedSpreadsheetData.jsonObject.Workbook.sheets.map(sheet => {
      if (sheet && sheet.rows) {
        let maxCol = 0;
        sheet.rows.map(row => {
          if (row && row.cells && row.cells.length > maxCol) {
            maxCol = row.cells.length;
          }
          return '';
        });
        let rows = sheet.rows.map((row, key) => {
          return (
            <tr className={classes.row} key={key}>
              {Array.from(Array(maxCol).keys()).map(cellKey => {
                return (
                  <td style={styles.description} key={cellKey}>
                    {row && row.cells && row.cells[cellKey]
                      ? row.cells[cellKey].value
                      : ' '}
                  </td>
                );
              })}
            </tr>
          );
        });
        sheets.push({
          rows,
          name: sheet.name,
        });
      }
      return '';
    });
  }

  return (
    <Grid container direction="column">
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {sheets.map(sheet => {
        return (
          <div>
            <Typography>{sheet.name}</Typography>
            <Table className={classes.tableContainer}>
              <Fragment>{sheet.rows}</Fragment>
            </Table>
            <br />
          </div>
        );
      })}
    </Grid>
  );
};

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'scroll',
  },
  row: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'start',
    width: '100%',
    border: '1px solid #ccc',
    borderBottom: 0,
    '&:last-child': {
      borderBottom: '1px solid #ccc',
    },
  },
  description: {
    width: '50px',
    wordBreak: 'break-all',
    borderRight: '1px solid #ccc',
  },
};

SpreadsheetSectionPreview.defaultProps = {
  isSignedOff: undefined,
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles)
)(SpreadsheetSectionPreview);

import React from 'react';

import { LinearProgress, CircularProgress, Backdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Loading = ({ classes, overlay, linear, transparent, backdrop }) => {
  if (linear)
    return (
      <div className={classes.linearWrapper}>
        <LinearProgress className={classes.linear} />
      </div>
    );
  if (overlay)
    return (
      <div className={classes.overlay}>
        <CircularProgress />
      </div>
    );
  if (backdrop)
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress />
      </Backdrop>
    );
  if (transparent)
    return (
      <div className={classes.transparent}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.default}>
      <CircularProgress />
    </div>
  );
};

const styles = {
  overlay: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999999,
  },
  transparent: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999999,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  default: {
    flexGrow: 1,
    display: 'flex',
    height: ({ height }) => height,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  linearWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 999999,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  linear: {
    position: 'absolute',
    height: 4,
    width: '100%',
    zIndex: 999999,
    top: 0,
    left: 0,
  },
  backdrop: {
    color: '#fff',
    zIndex: 9999,
  },
};

export default withStyles(styles)(Loading);

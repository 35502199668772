import React from 'react';
import { compose } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { BLACK } from '../../style/constants';

const CustomTooltip = ({ classes, hidden, title }) => {
  if (hidden) return null;
  return (
    <Tooltip
      className={classes.tooltip}
      placement="bottom"
      title={<Typography>{title}</Typography>}
    >
      <IconButton
        aria-label="More Info"
        className={classes.button}
        style={{ backgroundColor: 'transparent' }}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={classes.infoIcon}
          size="sm"
        />
      </IconButton>
    </Tooltip>
  );
};

const styles = {
  button: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
  },
  infoIcon: {
    color: BLACK,
    opacity: 1,
  },
  tooltip: {
    fontSize: '1rem',
  },
};

export default compose(withStyles(styles))(CustomTooltip);

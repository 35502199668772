import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { compose } from 'recompose';
import { gql, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { SECONDARY } from '../../style/constants';

import AppBarPublic from '../../components/AppBarPublic';
import Loading from '../../components/Loading';

const VERIFY_SIGNUP = gql`
  mutation verifySignup($id: String!) {
    verifySignUp(id: $id) {
      id
    }
  }
`;

const successContent = classes => (
  <Grid component="nav" container wrap="nowrap" direction="column">
    <div className={classes.pageTitle}>Account verified</div>
    <p className={classes.pageText}>
      Your account is now activated. Click&nbsp;<a href="/">here</a>&nbsp;to
      login the system.
    </p>
  </Grid>
);

const failContent = classes => (
  <Grid component="nav" container wrap="nowrap" direction="column">
    <div className={classes.pageTitle}>Account not verified</div>
    <p className={classes.pageText}>Your account could not be activated.</p>
    <p className={classes.pageText}>
      This link may have already been used or expired.
    </p>
    <p className={classes.pageText}>
      Click&nbsp;<a href="/signup">here</a>&nbsp;to signup again.
    </p>
  </Grid>
);

const SignupVerifyPage = ({ classes }) => {
  const [inflight, setInflight] = useState(true);
  const [mutateExecutedState, updatemutateExecutedState] = useState(false);
  const { code } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [verifySignUp, { error }] = useMutation(VERIFY_SIGNUP, {
    onCompleted: result => {
      setInflight(false);
      const verifySuccess =
        result && result.verifySignUp && result.verifySignUp.id;
      if (verifySuccess) {
        updatemutateExecutedState(true);
      } else {
        updatemutateExecutedState(false);
      }
    },
  });

  useEffect(() => {
    verifySignUp({
      variables: {
        id: code,
      },
    });
    if (!error) {
      updatemutateExecutedState(false);
    }
  }, []);

  if (inflight) {
    return <Loading />;
  }

  return (
    <AppBarPublic title="Clinik Note">
      <Grid component="main" className={classes.main} container>
        {mutateExecutedState ? successContent(classes) : failContent(classes)}
      </Grid>
    </AppBarPublic>
  );
};

const styles = {
  main: {
    height: '100%',
    paddingTop: 64,
  },
  pageTitle: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 32,
    color: SECONDARY,
  },
  pageText: {
    display: 'flex',
    fontSize: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '5px 0',
  },
};

export default compose(withStyles(styles))(SignupVerifyPage);

import React from 'react';
import { compose } from 'recompose';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import isEmpty from 'lodash/isEmpty';

import FormTemplateListContainer from './formTemplateListContainer';
import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';
import FormTemplateListItem from '../FormTemplateListItem';

const FormTemplateList = ({ atSelectFormTemplate, classes, status, error, formTemplates, formNotePage }) => (
    <Paper>
        {(() => {
            if (status.loading) return <Loading height={73} />;
            if (status.error) return <ErrorMessage error={error} />;
            if (!formTemplates || formTemplates.length === 0)
                return <EmptyData flex={1} text='This practice currently has no form templates available' />;

            return (
                <List disablePadding>
                    {formTemplates &&
                        formTemplates.map((template) => {
                            if (atSelectFormTemplate && isEmpty(template.sections)) return null;

                            return <FormTemplateListItem key={template.id} template={template} formNotePage={formNotePage} />;
                        })}
                </List>
            );
        })()}
    </Paper>
);

const styles = {};

export default compose(withStyles(styles), FormTemplateListContainer)(FormTemplateList);

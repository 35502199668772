import React from 'react';
import { compose } from 'recompose';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenu from '../../components/AppBarMenu';
import NewInvoiceForm from './NewInvoiceForm';

const NewInvoicePage = ({ classes }) => {
  return (
    <AppBarMenu>
      <Grid
        component="main"
        className={classes.main}
        container
        justifyContent="center"
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          item
          xs={12}
          sm={11}
          md={10}
          lg={8}
          xl={6}
        >
          <div className={classes.leftColumn}>
            <NewInvoiceForm />
          </div>
        </Grid>
      </Grid>
    </AppBarMenu>
  );
};

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: () => window.innerWidth > 600 && '100%',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 88,
    paddingRight: 44,
  },
};

export default compose(withStyles(styles))(NewInvoicePage);

import { branch, compose, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import pull from 'lodash/pull';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';

import { practiceSearched } from '../../data/redux/actions/practice';

export const categoryListQuery = gql`
  query categoryList(
    $practiceId: ID!
    $searchText: String
    $limit: Int
    $offset: Int
  ) {
    node(id: $practiceId) {
      __typename
      id
      ... on Practice {
        categories(searchText: $searchText, limit: $limit, offset: $offset) {
          nodes {
            id
            name
            deletedAt
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
`;

const upsertCategoryMutation = gql`
  mutation upsertCategory(
    $categoryId: ID
    $practiceId: ID!
    $name: String
    $deletedAt: DateTime
  ) {
    upsertCategory(
      id: $categoryId
      practice: $practiceId
      name: $name
      deletedAt: $deletedAt
    ) {
      category {
        id
        name
        deletedAt
      }
    }
  }
`;

const initialDialog = {
  open: false,
  itemToDelete: undefined,
};

const CategoryListContainer = compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
    searchText: practice.searchText,
  })),
  withState('dialog', 'updateDialog', initialDialog),
  graphql(categoryListQuery, {
    name: 'query',
    options: ({ practiceId, searchText }) => ({
      variables: {
        practiceId,
        offset: 0,
        limit: 20,
        searchText,
      },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withProps(({ query }) => ({
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      setVariables: query.networkStatus === 2,
      fetching: query.networkStatus === 3,
      refetching: query.networkStatus === 4,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    props => props.status.success,
    withProps(({ query }) => ({
      categories: query.node.categories.nodes,
      hasNextPage: query.node.categories.pageInfo.hasNextPage,
    }))
  ),
  graphql(upsertCategoryMutation),
  withHandlers({
    onListEnd: ({ status, query }) => () => {
      if (!status.fetching && !status.refetching) {
        query.fetchMore({
          variables: {
            offset: query.node.categories.nodes.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            node: {
              ...previousResult.node,
              categories: {
                ...previousResult.node.categories,
                nodes: uniqBy(
                  [
                    ...previousResult.node.categories.nodes,
                    ...fetchMoreResult.node.categories.nodes,
                  ],
                  item => item.id
                ),
                pageInfo: {
                  ...previousResult.node.categories.pageInfo,
                  ...fetchMoreResult.node.categories.pageInfo,
                },
              },
            },
          }),
        });
      }
    },
    onArchiveCategory: ({ mutate, practiceId }) => async categoryId => {
      try {
        await mutate({
          variables: {
            categoryId,
            practiceId,
            deletedAt: new Date(),
          },
          refetchQueries: [
            {
              query: categoryListQuery,
              variables: { practiceId },
            },
          ],
        });
      } catch (error) {
        console.log('Error: ', error); // eslint-disable-line
      }
    },
    onCreateCategory: ({
      dispatch,
      mutate,
      practiceId,
      searchText,
      tempCategories,
      updateTempCategories,
    }) => async () => {
      try {
        const response = await mutate({
          variables: {
            practiceId,
            name: searchText,
          },
          refetchQueries: [
            {
              query: categoryListQuery,
              variables: { practiceId },
            },
          ],
        });
        const selectedCategoryId = response.data.upsertCategory.category.id;
        const matching = includes(tempCategories, selectedCategoryId);
        const newCategories = tempCategories.map(categoryId => categoryId);

        if (!matching) {
          newCategories.push(selectedCategoryId);
        } else {
          pull(newCategories, selectedCategoryId);
        }

        updateTempCategories(newCategories);
        dispatch(practiceSearched(undefined));
      } catch (error) {
        console.log('Error: ', error); // eslint-disable-line
      }
    },
    onRefetch: ({ query }) => () => query.refetch(),
    onSelectCategory: ({
      tempCategories,
      updateTempCategories,
    }) => selectedCategoryId => {
      const matching = includes(tempCategories, selectedCategoryId);
      const newCategories =
        tempCategories && tempCategories.length
          ? tempCategories.map(categoryId => categoryId)
          : [];

      if (!matching) {
        newCategories.push(selectedCategoryId);
      } else {
        pull(newCategories, selectedCategoryId);
      }

      updateTempCategories(newCategories);
    },
  })
);

export default CategoryListContainer;

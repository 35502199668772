import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, WHITE } from '../../style/constants';

const AppBarPublic = ({ children, classes, title }) => (
  <React.Fragment>
    <AppBar component="nav" classes={{ root: classes.appBar }}>
      <Toolbar>
        <Link
          variant="h6"
          underline="none"
          component={RouterLink}
          to="/"
          className={classes.title}
        >
          {title}
        </Link>
      </Toolbar>
    </AppBar>
    {children}
  </React.Fragment>
);

const styles = {
  appBar: {
    backgroundColor: PRIMARY,
  },
  title: {
    marginLeft: 'auto',
    marginRight: 'auto',
    color: WHITE,
  },
};

export default withStyles(styles)(AppBarPublic);

export const SETTINGS_TAB_OPTIONS = [
    {
        id: 'practice',
        title: 'My Practice',
        route: '/settings/practice',
    },
    {
        id: 'users-availability',
        title: 'Users & Availability',
        route: '/settings/users-availability',
        hideForIntegrations: false,
        adminOnly: true,
    },
    {
        id: 'invoices',
        title: 'Invoices',
        route: '/settings/invoicing',
        hideForIntegrations: true,
    },
    {
        id: 'appointment-types',
        title: 'Appointment Types',
        route: '/settings/appointment-types',
        hideForIntegrations: false,
        adminOnly: false,
    },
    {
        id: 'inventory',
        title: 'Inventory & Services',
        route: '/settings/inventory',
        hideForIntegrations: true,
        adminOnly: false,
    },
    {
        id: 'appointments',
        title: 'External Bookings',
        route: '/settings/external-bookings',
        hideForIntegrations: true,
        adminOnly: false,
    },
    {
        id: 'integrations',
        title: 'Integrations',
        route: '/settings/integrations',
        hideForIntegrations: false,
        adminOnly: false,
    },
];

export const operatingDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const convertToDisplay = (arrayOfHours) => (arrayOfHours && arrayOfHours[0] ? `${arrayOfHours[0]} - ${arrayOfHours[1]}` : 'CLOSED');

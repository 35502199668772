import Moment from 'moment';

const OPERATING_INTERVALS_MINUTES = 30;

const thirtyMinuteIntervalArray = (interval) =>
    Array(48)
        .fill()
        .map((v, index) => interval * index);

export const hoursArray = (interval = OPERATING_INTERVALS_MINUTES) =>
    thirtyMinuteIntervalArray(interval).map((val) => {
        return Moment({ hour: 0 }).add(val, 'minutes').format('HH:mm:ss');
    });

export const operatingDays = [
    { label: 'Monday', id: 'mon' },
    { label: 'Tueday', id: 'tue' },
    { label: 'Wednesday', id: 'wed' },
    { label: 'Thursday', id: 'thu' },
    { label: 'Friday', id: 'fri' },
    { label: 'Saturday', id: 'sat' },
    { label: 'Sunday', id: 'sun' },
];

export const publicHolidays = [
    { name: `New Year's Day` },
    { name: 'Australia Day' },
    { name: 'Good Friday' },
    { name: 'Easter Monday' },
    { name: 'Anzac Day' },
    { name: 'Christmas Day' },
    { name: 'Boxing Day' },
];

import React, { useEffect, useState } from 'react';

import { compose } from 'recompose';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ActivateUserDialogContainer from './activateUserDialogContainer';

const ActivateUserDialog = (props) => {
    const [disableButtons, setDisableButtons] = useState(false);
    const { onCloseDialog, showDialog } = props;

    useEffect(() => {
        if (showDialog === true) setDisableButtons(false);
    }, [showDialog]);

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} maxWidth='lg'>
            <DialogTitle>Activate User</DialogTitle>

            <DialogContent>
                <Typography className={styles.dialogContent}>
                    Activating this user will change your current plan, increasing your monthly cost. This will take effect immediately if you
                    continue.
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    color='primary'
                    disabled={disableButtons}
                    onClick={async () => {
                        setDisableButtons(true);
                        onCloseDialog('confirm');
                    }}>
                    Ok
                </Button>

                <Button
                    color='primary'
                    disabled={disableButtons}
                    onClick={async () => {
                        onCloseDialog('cancel');
                    }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'space-between',
        width: '100%',
        height: '100%',
    },
    title: {
        width: '100px',
    },
    selectField: {
        width: '100%',
        height: '500px',
    },
    field: {
        width: '100%',
    },
    dialogContent: {
        width: '600px',
    },
    redDialogContent: {
        width: '600px',
        color: 'red',
    },
};

export default compose(ActivateUserDialogContainer, withStyles(styles))(ActivateUserDialog);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useSelector, connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  // TablePagination,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import BasePatientPage from './Base';
import { formatMoney } from '../../utility';

import { LIST_INVOICES } from '../InvoicingPage/gql';

const INITIAL_STATUSES = ['PAID', 'OVERDUE'];

const TransactionsPage = ({ classes, match, user }) => {
  const practiceId = useSelector(state => state.practice.id);
  // const [offset, setOffset] = useState(0);
  const [statuses, setStatuses] = useState(INITIAL_STATUSES);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const columnData = [
    { id: 'createdAt', label: 'Date' },
    { id: 'invoiceNumber', label: 'Invoice #' },
    { id: 'status', label: 'Status' },
    { id: 'dateDue', label: 'Due Date' },
    { id: 'amount', label: 'Amount' },
    { id: 'balanceDue', label: 'Balance Due' },
  ];

  const [getInvoices, { data }] = useLazyQuery(LIST_INVOICES, {
    variables: {
      encodedPatientId: match.params.patientId,
      practiceId,
      statuses,
      startDate,
      endDate,
      // offset,
    },
  });

  // const handleChangePage = (event, newPage) => {
  //   setOffset(newPage);
  // };

  const handleSelectInvoice = invoice => {
    window.location.href = `/invoices/${invoice.id}`;
  };

  const handleSelectStatus = e => {
    setStatuses(e.target.value);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <BasePatientPage encodedPatientId={match.params.patientId} match={match}>
      <div className={classes.subheading}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid className={classes.container}>
            <KeyboardDatePicker
              className={classes.field}
              value={startDate || ''}
              onChange={date => {
                setStartDate(moment(date).format('YYYY-MM-DD'));
              }}
              format="dd/MM/yyyy"
            />
            <KeyboardDatePicker
              className={classes.field}
              value={endDate || ''}
              onChange={date => {
                setEndDate(moment(date).format('YYYY-MM-DD'));
              }}
              maxDate={moment()
                .endOf('month')
                .format()}
              format="dd/MM/yyyy"
            />
            <Select
              className={classes.field}
              margin="normal"
              multiple
              onChange={handleSelectStatus}
              value={statuses}
            >
              {['DRAFT', 'OUTSTANDING', 'PAID', 'OVERDUE'].map(clinician => (
                <MenuItem
                  value={clinician}
                  key={`menuitem-clinician-b-${clinician}`}
                >
                  {clinician}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.subheading}>
        <Typography className={classes.subheadingText} variant="subtitle1">
          Invoices
        </Typography>
      </div>
      <Paper className={classes.submissionsPaper}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
          // className={form.loading ? classes.disabled : null}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columnData.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.fetchInvoice &&
                !!data.fetchInvoice.length &&
                data.fetchInvoice.map(row => (
                  <TableRow
                    className={classes.clickableRow}
                    hover
                    key={row.id}
                    onClick={() => handleSelectInvoice(row)}
                  >
                    <TableCell>
                      {moment(row.invoiceDate).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell>{row.invoiceNumber}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.dateDue}</TableCell>
                    <TableCell>{formatMoney(row.amount || 0, user.location.currency.currency)}</TableCell>
                    <TableCell>{formatMoney(row.balanceDue || 0, user.location.currency.currency)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={classes.buttonContainer} colSpan={6}>
                  {`Total invoices: ${data &&
                    data.fetchInvoice &&
                    data.fetchInvoice.length}`}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          {/* <TablePagination
            // rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data && data.fetchInvoice && data.fetchInvoice.length}
            // rowsPerPage={rowsPerPage}
            page={offset}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
        </Grid>
      </Paper>
    </BasePatientPage>
  );
};

const styles = {
  clickableRow: {
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: () => window.innerWidth > 600 && '100%',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 66,
    paddingRight: 22,
  },
  selectField: {
    marginLeft: 8,
  },
  subheading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  subheadingText: {
    fontWeight: 'bold',
  },
  submissionsPaper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 36,
    width: '100%',
    overflow: 'auto',
  },
};

export default compose(
  withStyles(styles),
  connect(({ user }) => ({ user: user })),
)(TransactionsPage);

import { compose, withProps, branch, withHandlers, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';

export const integrationListQuery = gql`
  query userList($practiceId: ID!) {
    node(id: $practiceId) {
      __typename
      id
      ... on Practice {
        users {
          id
          name
          email
          roles
          isActive
        }
      }
    }
  }
`;

const IntegrationTableContainer = compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
  })),
  graphql(integrationListQuery, {
    name: 'query',
    options: ({ practiceId }) => ({
      variables: { practiceId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withState('anchorEl', 'updateAnchorEl', null),
  withState('selectedRole', 'updateSelectedRole', null),
  withProps(({ query }) => ({
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    ({ status }) => status.success,
    withProps(({ query }) => ({
      users: query.node.users,
    }))
  ),
  withHandlers({


  })
);

export default IntegrationTableContainer;

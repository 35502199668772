import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { WARM_PINK } from '../../style/constants';
import AppBarMenu from '../../components/AppBarMenu';
import { patientSelected } from '../../data/redux/actions/patient';

const BasePatientPage = ({ classes, children, encodedPatientId, match, hasIntegration }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (match.params.patientId !== encodedPatientId) {
            // TODO: need to find a way to populate patient's name
            dispatch(patientSelected({ id: match.params.patientId }));
        }
    }, []);

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                <Grid className={classes.header} container>
                    <Grid item className={classes.tabItem}>
                        <Link to={`/patient/${encodedPatientId}`}>
                            <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={window.location.pathname.split('/').length === 3 ? classes.activeTab : classes.regularTab}>
                                Clinicial Overview
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item className={classes.tabItem}>
                        <Link to={`/patient/${encodedPatientId}/details`}>
                            <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={window.location.pathname.includes('details') ? classes.activeTab : classes.regularTab}>
                                Details
                            </Typography>
                        </Link>
                    </Grid>
                    {!hasIntegration && (
                        <Grid item className={classes.tabItem}>
                            <Link to={`/patient/${encodedPatientId}/accounts`}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={window.location.pathname.includes('accounts') ? classes.activeTab : classes.regularTab}>
                                    Accounts
                                </Typography>
                            </Link>
                        </Grid>
                    )}
                    {!hasIntegration && (
                        <Grid item className={classes.tabItem}>
                            <Link to={`/patient/${encodedPatientId}/invoicing`}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={window.location.pathname.includes('invoicing') ? classes.activeTab : classes.regularTab}>
                                    Invoicing
                                </Typography>
                            </Link>
                        </Grid>
                    )}
                    {hasIntegration && (
                        <Grid item className={classes.tabItem}>
                            <Link to={`/patient/${encodedPatientId}/mindbodyInvoices`}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={window.location.pathname.includes('mindbodyInvoices') ? classes.activeTab : classes.regularTab}>
                                    Mindbody Invoices
                                </Typography>
                            </Link>
                        </Grid>
                    )}
                    {!hasIntegration && (
                        <Grid item className={classes.tabItem}>
                            <Link to={`/patient/${encodedPatientId}/transactions`}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={window.location.pathname.includes('transactions') ? classes.activeTab : classes.regularTab}>
                                    Transactions
                                </Typography>
                            </Link>
                        </Grid>
                    )}
                </Grid>
                {children}
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    header: {
        display: 'flex',
        // paddingTop: 41,
        paddingBottom: 56,
        width: '100%',
    },
    activeTab: {
        color: WARM_PINK,
        borderBottom: `3px solid ${WARM_PINK}`,
    },
    regularTab: {
        color: 'black',
    },
    tabItem: {
        marginRight: 36,
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },
};

export default compose(
    withStyles(styles),
    connect(({ patient, integration }) => ({
        encodedPatientId: patient.id,
        hasIntegration: integration.id !== undefined,
    }))
)(BasePatientPage);

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ConsentFormBuilder from '../../ConsentFormBuilder';
import ConsentForm from '../../ConsentForm';

const ConsentFormTemplateDialog = ({
  consentFormTemplateDialogOpen,
  updateConsentFormTemplateDialogOpen,
  subcategoryIdToCreate,
  onCloseConsentFormTemplate,
  consentFormTemplate,
}) => {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={consentFormTemplateDialogOpen}
      onClose={async () => {
        updateConsentFormTemplateDialogOpen(false);
        await onCloseConsentFormTemplate();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {consentFormTemplate && consentFormTemplate.key
          ? 'Preview Consent Form Template'
          : 'New Consent Form Template'}
      </DialogTitle>
      <DialogContent>
        {consentFormTemplate && consentFormTemplate.key ? (
          <ConsentForm formKey={consentFormTemplate.key} disabled />
        ) : (
          <ConsentFormBuilder
            sharableTemplate
            templateCategory={
              subcategoryIdToCreate ? [subcategoryIdToCreate] : []
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            updateConsentFormTemplateDialogOpen(false);
            await onCloseConsentFormTemplate();
          }}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  connect(({ practice }) => ({
    practiceId: practice.id,
  }))
)(ConsentFormTemplateDialog);

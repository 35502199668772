import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import { compose, lifecycle, withHandlers, withState } from 'recompose';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';
import NotesListItem from '../NotesListItem';
import EmptyAccordion from '../../pages/PatientPage/EmptyAccordion';

const NotesList = ({
    attachments,
    hasCollapse,
    isExpand,
    toggleCollapse,
    classes,
    error,
    hasNextPage,
    loading,
    notes = [],
    onListEnd,
    onSortBy,
    patientId,
    sort,
    status,
}) => {
    if (!loading && !notes.length) {
        return <EmptyAccordion status={status} typeAttachment={attachments} />;
    }

    return (
        <Paper>
            {hasCollapse && Boolean(notes.length) && (
                <List disablePadding>
                    <ListItem>
                        <Typography variant='body2' className={classes.collapseText}>
                            {!isExpand ? `${notes.length} attachments` : null}
                        </Typography>
                        <IconButton className={isExpand ? `${classes.expandOpen} ${classes.expand}` : classes.expand} onClick={toggleCollapse}>
                            <ExpandMoreIcon fontSize='small' />
                        </IconButton>
                    </ListItem>
                    {isExpand ? <Divider /> : null}
                </List>
            )}
            {(() => {
                const tableList = (
                    <List
                        className={classes.list}
                        subheader={
                            <React.Fragment>
                                <ListItem component='ul'>
                                    <ListSubheader className={classes.subheaderTitle}>
                                        <Tooltip title='Sort By Title' placement='bottom-start' enterDelay={300}>
                                            <TableSortLabel
                                                disabled={loading}
                                                active={sort.by === 'TITLE'}
                                                direction={sort.direction.toLowerCase()}
                                                onClick={() => onSortBy('TITLE')}>
                                                Title
                                            </TableSortLabel>
                                        </Tooltip>
                                    </ListSubheader>
                                    {!attachments && (
                                        <ListSubheader className={classes.subheaderText}>
                                            <Tooltip title='Sort By Signed' placement='bottom-start' enterDelay={300}>
                                                <TableSortLabel
                                                    disabled={loading}
                                                    active={sort.by === 'SIGNED_OFF_TIME'}
                                                    direction={sort.direction.toLowerCase()}
                                                    onClick={() => onSortBy('SIGNED_OFF_TIME')}>
                                                    Signed
                                                </TableSortLabel>
                                            </Tooltip>
                                        </ListSubheader>
                                    )}
                                    <ListSubheader className={classes.subheaderText}>
                                        <Tooltip title='Sort By Created' placement='bottom-start' enterDelay={300}>
                                            <TableSortLabel
                                                active={sort.by === 'CREATED_AT'}
                                                direction={sort.direction.toLowerCase()}
                                                onClick={() => onSortBy('CREATED_AT')}>
                                                Created
                                            </TableSortLabel>
                                        </Tooltip>
                                    </ListSubheader>
                                    <ListSubheader className={classes.subheaderText}>
                                        <Tooltip title='Sort By Updated' placement='bottom-start' enterDelay={300}>
                                            <TableSortLabel
                                                disabled={loading}
                                                active={sort.by === 'UPDATED_AT'}
                                                direction={sort.direction.toLowerCase()}
                                                onClick={() => onSortBy('UPDATED_AT')}>
                                                Updated
                                            </TableSortLabel>
                                        </Tooltip>
                                    </ListSubheader>
                                    <ListSubheader className={classes.subheaderText}>Created By</ListSubheader>
                                </ListItem>
                            </React.Fragment>
                        }>
                        <InfiniteScroll
                            pageStart={0}
                            hasMore={hasNextPage}
                            useWindow={false}
                            loader={<Loading key={1} height={100} />}
                            loadMore={onListEnd}>
                            {(() => {
                                if (!patientId) return <EmptyData text='Please select or create a new patient' />;
                                if (status.loading || status.setVariables || status.refetching) return <Loading height={100} />;
                                if (status.success && notes.length === 0)
                                    return (
                                        <List disablePadding>
                                            <ListItem>
                                                <Typography variant='body2' className={classes.collapseText}>
                                                    {attachments ? '0 attachments' : '0 notes'}
                                                </Typography>
                                            </ListItem>
                                            {isExpand ? <Divider /> : null}
                                        </List>
                                    );
                                if (status.error) return <ErrorMessage error={error} />;

                                return (
                                    notes &&
                                    notes.map((note) => (
                                        <React.Fragment key={note.id}>
                                            <Divider className={classes.divider} />
                                            <NotesListItem note={note} attachments={attachments} />
                                        </React.Fragment>
                                    ))
                                );
                            })()}
                        </InfiniteScroll>
                    </List>
                );
                if (attachments && hasCollapse) {
                    return (
                        <Collapse in={isExpand} timeout='auto' unmountOnExit>
                            {tableList}
                        </Collapse>
                    );
                }
                return tableList;
            })()}
        </Paper>
    );
};

const styles = {
    list: {
        paddingBottom: 0,
    },
    subheaderTitle: {
        flexGrow: 1,
        lineHeight: '24px',
        padding: 0,
    },
    subheaderText: {
        lineHeight: '24px',
        flexBasis: 112,
        padding: 0,
        paddingLeft: 14,
    },
    divider: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
    },
    collapseButton: {
        flexBasis: 100,
    },
    collapseText: {
        alignSelf: 'center',
        flexGrow: 1,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
};

export default compose(
    withStyles(styles),
    withState('isExpand', 'updateCollapse', false),
    withHandlers({
        toggleCollapse:
            ({ isExpand, updateCollapse }) =>
            () => {
                updateCollapse(!isExpand);
            },
    }),
    lifecycle({
        componentDidMount() {
            const { notes = [], attachments, isExpand, updateCollapse } = this.props;
            if (attachments && notes.length === 0) {
                updateCollapse(!isExpand);
            }
        },
    })
)(NotesList);

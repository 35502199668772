import React from 'react';
import { compose, lifecycle } from 'recompose';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { WHITE } from '../../style/constants';
import { practiceSearched } from '../../data/redux/actions/practice';
import AppBarMenu from '../../components/AppBarMenu';
import PatientList from '../../components/PatientList/patientList';
import SearchBar from '../../components/SearchBar';

const SelectPatientPage = ({ classes }) => (
    <AppBarMenu>
        <Grid component='main' className={classes.main} container>
            <Grid component='nav' className={classes.patientList} container wrap='nowrap' direction='column'>
                <SearchBar />
                <PatientList replace />
            </Grid>
        </Grid>
    </AppBarMenu>
);

const styles = {
    main: {
        height: '100%',
        paddingTop: 64,
        paddingLeft: 88,
    },
    leftButton: {
        position: 'absolute',
        left: 0,
        marginLeft: 12,
    },
    patientList: {
        backgroundColor: WHITE,
    },
};

export default compose(
    withStyles(styles),
    lifecycle({
        componentWillUnmount() {
            this.props.dispatch(practiceSearched(null));
        },
    })
)(SelectPatientPage);

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const TextSection = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onChangeText,
  onFocus,
  onSave,
  section,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  selectionStart,
  cursors,
}) => {
  return (
    <Grid container direction="column">
      <Typography variant="body1" className={classes.title}>
        {section.title}
      </Typography>
      <TextField
        id="text"
        autoComplete="off"
        InputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
        multiline
        autoFocus={
          autoFocus === section.rowIndexes.concat(section.columnIndexes).join()
        }
        onMouseUp={async event => {
          console.log('onMouseUp');
          console.log(event.target.selectionStart);
          await onChangeText(
            event.target.value,
            section,
            event.target.selectionStart
          );
        }}
        onFocus={async event => {
          if (
            section.rowIndexes &&
            section.rowIndexes[0] &&
            cursors[section.rowIndexes[0] - 1]
          ) {
            event.target.selectionStart = cursors[section.rowIndexes[0] - 1];
          }
          onFocus();
        }}
        disabled={!canEdit || initialSave}
        className={classes.inputText}
        defaultValue={section.text || ''}
        onChange={async event => {
          // this.cursor = event.target.selectionStart;
          await onChangeText(
            event.target.value,
            section,
            event.target.selectionStart
          );
          onSave();
        }}
        fullWidth
      />
    </Grid>
  );
};

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles)
)(TextSection);

import { createStore } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import dialogReducer from '../reducers/dialog';
import episodeReducer from '../reducers/episode';
import formTemplateReducer from '../reducers/formTemplate';
import integrationReducer from '../reducers/integration';
import noteReducer from '../reducers/note';
import notesReducer from '../reducers/notes';
import patientReducer from '../reducers/patient';
import practiceReducer from '../reducers/practice';
import sectionReducer from '../reducers/section';
import subscriptionReducer from '../reducers/subscription';
import userReducer from '../reducers/user';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const config = {
    key: 'root',
    storage,
};

/* eslint-disable no-underscore-dangle */
const store = createStore(
    persistCombineReducers(config, {
        dialog: dialogReducer,
        episode: episodeReducer,
        formTemplate: formTemplateReducer,
        integration: integrationReducer,
        note: noteReducer,
        notes: notesReducer,
        patient: patientReducer,
        practice: practiceReducer,
        section: sectionReducer,
        subscription: subscriptionReducer,
        user: userReducer,
    }) /* preloadedState, */
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // reduxReset()
);
/* eslint-enable */

const persistor = persistStore(store);

export { store, persistor };

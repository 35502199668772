import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import NoteView from './NoteView.jsx';
// import logo from './logo.png';

const Header = ({ patient, episode, user }) => (
  <View style={styles.header} fixed>
    <Text>{`Name: ${patient.name}`}</Text>
    <Text>{`DOB: ${Moment(patient.dob).format('L')}`}</Text>
    <Text>{`Address: ${patient.address}`}</Text>
    <Text>{`Phone: ${patient.phone}`}</Text>
    <Text margin>{`Email: ${patient.email}`}</Text>
    <Text style={styles.episodeName}>
      {`${episode.name}: ${Moment(episode.startDate).format('ll')} - ${
        episode.endDate ? Moment(episode.endDate).format('ll') : 'In Progress'
      }`}
    </Text>
    <Text style={styles.userName}>{user.name}</Text>
    {/* <Image src={logo} style={styles.logo} /> */}
  </View>
);

Header.propTypes = {
  patient: PropTypes.shape({
    name: PropTypes.string,
    dob: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  episode: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
};

const Footer = ({ practice, user }) => (
  <View style={styles.footer} fixed>
    <Text>{`Practice: ${practice.name}`}</Text>
    <Text
      render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`}
      fixed
    />
  </View>
);

Footer.propTypes = {
  practice: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default function PDFDocument({ data }) {
  const {
    episode,
    patient,
    practice,
    user,
    notes = [],
    files,
    canvasImages,
    compactMode,
  } = data;
  return (
    <Document title={!compactMode ? episode.name : ''}>
      {notes.map((note, index) => (
        <Page
          key={note.id}
          size="A4"
          style={compactMode ? styles.pageCompact : styles.page}
        >
          {!compactMode && (
            <Header episode={episode} patient={patient} user={user} />
          )}
          <NoteView
            note={note}
            index={index}
            files={files}
            canvasImages={canvasImages}
            compactMode={compactMode}
          />
          {!compactMode && <Footer practice={practice} user={user} />}
        </Page>
      ))}
    </Document>
  );
}

PDFDocument.propTypes = {
  data: PropTypes.shape({
    episode: PropTypes.shape({
      name: PropTypes.string,
    }),
    patient: PropTypes.object,
    practice: PropTypes.object,
    user: PropTypes.object,
    notes: PropTypes.array,
    files: PropTypes.object,
  }).isRequired,
};

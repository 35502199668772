import React, { useState } from 'react';
import { compose } from 'recompose';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { Alert } from '@material-ui/lab';

import Loading from '../Loading';
import { WARM_PINK } from '../../style/constants';
import { formatMoney } from '../../utility';
import { LIST_INVOICES, UPSERT_INVOICE } from '../../pages/InvoicingPage/gql';

const columnData = [
  { id: 'createdAt', label: 'Date' },
  { id: 'invoiceNumber', label: 'Invoice #' },
  { id: 'status', label: 'Status' },
  { id: 'dateDue', label: 'Due Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'balanceDue', label: 'Balance Due' },
];

const InvoiceListCard = ({
  classes,
  currentLocationId,
  encodedPatientId,
  practiceId,
  location,
}) => {
  const [data, setData] = useState([]);

  const [upsertInvoice, { error, loading }] = useMutation(UPSERT_INVOICE, {
    refetchQueries: [{ query: LIST_INVOICES }],
    onCompleted: res => {
      if (res.upsertInvoice) {
        window.location.href = `/invoices/${res.upsertInvoice.id}`;
      }
    },
  });

  useQuery(LIST_INVOICES, {
    variables: {
      encodedPatientId,
      locationId: currentLocationId,
      // statuses: ['DRAFT', 'OUTSTANDING'],
      // startDate: moment()
      //   .subtract(1, 'month')
      //   .format('YYYY-MM-DD'),
      // endDate: moment().format('YYYY-MM-DD'),
    },
    onCompleted: res => {
      setData(res.fetchInvoice);
    },
  });

  const onSaveHandler = () => {
    upsertInvoice({
      variables: {
        encodedPatientId,
        invoiceDate: moment().format(),
        locationId: currentLocationId,
        practiceId,
      },
    });
  };

  const onSelectInvoice = invoice => {
    window.location.href = `/invoices/${invoice.id}`;
  };

  return (
    <>
      <div className={classes.subheading}>
        <Typography className={classes.subheadingText} variant="subtitle1">
          Invoices
        </Typography>
        {loading ? (
          <Loading />
        ) : (
          <Tooltip title="Create Blank Invoice">
            <Button onClick={onSaveHandler}>
              <SpeedDialIcon />
            </Button>
          </Tooltip>
        )}
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {error && <Alert severity="error">{error.message}</Alert>}
      </Snackbar>
      <Paper className={classes.submissionsPaper}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
          // className={form.loading ? classes.disabled : null}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columnData.map(column => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow
                  className={classes.clickableRow}
                  hover
                  key={row.id}
                  onClick={() => onSelectInvoice(row)}
                >
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.invoiceNumber}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.dateDue}</TableCell>
                  <TableCell>{formatMoney(row.amount || 0, location.currency.currency)}</TableCell>
                  <TableCell>{formatMoney(row.balanceDue || 0, location.currency.currency)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={classes.buttonContainer} colSpan={6}>
                  {/* {formatMoney(subtotal)} */}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Paper>
    </>
  );
};

const styles = {
  buttonItem: {
    justifyContent: 'space-between',
    padding: 4,
  },
  clickableRow: {
    cursor: 'pointer',
  },
  footerButtonItem: {
    justifyContent: 'flex-end',
    padding: 4,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  accountTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginLeft: 8,
  },
  detailItem: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  detailItemEditing: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  detailLabel: {
    flex: 0.5,
  },
  detailInput: {
    flex: 1,
    paddingLeft: 8,
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: () => window.innerWidth > 600 && '100%',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 66,
    paddingRight: 22,
  },
  redButton: {
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
  selectField: {
    marginLeft: 8,
  },
  subheading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  subheadingText: {
    fontWeight: 'bold',
  },
  submissionsPaper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 36,
    width: '100%',
    overflow: 'auto',
  },
};

export default compose(
  connect(({ patient, practice, user }) => ({
    encodedPatientId: patient.id,
    currentLocationId: user.currentLocationId,
    practiceId: practice.id,
    location: user.location
  })),
  withStyles(styles)
)(InvoiceListCard);

import { branch, compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

export const mediaNotePreviewQuery = gql`
  query mediaNotePreview($noteId: ID!) {
    node(id: $noteId) {
      id
      __typename
      ... on MediaNote {
        episodes {
          id
          name
          startDate
          endDate
        }
        categories {
          id
        }
        author {
          id
          name
        }
        createdAt
        title
        isSignedOff
        signedOffTime
        fileType
        file {
          id
          encodedFile
        }
        patient {
          id
          name
        }
      }
    }
  }
`;

const MediaNotePreviewContainer = compose(
  withRouter,
  graphql(mediaNotePreviewQuery, {
    name: 'query',
    options: ({ noteId }) => ({
      variables: { noteId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withProps(({ query }) => ({
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    ({ status }) => status.success,
    withProps(({ query }) => {
      const newProps = {
        id: query.node.id,
        createdAt: query.node.createdAt,
        episodes: query.node.episodes.map(episode => episode.id),
        title: query.node.title,
        isSignedOff: query.node.isSignedOff,
        signedOffTime: query.node.signedOffTime,
        patientName: query.node.patient.name,
        patientId: query.node.patient.id,
        fileType: query.node.fileType,
      };

      if (query.node.file !== null) {
        newProps.fileUri = `data:image/jpg;base64,${query.node.file.encodedFile}`;
      }

      return newProps;
    })
  )
);

export default MediaNotePreviewContainer;

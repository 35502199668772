import { onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';

import { store } from '../redux/store';

const { REACT_APP_API_URL } = process.env;

const cache = new InMemoryCache({
    __schema: {
        types: [
            {
                kind: 'INTERFACE',
                name: 'Node',
                possibleTypes: [
                    { name: 'User' },
                    { name: 'Practice' },
                    { name: 'File' },
                    { name: 'Patient' },
                    { name: 'Episode' },
                    { name: 'TextNote' },
                    { name: 'DrawingNote' },
                    { name: 'MediaNote' },
                    { name: 'FormNote' },
                ],
            },
            {
                kind: 'INTERFACE',
                name: 'Note',
                possibleTypes: [{ name: 'TextNote' }, { name: 'DrawingNote' }, { name: 'MediaNote' }, { name: 'FormNote' }],
            },
            {
                kind: 'INTERFACE',
                name: 'Section',
                possibleTypes: [{ name: 'TextSection' }, { name: 'DrawingSection' }],
            },
        ],
    },
});

const request = async (operation) => {
    const state = store.getState();

    // Apply authentication to all requests if user data exists in the store
    if (state && state.user && state.user.token) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${state.user.token}`,
            },
        });
    }
};

const requestLink = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            let handle;
            Promise.resolve(operation)
                .then((oper) => request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        })
);

const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                // eslint-disable-next-line array-callback-return
                graphQLErrors.map(({ message, locations, path }) => {
                    /* eslint-disable-next-line no-console */
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                    if (message === 'Subscription invalid or expired') {
                        // rediret to /subscription page
                        window.location.href = '/subscriptionExpired';
                    }
                });
            if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line no-console
        }),
        requestLink,
        new HttpLink({
            uri: REACT_APP_API_URL,
            credentials: 'same-origin',
        }),
    ]),
    cache,
});

export default client;

import React, { Fragment, useState } from 'react';
import { compose, withHandlers } from 'recompose';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';

import { Button, Dialog, Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import client from '../../data/apollo/client';
import { practiceCleared } from '../../data/redux/actions/practice';
import { integrationCleared } from '../../data/redux/actions/integration';
import { persistor } from '../../data/redux/store';
import { userAuthenticated, userCleared } from '../../data/redux/actions/user';
import Logo from '../../images/clinik-note-logo-pink.svg';

const GENERATE_USER_SESSION = gql`
    mutation generatePublicUserSession($email: String!, $password: String!) {
        generatePublicUserSession(email: $email, password: $password)
    }
`;

const FETCH_PATIENT_DETAILS = gql`
    query fetchPatient($email: String!) {
        fetchPatient(email: $email) {
            id
            firstName
            lastName
            email
            phone
            dob
        }
    }
`;

export const fetchAndStorePublicUser = (practiceId, booking, setBooking, setShowLoginDialog, user, email) => {
    client
        .query({
            query: FETCH_PATIENT_DETAILS,
            fetchPolicy: 'no-cache',
            variables: {
                email: user.email || email,
            },
        })
        .then((res) => {
            const patient = {
                id: _.get(res, ['data', 'fetchPatient', 'id'], ''),
                firstName: _.get(res, ['data', 'fetchPatient', 'firstName'], ''),
                lastName: _.get(res, ['data', 'fetchPatient', 'lastName'], ''),
                email: _.get(res, ['data', 'fetchPatient', 'email'], ''),
                phone: _.get(res, ['data', 'fetchPatient', 'phone'], ''),
                dob: _.get(res, ['data', 'fetchPatient', 'dob'], ''),
            };

            console.log('BOOKING AT THIS STAGE', booking);

            setBooking({
                ...booking,
                newPatient: !patient.id,
                patient,
            });
            setShowLoginDialog(false);
        });
};

const LoginModal = ({ classes, loginWithCred, dispatch, user, booking, noTooltip, setBooking, practiceId, setShowLoginDialog, showLoginDialog }) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    return (
        <Fragment>
            <Button className={classes.nextButton} variant='outlined' color='secondary' onClick={() => setShowLoginDialog(true)}>
                {user.email ? 'Not you? Logout' : 'Existing Users'}
            </Button>
            {!noTooltip && (
                <Typography variant='caption' color='textSecondary' className={classes.smallText}>
                    {user.email ? `Logged in as ${user.email}` : 'Login instead and save time'}
                </Typography>
            )}
            <Dialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} maxWidth='lg' className={classes.modal}>
                <div className={classes.contentContainer}>
                    <img src={Logo} className={classes.logo} alt='Clinik Note Logo' />
                    <div className={classes.headingContainer}>
                        {user.email ? (
                            <Fragment>
                                <Typography variant='h6' component='h1'>
                                    {`Logged in with ${user.email}`}
                                </Typography>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Typography variant='h6' component='h1'>
                                    Login
                                </Typography>
                            </Fragment>
                        )}
                    </div>
                    <Grid className={classes.modalContent} container spacing={2}>
                        {!user.email && (
                            <Fragment>
                                <TextField
                                    className={classes.field}
                                    placeholder='Email'
                                    value={email || ''}
                                    autoComplete='off'
                                    onChange={(event) => setEmail(event.target.value)}
                                />

                                <TextField
                                    className={classes.field}
                                    autoComplete='off'
                                    type='password'
                                    placeholder='Password'
                                    value={password || ''}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </Fragment>
                        )}
                        {user.email ? (
                            <Button
                                className={classes.loginFormButton}
                                variant='contained'
                                color='secondary'
                                onClick={() => {
                                    persistor.purge();
                                    dispatch({ type: 'RESET' });
                                    dispatch(userCleared());
                                    dispatch(practiceCleared());
                                    dispatch(integrationCleared());
                                    client.resetStore();
                                }}>
                                Not you? Sign out here.
                            </Button>
                        ) : (
                            <Button
                                className={classes.loginFormButton}
                                variant='contained'
                                color='secondary'
                                disabled={!email || !password || user.email}
                                onClick={() => {
                                    loginWithCred({ email, password }).then((data) => {
                                        dispatch(userAuthenticated(email, data));
                                        fetchAndStorePublicUser(practiceId, booking, setBooking, setShowLoginDialog, user, email);
                                        setShowLoginDialog(false);
                                    });
                                }}>
                                Login
                            </Button>
                        )}
                        <Button className={classes.loginFormButton} variant='outlined' color='secondary' onClick={() => setShowLoginDialog(false)}>
                            Cancel
                        </Button>
                    </Grid>
                </div>
            </Dialog>
        </Fragment>
    );
};

const styles = {
    modal: {
        margin: 'auto',
        width: 540,
        height: 600,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 32,
        width: '100%',
        height: '100%',
    },
    modalContent: {
        width: '100%',
    },
    smallText: {
        paddingTop: 4,
    },
    logo: {
        height: 89,
    },
    loginFormButton: {
        marginBottom: '0.5rem',
        width: '100%',
        height: 50,
    },
    headingContainer: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    field: {
        display: 'flex',
        alignContent: 'center',
        width: '100%',
        height: 40,
        marginBottom: 8,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user }) => ({
        user,
    })),
    graphql(GENERATE_USER_SESSION, {
        name: 'generateSession',
    }),
    withHandlers({
        loginWithCred:
            ({ generateSession }) =>
            async ({ email, password }) => {
                try {
                    const result = await generateSession({
                        variables: {
                            email,
                            password,
                        },
                    });
                    return result.data && result.data.generatePublicUserSession;
                } catch (error) {
                    console.log('Error: ', error); // eslint-disable-line
                    throw new Error(error);
                }
            },
    })
)(LoginModal);

import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import lodash from 'lodash';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import PdfContainer from './PdfContainer';
import Doc from './DocService';
import PdfPatient from './PdfPatient';
import DrawingSVG from '../../components/DrawingSVG';

const createPdf = (html, subId) => Doc.createPdf(html, subId);

// const tableData = [];
const headCells = [
  // { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
  {
    id: 'formName',
    numeric: false,
    disablePadding: false,
    label: 'Form',
    type: 'formName',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created At',
    type: 'createdAt',
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.length > 0 &&
          headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Tooltip title={headCell.label}>
                  <div>{lodash.truncate(headCell.label)}</div>
                </Tooltip>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

        <TableCell align="right">
          <Button>Actions</Button>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '20px 40px',
    outline: 'none',
  },
  drawing: {
    height: 400,
    width: 400,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  downloadCsvBtn: {
    color: theme.palette.primary,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperWrap: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    padding: '20px 40px',
  },
  pdfWrap: {
    width: 2480,
    height: 3508,
  },
  wrapCell: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const imgTableCell = (row, headCell) => {
  return (
    <TableCell align="right" key={`${row.id}-${headCell.id}`}>
      <img alt="" src={row[headCell.id]} width={100} height={75} />
    </TableCell>
  );
};

const themeAddFields = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
});

let submissionsData = [];

export default function PatientSubmissions({ submissions }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [dense] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openPdf, setOpenPdf] = useState([]);
  const [openDialog, updateOpenDialog] = useState(false);
  const [dialogValue, updateDialogValue] = useState('');

  useEffect(() => {
    if (submissions.length > 0) {
      const rowData = submissions.map(submission => {
        const {
          id,
          createdAt,
          details,
          form: { title },
        } = submission;
        return {
          id,
          formName: title,
          createdAt: moment(createdAt).format('YYYY-MM-DD'),
          details,
        };
      });
      setTableData(rowData);
      submissionsData = rowData;
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = tableData.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={dense ? 'small' : 'medium'}
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={tableData.length}
          submissions={submissions}
          tableData={tableData}
          setTableData={setTableData}
          submissionsData={submissionsData}
        />
        <TableBody>
          {stableSort(tableData, getComparator(order, orderBy)).map(
            (row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={event => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell
                    align="left"
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <div className={classes.wrapCell}>{row.formName}</div>
                  </TableCell>

                  <TableCell
                    align="right"
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <div className={classes.wrapCell}>{row.createdAt}</div>
                  </TableCell>

                  {headCells.map((headCell, i) => {
                    if (
                      headCell.id !== 'createdAt' &&
                      headCell.id !== 'formName' &&
                      headCell.id !== 'actions'
                    ) {
                      if (headCell.type === 'signature') {
                        return imgTableCell(row, headCell);
                      }
                      if (headCell.type === 'date') {
                        return (
                          <TableCell
                            align="right"
                            key={`${row.id}-${headCell.id}`}
                          >
                            {moment(row[headCell.id]).format('YYYY-MM-DD')}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          align="right"
                          key={`${row.id}-${headCell.id}`}
                        >
                          {row[headCell.id]}
                        </TableCell>
                      );
                    }
                    return null;
                  })}

                  <TableCell align="right">
                    <Button
                      onClick={event => {
                        openPdf[row.id] = event.currentTarget;
                        setOpenPdf(openPdf);
                      }}
                    >
                      View
                    </Button>

                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      item
                      xs={12}
                      sm={12}
                      md={1}
                      lg={1}
                      xl={1}
                    >
                      <ThemeProvider theme={themeAddFields}>
                        <Modal
                          aria-labelledby="more-info"
                          aria-describedby="more-info"
                          className={classes.modal}
                          open={Boolean(openPdf[row.id])}
                          onClose={() => {
                            openPdf[row.id] = null;
                            setOpenPdf(openPdf);
                          }}
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                        >
                          <Paper
                            style={{
                              maxWidth: '80%',
                              maxHeight: '80%',
                              overflow: 'scroll',
                            }}
                          >
                            <PdfContainer
                              createPdf={createPdf}
                              subId={row.id}
                              anchorEl={openPdf}
                              updateAnchorEl={setOpenPdf}
                            >
                              <Fragment>
                                <Paper
                                  id={row.id}
                                  style={{ boxShadow: 'none' }}
                                >
                                  <div className={classes.paper}>
                                    <PdfPatient
                                      formName={row.formName}
                                      submittedDate={row.createdAt}
                                    />
                                    <TableContainer>
                                      <Table aria-label="caption table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="left">
                                              Question
                                            </TableCell>
                                            <TableCell align="left">
                                              Answer
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.details.map((item, key) => {
                                            if (item.type !== 'label') {
                                              return (
                                                <TableRow key={item.name}>
                                                  <TableCell align="left">
                                                    {item.title}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {(() => {
                                                      if (
                                                        item.type ===
                                                        'signature'
                                                      ) {
                                                        return (
                                                          <img
                                                            alt=""
                                                            src={item.value}
                                                            width={100}
                                                            height={75}
                                                          />
                                                        );
                                                      }
                                                      if (
                                                        item.type === 'image'
                                                      ) {
                                                        return (
                                                          item.value && (
                                                            <div
                                                              onClick={() => {
                                                                updateOpenDialog(
                                                                  true
                                                                );
                                                                updateDialogValue(
                                                                  item.value
                                                                );
                                                              }}
                                                            >
                                                              <DrawingSVG
                                                                sectionId={`${new Date()
                                                                  .getTime()
                                                                  .toString()}-${key}`}
                                                                className={
                                                                  classes.drawing
                                                                }
                                                                drawingData={
                                                                  item.value
                                                                }
                                                              />
                                                            </div>
                                                          )
                                                        );
                                                      }
                                                      if (
                                                        item.type === 'date'
                                                      ) {
                                                        return moment(
                                                          item.value
                                                        ).format('YYYY-MM-DD');
                                                      }
                                                      if (
                                                        item.type === 'yesNo'
                                                      ) {
                                                        return lodash.upperFirst(
                                                          item.value
                                                        );
                                                      }
                                                      return item.value;
                                                    })()}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            }
                                            return null;
                                          })}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </Paper>
                              </Fragment>
                            </PdfContainer>
                          </Paper>
                        </Modal>
                      </ThemeProvider>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
      <Dialog
        open={openDialog}
        onClose={() => updateOpenDialog(false)}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogContent dividers>
          {(() => {
            if (dialogValue) {
              // DBG: added bgcolor [..., { backgroundColor: '#fcc' }]
              return (
                <DrawingSVG
                  style={{ width: '100%', height: '100%' }}
                  drawingData={dialogValue}
                />
              );
            }
            return <div>No drawing input</div>;
          })()}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              updateOpenDialog(false);
              updateDialogValue('');
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

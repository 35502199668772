import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { BOULDER, WARM_PINK, WILD_SAND } from '../../style/constants';
import BaseSettingsPage from './Base';
import FormTemplateList from '../../components/FormTemplateList';
import CustomTooltip from '../../components/CustomTooltip';

const TITLE =
    'Here you can add new and create your own form note templates or select and import forms found in the template library for your convenience.';

const TemplateSettings = ({ classes, isConsentFormSubscription }) => {
    const handleTemplateLibraryClick = () => {
        window.location.href = '/library';
    };
    const handleAddNew = () => {
        window.location.href = '/formTemplate';
    };

    return (
        <BaseSettingsPage>
            <div className={classes.header}>
                <div className={classes.headingContainer}>
                    <Typography variant='subtitle1' className={classes.subheading}>
                        Clinician Form Templates
                    </Typography>
                    <CustomTooltip title={TITLE} />
                </div>

                <span>
                    <Button className={classes.greyButton} variant='contained' onClick={handleTemplateLibraryClick}>
                        Template Library
                    </Button>
                    <Button className={classes.redButton} variant='contained' onClick={handleAddNew}>
                        ADD NEW
                    </Button>
                </span>
            </div>
            <Grid className={classes.subSection}>{!isConsentFormSubscription && <FormTemplateList />}</Grid>
        </BaseSettingsPage>
    );
};

const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingBottom: 8,
    },
    subheading: {
        fontWeight: 'bold',
    },
    subSection: {},
    greyButton: {
        marginRight: 8,
        height: 36,
        color: BOULDER,
        backgroundColor: WILD_SAND,
    },
    redButton: {
        height: 36,
        color: WARM_PINK,
        backgroundColor: WILD_SAND,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user }) => ({
        isConsentFormSubscription: user.isConsentFormSubscription,
    }))
)(TemplateSettings);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Loading from '../Loading';
import { faCheck, faCirclePlus, faPencil, faTrashCan, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_PATIENT_ALERT, DELETE_PATIENT_ALERT, GET_PATIENT_ALERTS, UPSERT_PATIENT_ALERT } from './gql';
import ConfirmActionDialog from '../ConfirmActionDialog';
import TextField from '@material-ui/core/TextField';
import { patientSelected } from '../../data/redux/actions/patient';
import lodash from 'lodash';

const PatientAlerts = ({ classes, patient, dispatch }) => {
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [editingAlert, setEditingAlert] = useState(null);
    const [editingAlertText, setEditingAlertText] = useState('');
    const [pendingAlertToDelete, setPendingAlertToDelete] = useState(null);
    const [alertToDelete, setAlertToDelete] = useState(null);

    useEffect(() => {
        getPatientAlerts();
    }, []);

    useEffect(() => {
        if (alertToDelete != null) {
            deletePatientAlert();
        }
    }, [alertToDelete]);

    const [getPatientAlerts] = useLazyQuery(GET_PATIENT_ALERTS, {
        onCompleted: (res) => {
            setAlerts(lodash.orderBy(res.fetchPatientAlerts, 'createdAt', 'asc'));
            setLoading(false);
        },
        variables: {
            encodedPatientId: patient.id,
        },
    });

    const [createPatientAlert] = useMutation(CREATE_PATIENT_ALERT, {
        onCompleted: (res) => {
            let newAlerts = [...alerts];
            newAlerts.push(res.createPatientAlert);
            setAlerts(lodash.orderBy(newAlerts, 'createdAt', 'asc'));
            setEditingAlert(res.createPatientAlert);
            setEditingAlertText('');
            patient.alerts = newAlerts;
            dispatch(patientSelected(patient));
        },
    });

    const [deletePatientAlert] = useMutation(DELETE_PATIENT_ALERT, {
        nextFetchPolicy: 'standby',
        onCompleted: (res) => {
            let newAlerts = [...alerts];
            const indexToRemove = newAlerts.findIndex((alert) => alert.id === res.deletePatientAlert.id);
            newAlerts.splice(indexToRemove, 1);
            setAlerts(newAlerts);
            patient.alerts = newAlerts;
            dispatch(patientSelected(patient));
        },
        variables: {
            alertId: alertToDelete,
        },
    });

    const [upsertPatientAlert] = useMutation(UPSERT_PATIENT_ALERT, {
        nextFetchPolicy: 'standby',
        onCompleted: (res) => {
            let newAlerts = [...alerts];
            setEditingAlertText('');
            setEditingAlert(null);

            const indexToReplace = newAlerts.findIndex((alert) => alert.id === res.upsertPatientAlert.id);
            newAlerts[indexToReplace] = res.upsertPatientAlert;
            setAlerts(lodash.orderBy(newAlerts, 'createdAt', 'asc'));

            patient.alerts = newAlerts;
            dispatch(patientSelected(patient));
        },
    });

    const onSave = async () => {
        await upsertPatientAlert({
            variables: {
                alertId: editingAlert.id,
                text: editingAlertText,
            },
        });
    };

    const onCancel = () => {
        setEditingAlertText('');
        setEditingAlert(null);
    };

    const onDelete = (alert) => {
        setPendingAlertToDelete(alert);
        setConfirmDeleteDialog(true);
    };

    const onConfirmDelete = async () => {
        setAlertToDelete(pendingAlertToDelete.id);
        setConfirmDeleteDialog(false);
    };

    const onNewAlert = async () => {
        await createPatientAlert({
            variables: {
                encodedPatientId: patient.id,
            },
        });
    };

    return (
        <Paper className={classes.paper}>
            {(() => {
                if (loading) return <Loading />;

                const handleToggle = () => {
                    if (editing) {
                        setEditing(false);
                    } else {
                        setEditing(true);
                    }
                };

                return (
                    <Grid container direction='column' justifyContent='center' wrap='nowrap' className={loading ? classes.disabled : null}>
                        <List disablePadding>
                            <ListItem className={classes.buttonItem} divider disableGutters>
                                <div>
                                    <Button disabled={loading} onClick={handleToggle}>
                                        {editing ? 'Done' : 'Edit'}
                                    </Button>
                                </div>
                            </ListItem>
                        </List>
                        <List style={{ padding: 20 }}>
                            <div className={classes.alertsContainer}>
                                {alerts.length === 0 && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            marginTop: 25,
                                        }}>
                                        Create patient alerts for visibility of critical information
                                    </div>
                                )}
                                {alerts.map((alert, index) => (
                                    <div key={`${alert.id}`} className={classes.alertBox}>
                                        {editing && (editingAlert === null || editingAlert.id !== alert.id) && (
                                            <div
                                                onClick={(e) => {
                                                    setEditingAlert(alert);
                                                    setEditingAlertText(alert.text);
                                                    e.preventDefault();
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    top: 5,
                                                    right: 40,
                                                    padding: 0,
                                                    width: 14,
                                                    height: 14,
                                                    cursor: 'pointer',
                                                }}>
                                                <FontAwesomeIcon icon={faPencil} color={'white'} size={'sm'} />
                                            </div>
                                        )}
                                        {editing && editingAlert !== null && editingAlert.id === alert.id && (
                                            <>
                                                <div
                                                    onClick={(e) => {
                                                        onSave();
                                                        e.preventDefault();
                                                    }}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 5,
                                                        right: 40,
                                                        padding: 0,
                                                        width: 14,
                                                        height: 14,
                                                        cursor: 'pointer',
                                                    }}>
                                                    <FontAwesomeIcon icon={faCheck} color={'white'} size={'sm'} />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        onCancel();
                                                    }}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 30,
                                                        right: 40,
                                                        padding: 0,
                                                        width: 14,
                                                        height: 14,
                                                        cursor: 'pointer',
                                                    }}>
                                                    <FontAwesomeIcon icon={faX} color={'white'} size={'sm'} />
                                                </div>
                                            </>
                                        )}
                                        {editing && (
                                            <div
                                                onClick={() => {
                                                    onDelete(alert);
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    top: 5,
                                                    right: 10,
                                                    padding: 0,
                                                    width: 14,
                                                    height: 14,
                                                    cursor: 'pointer',
                                                }}>
                                                <FontAwesomeIcon icon={faTrashCan} color={'white'} size={'sm'} />
                                            </div>
                                        )}
                                        {editingAlert !== null && editingAlert.id === alert.id && (
                                            <>
                                                <TextField
                                                    id={alert.id}
                                                    autoComplete='off'
                                                    multiline={true}
                                                    className={classes.input}
                                                    value={editingAlertText}
                                                    InputProps={{ disableUnderline: true }}
                                                    onChange={(e) => {
                                                        console.log(e.target.value);
                                                        setEditingAlertText(e.target.value);
                                                    }}
                                                    autoFocus
                                                />
                                            </>
                                        )}
                                        {(editingAlert === null || editingAlert.id !== alert.id) && <>{alert.text}</>}
                                    </div>
                                ))}
                                {editing && (
                                    <button onClick={onNewAlert} className={classes.newAlertBox}>
                                        <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 25 }} /> Add Alert
                                    </button>
                                )}
                            </div>
                        </List>
                    </Grid>
                );
            })()}
            <ConfirmActionDialog
                open={confirmDeleteDialog}
                text='Are you sure you want to delete this alert?'
                onClose={() => {
                    setConfirmDeleteDialog(false);
                }}
                onConfirm={async () => {
                    await onConfirmDelete();
                }}
                confirmText='Yes'
            />
        </Paper>
    );
};

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: 157,
        marginBottom: 36,
    },
    input: {
        backgroundColor: 'white',
        border: 'none',
        width: '80%',
        height: 150,
        padding: 10,
    },
    disabled: {
        opacity: 0.5,
    },
    buttonItem: {
        justifyContent: 'flex-end',
        padding: 4,
    },
    datePicker: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
    },
    detailItem: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    detailItemEditing: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    detailLabel: {
        flex: 0.5,
    },
    detail: {
        flex: 1,
    },
    detailInput: {
        flex: 1,
        paddingLeft: 8,
    },
    detailInputName: {
        flex: 1,
        paddingLeft: 8,
        fontWeight: 'bold',
    },
    alertsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 20,
    },
    alertBox: {
        position: 'relative',
        padding: 20,
        backgroundColor: '#dc3545',
        color: 'white',
        borderRadius: 15,
        minHeight: 60,
        width: '30%',
    },
    newAlertBox: {
        cursor: 'pointer',
        textAlign: 'left',
        padding: 20,
        backgroundColor: '#f5f5f5',
        color: '#262626',
        borderRadius: 15,
        minHeight: 60,
        width: '30%',
        borderStyle: 'dashed',
        borderWidth: 4,
        borderColor: 'gray',
    },
};

export default compose(
    withStyles(styles),
    connect(({ patient }) => ({
        patient: patient,
    }))
)(PatientAlerts);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { APPOINTMENT_COLOR_OBJ, APPOINTMENT_COLORS, ARRIVAL_RED, ERROR } from '../../style/constants';
import { DELETE_APPOINTMENT_TYPE, UPSERT_APPOINTMENT_TYPE } from './gql';
import IntegrationService from '../../services/IntegrationService';

const AppointmentTypeDialog = ({ classes, existingAppointmentType, locationId, onCloseDialog, practiceEncodedId, showDialog, taxRates }) => {
    const [invalidInput, setInvalidInput] = useState([]);
    const [appointmentType, setAppointmentType] = useState(existingAppointmentType || { color: APPOINTMENT_COLORS[0] });
    const [isUniqueInput, setIsUniqueInput] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const requiredFields = ['code', 'name', 'desc', 'color', 'price', 'duration', 'taxRateIds'];

    const validateChanges = () => {
        const invalidDetected = [];
        requiredFields.forEach((key) => {
            const variable = appointmentType[key];
            if (!variable) {
                invalidDetected.push(key);
            }
        });
        return setInvalidInput(invalidDetected);
    };

    const [deleteAppointmentType] = useMutation(DELETE_APPOINTMENT_TYPE, {
        onCompleted: (res) => {
            if (res.deleteAppointmentType) {
                onCloseDialog();
            }
        },
        refetchQueries: ['listAppointmentTypes'],
        variables: {
            id: appointmentType.id,
        },
    });

    const [updateAppointmentType] = useMutation(UPSERT_APPOINTMENT_TYPE, {
        onCompleted: (res) => {
            if (res.upsertAppointmentType) {
                onCloseDialog();
            }
        },
        refetchQueries: ['listAppointmentTypes'],
        variables: {
            ...appointmentType,
            taxables: appointmentType.taxRateIds,
            locationId,
            practiceEncodedId,
        },
    });

    useEffect(() => {
        validateChanges();
    }, [appointmentType]);

    const handleDelete = () => {
        deleteAppointmentType();
    };

    const handleSave = () => {
        if (!invalidInput.length && isUniqueInput) {
            updateAppointmentType();
        } else if (!showErrors) {
            setShowErrors(true);
        }
    };

    const handleTaxSelect = (event) => {
        setAppointmentType({ ...appointmentType, taxRateIds: event.target.value });
        setIsUniqueInput(true);
    };

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} className={classes.dialogContainer}>
            <DialogTitle id='form-dialog-title'>
                {existingAppointmentType && existingAppointmentType.name ? `Edit ${existingAppointmentType.name}` : 'Create New Appointment Type'}
            </DialogTitle>

            <DialogContent>
                <TextField
                    className={classes.field}
                    type='text'
                    label='Code'
                    autoComplete='off'
                    fullWidth
                    margin='normal'
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    defaultValue={appointmentType.code}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        const clonedOpHours = {
                            ...appointmentType,
                            code: event.target.value,
                        };
                        setAppointmentType(clonedOpHours);
                        setIsUniqueInput(true);
                    }}
                    error={showErrors && invalidInput.includes('code')}
                />

                <TextField
                    className={classes.field}
                    type='text'
                    label='Description'
                    autoComplete='off'
                    fullWidth
                    margin='normal'
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    defaultValue={appointmentType.desc}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        const clonedOpHours = {
                            ...appointmentType,
                            desc: event.target.value,
                        };
                        setAppointmentType(clonedOpHours);
                        setIsUniqueInput(true);
                    }}
                    error={showErrors && invalidInput.includes('desc')}
                />

                <TextField
                    className={classes.field}
                    type='text'
                    label='Name'
                    fullWidth
                    autoComplete='off'
                    margin='normal'
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    defaultValue={appointmentType.name}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        const clonedOpHours = {
                            ...appointmentType,
                            name: event.target.value,
                        };
                        setAppointmentType(clonedOpHours);
                        setIsUniqueInput(true);
                    }}
                    error={showErrors && invalidInput.includes('name')}
                />

                <Typography className={classes.title} variant='body2'>
                    Color
                </Typography>

                <Select
                    className={classes.field}
                    value={appointmentType.color}
                    fullWidth
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    margin='normal'
                    onChange={(event) => {
                        const clonedOpHours = {
                            ...appointmentType,
                            color: event.target.value,
                        };
                        setAppointmentType(clonedOpHours);
                        setIsUniqueInput(true);
                    }}>
                    {APPOINTMENT_COLOR_OBJ.map((color) => (
                        <MenuItem value={color.code} key={`menuitem-color-b-${color.name}`}>
                            <div style={{ background: color.code, padding: 6 }}>{color.name}</div>
                        </MenuItem>
                    ))}
                </Select>

                <Typography className={classes.title} variant='body2'>
                    Price
                </Typography>

                <TextField
                    className={classes.field}
                    id='standard-select-currency'
                    autoComplete='off'
                    defaultValue={appointmentType.price}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    onChange={(event) => {
                        const clonedOpHours = {
                            ...appointmentType,
                            price: event.target.value,
                        };
                        setAppointmentType(clonedOpHours);
                        setIsUniqueInput(true);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    error={showErrors && invalidInput.includes('price')}
                />

                <Typography className={classes.title} variant='body2'>
                    Duration
                </Typography>

                <TextField
                    className={classes.field}
                    type='number'
                    fullWidth
                    autoComplete='off'
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    defaultValue={appointmentType.duration}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        const clonedOpHours = {
                            ...appointmentType,
                            duration: event.target.value,
                        };
                        setAppointmentType(clonedOpHours);
                        setIsUniqueInput(true);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>mins</InputAdornment>,
                    }}
                    error={showErrors && invalidInput.includes('duration')}
                />

                <Typography className={classes.title} variant='body2'>
                    Applicable Tax Rates
                </Typography>

                <Select
                    className={classes.field}
                    fullWidth
                    margin='normal'
                    multiple
                    onChange={handleTaxSelect}
                    disabled={IntegrationService.objectIsLocked(appointmentType)}
                    renderValue={(selected) => `${selected.length} selected`}
                    value={appointmentType.taxRateIds}>
                    {taxRates.map((taxRate) => (
                        <MenuItem id={taxRate.id} value={taxRate.id} key={`menuitem-taxrate-${taxRate.id}`}>
                            <Checkbox checked={appointmentType.taxRateIds.indexOf(taxRate.id) > -1} />
                            <ListItemText primary={`${taxRate.name} - (${taxRate.ratePercent}%)`} />
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>

            <DialogActions>
                {existingAppointmentType && (
                    <div className={classes.buttonContainer}>
                        {confirmDelete && (
                            <React.Fragment>
                                <Button variant='outlined' className={classes.button} onClick={() => setConfirmDelete(false)}>
                                    UNDO
                                </Button>
                                <Button variant='outlined' className={classes.redButton} onClick={handleDelete}>
                                    CONFIRM
                                </Button>
                            </React.Fragment>
                        )}
                        {!confirmDelete && !IntegrationService.objectIsLocked(appointmentType) && (
                            <Button color='secondary' className={classes.deleteButton} onClick={() => setConfirmDelete(true)}>
                                DELETE
                            </Button>
                        )}
                    </div>
                )}

                <div className={classes.buttonContainer}>
                    <Button color='primary' onClick={onCloseDialog}>
                        Cancel
                    </Button>

                    {!IntegrationService.objectIsLocked(appointmentType) && (
                        <Button
                            color='primary'
                            onClick={handleSave}
                            disabled={invalidInput.length > 0 || _.isEqual(appointmentType, {}) || !isUniqueInput}>
                            SAVE
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        margin: '0px !important',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    fieldContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    buttonFieldContainer: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    title: {
        width: 100,
        marginTop: 16,
    },
    field: {
        width: 240,
    },
    dialogContainer: {
        width: '100%',
    },
    buttonContainer: {
        '& button': {
            marginLeft: 10,
        },
    },
    redButton: {
        width: 100,
        height: 40,
        color: ARRIVAL_RED,
    },
    button: {
        width: 100,
        height: 40,
    },
    deleteButton: {
        marginRight: 'auto',
        color: ERROR,
    },
};

export default compose(
    connect(({ practice, user }) => ({
        locationId: user.currentLocationId,
        practiceEncodedId: practice.id,
    })),
    withStyles(styles)
)(AppointmentTypeDialog);

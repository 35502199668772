import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { QRCodeSVG } from 'qrcode.react';

export default function QrCodeModal({ open, handleClose, qrCode }) {
  const printRef = React.useRef();

  const consentFormPath = `/consent-form/${qrCode.key}`;
  const fullConsentFormPath = `${window.location.origin}${consentFormPath}`;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">QR Code</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <QRCodeSVG ref={printRef} value={fullConsentFormPath} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect } from 'react';
import { branch, compose, withHandlers, withProps, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import uniqBy from 'lodash/uniqBy';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenu from '../../components/AppBarMenu';
import Loading from '../../components/Loading';
import EnhancedTableInfinitive from './list-item-infinitive';
import { LOAD_CONSENT_FORM, LOAD_CONSENT_FORM_SUBMISSIONS } from '../ConsentFormList/gql';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const ConsentFormSubmissions = ({ classes, consentForm, anchorEl, updateAnchorEl, submissions, onListEnd, pageInfo }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        });
    }, [location]);

    if (!consentForm) {
        return <Loading overlay />;
    }
    const data = { ...consentForm, submissions };

    return (
        <AppBarMenu>
            <div id='myMm' style={{ height: '1mm' }} />
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                {consentForm && (
                    <EnhancedTableInfinitive
                        consentForm={data}
                        anchorEl={anchorEl}
                        updateAnchorEl={updateAnchorEl}
                        hasNextPage={pageInfo ? pageInfo.hasNextPage : true}
                        onListEnd={onListEnd}
                    />
                )}
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
};

export default compose(
    withStyles(styles),
    withState('consentForm', 'updateConsentForm', null),
    withState('anchorEl', 'updateAnchorEl', {}),
    withState('offset', 'updateOffset', 0),
    withProps(({ match }) => ({
        formKey: match.params.formKey,
    })),
    graphql(LOAD_CONSENT_FORM, {
        name: 'getConsentFormQuery',
        options: ({ formKey, consentForm, updateConsentForm }) => ({
            variables: {
                key: formKey,
            },
            fetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                updateConsentForm(data.getConsentForm.form);
            },
        }),
    }),
    graphql(LOAD_CONSENT_FORM_SUBMISSIONS, {
        name: 'listConsentFormSubmissions',
        options: ({ formKey, offset }) => ({
            variables: {
                key: formKey,
                limit: 10,
                offset: 0,
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withProps(({ listConsentFormSubmissions }) => ({
        error: listConsentFormSubmissions.error,
        status: {
            loading: listConsentFormSubmissions.networkStatus === 1,
            fetching: listConsentFormSubmissions.networkStatus === 3,
            refetching: listConsentFormSubmissions.networkStatus === 4,
            success: listConsentFormSubmissions.networkStatus === 7 && Boolean(listConsentFormSubmissions.listConsentFormSubmissions),
            error: listConsentFormSubmissions.networkStatus === 8,
        },
    })),
    branch(
        (props) => props.status.success || props.status.fetching || props.status.refetching,
        withProps(({ listConsentFormSubmissions }) => ({
            submissions: listConsentFormSubmissions.listConsentFormSubmissions
                ? listConsentFormSubmissions.listConsentFormSubmissions.submissions
                : [],
            pageInfo: listConsentFormSubmissions.listConsentFormSubmissions ? listConsentFormSubmissions.listConsentFormSubmissions.pageInfo : {},
        }))
    ),
    withHandlers({
        onListEnd:
            ({ status, listConsentFormSubmissions, pageInfo, formKey, submissions, offset, updateOffset }) =>
            () => {
                if (!status.fetching && !status.refetching && submissions && submissions.length !== offset) {
                    listConsentFormSubmissions.fetchMore({
                        variables: {
                            limit: 10,
                            key: formKey,
                            offset: submissions ? submissions.length : 0,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const combinedItems = [
                                ...previousResult.listConsentFormSubmissions.submissions,
                                ...fetchMoreResult.listConsentFormSubmissions.submissions,
                            ];
                            const filteredResults = uniqBy(combinedItems, (item) => item.id);
                            updateOffset(filteredResults.length + 10);

                            return {
                                listConsentFormSubmissions: {
                                    ...previousResult.listConsentFormSubmissions,
                                    submissions: filteredResults,
                                    pageInfo: fetchMoreResult.listConsentFormSubmissions.pageInfo,
                                },
                            };
                        },
                    });
                }
            },
    })
)(ConsentFormSubmissions);

import React, { useRef } from 'react';
import { CSVLink } from 'react-csv';

// Allows you to download a CSV file without a button click
// https://github.com/react-csv/react-csv/issues/47#issuecomment-859555722
const CsvDownload = props => {
  const btnRef = useRef(null);

  // DEVNOTE: had to opt for a 2s timeout to ensure the CSVLink component
  // has been rendered before clicking the hidden button
  setTimeout(() => {
    if (btnRef.current) {
      btnRef.current.click();
    }
  }, 1500);

  return (
    <CSVLink {...props}>
      <span ref={btnRef} />
    </CSVLink>
  );
};

export default CsvDownload;

import React from 'react';
import { compose, withState, lifecycle } from 'recompose';
import { Document, Page } from 'react-pdf';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';

import Add from '@material-ui/icons/Add';
import AttachFile from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Remove from '@material-ui/icons/Remove';
import ZoomOut from '@material-ui/icons/ZoomOut';

import { withStyles } from '@material-ui/core/styles';

const PDFPreviewModal = ({
  classes,
  fileUri,
  onCloseModal,
  onChangeFile,
  pages,
  showModal,
  updatePages,
}) => (
  <Modal open={showModal} onClose={onCloseModal}>
    <div className={classes.modal}>
      <form name="mediaForm">
        <AppBar position="absolute" classes={{ root: classes.appBar }}>
          <Toolbar disableGutters>
            <IconButton
              className={classes.leftButton}
              color="inherit"
              aria-label="close"
              onClick={onCloseModal}
            >
              <Close />
            </IconButton>
            <div className={classes.middleButtons}>
              <IconButton
                color="inherit"
                aria-label="zoomOut"
                onClick={() =>
                  updatePages({
                    ...pages,
                    scale: pages.scale - 0.2,
                  })
                }
              >
                <Remove />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="defaultScale"
                onClick={() =>
                  updatePages({
                    ...pages,
                    scale: pages.defaultScale,
                  })
                }
              >
                <ZoomOut />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="zoomIn"
                onClick={() =>
                  updatePages({
                    ...pages,
                    scale: pages.scale + 0.2,
                  })
                }
              >
                <Add />
              </IconButton>
            </div>
            <div className={classes.rightButtons}>
              <label htmlFor="file-input">
                <IconButton color="inherit" aria-label="attach" component="div">
                  <AttachFile />
                </IconButton>
                <input
                  className={classes.input}
                  id="file-input"
                  type="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={event => {
                    onCloseModal();
                    onChangeFile(event.target.files[0]);
                  }}
                />
              </label>
              <IconButton
                color="inherit"
                aria-label="previous"
                onClick={() =>
                  updatePages({
                    ...pages,
                    pageNumber: pages.pageNumber - 1,
                  })
                }
                classes={{ disabled: classes.disabled }}
                disabled={pages.pageNumber === 1}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="next"
                onClick={() =>
                  updatePages({
                    ...pages,
                    pageNumber: pages.pageNumber + 1,
                  })
                }
                classes={{ disabled: classes.disabled }}
                disabled={pages.pageNumber === pages.numPages}
              >
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Document
          onLoadSuccess={({ numPages }) => updatePages({ ...pages, numPages })}
          file={fileUri}
          className={classes.document}
        >
          <Page
            pageNumber={pages.pageNumber}
            scale={pages.scale}
            renderAnnotations={false}
          />
        </Document>
      </form>
    </div>
  </Modal>
);

const styles = {
  modal: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  appBar: {
    backgroundColor: '#333',
    justifyContent: 'center',
  },
  leftButton: {
    position: 'absolute',
    left: 12,
  },
  middleButtons: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rightButtons: {
    position: 'absolute',
    right: 24,
  },
  input: {
    display: 'none',
  },
  document: {
    marginTop: 48,
  },
  disabled: {
    color: 'rgba(255, 255, 255, 0.47) !important',
  },
};

export default compose(
  withStyles(styles),
  withState('pages', 'updatePages', {
    numPages: null,
    pageNumber: 1,
    scale: 1,
    defaultScale: 1,
  }),
  lifecycle({
    componentDidMount() {
      const { pages, updatePages } = this.props;
      if (window.innerWidth <= 700)
        updatePages({ ...pages, scale: 0.7, defaultScale: 0.7 });
      if (window.innerWidth >= 1024)
        updatePages({ ...pages, scale: 1.1, defaultScale: 1.1 });
      if (window.innerWidth >= 2560)
        updatePages({ ...pages, scale: 2.5, defaultScale: 2.5 });
    },
  })
)(PDFPreviewModal);

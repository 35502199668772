import React, { useState } from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import debounce from 'lodash/debounce';
import get from 'lodash/get';

const SimpleTextSearch = ({
  classes,
  searchItems,
  setSearchItems,
  searchKeys,
}) => {
  const [barText, setBarText] = useState('');

  const search = searchTerm => {
    const filteredItems = searchItems.filter(item => {
      return searchKeys.some(key => {
        if (typeof item[key] === 'object') {
          return get(item, key, '').includes(searchTerm);
        }

        return get(item, key, '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
    setSearchItems(filteredItems);
  };

  const handleChange = event => {
    setBarText(event.target.value);
    const debounceSearch = debounce(search, 100);
    debounceSearch(event.target.value);
  };

  return (
    <form>
      <TextField
        value={barText}
        placeholder={`Search ${searchKeys
          .join(' or ')
          .replaceAll(/_|\./gi, ' ')}`}
        onChange={handleChange}
        autoComplete="off"
        margin="normal"
        variant="standard"
        className={classes.searchBar}
      />
    </form>
  );
};

const styles = {
  searchBar: {
    width: 300,
  },
};

export default compose(withStyles(styles))(SimpleTextSearch);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import { useMutation } from '@apollo/client';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { ARRIVAL_RED, ERROR } from '../../style/constants';
import { UPSERT_INVOICE_PAYMENT } from '../InvoicingPage/gql';
import { formatMoney } from '../../utility';

const DEFAULT_STATE = {
    dateReceived: Moment().format('yyyy-MM-DD'),
    cash: null,
    cheque: null,
    creditCard: null,
    deposit: null,
    eftpos: null,
    giftCard: null,
    healthFund: null,
    medicare: null,
};

const sanitiseAmount = (amount) => {
    if (!amount) {
        return 0;
    }
    return Math.abs(parseFloat(amount)).toFixed(2);
};

const PAYMENT_KEYS = [
    { key: 'cash', label: 'Cash' },
    { key: 'cheque', label: 'Cheque' },
    { key: 'creditCard', label: 'Credit Card' },
    { key: 'deposit', label: 'Deposit' },
    { key: 'eftpos', label: 'EFTPOS' },
    { key: 'giftCard', label: 'Gift Card' },
    { key: 'healthFund', label: 'Health Fund' },
    { key: 'medicare', label: 'Medicare' },
];

const InvoicePaymentDialog = ({
    classes,
    existingPayment,
    invoiceId,
    locationId,
    onCloseDialog,
    practiceEncodedId,
    showDialog,
    location,
    listInvoiceItems,
}) => {
    const [invoicePayment, setInvoicePayment] = useState(DEFAULT_STATE);
    const [isUniqueInput, setIsUniqueInput] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [subtotal, setSubtotal] = useState(0);

    const [upsertPayment] = useMutation(UPSERT_INVOICE_PAYMENT, {
        onCompleted: () => {
            listInvoiceItems();
            onCloseDialog();
        },
        variables: {
            practiceEncodedId,
            locationId,
            ...invoicePayment,
            invoiceId,
        },
    });

    const onBlur = ({ newPaymentObject = invoicePayment }) => {
        let temporaryTotal = 0;
        const temporaryPayment = { ...newPaymentObject };
        PAYMENT_KEYS.forEach((item) => {
            temporaryPayment[item.key] = newPaymentObject[item.key] ? sanitiseAmount(newPaymentObject[item.key]) : null;
            temporaryTotal += parseFloat(temporaryPayment[item.key] || 0);
        });
        setInvoicePayment(temporaryPayment);
        setSubtotal(temporaryTotal);
    };

    useEffect(() => {
        if (existingPayment && existingPayment.id) {
            onBlur({ newPaymentObject: existingPayment });
        } else {
            setInvoicePayment(DEFAULT_STATE);
        }
    }, [existingPayment]);

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} className={classes.dialogContainer}>
            <DialogTitle id='form-dialog-title'>{existingPayment ? `Edit Payment` : 'Add New Payment'}</DialogTitle>

            <DialogContent>
                <Grid>
                    <Grid item xs={12}>
                        {PAYMENT_KEYS.map((item) => (
                            <TextField
                                className={classes.field}
                                fullWidth
                                autoComplete='off'
                                id='standard-currency-amount'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>{location.currency.symbol}</InputAdornment>,
                                }}
                                key={item.key}
                                label={item.label}
                                margin='normal'
                                onBlur={onBlur}
                                onChange={(event) => {
                                    const clonedInvoicePayment = {
                                        ...invoicePayment,
                                        [item.key]: event.target.value,
                                    };
                                    setInvoicePayment(clonedInvoicePayment);
                                    setIsUniqueInput(true);
                                }}
                                type='number'
                                value={invoicePayment[item.key]}
                            />
                        ))}

                        <TextField
                            className={classes.field}
                            fullWidth
                            label='Date'
                            autoComplete='off'
                            margin='normal'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                const clonedInvoicePayment = {
                                    ...invoicePayment,
                                    dateReceived: event.target.value,
                                };
                                setInvoicePayment(clonedInvoicePayment);
                                setIsUniqueInput(true);
                            }}
                            type='date'
                            value={invoicePayment.dateReceived}
                        />

                        <TextField
                            className={classes.field}
                            fullWidth
                            label='Comments'
                            autoComplete='off'
                            margin='normal'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                const clonedInvoicePayment = {
                                    ...invoicePayment,
                                    comments: event.target.value,
                                };
                                setInvoicePayment(clonedInvoicePayment);
                                setIsUniqueInput(true);
                            }}
                            type='text'
                            value={invoicePayment.comments || ''}
                        />

                        <Typography className={classes.title} variant='body2'>
                            Subtotal
                        </Typography>

                        <Typography className={classes.title} variant='body2'>
                            {formatMoney(subtotal, location.currency.currency)}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                {existingPayment && (
                    <div className={classes.buttonContainer}>
                        {confirmDelete ? (
                            <React.Fragment>
                                <Button variant='outlined' className={classes.button} onClick={() => setConfirmDelete(false)}>
                                    UNDO
                                </Button>
                                <Button
                                    variant='outlined'
                                    className={classes.redButton}
                                    onClick={() => {
                                        // deleteAppointmentType(existingPayment.id);
                                        onCloseDialog();
                                    }}>
                                    CONFIRM
                                </Button>
                            </React.Fragment>
                        ) : (
                            <Button color='secondary' className={classes.deleteButton} onClick={() => setConfirmDelete(true)}>
                                DELETE
                            </Button>
                        )}
                    </div>
                )}

                <div className={classes.buttonContainer}>
                    <Button color='primary' onClick={onCloseDialog}>
                        Cancel
                    </Button>

                    <Button
                        color='primary'
                        onClick={async () => {
                            if (isUniqueInput) {
                                await upsertPayment();
                            }
                        }}>
                        SAVE
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    button: {
        width: 100,
        height: 40,
    },
    buttonContainer: {
        '& button': {
            marginLeft: 10,
        },
    },
    deleteButton: {
        marginRight: 'auto',
        color: ERROR,
    },
    dialogContainer: {
        width: '100%',
    },
    field: {
        width: 240,
    },
    modalContent: {
        padding: 32,
        margin: '0px !important',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    redButton: {
        width: 100,
        height: 40,
        color: ARRIVAL_RED,
    },
    title: {
        width: 100,
        marginTop: 16,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, patient, practice }) => ({
        locationId: user.currentLocationId,
        userId: user.id,
        practiceEncodedId: practice.id,
        location: user.location,
    }))
)(InvoicePaymentDialog);

import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const NumberScale10SectionPreview = ({
  numberScale10Value,
  title,
  classes = {},
}) => {
  let scaleValue = [0, 0];
  if (numberScale10Value) {
    scaleValue = numberScale10Value.split(';');
    if (scaleValue.length === 1) {
      scaleValue = [...[0], ...scaleValue];
    }
  }
  return (
    <Grid item xs>
      <div className={classes.text}>
        <Typography style={{ marginBottom: 40 }} variant="body1">
          {title}
        </Typography>
        <Slider
          value={scaleValue || [0, 10]}
          min={0}
          step={0.5}
          max={10}
          disabled
          valueLabelDisplay="on"
          aria-labelledby="non-linear-slider"
        />
      </div>
    </Grid>
  );
};

export default NumberScale10SectionPreview;

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import AppBarMenu from '../../components/AppBarMenu';
import { SETTINGS_TAB_OPTIONS } from './constants';
import { WARM_PINK } from '../../style/constants';

const BaseSettingsPage = ({ classes, children, hasIntegration, isAdmin }) => {
    const [activeTab, setActiveTab] = useState(SETTINGS_TAB_OPTIONS[0].id);

    useEffect(() => {
        const path = window.location.pathname.split('/')[2];
        if (path) {
            setActiveTab(path);
        }
    }, []);

    const renderSettingsTab = (tab) => {
        if (hasIntegration && tab.hideForIntegrations) return null;
        if (!isAdmin && tab.adminOnly) return null;

        const { REACT_APP_ENABLE_INTEGRATIONS } = process.env;

        if (tab.id === 'integrations' && REACT_APP_ENABLE_INTEGRATIONS !== 'true') return null;

        return (
            <Grid key={`setting-tab-bar-button-${tab.id}`} item className={classes.tabItem}>
                <Link to={tab.route}>
                    <Typography variant='subtitle1' gutterBottom className={activeTab === tab.id ? classes.activeTab : classes.regularTab}>
                        {tab.title}
                    </Typography>
                </Link>
            </Grid>
        );
    };

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                <Grid className={classes.header} container>
                    <Grid className={classes.bar} container spacing={6}>
                        {SETTINGS_TAB_OPTIONS.map((tab) => renderSettingsTab(tab))}
                    </Grid>

                    <Grid component='main' container justifyContent='center'>
                        <Grid className={classes.content} container direction='column' wrap='nowrap' item xs={12} sm={11} md={12} lg={12} xl={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    content: {
        paddingTop: 28,
    },
    header: {
        display: 'flex',
        // paddingTop: 41,
        paddingBottom: 56,
        width: '100%',
    },
    bar: {
        display: 'flex',
        justifyContent: 'space-between',
        // paddingTop: 31,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        // maxWidth: 800,
    },
    activeTab: {
        color: WARM_PINK,
        borderBottom: `3px solid ${WARM_PINK}`,
    },
    regularTab: {
        color: 'black',
    },
    tabItem: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, integration }) => ({
        hasIntegration: integration.id !== undefined,
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
    }))
)(BaseSettingsPage);

import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { MINOR_BLACK, SECONDARY_BLACK, BLACK } from '../../style/constants';

const MoreInfoPopover = ({
  anchorEl,
  attachment,
  authorName,
  classes,
  createdAt,
  episodes,
  groupId,
  loading,
  noteId,
  patientName,
  signedOffTime,
  updateAnchorEl,
}) => (
  <React.Fragment>
    <Tooltip title="Note Info" placement="bottom-start">
      <IconButton
        onClick={event => {
          updateAnchorEl(event.currentTarget);
        }}
        aria-label="More Info"
        color="inherit"
        disabled={(!noteId && !groupId) || loading}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={classes.infoIcon}
          size="sm"
        />
      </IconButton>
    </Tooltip>

    <Modal
      aria-labelledby="more-info"
      aria-describedby="more-info"
      open={Boolean(anchorEl)}
      onClose={() => updateAnchorEl(null)}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => updateAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={2}
      >
        <Grid
          className={classes.grid}
          container
          wrap="nowrap"
          direction="column"
        >
          <Typography className={classes.infoTitle}>
            {attachment ? 'Attachment Info' : 'Note Info'}
          </Typography>
          <div className={classes.separator} />
          <Typography className={classes.info}>{patientName}</Typography>
          <Typography className={classes.infoLabel}>Patient Name</Typography>
          <div className={classes.separator} />
          {!!episodes &&
            !!episodes.length &&
            episodes.map(episode => (
              <div key={episode.id}>
                <Typography className={classes.info}>{episode.name}</Typography>
                <Typography className={classes.infoLabel}>
                  Episode {Moment(episode.startDate).format('ll')} -{' '}
                  {episode.endDate
                    ? Moment(episode.endDate).format('ll')
                    : 'In Progress'}
                </Typography>
              </div>
            ))}
          {!!episodes && !!episodes.length && (
            <div className={classes.separator} />
          )}
          <Typography className={classes.info}>
            {Moment(createdAt).format('llll')}
          </Typography>
          <Typography className={classes.infoLabel}>Created At</Typography>
          <div className={classes.separator} />
          <Typography className={classes.info}>{authorName}</Typography>
          <Typography className={classes.infoLabel}>Created By</Typography>
          {signedOffTime && (
            <div>
              <div className={classes.separator} />
              <Typography className={classes.info}>
                {Moment(signedOffTime).format('llll')}
              </Typography>
              <Typography className={classes.infoLabel}>Signed Off</Typography>
            </div>
          )}
        </Grid>
      </Popover>
    </Modal>
  </React.Fragment>
);

const styles = {
  infoIcon: ({ loading }) => ({
    color: BLACK,
    opacity: loading ? 0.26 : 1,
  }),
  grid: {
    width: 300,
    overflow: 'auto',
    padding: 20,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
    borderBottom: `1px solid ${MINOR_BLACK}`,
  },
  infoTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  info: {
    fontSize: 16,
  },
  infoLabel: {
    color: SECONDARY_BLACK,
  },
  separator: {
    marginTop: 8,
    marginBottom: 8,
    borderBottom: `1px solid ${MINOR_BLACK}`,
  },
};

export default compose(
  connect(),
  withState('anchorEl', 'updateAnchorEl', null),
  withStyles(styles)
)(MoreInfoPopover);

import Moment from 'moment';
import React from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import './calendar.css';

const getThreeMonthHeading = startingDateStr => {
  const startingDate = Moment(startingDateStr);
  const prevMonth = startingDate.subtract(1, 'month').format('MMM');
  const nextMonth = startingDate.add(1, 'month').format('MMM');
  const year = startingDate.add(1, 'month').format('YYYY');
  return `${prevMonth} - ${nextMonth} ${year}`;
};

const CarouselControls = ({ classes, goNextMonth, currentDate }) => {
  const parsedCurrentDate = Moment(currentDate);

  return (
    <div className={classes.controlsContainer}>
      <IconButton
        color="inherit"
        aria-label="PrevMonth"
        className={classes.iconButton}
        onClick={() =>
          goNextMonth(parsedCurrentDate.subtract(3, 'M').format('YYYY-MM-DD'))
        }
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="subtitle1">
        <strong>{getThreeMonthHeading(currentDate)}</strong>
      </Typography>
      <IconButton
        color="inherit"
        aria-label="NextMonth"
        className={classes.iconButton}
        onClick={() =>
          goNextMonth(parsedCurrentDate.add(3, 'M').format('YYYY-MM-DD'))
        }
      >
        <ChevronRightIcon />
      </IconButton>
    </div>
  );
};

const styles = {
  iconButton: {
    width: 9,
    height: 16,
  },
  controlsContainer: {
    display: 'flex',
    padding: 10,
  },
};

export default withStyles(styles)(CarouselControls);

import { compose, withProps, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import get from 'lodash/get';
import some from 'lodash/some';
import remove from 'lodash/remove';

import {
  faFile,
  faFileAlt,
  faFileImage,
} from '@fortawesome/free-regular-svg-icons';
import { faPaste } from '@fortawesome/free-solid-svg-icons';

import { noteUpsert } from '../../data/redux/actions/note';
import { notesChecked } from '../../data/redux/actions/notes';

const NotesItemContainer = compose(
  withRouter,
  connect(({ notes, user }) => ({
    showCheckBox: notes.signOff,
    checkedNotes: notes.checkedNotes,
    userId: user.id,
    isAuth: user.roles.includes('CLINICIAN') || user.roles.includes('ADMIN'),
  })),
  withProps(({ checkedNotes, match, note, userId }) => ({
    checkedNoteObject: {
      id: note.id,
      __typename: note.__typename,
    },
    checkedNotesMatch: some(checkedNotes, {
      id: note.id,
      __typename: note.__typename,
    }),
    isAuthor: note.author && userId === note.author.id,
    patientId: match.params.patientId,
  })),
  withProps(({ note }) => {
    switch (note.__typename) {
      case 'TextNote':
        return { icon: faFileAlt };
      case 'DrawingNote':
        return { icon: faFileImage };
      case 'FormNote':
        return { icon: faPaste };
      default:
        return { icon: faFile };
    }
  }),
  withHandlers({
    onCheckNote: ({
      checkedNotes,
      checkedNotesMatch,
      checkedNoteObject,
      dispatch,
    }) => () => {
      const newCheckedNotes = checkedNotes.map(note => note);

      if (!checkedNotesMatch) {
        newCheckedNotes.push(checkedNoteObject);
      } else {
        remove(newCheckedNotes, checkedNoteObject);
      }

      dispatch(notesChecked(newCheckedNotes));
    },
  }),
  withHandlers({
    onLinkPress: ({
      dispatch,
      history,
      isAuth,
      isAuthor,
      onCheckNote,
      note,
      patientId,
      showCheckBox,
    }) => async () => {
      if (showCheckBox && isAuthor && isAuth) {
        onCheckNote();
      } else if (!showCheckBox) {
        if (get(note, 'group.id')) {
          await dispatch(
            noteUpsert({
              id: note.id,
              __typename: note.__typename,
              title: note.title,
              isSignedOff: note.isSignedOff,
              signedOffTime: note.signedOffTime,
              groupId: note.group.id,
            })
          );
        } else {
          await dispatch(
            noteUpsert({
              id: note.id,
              __typename: note.__typename,
              title: note.title,
              isSignedOff: note.isSignedOff,
              signedOffTime: note.signedOffTime,
            })
          );
        }

        switch (note.__typename) {
          case 'TextNote':
            return history.push(`/patient/${patientId}/note/text/${note.id}`);
          case 'DrawingNote':
            if (get(note, 'group.id')) {
              history.push(
                `/patient/${patientId}/note/drawings/${note.group.id}`
              );
            } else {
              return history.push(
                `/patient/${patientId}/note/drawing/${note.id}`
              );
            }
            break;
          case 'MediaNote':
            return history.push(`/patient/${patientId}/attachments/${note.id}`);
          case 'FormNote':
            return history.push(`/patient/${patientId}/note/form/${note.id}`);
          default:
            return null;
        }
      }

      return null;
    },
  })
);

export default NotesItemContainer;

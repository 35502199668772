import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { compose } from 'recompose';

const SearchBar = ({ classes, data, setFilteredData, showStatusFilter, noMargin }) => {
    const [searchParams, setSearchParams] = useState({
        startDate: null,
        endDate: null,
        searchquery: '',
        status: '',
    });

    const [statusTypes, setStatusTypes] = useState([]);

    useEffect(() => {
        search();
    }, [searchParams.startDate, searchParams.endDate, searchParams.searchquery, searchParams.status]);

    useEffect(() => {
        //* get all unique status - to show in dropdown
        const allStatus = data.map((invoice) => invoice.status);
        const uniqueStatus = ['', ...new Set(allStatus)];
        setStatusTypes(uniqueStatus);
    }, [data]);

    const search = () => {
        let filteredData = data;
        Object.entries(searchParams).forEach(([paramKey, paramValue]) => {
            //* Loop through search params -> search in the array of objects -> update state
            if (paramValue !== null && paramValue !== '') {
                filteredData = filteredData.filter((item) => {
                    let result = false;
                    if (paramKey === 'searchquery') {
                        //* Searches in id and patient name
                        result =
                            item.invoiceNumber.toString().toLowerCase().includes(paramValue.toLowerCase()) ||
                            (item.patient && item.patient.name.toString().toLowerCase().includes(paramValue.toLowerCase())) ||
                            item.patientName.toString().toLowerCase().includes(paramValue.toLowerCase());
                    } else if (paramKey === 'startDate' || paramKey === 'endDate') {
                        const searchDate = item.dateDue ? new Date(item.dateDue) : new Date(item.invoiceDate);

                        const start = new Date(searchParams.startDate);
                        const end = searchParams.endDate ? new Date(searchParams.endDate) : new Date(); //* today as end if end is not available

                        result = searchDate >= start && searchDate <= end;
                    } else {
                        result = item[paramKey] === paramValue;
                    }
                    return result;
                });
            }
        });
        setFilteredData(filteredData);
    };

    const handleChange = (event) => {
        //* update input states
        setSearchParams({ ...searchParams, [event.target.id]: event.target.value });
    };

    return (
        <form>
            <Box display='flex' justifyContent='space-around' alignItems='center' m={noMargin ? 0 : 1} p={1} bgcolor='background.paper'>
                <Box>
                    <TextField
                        className={classes.searchField}
                        id='searchquery'
                        onChange={handleChange}
                        autoComplete='off'
                        label='Search by ID or Patient Name'
                        variant='standard'
                        placeholder='Search...'
                        value={searchParams.searchquery}
                    />
                </Box>
                {showStatusFilter === false ? null : (
                    <Box>
                        <TextField
                            select
                            label='Status'
                            autoComplete='off'
                            value={searchParams.status}
                            id='status'
                            onBlur={handleChange}
                            SelectProps={{
                                native: true,
                            }}>
                            {statusTypes.map((option, index) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                )}
                <Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant='inline'
                            format='MM/dd/yyyy'
                            margin='normal'
                            label='Start Date'
                            name='start-date'
                            value={searchParams.startDate}
                            onChange={(e) => {
                                setSearchParams({
                                    ...searchParams,
                                    startDate: e && Object.prototype.toString.call(e) === '[object Date]' && !isNaN(e) ? e : null,
                                });
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
                <Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant='inline'
                            format='MM/dd/yyyy'
                            margin='normal'
                            label='End Date'
                            name='end-date'
                            value={searchParams.endDate}
                            onChange={(e) =>
                                setSearchParams({
                                    ...searchParams,
                                    endDate: e && Object.prototype.toString.call(e) === '[object Date]' && !isNaN(e) ? e : null,
                                })
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
        </form>
    );
};

const styles = {
    searchField: {
        width: 215,
    },
};

export default compose(withStyles(styles))(SearchBar);

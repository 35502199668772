import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';

const withAuth = wrappedComponent =>
  compose(
    connect(({ user }) => ({ user })),
    branch(
      ({ user, location }) =>
        !user.token &&
        location.pathname !== '/' &&
        location.pathname !== '/register',
      renderComponent(() => <Redirect to="/" />)
    ),
    branch(
      ({ user, location }) =>
        user.token &&
        (location.pathname === '/' || location.pathname === '/register'),
      renderComponent(() => <Redirect to="/diary" />)
    )
    // branch(
    //   ({ user, location }) => {
    //     return (
    //       user.token &&
    //       user.isConsentFormSubscription &&
    //       (location.pathname === '/' || location.pathname === '/register')
    //     );
    //   },
    //   renderComponent(() => <Redirect to="/consent-form-dashboard" />)
    // )
  )(wrappedComponent);

export default withAuth;

import React from 'react';
import { compose, lifecycle, withProps } from 'recompose';
import { Redirect } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { notesCleared } from '../../data/redux/actions/notes';
import DrawingNote from '../../components/DrawingNote';
import FormNote from '../../components/FormNote';
import GroupDrawingNote from '../../components/GroupDrawingNote';
import MediaNote from '../../components/MediaNote';
import TextNote from '../../components/TextNote';

const WebViewNotePage = ({
  atAttachments,
  atDrawing,
  atForm,
  atGroupDrawing,
  atText,
  classes,
  patientId,
}) => {
  if (patientId === 'undefined' && atDrawing)
    return <Redirect to="/patient/select/note/drawing" />;
  if (patientId === 'undefined' && atAttachments)
    return <Redirect to="/patient/select/attachments" />;
  if (patientId === 'undefined' && atText)
    return <Redirect to="/patient/select/note/text" />;
  if (patientId === 'undefined' && atForm)
    return <Redirect to="/patient/select/note/form" />;

  return (
    <Grid container wrap="nowrap">
      <Grid
        component="main"
        className={classes.main}
        container
        direction="column"
        wrap="nowrap"
        item
        flex={1}
      >
        {(() => {
          if (atAttachments) return <MediaNote />;
          if (atGroupDrawing) return <GroupDrawingNote />;
          if (atDrawing) return <DrawingNote />;
          if (atText) return <TextNote />;
          if (atForm) return <FormNote hidePrintPdf={true} />;

          return null;
        })()}
      </Grid>
    </Grid>
  );
};

const styles = {
  main: {
    padding: 10,
  },
  leftButton: {
    position: 'absolute',
    left: 0,
    marginLeft: 12,
  },
};

export default compose(
  withStyles(styles),
  withProps(({ match, location }) => ({
    patientId: match.params.patientId,
    atAttachments: location.pathname.includes('attachments'),
    atDrawing: location.pathname.includes('drawing'),
    atForm: location.pathname.includes('form'),
    atGroupDrawing: location.pathname.includes('drawings'),
    atText: location.pathname.includes('text'),
  })),
  lifecycle({
    componentDidMount() {
      this.props.dispatch(notesCleared());
    },
  })
)(WebViewNotePage);

import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { noteUpsert } from '../../data/redux/actions/note';

import { mediaNotePreviewQuery } from '../MediaNotePreview/mediaNotePreviewContainer';

export const upsertMediaNoteMutation = gql`
    mutation upsertMediaNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $episodes: [ID]
        $title: String
        $encodedFile: String
        $fileType: FileType
        $deletedAt: DateTime
    ) {
        upsertMediaNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            episodes: $episodes
            title: $title
            encodedFile: $encodedFile
            fileType: $fileType
            deletedAt: $deletedAt
        ) {
            note {
                __typename
                id
                title
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                author {
                    id
                    name
                }
                patient {
                    id
                    name
                }
                file {
                    id
                }
                fileType
                patient {
                    id
                }
            }
        }
    }
`;

const tagNoteMutation = gql`
    mutation tagNote($noteId: ID!, $categories: [ID], $episodes: [ID]) {
        tagNote(id: $noteId, episodes: $episodes, categories: $categories) {
            note {
                id
                __typename
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                categories {
                    id
                }
                title
            }
        }
    }
`;

const initialState = {
    id: undefined,
    categoryIdsArray: [],
    episodeIdsArray: [],
    title: undefined,
    fileType: undefined,
    fileData: undefined,
    fileUri: undefined,
    error: false,
    loading: false,
    isSignedOff: false,
    signedOffTime: undefined,
    fileLoading: false,
    createdAt: undefined,
    episodes: [],
    author: undefined,
    patientName: undefined,
    isAuthor: true,
    preventQuery: false,
    fileSizeExceed: false,
};

const handlers = {
    onChangeFile:
        ({ dispatch, upsertMediaNote, userId, patientId, form, noteId, updateForm, history }) =>
        async (file) => {
            updateForm({ ...form, fileSizeExceed: false, fileLoading: true });
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                // removes mime type prefix from reader.result.
                const fileData = reader.result.split(',')[1];

                // Check file size
                let fileSizeInMb = (fileData.length * 0.75) / 1e6;
                if (fileSizeInMb > 4.5) {
                    updateForm({ ...form, fileSizeExceed: true, fileLoading: false });
                    return;
                }

                let fileType;
                switch (file.type) {
                    case 'application/pdf':
                        fileType = 'PDF';
                        break;
                    case 'image/jpeg':
                        fileType = 'JPG';
                        break;
                    default:
                        return null;
                }
                const newOptions = {
                    variables: {
                        userId,
                        patientId,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                        title: file.name,
                        encodedFile: fileData,
                        fileType,
                    },
                };

                const editOptions = {
                    variables: {
                        noteId,
                        allowUpdate: true,
                        userId,
                        patientId,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                        title: file.name,
                        encodedFile: fileData,
                        fileType,
                    },
                    refetchQueries: [
                        {
                            query: mediaNotePreviewQuery,
                            variables: { noteId },
                        },
                    ],
                };

                try {
                    const response = await upsertMediaNote(noteId ? editOptions : newOptions);

                    dispatch(
                        noteUpsert({
                            id: response.data.upsertMediaNote.note.id,
                            __typename: response.data.upsertMediaNote.note.__typename,
                            title: response.data.upsertMediaNote.note.title,
                        })
                    );

                    if (!noteId) {
                        history.replace(`/patient/${patientId}/attachments/${response.data.upsertMediaNote.note.id}`);
                    }

                    updateForm({
                        ...form,
                        error: false,
                        fileLoading: false,
                        fileType,
                        fileUri: reader.result,
                        isAuthor: response.data.upsertMediaNote.note.author.id === userId,
                    });
                } catch (error) {
                    updateForm({ ...form, error, fileLoading: false });
                }

                return null;
            };
        },
    onSave:
        ({ dispatch, form, updateForm, userId, patientId, noteId, upsertMediaNote }) =>
        async () => {
            if (!form.id) {
                updateForm({
                    ...form,
                    loading: true,
                });
            } else {
                updateForm({
                    ...form,
                    loading: true,
                    preventQuery: true,
                });
            }

            try {
                const response = await upsertMediaNote({
                    variables: {
                        noteId,
                        allowUpdate: true,
                        userId,
                        patientId,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                        title: form.title || 'Untitled Attachment',
                        encodedFile: form.fileData,
                        fileType: form.fileType,
                    },
                    refetchQueries: [
                        {
                            query: mediaNotePreviewQuery,
                            variables: { noteId },
                        },
                    ],
                });

                dispatch(
                    noteUpsert({
                        id: response.data.upsertMediaNote.note.id,
                        __typename: response.data.upsertMediaNote.note.__typename,
                        title: response.data.upsertMediaNote.note.title,
                    })
                );

                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error: false,
                    preventQuery: true,
                    title: prevForm.title,
                }));
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onTag:
        ({ form, noteId, tagNote, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                const response = await tagNote({
                    variables: {
                        noteId,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                    },
                });

                updateForm({
                    ...form,
                    loading: false,
                    error: false,
                    episodes: response.data.tagNote.note.episodes,
                });
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onArchiveNote:
        ({ form, userId, patientId, noteId, updateForm, upsertMediaNote, history }) =>
        async () => {
            updateForm({ ...form, loading: true });
            try {
                await upsertMediaNote({
                    variables: {
                        noteId,
                        allowUpdate: true,
                        userId,
                        patientId,
                        categories: form.categoryIdsArray,
                        episodes: form.episodeIdsArray,
                        title: form.title || 'Untitled Attachment',
                        encodedFile: form.fileData,
                        fileType: form.fileType,
                        deletedAt: new Date(),
                    },
                });
                updateForm({ ...form, loading: false, error: false });
                history.goBack();
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
};

const MediaNoteContainer = compose(
    withRouter,
    connect(({ user }) => ({
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        isClinician: user.roles.includes('CLINICIAN'),
        userId: user.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withState('form', 'updateForm', initialState),
    withProps(({ match, location, form, isClinician, isAdmin, isReceptionist, isConsentFormSubscription }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
        noteId: match.params.noteId,
        canEdit: ((form.isAuthor && isClinician) || isAdmin || isReceptionist) && !isConsentFormSubscription,
    })),
    withState('showModal', 'updateShowModal', false),
    branch(
        ({ noteId, form }) => noteId && !form.preventQuery,
        compose(
            graphql(mediaNotePreviewQuery, {
                name: 'query',
                options: ({ noteId }) => ({
                    variables: { noteId },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                ({ status }) => status.success,
                withProps(({ query }) => {
                    const newProps = {
                        id: query.node.id,
                        episodeIdsArray: query.node.episodes.map((episode) => episode.id),
                        categoryIdsArray: query.node.categories.map((category) => category.id),
                        title: query.node.title,
                        createdAt: query.node.createdAt,
                        isSignedOff: query.node.isSignedOff,
                        signedOffTime: query.node.signedOffTime,
                        fileType: query.node.fileType,
                        author: query.node.author,
                        patientName: query.node.patient.name,
                        episodes: query.node.episodes,
                    };

                    if (query.node.file !== null) {
                        newProps.fileData = query.node.file.encodedFile;
                        newProps.fileUri = `data:image/jpg;base64,${query.node.file.encodedFile}`;
                    }

                    return newProps;
                })
            )
        )
    ),
    graphql(upsertMediaNoteMutation, { name: 'upsertMediaNote' }),
    graphql(tagNoteMutation, { name: 'tagNote' }),
    withHandlers(handlers),
    withPropsOnChange(['onSave'], ({ onSave }) => ({
        onSave: debounce(onSave, 1000),
    })),
    lifecycle({
        componentDidMount() {
            const {
                id,
                noteId,
                episodeId,
                episodeIdsArray,
                categoryIdsArray,
                title,
                fileUri,
                fileData,
                form,
                updateForm,
                status,
                isSignedOff,
                signedOffTime,
                fileType,
                createdAt,
                author,
                patientName,
                userId,
            } = this.props;

            if (!noteId && episodeId) {
                updateForm({ ...form, episodeIdsArray: [episodeId] });
            } else if (status && status.success) {
                updateForm({
                    ...form,
                    id,
                    episodeIdsArray,
                    categoryIdsArray,
                    title,
                    fileType,
                    fileUri,
                    fileData,
                    isSignedOff,
                    signedOffTime,
                    createdAt,
                    author,
                    patientName,
                    isAuthor: userId === get(author, 'id'),
                });
            }
        },
    })
);

export default MediaNoteContainer;

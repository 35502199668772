// action types

export const USER_SELECTED = 'USER_SELECTED';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_CLEARED = 'USER_CLEARED';
export const USER_SHOW_TOGGLE = 'USER_SHOW_TOGGLE';

// action creators

export const userSelected = ({
    id,
    currentLocationId,
    currentLocationName,
    locationOptions,
    name,
    roles,
    isSubscriptionValid,
    isConsentFormSubscription,
    location,
}) => ({
    type: USER_SELECTED,
    id,
    currentLocationId,
    currentLocationName,
    locationOptions,
    name,
    roles,
    isSubscriptionValid,
    isConsentFormSubscription,
    location,
});

export const userAuthenticated = (email, token) => ({
    type: USER_AUTHENTICATED,
    email,
    token,
});

export const userShowToggle = (key, boolean) => ({
    type: USER_SHOW_TOGGLE,
    [key]: boolean,
});

export const userCleared = () => ({ type: USER_CLEARED });

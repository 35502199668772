import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { MINOR_BLACK, WHITE } from '../../style/constants';

import EpisodeList from '../EpisodeList';
import PatientList from '../PatientList/patientList';
import SearchBar from '../SearchBar';

const SideBar = ({ classes, children, showEpisodeList, showPatientList }) => (
    <Grid container wrap='nowrap' className={classes.wrapper}>
        {(() => {
            if (showPatientList)
                return (
                    <Grid component='nav' item sm={3} className={classes.sideBar}>
                        <SearchBar />
                        <PatientList height='90%' replace usingWindowScroll={false} />
                    </Grid>
                );
            if (showEpisodeList)
                return (
                    <Grid component='nav' item sm={3} className={classes.sideBar}>
                        <EpisodeList height='96%' />
                    </Grid>
                );

            return null;
        })()}
        <Grid container wrap='nowrap' justifyContent='center' className={showPatientList || showEpisodeList ? classes.withSideBar : null}>
            {children}
        </Grid>
    </Grid>
);

const styles = {
    wrapper: {},
    sideBar: {
        left: 80,
        height: '100%',
        width: '25%',
        position: 'fixed',
        paddingTop: 64,
        backgroundColor: WHITE,
        borderRightWidth: 1,
        borderColor: MINOR_BLACK,
    },
    withSideBar: {
        paddingLeft: '25%',
    },
};

export default compose(
    connect(({ user }) => ({
        showEpisodeList: user.showEpisodeList,
        showPatientList: user.showPatientList,
    })),
    withStyles(styles)
)(SideBar);

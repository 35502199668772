import React from 'react';
import { compose } from 'recompose';

import {
  Button,
  Paper,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core';
import { ArrowBack, Edit } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import SearchBar from '../../components/SearchBar';
import PatientSelect from '../../components/PatientList/PatientSelect';

const SelectedPatientCard = ({ classes, form, setForm, onSave }) => {
  if (!form.patient) {
    return (
      <>
        <SearchBar />
        <PatientSelect height="90%" form={form} setForm={setForm} />
      </>
    );
  }

  const {
    patient: { name, phone, email, dob, invoiceAccounts },
  } = form;

  return (
    <Paper>
      <Box pt={2} pb={2}>
        <Box className={classes.buttonContainer}>
          <Button
            aria-label="back"
            onClick={() => setForm({ ...form, patient: undefined })}
          >
            <ArrowBack />
          </Button>
          <Button
            aria-label="edit"
            onClick={() => {
              window.location.href = `/patient/${form.patient.id}`;
            }}
          >
            <Edit />
          </Button>
        </Box>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell className={classes.noDividerCell}>Name</TableCell>
              <TableCell className={classes.noDividerCell}>{name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noDividerCell}>Phone</TableCell>
              <TableCell className={classes.noDividerCell}>{phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noDividerCell}>Email</TableCell>
              <TableCell className={classes.noDividerCell}>{email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noDividerCell}>DOB</TableCell>
              <TableCell className={classes.noDividerCell}>{dob}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noDividerCell}>Account</TableCell>
              <TableCell className={classes.noDividerCell}>
                <Select
                  className={classes.field}
                  fullWidth
                  margin="normal"
                  onChange={e => setForm({ ...form, account: e.target.value })}
                >
                  {invoiceAccounts.map(acc => (
                    <MenuItem value={acc} key={`menuitem-acc-b-${acc.id}`}>
                      <div style={{ background: acc.code, padding: 6 }}>
                        {acc.billingName} -{acc.status}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          </TableBody>
          {onSave && (
            <TableFooter>
              <TableRow>
                <TableCell className={classes.buttonContainer} colSpan={6}>
                  <Button
                    className={classes.greyButton}
                    onClick={() => {
                      // window.location.href = `/patient/${form.patient.id}`;
                      onSave();
                    }}
                    variant="outlined"
                    disabled={!form.account}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Box>
    </Paper>
  );
};

const styles = {
  field: {
    width: 240,
  },
  noDividerCell: {
    paddingBottom: '0px !important',
    borderBottom: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default compose(withStyles(styles))(SelectedPatientCard);

import React from 'react';
import { compose, withState } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';
import { BLACK } from '../../style/constants';

function downloadMedia(form) {
  const byteCharacters = atob(form.fileData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; ) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
    i += 1;
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'octet/stream' });
  const blobUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = blobUrl;
  // add attachment
  if (form.title.includes(form.fileType.toLowerCase())) {
    a.download = form.title;
  } else {
    a.download = `${form.title}.${form.fileType.toLowerCase()}`;
  }
  a.click();
  window.URL.revokeObjectURL(blobUrl);
}

const DownloadMediaNote = ({ classes, loading, form }) => (
  <div>
    <IconButton
      color="inherit"
      aria-label="Download"
      className={classes.iconButton}
      disabled={loading || !form}
      onClick={() => {
        downloadMedia(form);
      }}
    >
      <FontAwesomeIcon icon={faDownload} />
    </IconButton>
  </div>
);

const styles = {
  iconButton: ({ loading }) => ({
    color: BLACK,
    opacity: loading ? 0.26 : 1,
  }),
};

export default compose(
  withStyles(styles),
  withState('open', 'updateOpen', false)
)(DownloadMediaNote);

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { useMutation } from '@apollo/client';
import Moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { UPSERT_LOCATION } from './gql';
import GooglePlacesAutocomplete from '../../../components/GooglePlacesAutocomplete';
import IntegrationService from '../../../services/IntegrationService';

const LocationDetailDialog = ({ classes, currentLocation, handleClose, practiceEncodedId, showDialog }) => {
    const [form, setForm] = useState({});
    const dispatch = useDispatch();

    const [upsertLocation, { loading }] = useMutation(UPSERT_LOCATION, {
        variables: {
            ...form,
            practiceEncodedId,
        },
    });

    const handleFieldChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const handleSave = () => {
        upsertLocation({
            onCompleted: (res) => {
                if (res.upsertLocation) {
                    // hard reload to get new data
                    window.location.reload(true);
                }
            },
        });
    };

    useEffect(() => {
        console.log('Current location: ', currentLocation);
        setForm({ ...currentLocation });
    }, [currentLocation]);

    return (
        <Dialog fullWidth open={showDialog} onClose={handleClose}>
            <DialogTitle>{currentLocation.id ? `Update ${currentLocation.name}` : 'Create New Location'}</DialogTitle>

            <DialogContent className={classes.modalContent}>
                <FormControl>
                    <TextField
                        className={classes.field}
                        type='text'
                        label='Location Name'
                        disabled={IntegrationService.objectIsLocked(currentLocation)}
                        fullWidth
                        autoComplete='off'
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name='name'
                        onChange={handleFieldChange}
                        required
                        value={form.name || ''}
                    />

                    <GooglePlacesAutocomplete
                        classes={classes}
                        disabled={IntegrationService.objectIsLocked(currentLocation)}
                        handleFieldChange={handleFieldChange}
                        form={form}
                        setForm={setForm}
                        placeholder={form.address}
                    />

                    <TextField
                        className={classes.field}
                        value={form.country || ''}
                        type='text'
                        label='Country'
                        margin='normal'
                        name='country'
                        fullWidth
                        onChange={handleFieldChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Autocomplete
                        id='practice-timezone'
                        disabled={IntegrationService.objectIsLocked(currentLocation)}
                        options={Moment.tz.names()}
                        sx={{ width: 300 }}
                        label='Practice Timezone'
                        name='timezone'
                        onChange={(event, value) => {
                            if (value) {
                                handleFieldChange({
                                    target: { name: 'timezone', value },
                                });
                            }
                        }}
                        value={form.timezone || ''}
                        renderInput={(params) => (
                            <TextField
                                className={classes.field}
                                type='text'
                                fullWidth
                                margin='normal'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...params}
                                label='Practice Timezone'
                            />
                        )}
                    />

                    <TextField
                        className={classes.field}
                        value={form.state || ''}
                        disabled={IntegrationService.objectIsLocked(currentLocation)}
                        type='text'
                        label='State'
                        margin='normal'
                        name='state'
                        fullWidth
                        onChange={handleFieldChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        className={classes.field}
                        value={form.zip || ''}
                        disabled={IntegrationService.objectIsLocked(currentLocation)}
                        label='Zip Code'
                        type='text'
                        margin='normal'
                        name='zip'
                        fullWidth
                        onChange={handleFieldChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        className={classes.field}
                        type='text'
                        label='Phone'
                        disabled={IntegrationService.objectIsLocked(currentLocation)}
                        fullWidth
                        autoComplete='off'
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name='phone'
                        onChange={handleFieldChange}
                        value={form.phone || ''}
                    />
                    <TextField
                        className={classes.field}
                        type='text'
                        label='Fax'
                        fullWidth
                        autoComplete='off'
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name='fax'
                        onChange={handleFieldChange}
                        value={form.fax || ''}
                    />
                    <TextField
                        className={classes.field}
                        fullWidth
                        helperText='This is the email address that Patients will see in emails and invoices'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label='Email Address'
                        autoComplete='off'
                        margin='normal'
                        name='email'
                        onChange={handleFieldChange}
                        required
                        type='text'
                        value={form.email || ''}
                    />
                    <TextField
                        className={classes.field}
                        type='text'
                        label='ABN / Business ID'
                        fullWidth
                        autoComplete='off'
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name='abn'
                        onChange={handleFieldChange}
                        value={form.abn || ''}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {loading ? <CircularProgress /> : <Button onClick={handleSave}>Save</Button>}
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        margin: '0px !important',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    subheading: {
        fontWeight: 'bold',
        paddingTop: 36,
        paddingBottom: 8,
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice }) => ({
        practiceEncodedId: practice.id,
    }))
)(LocationDetailDialog);

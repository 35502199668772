import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { TableContainer } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import PatientContainer from '../../components/Patient/patientContainer';
import ErrorMessage from '../../components/ErrorMessage';
import Loading from '../../components/Loading';

const PdfPatient = ({ classes, error, form, status, formName, submittedDate }) => (
    <Paper className={classes.paper}>
        {form.loading && <Loading linear />}
        {(() => {
            if (status.loading || status.setVariables || status.refetching) return <Loading />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <Grid container direction='column' justifyContent='center' wrap='nowrap' className={form.loading ? classes.disabled : null}>
                    <TableContainer>
                        <Table aria-label='caption table' style={{ width: 'auto' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ borderBottom: 'none' }}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Form name:</span>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: 'none' }}>{formName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderBottom: 'none' }}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Submitted Date:</span>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: 'none' }}>{submittedDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderBottom: 'none' }}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Patient Name:</span>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: 'none' }}>{form.name}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{ borderBottom: 'none' }}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Patient DOB:</span>
                                    </TableCell>
                                    <TableCell style={{ borderBottom: 'none' }}>{Moment(form.dob).format('D MMMM YYYY')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            );
        })()}
    </Paper>
);

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: 157,
        marginBottom: 36,
        boxShadow: 'none',
    },
    disabled: {
        opacity: 0.5,
    },
    buttonItem: {
        justifyContent: 'flex-end',
        padding: 4,
    },
    datePicker: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
    },
    detailLabel: {
        flex: 0.5,
    },
    detail: {
        flex: 1,
    },
    detailInput: {
        flex: 1,
        paddingLeft: 8,
    },
    detailInputName: {
        flex: 1,
        paddingLeft: 8,
        fontWeight: 'bold',
    },
    detailInputDate: {
        flex: 1,
        height: 32,
        paddingLeft: 8,
        '& > div': {
            display: 'flex',
            zIndex: 9999,
        },
        '& > div:first-child > div:first-child': {
            flex: 1,
        },
    },
};

export default compose(withStyles(styles), PatientContainer)(PdfPatient);

import React from 'react';
import { compose, withState } from 'recompose';
import Moment from 'moment';
import some from 'lodash/some';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { BLACK } from '../../style/constants';
import ConfirmActionDialog from '../ConfirmActionDialog';

const SignOffDialog = ({
  anchorEl,
  authorName,
  classes,
  groupNotes,
  isSignedOff,
  loading,
  noteId,
  onSign,
  open,
  signedOffTime,
  updateOpen,
  updateAnchorEl,
}) => (
  <div>
    <Tooltip
      title={isSignedOff ? 'Unlock Note' : 'Lock Note'}
      placement="bottom-start"
    >
      <IconButton
        color="inherit"
        aria-label="SignOff"
        className={classes.iconButton}
        disabled={
          (groupNotes && groupNotes.length !== 0
            ? !some(groupNotes, 'id')
            : !noteId) || loading
        }
        onClick={() => updateOpen(true)}
      >
        {isSignedOff ? <Lock /> : <LockOpen />}
      </IconButton>
    </Tooltip>

    <ConfirmActionDialog
      open={open}
      text={
        isSignedOff
          ? 'Do you want to edit signed note?'
          : 'Editing will be locked after sign off.'
      }
      onConfirm={() => {
        onSign();
        updateOpen(false);
      }}
      onClose={() => updateOpen(false)}
      confirmText={isSignedOff ? 'Yes' : 'Sign Off'}
      cancelText={isSignedOff ? 'No' : undefined}
    />
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => updateAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.popover}>
        <Typography className={classes.popoverText}>{authorName}</Typography>
        <Typography className={classes.popoverText}>
          {Moment(signedOffTime).format('llll')}
        </Typography>
      </div>
    </Popover>
  </div>
);

const styles = {
  iconButton: ({ isSignedOff }) => ({
    color: isSignedOff ? 'green' : BLACK,
  }),
  text: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  popover: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  popoverText: {
    color: 'green',
  },
};

export default compose(
  withStyles(styles),
  withState('open', 'updateOpen', false),
  withState('anchorEl', 'updateAnchorEl', null)
)(SignOffDialog);

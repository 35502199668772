import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClipboardList,
    faFileExport,
    faFolder,
    faImages,
    faList,
    faPaperclip,
    faPaste,
    faPen,
    faUserPlus,
    faUsers,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import { withStyles } from '@material-ui/core/styles';
import { PRIMARY, SECONDARY } from '../../style/constants';

import { dialogOpen } from '../../data/redux/actions/dialog';
import { episodeSelected } from '../../data/redux/actions/episode';
import { patientCleared } from '../../data/redux/actions/patient';
import ErrorAlertDialog from '../ErrorAlertDialog';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_TEXT_NOTE } from './gql';

const FloatingActionButtons = ({
    classes,
    isAdmin,
    isClinician,
    isReceptionist,
    isSubscriptionValid,
    open,
    patient,
    episode,
    textNote,
    mediaNote,
    formTemplate,
    formNote,
    webDrawingNote,
    onClick,
    onRedirectSubscribe,
    onNewAttachment,
    onNewPatient,
    onNewEpisode,
    onNewFormTemplate,
    onNewFormNote,
    onNewUser,
    onNewTemplateImage,
    user,
    consentForm,
    onNewConsentForm,
    consentFormList,
    onOpenConsentFormList,
    showError,
    onHideError,
    templateLibrary,
    onOpenTemplateLibrary,
    history,
    match,
    episodeId,
    isConsentFormSubscription,
    onShowError,
    userId,
}) => {
    const [createNewTextNote] = useMutation(CREATE_NEW_TEXT_NOTE, {
        onCompleted: (res) => {
            if (res && res.createNewTextNote) {
                console.log('Successfully created new note', res.createNewTextNote);
                switch (episodeId) {
                    case undefined:
                        return history.push(`/patient/${match.params.patientId}/note/text/${res.createNewTextNote.note.id}`);
                    case null:
                        return history.push(`/patient/${match.params.patientId}/note/text/${res.createNewTextNote.note.id}?episodeId`);
                    default:
                        return history.push(`/patient/${match.params.patientId}/note/text/${res.createNewTextNote.note.id}?episodeId=${episodeId}`);
                }
            }
        },
        variables: {
            userId: userId,
            patientId: match.params.patientId,
            episodes: [episodeId],
        },
    });

    const onNewTextNote = async () => {
        if (isConsentFormSubscription) {
            onShowError();
            return undefined;
        }

        await createNewTextNote();
    };

    const onNewWebDrawingNote = async () => {
        if (isConsentFormSubscription) {
            onShowError();
            return undefined;
        }
        switch (episodeId) {
            case undefined:
                return history.push(`/patient/${match.params.patientId}/note/templates`);
            case null:
                return history.push(`/patient/${match.params.patientId}/note/templates?episodeId`);
            default:
                return history.push(`/patient/${match.params.patientId}/note/templates?episodeId=${episodeId}`);
        }
    };

    return (
        <div>
            {showError && (
                <ErrorAlertDialog
                    onExisted={onHideError}
                    error={{
                        message: 'Please upgrade to full version plan to use this function.',
                    }}
                />
            )}
            <SpeedDial
                ariaLabel='Action Button'
                className={classes.speedDial}
                classes={{
                    root: classes.root,
                    actions: classes.actions,
                    actionsClosed: classes.actionsClosed,
                }}
                icon={<SpeedDialIcon />}
                onClick={onClick}
                transitionDuration={0}
                open={open}
                color='primary'>
                {patient && (isClinician || isReceptionist || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faUsers} size='sm' />}
                        tooltipTitle='New Patient'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewPatient}
                    />
                )}
                {episode && (isClinician || isReceptionist || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faFolder} />}
                        tooltipTitle='New Episode'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewEpisode}
                    />
                )}
                {textNote && (isClinician || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faFileAlt} />}
                        tooltipTitle='New Text Note'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewTextNote}
                    />
                )}
                {mediaNote && (isClinician || isReceptionist || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faPaperclip} />}
                        tooltipTitle='New Attachment'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewAttachment}
                    />
                )}
                {formNote && (isClinician || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faPaste} />}
                        tooltipTitle='New Form Note'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewFormNote}
                    />
                )}
                {webDrawingNote && (isClinician || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faPen} />}
                        tooltipTitle='New Web Drawing Note'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewWebDrawingNote}
                    />
                )}
                {user && isAdmin && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faUserPlus} size='sm' />}
                        tooltipTitle='New User'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewUser}
                    />
                )}
                {formTemplate && (isClinician || isReceptionist || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faClipboardList} />}
                        tooltipTitle='New Form Template'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewFormTemplate}
                    />
                )}

                {formTemplate && (isClinician || isReceptionist || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faImages} />}
                        tooltipTitle='New Image Template'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewTemplateImage}
                    />
                )}

                {consentForm && (isClinician || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faWrench} />}
                        tooltipTitle='New Consent Form'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onNewConsentForm}
                    />
                )}

                {consentFormList && (isClinician || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faList} />}
                        tooltipTitle='Consent Form List'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onOpenConsentFormList}
                    />
                )}

                {templateLibrary && (isClinician || isAdmin) && (
                    <SpeedDialAction
                        icon={<FontAwesomeIcon icon={faFileExport} />}
                        tooltipTitle='Templates Library'
                        onClick={!isSubscriptionValid ? onRedirectSubscribe : onOpenTemplateLibrary}
                    />
                )}
            </SpeedDial>
        </div>
    );
};

const styles = {
    root: {
        pointerEvents: 'none',
    },
    actions: {
        pointerEvents: 'auto',
        marginBottom: 0,
        paddingBottom: 16,
    },
    actionsClosed: {
        pointerEvents: 'none',
    },
    speedDial: {
        position: 'fixed',
        bottom: 44,
        right: 24,
    },
    button: {
        backgroundColor: SECONDARY,
        '&:hover': {
            backgroundColor: SECONDARY,
        },
        pointerEvents: 'auto',
    },
    buttonActive: {
        backgroundColor: SECONDARY,
        '&:hover': {
            backgroundColor: SECONDARY,
        },
        pointerEvents: 'auto',
    },
    buttonAction: {
        height: 56,
        width: 56,
        marginLeft: 0,
        backgroundColor: PRIMARY,
        color: 'white',
        pointerEvents: 'auto',
    },
    attachmentIcon: {
        transform: 'rotate(270deg)',
    },
};

export default compose(
    connect(({ user }) => ({
        userId: user.id,
        isClinician: user.roles.includes('CLINICIAN'),
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        isSubscriptionValid: user.isSubscriptionValid,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withRouter,
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
    })),
    withStyles(styles),
    withState('open', 'updateOpen', false),
    withState('showError', 'updateShowError', false),
    withHandlers({
        onShowError:
            ({ updateShowError }) =>
            () =>
                updateShowError(true),
        onHideError:
            ({ updateShowError }) =>
            () =>
                updateShowError(false),
    }),
    withHandlers({
        onClick:
            ({ open, updateOpen }) =>
            () =>
                updateOpen(!open),
        onClose:
            ({ updateOpen }) =>
            () =>
                updateOpen(false),
        onOpen:
            ({ updateOpen }) =>
            () =>
                updateOpen(true),
        onRedirectSubscribe:
            ({ history, isAdmin }) =>
            () => {
                const routePath = '/subscriptionExpired';
                history.replace(routePath);
            },
        onNewPatient:
            ({ dispatch, history, location, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                    return undefined;
                }
                dispatch(patientCleared());
                if (location.pathname.includes('patient')) {
                    return history.replace('/patient/new');
                }
                return history.push('/patient/new');
            },
        onNewEpisode:
            ({ dispatch, history, match, location, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                    return undefined;
                }
                dispatch(episodeSelected('new', 'New Episode'));
                if (location.pathname.includes('notes')) {
                    return history.replace(`/patient/${match.params.patientId}/notes?episodeId=new`);
                }
                return history.push(`/patient/${match.params.patientId}/notes?episodeId=new`);
            },
        onNewFormNote:
            ({ history, match, episodeId, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                    return undefined;
                }
                switch (episodeId) {
                    case undefined:
                        return history.push(`/patient/${match.params.patientId}/note/form`);
                    case null:
                        return history.push(`/patient/${match.params.patientId}/note/form?episodeId`);
                    default:
                        return history.push(`/patient/${match.params.patientId}/note/form?episodeId=${episodeId}`);
                }
            },
        onNewAttachment:
            ({ history, match, episodeId, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                    return undefined;
                }
                switch (episodeId) {
                    case undefined:
                        return history.push(`/patient/${match.params.patientId}/attachments`);
                    case null:
                        return history.push(`/patient/${match.params.patientId}/attachments?episodeId`);
                    default:
                        return history.push(`/patient/${match.params.patientId}/attachments?episodeId=${episodeId}`);
                }
            },
        onNewFormTemplate:
            ({ history, location, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                    return undefined;
                }
                return location.pathname.includes('formTemplate') ? history.replace('/formTemplate') : history.push('/formTemplate');
            },
        onNewUser:
            ({ dispatch, isConsentFormSubscription, onShowError }) =>
            () => {
                if (isConsentFormSubscription) {
                    onShowError();
                } else {
                    dispatch(dialogOpen({ type: 'user' }));
                }
            },
        onNewConsentForm:
            ({ dispatch, history, location }) =>
            () => {
                if (location.pathname.includes('/consent-form-builder')) {
                    return history.replace('/consent-form-builder');
                }
                return history.push('/consent-form-builder');
            },
        onNewConsentFormDashboard:
            ({ dispatch, history, location }) =>
            () => {
                if (location.pathname.includes('/consent-form-dashboard')) {
                    return history.replace('/consent-form-dashboard');
                }
                return history.push('/consent-form-dashboard');
            },

        onOpenTemplateLibrary:
            ({ dispatch, history, location }) =>
            () => {
                if (location.pathname.includes('/library')) {
                    return history.replace('/library');
                }
                return history.push('/library');
            },
    }),
    withProps(() => ({
        isTouch: document && 'ontouchstart' in document.documentElement,
    }))
)(FloatingActionButtons);

import React from 'react';

import { compose, lifecycle, withState } from 'recompose';

import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { BLACK, SECONDARY } from '../../style/constants';
import EpisodeListPopover from '../EpisodeListPopover';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EpisodePopover = ({
    anchorEl,
    classes,
    groupNotes,
    noteId,
    onTag,
    selectedEpisodes,
    tempEpisodes,
    updateTempEpisodes,
    updateAnchorEl,
    updateEpisodes,
    loading,
}) => (
    <React.Fragment>
        <Tooltip title='Episode' placement='bottom-start'>
            <span>
                <IconButton
                    color='inherit'
                    disabled={loading}
                    onClick={(event) => {
                        updateAnchorEl(event.currentTarget);
                    }}
                    aria-label='Episode List'>
                    <FontAwesomeIcon icon={faFolder} />
                </IconButton>
            </span>
        </Tooltip>
        <Modal aria-labelledby='category-list' aria-describedby='category-list' open={Boolean(anchorEl)}>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={async () => {
                    if (!isEqual(tempEpisodes.sort(), selectedEpisodes.sort())) {
                        await updateEpisodes(tempEpisodes);
                        if (noteId && onTag) {
                            await onTag();
                        } else if (some(groupNotes, 'id')) {
                            groupNotes.forEach((note) => onTag(note.id));
                        }
                    }
                    updateAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                elevation={2}>
                <div className={classes.wrapper}>
                    <EpisodeListPopover tempEpisodes={tempEpisodes} updateTempEpisodes={updateTempEpisodes} />
                </div>
            </Popover>
        </Modal>
    </React.Fragment>
);

const styles = {
    wrapper: {
        width: 256,
        height: 286,
    },
    dateRangeIcon: ({ tempEpisodes, loading }) => ({
        color: tempEpisodes.length !== 0 ? SECONDARY : BLACK,
        opacity: loading ? 0.26 : 1,
    }),
};

export default compose(
    withState('tempEpisodes', 'updateTempEpisodes', []),
    withState('anchorEl', 'updateAnchorEl', null),
    lifecycle({
        componentDidMount() {
            this.props.updateTempEpisodes(this.props.selectedEpisodes);
        },
        componentDidUpdate(prevProps) {
            const { selectedEpisodes, updateTempEpisodes } = this.props;

            if (!!selectedEpisodes && !!prevProps.selectedEpisodes && selectedEpisodes.length !== prevProps.selectedEpisodes.length) {
                updateTempEpisodes(selectedEpisodes);
            }
        },
    }),
    withStyles(styles)
)(EpisodePopover);

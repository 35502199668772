import {
  FORM_TEMPLATE_UPSERT,
  FORM_TEMPLATE_CLEARED,
} from '../actions/formTemplate';

const formTemplateDefaultState = {
  id: undefined,
  name: undefined,
  sections: [],
  rows: 0,
};

const formTemplateReducer = (state = formTemplateDefaultState, action) => {
  switch (action.type) {
    case FORM_TEMPLATE_UPSERT:
      return {
        id: action.id || state.id,
        name: action.name || state.name,
        sections: action.sections || state.sections,
        rows: action.rows || state.rows,
      };
    case FORM_TEMPLATE_CLEARED:
      return {
        id: undefined,
        name: undefined,
        sections: [],
        rows: 0,
      };
    default:
      return state;
  }
};

export default formTemplateReducer;

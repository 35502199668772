import React from 'react';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import Moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPaste } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt, faFileImage } from '@fortawesome/free-regular-svg-icons';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, SECONDARY } from '../../style/constants';
import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import { LIST_UNSIGNED_NOTES } from './gql';
import { noteUpsert } from '../../data/redux/actions/note';
import { patientSelected } from '../../data/redux/actions/patient';

const UnsignedNotesList = ({ classes, hasNextPage, history, onListEnd, userId, dispatch }) => {
    const { data, loading, error } = useQuery(LIST_UNSIGNED_NOTES, {
        variables: {
            userId,
            offset: 0,
            limit: 10,
        },
        refetchOnMount: false,
        fetchPolicy: 'cache-and-network',
    });

    const handleNoteSelect = async (note) => {
        await dispatch(
            noteUpsert({
                id: note.id,
                __typename: note.__typename,
                title: note.title,
                isSignedOff: note.isSignedOff,
                signedOffTime: note.signedOffTime,
            })
        );
        await dispatch(patientSelected(note.patient));

        switch (note.__typename) {
            case 'TextNote':
                return history.push(`/patient/${note.patient.id}/note/text/${note.id}`);
            case 'DrawingNote':
                if (note.group && note.group.id) {
                    return history.push(`/patient/${note.patient.id}/note/drawings/${note.group.id}`);
                }
                return history.push(`/patient/${note.patient.id}/note/drawing/${note.id}`);
            case 'FormNote':
                return history.push(`/patient/${note.patient.id}/note/form/${note.id}`);
            case 'MediaNote':
                return history.push(`/patient/${note.patient.id}/attachments/${note.id}`);
            default:
                return null;
        }
    };

    return (
        <InfiniteScroll
            className={classes.infinite}
            pageStart={0}
            hasMore={hasNextPage}
            // TODO: This should stop the infinite scroll on /dashboard but need to check
            useWindow={false}
            loader={<Loading key={1} height={100} />}
            loadMore={onListEnd}>
            <List disablePadding>
                {(() => {
                    const notes = _.get(data, 'listUnsignedNotes', []);
                    if (loading) return <Loading height={100} />;
                    if (!loading && !notes.length)
                        return (
                            <Paper>
                                <EmptyData text='There are no notes available' />
                            </Paper>
                        );
                    if (error)
                        return (
                            <Paper>
                                <ErrorMessage error={error} />
                            </Paper>
                        );

                    return notes.map((note) => {
                        let iconName = faPaperclip;

                        if (note.__typename === 'TextNote') {
                            iconName = faFileAlt;
                        }
                        if (note.__typename === 'DrawingNote') {
                            iconName = faFileImage;
                        }
                        if (note.__typename === 'FormNote') {
                            iconName = faPaste;
                        }

                        return (
                            <ListItem
                                key={note.id}
                                className={classes.item}
                                onClick={() => handleNoteSelect(note)}
                                button
                                divider
                                TouchRippleProps={{ style: { color: PRIMARY } }}>
                                <div className={classes.titleWrapper}>
                                    <FontAwesomeIcon icon={iconName} color={SECONDARY} />
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography noWrap variant='subtitle1' className={classes.title}>
                                                {note.title}
                                            </Typography>
                                        }
                                    />
                                </div>
                                <ListItemText className={classes.text} secondary={note.patient ? note.patient.name : ''} />
                                <ListItemText className={classes.text} secondary={Moment(note.createdAt).format('llll')} />
                            </ListItem>
                        );
                    });
                })()}
            </List>
        </InfiniteScroll>
    );
};

const styles = {
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 12,
    },
    title: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    text: {
        margin: 0,
        padding: 0,
    },
    infinite: {
        display: 'flex',
        flexDirection: 'column',
    },
    item: {
        minHeight: 30,
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'hidden',
    },
};

export default compose(
    connect(({ user }) => ({
        userId: user.id,
    })),
    withStyles(styles)
)(UnsignedNotesList);

import React from 'react';


import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenu from '../../components/AppBarMenu';
import FormTemplateList from '../../components/FormTemplateList';

const SelectFormTemplatePage = ({ classes }) => (
  <AppBarMenu>
    <Grid component="main" className={classes.main} container>
      <Grid
        className={classes.templateList}
        container
        wrap="nowrap"
        direction="column"
        item
        xs={12}
        sm={11}
        md={10}
        lg={8}
        xl={6}
      >
        <Paper>
          <FormTemplateList />
        </Paper>
      </Grid>
    </Grid>
  </AppBarMenu>
);

const styles = {
  main: {
    height: '100%',
    paddingTop: 64,
  },
  templateList: {
    paddingTop: 28,
    paddingBottom: 56,
    paddingLeft: 88,
    paddingRight: 44,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export default withStyles(styles)(SelectFormTemplatePage);

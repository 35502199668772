import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const NumberScale100SectionPreview = ({
  numberScale100Value,
  title,
  classes = {},
}) => {
  let scaleValue = [0, 0];
  if (numberScale100Value) {
    scaleValue = numberScale100Value.split(';');
    if (scaleValue.length === 1) {
      scaleValue = [...[0], ...scaleValue];
    }
  }
  return (
    <Grid item xs>
      <div className={classes.text}>
        <Typography style={{ marginBottom: 40 }} variant="body1">
          {title}
        </Typography>
        <Slider
          value={scaleValue || [0, 0]}
          min={0}
          step={1}
          max={100}
          disabled
          valueLabelDisplay="on"
          aria-labelledby="non-linear-slider"
        />
      </div>
    </Grid>
  );
};

export default NumberScale100SectionPreview;

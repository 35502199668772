import { gql } from '@apollo/client';

const UPSERT_INVOICE = gql`
  mutation upsertInvoice(
    $comments: String
    $dateDue: Date
    $encodedPatientId: ID!
    $id: ID
    $invoiceAccountId: ID
    $invoiceDate: DateTime
    $invoiceItemIds: [ID]
    $locationId: ID!
    $notes: String
    $practiceId: ID!
    $status: InvoiceStatus
  ) {
    upsertInvoice(
      comments: $comments
      dateDue: $dateDue
      encodedPatientId: $encodedPatientId
      id: $id
      invoiceAccountId: $invoiceAccountId
      invoiceDate: $invoiceDate
      invoiceItemIds: $invoiceItemIds
      locationId: $locationId
      notes: $notes
      practiceId: $practiceId
      status: $status
    ) {
      id
      dateDue
      status
    }
  }
`;

export default UPSERT_INVOICE;

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { PRIMARY } from '../../../style/constants';

import Loading from '../../../components/Loading';
import ErrorMessage from '../../../components/ErrorMessage';
import PatientSelectContainer from '../../../components/PatientList/patientSelectContainer';
import { isValidEmail } from '../../../utility/string';

const PatientSelect = ({ classes, error, patientId, patients, handleEmailSelect, hasNextPage, onListEnd, searchText, status, form, setForm }) => {
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isEmailSelected, setIsEmailSelected] = useState(false);
    const onClick = (patient) => {
        setForm({
            ...form,
            patient,
        });
    };

    useEffect(() => {
        if (searchText === '') {
            setIsInvalidEmail(false);
            setShowError(false);
        }
        // use regex to check if email is valid
        if (searchText !== '' && !isValidEmail(searchText)) {
            setIsInvalidEmail(true);
        } else {
            setIsInvalidEmail(false);
        }
    }, [searchText]);

    const handleCheckEmailSelect = () => {
        setIsEmailSelected(!isEmailSelected);
        if (isInvalidEmail) {
            setShowError(true);
        } else {
            handleEmailSelect(searchText);
        }
    };

    return (
        <div className={classes.infiniteWrapper}>
            <InfiniteScroll
                className={classes.infinite}
                pageStart={0}
                hasMore={hasNextPage}
                // useWindow={false}
                loader={<Loading key={1} height={100} />}
                loadMore={onListEnd}>
                {(() => {
                    if (status.loading || status.setVariables || status.refetching) return <Loading height={100} />;
                    if (status.error) return <ErrorMessage error={error} />;

                    return (
                        <List disablePadding>
                            {patients &&
                                patients.map((patient) => (
                                    <ListItem
                                        key={patient.id}
                                        className={classes.row}
                                        onClick={() => onClick(patient)}
                                        button
                                        divider
                                        TouchRippleProps={{ style: { color: PRIMARY } }}>
                                        <Grid className={classes.item}>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        variant='subtitle1'
                                                        className={patient.id === patientId ? classes.activeTitle : classes.title}>
                                                        {patient.name}
                                                    </Typography>
                                                }
                                            />
                                            <ListItemText className={classes.text} secondary={patient.phone} />
                                            <ListItemText className={classes.text} secondary={patient.email} />
                                        </Grid>
                                        {patient.id === patientId && <FontAwesomeIcon icon={faCheck} />}
                                    </ListItem>
                                ))}
                            {searchText.includes('@') && (
                                <ListItem
                                    className={classes.row}
                                    onClick={handleCheckEmailSelect}
                                    button
                                    divider
                                    TouchRippleProps={{ style: { color: PRIMARY } }}>
                                    <Grid className={classes.item}>
                                        <ListItemText className={classes.text} primary='Would you like to send directly to this email address?' />
                                        <ListItemText className={classes.text} secondary={searchText} />
                                        {showError && isInvalidEmail && <ErrorMessage text='Invalid Email Format. Please try again.' />}
                                    </Grid>
                                    {isEmailSelected && <FontAwesomeIcon icon={faCheck} />}
                                </ListItem>
                            )}
                        </List>
                    );
                })()}
            </InfiniteScroll>
        </div>
    );
};

const styles = {
    infiniteWrapper: {
        height: ({ height }) => height,
        overflow: 'auto',
    },
    infinite: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    item: {
        minHeight: 100,
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    title: {
        paddingBottom: 18,
    },
    activeTitle: {
        paddingBottom: 18,
        color: PRIMARY,
    },
    text: {
        padding: 0,
    },
};

export default compose(PatientSelectContainer, withStyles(styles))(PatientSelect);

import React from 'react';
import { CustomPicker } from 'react-color';
import {
  Saturation,
  Hue,
  EditableInput,
} from 'react-color/lib/components/common';
import { Paper } from '@material-ui/core';

const styles = {
  wrapper: {
    padding: '10px',
  },
  saturation: {
    position: 'relative',
    width: '225px',
    height: '100px',
    borderRadius: '2px 2px 0 0',
  },
  hue: {
    position: 'relative',
    height: '10px',
  },
  input: {
    fontSize: '11px',
    color: '#333',
    width: '100%',
    borderRadius: '2px',
    border: 'none',
    boxShadow: 'inset 0 0 0 1px #dadada',
    height: '21px',
    textAlign: 'center',
    marginTop: '10px',
  },
};

const ColorPicker = props => {
  const { color } = props;
  const hex = color.hex || color;

  return (
    <Paper>
      <div style={styles.saturation}>
        <Saturation {...props} />
      </div>
      <div style={styles.wrapper}>
        <div style={styles.hue}>
          <Hue {...props} />
        </div>
        <EditableInput style={{ input: styles.input }} value={hex} {...props} />
      </div>
    </Paper>
  );
};

export default CustomPicker(ColorPicker);

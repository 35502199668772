import React, { useState } from 'react';

import { compose } from 'recompose';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useLazyQuery } from '@apollo/client';
import { CHECK_SITEID_VALIDITY } from './gql';

const MindbodySiteCodeIntegrationDialog = ({ classes, onCloseDialog, showDialog, siteCodeChange, practiceEncodedId }) => {
    const defaultSiteCode = '';
    const [siteCode, setSiteCode] = useState(defaultSiteCode);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [checkSiteIdValidity] = useLazyQuery(CHECK_SITEID_VALIDITY, {
        onCompleted: async (res) => {
            if (res && res.checkSiteIdValidity) {
                if (res.checkSiteIdValidity.valid === true) {
                    await onCloseDialog('ok');
                    setErrors({});
                    setLoading(false);
                    setSiteCode(defaultSiteCode);
                } else {
                    setLoading(false);
                    setErrors({ siteCode: 'That site id is already in use' });
                }
            }
        },
        onError: () => {
            setLoading(false);
            setErrors({ siteCode: 'Something went wrong. Please try again' });
        },
    });

    const handleSiteCodeChange = (newSiteCode) => {
        if (newSiteCode === '' || Number.isNaN(newSiteCode)) {
            setErrors({ siteCode: 'You must enter a valid site id' });
        } else {
            setErrors({});
        }

        setSiteCode(newSiteCode);
        siteCodeChange(newSiteCode);
    };

    const checkSiteCodeValidity = async () => {
        setLoading(true);
        await checkSiteIdValidity({
            variables: { siteId: siteCode.toString(), encodedPracticeId: practiceEncodedId },
        });
    };

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} maxWidth='lg'>
            <DialogTitle>Mindbody Integration</DialogTitle>

            <DialogContent>
                <Typography className={classes.dialogContent}>
                    Please enter your Mindbody Site Id and desired location to begin your integration.
                </Typography>

                <TextField
                    id='sitecode'
                    margin='normal'
                    label='Mindbody Site Id'
                    autoFocus
                    fullWidth
                    value={siteCode || ''}
                    error={!!errors.siteCode}
                    helperText={errors.siteCode && errors.siteCode}
                    onChange={(e) => handleSiteCodeChange(e.target.value)}
                />
                <br />
                <Link
                    variant='caption'
                    component='a'
                    underline='always'
                    href='https://support.mindbodyonline.com/s/article/206398178-How-do-I-find-my-Client-ID?language=en_US'
                    target='_blank'
                    className={classes.white}>
                    How do I find my Mindbody Site Id?
                </Link>
            </DialogContent>

            <DialogActions>
                <Button
                    color='primary'
                    disabled={loading === true}
                    onClick={async () => {
                        onCloseDialog('cancel');
                    }}>
                    Cancel
                </Button>
                <Button
                    color='primary'
                    onClick={async () => {
                        checkSiteCodeValidity();
                    }}
                    disabled={errors.sitecode !== undefined || loading === true}>
                    {loading ? 'Loading...' : 'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'space-between',
        width: '100%',
        height: '100%',
    },
    title: {
        width: 100,
    },
    selectField: {
        width: '100%',
        height: 500,
    },
    field: {
        width: '100%',
    },
    dialogContent: {
        width: 600,
    },
    redDialogContent: {
        marginTop: 10,
        width: '100%',
        maxWidth: 600,
        color: 'red',
    },
};

export default compose(withStyles(styles))(MindbodySiteCodeIntegrationDialog);

import React from 'react';
import { compose } from 'recompose';
import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import GroupDrawingNoteContainer from './groupDrawingNoteContainer';
import CategoryListPopover from '../CategoryListPopover';
import DeleteNoteDialog from '../DeleteNoteDialog';
import DrawingSVG from '../DrawingSVG';
import DuplicateDialog from '../DuplicateDialog';
import EmptyData from '../EmptyData';
import EpisodePopover from '../EpisodePopover';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import MoreInfoPopover from '../MoreInfoPopover';
import SignOffDialog from '../SignOffDialog';
import ErrorAlertDialog from '../ErrorAlertDialog';
import PrintNote from '../PrintNote';

const GroupDrawingNote = ({
    canEdit,
    classes,
    error,
    form,
    index,
    isAdmin,
    isClinician,
    isReceptionist,
    onArchiveNote,
    onDuplicate,
    onChevronLeft,
    onChevronRight,
    onGroupForEachSave,
    onSign,
    onTag,
    status,
    updateForm,
    onEditNote,
    isHideAction,
}) => {
    return (
        <Grid>
            {form.error && !form.loading && (
                <ErrorAlertDialog error={form.error} onExisted={() => updateForm((prevForm) => ({ ...prevForm, error: undefined }))} />
            )}
            <Grid className={classes.titleRow}>
                <TextField
                    id='title'
                    autoComplete='off'
                    label='Title'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        classes: {
                            disabled: classes.disabledTextField,
                        },
                    }}
                    placeholder='Untitled Drawing Note'
                    autoFocus
                    disabled={!canEdit || status.loading}
                    className={classes.inputTitle}
                    value={form.title || ''}
                    onChange={async (event) => {
                        let shouldSave = false;
                        if (event.target.value) shouldSave = true;
                        await updateForm({
                            ...form,
                            title: event.target.value,
                            autoFocus: 'title',
                        });
                        if (shouldSave) onGroupForEachSave();
                    }}
                />
                {isHideAction || (
                    <Grid className={classes.buttons}>
                        {form.loading && <Typography variant='body1'>Saving ...</Typography>}

                        {((form.isAuthor && (isClinician || isAdmin)) || form.isSignedOff) && (
                            <Tooltip title='Drawing' placement='bottom-start'>
                                <IconButton
                                    color='inherit'
                                    aria-label='SignOff'
                                    className={classes.iconButton}
                                    disabled={form.isSignedOff || status.loading}
                                    onClick={() => onEditNote()}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )}

                        {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                            <EpisodePopover
                                groupNotes={form.groupNotes}
                                selectedEpisodes={form.episodeIdsArray}
                                onTag={onTag}
                                loading={status.loading}
                                updateEpisodes={(episodeIdsArray) => {
                                    updateForm({ ...form, episodeIdsArray });
                                }}
                            />
                        )}
                        {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                            <CategoryListPopover
                                groupNotes={form.groupNotes}
                                selectedCategories={form.categoryIdsArray}
                                onTag={onTag}
                                loading={status.loading}
                                updateCategories={(categoryIdsArray) =>
                                    updateForm({
                                        ...form,
                                        categoryIdsArray: categoryIdsArray || [],
                                    })
                                }
                            />
                        )}
                        {((form.isAuthor && (isClinician || isAdmin)) || form.isSignedOff) && (
                            <SignOffDialog
                                groupNotes={form.groupNotes}
                                isSignedOff={form.isSignedOff}
                                signedOffTime={form.signedOffTime}
                                onSign={onSign}
                                loading={status.loading}
                                authorName={form.authorName}
                            />
                        )}
                        {(isClinician || isAdmin) && <DuplicateDialog groupId={form.groupId} loading={status.loading} onDuplicate={onDuplicate} />}

                        <MoreInfoPopover
                            authorName={form.author && form.author.name}
                            createdAt={form.createdAt}
                            episodes={form.episodes}
                            groupId={form.groupId}
                            loading={status.loading}
                            patientName={form.patientName}
                            signedOffTime={form.signedOffTime}
                        />

                        {form.isAuthor && (isClinician || isAdmin) && (
                            <DeleteNoteDialog
                                isSignedOff={form.isSignedOff}
                                loading={status.loading}
                                noteId={form.groupId}
                                onArchiveNote={onArchiveNote}
                            />
                        )}

                        {form.isAuthor && (isClinician || isAdmin) && (
                            <PrintNote form={form} typename='DrawingNote' loading={status.loading} noteId={form.id} onPrintNote={() => {}} />
                        )}
                    </Grid>
                )}
            </Grid>
            <Paper className={classes.paper}>
                {(() => {
                    if (status.loading) return <Loading />;
                    if (status.error) return <ErrorMessage text={error.message} />;

                    return (
                        <Grid container direction='column'>
                            <Grid container alignItems='center' justifyContent='center' className={classes.pageNumberRow}>
                                <IconButton disabled={index === 0} onClick={onChevronLeft}>
                                    <ChevronLeft />
                                </IconButton>
                                <Typography variant='body1' style={styles.pageText}>
                                    Page {index + 1} of {form.groupNotes.length}
                                </Typography>
                                <IconButton disabled={index === form.groupNotes.length - 1} onClick={onChevronRight}>
                                    <ChevronRight />
                                </IconButton>
                            </Grid>
                            {get(form, `groupNotes[${index}].drawingData`) || get(form, `groupNotes[${index}].template.file.encodedFile`) ? (
                                <DrawingSVG
                                    className={classes.drawing}
                                    drawingData={get(form, `groupNotes[${index}].drawingData`)}
                                    templateData={get(form, `groupNotes[${index}].template.file.encodedFile`)}
                                />
                            ) : (
                                <Grid className={classes.emptyData}>
                                    <EmptyData text='No Drawing Input' />
                                </Grid>
                            )}
                        </Grid>
                    );
                })()}
            </Paper>
        </Grid>
    );
};

const styles = {
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 18,
    },
    paper: {
        position: 'relative',
        marginBottom: 36,
        padding: 20,
    },
    disabled: {
        opacity: 0.5,
    },
    disabledTextField: {
        color: 'black',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 16,
    },
    inputTitle: {
        flexBasis: '65%',
        fontWeight: 'bold',
    },
    drawing: {
        height: '100%',
        width: '100%',
    },
    emptyData: {
        border: [1, 'solid', 'lightgray'],
        height: '100%',
        width: '100%',
    },
    pageText: {
        paddingLeft: 16,
        paddingRight: 16,
    },
};

export default compose(GroupDrawingNoteContainer, withStyles(styles))(GroupDrawingNote);

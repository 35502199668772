import React, { useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { withStyles } from '@material-ui/core/styles';

import './calendar.css';
import CarouselControls from './CarouselControls';
import { aMonthBefore, getThreeMonthsArray } from '../../../utility/date';

const firstCalendar = React.createRef();
const secondCalendar = React.createRef();
const thirdCalendar = React.createRef();

const CalendarCarousel = ({ classes, handleDateSelect, todayStr }) => {
  const [currentDate, setCurrentDate] = useState(todayStr);

  const goNextMonth = (nextDateStr) => {
    setCurrentDate(nextDateStr);
    const nextThreeMonths = getThreeMonthsArray(nextDateStr);
    firstCalendar.current
      .getApi()
      .changeView('dayGridMonth', nextThreeMonths[0]);
    secondCalendar.current
      .getApi()
      .changeView('dayGridMonth', nextThreeMonths[1]);
    thirdCalendar.current
      .getApi()
      .changeView('dayGridMonth', nextThreeMonths[2]);
  };

  return (
    <div className={classes.carouselContainer}>
      <CarouselControls currentDate={currentDate} goNextMonth={goNextMonth} />
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'title',
          center: '',
          right: '',
        }}
        initialView="dayGridMonth"
        initialDate={aMonthBefore(true, todayStr)}
        ref={firstCalendar}
        selectable
        select={handleDateSelect}
        showNonCurrentDates={false}
        fixedWeekCount={false}
      />
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'title',
          center: '',
          right: '',
        }}
        initialView="dayGridMonth"
        ref={secondCalendar}
        selectable
        select={handleDateSelect}
        showNonCurrentDates={false}
        fixedWeekCount={false}
      />
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'title',
          center: '',
          right: '',
        }}
        initialView="dayGridMonth"
        initialDate={aMonthBefore(false, todayStr)}
        ref={thirdCalendar}
        selectable
        select={handleDateSelect}
        showNonCurrentDates={false}
        fixedWeekCount={false}
      />
    </div>
  );
};

const styles = {
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    minWidth: 270,
    borderRight: '1px solid #e0e0e0',
    '& .fc-view-harness': {
      height: '195px !important',
    },
    '& .fc-toolbar-title': {
      marginBottom: '10px',
    },
  },
};

export default withStyles(styles)(CalendarCarousel);

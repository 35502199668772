import { gql } from '@apollo/client';

export const CREATE_UPDATE_PAYMENT_METHOD_SESSION = gql`
    query createStripeUpdatePaymentMethodSession($customerToken: String) {
        createStripeUpdatePaymentMethodSession(customerToken: $customerToken) {
            checkoutUrl
        }
    }
`;

export const UPDATE_DEFAULT_PAYMENT_METHOD = gql`
    query updateDefaultPaymentMethod($customerToken: String, $checkoutSessionToken: String) {
        updateDefaultPaymentMethod(customerToken: $customerToken, checkoutSessionToken: $checkoutSessionToken) {
            success
        }
    }
`;

export const GET_CARD_DETAILS = gql`
    query getCreditCardDetails($customerToken: String) {
        getCreditCardDetails(customerToken: $customerToken) {
            expiryMonth
            expiryYear
            last4
            name
        }
    }
`;

import React from 'react';
import { compose, withState } from 'recompose';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Collections from '@material-ui/icons/Collections';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import MorePopover from '../MorePopover';

const GroupFormTemplateSection = ({
  autoFocus,
  classes,
  isAuth,
  isLoading,
  isOptimisticResponse,
  groupSections,
  onArchiveSection,
  onChangeGroupTitle,
  onFocus,
  onSave,
  onSaveSection,
  onSelectSection,
  pageNumber,
  rowNumber,
  section,
  updatePageNumber,
  updateReorderDialog,
  updateTemplateDialog,
}) => (
  <div className={classes.column}>
    <div className={classes.templateTitle}>
      <TextField
        id="title"
        autoComplete="off"
        disabled={!isAuth || (isLoading && !isOptimisticResponse)}
        className={classes.sectionTitle}
        value={section.title}
        InputProps={{
          className: classes.sectionTitleInput,
        }}
        autoFocus={
          autoFocus === section.rowIndexes.concat(section.columnIndexes).join()
        }
        onFocus={onFocus}
        onChange={async event => {
          let shouldSave = false;
          if (event.target.value) shouldSave = true;
          await onChangeGroupTitle(event.target.value, groupSections);
          if (shouldSave) onSave();
        }}
        fullWidth
      />
      <IconButton
        onClick={() => {
          onSelectSection(section);
          updateTemplateDialog(true);
        }}
        aria-label="More"
        color="inherit"
        disabled={!isAuth || isLoading}
      >
        <Collections />
      </IconButton>
      <MorePopover
        disabled={!isAuth || isLoading}
        display={
          <div className={classes.popOverButtons}>
            <Button
              onClick={async () => {
                await onArchiveSection(section, rowNumber);
                onSaveSection();
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() =>
                updateReorderDialog({
                  show: true,
                  selectedSection: section,
                })
              }
            >
              Reorder
            </Button>
          </div>
        }
      />
    </div>
    <div className={classes.imageWrapper}>
      <IconButton
        disabled={pageNumber === 0}
        onClick={() => {
          updatePageNumber(pageNumber - 1);
        }}
      >
        <ChevronLeft />
      </IconButton>
      <img
        className={classes.image}
        alt="Template"
        src={`data:image/png;base64,${_.get(groupSections, [
          pageNumber,
          'template',
          'file',
          'encodedFile',
        ])}`}
      />

      <IconButton
        disabled={pageNumber === groupSections.length - 1}
        onClick={() => {
          updatePageNumber(pageNumber + 1);
        }}
      >
        <ChevronRight />
      </IconButton>
    </div>
  </div>
);

const styles = {
  column: {
    flex: 1,
    padding: 6,
  },
  templateTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  sectionTitleInput: {
    '&:before': {
      borderBottomColor: 'transparent',
    },
    '&:hover:before': {
      borderBottomColor: 'transparent !important',
    },
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 320,
  },
  image: {
    height: 256,
    width: 224,
  },
  popOverButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default compose(
  withState('pageNumber', 'updatePageNumber', 0),
  withStyles(styles)
)(GroupFormTemplateSection);

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import AppBarMenu from '../../components/AppBarMenu';
import PageHeader from '../InvoicingPage/PageHeader';
import MindbodyInvoiceTable from './MindbodyInvoiceTable';
import { WARM_PINK } from '../../style/constants';
import { LIST_MINDBODY_INVOICES } from './gql';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';

const MindbodyInvoicePage = ({ practiceId, locationId, classes }) => {
    const { data, loading, error } = useQuery(LIST_MINDBODY_INVOICES, {
        variables: {
            encodedPracticeId: practiceId,
        },
        fetchPolicy: 'cache-and-network',
    });
    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                <PageHeader />
                {loading && <Loading backdrop />}
                {error && <ErrorMessage error={error} />}
                {data && <MindbodyInvoiceTable data={data.fetchMindbodyInvoices} />}
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    button: {
        width: 100,
        height: 40,
    },
    redButton: {
        width: 130,
        height: 36,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        practiceId: practice.id,
        locationId: user.currentLocationId,
    }))
)(MindbodyInvoicePage);

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { WARM_PINK } from '../../style/constants';

const PageHeader = ({ classes, integrationId }) => {
  const activeTab = window.location.pathname.split('/')[1];
  return (
    <Grid className={classes.bar} container spacing={6}>
      <Grid item className={classes.tabItem}>
        {integrationId && (
          <Link to="/mindbodyinvoices">
            <Typography
              variant="subtitle1"
              gutterBottom
              className={
                activeTab === 'mindbodyinvoices'
                  ? classes.activeTab
                  : classes.regularTab
              }
            >
              Mindbody Invoices
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid item className={classes.tabItem}>
        {!integrationId && (
          <Link to="/invoices">
            <Typography
              variant="subtitle1"
              gutterBottom
              className={
                activeTab === 'invoices'
                  ? classes.activeTab
                  : classes.regularTab
              }
            >
              Invoices
            </Typography>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  bar: {
    display: 'flex',
    justifyContent: 'center',
    // paddingTop: 31,
    paddingBottom: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 800,
  },
  activeTab: {
    color: WARM_PINK,
    borderBottom: `3px solid ${WARM_PINK}`,
  },
  regularTab: {
    color: 'black',
  },
  tabItem: {
    paddingLeft: '50 !important',
    paddingRight: '0 !important',
  },
};

export default compose(
  withStyles(styles),
  connect(({ integration }) => ({
    integrationId: integration.id,
  }))
)(PageHeader);

import React, { useState } from 'react';
import Ajv from 'ajv';
import { gql, useMutation } from '@apollo/client';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

import { MINOR_BLACK, PRIMARY, SECONDARY, WHITE } from '../../style/constants';
import { isPasswordValid } from '../../utility';
import FlashMessage from '../../components/FlashMessage';

const NEW_SIGNUP = gql`
    mutation newSignUp(
        $practiceName: String!
        $practiceABN: String
        $firstName: String!
        $lastName: String!
        $userEmail: String!
        $userPassword: String!
        $leadSource: String
    ) {
        newSignUp(
            practiceName: $practiceName
            practiceABN: $practiceABN
            firstName: $firstName
            lastName: $lastName
            userEmail: $userEmail
            userPassword: $userPassword
            leadSource: $leadSource
        ) {
            id
        }
    }
`;

const formDefaultValues = {
    firstName: '',
    lastName: '',
    userEmail: '',
    userPassword: '',
    userConfirm: '',
    practiceName: '',
    practiceABN: '',
    leadSource: '',
};

// const LEADSOURCE_OPTIONS = [
//   { value: 'google', name: 'Google' },
//   { value: 'clinician', name: 'Other Clinician' },
//   { value: 'publication', name: 'Industry Publication' },
//   { value: 'conference', name: 'Conference' },
//   { value: 'facebook', name: 'Facebook' },
//   { value: 'other', name: 'Other' },
// ];

const COMMON_ERROR_MESSAGE = 'Must be at least 2 characters';

const validateForm = (form) => {
    const errors = {};
    if (!form.firstName || form.firstName.length < 2) errors.firstName = COMMON_ERROR_MESSAGE;

    if (!form.lastName || form.lastName.length < 2) errors.lastName = COMMON_ERROR_MESSAGE;

    //   if (!form.practiceName || form.practiceName.length < 2)
    //     errors.practiceName = COMMON_ERROR_MESSAGE;

    // check email
    if (!form.userEmail) errors.userEmail = 'Email address can not be empty';
    const ajv = new Ajv();
    if (!ajv.validate({ format: 'email' }, form.userEmail)) errors.userEmail = 'Email address is invalid';

    // check password
    if (!form.userPassword) {
        errors.userPassword = 'Password can not be blank';
    } else if (form.userPassword && form.userConfirm && form.userPassword !== form.userConfirm) {
        errors.userPassword = 'Passwords do not match';
    } else if (!isPasswordValid(form.userPassword)) {
        errors.userPassword = 'Must be at least 8 characters';
    }

    return Object.keys(errors).length > 0 ? errors : null;
};

const SimpleSignUpPage = ({ classes, dispatch }) => {
    const [form, updateForm] = useState({
        firstName: '',
        lastName: '',
        userEmail: '',
        userPassword: '',
        userConfirm: '',
        practiceName: '',
        practiceABN: '',
        leadSource: '',
    });
    const [formErrors, updateFormErrors] = useState({
        firstName: '',
        lastName: '',
        userEmail: '',
        userPassword: '',
        userConfirm: '',
        practiceName: '',
        practiceABN: '',
        leadSource: '',
    });

    // eslint-disable-next-line no-unused-vars
    const [newPracticeWithUser, { newPracticeData, newPracticeLoading, newPracticeError }] = useMutation(NEW_SIGNUP, {
        onCompleted: (result) => {
            window.location.href = '/verify-email';
            // request sent
            // updateDialogOpen(false);
            // if (result.removeConsentForm.success === true) {
            //   updateSuccessMessage('You have successfully removed the form');
            //   updateListForm(result.removeConsentForm.forms);
            // } else {
            //   updateError('There was an error removing the form. Please try again.');
            // }
        },
    });

    return (
        <Grid component='main' container justifyContent='center' alignItems='center' className={classes.main}>
            <Grid component='section' container item className={classes.section}>
                <Grid
                    component='form'
                    container
                    spacing={3}
                    className={classes.form}
                    onSubmit={(e) => {
                        e.preventDefault();
                        // clear previous form errors, then validate
                        updateFormErrors(formDefaultValues);
                        const errors = validateForm(form);

                        if (!errors) {
                            newPracticeWithUser({
                                variables: {
                                    ...form,
                                },
                            });
                        } else {
                            updateFormErrors(errors);
                        }
                    }}>
                    <div className={classes.fieldRow}>
                        {newPracticeError && <FlashMessage className={classes.fullWidth} message={newPracticeError} />}

                        {!newPracticeError && newPracticeData && newPracticeData.newSignUp && newPracticeData.newSignUp.id && (
                            <FlashMessage
                                className={classes.fullWidth}
                                success
                                message={
                                    <span>
                                        Submitted.
                                        <br />
                                        Please close this page and check your email.
                                    </span>
                                }
                            />
                        )}
                    </div>

                    <div className={classes.fieldRow}>
                        <FormControl className={classes.fieldColumn}>
                            <Input
                                id='firstName'
                                required
                                autoComplete='off'
                                disableUnderline
                                placeholder='First Name'
                                value={form.firstName || ''}
                                className={`${classes.field} ${classes.fieldLeftColumn}`}
                                onChange={(e) => updateForm({ ...form, firstName: e.target.value })}
                            />
                            <FormLabel className={classes.fieldErrors}>{formErrors.firstName}</FormLabel>
                        </FormControl>

                        <FormControl className={classes.fieldColumn}>
                            <Input
                                id='lastName'
                                required
                                autoComplete='off'
                                disableUnderline
                                placeholder='Last Name'
                                value={form.lastName || ''}
                                className={`${classes.field} ${classes.fieldRightColumn}`}
                                onChange={(e) => updateForm({ ...form, lastName: e.target.value })}
                            />
                            <FormLabel className={classes.fieldErrors}>{formErrors.lastName}</FormLabel>
                        </FormControl>
                    </div>

                    <div className={classes.fieldRow}>
                        <FormControl className={classes.fieldColumn}>
                            <Input
                                id='email'
                                required
                                disableUnderline
                                placeholder='Email'
                                value={form.userEmail || ''}
                                className={classes.field}
                                onChange={(e) => updateForm({ ...form, userEmail: e.target.value })}
                            />
                            <FormLabel className={classes.fieldErrors}>{formErrors.userEmail}</FormLabel>
                        </FormControl>
                    </div>

                    <br />

                    <div className={classes.fieldRow}>
                        <FormControl className={classes.fieldColumn}>
                            <Input
                                id='password'
                                type='password'
                                autoComplete='new-password'
                                required
                                placeholder='Password'
                                disableUnderline
                                value={form.userPassword || ''}
                                className={`${classes.field} ${classes.fieldLeftColumn}`}
                                onChange={(e) => updateForm({ ...form, userPassword: e.target.value })}
                            />
                        </FormControl>

                        <FormControl className={classes.fieldColumn}>
                            <Input
                                id='confirm'
                                type='password'
                                autoComplete='new-password'
                                required
                                placeholder='Confirm Password'
                                disableUnderline
                                value={form.userConfirm || ''}
                                className={`${classes.field} ${classes.fieldRightColumn}`}
                                onChange={(e) => updateForm({ ...form, userConfirm: e.target.value })}
                            />
                        </FormControl>
                    </div>
                    <FormLabel className={classes.fieldErrors}>{formErrors.userPassword}</FormLabel>

                    <div className={classes.fieldRow}>&nbsp;</div>

                    <div className={classes.fieldRow}>
                        <FormControl className={classes.fieldColumn}>
                            <Input
                                id='practiceName'
                                autoFocus
                                autoComplete='off'
                                required
                                disableUnderline
                                placeholder='Clinic Name'
                                value={form.practiceName || ''}
                                className={classes.field}
                                onChange={(e) => updateForm({ ...form, practiceName: e.target.value })}
                            />
                            <FormLabel className={classes.fieldErrors}>{formErrors.practiceName}</FormLabel>
                        </FormControl>
                    </div>

                    <br />

                    <div className={classes.fieldRow}>
                        <FormControl className={classes.fieldColumn}>
                            <Button
                                type='submit'
                                variant='contained'
                                color='secondary'
                                disableFocusRipple
                                disableRipple
                                classes={{ root: classes.button }}>
                                {newPracticeLoading ? <CircularProgress classes={{ root: classes.loading }} size={28} /> : 'Register'}
                            </Button>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

const styles = {
    main: {
        minHeight: '100%',
        backgroundColor: PRIMARY,
    },
    section: {
        maxWidth: 448,
    },
    logo: {
        width: '100%',
    },
    loading: {
        color: WHITE,
    },
    form: {
        padding: 32,
        maxWidth: 448,
    },
    fullWidth: {
        width: '100%',
    },
    fieldRow: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        '@media (max-width: 425px)': {
            flexDirection: 'column',
        },
    },
    fieldColumn: {
        flex: 1,
    },
    field: {
        backgroundColor: WHITE,
        flex: 1,
        height: 48,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 5,
        marginBottom: 8,
    },
    fieldLeftColumn: {
        marginRight: 3,
        '@media (max-width: 425px)': {
            marginRight: 0,
        },
    },
    fieldRightColumn: {
        marginLeft: 3,
        '@media (max-width: 425px)': {
            marginLeft: 0,
        },
    },
    selectField: {
        backgroundColor: WHITE,
        flex: 1,
        height: 48,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 5,
        marginBottom: 16,
        borderBottom: '0px solid white !important',
    },
    fieldErrors: {
        marginBottom: 15,
        color: SECONDARY,
    },
    button: {
        flex: 1,
        height: 48,
        fontSize: 18,
        fontWeight: 300,
        letterSpacing: 0.5,
        textTransform: 'capitalize',
    },
    error: {
        color: WHITE,
        paddingTop: 32,
        textAlign: 'center',
    },
    trialText: {
        color: MINOR_BLACK,
        textAlign: 'center',
    },
    trialGrid: {
        width: '100%',
    },
};

export default compose(withStyles(styles))(SimpleSignUpPage);

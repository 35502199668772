import React from 'react';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEye, faList, faTh, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import { BLACK } from '../../style/constants';

import NotesContainer from './notesContainer';
import CategoryListPopover from '../CategoryListPopover';
import EmptyData from '../EmptyData';
import NotesGrid from '../NotesGrid';
import NotesList from '../NotesList';
import NotesListPreview from '../NotesListPreview';

const Notes = ({
    attachments,
    categories,
    checkedNotes,
    classes,
    episodeId,
    error,
    hasNextPage,
    hasCollapse,
    heading,
    notes = [],
    onCancelSignOff,
    onConfirmSignOff,
    onListEnd,
    onAllowSignOff,
    onSortBy,
    onSortDirection,
    onToggleView,
    patientId,
    sort,
    signOff,
    status = {},
    updateCategories,
    view,
}) => {
    return (
        <Grid className={attachments ? classes.wrapperAttachments : null}>
            <Grid container wrap='nowrap' alignItems='center' justifyContent='space-between' className={classes.subheadingRow}>
                <Grid className={classes.subheadingWrapper}>
                    <Typography variant='subtitle1' className={classes.subheading}>
                        {heading}
                    </Typography>
                    {!attachments && signOff && (
                        <IconButton onClick={onCancelSignOff} aria-label='Cancel Sign Off' color='inherit'>
                            <FontAwesomeIcon className={classes.iconClose} icon={faTimesCircle} />
                        </IconButton>
                    )}
                </Grid>
                <Grid container alignItems='center' justifyContent='flex-end' className={classes.subheadingButtons}>
                    {view !== 'list' && !attachments && (
                        <React.Fragment>
                            <Tooltip title='Sort By' placement='bottom-start' enterDelay={300}>
                                <FormControl className={classes.formControl}>
                                    <NativeSelect
                                        disabled={status.loading}
                                        value={sort.by}
                                        onChange={(event) => onSortBy(event.target.value)}
                                        input={<Input name='sortBy' id='notes-grid-sort-by' />}>
                                        <option value='TITLE'>Title</option>
                                        <option value='CREATED_AT'>Created</option>
                                        <option value='UPDATED_AT' text='Updated'>
                                            Updated
                                        </option>
                                        <option value='SIGNED_OFF_TIME'>Signed</option>
                                    </NativeSelect>
                                </FormControl>
                            </Tooltip>
                            <ToggleButtonGroup
                                className={classes.toggleGroup}
                                value={sort.direction}
                                exclusive
                                onChange={(event, value) => onSortDirection(value)}>
                                <Tooltip title='Ascending' placement='bottom-end'>
                                    <ToggleButton selected={sort.direction === 'ASC'} disableRipple={sort.direciton === 'ASC'} value='ASC'>
                                        <FontAwesomeIcon icon={faArrowUp} className={classes.iconView} />
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip title='Descending' placement='bottom-end'>
                                    <ToggleButton selected={sort.direction === 'DESC'} disableRipple={sort.direciton === 'DESC'} value='DESC'>
                                        <FontAwesomeIcon icon={faArrowDown} className={classes.iconView} />
                                    </ToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </React.Fragment>
                    )}
                    {!attachments && !!notes.length && (
                        <Button
                            onClick={
                                // eslint-disable-next-line no-nested-ternary
                                signOff ? (checkedNotes.length !== 0 ? onConfirmSignOff : onCancelSignOff) : onAllowSignOff
                            }
                            size='small'>
                            {signOff ? 'Confirm' : 'Sign Off'}
                        </Button>
                    )}
                    {!attachments && !!notes.length && window.innerWidth > 600 && (
                        <ToggleButtonGroup className={classes.toggleGroup} value={view} exclusive onChange={(event, value) => onToggleView(value)}>
                            <Tooltip title='List' placement='bottom-end'>
                                <ToggleButton selected={view === 'list'} disableRipple={view === 'list'} value='list'>
                                    <FontAwesomeIcon className={classes.iconView} icon={faList} />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title='Grid' placement='bottom-end'>
                                <ToggleButton selected={view === 'grid'} disableRipple={view === 'grid'} value='grid'>
                                    <FontAwesomeIcon className={classes.iconView} icon={faTh} />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title='Preview' placement='bottom-end'>
                                <ToggleButton selected={view === 'preview'} disableRipple={view === 'preview'} value='preview'>
                                    <FontAwesomeIcon className={classes.iconView} icon={faEye} />
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    )}
                    <CategoryListPopover selectedCategories={categories} loading={status.loading} updateCategories={updateCategories} />
                </Grid>
            </Grid>
            {(() => {
                if (episodeId === 'new')
                    return (
                        <Paper>
                            <EmptyData text='Please create a new episode' />
                        </Paper>
                    );
                if ((view === 'list' || attachments) && window.innerWidth > 600)
                    return (
                        <NotesList
                            attachments={attachments}
                            hasCollapse={hasCollapse}
                            error={error}
                            hasNextPage={hasNextPage}
                            loading={status.loading}
                            notes={notes}
                            onSortBy={onSortBy}
                            patientId={patientId}
                            sort={sort}
                            status={status}
                            onListEnd={onListEnd}
                        />
                    );
                if (view === 'preview')
                    return <NotesListPreview error={error} hasNextPage={hasNextPage} notes={notes} status={status} onListEnd={onListEnd} />;
                if (view === 'grid' || window.innerWidth <= 600 || (attachments && window.innerWidth <= 600))
                    return <NotesGrid error={error} hasNextPage={hasNextPage} notes={notes} status={status} onListEnd={onListEnd} />;

                return null;
            })()}
        </Grid>
    );
};

const styles = {
    wrapperAttachments: {
        // paddingBottom: 24,
    },
    subheadingRow: {
        paddingBottom: 8,
    },
    subheadingWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    subheading: {
        paddingRight: 4,
        fontWeight: 'bold',
    },
    subheadingButtons: {
        width: 'unset',
        minHeight: 48,
    },
    iconClose: {
        color: BLACK,
        paddingRight: 4,
        paddingLeft: 4,
    },
    iconView: {
        color: BLACK,
    },
    formControl: {
        padding: 8,
        '& div': {
            fontSize: '0.9rem',
        },
    },
    toggleGroup: {
        marginRight: 8,
        marginLeft: 12,
    },
};

export default compose(NotesContainer, withStyles(styles))(Notes);

import React from 'react';
import { compose, withState } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import ConfirmActionDialog from '../ConfirmActionDialog';

const DeleteNoteDialog = ({
  classes,
  isSignedOff,
  loading,
  noteId,
  open,
  updateOpen,
  onArchiveNote,
}) => (
  <div>
    <Tooltip title="Trash Note" placement="bottom-start">
      <span>
        <IconButton
          color="inherit"
          aria-label="SignOff"
          className={classes.iconButton}
          disabled={loading || !noteId || isSignedOff}
          onClick={() => updateOpen(true)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      </span>
    </Tooltip>
    <ConfirmActionDialog
      open={open}
      text="Performing this action will remove selected item permanently."
      onConfirm={() => {
        onArchiveNote();
        updateOpen(false);
      }}
      onClose={() => updateOpen(false)}
      confirmText="Delete"
    />
  </div>
);

const styles = {
  iconButton: {
    color: '#ED4337',
  },
};

export default compose(
  withStyles(styles),
  withState('open', 'updateOpen', false)
)(DeleteNoteDialog);

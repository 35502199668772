import { getImageExtension, drawingCanvas } from './pdf/utils';

// transpatent black "X" size: 64x64
const errorImage = new Image();
errorImage.src =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAEAYAAAD6+a2dAAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAACXZwQWcAAABAAAAAQADq8/hgAAABtklEQVR42u3aS45BQRjFcUTMCKuRmHjbi7ehjYiIAQuwDgYsx2NGD77UQKU7qupyq0r/f5MzQb44Z9CdK5MBAAAAAAAAAAAAAAAAgJRls5L1uu9LzFSrkuWy70sip4pfrSTvd8l+3/dlv1MDPZ8lj0dJhmBJFb9eSz4ezxnaEPTi9XtPJ8lKxfelkVgsJPUvMrQhvCr+ryEUCn7ujUarJXm9SpoOYTBI5z7b4lXOZunc9zVCGwLFe9JuS5oOQeW7hkDxgUh7CBQfKNchDIdmn0/xkXj3ECg+Up2OpOsQKP5LuA7hdrN7PcUHrtuVtB0CxX+Zdw2B4iM3n0vaFr/b+b4cibj+cRfKswY4Slo8Q4iUa/Gm/wUwhEC5Fj8aPb//cjF7H0MIRNLidY2GJEMInGvx47HZ5zebkgwhMJ8uXscQApF28Tr1wxSGkDLX4ieTz9zj+gslhmAptOJ1to+hGYKl5VLStPjp1M+dtkM4HCTzeT/3RiOXk9xuJUMrXvfqMbQqvlTyfWlk1BA2G8nQitfpQ9jvJSk+ITWEXs/3JWZqNcli0fclAAAAAAAAAAAAAAAA+Gd+ACV4YmN2KeBIAAAAJXRFWHRjcmVhdGUtZGF0ZQAyMDEzLTAxLTIxVDIzOjU0OjA5LTA2OjAwzz41DQAAACV0RVh0bW9kaWZ5LWRhdGUAMjAxMy0wMS0yMVQyMjo1MDozNS0wNjowMA3dV1EAAAAASUVORK5CYII=';

export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', e => resolve(img));
    img.addEventListener('error', e =>
      reject(new Error(`Error loading ${url}`))
    );
    img.src = url;
  });
}

export function loadEncodedImage(encodedFile, imageType) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', e => resolve(img));
    img.addEventListener('error', e => resolve(errorImage));
    img.src = `data:image/${imageType};base64,${encodedFile}`;
  });
}

export default async function processMedias(notes) {
  {
    // search all encodedImages
    const files = {};
    const ids = [];
    const images = [];
    const canvasIds = [];
    const canvasSrc = [];

    const addNewEncodedFile = file => {
      const { id, encodedFile } = file || {};
      ids.push(id);
      images.push(loadEncodedImage(encodedFile, 'jpg'));
      files[id] = {
        imageSrc: `data:${getImageExtension(
          encodedFile
        )};base64, ${encodedFile}`,
      };
    };

    const addNewCanvasData = canvas => {
      canvasIds.push(canvas.id);
      canvasSrc.push(drawingCanvas(canvas.drawingData));
    };

    notes.forEach(note => {
      if (note.__typename === 'MediaNote') {
        addNewEncodedFile(note.file);
      } else if (note.__typename === 'FormNote') {
        const { sections = [] } = note;
        sections.forEach(section => {
          if (section.template) {
            addNewEncodedFile(section.template.file);
          }
          if (section.drawingData) {
            addNewCanvasData(section);
          }
        });
      } else if (note.__typename === 'DrawingNote') {
        if (note.template) {
          addNewEncodedFile(note.template.file);
        }
        if (note.drawingData) {
          addNewCanvasData(note);
        }
      }
    });

    const result = await Promise.all(images);

    const imageByIdMap = new Map();
    result.forEach((img, index) => {
      const imageId = ids[index];
      imageByIdMap.set(imageId, {
        ...files[imageId],
        loadedImage: img,
      });
    });

    const canvas = await Promise.all(canvasSrc);
    const canvasImages = canvas.reduce((acc, src, index) => {
      acc[canvasIds[index]] = src;
      return acc;
    }, {});

    return { files: imageByIdMap, canvasImages };
  }
}

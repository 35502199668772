import React from 'react';

import { compose } from 'recompose';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const RemoveIntegrationDialog = ({ classes, onCloseDialog, showDialog }) => {
    return (
        <Dialog open={showDialog} onClose={onCloseDialog} maxWidth='lg'>
            <DialogTitle>Mindbody Integration</DialogTitle>
            <DialogContent>
                <Typography className={classes.dialogContent}>Are you sure you wish to remove this integration?</Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    color='primary'
                    onClick={async () => {
                        onCloseDialog('cancel');
                    }}>
                    Cancel
                </Button>
                <Button
                    color='primary'
                    onClick={() => {
                        onCloseDialog('ok');
                    }}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'space-between',
        width: '100%',
        height: '100%',
    },
    title: {
        width: 100,
    },
    selectField: {
        width: '100%',
        height: 500,
    },
    field: {
        width: '100%',
    },
    dialogContent: {
        width: 600,
    },
};

export default compose(withStyles(styles))(RemoveIntegrationDialog);

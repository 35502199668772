import {
  PRACTICE_SELECTED,
  PRACTICE_SEARCHED,
  PRACTICE_REFETCH_TOGGLE,
  PRACTICE_CLEARED,
} from '../actions/practice';

const practiceDefaultState = {
  id: undefined,
  name: undefined,
  searchText: undefined,
  refetchPatientList: false,
  refetchEpisodeList: false,
  appointmentTimeSlot: 15,
  includeArchived: false,
  locations: []
};

const practiceReducer = (state = practiceDefaultState, action) => {
  switch (action.type) {
    case PRACTICE_SELECTED:
      return {
        ...state,
        id: action.id || state.id,
        name: action.name || state.name,
        appointmentTimeSlot:
          action.appointmentTimeSlot || state.appointmentTimeSlot,
        locations: action.locations || state.locations
      };
    case PRACTICE_SEARCHED:
      return {
        ...state,
        searchText: action.searchText,
        includeArchived: action.includeArchived,
      };
    case PRACTICE_REFETCH_TOGGLE:
      return {
        ...state,
        refetchPatientList:
          action.refetchPatientList === undefined
            ? state.refetchPatientList
            : action.refetchPatientList,
        refetchEpisodeList:
          action.refetchEpisodeList === undefined
            ? state.refetchEpisodeList
            : action.refetchEpisodeList,
      };
    case PRACTICE_CLEARED:
      return practiceDefaultState;
    default:
      return state;
  }
};

export default practiceReducer;

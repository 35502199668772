/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

import Moment from 'moment';

import { WARM_PINK } from '../../../style/constants';
import { UPSERT_EVENT } from './gql';

const UpsertEventContent = ({
  classes,
  existingEvent,
  locationId,
  onCloseDialog,
  clinicianOptions,
  selectedDay,
  selectedEnd,
  selectedClinicianId,
  showDialog,
}) => {
  const [invalidInput, setInvalidInput] = useState(true);
  const [eventDetail, setEventDetail] = useState({});

  const [upsertEvent] = useMutation(UPSERT_EVENT, {
    onCompleted: () => {
      onCloseDialog();
    },
    refetchQueries: ['listAppointments'],
    variables: {
      start: Moment(eventDetail.start).toISOString(true),
      end: Moment(eventDetail.end).toISOString(true),
      locationId,
      clinicianEncodedId: _.get(eventDetail, 'clinician.id'),
      title: eventDetail.title,
      description: eventDetail.description,
    },
  });

  useEffect(() => {
    if (existingEvent && existingEvent.id) {
      setEventDetail({
        ...existingEvent,
        duration: Moment(existingEvent.end).diff(
          Moment(existingEvent.start),
          'minutes'
        ),
        start: Moment(existingEvent.start).format('YYYY-MM-DD[T]HH:mm'),
        end: Moment(existingEvent.end).format('YYYY-MM-DD[T]HH:mm'),
      });
    } else {
      const clinician = clinicianOptions.find(
        (e) => e.id === selectedClinicianId
      );
      setEventDetail({
        // Left commented for future ticket (drag select appt times)
        clinician,
        // duration: DEFAULT_DURATION,
        start: Moment(selectedDay).format('YYYY-MM-DD[T]HH:mm'),
        end: Moment(selectedEnd).format('YYYY-MM-DD[T]HH:mm'),
      });
    }
  }, []);

  const validateChanges = () => {
    if (
      !eventDetail.start ||
      !eventDetail.clinician ||
      !eventDetail.title ||
      _.isEqual(eventDetail, existingEvent) ||
      Moment(eventDetail.end).isSameOrBefore(eventDetail.start)
    ) {
      setInvalidInput(true);
    } else {
      setInvalidInput(false);
    }
  };

  const handleChange = (newEntry) => {
    setEventDetail({ ...eventDetail, ...newEntry });
  };

  const handleDetailChange = (e) => {
    setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    upsertEvent();
  };

  useEffect(() => {
    validateChanges();
  }, [eventDetail]);

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Title
          </Typography>

          <TextField
            className={classes.field}
            name="title"
            autoComplete="off"
            onChange={handleDetailChange}
            placeholder="Vacation or Sick Leave etc."
            value={eventDetail.title}
          />
        </Grid>
        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Clinician
          </Typography>

          <Autocomplete
            id="appt-pract-select"
            className={classes.field}
            onChange={(e, values) => {
              if (values) {
                handleChange({ clinician: values });
              } else {
                handleChange({ clinician: null });
              }
            }}
            value={eventDetail.clinician ? { ...eventDetail.clinician } : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                margin="normal"
                className={classes.field}
              />
            )}
            options={clinicianOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
          />
        </Grid>
        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Start
          </Typography>

          <TextField
            id="appt-start-date"
            className={classes.field}
            type="datetime-local"
            autoComplete="off"
            value={eventDetail.start}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              handleChange({
                start: e.target.value,
              })
            }
          />
        </Grid>
        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            End
          </Typography>

          <TextField
            id="appt-start-date"
            className={classes.field}
            autoComplete="off"
            type="datetime-local"
            value={eventDetail.end}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              handleChange({
                end: e.target.value,
              })
            }
          />
        </Grid>

        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Notes
          </Typography>

          <TextField
            className={classes.field}
            // autoComplete="off"
            id="standard-full-width"
            multiline
            minRows={2}
            onChange={(e) => handleChange({ notes: e.target.value })}
            value={eventDetail.notes ? eventDetail.notes : ''}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onCloseDialog}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={invalidInput}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const styles = {
  dialogContent: {
    width: 650,
  },
  fieldContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 8,
  },
  pinkButton: {
    color: WARM_PINK,
  },
  field: {
    marginTop: -4,
    width: 369,
  },
  creationButtonContainer: {
    '& > *:not(:last-child)': {
      marginRight: 15,
    },
  },
};

export default compose(
  connect(({ practice, user }) => ({
    practiceEncodedId: practice.id,
    locationId: user.currentLocationId,
  })),
  withStyles(styles)
)(UpsertEventContent);

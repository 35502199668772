import { branch, compose, withProps, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import some from 'lodash/some';
import remove from 'lodash/remove';

import { noteUpsert } from '../../data/redux/actions/note';
import { notesChecked } from '../../data/redux/actions/notes';

export const formNotePreviewQuery = gql`
  query formNotePreview($noteId: ID!) {
    node(id: $noteId) {
      __typename
      id
      ... on FormNote {
        createdAt
        rows
        isSignedOff
        patient {
          id
          name
        }
        author {
          id
          name
        }
        sections {
          id
          type
          title
          rowIndexes
          columnIndexes
          ... on TextSection {
            text
          }
          ... on YesNoSection {
            yesNoValue
          }
          ... on NumberScale10Section {
            numberScale10Value
          }
          ... on NumberScale100Section {
            numberScale100Value
          }
          ... on CheckboxesSection {
            checkboxesData
          }
          ... on SpreadsheetSection {
            spreadsheetData
          }
          ... on DrawingSection {
            drawingData
            pageNumber
            group {
              id
            }
            template {
              pageNumber
              id
              file {
                id
                encodedFile
              }
              group {
                id
              }
            }
          }
        }
        signedOffTime
        title
      }
    }
  }
`;

const FormNotePreviewContainer = compose(
  withRouter,
  connect(({ notes, user }) => ({
    showCheckBox: notes.signOff,
    checkedNotes: notes.checkedNotes,
    userId: user.id,
    isAuth: user.roles.includes('CLINICIAN') || user.roles.includes('ADMIN'),
  })),
  graphql(formNotePreviewQuery, {
    name: 'query',
    options: ({ noteId }) => ({
      variables: { noteId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withProps(({ query }) => ({
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    props => props.status.success,
    withProps(({ query, checkedNotes, userId }) => ({
      checkedNoteObject: {
        id: query.node.id,
        __typename: query.node.__typename,
      },
      checkedNotesMatch: some(checkedNotes, {
        id: query.node.id,
        __typename: query.node.__typename,
      }),
      __typename: query.node.__typename,
      id: query.node.id,
      createdAt: query.node.createdAt,
      title: query.node.title,
      sections: query.node.sections,
      isSignedOff: query.node.isSignedOff,
      signedOffTime: query.node.signedOffTime,
      patientName: query.node.patient ? query.node.patient.name : '',
      patientId: query.node.patient ? query.node.patient.id : '',
      isAuthor: query.node.author && userId === query.node.author.id,
      authorName: query.node.author ? query.node.author.name : '',
    }))
  ),
  withHandlers({
    onCheckNote: ({
      checkedNotes,
      checkedNotesMatch,
      checkedNoteObject,
      dispatch,
    }) => () => {
      const newCheckedNotes = checkedNotes.map(note => note);

      if (!checkedNotesMatch) {
        newCheckedNotes.push(checkedNoteObject);
      } else {
        remove(newCheckedNotes, checkedNoteObject);
      }

      dispatch(notesChecked(newCheckedNotes));
    },
  }),
  withHandlers({
    onLinkPress: ({
      __typename,
      dispatch,
      history,
      id,
      isSignedOff,
      signedOffTime,
      title,
      showCheckBox,
      onCheckNote,
      patientId,
      noteId,
      isAuthor,
      isAuth,
    }) => async () => {
      if (showCheckBox && isAuthor && isAuth) {
        onCheckNote();
      } else if (!showCheckBox) {
        await dispatch(
          noteUpsert({
            id,
            __typename,
            title,
            isSignedOff,
            signedOffTime,
          })
        );
        history.push(`/patient/${patientId}/note/form/${noteId}`);
      }
    },
  })
);

export default FormNotePreviewContainer;

import { gql } from '@apollo/client';

export const CREATE_NEW_FORM_NOTE = gql`
    mutation createNewFormNote($templateId: String, $author: ID!, $patientId: ID!, $episodes: [ID]) {
        createNewFormNote(templateId: $templateId, author: $author, patientId: $patientId, episodes: $episodes) {
            note {
                id
                sections {
                    ... on TextSection {
                        id
                        type
                        title
                        text
                    }
                }
            }
        }
    }
`;

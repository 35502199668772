import React, { Fragment, useState } from 'react';
import { compose } from 'recompose';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { WARM_PINK } from '../../../style/constants';
import {
  LIST_INVENTORY_ITEMS,
  UPSERT_INVENTORY_ITEM,
  DELETE_INVENTORY_ITEM,
} from '../gql';
import Loading from '../../../components/Loading';
import ConfirmActionDialog from '../../../components/ConfirmActionDialog';

const InventoryDialog = ({
  classes,
  handleToggleDialog,
  isDialogOpen,
  locationId,
  practiceEncodedId,
  selectedInventoryItem,
  setSelectedInventoryItem,
  taxRates,
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [isUniqueInput, setIsUniqueInput] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  // GQL Mutation to create/update an inventory item
  const [upsertInventoryItem, { loading }] = useMutation(
    UPSERT_INVENTORY_ITEM,
    {
      // If successful, close the dialog
      onCompleted: res => {
        if (res.upsertInventoryItem) {
          handleToggleDialog();
        }
      },
      // On complete, refecth the list of inventory items
      refetchQueries: [
        { query: LIST_INVENTORY_ITEMS, variables: { locationId } },
      ],
      variables: {
        ...selectedInventoryItem,
        locationId,
        practiceEncodedId,
        taxables: selectedInventoryItem.taxRateIds,
      },
    }
  );

  // GQL to delete an inventory item
  const [deleteInventoryItem] = useMutation(DELETE_INVENTORY_ITEM, {
    // If successful, close the dialog
    onCompleted: res => {
      if (res.deleteInventoryItem) {
        handleToggleDialog();
      }
    },
    // On complete, refetch the list of inventory items
    refetch: [{ query: LIST_INVENTORY_ITEMS, variables: { locationId } }],
    variables: {
      id: selectedInventoryItem.id,
      ...selectedInventoryItem,
      locationId,
      practiceEncodedId,
      status: selectedInventoryItem.status,
    },
  });

  const INVENTORY_HEADERS = [
    { id: 'code', label: 'Code', type: 'text' },
    { id: 'description', label: 'Description', type: 'text' },
    { id: 'category', label: 'Category', type: 'text' },
    { id: 'price', label: 'Unit Price', type: 'number' },
    // {
    //   id: 'isTaxable',
    //   label: 'Taxable',
    //   type: 'checkbox',
    //   checkbox_title: 'Taxable',
    // },
    // { id: 'stock', label: 'Stock', type: 'number' },
  ];
  const COMPULSORY_KEYS = INVENTORY_HEADERS.map(header => header.id);

  const handleInputChange = (field, event) => {
    const clonedObj = {
      ...selectedInventoryItem,
      [field.id]:
        field.type === 'checkbox' ? event.target.checked : event.target.value,
    };
    setSelectedInventoryItem(clonedObj);
    setIsUniqueInput(true);
  };

  const handleTaxSelect = event => {
    setSelectedInventoryItem({
      ...selectedInventoryItem,
      taxRateIds: event.target.value,
    });
    setIsUniqueInput(true);
  };

  const handleToggleDelete = () => {
    if (selectedInventoryItem.status === 'ACTIVE') {
      setIsConfirming(!isConfirming);
    } else {
      deleteInventoryItem();
    }
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleToggleDialog}
        className={classes.dialogContainer}
      >
        <DialogTitle>
          {selectedInventoryItem.id
            ? 'Edit Existing Inventory Item'
            : 'Create New Inventory Item'}
        </DialogTitle>

        <DialogContent>
          {INVENTORY_HEADERS.map(field => {
            return (
              <Fragment key={field.id}>
                {/* TODO: make Unit price and stock number fields */}
                {(field.type === 'text' || field.type === 'number') && (
                  <TextField
                    className={classes.field}
                    type={field.type}
                    label={field.label}
                    fullWidth
                    autoComplete="off"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => {
                      handleInputChange(field, event);
                    }}
                    placeholder={field.label}
                    value={_.get(selectedInventoryItem, field.id)}
                  />
                )}

                {field.type === 'checkbox' && (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {field.checkbox_title}
                      <Checkbox
                        checked={_.get(selectedInventoryItem, field.id)}
                        onChange={event => handleInputChange(field, event)}
                      />
                    </FormLabel>
                  </FormControl>
                )}
                {showErrors &&
                  !selectedInventoryItem[field.id] &&
                  typeof selectedInventoryItem[field.id] !== 'boolean' && (
                    <Typography
                      color="error"
                      variant="caption"
                      className={classes.error}
                    >
                      {field.label} is required
                    </Typography>
                  )}
              </Fragment>
            );
          })}
          <Typography className={classes.title} variant="body2">
            Applicable Tax Rates
          </Typography>

          <Select
            className={classes.field}
            fullWidth
            margin="normal"
            multiple
            onChange={handleTaxSelect}
            renderValue={selected => `${selected.length} selected`}
            value={selectedInventoryItem.taxRateIds}
          >
            {taxRates.map(taxRate => (
              <MenuItem
                id={taxRate.id}
                value={taxRate.id}
                key={`menuitem-taxrate-${taxRate.id}`}
              >
                <Checkbox
                  checked={
                    selectedInventoryItem.taxRateIds.indexOf(taxRate.id) > -1
                  }
                />
                <ListItemText
                  primary={`${taxRate.name} - (${taxRate.ratePercent}%)`}
                />
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions className={classes.actionContainer}>
          {loading ? (
            <Loading />
          ) : (
            <div className={classes.buttonContainer}>
              <Button onClick={handleToggleDelete} color="secondary">
                {selectedInventoryItem.status === 'ACTIVE'
                  ? 'Delete'
                  : 'Undo Delete'}
              </Button>
              <Button onClick={handleToggleDialog} color="primary">
                Cancel
              </Button>
              <Button
                className={classes.redButton}
                onClick={async () => {
                  setShowErrors(true);
                  const missingKeys = COMPULSORY_KEYS.filter(key => {
                    if (typeof selectedInventoryItem[key] === 'boolean') {
                      return false;
                    }
                    return !selectedInventoryItem[key];
                  });
                  if (isUniqueInput && !missingKeys.length) {
                    await upsertInventoryItem();
                  }
                }}
              >
                SAVE
              </Button>
            </div>
          )}

          {showErrors && (
            <Typography
              color="error"
              variant="caption"
              className={classes.error}
              style={{ display: isUniqueInput ? 'none' : 'block' }}
            >
              Please change a field to continue
            </Typography>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmActionDialog
        open={isConfirming}
        text="Are you sure you want to delete this item?"
        onClose={handleToggleDelete}
        onConfirm={async () => {
          await await deleteInventoryItem();
        }}
        confirmText="Delete"
      />
    </>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  subheading: {
    fontWeight: 'bold',
  },
  subSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  redButton: {
    marginLeft: 4,
    height: 36,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    locationId: user.currentLocationId,
    practiceEncodedId: practice.id,
  }))
)(InventoryDialog);

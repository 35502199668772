import { gql } from '@apollo/client';

export const SUBMIT_CONSENT_FORM = gql`
  mutation submitConsentForm($formData: String!, $key: String!) {
    submitConsentForm(formData: $formData, key: $key) {
      success
    }
  }
`;

export const LOAD_CONSENT_FORM = gql`
  query getConsentForm($key: String!) {
    getConsentForm(key: $key) {
      form {
        title
        description
        key
        state
        schema
        uiSchema
        submissions_count
        allow_registration
        practice_id
        fields {
          type
          title
          name
          is_required
        }
      }
    }
  }
`;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WARM_PINK } from '../../style/constants';
import { FETCH_LOCATION_CLINICIANS } from './gql';
import Birthdays from './ReportTypes/Birthdays';
import Appointments from './ReportTypes/Appointments';
import Transactions from './ReportTypes/Transactions';
import Cashflow from './ReportTypes/Cashflow';
import Emails from './ReportTypes/Emails';

const ReportSelector = ({
  classes,
  practiceEncodedId,
  reportType,
  reportTypeOptions,
  setReportType,
  ...props
}) => {
  const [clinicianOptions, setClinicianOptions] = useState([]);

  useQuery(FETCH_LOCATION_CLINICIANS, {
    variables: {
      practiceEncodedId,
      // maybe required in future if we only want to scope to current location
      // locationId: currentLocationId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      const data = _.get(res, 'node.users', []);
      if (data.length) {
        setClinicianOptions(data);
      }
      return null;
    },
  });

  const reportGenerator = () => {
    switch (reportType) {
      case 'Upcoming Patient Birthdays':
        return <Birthdays />;
      case 'View Patient Email Addresses':
        return <Emails />;
      case "View Today's Appointments":
        return <Appointments clinicianOptions={clinicianOptions} />;
      case "View Today's Transactions":
        return <Transactions />;
      default:
        return <Cashflow {...props} />;
    }
  };

  const onSelectReport = e => {
    setReportType(e.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <Typography className={classes.descriptor}>Report Type</Typography>
        </Grid>
        <Grid item xs={9}>
          <Select
            className={classes.field}
            defaultValue={reportTypeOptions[0]}
            fullWidth
            onChange={onSelectReport}
          >
            {reportTypeOptions.map(item => (
              <MenuItem value={item} key={`menuitem-${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {reportGenerator()}
      </Grid>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  field: {
    marginBottom: 20,
  },
  descriptor: {
    marginTop: 6,
  },
  warmPinkButton: {
    padding: 10,
    maxWidth: 172,
    height: 40,
    background: WARM_PINK,
    color: 'white',
  },
};

export default compose(
  connect(({ practice }) => ({
    practiceEncodedId: practice.id,
  })),
  withStyles(styles)
)(ReportSelector);

import { gql } from '@apollo/client';

export const UPSERT_SUBSCRIPTION_FROM_CHECKOUT = gql`
    mutation upsertSubscriptionFromCheckout($practiceId: ID!, $checkoutSession: String) {
        upsertSubscriptionFromCheckout(practiceId: $practiceId, checkoutSession: $checkoutSession) {
            customerToken
            planToken
            numberOfUsers
            cardLast4
            status
            expiry
            renewDate
        }
    }
`;

export const GET_PRACTICE_SUBSCRIPTION = gql`
    query getPracticeSubscription($practiceId: String) {
        getPracticeSubscription(practiceId: $practiceId) {
            stripeSubscription {
                customerToken
                planToken
                numberOfUsers
                cardLast4
                status
                expiry
                renewDate
            }
        }
    }
`;

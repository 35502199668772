import { DIALOG_OPEN, DIALOG_CLOSED } from '../actions/dialog';

const dialogDefaultState = {
  data: {},
  open: false,
  type: undefined,
};

const notesReducer = (state = dialogDefaultState, action) => {
  switch (action.type) {
    case DIALOG_OPEN:
      return {
        data: action.data || state.data,
        open: true,
        type: action.dialogType || state.type,
      };
    case DIALOG_CLOSED:
      return dialogDefaultState;
    default:
      return state;
  }
};

export default notesReducer;

import React, { useState } from 'react';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Typography } from '@material-ui/core';

const ChangePasswordDialog = ({ classes, onCloseDialog, onConfirm, userId, setDialogState, updateChangePasswordDialog }) => {
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [currentPassword, setCurrentPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [status, setStatus] = useState();

    const confirmHandler = () => {
        onConfirm({ userId, newPassword, currentPassword }).then((res) => {
            if (res && res.user) {
                setStatus('success');
                updateChangePasswordDialog(false);
            } else {
                setStatus('error');
            }
        });
    };

    const errors = !newPassword || !confirmPassword || !currentPassword || newPassword === currentPassword || newPassword !== confirmPassword;

    return (
        <Grid className={classes.wrapper}>
            <TextField
                margin='normal'
                id='currentPwd'
                label='Current password'
                autoComplete='off'
                type={showPassword ? 'text' : 'password'}
                value={currentPassword || ''}
                onChange={(e) => setCurrentPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='Toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={(e) => e.preventDefault()}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                margin='normal'
                label='New password'
                type={showPassword ? 'text' : 'password'}
                value={newPassword || ''}
                autoComplete='off'
                onChange={(e) => {
                    setNewPassword(e.target.value);
                    setStatus('');
                }}
                helperText={newPassword === currentPassword && newPassword ? 'Please choose a new password' : ''}
                error={newPassword === currentPassword && newPassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='Toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={(e) => e.preventDefault()}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                margin='normal'
                autoComplete='off'
                label='Confirm password'
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword || ''}
                onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setStatus('');
                }}
                helperText={confirmPassword && newPassword !== confirmPassword ? 'Password does not match above' : ''}
                error={confirmPassword && newPassword !== confirmPassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='Toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={(e) => e.preventDefault()}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {status === 'success' && <div>Password updated successfully!</div>}
            {status === 'error' && <Typography className={classes.error}>There was an error updating your password. Please try again.</Typography>}
            <Grid container className={classes.buttonGroup} direction='row' justifyContent='flex-end'>
                <Button onClick={() => updateChangePasswordDialog(false)} className={classes.button} variant='outlined' color='primary'>
                    Cancel
                </Button>
                <Button onClick={confirmHandler} className={classes.button} disabled={!!errors} variant='contained' color='secondary'>
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

const styles = {
    wrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonGroup: {
        flex: 1,
        display: 'flex',
    },
    button: {
        marginTop: 16,
        marginBottom: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    error: {
        color: 'red',
    },
};

const changePasswordMutation = gql`
    mutation changePassword($id: ID!, $currentPassword: String!, $newPassword: String!) {
        changePassword(id: $id, currentPassword: $currentPassword, newPassword: $newPassword) {
            user {
                id
                name
                email
                practice {
                    id
                }
                roles
                deletedAt
            }
        }
    }
`;

export default compose(
    withStyles(styles),
    withRouter,
    connect(({ user, practice }) => ({
        practiceName: practice.name,
        userName: user.name,
        userId: user.id,
    })),
    graphql(changePasswordMutation, { name: 'changePassword' }),
    withHandlers({
        onConfirm:
            ({ changePassword }) =>
            async (variables) => {
                try {
                    const options = {
                        variables: {
                            id: variables.userId,
                            currentPassword: variables.currentPassword,
                            newPassword: variables.newPassword,
                        },
                    };
                    const result = await changePassword(options);
                    // return await changePassword(options);
                    return result.data && result.data.changePassword;
                } catch (error) {
        console.log('Error: ', error); // eslint-disable-line
                    // throw new Error(error);
                }
            },
    })
)(ChangePasswordDialog);

import {
  branch,
  compose,
  withProps,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

import includes from 'lodash/includes';
import pull from 'lodash/pull';
import uniqBy from 'lodash/uniqBy';

import { episodeSelected } from '../../data/redux/actions/episode';
import { practiceRefetchToggle } from '../../data/redux/actions/practice';

export const episodeListQuery = gql`
  query episodeList(
    $patientId: ID!
    $searchText: String
    $limit: Int
    $offset: Int
  ) {
    node(id: $patientId) {
      __typename
      id
      ... on Patient {
        episodes(searchText: $searchText, limit: $limit, offset: $offset) {
          nodes {
            id
            name
            startDate
            endDate
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
`;

const EpisodeListContainer = compose(
  withRouter,
  connect(({ practice }) => ({
    refetchEpisodeList: practice.refetchEpisodeList,
  })),
  withProps(({ match }) => ({
    patientId: match.params.patientId,
  })),
  withState('searchText', 'updateSearchText', undefined),
  branch(
    ({ patientId }) => patientId,
    compose(
      graphql(episodeListQuery, {
        name: 'query',
        options: ({ patientId }) => ({
          variables: {
            patientId,
            offset: 0,
            limit: 20,
          },
          fetchPolicy: 'cache-and-network',
        }),
      }),
      withProps(({ query }) => ({
        error: query.error,
        status: {
          loading: query.networkStatus === 1,
          setVariables: query.networkStatus === 2,
          fetching: query.networkStatus === 3,
          refetching: query.networkStatus === 4,
          success: query.networkStatus === 7 && Boolean(query.node),
          error: query.networkStatus === 8,
        },
      })),
      branch(
        ({ status }) => status.fetching || status.success,
        withProps(({ query }) => ({
          episodes: query.node.episodes.nodes,
          hasNextPage: query.node.episodes.pageInfo.hasNextPage,
        }))
      )
    )
  ),
  withHandlers({
    onAllNotes: ({ dispatch, history, patientId }) => () => {
      dispatch(episodeSelected(undefined, 'All Notes'));
      history.push(`/patient/${patientId}/notes`);
    },
    onEpisode: ({ dispatch, history, patientId }) => episode => {
      dispatch(
        episodeSelected(
          episode.id,
          episode.name,
          episode.startDate,
          episode.endDate
        )
      );
      history.push({
        pathname: `/patient/${patientId}/notes`,
        search: `?episodeId=${episode.id}`,
      });
    },
    onListEnd: ({ status, query }) => () => {
      if (!status.fetching && !status.refetching) {
        query.fetchMore({
          variables: {
            offset: query.node.episodes.nodes.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            node: {
              ...previousResult.node,
              episodes: {
                ...previousResult.node.episodes,
                nodes: uniqBy(
                  [
                    ...previousResult.node.episodes.nodes,
                    ...fetchMoreResult.node.episodes.nodes,
                  ],
                  item => item.id
                ),
                pageInfo: {
                  ...previousResult.node.episodes.pageInfo,
                  ...fetchMoreResult.node.episodes.pageInfo,
                },
              },
            },
          }),
        });
      }
    },
    onSelectEpisode: ({
      tempEpisodes,
      updateTempEpisodes,
    }) => selectedEpisodeId => {
      const matching = includes(tempEpisodes, selectedEpisodeId);
      const newEpisodes = tempEpisodes.map(episodeId => episodeId);

      if (!matching) {
        newEpisodes.push(selectedEpisodeId);
      } else {
        pull(newEpisodes, selectedEpisodeId);
      }

      updateTempEpisodes(newEpisodes);
    },
    onRefetch: ({ query }) => () => query.refetch(),
    onUntaggedNotes: ({ dispatch, history, patientId }) => () => {
      dispatch(episodeSelected(null, 'Untagged Notes'));
      history.push(`/patient/${patientId}/notes?episodeId`);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { selectedEpisodes, updateTempEpisodes, status } = this.props;

      if (selectedEpisodes && status.success) {
        updateTempEpisodes(selectedEpisodes);
      }
    },
    componentDidUpdate(prevProps) {
      const { dispatch, onRefetch, refetchEpisodeList } = this.props;

      if (refetchEpisodeList) {
        onRefetch();
        dispatch(practiceRefetchToggle('refetchEpisodeList', false));
      }
    },
  })
);

export default EpisodeListContainer;

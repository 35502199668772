import React from 'react';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { dialogOpen } from '../../data/redux/actions/dialog';
import { patientCleared } from '../../data/redux/actions/patient';
import { formTemplateCleared } from '../../data/redux/actions/formTemplate';
import { sectionCleared } from '../../data/redux/actions/section';

import AppBarMenu from '../../components/AppBarMenu';
import UserDialog from '../../components/UserDialog';
import ChangePasswordDialog from '../SettingsPage/PracticeSettings/ChangePasswordDialog';

const UserPracticePage = ({
    classes,
    userName,
    userEmail,
    onChangePassword,
    notifyPasswordSuccess,
    updateNotifyPasswordSuccess,
    changePasswordDialog,
    updateChangePasswordDialog,
    onChangePasswordSuccess,
}) => (
    <AppBarMenu>
        <Grid component='main' className={classes.main} container justifyContent='center'>
            <Grid className={classes.content} container direction='column' wrap='nowrap' item xs={12} sm={11} md={10} lg={8} xl={6}>
                <Typography variant='subtitle1' className={classes.subheadingFirst}>
                    User Information
                </Typography>
                <Paper className={classes.paper}>
                    <ListItem divider className={classes.listItem}>
                        <ListItemText secondary='name' className={classes.label} />
                        <ListItemText
                            className={classes.detail}
                            disableTypography
                            primary={
                                <Typography variant='subtitle1'>
                                    <strong>{userName}</strong>
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem divider className={classes.listItem}>
                        <ListItemText secondary='email' className={classes.label} />
                        <ListItemText
                            className={classes.detail}
                            disableTypography
                            primary={
                                <Typography variant='subtitle1'>
                                    {userEmail}
                                    <Button onClick={onChangePassword} variant='outlined' color='secondary' className={classes.changePasswordButton}>
                                        Change password
                                    </Button>
                                </Typography>
                            }
                        />
                    </ListItem>
                </Paper>
                {/* <Typography variant="subtitle1" className={classes.subheading}>
          Practice Information
        </Typography> */}
                {/* <Paper className={classes.paper}>
          <ListItem divider className={classes.listItem}>
            <ListItemText secondary="name" className={classes.label} />
            <ListItemText
              className={classes.detailPractice}
              disableTypography
              primary={
                <Fragment>
                  <Typography variant="subtitle1">
                    <strong>{practiceName}</strong>
                  </Typography>
                  {isAdmin && (
                    <Grid>
                      <Button
                        onClick={onPractice}
                        variant="contained"
                        color="secondary"
                        className={classes.practiceButton}
                      >
                        Edit Practice
                      </Button>
                      <Link to="/subscription">
                        <Button
                          color="secondary"
                          variant="contained"
                          className={classes.practiceButton}
                        >
                          Manage Plan
                        </Button>
                      </Link>
                    </Grid>
                  )}
                </Fragment>
              }
            />
          </ListItem>
        </Paper> */}
                {/* {isAdmin && (
          <React.Fragment>
            <Typography variant="subtitle1" className={classes.subheading}>
              Practice Users
            </Typography>
            {!isConsentFormSubscription && <UsersTable />}
            {isConsentFormSubscription && <UsersTableConsentForm />}
          </React.Fragment>
        )} */}
                {/* {!isConsentFormSubscription && (
          <div>
            <Typography variant="subtitle1" className={classes.subheading}>
              Practice Form Templates
            </Typography>
            <FormTemplateList />
          </div>
        )} */}
            </Grid>
            {/* <PracticeDialog /> */}
            <UserDialog />

            {/* {!isConsentFormSubscription && (
        <Dialog
          open={templateListDialog}
          onClose={() => updateTemplateListDialog(false)}
          maxWidth="lg"
        >
          <DrawingTemplateList
            dialogTitle="New Image Template"
            onClose={() => updateTemplateListDialog(false)}
            shouldHideBlankTemplate
          />
        </Dialog>
      )} */}

            {changePasswordDialog && (
                <Dialog open={changePasswordDialog} onClose={() => updateChangePasswordDialog(false)} maxWidth='md' fullWidth>
                    <DialogTitle>Change password</DialogTitle>
                    <DialogContent>
                        <ChangePasswordDialog
                            updateChangePasswordDialog={updateChangePasswordDialog}
                            onChangePasswordSuccess={() => {
                                onChangePasswordSuccess();
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {/* <FloatingActionButtons
        templateLibrary
        formTemplate
        user
        consentForm
        consentFormDashboard
        onNewTemplateImage={() => {
          updateTemplateListDialog(true);
        }}
      /> */}

            {notifyPasswordSuccess && (
                <Snackbar open={notifyPasswordSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert
                        severity='success'
                        onClose={() => {
                            updateNotifyPasswordSuccess(false);
                        }}>
                        Your password has been successfully updated.
                    </Alert>
                </Snackbar>
            )}
        </Grid>
    </AppBarMenu>
);

const styles = {
    main: {
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 44,
        paddingRight: 44,
    },
    leftButton: {
        position: 'absolute',
        left: 0,
        marginLeft: 12,
    },
    content: {
        paddingTop: 28,
    },
    subheadingFirst: {
        fontWeight: 'bold',
        paddingBottom: 8,
    },
    subheading: {
        fontWeight: 'bold',
        paddingTop: 36,
        paddingBottom: 8,
    },
    label: {
        flex: 0.5,
    },
    detail: {
        flex: 1,
    },
    detailPractice: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    listItem: {
        paddingTop: 24,
        paddingBottom: 24,
    },
    practiceButton: {
        marginLeft: 8,
        marginRight: 8,
    },

    changePasswordButton: {
        marginLeft: 8,
        marginRight: 16,
        right: 0,
        position: 'absolute',
    },
};

export default compose(
    withStyles(styles),
    connect(({ dialog, practice, user }) => ({
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        practiceName: practice.name,
        userDialog: dialog.open && dialog.type === 'user',
        userName: user.name,
        userEmail: user.email,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withState('templateListDialog', 'updateTemplateListDialog', false),
    withState('changePasswordDialog', 'updateChangePasswordDialog', false),
    withState('notifyPasswordSuccess', 'updateNotifyPasswordSuccess', false),
    withHandlers({
        onPractice:
            ({ dispatch }) =>
            () =>
                dispatch(dialogOpen({ type: 'practice' })),
        onChangePassword:
            ({ updateChangePasswordDialog }) =>
            () => {
                updateChangePasswordDialog(true);
            },
        onChangePasswordSuccess:
            ({ updateNotifyPasswordSuccess }) =>
            () => {
                updateNotifyPasswordSuccess(true);
                setTimeout(() => updateNotifyPasswordSuccess(false), 5000);
            },
    }),
    lifecycle({
        componentDidMount() {
            this.props.dispatch(formTemplateCleared());
            this.props.dispatch(sectionCleared());
            this.props.dispatch(patientCleared());
        },
    })
)(UserPracticePage);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { DialogTitle, Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Alert } from '@material-ui/lab';

import EnterEmailScreen from './EnterEmailScreen';

const SendFormDialog = ({
  classes,
  formKey: key,
  formName,
  dialogOpen,
  setDialogOpen,
}) => {
  const [responseMessage, setResponseMessage] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [form, setForm] = useState({
    key,
  });

  const resetMessage = () => {
    setResponseMessage({
      ...responseMessage,
      message: '',
    });
  };

  const handleFormReset = () => {
    setForm({
      key,
    });
    setResponseMessage({ open: false, severity: 'success', message: '' });
  };

  const handleToggle = () => {
    setDialogOpen(!dialogOpen);
    handleFormReset();
  };

  useEffect(() => {
    setForm({
      key,
    });
  }, [key]);

  return (
    <>
      <Dialog
        className={classes.modal}
        fullWidth
        open={dialogOpen}
        onClose={handleToggle}
        disableEscapeKeyDown
      >
        <DialogTitle>
          {responseMessage.message ? 'Success' : `Send a Form (${formName})`}
        </DialogTitle>
        {responseMessage.message ? (
          <Snackbar
            open
            autoHideDuration={3000}
            onClose={resetMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity={responseMessage.severity}>
              {responseMessage.message}
            </Alert>
          </Snackbar>
        ) : null}
        <EnterEmailScreen
          form={form}
          setForm={setForm}
          setResponseMessage={setResponseMessage}
        />
      </Dialog>
    </>
  );
};

const styles = {
  contentContainer: {
    height: 500,
  },
  modal: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
};

export default compose(
  connect(({ practice, user }) => ({
    practiceId: practice.id,
  })),
  withStyles(styles)
)(SendFormDialog);

// action types
export const SUBSCRIPTION_SHOW_LOADING = 'SUBSCRIPTION_SHOW_LOADING';
export const SUBSCRIPTION_SET_OPTIONS = 'SUBSCRIPTION_SET_OPTIONS';
export const SUBSCRIPTION_SELECT_PLAN = 'SUBSCRIPTION_SELECT_PLAN';
export const SUBSCRIPTION_SELECT_USERS = 'SUBSCRIPTION_SELECT_USERS';
export const SUBSCRIPTION_ERROR_MESSAGE = 'SUBSCRIPTION_ERROR_MESSAGE';
export const SUBSCRIPTION_SHOW_CC = 'SUBSCRIPTION_SHOW_CC';

// action creators
export const subscriptionSetOptions = (plans, subscription) => ({
  type: SUBSCRIPTION_SET_OPTIONS,
  plans,
  subscription,
});

export const subscriptionSelectPlan = planid => ({
  type: SUBSCRIPTION_SELECT_PLAN,
  planid,
});

export const subscriptionErrorMessage = error => ({
  type: SUBSCRIPTION_ERROR_MESSAGE,
  error,
});

export const subscriptionSelectUsers = selectedNumberOfUsers => ({
  type: SUBSCRIPTION_SELECT_USERS,
  selectedNumberOfUsers,
});

export const subscriptionShowCreditCard = showCC => ({
  type: SUBSCRIPTION_SHOW_CC,
  showCC,
});

export const subscriptionShowLoading = loading => ({
  type: SUBSCRIPTION_SHOW_LOADING,
  loading,
});

import { gql } from '@apollo/client';

export const GET_PATIENT_SUBMISSIONS = gql`
    query listSubmissionsByPatient($patientId: String!) {
        listSubmissionsByPatient(patientId: $patientId) {
            submissions {
                id
                patient_id
                createdAt
                details {
                    title
                    type
                    name
                    value
                }
                form {
                    id
                    title
                    description
                    key
                    state
                    schema
                    uiSchema
                    submissions_count
                    fields {
                        type
                        title
                        name
                        is_required
                    }
                }
            }
        }
    }
`;

export const UPSERT_PATIENT = gql`
    mutation upsertPatient(
        $patientId: ID
        $practiceId: ID!
        $name: String
        $firstName: String
        $middleName: String
        $lastName: String
        $address: String
        $email: String
        $dob: Date!
        $phone: String
        $mobile: String
        $isArchived: Boolean
        $flexibleFieldData1: String
        $flexibleFieldData2: String
        $flexibleFieldData3: String
        $flexibleFieldData4: String
        $flexibleFieldData5: String
    ) {
        upsertPatient(
            id: $patientId
            practice: $practiceId
            name: $name
            firstName: $firstName
            middleName: $middleName
            lastName: $lastName
            address: $address
            email: $email
            dob: $dob
            phone: $phone
            mobile: $mobile
            isArchived: $isArchived
            flexibleFieldData1: $flexibleFieldData1
            flexibleFieldData2: $flexibleFieldData2
            flexibleFieldData3: $flexibleFieldData3
            flexibleFieldData4: $flexibleFieldData4
            flexibleFieldData5: $flexibleFieldData5
        ) {
            patient {
                id
                practice {
                    id
                }
            }
        }
    }
`;

export const FETCH_APPOINTMENTS = gql`
    query listAppointments($end: DateTime, $locationId: ID!, $patientEncodedId: ID, $start: DateTime) {
        listAppointments(end: $end, locationId: $locationId, patientEncodedId: $patientEncodedId, start: $start) {
            id
            appointmentType {
                id
                name
                color
                code
            }
            start
            end
            clinician {
                name
            }
            checkedIn
            invoiced
        }
    }
`;

export const GET_APPOINTMENT_EXPORT = gql`
    mutation requestAppointmentExport(
        $data: [AppointmentPdfLineItem]
        $endDate: String
        $locationId: ID!
        $patientEncodedId: ID!
        $practiceEncodedId: ID!
        $startDate: String
    ) {
        requestAppointmentExport(
            data: $data
            endDate: $endDate
            locationId: $locationId
            patientEncodedId: $patientEncodedId
            practiceEncodedId: $practiceEncodedId
            startDate: $startDate
        ) {
            file
        }
    }
`;

export const LIST_MINDBODY_INVOICES_FOR_PATIENT = gql`
    query fetchMindbodyInvoicesForPatient($encodedPracticeId: String, $encodedPatientId: ID) {
        fetchMindbodyInvoicesForPatient(encodedPracticeId: $encodedPracticeId, encodedPatientId: $encodedPatientId) {
            id
            invoiceNumber
            patientId
            invoiceUri
            practiceId
            locationId
            patientName
            invoiceDate
            totalAmount
            redeemedAmount
        }
    }
`;

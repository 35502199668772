import React from 'react';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import TextNoteContainer from './TextNoteContainer';
import CategoryListPopover from '../CategoryListPopover';
import DeleteNoteDialog from '../DeleteNoteDialog';
import DuplicateDialog from '../DuplicateDialog';
import EpisodePopover from '../EpisodePopover';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import MoreInfoPopover from '../MoreInfoPopover';
import SignOffDialog from '../SignOffDialog';
import ErrorAlertDialog from '../ErrorAlertDialog';

const TextNote = ({
  classes,
  error,
  form,
  initialSave,
  isClinician,
  isReceptionist,
  isAdmin,
  onDuplicate,
  onSave,
  onSign,
  onTag,
  status = {},
  updateForm,
  canEdit,
  onArchiveNote,
  isHideAction,
  cursor,
  updateCursor,
}) => (
  <Grid>
    {form.error && !form.loading && (
      <ErrorAlertDialog
        error={form.error}
        onExisted={() =>
          updateForm(prevForm => ({ ...prevForm, error: undefined }))
        }
      />
    )}
    <div className={classes.titleRow}>
      <TextField
        id="title"
        autoComplete="off"
        label="Title"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
        placeholder="Untitled Text Note"
        autoFocus={form.autoFocus === 'title'}
        className={classes.inputTitle}
        disabled={!canEdit || initialSave || status.loading}
        value={form.title || ''}
        onChange={async event => {
          let shouldSave = false;
          if (event.target.value) shouldSave = true;
          await updateForm({
            ...form,
            title: event.target.value,
            autoFocus: 'title',
          });
          if (shouldSave) onSave();
        }}
      />
      {isHideAction || (
        <Grid className={classes.buttons}>
          {form.loading && <Typography variant="body1">Saving ...</Typography>}
          {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
            <EpisodePopover
              noteId={form.id}
              selectedEpisodes={form.episodeIdsArray}
              onTag={onTag}
              loading={status.loading}
              updateEpisodes={episodeIdsArray => {
                updateForm({ ...form, episodeIdsArray });
              }}
            />
          )}
          {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
            <CategoryListPopover
              noteId={form.id}
              selectedCategories={form.categoryIdsArray}
              onTag={onTag}
              loading={status.loading}
              updateCategories={categoryIdsArray =>
                updateForm({
                  ...form,
                  categoryIdsArray: categoryIdsArray || [],
                })
              }
            />
          )}
          {((form.isAuthor && (isClinician || isAdmin)) ||
            form.isSignedOff) && (
            <SignOffDialog
              noteId={form.id}
              isSignedOff={form.isSignedOff}
              signedOffTime={form.signedOffTime}
              onSign={onSign}
              loading={status.loading}
              authorName={form.authorName}
            />
          )}
          {(isClinician || isAdmin) && (
            <DuplicateDialog
              noteId={form.id}
              loading={status.loading}
              onDuplicate={onDuplicate}
            />
          )}
          <MoreInfoPopover
            authorName={form.author && form.author.name}
            createdAt={form.createdAt}
            episodes={form.episodes}
            loading={status.loading}
            noteId={form.id}
            patientName={form.patientName}
            signedOffTime={form.signedOffTime}
          />
          {form.isAuthor && (isClinician || isAdmin) && (
            <DeleteNoteDialog
              isSignedOff={form.isSignedOff}
              loading={status.loading}
              noteId={form.id}
              onArchiveNote={() => onArchiveNote()}
            />
          )}
        </Grid>
      )}
    </div>
    <Paper className={classes.paper}>
      {(() => {
        if (status.loading) return <Loading />;
        if (status.error) return <ErrorMessage error={error} />;

        return (
          <Grid container direction="column">
            <TextField
              id="text"
              autoComplete="off"
              label="Text"
              autoFocus={form.autoFocus === 'text'}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  disabled: classes.disabled,
                },
              }}
              multiline
              disabled={!canEdit || initialSave}
              className={classes.inputText}
              defaultValue={form.text || ''}
              onFocus={event => {
                event.target.selectionStart = cursor;
              }}
              onChange={async event => {
                // this.cursor = event.target.selectionStart;
                updateCursor(event.target.selectionStart);
                await updateForm({
                  ...form,
                  text: event.target.value,
                  autoFocus: 'text',
                });
                onSave();
              }}
            />
          </Grid>
        );
      })()}
    </Paper>
  </Grid>
);

const styles = {
  disabled: {
    color: 'black',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 18,
  },
  paper: {
    marginBottom: 36,
    padding: 20,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 16,
  },
  inputTitle: {
    flexBasis: '65%',
    fontWeight: 'bold',
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
};

export default compose(TextNoteContainer, withStyles(styles))(TextNote);

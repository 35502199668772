/* eslint-disable react/prop-types */
import React from 'react';
import { compose, withHandlers, branch } from 'recompose';
import { connect } from 'react-redux';

import { graphql } from '@apollo/client/react/hoc';
import {
  Paper,
  Typography,
  ListItem,
  ListItemText,
  Button,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { dialogOpen } from '../../../data/redux/actions/dialog';
import PracticeDialog from '../../../components/PracticeDialog';
import { FETCH_PRACTICE } from '../gql';
import Restricted from '../../../components/Restricted';

const EditPractice = ({ classes, practiceName, onPractice }) => {
  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="subtitle1" className={classes.subheading}>
          Practice Information
        </Typography>

        <Restricted isAllowedTo="upsertPractice">
          <Grid className={classes.buttonContainer}>
            <Button
              onClick={onPractice}
              variant="contained"
              color="secondary"
              className={classes.practiceButton}
            >
              Edit Practice
            </Button>
            {/* <Link to="/subscription"> */}
            <Button
              color="secondary"
              variant="contained"
              className={classes.practiceButton}
              onClick={() => {
                window.location.href = '/subscription';
              }}
            >
              Manage Plan
            </Button>
          </Grid>
        </Restricted>
      </div>
      <Paper className={classes.paper}>
        <ListItem divider className={classes.listItem}>
          <ListItemText secondary="Name" className={classes.label} />
          <ListItemText
            className={classes.detailPractice}
            disableTypography
            primary={
              <React.Fragment>
                <Typography variant="subtitle1">
                  <strong>{practiceName}</strong>
                </Typography>

                <PracticeDialog />
              </React.Fragment>
            }
          />
        </ListItem>
      </Paper>
      <PracticeDialog />
    </>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  label: {
    flex: 0.5,
  },
  subheading: {
    fontWeight: 'bold',
  },
  practiceButton: {
    marginLeft: 8,
    height: 36,
  },
  buttonContainer: {
    display: 'flex',
  },
  detailPractice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    practiceId: practice.id,
    userName: user.name,
    userEmail: user.email,
  })),
  branch(
    ({ practiceId }) => !!practiceId,
    graphql(FETCH_PRACTICE, {
      name: 'fetchPractice',
    })
  ),
  withHandlers({
    onPractice: ({ dispatch }) => () =>
      dispatch(dialogOpen({ type: 'practice' })),
    onChangePassword: ({ updateChangePasswordDialog }) => () => {
      updateChangePasswordDialog(true);
    },
    onChangePasswordSuccess: ({ updateNotifyPasswordSuccess }) => () => {
      updateNotifyPasswordSuccess(true);
      setTimeout(() => updateNotifyPasswordSuccess(false), 5000);
    },
  })
)(EditPractice);

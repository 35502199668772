// action types

export const SECTION_NEW_TYPE = 'SECTION_NEW_TYPE';
export const SECTION_SELECT = 'SECTION_SELECT';
export const SECTION_CLEARED = 'SECTION_CLEARED';

// action creators

export const sectionNewType = (newType, rowNumber) => ({
  type: SECTION_NEW_TYPE,
  newType,
  rowNumber,
});

export const sectionSelect = selectedSection => ({
  type: SECTION_SELECT,
  selectedSection,
});

export const sectionCleared = () => ({ type: SECTION_CLEARED });

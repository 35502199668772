import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';

import { dialogClosed } from '../../data/redux/actions/dialog';
import { practiceSelected } from '../../data/redux/actions/practice';

const updatePracticeMutation = gql`
  mutation updatePractice($id: ID!, $name: String) {
    updatePractice(id: $id, name: $name) {
      practice {
        id
        name
      }
    }
  }
`;

const initialForm = {
  loading: false,
  error: false,
  name: undefined,
};

const localHandlers = {
  onChangeName: ({ form, updateForm }) => event =>
    updateForm({ ...form, name: event.target.value }),
  onClose: ({ dispatch }) => () => dispatch(dialogClosed()),
};

const apiHandlers = {
  onSave: ({ dispatch, form, mutate, practiceId, updateForm }) => async () => {
    updateForm({ ...form, loading: true });

    try {
      await mutate({
        variables: {
          id: practiceId,
          name: form.name,
        },
      });
      dispatch(practiceSelected({ name: form.name }));
      dispatch(dialogClosed());
    } catch (error) {
      updateForm({ ...form, loading: false, error });
    }
  },
};

const PracticeDialogContainer = compose(
  connect(({ dialog, practice }) => ({
    practiceId: practice.id,
    practiceName: practice.name,
    practiceDialogOpen: dialog.type === 'practice' && dialog.open,
  })),
  withState('form', 'updateForm', initialForm),
  graphql(updatePracticeMutation),
  withHandlers(apiHandlers),
  withHandlers(localHandlers),
  lifecycle({
    componentDidMount() {
      const { form, practiceId, practiceName, updateForm } = this.props;

      updateForm({ ...form, id: practiceId, name: practiceName });
    },
  })
);

export default PracticeDialogContainer;

import React from 'react';
import { compose, withHandlers, withState, lifecycle } from 'recompose';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

import { BLACK, SECONDARY } from '../../style/constants';
import CategoryList from '../CategoryList';
import SearchBar from '../SearchBar';
// import { practiceSearched } from '../../data/redux/actions/practice';

const CategoryListPopover = ({
  anchorEl,
  classes,
  onClose,
  tempCategories,
  updateAnchorEl,
  updateTempCategories,
  loading,
}) => (
  <React.Fragment>
    <Tooltip title="Tag a note" placement="bottom-start">
      <span>
        <IconButton
          disabled={loading}
          onClick={event => {
            updateAnchorEl(event.currentTarget);
          }}
          aria-label="Categories List"
          color="inherit"
        >
          <FontAwesomeIcon
            icon={faHashtag}
            className={classes.dateRangeIcon}
            size="sm"
          />
        </IconButton>
      </span>
    </Tooltip>

    <Modal
      aria-labelledby="category-list"
      aria-describedby="category-list"
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={2}
      >
        <Grid
          className={classes.grid}
          container
          wrap="nowrap"
          direction="column"
        >
          <SearchBar />
          <CategoryList
            tempCategories={tempCategories}
            updateTempCategories={updateTempCategories}
          />
        </Grid>
      </Popover>
    </Modal>
  </React.Fragment>
);

const styles = {
  dateRangeIcon: ({ tempCategories, loading }) => ({
    color: tempCategories && tempCategories.length !== 0 ? SECONDARY : BLACK,
    opacity: loading ? 0.26 : 1,
  }),
  grid: {
    height: 377,
    width: 320,
    overflow: 'auto',
  },
};

export default compose(
  withState('anchorEl', 'updateAnchorEl', null),
  withState('tempCategories', 'updateTempCategories', []),
  withStyles(styles),
  withHandlers({
    onClose: ({
      // dispatch,
      groupNotes,
      noteId,
      onTag,
      tempCategories,
      selectedCategories = [],
      updateCategories,
      updateAnchorEl,
    }) => async () => {
      if (!isEqual(tempCategories.sort(), selectedCategories.sort())) {
        await updateCategories(tempCategories || []);
        if (noteId && onTag) {
          await onTag();
        } else if (some(groupNotes, 'id')) {
          groupNotes.forEach(note => onTag(note.id));
        }
      }
      // DEVNOTE: dispatch was undefined here for some reason; maybe not required
      // dispatch(practiceSearched(undefined));
      updateAnchorEl(null);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateTempCategories(this.props.selectedCategories);
    },
    componentDidUpdate(prevProps) {
      const { selectedCategories, updateTempCategories } = this.props;

      if (
        !!selectedCategories &&
        !!prevProps.selectedCategories &&
        selectedCategories.length !== prevProps.selectedCategories.length
      ) {
        updateTempCategories(selectedCategories);
      }
    },
  })
)(CategoryListPopover);

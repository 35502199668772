// action types

export const NOTE_UPSERT = 'NOTE_UPSERT';
export const NOTE_CLEARED = 'NOTE_CLEARED';

// action creators

export const noteUpsert = ({
  id,
  __typename,
  title,
  isSignedOff,
  signedOffTime,
  templateId,
  groupNotes,
  groupTemplates,
  groupId,
}) => ({
  type: NOTE_UPSERT,
  __typename,
  id,
  title,
  isSignedOff,
  signedOffTime,
  templateId,
  groupNotes,
  groupTemplates,
  groupId,
});

export const noteCleared = () => ({ type: NOTE_CLEARED });

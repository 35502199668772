import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { SECONDARY } from '../../style/constants';
import AppBarMenu from '../../components/AppBarMenu';

const SubscriptionExpiredPage = ({ history, classes, isAdmin }) => (
  <AppBarMenu>
    <Grid component="main" className={classes.main} container>
      <Grid component="nav" container wrap="nowrap" direction="column">
        <div className={classes.pageTitle}>Subscription Expired</div>
        <p className={classes.pageText}>
          The subscription for this practice has expired.
        </p>
        <p className={classes.pageText}>
          You can view and export existing data only.
        </p>
        {!isAdmin && (
          <p className={classes.pageText}>
            Please contact your practice administrator to subscribe to a plan.
          </p>
        )}
        <p className={classes.pageText}>
          You will need to logout and log back into the sytem for a new plan to
          take effect.
        </p>
        {isAdmin && (
          <div className={classes.renewButtonContainer}>
            <Button
              className={classes.renewButton}
              variant="contained"
              color="secondary"
              onClick={() => history.replace('/subscription')}
            >
              Renew subscription
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  </AppBarMenu>
);

const styles = {
  main: {
    height: '100%',
    paddingTop: 64,
  },
  leftButton: {
    position: 'absolute',
    left: 0,
    marginLeft: 12,
  },
  pageTitle: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 32,
    color: SECONDARY,
  },
  pageText: {
    display: 'flex',
    fontSize: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '5px 0',
  },
  renewButtonContainer: {
    textAlign: 'center',
    marginTop: 20,
  },
  renewButton: {
    marginLeft: 8,
    marginRight: 8,
  },
};

export default compose(
  connect(props => ({
    isAdmin: props.user.roles.includes('ADMIN'),
  })),
  withStyles(styles)
)(SubscriptionExpiredPage);

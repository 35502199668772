import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import {
  processTemplateData,
  processDrawingNoteData,
  processFormNoteSection,
  processFormNoteGroupedSections,
} from './utils';
import DrawingToolComponent from './DrawingToolComponent';

const getCanvasDataFromProps = ({
  templateData,
  drawingNoteData,
  formNoteSection,
  formNoteGroupedSections,
}) => {
  let canvasData = [];

  // Get canvas data from templateData
  if (templateData) {
    const data = processTemplateData(templateData);
    data.forEach(item => {
      canvasData.push({
        id: item.fileId,
        template: {
          file: item,
        },
      });
    });
  }

  // Get canvas data from drawingNoteData
  if (drawingNoteData) {
    canvasData = processDrawingNoteData(drawingNoteData);
  }

  // Get canvas data from FormNoteSection
  if (formNoteSection) {
    canvasData = processFormNoteSection(formNoteSection);
  }

  // Get canvas data from FormNoteGroupSection
  if (formNoteGroupedSections) {
    canvasData = processFormNoteGroupedSections(formNoteGroupedSections);
  }

  // create canvas with no background or template
  if (canvasData.length === 0) {
    canvasData.push({
      id: 'blankId',
    });
  }
  return canvasData;
};

/**
 * Get selected symptoms from canvas data
 * @param {Object} canvasData
 */
const getPreSelectedSymptoms = canvasData => {
  const symptoms = [];

  if (canvasData.length < 1) return symptoms;

  const note = canvasData[canvasData.length - 1];
  if (!note.drawingData) return symptoms;

  const drawingData = JSON.parse(note.drawingData);
  if (drawingData.rawCanvasJson) {
    const { rawCanvasJson } = drawingData;

    if (rawCanvasJson.objects && rawCanvasJson.objects.length > 0) {
      rawCanvasJson.objects.forEach(object => {
        if (
          object.type === 'i-text' &&
          object.id.includes('iText') &&
          object.id.includes('#')
        ) {
          const [, key, type] = object.id.split('#');
          if (key && type) {
            const hasKey = symptoms.filter(e => e.key === key).length > 0;
            if (!hasKey) {
              if (type === 'text') {
                symptoms.push({
                  key,
                  type,
                  value: object.text.replace(`${key}: `, '').split(', '),
                });
              } else {
                const textWithoutKey = object.text.replace(`${key}: `, '');
                let maxValue = 10;
                if (textWithoutKey.includes('/')) {
                  [, maxValue] = textWithoutKey.split('/');
                }
                const existingValue = textWithoutKey.split('/')[0];
                symptoms.push({
                  key,
                  type,
                  maxValue,
                  value: existingValue.includes('-')
                    ? [
                        parseInt(existingValue.split('-')[0], 10),
                        parseInt(existingValue.split('-')[1], 10),
                      ]
                    : [0, parseInt(existingValue, 10)],
                });
              }
            }
          }
        }
      });
    }
  }

  return symptoms;
};

export default compose(
  withState('selectedTemplateId', 'setSelectedTemplateId', undefined),
  withState('templateData', 'setTemplateData', props => props.templateData),
  withPropsOnChange(
    [
      'formNoteSection',
      'formNoteGroupedSections',
      'drawingNoteData',
      'templateData',
    ],
    props => {
      const {
        formNoteSection,
        formNoteGroupedSections,
        drawingNoteData,
      } = props;
      const canvasData = getCanvasDataFromProps(props);
      const symptopSelection = getPreSelectedSymptoms(canvasData);

      return {
        canvasData,
        symptopSelection,
        shouldHasHomeBtn: !formNoteSection && !formNoteGroupedSections,
        shouldHasTemplateBtn:
          !drawingNoteData && !formNoteSection && !formNoteGroupedSections,
      };
    }
  ),
  withHandlers({
    onTemplateChange: props => data => {
      const { setSelectedTemplateId, setTemplateData } = props;
      const { templateId, templateData, groupedTemplates, fileName } = data;
      let templates;

      if (!templateId) return;

      setSelectedTemplateId(templateId);

      if (groupedTemplates) {
        templates = {
          __typename: 'Group',
          templates: groupedTemplates.map(item => ({
            id: item.id,
            name: item.name,
            file: {
              encodedFile: item.file.encodedFile,
            },
          })),
        };
      } else if (templateData) {
        templates = {
          name: fileName,
          file: {
            id: templateId,
            encodedFile: templateData,
          },
        };
      }

      setTemplateData(templates);
    },

    // @TODO refactor this function
    onSave: props => data => {
      const {
        formNoteSection,
        formNoteGroupedSections,
        onSaveDrawingFormNote,
        saveAction,
      } = props;
      let canSave = false;
      if (formNoteSection || formNoteGroupedSections) {
        if (onSaveDrawingFormNote) {
          if (formNoteGroupedSections) {
            const originGroupedSections = [...formNoteGroupedSections];
            if (data.length > 0 && originGroupedSections.length > 0) {
              data.forEach(drawingItem => {
                originGroupedSections.forEach(originItem => {
                  if (
                    drawingItem.fileId === originItem.id &&
                    drawingItem.data
                  ) {
                    originItem.drawingData = JSON.stringify(drawingItem.data);
                  }
                });
              });
            }
            onSaveDrawingFormNote(originGroupedSections, true);
            canSave = true;
          } else if (formNoteSection) {
            const originSection = { ...formNoteSection };
            data.forEach(drawingItem => {
              if (drawingItem.fileId === originSection.id && drawingItem.data) {
                originSection.drawingData = JSON.stringify(drawingItem.data);
              }
            });
            onSaveDrawingFormNote(originSection, false);
            canSave = true;
          }
        }
      } else {
        saveAction(data);
        canSave = true;
      }

      return canSave;
    },
  })
)(DrawingToolComponent);

import React, { useState } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import PatientSelect from '../../components/PatientList/PatientSelect';
import SearchBar from '../../components/SearchBar';
import { WARM_PINK } from '../../style/constants';

const SubmissionDialog = ({
  allowsRegistration,
  classes,
  formKey,
  formName,
  dialogOpen,
  setDialogOpen,
}) => {
  const [form, setForm] = useState({
    patient: {},
  });

  return (
    <>
      <Dialog
        className={classes.modal}
        fullWidth
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        disableEscapeKeyDown
      >
        <DialogTitle>{`Select a Patient for Form (${formName})`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <SearchBar showIncludeArchived={false} />
            <PatientSelect
              form={form}
              patientId={_.get(form, 'patient.id')}
              setForm={setForm}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            className={classes.button}
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={classes.warmPinkButton}
            disabled={!form.patient || !form.patient.id}
            href={`/consent-form/${formKey}/${_.get(form, 'patient.id')}`}
            target="_blank"
          >
            Next
          </Button>
          {allowsRegistration && (
            <Tooltip
              title="Includes Patient Registration"
              placement="bottom-start"
            >
              <Button
                className={classes.button}
                color="primary"
                disabled={!formKey}
                href={`/consent-form/${formKey}/`}
                target="_blank"
              >
                Fresh Submission
              </Button>
            </Tooltip>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  contentContainer: {
    height: 500,
  },
  modal: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  warmPinkButton: {
    padding: 10,
    height: 40,
    background: WARM_PINK,
    color: 'white',
    marginLeft: 12,
  },
};

export default compose(withStyles(styles))(SubmissionDialog);

import React, { useState } from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Button, Paper, Typography } from '@material-ui/core';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Jimp from 'jimp';
import { gql, useQuery } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { WARM_PINK } from '../../../style/constants';
import { FETCH_PRACTICE } from '../gql';
import Loading from '../../../components/Loading';

const PracticeLogo = ({ classes, practiceEncodedId, practiceLogo, onUploadLogo }) => {
    const [practiceData, setPracticeData] = useState({});

    const { loading } = useQuery(FETCH_PRACTICE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.fetchPractice) {
                setPracticeData(res.fetchPractice);
            }
        },
        variables: {
            practiceEncodedId,
        },
    });

    return (
        <>
            <div className={classes.headerContainer}>
                <Typography variant='subtitle1' className={classes.subheading}>
                    Practice Logo
                </Typography>

                <form
                    name='uploadForm'
                    style={{
                        padding: 10,
                    }}>
                    <label htmlFor='file-input'>
                        <Button component='span' className={classes.redButton} startIcon={<CloudUploadIcon />} variant='contained' size='small'>
                            Upload
                        </Button>
                        <input
                            style={{ display: 'none' }}
                            id='file-input'
                            type='file'
                            accept='.jpg,.jpeg,.png'
                            onChange={(event) => onUploadLogo(event.target.files)}
                        />
                    </label>
                </form>
            </div>
            {loading && <Loading />}
            {!practiceData.logo && !loading && <Paper className={classes.submissionsPaper}>No Logo</Paper>}
            {practiceData.logo && !loading && (
                <Paper className={classes.submissionsPaper}>
                    <img
                        className={classes.practiceLogo}
                        style={{ padding: 5 }}
                        src={`data:image/png;base64,${practiceData.logo.encodedFile}`}
                        alt='Practice Logo'></img>
                </Paper>
            )}
        </>
    );
};

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    subheading: {
        fontWeight: 'bold',
    },
    field: {
        width: 240,
    },
    practiceLogo: {
        margin: 20,
    },
    redButton: {
        marginTop: -30,
        marginRight: -7,
        height: 36,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
};

const upsertPracticeLogoMutation = gql`
    mutation upsertPracticeLogo($id: ID!, $encodedFile: String) {
        upsertPracticeLogo(id: $id, encodedFile: $encodedFile) {
            practice {
                id
                logo {
                    id
                    encodedFile
                }
            }
        }
    }
`;

const apiHandlers = {
    onUploadLogo:
        ({ updateFileLoading, practiceEncodedId, upsertPracticeLogo }) =>
        async (files) => {
            if (files.length === 0) return;
            updateFileLoading(true);

            const reader = new FileReader();

            reader.readAsDataURL(files[0]);
            reader.onloadend = async () => {
                // removes mime type prefix from reader.result.
                let fileData = reader.result.split(',')[1];
                await Jimp.read(reader.result)
                    .then((image) => {
                        // Do stuff with the image.
                        const maxSize = 500;
                        if (image.getWidth() > image.getHeight()) {
                            if (image.getWidth() > maxSize) {
                                image.resize(maxSize, Jimp.AUTO, Jimp.RESIZE_BEZIER);
                            }
                        } else if (image.getHeight() > maxSize) {
                            image.resize(maxSize, Jimp.AUTO, Jimp.RESIZE_BEZIER);
                        }
                        if (image) {
                            image.getBase64Async(image.getMIME()).then((base64Value) => {
                                if (base64Value.includes(',')) {
                                    fileData = base64Value.split(',')[1];
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        // Handle an exception.
                        console.log(`Resize image Error: ${err}`);
                    });
                try {
                    await upsertPracticeLogo({
                        variables: {
                            id: practiceEncodedId,
                            encodedFile: fileData,
                        },
                        refetchQueries: [
                            {
                                query: FETCH_PRACTICE,
                                variables: { practiceEncodedId },
                            },
                        ],
                    });
                    updateFileLoading(false);
                } catch (error) {
        console.log('API Error: ', error); // eslint-disable-line
                    updateFileLoading(false);
                }
            };
        },
};

export default compose(
    connect(({ practice, user }) => ({
        practiceEncodedId: practice.id,
    })),
    withStyles(styles),
    withState('fileLoading', 'updateFileLoading', false),
    graphql(upsertPracticeLogoMutation, { name: 'upsertPracticeLogo' }),
    withHandlers(apiHandlers)
)(PracticeLogo);

import React from 'react';
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CreateIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './styles';

const PaperWrapper = withStyles({
  root: styles.topMenu,
})(Paper);

const ResponsiveButton = ({ icon, children, ...props }) => {
  if (isMobileOnly || isTablet || isIPad13) {
    if (children === 'Stylus') {
      return (
        <IconButton {...props}>
          {icon}
          <CreateIcon />
        </IconButton>
      );
    }
    return <IconButton {...props}>{icon}</IconButton>;
  }

  return (
    <Button startIcon={icon} {...props}>
      {children}
    </Button>
  );
};

const NavigationMenu = ({
  handleBackBtnClick,
  isHomeBtnVisible,
  isStylusEnable,
  handleHomeBtnClick,
  isTemplateBtnVisible,
  handleTemplateBtnClick,
  handleSaveBtnClick,
  handleDiscardBtnClick,
  handleUndoBtnClick,
  handleRedoBtnClick,
  handleResetBtnClick,
  handleZoomInBtnClick,
  handleZoomOutBtnClick,
  handlePenModeClick,
  isStylusBtnVisible,
  isBackBtnVisible,
}) => {
  const itemSpacing = isMobileOnly || isTablet || isIPad13 ? 0 : 2;

  return (
    <PaperWrapper id="navigation-menu">
      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item>
          <Grid container spacing={itemSpacing}>
            {isBackBtnVisible && (
              <Grid item key="back-menu-button">
                <ResponsiveButton
                  id="back-menu-button"
                  icon={<ChevronLeftIcon />}
                  onClick={handleBackBtnClick}
                >
                  Back
                </ResponsiveButton>
              </Grid>
            )}

            {isHomeBtnVisible && (
              <Grid item key="home-menu-button">
                <ResponsiveButton
                  id="home-menu-button"
                  icon={<HomeIcon />}
                  onClick={handleHomeBtnClick}
                >
                  Home
                </ResponsiveButton>
              </Grid>
            )}

            {isTemplateBtnVisible && (
              <Grid item key="select-template-button">
                <ResponsiveButton
                  id="select-template-button"
                  onClick={handleTemplateBtnClick}
                  icon={<InsertPhotoIcon />}
                >
                  Select Template
                </ResponsiveButton>
              </Grid>
            )}

            <Grid item key="undo-canvas">
              <ResponsiveButton
                id="undo-canvas"
                onClick={handleUndoBtnClick}
                icon={<UndoIcon />}
              >
                Undo
              </ResponsiveButton>
            </Grid>

            <Grid item key="redo-canvas">
              <ResponsiveButton
                id="redo-canvas"
                onClick={handleRedoBtnClick}
                icon={<RedoIcon />}
              >
                Redo
              </ResponsiveButton>
            </Grid>

            <Grid item key="reset-canvas">
              <ResponsiveButton
                id="reset-canvas"
                onClick={handleResetBtnClick}
                icon={<AspectRatioIcon />}
              >
                Reset
              </ResponsiveButton>
            </Grid>

            <Grid item key="zoom-in">
              <ResponsiveButton
                id="zoom-in"
                onClick={handleZoomInBtnClick}
                icon={<ZoomInIcon />}
              >
                Zoom In
              </ResponsiveButton>
            </Grid>

            <Grid item key="zoom-out">
              <ResponsiveButton
                id="zoom-out"
                onClick={handleZoomOutBtnClick}
                icon={<ZoomOutIcon />}
              >
                Zoom Out
              </ResponsiveButton>
            </Grid>

            {isStylusBtnVisible && (
              <Tooltip title="Stylus mode" placement="right">
                <Grid item key="pen-mode">
                  <ResponsiveButton
                    id="stylus-mode"
                    icon={
                      isStylusEnable ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    }
                    onClick={handlePenModeClick}
                  >
                    Stylus
                  </ResponsiveButton>
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={itemSpacing}>
            <Grid item>
              <ResponsiveButton
                id="clear-canvas"
                icon={<DeleteIcon />}
                onClick={handleDiscardBtnClick}
              >
                Discard
              </ResponsiveButton>
            </Grid>

            <Grid item>
              <ResponsiveButton
                onClick={handleSaveBtnClick}
                icon={<SaveIcon />}
                variant="outlined"
                color="primary"
              >
                Save
              </ResponsiveButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};

export default NavigationMenu;

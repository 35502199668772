import React, { Component } from 'react';

import { isMobileOnly } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ValueLabel from '@material-ui/core/Slider/ValueLabel';
import Slider from '@material-ui/core/Slider';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import ListIcon from '@material-ui/icons/List';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './styles';

export const newPredefinedSelection = [
  {
    key: 'Pain/VAS',
    type: 'scale',
    value: [0, 10],
  },
  {
    key: 'Symptoms',
    type: 'text',
    value: [
      'Dull ache',
      'Deep',
      'Superficial',
      'Pins and needles',
      'Numbesss',
      'No pins and needles',
      'No numbness',
      'Sharp',
      'Stabbing',
      'Constant',
      'Burning',
      'Hot',
      'Cold',
      'Shooting',
      'Throbbing',
      'Irritable',
      'Not irritable',
      'Weakness',
    ],
  },
  {
    key: 'Regional Questions',
    type: 'text',
    value: [
      'Unstable',
      'Stiff',
      'Clicking',
      'No Clicking',
      'Grinding',
      'Locking',
      'No Locking',
      'Give way',
      'No giving way',
      'Diplopia',
      'Dizziness',
      'Drop attacks',
      'Dysarthria',
      'Dysphagia',
      'Ataxia of gait',
      'Nausea',
      'Numbness',
      'Nystagmus',
    ],
  },
  {
    key: 'General Questions',
    type: 'text',
    value: [
      'Pain with cough',
      'Pain with sneeze',
      'Pain toileting',
      'Incontinent',
      'Pain on deep breath',
    ],
  },
];

const StyledValueLabel = withStyles({
  offset: {
    top: -20,
    left: props => 'calc(-50% + -35px)',
  },
  circle: {
    transform: props => 'rotate(-90deg)',
  },
  label: {
    transform: props => 'rotate(90deg)',
  },
})(ValueLabel);

const SliderStyle = withStyles({
  root: {
    flex: 1,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0px 2px 2px',
    '&:focus,&:hover,&$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    },
  },
  active: {},
  valueLabel: {
    marginLeft: 13,
  },
  track: {},
  rail: {},
})(Slider);

const SliderWrapperStyle = withStyles({
  root: {
    overflow: 'visible',
    padding: '15px',
    [isMobileOnly ? 'height' : 'width']: '300px',
  },
})(Paper);

const MenuItemStyle = withStyles({
  selected: {
    fontWeight: 'bold',
  },
})(MenuItem);

const SubMenu = ({ item, selectedItemsData, onMenuItemClick }) => {
  return (
    <Paper>
      <MenuList>
        {item.value.map(name => {
          const selected =
            selectedItemsData.filter(
              e => e.key === item.key && e.value.includes(name)
            ).length > 0;
          return (
            <MenuItemStyle
              key={name}
              selected={selected}
              onClick={() =>
                onMenuItemClick({
                  key: item.key,
                  type: item.type,
                  name,
                })
              }
            >
              {selected && <CheckIcon />} {name}
            </MenuItemStyle>
          );
        })}
      </MenuList>
    </Paper>
  );
};

class VASSlider extends Component {
  handleSliderChange = (e, value) => {
    const { item, onSliderChange } = this.props;
    const maxValue = item.value[1];
    onSliderChange({
      ...item,
      maxValue,
      value,
    });
  };

  render() {
    const { item, selectedItemsData } = this.props;
    const selected = selectedItemsData.find(s => s.key === item.key);
    const selectedValue = selected ? selected.value : [0, 0];
    const min = item.value[0];
    const max = item.value[1];
    const step = 1;
    const marks = Array.from({ length: max - min + step }, (_, i) => ({
      value: i,
      label: i,
    }));
    return (
      <SliderWrapperStyle>
        <SliderStyle
          orientation={isMobileOnly ? 'vertical' : 'horizontal'}
          defaultValue={selectedValue}
          min={min}
          max={max}
          marks={marks}
          valueLabelDisplay="on"
          onChangeCommitted={this.handleSliderChange}
          {...(isMobileOnly && {
            ValueLabelComponent: StyledValueLabel,
          })}
        />
      </SliderWrapperStyle>
    );
  }
}

class MenuItemWithPopOver extends Component {
  state = {
    anchorEl: null,
  };

  handleAnchorClick = e => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { name, children } = this.props;
    const { anchorEl } = this.state;

    return (
      <ClickAwayListener onClickAway={this.handlePopoverClose}>
        <div>
          <MenuItem onClick={this.handleAnchorClick}>
            {name} <ArrowRightIcon />
          </MenuItem>
          <Popper
            anchorEl={anchorEl}
            placement="right-start"
            open={Boolean(anchorEl)}
            onClose={this.handlePopoverClose}
            disablePortal
          >
            {children}
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
}

export default class SymptomsMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleAnchorClick = e => {
    const { anchorEl } = this.state;

    if (!anchorEl) {
      this.setState({ anchorEl: e.currentTarget });
    } else {
      this.setState({ anchorEl: null });
    }
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      defaultMenuData,
      selectedItemsData,
      onSymptomClick,
      onUserPreferenceClick,
      onVASSliderChange,
      userPreferences,
      isInConsentForm,
    } = this.props;

    const { anchorEl } = this.state;

    let menuData = [];
    const defaultPreferences = userPreferences
      ? userPreferences.filter(e => e.isDefaultPreference === 1)
      : [];

    if (defaultPreferences.length > 0) {
      const defaultPreference = defaultPreferences[0];
      if (defaultPreference.settings) {
        const settings = JSON.parse(defaultPreference.settings);
        menuData = settings.data;
      }
    } else {
      menuData = defaultMenuData;
    }

    const dropdowns = menuData.map(item => {
      if (item.type === 'scale') {
        return (
          <MenuItemWithPopOver key={item.key} name={item.key}>
            <VASSlider
              item={item}
              selectedItemsData={selectedItemsData}
              onSliderChange={onVASSliderChange}
            />
          </MenuItemWithPopOver>
        );
      }
      return (
        <MenuItemWithPopOver key={item.key} name={item.key}>
          <SubMenu
            item={item}
            selectedItemsData={selectedItemsData}
            onMenuItemClick={onSymptomClick}
          />
        </MenuItemWithPopOver>
      );
    });
    if (!isInConsentForm) {
      dropdowns.push(
        <MenuItem key="preferences" onClick={onUserPreferenceClick}>
          Edit Preferences
        </MenuItem>
      );
    }

    return (
      <ClickAwayListener onClickAway={this.handleMenuClose}>
        <div id="symptoms-menu" style={styles.preselectionsMenu}>
          <Tooltip title="Quick responses" placement="right">
            <Button
              onClick={this.handleAnchorClick}
              variant="outlined"
              size={isMobileOnly ? 'small' : 'medium'}
            >
              <ListIcon fontSize={isMobileOnly ? 'medium' : 'large'} />
            </Button>
          </Tooltip>

          <Popper
            anchorEl={anchorEl}
            placement="bottom-start"
            open={Boolean(anchorEl)}
            disablePortal
          >
            <Paper>
              <MenuList>{dropdowns}</MenuList>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useMutation } from '@apollo/client';

import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  DialogActions,
  Button,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { UPSERT_TAX_RATE } from './gql';

const TaxRateDialog = ({
  classes,
  currentLocationId,
  form,
  handleClose,
  practiceEncodedId,
  setForm,
  showDialog,
}) => {
  const [upsertTaxRate, { loading }] = useMutation(UPSERT_TAX_RATE, {
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: ['listTaxRates'],
  });
  const [showErrors, setShowErrors] = useState(false);
  const isSubmitDisabled = !form.name || !form.ratePercent;

  const handleFieldChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSave = e => {
    e.preventDefault();
    if (!isSubmitDisabled) {
      upsertTaxRate({
        onCompleted: res => {
          if (res.upsertTaxRate) {
            handleClose();
          }
        },
        variables: {
          ...form,
          rate: parseFloat(form.ratePercent) / 100,
          locationId: currentLocationId,
          practiceEncodedId,
        },
      });
    } else {
      setShowErrors(true);
    }
  };

  return (
    <Dialog fullWidth open={showDialog} onClose={handleClose}>
      <DialogTitle>
        {form.id ? `Update ${form.name} Rate` : 'Create Tax Rate'}
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <FormControl onSubmit={handleSave}>
          <TextField
            className={classes.field}
            error={showErrors && !form.name}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Rate Name (Mandatory)"
            margin="normal"
            name="name"
            autoComplete="off"
            onChange={handleFieldChange}
            type="text"
            value={form.name || ''}
          />
          <TextField
            className={classes.field}
            error={showErrors && !form.ratePercent}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            label="Rate % (Mandatory)"
            margin="normal"
            autoComplete="off"
            name="ratePercent"
            onChange={handleFieldChange}
            type="number"
            defaultValue={form.ratePercent || ''}
          />
          <TextField
            className={classes.field}
            error={showErrors && !form.name}
            autoComplete="off"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Description (Internal Use Only)"
            margin="normal"
            name="desc"
            onChange={handleFieldChange}
            type="text"
            value={form.desc || ''}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={handleSave}>Save</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    padding: 32,
    margin: '0px !important',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  subheading: {
    fontWeight: 'bold',
    paddingTop: 36,
    paddingBottom: 8,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    currentLocationId: user.currentLocationId,
    practiceEncodedId: practice.id,
  }))
)(TaxRateDialog);

import { gql } from '@apollo/client';

export const LIST_INVOICES = gql`
  query fetchInvoice(
    $encodedPatientId: ID
    $id: ID
    $locationId: ID
    $statuses: [InvoiceStatus]
    $startDate: Date
    $endDate: Date
    $offset: Int
  ) {
    fetchInvoice(
      encodedPatientId: $encodedPatientId
      id: $id
      offset: $offset
      locationId: $locationId
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      amount
      balanceDue
      comments
      createdAt
      dateDue
      invoiceDate
      invoiceNumber
      notes
      locationId
      status
      totalTax
      invoiceAccount {
        patientId
        id
        status
        billingName
      }
      invoiceItem {
        id
        clinicianName
        comments
        quantity
        price
        createdAt
        date
        taxes
      }
      invoicePayment {
        id
        dateReceived
        cash
        cheque
        creditCard
        deposit
        eftpos
        giftCard
        healthFund
        medicare
        comments
        subtotal
        paidVia
        userName
      }
      patient {
        id
        name
        email
        mobile
        phone
        dob
        invoiceAccounts {
          id
          billingName
        }
      }
    }
  }
`;

export const UPSERT_INVOICE = gql`
  mutation upsertInvoice(
    $comments: String
    $dateDue: Date
    $encodedPatientId: ID!
    $id: ID
    $invoiceAccountId: ID
    $invoiceDate: DateTime
    $invoiceItemIds: [ID]
    $locationId: ID!
    $notes: String
    $practiceId: ID!
    $status: InvoiceStatus
  ) {
    upsertInvoice(
      comments: $comments
      dateDue: $dateDue
      encodedPatientId: $encodedPatientId
      id: $id
      invoiceAccountId: $invoiceAccountId
      invoiceDate: $invoiceDate
      invoiceItemIds: $invoiceItemIds
      locationId: $locationId
      notes: $notes
      practiceId: $practiceId
      status: $status
    ) {
      id
      dateDue
      status
    }
  }
`;

export const DELETE_INVOICE_ITEM = gql`
  mutation deleteInvoiceItem($id: ID!) {
    deleteInvoiceItem(id: $id) {
      id
      comments
      price
      quantity
      createdAt
    }
  }
`;

export const UPSERT_INVOICE_ITEM = gql`
  mutation upsertInvoiceItem(
    $id: ID
    $code: String
    $comments: String
    $encodedPatientId: ID
    $inventoryItemId: ID
    $invoiceId: ID
    $locationId: ID!
    $practiceId: ID!
    $price: Float
    $quantity: Float
  ) {
    upsertInvoiceItem(
      id: $id
      code: $code
      comments: $comments
      encodedPatientId: $encodedPatientId
      inventoryItemId: $inventoryItemId
      invoiceId: $invoiceId
      locationId: $locationId
      practiceId: $practiceId
      price: $price
      quantity: $quantity
    ) {
      id
      comments
      price
      quantity
      createdAt
    }
  }
`;

export const UPSERT_INVOICE_PAYMENT = gql`
  mutation upsertInvoicePayment(
    $id: ID
    $dateReceived: Date
    $invoiceId: ID
    $practiceEncodedId: ID!
    $comments: String
    $cash: Float
    $cheque: Float
    $creditCard: Float
    $deposit: Float
    $eftpos: Float
    $giftCard: Float
    $healthFund: Float
    $medicare: Float
  ) {
    upsertInvoicePayment(
      id: $id
      dateReceived: $dateReceived
      invoiceId: $invoiceId
      practiceEncodedId: $practiceEncodedId
      comments: $comments
      cash: $cash
      cheque: $cheque
      creditCard: $creditCard
      deposit: $deposit
      eftpos: $eftpos
      giftCard: $giftCard
      healthFund: $healthFund
      medicare: $medicare
    ) {
      id
      cash
      cheque
      creditCard
      deposit
      eftpos
      giftCard
      healthFund
      medicare
      dateReceived
    }
  }
`;

export const REQUEST_PDF_EXPORT = gql`
  mutation requestPdfExport(
    $emailPdf: Boolean
    $invoiceDate: DateTime
    $invoiceId: ID!
    $isReceipt: Boolean
  ) {
    requestPdfExport(
      emailPdf: $emailPdf
      invoiceDate: $invoiceDate
      invoiceId: $invoiceId
      isReceipt: $isReceipt
    ) {
      file
      filename
      url
      emailResponse
    }
  }
`;

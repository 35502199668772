/*
 * Service for managing third party integrations
 */

import Logger from './Logger';
import ServiceBase from './ServiceBase';

export class IntegrationService {
    /**
     * Start Mindbody integration
     */
    static SendActivationEmail = async (integrationId: string): Promise<boolean> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/${integrationId}/generateActivationCode`, null);

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] SendActivationEmail Failed ${JSON.stringify(request)}`);
                return false;
            }

            return true;
        } catch (error) {
            Logger.LogException('[ERROR] SendActivationEmail Failed -> ', error);
            return false;
        }
    };

    static CreateIntegration = async (siteId: string, emailAddress: string, practiceId: string, locationId: string): Promise<any> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/createIntegration`, {
                siteId,
                emailAddress,
                practiceId,
                locationId,
            });

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] CreateIntegration Failed ${JSON.stringify(request)}`);
                return null;
            }

            return request.json();
        } catch (error) {
            Logger.LogException('[ERROR] CreateIntegration Failed -> ', error);
            return null;
        }
    };

    static CompleteMindbodyIntegration = async (integrationId: string): Promise<any> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/completeIntegration`, { integrationId });

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] CompleteMindbodyIntegration Failed ${JSON.stringify(request)}`);
                return null;
            }

            return request.json();
        } catch (error) {
            Logger.LogException('[ERROR] CompleteMindbodyIntegration -> ', error);
            return null;
        }
    };

    static RemoveMindbodyIntegration = async (integrationId: string): Promise<boolean> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/removeIntegration`, { integrationId });

            if (request.status !== 200) {
                Logger.LogError(`"[ERROR] RemoveMindbodyIntegration failed ${JSON.stringify(request)}`);
                return false;
            }

            return true;
        } catch (error) {
            Logger.LogException('[ERROR] RemoveMindbodyIntegration -> ', error);
            return false;
        }
    };

    static InitiateSync = async (integrationId: string, locationId: number): Promise<any> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/initiateSync`, {
                integrationId,
                locationId,
            });

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] InitiateSync Failed ${JSON.stringify(request)}`);
            }
        } catch (error) {
            Logger.LogException('[ERROR] InitiateSync -> ', error);
        }
    };

    static InitiateSyncForStaff = async (practiceId: string, staffId: string): Promise<any> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/initiateStaffAppointmentSync`, {
                practiceId,
                staffId,
            });

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] InitiateSyncForStaff Failed ${JSON.stringify(request)}`);
            }
        } catch (error) {
            Logger.LogException('[ERROR] InitiateSyncForStaff -> ', error);
        }
    };

    static GetAvailableLocations = async (siteId: string): Promise<any> => {
        try {
            const request = await ServiceBase.GetRequest(`mindbody/${siteId}/availableLocations`);

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] GetAvailableLocations Failed`);
            }

            return request.json();
        } catch (error) {
            Logger.LogException(`[ERROR] GetAvailableLocations ->`, error);
            return null;
        }
    };

    static ReSyncInvoices = async (id: string): Promise<boolean> => {
        try {
            const request = await ServiceBase.PostRequest(`mindbody/resyncInvoices/${id}`, null);

            if (request.status !== 200) {
                Logger.LogError(`[ERROR] ReSyncInvoices Failed ${JSON.stringify(request)}`);
            }

            return true;
        } catch (error) {
            Logger.LogException('[ERROR] ReSyncInvoices ->', error);
            return false;
        }
    };

    static objectIsLocked = (item: any): boolean => {
        if (!item.integrationMetadata) return false;
        const parsedMetadata = JSON.parse(item.integrationMetadata);
        if (!parsedMetadata) return false;
        if (!parsedMetadata.Locked) return false;
        if (parsedMetadata.Locked === true) return true;
        return false;
    };

    static hasIntegrationMetadata = (item: any): boolean => {
        return item.integrationMetadata !== null;
    };
}

export default IntegrationService;

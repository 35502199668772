import React, {useState} from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import { PRIMARY } from '../../style/constants';

const OutOfBoxExperiencePage = () => {
    const [clinikNoteClasses, setClinikNoteClasses] = useState("oobeOption oobeOptionButton fadeIn");
    const [mindbodyClasses, setMindbodyClasses] = useState("oobeOption oobeOptionButton fadeIn");
    const [mindbodyContentClasses, setMindbodyContentClasses] = useState("");

    const integrateMindbody = () => {
        setClinikNoteClasses("oobeOption fadeOut")
        setMindbodyContentClasses("fadeOut");
        setMindbodyClasses("oobeOption grow");
    }

    return (
    <div className="oobe">
      <div className="oobeWelcomeContainer">
        <h1 className="oobeWelcome fadeInAndOut">Welcome to ClinikNote</h1>
      </div>
      <div className="oobeSelectPathwayContainer">
        <div className={clinikNoteClasses}>
            <img src="https://cliniknote.com/wp-content/uploads/2018/08/clinik-note-logo-1.png" alt="ClinikNote" height="100px" />
            <br /><br/><br /><br/>
            <h2>I would like to set up my own practice</h2>
        </div>
        <div className={mindbodyClasses} onClick={integrateMindbody}>
            <img className={mindbodyContentClasses} src="https://www.mindbodyonline.com/themes/mindbody/dist/patterns/images/global/mindbody-logos/business.svg" alt="Mindbody" height="100px" />
            <br /><br/><br /><br/>
            <h2 className={mindbodyContentClasses}>I would like to integrate with Mindbody</h2>
        </div>
      </div>
    </div>
  );
};

const styles = {
  main: {
    minHeight: '100%',
    backgroundColor: PRIMARY,
  },
};

export default compose(withStyles(styles))(OutOfBoxExperiencePage);

import {
  compose,
  withHandlers,
  withProps,
  withState,
  lifecycle,
} from 'recompose';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  subscriptionShowLoading,
  subscriptionSetOptions,
  subscriptionSelectPlan,
  subscriptionSelectUsers,
  subscriptionErrorMessage,
  subscriptionShowCreditCard,
} from '../../data/redux/actions/subscription';

const stripePlanQuery = gql`
  query stripeSubscriptionList($practiceId: ID!) {
    node(id: $practiceId) {
      ... on Practice {
        id
        users {
          name
          roles
        }
        stripeSubscription {
          customerToken
          planToken
          numberOfUsers
          cardLast4
          status
          expiry
          renewDate
        }
        stripePlans {
          id
          title
          description
          price
          stripePlanToken
        }
        stripePublicKey
        isSubscriptionValid
        isConsentFormSubscription
      }
    }
  }
`;

const stripeChangeCreditCardMutation = gql`
  mutation addCreditCard(
    $practiceId: ID!
    $customerToken: String
    $cardSource: String
    $cardLast4: String
  ) {
    addCreditCard(
      practiceId: $practiceId
      customerToken: $customerToken
      cardSource: $cardSource
      cardLast4: $cardLast4
    ) {
      stripeSubscription {
        customerToken
        planToken
        numberOfUsers
        cardLast4
        status
        expiry
      }
    }
  }
`;

const stripeSubscribeMutation = gql`
  mutation subscribeToPlan(
    $practiceId: ID!
    $planToken: String
    $numberOfUsers: Int
  ) {
    subscribeToPlan(
      practiceId: $practiceId
      planToken: $planToken
      numberOfUsers: $numberOfUsers
    ) {
      stripeSubscription {
        customerToken
        planToken
        numberOfUsers
        cardLast4
        status
        expiry
      }
    }
  }
`;

const stripeCancelMutation = gql`
  mutation cancelPlan($practiceId: ID!) {
    cancelPlan(practiceId: $practiceId) {
      stripeSubscription {
        customerToken
        planToken
        numberOfUsers
        cardLast4
        status
        expiry
      }
    }
  }
`;

const PLAN_CHANGED_NOTE =
  'Plan changed. Please re-login for this to take effect';

const onCardChange = props => async parameters => {
  const { stripeChangeCreditCardMutate, practiceId, dispatch } = props;
  const { stripe, customerToken, cardElement } = parameters;

  dispatch(subscriptionErrorMessage(''));

  // get stripe token
  const stripeResult = await stripe.createToken({ name: 'Name' });

  if (!stripeResult || !stripeResult.token) return;

  const variables = {
    practiceId,
    customerToken,
    cardSource: stripeResult.token.id,
    cardToken: stripeResult.token.card.id,
    cardLast4: stripeResult.token.card.last4,
  };

  dispatch(subscriptionShowLoading(true));

  // save credit card to practice
  try {
    stripeChangeCreditCardMutate({
      variables,
      refetchQueries: () => [
        { query: stripePlanQuery, variables: { practiceId } },
      ],
    })
      .then(mutationResult => {
        // clear card element if all good
        cardElement.clear();

        // otherwise show last 4 digits of new card number
        dispatch(
          subscriptionSetOptions(props.subscription.plans, {
            cardLast4:
              mutationResult.data.addCreditCard.stripeSubscription.cardLast4,
          })
        );

        dispatch(subscriptionShowCreditCard(false));
        dispatch(subscriptionShowLoading(false));
      })
      .catch(error1 => {
        dispatch(subscriptionErrorMessage(error1));
        dispatch(subscriptionShowLoading(false));
      });
  } catch (error2) {
    dispatch(subscriptionErrorMessage(error2));
    dispatch(subscriptionShowLoading(false));
  }
};

const onSubscribePlan = props => async () => {
  const { stripeSubscribeMutate, practiceId, dispatch, subscription } = props;
  dispatch(subscriptionErrorMessage(''));
  if (!subscription.selectedPlan) return;

  const variables = {
    practiceId,
    planToken: subscription.selectedPlan,
    numberOfUsers: subscription.selectedNumberOfUsers,
  };

  dispatch(subscriptionShowLoading(true));

  try {
    stripeSubscribeMutate({
      variables,
      refetchQueries: () => [
        { query: stripePlanQuery, variables: { practiceId } },
      ],
    })
      .then(mutationResult => {
        const subscriptionResult = get(
          mutationResult,
          'data.subscribeToPlan.stripeSubscription',
          null
        );
        const newNumberOfusers = subscriptionResult
          ? subscriptionResult.numberOfUsers
          : subscription.numberOfUsers;

        // on success update current plan
        dispatch(
          subscriptionSetOptions(subscription.plans, {
            planToken: subscription.selectedPlan,
            numberOfUsers: newNumberOfusers,
            renewDate: subscription.renewDate,
          })
        );
        dispatch(subscriptionShowLoading(false));
        dispatch(subscriptionErrorMessage(PLAN_CHANGED_NOTE));
      })
      .catch(error1 => {
        dispatch(subscriptionErrorMessage(error1));
        dispatch(subscriptionShowLoading(false));
      });
  } catch (error2) {
    dispatch(subscriptionErrorMessage(error2));
    dispatch(subscriptionShowLoading(false));
  }
};

const onCancelPlan = props => async () => {
  const { stripeCancelMutate, practiceId, dispatch, subscription } = props;
  dispatch(subscriptionErrorMessage(''));

  if (!subscription.planToken) return;

  const variables = { practiceId };

  dispatch(subscriptionShowLoading(true));

  try {
    stripeCancelMutate({
      variables,
      refetchQueries: () => [
        { query: stripePlanQuery, variables: { practiceId } },
      ],
    })
      .then(mutationResult => {
        const subscriptionResult = get(
          mutationResult,
          'data.cancelPlan.stripeSubscription',
          null
        );
        const newNumberOfusers = subscriptionResult
          ? subscriptionResult.numberOfUsers
          : subscription.numberOfUsers;

        // on success update current plan
        dispatch(
          subscriptionSetOptions(subscription.plans, {
            planToken: '',
            numberOfUsers: newNumberOfusers,
            expiry: subscriptionResult.expiry,
            status: subscriptionResult.status,
          })
        );
        dispatch(subscriptionShowLoading(false));
        dispatch(subscriptionErrorMessage(PLAN_CHANGED_NOTE));
      })
      .catch(error1 => {
        dispatch(subscriptionErrorMessage(error1));
        dispatch(subscriptionShowLoading(false));
      });
  } catch (error2) {
    dispatch(subscriptionErrorMessage(error2));
    dispatch(subscriptionShowLoading(false));
  }
};

const confirmDialogState = {
  show: false,
  message: '',
  action: null,
  actionParameters: {},
};

const SubscriptionPageContainer = compose(
  connect(props => ({
    isAdmin: props.user.roles.includes('ADMIN'),
    subscription: props.subscription,
    practiceId: props.practice.id,
    basePlan:
      props.subscription && props.subscription.plans
        ? props.subscription.plans.filter(
            plan => plan.title.toUpperCase().indexOf('BASE') >= 0
          )[0]
        : {},
    userPlan:
      props.subscription && props.subscription.plans
        ? props.subscription.plans.filter(
            plan => plan.title.toUpperCase().indexOf('USER') >= 0
          )[0]
        : {},
    consentFormPlan:
      props.subscription && props.subscription.plans
        ? props.subscription.plans.filter(
            plan => plan.title.toUpperCase().indexOf('CONSENT') >= 0
          )[0]
        : {},
  })),
  withState('confirmDialog', 'updateConfirmDialog', confirmDialogState),
  graphql(stripePlanQuery, {
    name: 'query1',
    options: ({ practiceId }) => ({
      variables: { practiceId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(stripeChangeCreditCardMutation, {
    name: 'stripeChangeCreditCardMutate',
  }),
  graphql(stripeSubscribeMutation, {
    name: 'stripeSubscribeMutate',
  }),
  graphql(stripeCancelMutation, {
    name: 'stripeCancelMutate',
  }),
  withProps(({ query1, basePlan, consentFormPlan }) => ({
    error: query1.error,
    status: {
      loading: query1.networkStatus === 1,
      success: query1.networkStatus === 7 && Boolean(query1.viewer),
      error: query1.networkStatus === 8,
    },
  })),
  withHandlers({
    onSelectPlan: ({ dispatch, consentFormPlan }) => event => {
      if (
        consentFormPlan &&
        event.target.value === consentFormPlan.stripePlanToken
      ) {
        // consent form plan always have 2 users. 1 Admin and 1 Receptionist
        dispatch(subscriptionSelectUsers(2));
      }
      dispatch(subscriptionSelectPlan(event.target.value));
    },
    onChangeNumberOfUsers: ({ dispatch }) => selectedNumberOfUsers => {
      dispatch(subscriptionSelectUsers(selectedNumberOfUsers));
    },
    showCreditCard: ({ dispatch }) => showCC => {
      dispatch(subscriptionShowCreditCard(showCC));
    },
    onCardChange,
    onSubscribePlan,
    onCancelPlan,
  }),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate(prevProps) {
      const { dispatch, query1 } = this.props;
      const { node, loading } = query1;

      if (node) {
        const {
          stripePlans,
          stripeSubscription,
          stripePublicKey,
          users,
          isSubscriptionValid,
          isConsentFormSubscription,
        } = node;

        const noStripeInfo = {
          customerToken: '',
          planToken: '',
          numberOfUsers: 1,
          cardLast4: '',
          status: 0,
          expiry: null,
          error: null,
          // stripePublicKey, isSubscriptionValid and users, must *always* be obtained from the API
          stripePublicKey,
          isSubscriptionValid,
          isConsentFormSubscription,
          users,
        };

        if (prevProps.query1.loading && !loading) {
          let subscriptionData = null;
          if (stripeSubscription)
            subscriptionData = {
              ...stripeSubscription,
              stripePublicKey,
              isSubscriptionValid,
              isConsentFormSubscription,
              users,
            };

          dispatch(
            subscriptionSetOptions(
              stripePlans,
              subscriptionData || noStripeInfo
            )
          );

          if (stripeSubscription && stripeSubscription.planToken) {
            dispatch(subscriptionSelectPlan(stripeSubscription.planToken));
          } else {
            dispatch(
              subscriptionSelectPlan(get(stripePlans, ['0', 'stripePlanToken']))
            );
          }

          const showNumberOfUsers =
            stripeSubscription && stripeSubscription.numberOfUsers
              ? stripeSubscription.numberOfUsers
              : 1;
          dispatch(subscriptionSelectUsers(showNumberOfUsers));

          dispatch(subscriptionShowLoading(false));
        }
      }
    },
    componentWillUnmount() {},
  })
);

export default SubscriptionPageContainer;

import { branch, compose, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';
import { BLANK_TEMPLATE_ID } from '../TemplateList/templateListContainer';

export const drawingNoteQuery = gql`
    query drawingNote($noteId: ID!) {
        node(id: $noteId) {
            __typename
            id
            ... on DrawingNote {
                episodes {
                    id
                    name
                    startDate
                    endDate
                    __typename
                }
                categories {
                    id
                    __typename
                }
                author {
                    id
                    name
                    __typename
                }
                createdAt
                title
                drawingData
                template {
                    pageNumber
                    id
                    file {
                        id
                        encodedFile
                        __typename
                    }
                    group {
                        id
                        __typename
                    }
                    __typename
                }
                isSignedOff
                signedOffTime
                patient {
                    id
                    name
                    __typename
                }
                __typename
            }
        }
    }
`;

const groupNotesQuery = gql`
    query groupNotes($groupId: ID!) {
        node(id: $groupId) {
            __typename
            id
            ... on Group {
                notes {
                    __typename
                    id
                    categories {
                        id
                    }
                    createdAt
                    title
                    isSignedOff
                    signedOffTime
                    patient {
                        id
                        name
                    }
                    author {
                        id
                        name
                    }
                    episodes {
                        id
                        name
                        startDate
                        endDate
                    }
                    ... on DrawingNote {
                        pageNumber
                        drawingData
                        template {
                            pageNumber
                            id
                            file {
                                id
                                encodedFile
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const singleTemplateQuery = gql`
    query template($templateId: ID!) {
        node(id: $templateId) {
            __typename
            id
            ... on Template {
                name
                file {
                    id
                    encodedFile
                    __typename
                }
                __typename
            }
        }
    }
`;

export const groupTemplateQuery = gql`
    query groupTemplates($groupId: ID!) {
        node(id: $groupId) {
            __typename
            id
            ... on Group {
                templates {
                    __typename
                    id
                    name
                    pageNumber
                    file {
                        id
                        encodedFile
                        __typename
                    }
                }
                __typename
            }
        }
    }
`;

const upsertDrawingNoteMutation = gql`
    mutation upsertDrawingNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $episodes: [ID]
        $categories: [ID]
        $title: String
        $drawingData: String
        $templateId: ID
        $deletedAt: DateTime
    ) {
        upsertDrawingNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            episodes: $episodes
            categories: $categories
            title: $title
            drawingData: $drawingData
            template: $templateId
            deletedAt: $deletedAt
        ) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
            }
        }
    }
`;

const upsertGroup = gql`
    mutation upsertGroup($practiceId: ID!) {
        upsertGroup(practice: $practiceId) {
            group {
                id
                __typename
            }
            __typename
        }
    }
`;

const upsertTemplateImage = gql`
    mutation upsertTemplate($id: ID, $practice: ID!, $name: String, $encodedFile: String, $deletedAt: DateTime, $pageNumber: Int, $groupId: ID) {
        upsertTemplate(
            id: $id
            practice: $practice
            name: $name
            encodedFile: $encodedFile
            deletedAt: $deletedAt
            pageNumber: $pageNumber
            group: $groupId
        ) {
            template {
                id
                name
                file {
                    id
                    encodedFile
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

const upsertGroupDrawingNoteMutation = gql`
    mutation upsertDrawingNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $episodes: [ID]
        $categories: [ID]
        $groupId: ID
        $title: String
        $drawingData: String
        $pageNumber: Int
        $templateId: ID
        $deletedAt: DateTime
    ) {
        upsertDrawingNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            episodes: $episodes
            categories: $categories
            group: $groupId
            title: $title
            drawingData: $drawingData
            pageNumber: $pageNumber
            template: $templateId
            deletedAt: $deletedAt
        ) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
                createdAt
                author {
                    id
                    name
                    __typename
                }
                patient {
                    id
                    name
                    __typename
                }
            }
            __typename
        }
    }
`;

export const userPreferencesListQuery = gql`
    query userPreferencesList($practiceId: ID!) {
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                userPreferences {
                    id
                    name
                    type
                    settings
                    deletedAt
                    isDefaultPreference
                }
            }
        }
    }
`;

const apiHandlers = {
    onUpsertDrawingNote:
        ({ upsertDrawingNote }) =>
        async (variablesData) => {
            return await upsertDrawingNote({
                variables: variablesData,
            });
        },
    onUpsertGroup:
        ({ upsertGroup }) =>
        async (practiceId) => {
            return await upsertGroup({
                variables: {
                    practiceId: practiceId,
                },
            });
        },
    onUpsertTemplateImage:
        ({ upsertTemplateImage }) =>
        async (variables) => {
            return await upsertTemplateImage({
                variables: variables,
            });
        },
    onUpsertGroupDrawingNote:
        ({ upsertGroupDrawingNote }) =>
        async (variablesData) => {
            return await upsertGroupDrawingNote({
                variables: variablesData,
            });
        },
};

const localHandlers = {
    saveNoteAction:
        ({
            userId,
            practiceId,
            patientId,
            episodeId,
            history,
            onUpsertDrawingNote,
            onUpsertGroupDrawingNote,
            onUpsertGroup,
            drawingNoteId,
            drawingNoteData,
        }) =>
        async (data) => {
            var searchQuery = history.location.search;
            var templateId = undefined;
            var urlEpisodeId = undefined;

            if (searchQuery.includes('&')) {
                searchQuery.split('&').forEach(function (item) {
                    if (item.includes('templateId')) {
                        templateId = item.split('=')[1];
                    }
                    if (item.includes('episodeId')) {
                        urlEpisodeId = item.split('=')[1];
                    }
                });
            }
            var varEpisodeId = episodeId !== undefined ? episodeId : urlEpisodeId;

            if (patientId === undefined) {
                patientId = history.location.pathname.split('/')[2];
            }
            if (Array.isArray(data)) {
                if (data.length === 1) {
                    // Single template or blank
                    var drawingData = data[0];
                    var selectedTemplateId = drawingData.selectedTemplateId;
                    if (selectedTemplateId !== undefined) {
                        templateId = selectedTemplateId;
                    }

                    //Upsert note
                    // for single template page
                    var variables = {
                        userId: userId,
                        patientId: patientId,
                        episodes: varEpisodeId !== undefined ? [varEpisodeId] : [],
                        categories: [],
                        title: drawingData.name,
                        drawingData: JSON.stringify(drawingData.data),
                    };

                    if (templateId !== undefined && templateId !== BLANK_TEMPLATE_ID && templateId.length > 0) {
                        variables.templateId = templateId;
                    }
                    if (drawingData.drawingNoteId || drawingNoteId) {
                        variables.noteId = drawingNoteId !== undefined ? drawingNoteId : drawingData.drawingNoteId;
                        variables.allowUpdate = true;
                    }

                    var noteResponse = await onUpsertDrawingNote(variables);
                    var noteId = noteResponse.data.upsertDrawingNote.note.id;
                    history.push({
                        pathname: `/patient/${patientId}/note/drawing/${noteId}`,
                    });
                } else if (data.length > 1) {
                    var groupResponse = await onUpsertGroup(practiceId);
                    if (groupResponse && groupResponse.data.upsertGroup.group.id !== undefined) {
                        var groupId = groupResponse.data.upsertGroup.group.id;
                        var results = data.map(async (drawingData, index) => {
                            var variables = {
                                userId: userId,
                                patientId: patientId,
                                groupId: groupId,
                                templateId: null,
                                episodes: varEpisodeId !== undefined ? [varEpisodeId] : [],
                                categories: [],
                                title: drawingData.name,
                                pageNumber: index + 1,
                                drawingData: JSON.stringify(drawingData.data),
                            };

                            if (
                                drawingNoteData &&
                                drawingNoteData.notes &&
                                drawingNoteData.notes.length > 0 &&
                                drawingNoteData.notes[index] &&
                                drawingNoteData.notes[index].id !== undefined
                            ) {
                                variables.noteId = drawingNoteData.notes[index].id;
                                variables.allowUpdate = true;
                            }

                            return await onUpsertGroupDrawingNote(variables);
                        });
                        Promise.all(results).then(() => {
                            history.push({
                                pathname: `/patient/${patientId}/note/drawings/${groupId}`,
                            });
                        });
                    } else {
                        console.log('Cannot upsert group');
                    }
                }
            }
        },
};

const WebDrawingNoteContainer = compose(
    withRouter,
    connect(({ user, patient, episode, practice }) => ({
        userId: user.id,
        patientId: patient.id,
        episodeId: episode.id,
        practiceId: practice.id,
    })),
    graphql(upsertDrawingNoteMutation, { name: 'upsertDrawingNote' }),
    graphql(upsertGroup, { name: 'upsertGroup' }),
    graphql(upsertTemplateImage, { name: 'upsertTemplateImage' }),
    graphql(upsertGroupDrawingNoteMutation, { name: 'upsertGroupDrawingNote' }),
    withHandlers(apiHandlers),
    withProps(({ location }) => {
        var props = {};

        if (location.search.includes('&')) {
            var searchArr = location.search.split('&');
            searchArr.forEach((element) => {
                if (element.includes('templateId')) {
                    props.templateId = element.split('=')[1];
                }
                if (element.includes('isGroup')) {
                    props.isGroup = element.split('=')[1];
                }
                if (element.includes('drawingNoteId')) {
                    props.drawingNoteId = element.split('=')[1];
                }
                if (element.includes('episodeId')) {
                    props.episodeId = element.split('=')[1];
                }
            });
        }

        return props;
    }),

    branch(
        ({ drawingNoteId }) => drawingNoteId,
        branch(
            ({ isGroup }) => isGroup === 'true',
            compose(
                graphql(groupNotesQuery, {
                    name: 'query',
                    options: ({ drawingNoteId }) => ({
                        variables: {
                            groupId: drawingNoteId,
                        },
                        fetchPolicy: 'cache-and-network',
                    }),
                }),
                withProps(({ query }) => ({
                    error: query.error,
                    status: {
                        loading: query.networkStatus === 1,
                        success: query.networkStatus === 7 && Boolean(query.node),
                        error: query.networkStatus === 8,
                    },
                })),
                branch(
                    ({ status }) => status.success,
                    withProps(({ query }) => ({
                        drawingNoteData: query.node,
                    }))
                )
            ),
            compose(
                graphql(drawingNoteQuery, {
                    name: 'query',
                    options: ({ drawingNoteId }) => ({
                        variables: {
                            noteId: drawingNoteId,
                        },
                        fetchPolicy: 'cache-and-network',
                    }),
                }),
                withProps(({ query }) => ({
                    error: query.error,
                    status: {
                        loading: query.networkStatus === 1,
                        success: query.networkStatus === 7 && Boolean(query.node),
                        error: query.networkStatus === 8,
                    },
                })),
                branch(
                    ({ status }) => status.success,
                    withProps(({ query }) => ({
                        drawingNoteData: query.node,
                    }))
                )
            )
        )
    ),

    branch(
        ({ templateId, drawingNoteId }) => templateId && templateId !== BLANK_TEMPLATE_ID && templateId !== drawingNoteId,
        branch(
            ({ isGroup }) => isGroup === 'true',
            compose(
                graphql(groupTemplateQuery, {
                    name: 'query',
                    options: ({ templateId }) => ({
                        variables: {
                            groupId: templateId,
                        },
                        fetchPolicy: 'cache-and-network',
                    }),
                }),
                withProps(({ query }) => ({
                    error: query.error,
                    status: {
                        loading: query.networkStatus === 1,
                        success: query.networkStatus === 7 && Boolean(query.node),
                        error: query.networkStatus === 8,
                    },
                })),
                branch(
                    ({ status }) => status.success,
                    withProps(({ query }) => ({
                        templateData: query.node,
                    }))
                )
            ),
            compose(
                graphql(singleTemplateQuery, {
                    name: 'query',
                    options: ({ templateId }) => ({
                        variables: {
                            templateId: templateId,
                        },
                        fetchPolicy: 'cache-and-network',
                    }),
                }),
                withProps(({ query }) => ({
                    error: query.error,
                    status: {
                        loading: query.networkStatus === 1,
                        success: query.networkStatus === 7 && Boolean(query.node),
                        error: query.networkStatus === 8,
                    },
                })),
                branch(
                    ({ status }) => status.success,
                    withProps(({ query }) => ({
                        templateData: query.node,
                    }))
                )
            )
        ),
        withProps(() => ({
            templateId: BLANK_TEMPLATE_ID,
        }))
    ),
    compose(
        graphql(userPreferencesListQuery, {
            name: 'query',
            options: ({ practiceId }) => ({
                variables: { practiceId },
                fetchPolicy: 'cache-and-network',
            }),
        }),
        withProps(({ query }) => ({
            error: query.error,
            status: {
                loading: query.networkStatus === 1,
                success: query.networkStatus === 7 && Boolean(query.node),
                error: query.networkStatus === 8,
            },
        })),
        branch(
            ({ status }) => status.success,
            withProps(({ query }) => ({
                listUserPreferences: query.node.userPreferences,
            }))
        )
    ),
    withHandlers(localHandlers)
);

export default WebDrawingNoteContainer;

import _ from 'lodash';
import Holidays from 'date-holidays';
import * as data from './countries.json';

export const convertName = id => {
    const found = STATES_ARRAY.find(state => state.id === id);
    if (!found) {
        return {};
    }

    const splitId = found.id.split('-');
    if (splitId[0] === splitId[1]) {
        return {
            ...found,
            stateId: null,
        };
    }

    return {
        ...found,
        stateId: splitId[1],
    };
};

const getSortingPriority = countryId => {
    if (['AU', 'NZ', 'UK', 'CA'].includes(countryId)) {
        return 1;
    }
    if (['DK', 'ZA', 'US'].includes(countryId)) {
        return 2;
    }
    return 10;
};

// From https://www.npmjs.com/package/date-holidays
// eslint-disable-next-line no-unused-vars
export const generateStates = () => {
    const holidays = new Holidays();
    const countries = holidays.getCountries();
    const results = [];

    Object.keys(countries).forEach(country => {
        const hd = new Holidays();
        const tempStates = hd.getStates(country);

        if (tempStates) {
            Object.keys(tempStates).forEach(state => {
                results.push({
                    country: countries[country],
                    countryId: country,
                    id: `${country}-${state}`,
                    name: tempStates[state],
                    priority: getSortingPriority(country),
                });
            });
        } else {
            results.push({
                country: countries[country],
                countryId: country,
                id: `${country}-${country}`,
                name: countries[country],
                priority: getSortingPriority(country),
            });
        }
    });

    return _.sortBy(results, ['priority', 'country', 'name']);
};

/**
 * This was generated by the generateStates function above
 */
export const STATES_ARRAY = data.default;

// Just a list of countries' names
export const COUNTRIES_ARRAY = _.uniq(STATES_ARRAY.map(state => state.country));

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  Paper,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import ContentCopy from '@material-ui/icons/FileCopy';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenu from '../../components/AppBarMenu';
import { featureFlags } from '../../utility';

const BookingFormDashboard = ({ classes, locationId }) => {
  const formBaseUrl = featureFlags('DEV')
    ? 'localhost:3001'
    : 'https://staging.app.cliniknote.com';

  const str =
    `<iframe 
      src=` +
    `"${formBaseUrl}/booking/${locationId}"` +
    ` 
      title="iFrame1"
      style={{
        height: '900px',
        width: '100%',
        border: 'none',
        overflow: 'hidden',
      }}
    />`;

  return (
    <AppBarMenu>
      <Grid
        component="main"
        className={classes.main}
        container
        justifyContent="center"
      >
        <Grid
          className={classes.content}
          container
          direction="column"
          wrap="nowrap"
          item
          xs={12}
          sm={11}
          md={10}
          lg={8}
          xl={6}
        >
          <Paper className={classes.contentContainer}>
            <Typography>
              Copy-paste the following link into an incognito tab to test the
              booking form:
            </Typography>

            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={`${formBaseUrl}/booking/${locationId}`}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${formBaseUrl}/booking/${locationId}`
                      );
                    }}
                    edge="end"
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <Typography>
              Copy-paste the following code in your website to embed the booking
              form into your web app:
            </Typography>
            <pre>
              <code>{str}</code>
            </pre>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={str}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      navigator.clipboard.writeText(str);
                    }}
                    edge="end"
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Paper>

          <Typography>
            Please note the embedded Booking Form below is just for demo
            purposes and is not suitable for testing.
          </Typography>
        </Grid>

        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <iframe
            src={`http://${formBaseUrl}/booking/${locationId}`}
            title="iFrame1"
            style={{
              height: '900px',
              width: '100%',
              border: 'none',
              overflow: 'hidden',
            }}
          />
        </div>
      </Grid>
    </AppBarMenu>
  );
};

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: () => window.innerWidth > 600 && '100%',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 88,
    paddingRight: 44,
  },
  content: {
    paddingTop: 56,
  },
  contentContainer: {
    padding: 32,
  },
};

export default compose(
  withStyles(styles),
  connect(({ user }) => ({
    locationId: user.currentLocationId,
    userId: user.id,
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  }))
)(BookingFormDashboard);

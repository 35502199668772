import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';

import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, SwipeableDrawer, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuDropdown from '../../../components/MenuDropdown';
import './calendarEventDetail.css';
import { WILD_SAND } from '../../../style/constants';
import SingleEventDetail from './SingleEventDetail';
import { DELETE_APPOINTMENT, UPSERT_APPOINTMENT_ARRIVAL } from './gql';
import UserEventDetail from './UserEventDetail';
import IntegrationService from '../../../services/IntegrationService';

const CalendarEventDetail = ({ classes, appointment, open, anchorEl, onClose, setModalType }) => {
    const [expanded, setExpanded] = useState(!!appointment.notes);
    const historyInstance = useHistory();
    const patientIsCheckedIn = Array.isArray(appointment.checkedIn) && appointment.checkedIn.includes(appointment.patients[0].id);
    const undoCheckIn =
        appointment.patients && appointment.patients.length === 1
            ? [
                  {
                      id: 'undo',
                      text: patientIsCheckedIn ? 'Undo Mark Arrived' : 'Mark Arrived',
                      action: () => {
                          updateAppointment({
                              variables: {
                                  ...appointment,
                                  checkedIn: patientIsCheckedIn ? [] : [appointment.patients[0].id],
                              },
                          });
                      },
                  },
              ]
            : [];

    const classDescriptionName =
        appointment.integrationMetadata !== undefined && appointment.integrationMetadata !== null
            ? appointment.integrationMetadata.classDescriptionName
            : null;

    const [updateAppointment] = useMutation(UPSERT_APPOINTMENT_ARRIVAL, {
        refetchQueries: ['listAppointments'],
    });

    const [deleteAppointment] = useMutation(DELETE_APPOINTMENT, {
        refetchQueries: ['listAppointments'],
    });

    const options = [
        { id: 'edit', text: 'Edit', action: () => setModalType('edit') },
        {
            id: 'delete',
            text: 'Cancellation',
            action: () => {
                deleteAppointment({
                    variables: {
                        id: appointment.id,
                    },
                });
                onClose();
                historyInstance.push('/diary');
            },
            requiresConfirmation: 'Delete Appointment',
        },
        ...undoCheckIn,
    ];

    return (
        <SwipeableDrawer id={open ? 'simple-popover' : undefined} anchorel={anchorEl} anchor='right' onClose={onClose} open={open} onOpen={() => {}}>
            <Grid className={classes.modal} container spacing={2}>
                {(() => {
                    if (appointment.__typename === 'Event') {
                        return <UserEventDetail event={appointment} setModalType={setModalType} />;
                    }
                    return (
                        <>
                            <Grid container item xs className={classes.title}>
                                <Typography variant='h6' component='h1'>
                                    {`${appointment.appointmentType.code} - ${
                                        classDescriptionName !== null && classDescriptionName !== undefined && classDescriptionName !== ''
                                            ? classDescriptionName
                                            : appointment.appointmentType.name
                                    }`}
                                </Typography>
                                {!IntegrationService.objectIsLocked(appointment) && <MenuDropdown options={options} />}
                            </Grid>
                            <Grid container item xs className={classes.section}>
                                <Typography variant='body2'>{Moment(appointment.start).format('dddd, Do MMMM YYYY')}</Typography>

                                <Typography variant='body2'>
                                    {`${Moment(appointment.start).format('h:mm a')}
                    -
                    ${Moment(appointment.end).format('h:mm a')}`}
                                </Typography>

                                <Typography variant='body2' className='text-grey'>
                                    {appointment.clinician && appointment.clinician.name}
                                </Typography>
                            </Grid>

                            <Divider />
                            <SingleEventDetail appointment={appointment} updateAppointment={updateAppointment} />
                        </>
                    );
                })()}
                <Divider />
            </Grid>
            {!!appointment.notes && (
                <Grid container item xs className={classes.section}>
                    <Grid container item xs>
                        <Accordion
                            className={classes.notesSection}
                            disabled={!appointment.notes}
                            expanded={expanded}
                            onChange={() => setExpanded(!expanded)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                className={classes.accordion}>
                                <Typography variant='subtitle1'>Notes</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant='body2'>{appointment.notes}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            )}
        </SwipeableDrawer>
    );
};

const styles = {
    modal: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        maxHeight: '90vh',
        // height: '100%',
        width: '100% !important',
        background: WILD_SAND,
        border: '1px solid #c7c7c7',
        boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: '0.25em',
        padding: 0,
        '& > div:first-child': {
            justifyContent: 'space-between',
        },
        '& .MuiTypography-h6': {
            fontSize: 16,
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px 0 !important',
    },
    section: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        background: WILD_SAND,
        width: 350,
        padding: '5px 20px !important',
    },
    notesSection: {
        backgroundColor: '#f4f4f4 !important',
        boxShadow: 'none !important',
    },
    accordion: {
        width: '100%',
        padding: '0 !important',
    },
};

export default compose(withStyles(styles))(CalendarEventDetail);

/**
 * Calculate Modulo as js % operator is a remainder operator which behave differently for negative number
 * @param {Number} n 
 * @param {Number} m 
 * @return {Number}
 */
export const mod = (n, m) => {
  return ((n % m) + m) % m;
}

/**
 * processTemplateData
 * @param {Object} templateData 
 */
export const processTemplateData = (templateData = {}) => {
  const result = [];

  if (templateData.__typename === 'Group') {
    templateData.templates.forEach(item => {
      result.push({
        fileId: item.id,
        name: item.name,
        encodedFile: item.file.encodedFile,
      });
    });
  } else {
    result.push({
      fileId: templateData.file.id,
      name: templateData.name,
      encodedFile: templateData.file.encodedFile,
    });
  }

  return result;
};

/**
 * 
 * @param {Object} item 
 */
const processCanvasItem = ({id, title, drawingData, template}) => {
  return {
    id,
    title,
    name: title,
    drawingData,
    template,
  };
};

/**
 * 
 * @param {Object} drawingNoteData 
 */
export const processDrawingNoteData = (drawingNoteData = {}) => {
  const result = [];
  const { __typename } = drawingNoteData;

  if (__typename === 'Group') {
    drawingNoteData.notes.forEach(item => {
      result.push(processCanvasItem(item));
    });
  } else {
    result.push(processCanvasItem(drawingNoteData));
  }

  return result;
};

/**
 * processFormNoteSection
 * @param {Object} formNoteSection 
 */
export const processFormNoteSection = (formNoteSection = {}) => {
  const result = [];

  result.push(processCanvasItem(formNoteSection));

  return result;
};

/**
 * processFormNoteGroupedSections
 * @param {Object} formNoteGroupedSections 
 */
export const processFormNoteGroupedSections = (
  formNoteGroupedSections = {}
) => {
  const result = [];

  formNoteGroupedSections.forEach(item => {
    result.push(processCanvasItem(item));
  });

  return result;
};

/**
 * Get Canvas dimension
 */
export const getCanvasDimension = () => {
  const wrapperBounding = document.getElementById('canvasWrapper').getBoundingClientRect();
  const { width } = wrapperBounding
  const height =
    (window.innerHeight > 0 ? window.innerHeight : window.screen.height) * 3 / 4;

  return { width, height };
};

/**
 * Calculate distance between 2 points
 */
export const calDistanceBetweenPositions = (pos2, pos1) => {
  if (!pos1 || !pos2) {
    return 0;
  }
  
  if (pos2 instanceof MouseEvent && pos1 instanceof MouseEvent) {
    return Math.sqrt(
      (Math.abs(pos2.clientX - pos1.clientX) ** 2)
      + 
      (Math.abs(pos2.clientY - pos1.clientY) ** 2)
    );
  }

  if (pos1 instanceof TouchEvent && pos2 instanceof TouchEvent) {
    // we don't support more than 2 touches at the moment
    if ( (!pos1.touches || !pos2.touches) && (pos1.touches.length > 1 || pos2.touches.length > 1)) {
      return NaN;
    }

    return Math.sqrt(
      (Math.abs(pos2.touches[0].clientX - pos1.touches[0].clientX) ** 2) 
      + 
      (Math.abs(pos2.touches[0].clientY - pos1.touches[0].clientY) ** 2)
    );
    
  }

  return 0;
}
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Button,
  ButtonBase,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

import Moment from 'moment';

import CreatePatient from './CreatePatient';
// CZ: Commented out because these css styles don't run on production
// import './calendarDialog.css';
import Loading from '../../../components/Loading';
import { WARM_PINK } from '../../../style/constants';
import {
  PATIENT_LIST_QUERY,
  UPSERT_APPOINTMENT,
  UPSERT_APPT_PLUS_PATIENT,
} from './gql';

const CalendarDialog = ({
  classes,
  existingAppt,
  locationId,
  onCloseDialog,
  practiceId,
  practiceEncodedId,
  clinicianOptions,
  selectedDay,
  selectedClinicianId,
  showDialog,
}) => {
  const [invalidInput, setInvalidInput] = useState(true);
  const [appointment, setAppointment] = useState({
    sendNotification: true,
  });
  const [newPatients, setNewPatients] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(PATIENT_LIST_QUERY, {
    variables: {
      locationId,
      practiceId,
      offset: 0,
      includeArchived: true,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const patientNodes = _.get(res, 'node.patients.nodes');
      setPatientList(patientNodes);
      const types = _.get(res, 'listAppointmentTypes');
      setAppointmentTypes(types);
    },
  });

  const [upsertAppt, { upsertApptData, upsertApptLoading, upsertApptError }] =
    useMutation(UPSERT_APPOINTMENT, {
      onCompleted: () => {
        onCloseDialog();
      },
      refetchQueries: ['listAppointments'],
    });

  const [upsertApptPlusPatient, { data, loading, error }] = useMutation(
    UPSERT_APPT_PLUS_PATIENT,
    {
      onCompleted: () => {
        onCloseDialog();
      },
      refetchQueries: ['listAppointments'],
    }
  );

  const CustomDropdown = (props) => (
    <Popper
      {...props}
      style={{ width: 'fit-content' }}
      placement="bottom-start"
    />
  );

  useEffect(() => {
    if (existingAppt && existingAppt.id) {
      setAppointment({
        ...existingAppt,
        sendNotification: true,
        duration: Moment(existingAppt.end).diff(
          Moment(existingAppt.start),
          'minutes'
        ),
        start: Moment(existingAppt.start).format('YYYY-MM-DD[T]HH:mm'),
        end: Moment(existingAppt.end).format('YYYY-MM-DD[T]HH:mm'),
      });
    } else {
      const clinician = clinicianOptions.find(
        (e) => e.id === selectedClinicianId
      );
      setAppointment({
        // Left commented for future ticket (drag select appt times)
        clinician,
        sendNotification: true,
        // duration: DEFAULT_DURATION,
        start: Moment(selectedDay).format('YYYY-MM-DD[T]HH:mm'),
        // end: Moment(selectedDay)
        //   .add({ minutes: DEFAULT_DURATION })
        //   .format('YYYY-MM-DD[T]HH:mm'),
      });
    }
  }, []);

  const validateChanges = () => {
    if (
      !appointment.start ||
      !appointment.appointmentType ||
      !appointment.clinician ||
      _.isEqual(appointment, existingAppt) ||
      Moment(appointment.end).isSameOrBefore(appointment.start)
    ) {
      setInvalidInput(true);
    } else if (!appointment.patients) {
      if (newPatients.length) {
        const newPatientsAreValid = newPatients.every(
          (obj) => obj.firstName && obj.lastName && obj.email
        );
        if (newPatientsAreValid) {
          setInvalidInput(false);
        } else {
          setInvalidInput(true);
        }
      } else {
        setInvalidInput(true);
      }
    } else {
      setInvalidInput(false);
    }
  };

  const handleChange = (newEntry) => {
    setAppointment({ ...appointment, ...newEntry });
  };

  const handleCreationChange = (newEntry, index) => {
    const copy = [...newPatients];
    copy[index] = {
      ...newPatients[index],
      ...newEntry,
      dob: Moment().format('YYYY-MM-DD'),
    };
    setNewPatients(copy);
  };

  useEffect(() => {
    validateChanges();
  }, [appointment, newPatients]);

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Appointment Type
          </Typography>

          {queryLoading ? (
            <Loading />
          ) : (
            <Autocomplete
              className={classes.field}
              onChange={(event, values) => {
                if (values) {
                  handleChange({
                    appointmentType: values,
                    duration: values.duration,
                    end: Moment(appointment.start)
                      .add({ minutes: values.duration })
                      .format('YYYY-MM-DD[T]HH:mm'),
                  });
                } else {
                  handleChange({ appointmentType: null });
                }
              }}
              value={{
                name: appointment.appointmentType
                  ? appointment.appointmentType.name
                  : '',
              }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  margin="normal"
                  className={classes.field}
                  style={
                    appointment.appointmentType && {
                      background: appointment.appointmentType.color,
                    }
                  }
                />
              )}
              renderOption={(params) => (
                <div className={classes.dropdownOptions}>
                  <Typography className={classes.optionDetails}>
                    {params.name}
                  </Typography>
                  <Typography className={classes.optionDetails}>
                    {params.desc}
                  </Typography>
                  <Typography className={classes.optionDetails}>
                    {params.code}
                  </Typography>
                </div>
              )}
              PopperComponent={CustomDropdown}
              options={appointmentTypes}
              getOptionLabel={(option) => option.name}
            />
          )}
        </Grid>

        <Grid container item xs className={classes.fieldContainer}>
          <Typography variant="body2" className={classes.nameLabel}>
            Patient
          </Typography>

          {queryLoading ? (
            <div />
          ) : (
            <div>
              <Autocomplete
                id="appt-patient-select"
                className={classes.patientField}
                onChange={(event, values) => {
                  if (values) {
                    handleChange({ patients: values });
                  } else {
                    handleChange({ patients: null });
                  }
                }}
                multiple
                value={appointment.patients ? [...appointment.patients] : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    margin="normal"
                    className={classes.field}
                  />
                )}
                renderOption={(params) => (
                  <div className={classes.dropdownOptions}>
                    <Typography className={classes.optionDetails}>
                      {params.name}
                    </Typography>
                    <Typography className={classes.optionDetails}>
                      {params.phone}
                    </Typography>
                    <Typography className={classes.optionDetails}>
                      {params.email}
                    </Typography>
                  </div>
                )}
                // eslint-disable-next-line react/jsx-no-bind
                PopperComponent={CustomDropdown}
                options={patientList}
                getOptionLabel={(option) => option.name}
              />

              <div className={classes.creationButtonContainer}>
                <ButtonBase
                  onClick={() => setNewPatients([...newPatients, {}])}
                >
                  <Typography variant="body1" className={classes.patientButton}>
                    {newPatients.length ? '' : 'Add Patient'}
                  </Typography>
                </ButtonBase>

                {!!newPatients.length && (
                  <ButtonBase
                    onClick={() => {
                      const newArray = [...newPatients];
                      newArray.pop();
                      setNewPatients(newArray);
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.patientButton}
                    >
                      Cancel
                    </Typography>
                  </ButtonBase>
                )}
              </div>

              <div className={classes.patientCreationContainer}>
                {newPatients.map((i, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`new-patient-container-${index}`}>
                    <Typography variant="body2" className={classes.nameLabel}>
                      New Patient
                    </Typography>
                    <CreatePatient
                      handleChange={(entry) =>
                        handleCreationChange(entry, index)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </Grid>

        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Date &amp; Time
          </Typography>

          <TextField
            id="appt-start-date"
            className={classes.field}
            type="datetime-local"
            value={appointment.start}
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) =>
              handleChange({
                start: event.target.value,
                end: Moment(event.target.value)
                  .add(appointment.duration, 'minutes')
                  .format('YYYY-MM-DD[T]HH:mm'),
              })
            }
          />
        </Grid>

        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Duration
          </Typography>

          <TextField
            id="appt-duration-field"
            autoComplete="off"
            className={classes.field}
            value={appointment.duration}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">mins</InputAdornment>
              ),
            }}
            onChange={(event) => {
              const converted = parseInt(event.target.value, 10);
              if (!Number.isNaN(converted) && converted > 0) {
                handleChange({
                  duration: converted,
                  end: Moment(appointment.start)
                    .add(converted, 'minutes')
                    .format('YYYY-MM-DD[T]HH:mm'),
                });
              } else if (!converted) {
                handleChange({
                  duration: event.target.value,
                  end: Moment(appointment.start),
                });
              }
            }}
          />
        </Grid>

        {/* <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Ending
          </Typography>

          {appointment.end && (
            <TextField
              id="appt-start-date"
              className={classes.field}
              type="datetime-local"
              value={appointment.end}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          )}
        </Grid> */}

        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Clinician
          </Typography>

          <Autocomplete
            id="appt-pract-select"
            className={classes.field}
            onChange={(event, values) => {
              if (values) {
                handleChange({ clinician: values });
              } else {
                handleChange({ clinician: null });
              }
            }}
            value={appointment.clinician ? { ...appointment.clinician } : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                margin="normal"
                className={classes.field}
              />
            )}
            options={clinicianOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
          />
        </Grid>

        <Grid container item xs className={classes.fieldContainer}>
          <Typography className={classes.title} variant="body2">
            Notes
          </Typography>

          <TextField
            className={classes.field}
            id="standard-full-width"
            multiline
            autoComplete="off"
            minRows={2}
            onChange={(event) => handleChange({ notes: event.target.value })}
            value={appointment.notes ? appointment.notes : ''}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onCloseDialog}>
          Cancel
        </Button>

        {existingAppt && existingAppt.id && (
          <FormControlLabel
            control={
              <Checkbox
                checked={appointment.sendNotification}
                onChange={() =>
                  handleChange({
                    sendNotification: !appointment.sendNotification,
                  })
                }
                // name="gilad"
              />
            }
            label="Notify Patient of Time Changes"
          />
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={async () => {
            if (newPatients.length) {
              await upsertApptPlusPatient({
                variables: {
                  ...appointment,
                  start: Moment(appointment.start).toISOString(true),
                  end: Moment(appointment.end).toISOString(true),
                  clinicianEncodedId: appointment.clinician.id,
                  appointmentType: appointment.appointmentType.id,
                  locationId,
                  practiceEncodedId,
                  patients:
                    appointment.patients &&
                    appointment.patients.map((patient) => patient.id),
                  firstNames: newPatients.map((p) => p.firstName),
                  lastNames: newPatients.map((p) => p.lastName),
                  email: newPatients.map((p) => p.email),
                  phone: newPatients.map((p) => p.phone),
                  dob: newPatients.map((p) => p.dob),
                },
              });
            } else {
              await upsertAppt({
                variables: {
                  ...appointment,
                  start: Moment(appointment.start).toISOString(true),
                  end: Moment(appointment.end).toISOString(true),
                  locationId,
                  patientIds: appointment.patients.map((patient) => patient.id),
                  clinicianEncodedId: appointment.clinician.id,
                  appointmentType: appointment.appointmentType.id,
                },
              });
            }
          }}
          disabled={invalidInput}
        >
          Book
        </Button>
      </DialogActions>
    </>
  );
};

const styles = {
  dialogContent: {
    width: 650,
  },
  fieldContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 8,
  },
  nameLabel: {
    alignSelf: 'start',
  },
  patientButton: {
    color: WARM_PINK,
  },
  patientField: {
    marginTop: -16,
    width: 369,
  },
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *:not(:last-child)': {
      marginRight: 8,
    },
    borderBottom: '1px solid #c7c7c7',
  },
  optionDetails: {
    width: 180,
  },
  field: {
    marginTop: -4,
    width: 369,
  },
  creationButtonContainer: {
    '& > *:not(:last-child)': {
      marginRight: 15,
    },
  },
  patientCreationContainer: {
    width: 'auto',
    // maxHeight: 160,
    // overflowX: 'clip',
    // overflowY: 'scroll',
  },
};

export default compose(
  connect(({ practice, user }) => ({
    practiceId: practice.id,
    practiceEncodedId: practice.id,
    refetchPatientList: practice.refetchPatientList,
    locationId: user.currentLocationId,
  })),
  withStyles(styles)
)(CalendarDialog);

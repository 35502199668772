import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import Form from '@rjsf/material-ui';
import { compose, withProps } from 'recompose';
import moment from 'moment';
import Parser from 'html-react-parser';
import lodash from 'lodash';

import {
  createTheme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert/Alert';

import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import AppBarMenu from '../../components/AppBarMenu';
import {
  registrationSchema,
  templateUiSchema,
} from '../ConsentFormBuilder/schemas';
import { LOAD_CONSENT_FORM, SUBMIT_CONSENT_FORM } from './gql';

const defaultSchema = {};

const defaultUiSchema = {};
const onError = errors => {
  // eslint-disable-next-line no-console
  console.log(errors);
};

const themeConsentForm = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#233d4d !important',
      },
    },
    MuiButton: {
      root: {
        backgroundColor: '#233d4d !important',
      },
      label: {
        color: '#fff',
      },
    },
    MuiInputLabel: {
      formControl: {
        position: 'static',
        transform: 'translate(0) scale(1) !important',
        color: '#000 !important',
        textTransform: 'capitalize',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        marginTop: '5px !important',
        '&:before': {
          content: 'none !important',
        },
        '&:after': {
          content: 'none !important',
        },
      },
      input: {
        border: '1px solid rgba(0, 0, 0, 0.42)',
        padding: '6px 5px 7px',
      },
      inputMultiline: {
        padding: '5px !important',
      },
    },
    MuiGrid: {
      root: {
        '&.sigBoxWrap': {
          width: 'auto',
          border: '1px solid rgba(0, 0, 0, 0.42)',
          margin: '0 auto',
        },
        '& .yesno-container': {
          '& h5': {
            fontSize: '1rem !important',
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000 !important',
        textTransform: 'capitalize',
      },
    },
    MuiFormControlLabel: {
      label: {
        textTransform: 'capitalize',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#233d4d !important',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
});

const uiFieldTemplate = fieldProps => {
  if (fieldProps.schema.original_type === 'label') {
    return (
      <div
        className={fieldProps.classNames}
        style={{ position: 'relative' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: fieldProps.schema.description }}
      />
    );
  }
  return (
    <div>
      <div
        className={fieldProps.classNames}
        style={{ position: 'relative', width: '100%' }}
      >
        {fieldProps.description}
        {fieldProps.children}
        {fieldProps.errors}
        {fieldProps.help}
      </div>
      {(fieldProps.schema.original_type === 'scale10' ||
        fieldProps.schema.original_type === 'scale100') && (
        <div>
          {fieldProps.children &&
          fieldProps.children[0] &&
          fieldProps.children[0].props &&
          fieldProps.children[0].props.formData
            ? fieldProps.children[0].props.formData
            : ''}
        </div>
      )}
    </div>
  );
};

const ConsentForm = ({
  classes,
  history,
  formKey,
  user,
  practiceId,
  patientId,
  disabled,
}) => {
  const [consentForm, setConsentForm] = useState(null);
  const [formData, setFormData] = useState({ data: {} });
  const [formError, setFormError] = useState({
    message: undefined,
  });
  const [loadingForm, setLoadingForm] = useState(true);
  const [schema, setSchema] = useState(defaultSchema);
  const [submitting, setSubmitting] = useState(false);
  const [uiSchema, setUiSchema] = useState(defaultUiSchema);

  // eslint-disable-next-line no-unused-vars
  const [submitConsentForm, { data: submitData, loading, error }] = useMutation(
    SUBMIT_CONSENT_FORM
  );

  useQuery(LOAD_CONSENT_FORM, {
    variables: {
      key: formKey,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setConsentForm(data.getConsentForm.form);
      setLoadingForm(false);

      const initSchema = {
        title: data.getConsentForm.form.title,
        type: 'object',
        required: [],
        properties: {},
      };

      if (
        !patientId &&
        'allow_registration' in data.getConsentForm.form &&
        data.getConsentForm.form.allow_registration === true
      ) {
        initSchema.properties = registrationSchema;
        initSchema.required = [
          'firstName',
          'lastName',
          'address',
          'phone',
          'email',
          'dob',
        ];
      }

      const formSchema = JSON.parse(data.getConsentForm.form.schema);
      const formUiSchema = JSON.parse(data.getConsentForm.form.uiSchema);
      // uiSchema = formUiSchema || uiSchema;
      if (formUiSchema) {
        setUiSchema(formUiSchema);
      }
      if (uiSchema['ui:order']) {
        ['formName', 'allow_registration'].forEach(item => {
          const index = uiSchema['ui:order'].indexOf(item);
          if (index > -1) {
            uiSchema['ui:order'].splice(index, 1);
          }
        });
      }
      initSchema.required = lodash.uniq(
        lodash.concat(initSchema.required, formSchema.required)
      );
      initSchema.properties = lodash.merge(
        initSchema.properties,
        formSchema.properties
      );

      // Remove form name required on submit
      const formNameIndex = initSchema.required.indexOf('formName');
      if (formNameIndex > -1) {
        initSchema.required.splice(formNameIndex, 1);
      }

      if (Object.keys(initSchema.properties).length > 0) {
        Object.keys(initSchema.properties).forEach(fieldKey => {
          if ('required' in initSchema.properties[fieldKey]) {
            if (initSchema.properties[fieldKey].required !== false) {
              if (
                'required' in initSchema &&
                Array.isArray(initSchema.required)
              ) {
                if (initSchema.required.indexOf(fieldKey) < 0) {
                  initSchema.required.push(fieldKey);
                }
              } else {
                initSchema.required = [fieldKey];
              }
            }
            delete initSchema.properties[fieldKey].required;
          }

          if (
            'original_type' in initSchema.properties[fieldKey] &&
            initSchema.properties[fieldKey].original_type in templateUiSchema
          ) {
            uiSchema[fieldKey] =
              templateUiSchema[initSchema.properties[fieldKey].original_type];
          }
          if (uiSchema[fieldKey] && uiSchema[fieldKey]['ui:readonly']) {
            uiSchema[fieldKey]['ui:readonly'] = false;
          }
        });
      }
      setSchema(initSchema);
      setUiSchema(uiSchema);
      // set uischema
    },
  });

  if (loadingForm) return <Loading overlay />;
  if (!consentForm) {
    return <Alert severity="error">Invalid Link</Alert>;
  }

  const content = () => {
    if (loading) return <Loading overlay />;
    if (submitting === true) return <Loading overlay />;
    if (error) return <ErrorMessage error={error} />;

    return (
      <Paper className={classes.paperWrap}>
        <ThemeProvider theme={themeConsentForm}>
          {formError.message && (
            <Alert severity="error">
              <span style={{ display: 'inline-block' }}>
                {Parser(formError.message)}
              </span>
            </Alert>
          )}

          <Form
            disabled={disabled}
            schema={schema}
            uiSchema={uiSchema}
            formData={formData.data}
            FieldTemplate={uiFieldTemplate}
            onSubmit={(i, e) => {
              setSubmitting(true);

              let mergedFormData = lodash.cloneDeep(i.formData);
              setFormData({ data: i.formData });
              if ('properties' in schema) {
                if (Object.keys(schema.properties).length > 0) {
                  Object.keys(schema.properties).forEach(fieldKey => {
                    // Check if it is date field
                    if (schema.properties[fieldKey].original_type === 'date') {
                      if (
                        !mergedFormData[fieldKey] ||
                        mergedFormData[fieldKey] === 'Invalid date'
                      ) {
                        mergedFormData[fieldKey] = moment().format();
                      }
                    }

                    if (schema.properties[fieldKey].original_type === 'yesNo') {
                      if (typeof mergedFormData[fieldKey] === 'object') {
                        if (mergedFormData[fieldKey].yesNoValue === false) {
                          mergedFormData[fieldKey] = mergedFormData[fieldKey]
                            .reason
                            ? `No - ${mergedFormData[fieldKey].reason}`
                            : 'No';
                        } else {
                          mergedFormData[fieldKey] = mergedFormData[fieldKey]
                            .reason
                            ? `Yes - ${mergedFormData[fieldKey].reason}`
                            : 'Yes';
                        }
                      } else if (mergedFormData[fieldKey] === false) {
                        mergedFormData[fieldKey] = 'No';
                      } else {
                        mergedFormData[fieldKey] = 'Yes';
                      }
                    }
                    if (
                      schema.properties[fieldKey].original_type ===
                        'multipleCheckboxes' &&
                      Array.isArray(mergedFormData[fieldKey])
                    ) {
                      mergedFormData[fieldKey] = mergedFormData[fieldKey].join(
                        '; '
                      );
                    }

                    if (Array.from(schema.required).includes(fieldKey)) {
                      // this is a required field
                      if (
                        !(fieldKey in mergedFormData) ||
                        !mergedFormData[fieldKey]
                      ) {
                        setFormError({
                          ...formError,
                          message: `${schema.properties[fieldKey].title} is required`,
                        });
                        setSubmitting(false);
                      }
                    }
                  });
                }
              }

              setFormError({ ...formError, message: undefined });

              const practiceIdOverride = practiceId || consentForm.practice_id;
              if (practiceIdOverride) {
                mergedFormData = lodash.merge(mergedFormData, {
                  practice: practiceIdOverride,
                });
              }

              if (patientId) {
                mergedFormData = lodash.merge(mergedFormData, {
                  patientId,
                });
              }

              // submit data
              submitConsentForm({
                variables: {
                  formData: JSON.stringify(mergedFormData),
                  key: formKey,
                },
              }).then(res => {
                setSubmitting(false);

                return history.push('/thankyou');
              });
            }}
            onError={onError}
          >
            <Grid style={{ marginTop: '20px' }}>
              {!disabled && (
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              )}
            </Grid>
          </Form>
        </ThemeProvider>
      </Paper>
    );
  };

  const pageContent = (
    <Grid
      component="main"
      className={classes.main}
      container
      direction="row"
      wrap="nowrap"
      item
      xs={12}
      sm={12}
      md={12}
      lg={10}
      xl={9}
    >
      <Grid
        className={classes.consentForm}
        container
        direction="row"
        wrap="nowrap"
        item
        xs={12}
        sm={12}
        md={11}
        lg={11}
        xl={11}
      >
        {content()}
      </Grid>
    </Grid>
  );

  if (user.email) {
    return <AppBarMenu>{pageContent}</AppBarMenu>;
  }
  return pageContent;
};

const styles = {
  main: {
    paddingTop: 92,
    paddingLeft: 88,
    paddingRight: 44,
    paddingBottom: 64,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: () => window.innerWidth > 600 && '100%',
  },
  consentForm: {
    paddingRight: 10,
  },
  paperWrap: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    padding: '20px 40px',
    height: 'max-content',
    marginBottom: '50px',
  },
};

export default compose(
  withStyles(styles),
  connect(({ user, practice }) => ({
    user,
    practiceId: practice.id,
  })),
  withProps(({ match, formKey }) => ({
    formKey: formKey || match.params.formKey,
    patientId: match && match.params ? match.params.patientId : '',
  }))
)(ConsentForm);

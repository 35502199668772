import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';

import { Button, Grid, Paper, Typography, TableCell, TableRow, TableHead, TableBody, Table, TableFooter, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';

import _ from 'lodash';
import AppBarMenu from '../../components/AppBarMenu';
import { LIST_INVOICES, UPSERT_INVOICE } from '../InvoicingPage/gql';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import { BOULDER, WILD_SAND, WARM_PINK } from '../../style/constants';
import InvoiceItemDialog from './InvoiceItemDialog';
import SelectedPatientCard from '../InvoicingPage/SelectedPatientCard';
import InvoicePaymentDialog from './InvoicePaymentDialog';
import UpdateInvoiceDialog from './UpdateInvoiceDialog';
import { patientSelected } from '../../data/redux/actions/patient';
import { formatMoney } from '../../utility';
import PdfControls from './PdfControls';

const InvoiceDetailPage = ({ classes, currentLocationId, encodedPatientId, match, practiceId, location }) => {
    const dispatch = useDispatch();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [patient, setPatient] = useState();
    const [subtotalPayments, setSubtotalPayments] = useState(0);
    const [invoiceAccount, setInvoiceAccount] = useState({});
    const [invoiceAccountOptions, setInvoiceAccountOptions] = useState([]);
    const [showEditInvoiceDialog, setShowEditInvoiceDialog] = useState(false);
    const [showItemDialog, setShowItemDialog] = useState(false);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [selectedInvoiceItem, setSelectedInvoiceItem] = useState();
    const [selectedInvoicePayment, setSelectedInvoicePayment] = useState();
    const [readyToGenerate, setReadyToGenerate] = useState(false);

    const { invoiceId } = match.params;

    const [listInvoiceItems, { loading, error }] = useLazyQuery(LIST_INVOICES, {
        variables: {
            id: invoiceId,
            practiceId,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.fetchInvoice[0]) {
                const invoicePatient = _.get(res, 'fetchInvoice[0].patient');
                setInvoiceDetails(res.fetchInvoice[0]);
                setPatient(invoicePatient);
                setInvoiceAccountOptions(_.get(res, 'fetchInvoice[0].patient.invoiceAccounts', []));
                // TODO: make another Phantom query to get the invoice total payments on API
                setSubtotalPayments(_.get(res, 'fetchInvoice[0].amount', 0) - _.get(res, 'fetchInvoice[0].balanceDue', 0));
                dispatch(patientSelected(invoicePatient));
            }
            return null;
        },
    });

    const [upsertInvoice] = useMutation(UPSERT_INVOICE, {
        fetchPolicy: 'no-cache',
        variables: {
            locationId: currentLocationId,
            encodedPatientId,
            id: invoiceDetails.id,
            status: 'OUTSTANDING',
            practiceId,
        },
    });

    useEffect(() => {
        if (!invoiceDetails.invoiceAccount || !invoiceDetails.dateDue) {
            setReadyToGenerate(false);
        } else {
            setReadyToGenerate(true);
        }
    }, [invoiceDetails]);

    useEffect(() => {
        listInvoiceItems({
            variables: {
                id: match.params.invoiceId,
                practiceId,
            },
        });
    }, []);

    const handlePatientSelect = () => {
        if (patient && patient.id) {
            dispatch(patientSelected(patient));
            window.location.href = `/patient/${patient.id}`;
        }
    };

    const onClickEdit = () => {
        setShowEditInvoiceDialog(!showEditInvoiceDialog);
    };

    const onSaveHandler = () => {
        upsertInvoice({
            variables: {
                id: invoiceDetails.id,
                practiceId,
                invoiceAccountId: invoiceAccount.account.id,
            },
        });
    };

    const onCloseItemDialog = () => {
        setSelectedInvoiceItem(null);
        setShowItemDialog(false);
    };

    const onSelectInvoiceItem = (e) => {
        setSelectedInvoiceItem(e);
        setShowItemDialog(true);
    };

    const onClosePaymentDialog = () => {
        setSelectedInvoicePayment(null);
        setShowPaymentDialog(false);
    };

    const onSelectInvoicePayment = (e) => {
        setSelectedInvoicePayment(e);
        setShowPaymentDialog(true);
    };

    if (loading) return <Loading />;
    if (error) return <ErrorMessage error={error} />;
    if (_.isEmpty(invoiceDetails) && !loading) return <div>No Invoice found. Check Invoice ID is correct.</div>;

    return (
        <AppBarMenu>
            <Grid container component='main' className={classes.main}>
                <Grid
                    // breadcrumbs and nav container
                    className={classes.controlsContainer}
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    item
                    xs={12}
                    sm={11}
                    md={10}
                    lg={8}
                    xl={8}>
                    <PdfControls
                        invoiceDetails={invoiceDetails}
                        readyToGenerate={readyToGenerate}
                        upsertInvoice={upsertInvoice}
                        listInvoiceItems={listInvoiceItems}
                    />
                </Grid>
                <Grid className={classes.content} container direction='column' wrap='nowrap' item xs={12} sm={11} md={10} lg={8} xl={8}>
                    <div className={classes.leftColumn}>
                        <Typography className={classes.subheading} variant='subtitle1'>
                            {patient ? 'Patient' : 'No Patient Selected'}
                        </Typography>
                        <Paper className={classes.patientSearchContainer}>
                            {patient ? (
                                <Table className={classes.table} aria-label='simple table'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.noDividerCell}>Name</TableCell>
                                            <TableCell className={classes.noDividerCell}>{patient.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.noDividerCell}>Phone</TableCell>
                                            <TableCell className={classes.noDividerCell}>{patient.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.noDividerCell}>Email</TableCell>
                                            <TableCell className={classes.noDividerCell}>{patient.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.noDividerCell}>DOB</TableCell>
                                            <TableCell className={classes.noDividerCell}>{patient.dob}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Button
                                                    className={classes.greyButton}
                                                    disabled={!patient || !patient.id}
                                                    onClick={handlePatientSelect}
                                                    variant='outlined'>
                                                    Patient Notes
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            ) : (
                                <SelectedPatientCard form={invoiceAccount} setForm={setInvoiceAccount} onSave={onSaveHandler} />
                            )}
                        </Paper>
                        {!loading && patient && (
                            <>
                                <Typography className={classes.subheading} variant='subtitle1'>
                                    Invoice Details
                                </Typography>
                                <Paper className={classes.patientSearchContainer}>
                                    <Table className={classes.table} aria-label='simple table'>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.noDividerCell}>Invoice No.</TableCell>
                                                <TableCell className={classes.noDividerCell}>{invoiceDetails.invoiceNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.noDividerCell}>Invoice Date</TableCell>
                                                <TableCell className={classes.noDividerCell}>
                                                    {moment(invoiceDetails.invoiceDate).format('YYYY-MM-DD')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.noDividerCell}>Invoice Due</TableCell>
                                                <TableCell className={classes.noDividerCell}>{invoiceDetails.dateDue}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.noDividerCell}>Bill To</TableCell>
                                                <TableCell className={classes.noDividerCell}>
                                                    {_.get(invoiceDetails, ['invoiceAccount', 'billingName'], 'No Invoice Account Selected')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.noDividerCell}>Status</TableCell>
                                                <TableCell className={classes.noDividerCell}>{invoiceDetails.status}</TableCell>
                                            </TableRow>
                                            {invoiceDetails.status !== 'DRAFT' && (
                                                <TableRow>
                                                    <TableCell className={classes.noDividerCell}>
                                                        <Typography variant='h6'>Balance Due</Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.noDividerCell}>
                                                        <Typography variant='h6'>
                                                            {(invoiceDetails.balanceDue || 0) < 0 ? '+' : ''}
                                                            {formatMoney(Math.abs(invoiceDetails.balanceDue || 0), location.currency.currency)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Button className={classes.greyButton} onClick={onClickEdit} variant='outlined'>
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Paper>
                            </>
                        )}
                    </div>
                    <div className={classes.rightColumn}>
                        <Typography className={classes.subheading} variant='subtitle1'>
                            Items
                        </Typography>
                        <Paper className={classes.patientSearchContainer}>
                            <Table className={classes.table} aria-label='simple table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={7} className={classes.buttonContainer}>
                                            <Button
                                                className={classes.button}
                                                onClick={() => {
                                                    setShowItemDialog(true);
                                                }}>
                                                Add Item
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell align='left'>Clinician</TableCell>
                                        <TableCell align='left'>Item / Service</TableCell>
                                        <TableCell align='left'>Qty</TableCell>
                                        <TableCell align='left'>Price</TableCell>
                                        <TableCell align='left'>Taxes</TableCell>
                                        <TableCell align='right'>Subtotal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoiceDetails.invoiceItem.map((row) => (
                                        <TableRow className={classes.clickableRow} hover key={row.id} onClick={() => onSelectInvoiceItem(row)}>
                                            <TableCell>{row.date}</TableCell>
                                            <TableCell>{row.clinicianName}</TableCell>
                                            <TableCell>{row.comments}</TableCell>
                                            <TableCell>{row.quantity}</TableCell>
                                            <TableCell>{formatMoney(row.price || 0, location.currency.currency)}</TableCell>
                                            <TableCell>{formatMoney(row.taxes, location.currency.currency)}</TableCell>
                                            <TableCell align='right'>
                                                {formatMoney((row.price * row.quantity || 0) + (row.taxes || 0), location.currency.currency)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell className={classes.buttonContainer} colSpan={6}>
                                            Total Tax
                                        </TableCell>
                                        <TableCell className={classes.buttonContainer} colSpan={1}>
                                            {formatMoney(invoiceDetails.totalTax || 0, location.currency.currency)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.buttonContainer} colSpan={6}>
                                            Grand Total
                                        </TableCell>
                                        <TableCell className={classes.buttonContainer} colSpan={1}>
                                            {formatMoney(invoiceDetails.amount || 0, location.currency.currency)}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Paper>
                        {!loading && (
                            <>
                                <Typography className={classes.subheading} variant='subtitle1'>
                                    Payments
                                </Typography>
                                <Paper className={classes.patientSearchContainer}>
                                    <Table className={classes.table} aria-label='simple table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={6} className={classes.buttonContainer}>
                                                    <Button
                                                        className={classes.button}
                                                        onClick={() => {
                                                            setShowPaymentDialog(true);
                                                        }}>
                                                        Add Payment
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell align='left'>Staff</TableCell>
                                                <TableCell align='left'>Method</TableCell>
                                                <TableCell align='left'>Has Comments?</TableCell>
                                                <TableCell align='left'>Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoiceDetails.invoicePayment.map((row) => (
                                                <TableRow
                                                    className={classes.clickableRow}
                                                    hover
                                                    key={row.id}
                                                    onClick={() => onSelectInvoicePayment(row)}>
                                                    <TableCell>{row.dateReceived}</TableCell>
                                                    <TableCell>{row.userName}</TableCell>
                                                    <TableCell>{row.paidVia}</TableCell>

                                                    <TableCell>
                                                        {!!row.comments && (
                                                            <Tooltip title={row.comments}>
                                                                <CommentIcon />
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align='right'>{formatMoney(row.subtotal || 0, location.currency.currency)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell className={classes.buttonContainer} colSpan={5}>
                                                    {formatMoney(subtotalPayments, location.currency.currency)}
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Paper>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            <UpdateInvoiceDialog
                existingElement={invoiceDetails}
                invoiceAccountOptions={invoiceAccountOptions}
                invoiceId={match.params.invoiceId}
                onCloseDialog={() => setShowEditInvoiceDialog(false)}
                practice={practiceId}
                showDialog={showEditInvoiceDialog}
            />
            <InvoiceItemDialog
                existingItem={selectedInvoiceItem}
                invoiceId={match.params.invoiceId}
                listInvoiceItems={listInvoiceItems}
                onCloseDialog={onCloseItemDialog}
                practiceId={practiceId}
                setExistingItem={setSelectedInvoiceItem}
                showDialog={showItemDialog}
            />
            <InvoicePaymentDialog
                listInvoiceItems={listInvoiceItems}
                existingPayment={selectedInvoicePayment}
                invoiceId={match.params.invoiceId}
                setExistingPayment={setSelectedInvoicePayment}
                onCloseDialog={onClosePaymentDialog}
                showDialog={showPaymentDialog}
            />
        </AppBarMenu>
    );
};

const styles = {
    main: {
        display: 'flex',
        justifyContent: 'center',
        height: () => window.innerWidth > 600 && '100%',
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 66,
        paddingRight: 22,
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 10,
    },
    clickableRow: {
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftColumn: {
        width: 370,
    },
    rightColumn: {
        marginLeft: 30,
        // TODO: change to flex
        width: 'calc(100% - 370px)',
    },
    subheading: {
        fontWeight: 'bold',
        paddingTop: 36,
        paddingBottom: 8,
    },
    patientSearchContainer: {
        marginTop: 8,
    },
    invoiceDetailContainer: {
        marginTop: 8,
        padding: 12,
    },
    buttonContainer: {
        textAlign: 'right',
    },
    noDividerCell: {
        paddingBottom: '0px !important',
        borderBottom: 'none',
    },
    greyButton: {
        width: 144,
        height: 40,
        color: BOULDER,
        backgroundColor: WILD_SAND,
    },
    warmPinkButton: {
        padding: 10,
        height: 40,
        background: WARM_PINK,
        color: 'white',
        marginLeft: 12,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, patient, practice }) => ({
        currentLocationId: user.currentLocationId,
        userId: user.id,
        practiceId: practice.id,
        selectedPatient: patient,
        encodedPatientId: patient.id,
        patientName: patient.name,
        location: user.location,
    }))
)(InvoiceDetailPage);

import React, { useState } from 'react';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import EmptyData from '../../components/EmptyData';
import ErrorMessage from '../../components/ErrorMessage';
import Loading from '../../components/Loading';

const EmptyAccordion = ({
  classes,
  error,
  records = [],
  status,
  typeAttachment,
  typeEpisodes,
  typeSubmission,
}) => {
  const [expanded, setExpanded] = useState(false);
  let recordType = 'notes';
  if (typeAttachment) {
    recordType = 'attachments';
  } else if (typeEpisodes) {
    recordType = 'episodes';
  } else if (typeSubmission) {
    recordType = 'submissions';
  }
  const loading = status.loading || status.refetching || status.fetching;

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={handleToggle}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2" className={classes.collapseText}>
          {`0 ${recordType}`}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Paper className={classes.paper}>
          {loading && <Loading height={100} />}
          {error && (
            <ErrorMessage
              error={`Can not query ${recordType} data: ${error}`}
            />
          )}
          {!loading && (!records || records.length === 0) && (
            <EmptyData text={`There are no ${recordType} available`} />
          )}
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    // marginBottom: 24,
  },
  accordion: {
    marginBottom: 24,
  },
  collapseText: {
    alignSelf: 'center',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

export default compose(withStyles(styles))(EmptyAccordion);

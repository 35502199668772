/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { compose } from 'recompose';
import _ from 'lodash';

import {
    Button,
    ButtonBase,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

import UpdateAvailabilityDialogContainer from './updateAvailabilityDialogContainer';
import { daysOfWeek } from './constants';
import { hoursArray } from '../../pages/DairyPage/CalendarSettingsDialog/constants';
import { WARM_PINK } from '../../style/constants';

const NEW_BLOCKED_OUT = {
    start: '',
    end: '',
};

const UpdateAvailabilityDialog = ({ classes, existingClinician, onCloseDialog, onCreateAvailability, showDialog }) => {
    const { id, name, availability, blockedOut } = existingClinician;
    const [invalidInput, setInvalidInput] = useState([]);
    const [tempAvailability, setTempAvailability] = useState(availability || []);
    const [tempBlockedOut, setTempBlockedOut] = useState(blockedOut || []);
    const [isUniqueInput, setIsUniqueInput] = useState(false);

    useEffect(() => {
        // TODO: validation for clinicians' availability
        // validateChanges();
    }, [tempAvailability]);

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} className={classes.modal} maxWidth='lg'>
            <DialogTitle>{`${name}'s Availability`}</DialogTitle>
            <DialogContent>
                <Grid className={classes.section}>
                    <FormLabel component='legend' className={classes.formLabel}>
                        Days Available
                    </FormLabel>
                    <Grid>
                        {daysOfWeek.map((day) => (
                            <ButtonBase
                                className={classes.dateField}
                                onClick={() => {
                                    if (!tempAvailability.includes(day.id)) {
                                        setTempAvailability([...tempAvailability, day.id]);
                                        setIsUniqueInput(true);
                                    } else {
                                        const availOmitted = tempAvailability.filter((e) => e !== day.id);
                                        setTempAvailability(availOmitted);
                                        setIsUniqueInput(true);
                                    }
                                }}
                                key={`button-day-${day.id}`}>
                                <Checkbox checked={tempAvailability.includes(day.id)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                <div className={classes.formControlLabel}>{day.name}</div>
                            </ButtonBase>
                        ))}
                    </Grid>
                </Grid>

                <Grid className={classes.section}>
                    <FormLabel component='legend' className={classes.formLabel}>
                        Blocked Out
                    </FormLabel>

                    <Grid>
                        {tempBlockedOut &&
                            tempBlockedOut.map((period, index) => {
                                const { start, end } = period;
                                return (
                                    <div
                                        className={classes.timeField}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`select-group-blockedOut-${start}-${index}-${end}`}>
                                        <Select
                                            className={classes.field}
                                            value={start}
                                            onChange={(event) => {
                                                const clonedBlockedOut = [...tempBlockedOut];
                                                clonedBlockedOut[index].start = event.target.value;
                                                setTempBlockedOut(clonedBlockedOut);
                                                setIsUniqueInput(true);
                                            }}
                                            error={_.includes(invalidInput, period.id)}>
                                            {hoursArray().map((time) => (
                                                <MenuItem value={time} key={`menuitem-time-a-${time}`}>
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <div>-</div>

                                        <Select
                                            className={classes.field}
                                            value={end}
                                            onChange={(event) => {
                                                const clonedBlockedOut = [...tempBlockedOut];
                                                clonedBlockedOut[index].end = event.target.value;
                                                setTempBlockedOut(clonedBlockedOut);
                                                setIsUniqueInput(true);
                                            }}
                                            disabled={!!tempBlockedOut[period.id]}
                                            error={_.includes(invalidInput, period.id)}>
                                            {hoursArray().map((time) => (
                                                <MenuItem value={time} key={`menuitem-time-b-${time}`}>
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <IconButton
                                            className={classes.deleteButton}
                                            onClick={() => {
                                                const newBlockedOut = tempBlockedOut.filter((item, ind) => ind !== index);
                                                setTempBlockedOut(newBlockedOut);
                                                setIsUniqueInput(true);
                                            }}>
                                            <CancelIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}

                        <ButtonBase className={classes.addButton} onClick={() => setTempBlockedOut([...tempBlockedOut, NEW_BLOCKED_OUT])}>
                            Add Time
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onCloseDialog}>
                    Cancel
                </Button>

                <Button
                    color='secondary'
                    onClick={async () => {
                        await onCreateAvailability({
                            availability: tempAvailability,
                            blockedOut: tempBlockedOut,
                            name,
                            id,
                        });
                        onCloseDialog();
                    }}
                    disabled={
                        invalidInput.length > 0 ||
                        // (_.isEqual(tempAvailability, availability &&
                        // _.isEqual(tempBlockedOut, blockedOut) ||
                        !isUniqueInput
                    }>
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    title: {
        width: 100,
    },
    field: {
        width: 120,
    },
    formLabel: {
        width: 160,
        paddingTop: 12,
    },
    formControlLabel: {
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
    },
    section: {
        display: 'flex',
        alignItems: 'start',
        '&:last-child': {
            marginTop: 30,
        },
    },
    dateField: {
        display: 'flex',
        alignItems: 'center',
    },
    timeField: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: 24,
        },
    },
    deleteButton: {
        width: 24,
        height: 24,
        background: WARM_PINK,
        color: 'white',
    },
    addButton: {
        color: WARM_PINK,
        fontSize: 16,
        padding: 12,
    },
};

export default compose(UpdateAvailabilityDialogContainer, withStyles(styles))(UpdateAvailabilityDialog);

import { branch, compose, withProps, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import some from 'lodash/some';
import remove from 'lodash/remove';

import { noteUpsert } from '../../data/redux/actions/note';
import { notesChecked } from '../../data/redux/actions/notes';

export const textNotePreviewQuery = gql`
  query textNotePreview($noteId: ID!) {
    node(id: $noteId) {
      id
      __typename
      ... on TextNote {
        createdAt
        title
        text
        isSignedOff
        signedOffTime
        patient {
          id
          name
        }
        author {
          id
          name
        }
      }
    }
  }
`;

const TextNotePreviewContainer = compose(
  withRouter,
  connect(({ notes, user }) => ({
    showCheckBox: notes.signOff,
    checkedNotes: notes.checkedNotes,
    userId: user.id,
    isAuth: user.roles.includes('CLINICIAN') || user.roles.includes('ADMIN'),
  })),
  graphql(textNotePreviewQuery, {
    name: 'query',
    options: ({ noteId }) => ({
      variables: { noteId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withProps(({ query }) => ({
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    props => props.status.success,
    withProps(({ query, checkedNotes, userId }) => ({
      __typename: query.node.__typename,
      checkedNoteObject: {
        id: query.node.id,
        __typename: query.node.__typename,
      },
      checkedNotesMatch: some(checkedNotes, {
        id: query.node.id,
        __typename: query.node.__typename,
      }),
      id: query.node.id,
      createdAt: query.node.createdAt,
      title: query.node.title,
      text: query.node.text,
      isSignedOff: query.node.isSignedOff,
      signedOffTime: query.node.signedOffTime,
      patientName: query.node.patient.name,
      patientId: query.node.patient.id,
      isAuthor: userId === query.node.author.id,
      authorName: query.node.author.name,
    }))
  ),
  withHandlers({
    onCheckNote: ({
      checkedNotes,
      checkedNotesMatch,
      checkedNoteObject,
      dispatch,
    }) => () => {
      const newCheckedNotes = checkedNotes.map(note => note);

      if (!checkedNotesMatch) {
        newCheckedNotes.push(checkedNoteObject);
      } else {
        remove(newCheckedNotes, checkedNoteObject);
      }

      dispatch(notesChecked(newCheckedNotes));
    },
  }),
  withHandlers({
    onLinkPress: ({
      __typename,
      dispatch,
      history,
      showCheckBox,
      onCheckNote,
      patientId,
      noteId,
      isAuthor,
      isAuth,
      isSignedOff,
      signedOffTime,
      title,
    }) => async () => {
      if (showCheckBox && isAuthor && isAuth) {
        onCheckNote();
      } else if (!showCheckBox) {
        await dispatch(
          noteUpsert({
            id: noteId,
            __typename,
            title,
            isSignedOff,
            signedOffTime,
          })
        );
        switch (__typename) {
          case 'TextNote':
            return history.push(`/patient/${patientId}/note/text/${noteId}`);
          case 'DrawingNote':
            return history.push(`/patient/${patientId}/note/drawing/${noteId}`);
          case 'MediaNote':
            return history.push(`/patient/${patientId}/attachments/${noteId}`);
          case 'FormNote':
            return history.push(`/patient/${patientId}/note/form/${noteId}`);
          default:
        }
        return null;
      }
    },
  })
);

export default TextNotePreviewContainer;

import React from 'react';


import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import DrawingSVG from '../DrawingSVG';
import EmptyData from '../EmptyData';

const DrawingSectionPreview = ({
  classes,
  drawingData,
  templateData,
  title,
}) => (
  <Grid item xs>
    <div className={classes.content}>
      <Typography variant="body1">{title}</Typography>
      {(() => {
        if (drawingData || templateData)
          return (
            <DrawingSVG
              className={classes.drawing}
              drawingData={drawingData}
              templateData={templateData}
            />
          );

        return (
          <div className={classes.emptyData}>
            <EmptyData text="No Drawing Input" />
          </div>
        );
      })()}
    </div>
  </Grid>
);

const styles = {
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: [0, 12, 0, 12],
  },
  drawing: {
    height: 212,
    width: 212,
  },
  emptyData: {
    border: [1, 'solid', 'lightgray'],
    height: 200,
    width: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default withStyles(styles)(DrawingSectionPreview);

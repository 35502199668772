import React from 'react';
import { compose } from 'recompose';
import { Document, Page } from 'react-pdf';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import PDFPreviewModal from '../PDFPreviewModal';
import EpisodePopover from '../EpisodePopover';
import CategoryListPopover from '../CategoryListPopover';
import MoreInfoPopover from '../MoreInfoPopover';
import DeleteNoteDialog from '../DeleteNoteDialog';
import DownloadMediaNote from '../DownloadMediaNote';
import MediaNoteContainer from './mediaNoteContainer';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import EmptyData from '../EmptyData';

// TODO: Add download button on this component.

const MediaNote = ({
    classes,
    error,
    form,
    onSave,
    onChangeFile,
    onTag,
    showModal,
    updateForm,
    updateShowModal,
    isAdmin,
    isReceptionist,
    isClinician,
    canEdit,
    onArchiveNote,
    isConsentFormSubscription,
}) => (
    <Grid>
        <Grid className={classes.titleRow}>
            <TextField
                id='title'
                autoComplete='off'
                label='Title'
                disabled={!form.fileUri || !canEdit || form.loading}
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder='Untitled Attachment'
                className={classes.inputTitle}
                value={form.title || ''}
                onChange={async (event) => {
                    let shouldSave = false;
                    if (event.target.value) shouldSave = true;
                    await updateForm({ ...form, title: event.target.value });
                    if (shouldSave) onSave();
                }}
            />
            <Grid className={classes.buttons}>
                {form.loading && <Typography variant='body1'>Saving ...</Typography>}
                {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                    <EpisodePopover
                        noteId={form.id}
                        selectedEpisodes={form.episodeIdsArray}
                        onTag={onTag}
                        loading={form.loading}
                        updateEpisodes={(episodeIdsArray) => {
                            updateForm({ ...form, episodeIdsArray });
                        }}
                    />
                )}
                {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                    <CategoryListPopover
                        noteId={form.id}
                        selectedCategories={form.categoryIdsArray}
                        onTag={onTag}
                        loading={form.loading}
                        updateCategories={(categoryIdsArray) => updateForm({ ...form, categoryIdsArray: categoryIdsArray || [] })}
                    />
                )}
                <MoreInfoPopover
                    attachment
                    authorName={form.author && form.author.name}
                    createdAt={form.createdAt}
                    episodes={form.episodes}
                    loading={form.loading}
                    noteId={form.id}
                    patientName={form.patientName}
                />
                {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && !isConsentFormSubscription && (
                    <DeleteNoteDialog
                        isSignedOff={form.isSignedOff}
                        loading={form.loading}
                        noteId={form.id}
                        onArchiveNote={() => onArchiveNote()}
                        form={form}
                    />
                )}
                <DownloadMediaNote isSignedOff={form.isSignedOff} loading={form.loading} form={form} />
            </Grid>
        </Grid>
        <Paper className={classes.paper}>
            {form.fileLoading && <Loading overlay />}
            {(() => {
                if (form.loading) return <Loading />;
                if (form.error) return <ErrorMessage text={form.error.message} />;

                return (
                    <Grid container direction='column' className={form.fileLoading ? classes.disabled : null}>
                        {(() => {
                            if (form.fileUri && form.fileType === 'JPG')
                                return (
                                    <form className={classes.form} name='mediaForm'>
                                        <label htmlFor='file-input'>
                                            <img className={classes.image} src={form.fileUri} alt='Media Note' />
                                            <input
                                                className={classes.input}
                                                id='file-input'
                                                type='file'
                                                accept='.jpg,.jpeg,.pdf'
                                                onChange={(event) => onChangeFile(event.target.files[0])}
                                                disabled={!canEdit}
                                            />
                                        </label>
                                    </form>
                                );
                            if (form.fileUri && form.fileType === 'PDF')
                                return (
                                    <div className={classes.modalPreview}>
                                        <Document file={form.fileUri} onClick={() => updateShowModal(true)}>
                                            <Page pageNumber={1} width={256} />
                                        </Document>
                                        <PDFPreviewModal
                                            fileUri={form.fileUri}
                                            onChangeFile={onChangeFile}
                                            onCloseModal={() => updateShowModal(false)}
                                            showModal={showModal}
                                        />
                                    </div>
                                );

                            return (
                                <form className={classes.form} name='mediaForm'>
                                    <label htmlFor='file-input'>
                                        <div className={classes.emptyData}>
                                            <EmptyData
                                                minHeight={200}
                                                text={
                                                    !form.fileType
                                                        ? 'Click to Upload File'
                                                        : 'The file type is unsupported. Please upload a new file.'
                                                }
                                            />
                                        </div>
                                        <input
                                            className={classes.input}
                                            id='file-input'
                                            type='file'
                                            accept='.jpg,.jpeg,.pdf'
                                            onChange={(event) => onChangeFile(event.target.files[0])}
                                            disabled={!canEdit}
                                        />
                                    </label>
                                    {form.fileSizeExceed && (
                                        <ErrorMessage text='Maximum file upload size is 4.5 MB. Please try again with smaller file size.' />
                                    )}
                                </form>
                            );
                        })()}
                    </Grid>
                );
            })()}
        </Paper>
    </Grid>
);

const styles = {
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 16,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 18,
    },
    paper: {
        position: 'relative',
        marginBottom: 36,
        padding: 20,
    },
    disabled: {
        opacity: 0.5,
    },
    inputTitle: {
        fontWeight: 'bold',
        flexBasis: '65%',
    },
    form: {
        height: '100%',
        width: '100%',
    },
    image: {
        height: '100%',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    emptyData: {
        border: [1, 'solid', 'lightgray'],
        height: '100%',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    input: {
        display: 'none',
    },
    modalPreview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
};

export default compose(MediaNoteContainer, withStyles(styles))(MediaNote);

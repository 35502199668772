/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';

import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Button,
  ButtonBase,
  Popper,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import UpsertAppointmentContent from './UpsertAppointmentContent';
import UpsertEventContent from './UpsertEventContent';

const CalendarDialog = ({ classes, ...props }) => {
  const { existingAppt, setExistingAppt, onCloseDialog, showDialog } = props;
  const eventType =
    existingAppt.__typename === 'Event' ? 'Block Out' : 'Appointment';

  return (
    <Dialog
      open={showDialog}
      onClose={onCloseDialog}
      maxWidth="lg"
      className={classes.modal}
    >
      <DialogTitle>
        {existingAppt && existingAppt.id
          ? `Edit ${eventType}`
          : `New ${eventType}`}
      </DialogTitle>
      <Grid className={classes.fieldContainer}>
        <Typography variant="body2">Select One:</Typography>
        <RadioGroup
          className={classes.field}
          defaultValue="Appointment"
          onChange={setExistingAppt}
        >
          <FormControlLabel
            name="__typename"
            value="Appointment"
            control={<Radio />}
            label={<Typography variant="body2">Appointment</Typography>}
          />
          <FormControlLabel
            name="__typename"
            value="Event"
            control={<Radio />}
            label={<Typography variant="body2">Block Out</Typography>}
          />
        </RadioGroup>
      </Grid>
      {existingAppt.__typename === 'Event' ? (
        <UpsertEventContent {...props} />
      ) : (
        <UpsertAppointmentContent {...props} />
      )}
    </Dialog>
  );
};

const styles = {
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  field: {
    width: 369,
  },
};

export default compose(withStyles(styles))(CalendarDialog);

import React from 'react';

import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Collections from '@material-ui/icons/Collections';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { withStyles } from '@material-ui/core/styles';

import { MINOR_BLACK } from '../../style/constants';
import MorePopover from '../MorePopover';

const FormTemplateSection = ({
  autoFocus,
  classes,
  isAuth,
  isLoading,
  isOptimisticResponse,
  onArchiveSection,
  onChangeTitle,
  onFocus,
  onSave,
  onSaveSection,
  onSelectSection,
  rowNumber,
  section,
  updateReorderDialog,
  updateTemplateDialog,
  onNewCheckbox,
  onDeleteCheckbox,
  checkboxesData,
  onUpdateCheckboxData,
  spreadsheetData,
  onNewSpreadsheetColumn,
  onDeleteSpreadsheetColumn,
  onUpdateSpreadsheetColumn,
  onUpdateSpreadsheetData,
}) => (
  <div className={classes.column}>
    <div className={classes.templateTitle}>
      <TextField
        id="title"
        autoComplete="off"
        disabled={!isAuth || (isLoading && !isOptimisticResponse)}
        className={classes.sectionTitle}
        value={section.title}
        InputProps={{
          className: isAuth ? classes.sectionTitleInput : null,
        }}
        autoFocus={
          autoFocus === section.rowIndexes.concat(section.columnIndexes).join()
        }
        onFocus={onFocus}
        onChange={async event => {
          let shouldSave = false;
          if (event.target.value) shouldSave = true;
          await onChangeTitle(event.target.value, section);
          if (shouldSave) onSave();
        }}
        fullWidth
      />
      {section.type === 'DRAWING' && (
        <IconButton
          disabled={!isAuth || isLoading}
          onClick={() => {
            onSelectSection(section);
            updateTemplateDialog(true);
          }}
          aria-label="More"
          color="inherit"
        >
          <Collections />
        </IconButton>
      )}

      <MorePopover
        disabled={!isAuth || isLoading}
        display={
          <div className={classes.popOverButtons}>
            <Button
              onClick={async () => {
                await onArchiveSection(section, rowNumber);
                onSaveSection();
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() =>
                updateReorderDialog({
                  show: true,
                  selectedSection: section,
                })
              }
            >
              Reorder
            </Button>
          </div>
        }
      />
    </div>
    {(() => {
      if (
        section.type === 'DRAWING' &&
        get(section, 'template.file.encodedFile')
      )
        return (
          <div className={classes.imageWrapper}>
            <img
              className={classes.image}
              alt="Template"
              src={`data:image/png;base64,${section.template.file.encodedFile}`}
            />
          </div>
        );
      if (section.type === 'DRAWING')
        return (
          <div className={classes.imageWrapper}>
            <div className={classes.content}>
              <Typography variant="subtitle1" className={classes.contentText}>
                DRAWING
              </Typography>
            </div>
          </div>
        );
      if (section.type === 'YESNO')
        return (
          <div className={classes.content}>
            <Typography variant="subtitle1" className={classes.contentText}>
              Yes No Question
            </Typography>
          </div>
        );
      if (section.type === 'NUMBERSCALE10')
        return (
          <div className={classes.content}>
            <Typography variant="subtitle1" className={classes.contentText}>
              Number Scale 1 - 10
            </Typography>
          </div>
        );
      if (section.type === 'NUMBERSCALE100')
        return (
          <div className={classes.content}>
            <Typography variant="subtitle1" className={classes.contentText}>
              Number Scale 0 - 100
            </Typography>
          </div>
        );

      if (section.type === 'TABLE')
        return (
          <div className={classes.content}>
            <Typography variant="subtitle1" className={classes.contentText}>
              Table
            </Typography>
          </div>
        );
      if (section.type === 'CHECKBOXES') {
        return (
          <div className={classes.checkboxesWrapper}>
            {checkboxesData.map((checkboxData, index) => {
              return (
                <div key={index} className={classes.content}>
                  <TextField
                    disabled={!isAuth || (isLoading && !isOptimisticResponse)}
                    className={classes.sectionTitle}
                    autoComplete="off"
                    placeholder={'Option Title'}
                    InputProps={{
                      className: isAuth ? classes.sectionTitleInput : null,
                    }}
                    value={checkboxData.title}
                    onChange={async event => {
                      onUpdateCheckboxData(index, event.target.value);
                      // let shouldSave = false;
                      // if (event.target.value) shouldSave = true;
                      // await onChangeTitle(event.target.value, section);
                      // if (shouldSave) onSave();
                    }}
                    fullWidth
                  />
                  <IconButton onClick={() => onDeleteCheckbox(index)}>
                    <RemoveCircle />
                  </IconButton>
                </div>
              );
            })}

            <div className={classes.addButtonContainer}>
              <IconButton onClick={onNewCheckbox}>
                <AddCircle />
              </IconButton>
            </div>
          </div>
        );
      }
      if (section.type === 'SPREADSHEET') {
        let spreadsheet;
        return (
          <div>
            <SpreadsheetComponent
              ref={ssObj => {
                spreadsheet = ssObj;
              }}
              created={() => {
                if (spreadsheetData && spreadsheetData.jsonObject) {
                  spreadsheet.openFromJson({
                    file: spreadsheetData.jsonObject,
                  });
                }
              }}
            />
            <br />
            <button
              onClick={() => {
                spreadsheet.saveAsJson().then(Json => {
                  console.log('Json', Json);
                  onUpdateSpreadsheetData(Json);
                });
              }}
            >
              Save
            </button>
          </div>
        );
      }

      return (
        <div className={classes.content}>
          <Typography variant="subtitle1" className={classes.contentText}>
            TEXT
          </Typography>
        </div>
      );
    })()}
  </div>
);

const styles = {
  column: {
    flex: 1,
    padding: 6,
  },
  templateTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  sectionTitleInput: {
    '&:before': {
      borderBottomColor: 'transparent',
    },
    '&:hover:before': {
      borderBottomColor: 'transparent !important',
    },
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 320,
  },
  checkboxesWrapper: {
    minWidth: 320,
  },
  addButtonContainer: {
    marginTop: 10,
    textAlign: 'center',
  },
  image: {
    height: 256,
    width: 224,
  },
  content: ({ section }) => ({
    border: `1px solid ${MINOR_BLACK}`,
    minHeight: section.type === 'DRAWING' ? 256 : 50,
    width: section.type === 'DRAWING' ? 256 : '100%',
    padding: section.type === 'CHECKBOXES' ? 10 : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  contentText: {
    color: MINOR_BLACK,
  },
  popOverButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const initCheckboxesData = [{ title: '', value: false }];
const initSpreadsheetData = {};

export default compose(
  withStyles(styles),
  withState('checkboxesData', 'updateCheckboxesData', initCheckboxesData),
  withState('spreadsheetData', 'updateSpreadsheetData', initSpreadsheetData),
  withHandlers({
    onNewCheckbox: ({ checkboxesData, updateCheckboxesData }) => () => {
      checkboxesData.push({ title: '', value: false });
      updateCheckboxesData(checkboxesData);
    },
    onDeleteCheckbox: ({
      checkboxesData,
      updateCheckboxesData,
      onChangeSectionCheckbox,
      section,
      onSave,
    }) => async index => {
      checkboxesData.splice(index, 1);
      updateCheckboxesData(checkboxesData);
      await onChangeSectionCheckbox(checkboxesData, section);
      onSave();
    },
    onUpdateCheckboxData: ({
      onChangeSectionCheckbox,
      checkboxesData,
      updateCheckboxesData,
      section,
      onSave,
    }) => async (index, value) => {
      checkboxesData[index] = { title: value, value: false };
      updateCheckboxesData(checkboxesData);
      await onChangeSectionCheckbox(checkboxesData, section);
      onSave();
    },
    onNewSpreadsheetColumn: ({
      spreadsheetData,
      updateSpreadsheetData,
    }) => () => {
      spreadsheetData.columns.push('');
      updateSpreadsheetData(spreadsheetData);
    },
    onDeleteSpreadsheetColumn: ({
      spreadsheetData,
      updateSpreadsheetData,
      onChangeSectionSpreadsheet,
      section,
      onSave,
    }) => async index => {
      spreadsheetData.columns.splice(index, 1);
      updateSpreadsheetData(spreadsheetData);
      await onChangeSectionSpreadsheet(spreadsheetData, section);
      onSave();
    },
    onUpdateSpreadsheetColumn: ({
      onChangeSectionSpreadsheet,
      spreadsheetData,
      updateSpreadsheetData,
      section,
      onSave,
    }) => async (index, value) => {
      spreadsheetData.columns[index] = value;
      updateSpreadsheetData(spreadsheetData);
      await onChangeSectionSpreadsheet(spreadsheetData, section);
      onSave();
    },
    onUpdateSpreadsheetData: ({
      onChangeSectionSpreadsheet,
      spreadsheetData,
      updateSpreadsheetData,
      section,
      onSave,
    }) => async data => {
      updateSpreadsheetData(data);
      await onChangeSectionSpreadsheet(data, section);
      console.log('onUpdateSpreadsheetData', data);
      onSave();
    },
  }),
  withPropsOnChange(
    ['onChangeSectionCheckbox'],
    ({ onChangeSectionCheckbox }) => ({
      onChangeSectionCheckbox: debounce(onChangeSectionCheckbox, 1000),
    })
  ),
  withPropsOnChange(
    ['onChangeSectionSpreadsheet'],
    ({ onChangeSectionSpreadsheet }) => ({
      onChangeSectionSpreadsheet: debounce(onChangeSectionSpreadsheet, 1000),
    })
  ),
  withPropsOnChange(['onSave'], ({ onSave }) => ({
    onSave: debounce(onSave, 1000),
  })),
  lifecycle({
    componentWillMount() {
      const {
        section,
        updateCheckboxesData,
        updateSpreadsheetData,
      } = this.props;
      if (section.type === 'CHECKBOXES') {
        if (Array.isArray(section.checkboxesData)) {
          updateCheckboxesData([...section.checkboxesData]);
        } else {
          updateCheckboxesData([...initCheckboxesData]);
        }
      }
      if (section.type === 'SPREADSHEET') {
        if (
          typeof section.spreadsheetData === 'object' &&
          section.spreadsheetData !== null &&
          !Array.isArray(section.spreadsheetData)
        ) {
          updateSpreadsheetData({ ...section.spreadsheetData });
        } else {
          updateSpreadsheetData({ ...initSpreadsheetData });
        }
      }
    },
  })
)(FormTemplateSection);

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ErrorMessage from '../../../components/ErrorMessage';
import { WARM_PINK } from '../../../style/constants';
import { FETCH_TRANSACTIONS } from '../gql';
import CsvDownload from '../../../components/CsvDownload';

const Transactions = ({ classes, currentLocationId, practiceEncodedId }) => {
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState('');
  const timezone = moment.tz.guess();

  const [requestTransactions, { error }] = useLazyQuery(FETCH_TRANSACTIONS, {
    variables: {
      locationId: currentLocationId,
      practiceEncodedId,
      start: moment()
        .startOf('day')
        .toISOString(),
      end: moment()
        .endOf('day')
        .toISOString(),
      timezone,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      setCsvData([]);
      setCsvName('');
      const data = _.get(res, 'fetchPaymentReport', []);
      if (data.length) {
        setCsvData(JSON.parse(data));
        setCsvName(
          `Daily Transactions Report - ${moment().format(
            'YYYY-MM-DD-HH:mm:ss'
          )}`
        );
      }
      return null;
    },
  });

  const handleRequestTransactions = () => {
    requestTransactions();
  };

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Grid item xs={3}></Grid>
      <Grid item xs={9}>
        <Button
          className={classes.warmPinkButton}
          onClick={handleRequestTransactions}
        >
          Generate Report
        </Button>
      </Grid>
      {csvData.length > 0 && csvName && (
        <CsvDownload data={csvData} filename={csvName} separator="," />
      )}
    </>
  );
};

const styles = {
  warmPinkButton: {
    padding: 10,
    height: 40,
    background: WARM_PINK,
    color: 'white',
    marginTop: 12,
  },
};

export default compose(
  withStyles(styles),
  connect(({ user, practice }) => ({
    currentLocationId: user.currentLocationId,
    practiceEncodedId: practice.id,
  }))
)(Transactions);

import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';

import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import client from '../../data/apollo/client';
import { persistor } from '../../data/redux/store';
import { userCleared } from '../../data/redux/actions/user';
import { practiceCleared } from '../../data/redux/actions/practice';
import { patientCleared } from '../../data/redux/actions/patient';
import { integrationCleared } from '../../data/redux/actions/integration';
import { PRIMARY } from '../../style/constants';
import Logo from '../../images/clinik-note-logo-pink.svg';

const LogoutPage = ({ classes }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    persistor.purge();
    dispatch({ type: 'RESET' });
    dispatch(userCleared());
    dispatch(practiceCleared());
    dispatch(patientCleared());
    dispatch(integrationCleared());
    client.resetStore();
    window.location.href = '/';
  }, []);

  return (
    <Grid
      component="main"
      container
      justifyContent="center"
      alignItems="center"
      className={classes.main}
    >
      <Grid component="section" container item className={classes.section}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <img src={Logo} className={classes.logo} alt="Clinik Note Logo" />
            <Typography variant="h5" component="h2">
              You are being logged out.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const styles = {
  main: {
    height: '100%',
    backgroundColor: PRIMARY,
  },
  section: {
    maxWidth: 448,
  },
  card: {
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 32,
  },
  logo: {
    height: '100%',
    width: '100%',
  },
};

export default compose(withStyles(styles))(LogoutPage);

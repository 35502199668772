import { gql } from '@apollo/client';

export const CREATE_CHECKOUT_SESSION = gql`
    query createStripeCheckoutSession($customerToken: String, $practiceId: String) {
        createStripeCheckoutSession(customerToken: $customerToken, practiceId: $practiceId) {
            id
            checkoutUrl
        }
    }
`;

export const GET_PLAN_DETAILS = gql`
    query getPlanDetails($planToken: String, $practiceId: String) {
        getPlanDetails(planToken: $planToken, practiceId: $practiceId) {
            planPrice
            planIndex
        }
    }
`;

export const CANCEL_SUBSCRIPTION = gql`
    query cancelSubscription($planToken: String, $practiceId: String) {
        cancelSubscription(planToken: $planToken, practiceId: $practiceId) {
            success
        }
    }
`;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { useQuery } from '@apollo/client';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import { WARM_PINK } from '../../style/constants';
import { PATIENT_NODE_QUERY } from './gql';

// Will be stored on backend as a JSON object
export const INVOICE_ACCOUNT_KEYS = [
  // { key: 'id', label: 'ID' },
  // { key: 'status', label: 'Status', default: 'ACTIVE', type: "select", options: ['ACTIVE', 'PRIMARY'] },
  // { key: 'billToClient', label: 'Bill to Client', editable: true },
  { key: 'billingName', label: 'Account Type*', editable: true },
  { key: 'billingAddress', label: 'Billing Address', editable: true },
  { key: 'billingPhone', label: 'Billing Phone', editable: true },
  { key: 'billingEmail', label: 'Billing Email', editable: true },
  {
    key: 'billingInsuranceCompany',
    label: 'Billing Insurance Company',
    editable: true,
  },
  { key: 'billingClaimNumber', label: 'Billing Claim Number', editable: true },
  { key: 'billingReferrer', label: 'Billing Referrer', editable: true },
  {
    key: 'billingDateInjury',
    label: 'Billing Date of Injury',
    type: 'date',
    editable: true,
  },
];

const CreateAccountDialog = ({
  classes,
  dialogAction,
  form,
  onCloseDialog,
  onFieldChange,
  patientName,
  patientId,
  setForm,
  showDialog,
}) => {
  const [patient, setPatient] = useState();

  const { loading, error } = useQuery(PATIENT_NODE_QUERY, {
    variables: {
      patientId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ node }) => {
      setPatient(node);
    },
  });

  const onCheckboxChange = e => {
    if (e.target.checked) {
      const patientDetails = {
        billingName: 'Personal',
        billingAddress: patient.address,
        billingEmail: patient.email,
        billingPhone: patient.phone,
      };
      setForm({ ...form, ...patientDetails, billToClient: true });
    } else {
      setForm({
        ...form,
        billToClient: false,
        billingName: '',
        billingAddress: '',
        billingEmail: '',
        billingPhone: '',
      });
    }
  };

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <Dialog open={showDialog} onClose={onCloseDialog} maxWidth="lg">
      <DialogTitle
        className={classes.title}
      >{`Creating new Invoice Account for ${patientName || ''}`}</DialogTitle>

      <DialogContent className={classes.modalContent}>
        <List>
          <ListItem className={classes.detailItemEditing}>
            <ListItemText
              className={classes.detailLabel}
              secondary="Bill to Client?"
            />

            <div className={classes.detailInput}>
              <Checkbox onChange={onCheckboxChange} />
            </div>
          </ListItem>
          {INVOICE_ACCOUNT_KEYS.map(element => (
            <ListItem
              className={classes.detailItemEditing}
              key={`list-item-${element.key}`}
            >
              <ListItemText
                className={classes.detailLabel}
                secondary={element.label}
              />

              {element.type === 'date' ? (
                <TextField
                  autoComplete="off"
                  className={classes.detailInput}
                  name={element.key}
                  onChange={onFieldChange}
                  type="date"
                  value={form[element.key] || ''}
                />
              ) : (
                <TextField
                  autoComplete="off"
                  id={element.key}
                  name={element.key}
                  className={classes.detailInput}
                  placeholder={element.placeholder}
                  // defaultValue={form[element.key] || ''}
                  onChange={onFieldChange}
                  value={form[element.key] || ''}
                />
              )}
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="primary" onClick={onCloseDialog}>
          Cancel
        </Button>

        <Button
          color="primary"
          className={classes.confirmButton}
          disabled={!form.billingName}
          onClick={async () => {
            dialogAction(form);
            onCloseDialog();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  confirmButton: {
    color: WARM_PINK,
  },
  checkbox: {
    flex: 1,
    paddingLeft: 8,
  },
  detailInput: {
    flex: 1,
    paddingLeft: 8,
  },
  detailItemEditing: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  detailLabel: {
    flex: 0.5,
  },
  modalContent: {
    padding: 32,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'space-between',
    width: '100%',
    height: '100%',
  },
  title: {
    textAlign: 'center',
  },
};

export default compose(withStyles(styles))(CreateAccountDialog);

import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

import { isValidEmail } from '../../utility/string';

import { PRIMARY, SECONDARY, WHITE } from '../../style/constants';
import FlashMessage from '../../components/FlashMessage';
import Logo from '../../images/clinik-note-logo-pink.svg';

const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($userEmail: String!) {
    resetPassword(userEmail: $userEmail) {
      id
    }
  }
`;

const ResetPasswordPage = ({ classes, dispatch }) => {
  const [userEmail, updateUserEmail] = useState('');
  const [userEmailError, updateUserEmailError] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [resetPassword, { data, loading, error }] = useMutation(
    RESET_PASSWORD_MUTATION,
    {
      variables: {
        userEmail,
      },
    },
    {
      onCompleted: (result) => {},
    }
  );

  const validateEmail = () => {
    if (!isValidEmail(userEmail)) {
      updateUserEmailError('Email address is invalid');
      return false;
    }
    updateUserEmailError('');
    return true;
  };

  return (
    <Grid
      component="main"
      container
      justifyContent="center"
      alignItems="center"
      className={classes.main}
    >
      <Grid component="section" container item className={classes.section}>
        <img src={Logo} className={classes.logo} alt="Clinik Note Logo" />

        <Grid
          component="form"
          container
          spacing={3}
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            // clear previous form errors, then validate
            const isValid = validateEmail(userEmail);

            if (isValid) {
              resetPassword();
            }
          }}
        >
          <div className={classes.fieldRow}>
            {error && (
              <FlashMessage className={classes.fullWidth} message={error} />
            )}

            {!error && data && data.resetPassword && data.resetPassword.id && (
              <FlashMessage
                className={classes.fullWidth}
                success
                message={
                  <span>
                    Submitted.
                    <br />
                    Please check your email for further instructions
                  </span>
                }
              />
            )}
          </div>

          <div className={classes.fieldRow}>
            <FormControl className={classes.fieldColumn}>
              <Input
                id="email"
                required
                autoComplete="off"
                disableUnderline
                placeholder="Email"
                value={userEmail || ''}
                className={classes.field}
                onChange={(e) => updateUserEmail(e.target.value)}
              />
              <FormLabel className={classes.fieldErrors}>
                {userEmailError}
              </FormLabel>
            </FormControl>
          </div>

          <div className={classes.fieldRow}>
            <FormControl className={classes.fieldColumn}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disableFocusRipple
                disableRipple
                classes={{ root: classes.button }}
              >
                {loading ? (
                  <CircularProgress
                    classes={{ root: classes.loading }}
                    size={28}
                  />
                ) : (
                  'Reset Password'
                )}
              </Button>
            </FormControl>
          </div>
          <div className={classes.fieldRow} style={{ marginTop: 20 }}>
            <FormControl className={classes.fieldColumn}>
              <Button
                onClick={() => {
                  window.location = process.env.REACT_APP_BASE_URL;
                }}
                variant="contained"
                color="secondary"
                disableFocusRipple
                disableRipple
                classes={{ root: classes.button }}
              >
                Back to Login
              </Button>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  main: {
    minHeight: '100%',
    backgroundColor: PRIMARY,
  },
  section: {
    maxWidth: 448,
  },
  logo: {
    width: '100%',
  },
  loading: {
    color: WHITE,
  },
  form: {
    padding: 32,
    maxWidth: 448,
  },
  fullWidth: {
    width: '100%',
  },
  fieldRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  fieldColumn: {
    flex: 1,
  },
  field: {
    backgroundColor: WHITE,
    flex: 1,
    height: 48,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 5,
    marginBottom: 8,
  },
  fieldErrors: {
    marginBottom: 15,
    color: SECONDARY,
  },
  button: {
    flex: 1,
    height: 48,
    fontSize: 18,
    fontWeight: 300,
    letterSpacing: 0.5,
    textTransform: 'capitalize',
  },
  error: {
    color: WHITE,
    paddingTop: 32,
    textAlign: 'center',
  },
};

export default compose(withStyles(styles))(ResetPasswordPage);

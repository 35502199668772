import React from 'react';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const ErrorMessage = ({ className, classes, error, text }) => {
  let newText;
  if (error && error.message) {
    newText = error.message;
    if (
      newText.includes('GraphQL error: ') ||
      newText.includes('Network error: ')
    ) {
      newText = error.message.slice(15);
    }
  } else if (text) {
    newText = text;
  } else {
    newText =
      'There has been an unexpected error, please reload the page or restart the app';
  }

  return (
    <Typography
      variant="body2"
      align="center"
      color="error"
      className={className || classes.text}
    >
      {newText}
    </Typography>
  );
};

const styles = {
  text: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: 28,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 28,
  },
};

export default withStyles(styles)(ErrorMessage);

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import Moment from 'moment';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import CalendarCarousel from './CalendarCarousel';
import CalendarDialog from './CalendarDialog';
import CalendarEventDetail from './CalendarEventDetail';

import './calendarPage.css';
import CalendarSchedule from './CalendarSchedule';
import { generateBusinessHours } from './utils';
import { LIST_APPOINTMENTS } from './gqlQueries';
import IntegrationErrorDialog from '../../components/IntegrationsDialogs/integrationErrorDialog';

const dayCalendar = React.createRef();

const CalendarPage = ({ classes, dispatch, locationId }) => {
    const todayStr = Moment();
    const [modalType, setModalType] = useState('');
    const [selectedDay, setSelectedDay] = useState(todayStr.format('YYYY-MM-DD'));
    const [selectedEnd, setSelectedEnd] = useState(todayStr.format('YYYY-MM-DD'));
    const [selectedClinicianId, setSelectedClinicianId] = useState();
    const [anchorEl, setAnchorEl] = useState(document.getElementById('calendar-carousel-element'));
    const [currentAppointment, setCurrentAppointment] = useState({});
    const [appointmentId, setAppointmentId] = useState(window.location.pathname.split('/')[3]);
    const [resources, setResources] = useState([]);
    const [fetchClinician, setFetchClinician] = useState([]);
    const [listAppointment, setListAppointment] = useState([]);
    const [closures, setClosures] = useState([]);
    const [operatingHours, setOperatingHours] = useState([]);
    const [currentView, setCurrentView] = useState(sessionStorage.getItem('dayWeekView') || 'resourceTimeGridWeek');
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [openingTime, setOpeningTime] = useState('');
    const [closingTime, setClosingTime] = useState('');

    const [getAppointments] = useLazyQuery(LIST_APPOINTMENTS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            if (res.listAppointments) {
                let combinedList = res.listAppointments;
                if (res.listEvents && res.listEvents.length) {
                    combinedList = [...combinedList, ...res.listEvents];
                }
                setListAppointment(combinedList);
            }
            if (res.fetchLocation) {
                const location = _.get(res, 'fetchLocation.0');
                setOpenWindow(location);
                if (location) {
                    setOperatingHours(location);
                    const holidays = JSON.parse(location.activeClosures);
                    if (holidays) {
                        const holidayDays = holidays.map((day) => ({
                            ...day,
                            start: Moment(day.start).utc().toISOString(),
                            end: Moment(day.end).utc().toISOString(),
                            title: `Closed - ${day.name}`,
                            eventTextColor: '#ff0000',
                            display: 'background',
                            backgroundColor: '#f00',
                            // '__typename': 'Event'
                        }));
                        setClosures(holidayDays);
                    }
                }
            }
            if (res.fetchClinician) {
                const resourcesArray = res.fetchClinician.map((clinician) => {
                    return {
                        ...clinician,
                        title: clinician.name,
                        businessHours: generateBusinessHours(
                            clinician.availability || [],
                            JSON.parse(clinician.blockedOut),
                            _.get(res, 'fetchLocation.0', {})
                        ),
                    };
                });
                setFetchClinician(resourcesArray);
            }
        },
        variables: {
            locationId,
            start: Moment(selectedDay),
        },
    });

    useEffect(() => {
        const interval = setInterval(() => {
            getAppointments();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getAppointments();
    }, []);

    useEffect(() => {
        if (appointmentId) {
            handleEventClick();
        }
    }, [appointmentId]);

    useEffect(() => {
        handleRefreshEventDetail();
    }, [appointmentId, listAppointment]);

    // After clinicians are fetched, parse them to appear in the FullCalendar view
    useEffect(() => {
        if (fetchClinician && operatingHours) {
            const existingFilterSettings = sessionStorage.getItem('clinicianFilter');

            const foundClinician = fetchClinician.find((clinician) => clinician.id === existingFilterSettings);

            if (foundClinician) {
                setResources([foundClinician]);
            } else {
                setResources(fetchClinician);
            }
        }
    }, [fetchClinician, operatingHours, selectedClinicianId]);

    const convertTimeStringToNumber = (timeString) => {
        const timeSplit = timeString.split(':');

        let response = 0;

        response += parseInt(timeSplit[0] * 60);
        response += parseInt(timeSplit[1]);

        return response;
    };

    const convertNumberToTimeString = (timeNumber) => {
        const hour = Math.floor(timeNumber / 60);
        const minute = timeNumber % 60;

        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    const setOpenWindow = (location) => {
        let openingTimes = [];
        let closingTimes = [];

        if (location.mon) {
            openingTimes.push(convertTimeStringToNumber(location.mon[0]));
            closingTimes.push(convertTimeStringToNumber(location.mon[1]));
        }

        if (location.tue) {
            openingTimes.push(convertTimeStringToNumber(location.tue[0]));
            closingTimes.push(convertTimeStringToNumber(location.tue[1]));
        }

        if (location.wed) {
            openingTimes.push(convertTimeStringToNumber(location.wed[0]));
            closingTimes.push(convertTimeStringToNumber(location.wed[1]));
        }

        if (location.thu) {
            openingTimes.push(convertTimeStringToNumber(location.thu[0]));
            closingTimes.push(convertTimeStringToNumber(location.thu[1]));
        }

        if (location.fri) {
            openingTimes.push(convertTimeStringToNumber(location.fri[0]));
            closingTimes.push(convertTimeStringToNumber(location.fri[1]));
        }

        if (location.sat) {
            openingTimes.push(convertTimeStringToNumber(location.sat[0]));
            closingTimes.push(convertTimeStringToNumber(location.sat[1]));
        }

        if (location.sun) {
            openingTimes.push(convertTimeStringToNumber(location.sun[0]));
            closingTimes.push(convertTimeStringToNumber(location.sun[1]));
        }

        openingTimes.push(420); // 07:00
        closingTimes.push(1200); // 22:00

        const openingTime = convertNumberToTimeString(Math.min(...openingTimes));
        const closingTime = convertNumberToTimeString(Math.max(...closingTimes));

        setOpeningTime(openingTime);
        setClosingTime(closingTime);
    };

    const changeCalendarView = (viewString) => {
        if (dayCalendar.current) {
            dayCalendar.current.getApi().changeView(viewString);
            sessionStorage.setItem('dayWeekView', viewString);
        }
    };

    const handleChange = (e) => {
        setCurrentAppointment({
            ...currentAppointment,
            [e.target.name]: e.target.value,
        });
    };

    const handleDateSelect = (selectInfo) => {
        if (dayCalendar.current) {
            dayCalendar.current.getApi().changeView(currentView, selectInfo.startStr);
            setSelectedDay(selectInfo.startStr);
            /*sessionStorage.setItem('selectedDay', selectInfo.startStr);*/
        }
    };

    const handleEventClick = (event) => {
        setAnchorEl(document.getElementById('calendar-carousel-element'));
    };

    const handleRefreshEventDetail = () => {
        const foundAppt = listAppointment && listAppointment.find((appt) => appt.id === appointmentId);

        // if the URL's apptId is valid, set it as the "current appt"
        if (foundAppt) {
            setCurrentAppointment(foundAppt);
            setSelectedClinicianId(foundAppt.clinician.id);
        }
        // else if there are appointments found AND apptId could not be found,
        // route back to /diary
        else if (Array.isArray(listAppointment) && appointmentId) {
            // eslint-disable-next-line no-console
            console.log('Error: appointment not found! Double check the ID exists.');
            window.history.pushState(null, null, '/diary');
        } else {
            setAppointmentId(null);
            setCurrentAppointment({});
        }
    };

    if (!locationId) {
        return (
            <p
                style={{
                    color: 'red',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}>
                Practice location not found! <br /> Please add a location in the Settings page to access diary.
            </p>
        );
    }

    return (
        <div className={classes.calendarContainer} id='calendar-carousel-element'>
            {!collapseSidebar && <CalendarCarousel handleDateSelect={handleDateSelect} todayStr={todayStr.format('YYYY-MM-DD')} />}

            <div className={classes.schedulerContainer}>
                <CalendarSchedule
                    changeCalendarView={changeCalendarView}
                    closures={closures}
                    currentView={currentView}
                    dayCalendar={dayCalendar}
                    handleDateSelect={handleDateSelect}
                    handleEventClick={handleEventClick}
                    listAppointments={listAppointment}
                    operatingHours={operatingHours}
                    listClinicians={fetchClinician}
                    resources={resources}
                    selectedDay={selectedDay}
                    selectedClinicianId={selectedClinicianId}
                    setAppointmentId={setAppointmentId}
                    setCurrentView={setCurrentView}
                    setSelectedDay={setSelectedDay}
                    setSelectedEnd={setSelectedEnd}
                    setSelectedClinicianId={setSelectedClinicianId}
                    setModalType={setModalType}
                    timeNow={todayStr.format('HH:mm:SS')}
                    collapseSidebar={collapseSidebar}
                    setCollapseSidebar={setCollapseSidebar}
                    openingTime={openingTime}
                    closingTime={closingTime}
                />

                {currentAppointment.id && (
                    <CalendarEventDetail
                        anchorEl={anchorEl}
                        dispatch={dispatch}
                        onClose={() => {
                            setAnchorEl(null);
                            window.history.pushState(null, null, '/diary/');
                            setAppointmentId(null);
                        }}
                        open={!!anchorEl}
                        setModalType={setModalType}
                        appointment={currentAppointment}
                        listClinicians={fetchClinician}
                    />
                )}

                {modalType === 'create' && (
                    <CalendarDialog
                        onCloseDialog={() => {
                            setModalType('');
                            setCurrentAppointment({});
                        }}
                        clinicianOptions={fetchClinician}
                        existingAppt={currentAppointment}
                        selectedDay={selectedDay}
                        selectedEnd={selectedEnd}
                        selectedClinicianId={selectedClinicianId}
                        setExistingAppt={handleChange}
                        showDialog={modalType === 'create'}
                    />
                )}

                {modalType === 'edit' && (
                    <CalendarDialog
                        clinicianOptions={fetchClinician}
                        existingAppt={currentAppointment}
                        onCloseDialog={() => {
                            setModalType('');
                            setCurrentAppointment({});
                        }}
                        // selectedEnd={selectedEnd}
                        showDialog={modalType === 'edit'}
                    />
                )}

                {modalType === 'integrationPresentError' && (
                    <IntegrationErrorDialog
                        onCloseDialog={() => {
                            setModalType('');
                        }}
                        showDialog={modalType === 'integrationPresentError'}
                        dialogTitle='Diary Locked'
                        dialogText='You cannot create a new appointment on this screen because you have an active Mindbody integration. Please create new appointments via Mindbody'
                    />
                )}
            </div>
        </div>
    );
};

const styles = {
    main: {
        paddingTop: 64,
        paddingLeft: 91,
        paddingRight: 0,
        paddingBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && 'calc(100vh - 32px)',
        background: 'white',
    },
    calendarContainer: {
        display: 'flex',
    },
    field: {
        width: 146,
        height: 30,
    },
    schedulerContainer: {
        minWidth: 800,
        width: '100%',
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, practice }) => ({
        practiceId: practice.id,
        locationId: user.currentLocationId,
        locationName: user.currentLocationName,
    }))
)(CalendarPage);

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import BaseSettingsPage from './Base';
import { UPSERT_LOCATION, FETCH_LOCATION } from './gql';
import { WARM_PINK } from '../../style/constants';
import TaxRateCard from './Region/TaxRateCard';
import Restricted from '../../components/Restricted';
import CustomTooltip from '../../components/CustomTooltip';

const TITLE =
  'Add payment details such as your bank details or terms which you would like to appear on your invoices.';

const InvoiceSettings = ({
  classes,
  isAdmin,
  locationId,
  practiceEncodedId,
}) => {
  const [invoiceDialogSettings, setInvoiceDialogSettings] = useState(false);
  const [invoiceFields, setInvoiceFields] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  // Required for later currency work
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  useQuery(FETCH_LOCATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.fetchLocation && res.fetchLocation.length) {
        const {
          paymentLine1,
          paymentLine2,
          paymentLine3,
        } = res.fetchLocation[0];
        setInvoiceFields({
          'Payment Line 1': paymentLine1,
          'Payment Line 2': paymentLine2,
          'Payment Line 3': paymentLine3,
        });
      }
    },
    variables: {
      id: locationId,
      practiceEncodedId,
    },
  });

  const [upsertLocation] = useMutation(UPSERT_LOCATION, {
    variables: {
      id: locationId,
      paymentLine1: invoiceFields['Payment Line 1'],
      paymentLine2: invoiceFields['Payment Line 2'],
      paymentLine3: invoiceFields['Payment Line 3'],
      practiceEncodedId,
    },
    onCompleted: res => {
      if (res.upsertLocation) {
        setSuccessMessage('Settings updated successfully');
      }
    },
    onError: error => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const handleCloseDialog = () => {
    setInvoiceDialogSettings(false);
  };

  const handleSave = () => {
    upsertLocation();
    setInvoiceDialogSettings(false);
  };

  return (
    <BaseSettingsPage>
      {successMessage && (
        <Paper>
          <Alert severity="success">{successMessage}</Alert>
        </Paper>
      )}
      <Grid
        className={classes.content}
        container
        direction="column"
        wrap="nowrap"
        item
      >
        <div className={classes.header}>
          <Typography variant="subtitle1" className={classes.subheading}>
            Invoice Content
          </Typography>
          <CustomTooltip hidden={!isAdmin} title={TITLE} />
        </div>
        <Grid className={classes.subSection}>
          <TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Text</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceFields &&
                  Object.keys(invoiceFields).map(key => (
                    <TableRow
                      hover
                      onClick={() => {
                        // setSelectedAppointmentType(item);
                        setInvoiceDialogSettings(true);
                      }}
                      key={`table-row-${key}`}
                    >
                      <TableCell>{key}</TableCell>
                      <TableCell>{invoiceFields[key]}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <TaxRateCard />
      {invoiceDialogSettings && (
        <Restricted isAllowedTo="upsertLocation">
          <Dialog
            open={invoiceDialogSettings}
            onClose={handleCloseDialog}
            className={classes.dialogContainer}
          >
            <DialogTitle id="form-dialog-title">
              Edit Invoice Fields
            </DialogTitle>

            <DialogContent>
              {invoiceFields &&
                Object.keys(invoiceFields).map(key => {
                  return (
                    <TextField
                      className={classes.field}
                      type="text"
                      key={`text-field-${key}`}
                      label={key}
                      fullWidth
                      autoComplete="off"
                      margin="normal"
                      // defaultValue={appointmentType.code}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={event => {
                        const clonedOpHours = {
                          ...invoiceFields,
                          [key]: event.target.value,
                        };
                        setInvoiceFields(clonedOpHours);
                        // setIsUniqueInput(true);
                      }}
                      placeholder={key}
                      value={invoiceFields[key]}
                    />
                  );
                })}
            </DialogContent>

            <DialogActions>
              <div className={classes.buttonContainer}>
                <Button
                  onClick={() => {
                    setInvoiceDialogSettings(false);
                  }}
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  onClick={handleSave}
                  // disabled={
                  //   invalidInput.length > 0 ||
                  //   _.isEqual(appointmentType, {}) ||
                  //   !isUniqueInput
                  // }
                >
                  SAVE
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </Restricted>
      )}
    </BaseSettingsPage>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  subheading: {
    fontWeight: 'bold',
  },
  colorIcon: {
    width: 20,
    height: 20,
    border: 'solid 1px #e0e0e0',
  },
  subSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  redButton: {
    height: 36,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
};

export default compose(
  withStyles(styles),
  connect(({ user, practice }) => ({
    locationId: user.currentLocationId,
    currentLocationName: user.currentLocationName,
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
    isConsentFormSubscription: user.isConsentFormSubscription,
    practiceEncodedId: practice.id,
    user,
  }))
)(InvoiceSettings);

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const SubCategoryDialog = ({
  subCategoryDialog,
  updateSubCategoryDialog,
  subcategoryName,
  updateSubcategoryName,
  onCreateSubcategory,
  editingSubcategory,
}) => {
  return (
    <Dialog
      open={subCategoryDialog}
      onClose={() => {
        updateSubCategoryDialog(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {editingSubcategory
          ? 'Update sub-category name'
          : 'Add new sub-category'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1">
          Templates can be assigned to one or more sub-categories.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="off"
          label="Name"
          fullWidth
          defaultValue={editingSubcategory ? editingSubcategory.name : ''}
          onChange={e => {
            updateSubcategoryName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateSubCategoryDialog(false);
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onCreateSubcategory(subcategoryName);
          }}
          color="primary"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const apiHandlers = {
  onCreateSubcategory: ({
    subcategoryName,
    onCreateNewSubCategory,
    editingSubcategory,
  }) => () => {
    onCreateNewSubCategory(
      subcategoryName,
      editingSubcategory ? editingSubcategory.id : undefined
    );
  },
};

export default compose(
  connect(({ practice, user }) => ({
    practiceId: practice.id,
  })),
  withState('subcategoryName', 'updateSubcategoryName', undefined),
  withHandlers(apiHandlers)
)(SubCategoryDialog);

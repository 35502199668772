import React from 'react';
import { compose } from 'recompose';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Logo from '../../images/clinik-note-logo-pink.svg';

const BookingErrorPage = ({ classes }) => {
  return (
    <Grid component="main" className={classes.main} container justifyContent="center">
      <Grid
        className={classes.content}
        container
        direction="column"
        wrap="nowrap"
        item
        xs={12}
        sm={11}
        md={10}
        lg={8}
        xl={6}
      >
        <img src={Logo} className={classes.logo} alt="Clinik Note Logo" />
        <Typography className={classes.title} variant="h6" component="h1">
          Error
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
          variant="subtitle2"
        >
          You have encountered an error please check the link provided
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: '350px',
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 88,
    paddingRight: 44,
  },
  logo: {
    marginBottom: 22,
    height: 89,
  },
  content: {
    border: 'solid 1px #e6e6e6',
    background: '#ffffff',
    maxWidth: 650,
  },
  title: {
    textAlign: 'center',
  },
};

export default compose(withStyles(styles))(BookingErrorPage);

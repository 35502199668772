import React, { useEffect, useState } from 'react';

import { compose } from 'recompose';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const CompleteIntegrationDialog = ({ classes, onCloseDialog, showDialog, locations, saveMindbodyLocation }) => {
    const [mindbodyLocation, setMindbodyLocation] = useState({});
    const [dialogState, setDialogState] = useState('');

    useEffect(() => {
        if (locations == null) {
            setDialogState('');
        } else if (locations.length > 1) {
            setMindbodyLocation(locations[0]);
            setDialogState('selectMindbodyLocation');
        } else if (locations.length === 1) {
            selectMindbodyLocation(locations[0]);
        }
    }, [locations]);

    const handleLocationChange = (event) => {
        for (let i = 0; i <= locations.length; i += 1) {
            if (locations[i].id === event.target.value) {
                setMindbodyLocation(locations[i]);
                break;
            }
        }
    };

    const setCompleteIntegration = () => {
        setDialogState('complete');
        setTimeout(() => {
            window.location.reload();
        }, 8000);
    };

    const selectMindbodyLocation = (mbLocation) => {
        console.log('Selecting Mindbody Location', mbLocation);
        saveMindbodyLocation(mbLocation);
        setCompleteIntegration();
    };

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} maxWidth='lg'>
            <DialogTitle>Mindbody Integration</DialogTitle>

            {dialogState === 'selectMindbodyLocation' && (
                <DialogContent>
                    <Typography className={classes.dialogContent}>Please select your Mindbody location</Typography>
                    <br />
                    <Select
                        labelId='mindbody-location-label'
                        id='mindbody-location'
                        value={mindbodyLocation.id}
                        label='Mindbody Location'
                        fullWidth
                        onChange={handleLocationChange}>
                        {locations.map((location) => {
                            return (
                                <MenuItem key={location.id} value={location.id}>
                                    {location.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </DialogContent>
            )}

            {dialogState === 'complete' && (
                <DialogContent className={classes.centerContent}>
                    <Typography className={classes.dialogContent}>
                        Congratulations, your integration is now underway. Please be patient while we sync your data from Mindbody.
                    </Typography>
                    <FontAwesomeIcon icon={faSpinner} spin size='lg' className={classes.loadingSpinner} />
                </DialogContent>
            )}

            {dialogState === 'selectMindbodyLocation' && (
                <DialogActions>
                    <Button
                        color='primary'
                        onClick={async () => {
                            onCloseDialog('cancel');
                        }}>
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        onClick={() => {
                            selectMindbodyLocation(mindbodyLocation);
                        }}>
                        Ok
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

const styles = {
    modalContent: {
        padding: 32,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'space-between',
        width: '100%',
        height: '100%',
    },
    title: {
        width: 100,
    },
    selectField: {
        width: '100%',
        height: 500,
    },
    field: {
        width: '100%',
    },
    dialogContent: {
        width: 600,
        alignItems: 'center',
        textAlign: 'center',
    },
    centerContent: {
        textAlign: 'center',
        alignItems: 'center',
    },
    loadingSpinner: {
        marginTop: 10,
        marginBottom: 10,
    },
};

export default compose(withStyles(styles))(CompleteIntegrationDialog);

import React from 'react';
import { compose } from 'recompose';
import { gql, useQuery } from '@apollo/client';

import { Typography, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { BOOKING_STATUS } from './constants';
import Logo from '../../images/clinik-note-logo-pink.svg';

export const FETCH_PRACTICE_BY_ID = gql`
  query fetchPracticeById($id: ID!) {
    fetchPracticeById(id: $id) {
      isSubscriptionValid
      logo {
        id
        encodedFile
      }
    }
  }
`;

const StatusBar = ({ classes, activeTab }) => {
  // Get practice Id from URL params and fetch the logo from the backend
  const { practiceId } = useParams();
  const { data: practiceData } = useQuery(FETCH_PRACTICE_BY_ID, {
    variables: {
      id: practiceId,
    },
  });

  // If subscription is Invalid then redirect to the error page
  if (
    practiceData &&
    practiceData.fetchPracticeById &&
    !practiceData.fetchPracticeById.isSubscriptionValid
  ) {
    window.location.href = '/booking-error';
  }

  return (
    <div className={classes.contentContainer}>
      <div className={classes.logoContainer}>
        {practiceData && practiceData.fetchPracticeById.logo && (
          <img
            src={`data:image/png;base64,${practiceData.fetchPracticeById.logo.encodedFile}`}
            className={classes.logo}
            alt="Clinik Note Logo"
          />
        )}
        <a href="https://cliniknote.com/">
          <div className={classes.flexbox}>
            <Typography variant="caption" style={{ color: '#000000' }}>
              Powered By:{' '}
            </Typography>
            <img src={Logo} alt="Clinik Note Logo" height="100" width="100" />
          </div>
        </a>
      </div>

      <div className={classes.fieldContainer}>
        {BOOKING_STATUS.map((element, index) => (
          <Typography
            variant="subtitle2"
            color={index === activeTab ? 'initial' : 'textSecondary'}
            key={`status-subtitle-${element}`}
          >
            {element}
          </Typography>
        ))}
      </div>

      <LinearProgress
        style={{ backgroundColor: '#f7f7f7' }}
        color="secondary"
        variant="determinate"
        // Hardcoded status numbers
        value={[12, 51, 91, 100][activeTab]}
      />
    </div>
  );
};

const styles = {
  contentContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'start',
    marginBottom: 37,
    maxWidth: 650,
    // height: '100%',
  },
  logo: {
    marginBottom: 22,
    height: 200,
    width: 200,
    alignSelf: 'center',
    objectFit: 'contain',
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 11px 11px',
    width: '100%',
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 22,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
};

export default compose(withStyles(styles))(StatusBar);

import { gql } from '@apollo/client';

const FETCH_CLINICIAN = gql`
  query fetchClinician($locationId: ID!, $id: ID) {
    fetchClinician(locationId: $locationId, id: $id) {
      id
      name
      availability
      blockedOut
    }
  }
`;

export default FETCH_CLINICIAN;

// action types

export const PRACTICE_SELECTED = 'PRACTICE_SELECTED';
export const PRACTICE_SEARCHED = 'PRACTICE_SEARCHED';
export const PRACTICE_REFETCH_TOGGLE = 'PRACTICE_REFETCH_TOGGLE';
export const PRACTICE_CLEARED = 'PRACTICE_CLEARED';

// action creators

export const practiceSelected = ({ id, appointmentTimeSlot, name, locations }) => ({
  type: PRACTICE_SELECTED,
  id,
  appointmentTimeSlot,
  name,
  locations
});

export const practiceSearched = (searchText, includeArchived = false) => ({
  type: PRACTICE_SEARCHED,
  searchText,
  includeArchived,
});

export const practiceRefetchToggle = (key, boolean) => ({
  type: PRACTICE_REFETCH_TOGGLE,
  [key]: boolean,
});

export const practiceCleared = () => ({ type: PRACTICE_CLEARED });

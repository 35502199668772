import React from 'react';
import { compose, withState } from 'recompose';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

const TemplateCategories = ({
  classes,
  selectedCategory,
  updateSelectedCategory,
}) => {
  return (
    <div className={classes.categoryContainer}>
      <Chip
        label="Drawing images"
        clickable
        color="primary"
        className={classes.categoryChip}
        variant={selectedCategory === 0 ? 'default' : 'outlined'}
        onClick={() => {
          updateSelectedCategory(0);
        }}
      />
      <Chip
        label="Form templates"
        clickable
        color="primary"
        className={classes.categoryChip}
        variant={selectedCategory === 1 ? 'default' : 'outlined'}
        onClick={() => {
          updateSelectedCategory(1);
        }}
      />
      <Chip
        label="Consent form templates"
        clickable
        color="primary"
        className={classes.categoryChip}
        variant={selectedCategory === 2 ? 'default' : 'outlined'}
        onClick={() => {
          updateSelectedCategory(2);
        }}
      />
    </div>
  );
};

const PreCreateTemplateDialog = ({
  classes = {},
  categorySelection,
  updateCategorySelection,
  onCreateTemplateCategorySelected,
  subcategories,
  selectedCategory,
  updateSelectedCategory,
  selectedSubCategory,
  updateSelectedSubCategory,
}) => {
  return (
    <Dialog
      open={categorySelection}
      onClose={() => {
        updateCategorySelection(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Select category and subcategory for the template
      </DialogTitle>
      <DialogContent>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Categories</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TemplateCategories
              classes={classes}
              selectedCategory={selectedCategory}
              updateSelectedCategory={updateSelectedCategory}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="h6">Sub-categories</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexWrap="wrap">
              {subcategories &&
                subcategories.map(subcate => {
                  return (
                    <Chip
                      color="primary"
                      variant={
                        selectedSubCategory === subcate.id
                          ? 'default'
                          : 'outlined'
                      }
                      className={classes.categoryChipDialog}
                      key={subcate.key}
                      label={subcate.name}
                      clickable
                      onClick={() => {
                        updateSelectedSubCategory(subcate.id);
                      }}
                    />
                  );
                })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateCategorySelection(false);
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onCreateTemplateCategorySelected(
              selectedCategory,
              selectedSubCategory
            );
            updateSelectedCategory(undefined);
            updateSelectedSubCategory(undefined);
          }}
          color="primary"
        >
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  withState('selectedCategory', 'updateSelectedCategory', undefined),
  withState('selectedSubCategory', 'updateSelectedSubCategory', undefined)
)(PreCreateTemplateDialog);

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  const { disabled } = props;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={!!disabled}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const YesNoSection = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onFocus,
  onSave,
  section,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  onChange,
}) => (
  <Grid container direction="column">
    <Typography variant="body1" className={classes.title}>
      {section.title}
    </Typography>
    <IOSSwitch
      InputProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      disabled={!canEdit || initialSave}
      onChange={onChange}
      checked={!!section.yesNoValue}
    />
  </Grid>
);

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles),
  withHandlers({
    onChange: ({ section, onSave, onChangeYesNoValue }) => async event => {
      onChangeYesNoValue(event.target.checked, section);
      onSave();
    },
  })
)(YesNoSection);

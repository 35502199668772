import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import ErrorMessage from '../../../components/ErrorMessage';
import { WARM_PINK } from '../../../style/constants';
import { REQUEST_BIRTHDAY_LIST } from '../gql';
import CsvDownload from '../../../components/CsvDownload';

const MONTHS = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

const ReportsPage = ({ classes, practiceEncodedId }) => {
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState('');
  const [birthdayMonth, setBirthdayMonth] = useState(moment().format('MM'));
  const [showAlert, setShowAlert] = useState(false);

  const [requestBirthdays, { error }] = useLazyQuery(REQUEST_BIRTHDAY_LIST, {
    variables: {
      birthdayMonth,
      practiceEncodedId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      setCsvData([]);
      setCsvName('');
      const data = _.get(res, 'node.patients.nodes', []);
      if (data.length) {
        setCsvData(data);
        setCsvName(
          `Birthday Report - ${
            MONTHS.find(month => month.value === birthdayMonth).label
          } - ${moment().format('YYYY-MM-DD-HH:mm:ss')}`
        );
      } else {
        setShowAlert(true);
      }
      return null;
    },
  });

  const handleRequestBirthdays = () => {
    requestBirthdays();
    setShowAlert(false);
  };

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Grid item xs={3}>
        <Typography className={classes.descriptor}>Select Month</Typography>
      </Grid>
      <Grid item xs={9}>
        <Select className={classes.field} defaultValue={birthdayMonth}>
          {MONTHS.map(month => (
            <MenuItem
              key={month}
              value={month.value}
              onClick={() => setBirthdayMonth(month.value)}
            >
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={9}>
        <Button
          className={classes.warmPinkButton}
          onClick={handleRequestBirthdays}
        >
          Generate Report
        </Button>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={9}>
        {showAlert && (
          <Alert severity="warning">
            No upcoming birthdays for this month.
          </Alert>
        )}
      </Grid>
      {csvData.length > 0 && csvName && (
        <CsvDownload data={csvData} filename={csvName} separator="," />
      )}
    </>
  );
};

const styles = {
  descriptor: {
    marginTop: 6,
  },
  field: {
    width: '100%',
  },
  warmPinkButton: {
    padding: 10,
    height: 40,
    background: WARM_PINK,
    color: 'white',
    marginTop: 12,
    marginBottom: 12,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice }) => ({
    practiceEncodedId: practice.id,
  }))
)(ReportsPage);

import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';
import Jimp from 'jimp';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import TemplateImage from '../../../components/TemplateImage/templateImage';

const ImageTemplateDialog = ({
    classes,
    imageTemplateDialogOpen,
    updateImageTemplateDialogOpen,
    onUploadTemplate,
    groupTemplates,
    templateFiles,
    updateTemplateFiles,
}) => {
    return (
        <Dialog
            open={imageTemplateDialogOpen}
            onClose={() => {
                updateImageTemplateDialogOpen(false);
            }}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>
                {groupTemplates && groupTemplates.length ? 'Preview Image Template' : 'New Image Template'}
            </DialogTitle>
            <DialogContent>
                {groupTemplates &&
                    groupTemplates.length &&
                    groupTemplates
                        .sort((a, b) => a.pageNumber < b.pageNumber)
                        .map((template) => {
                            return (
                                <Card className={classes.card}>
                                    <CardActionArea>
                                        <TemplateImage
                                            fileId={template.file.id}
                                            onImageLoaded={(imageData) => {
                                                if (templateFiles.filter((file) => file.id === imageData.id).length === 0)
                                                    updateTemplateFiles([...templateFiles, imageData]);
                                            }}
                                        />

                                        <CardContent>
                                            <Typography gutterBottom variant='h6' component='h2'>
                                                {template.name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            );
                        })}
                {(!groupTemplates || !groupTemplates.length) && (
                    <Typography variant='body1'>
                        Please note: template loader only accepts .jpgs, .jpegs, and .png files. <br />
                        If you have a multi-page template, please split them into separate image files and select all pages when uploading. <br />
                        The order of a multi-page template is determined by how they are ordered within the file picker (top - bottom = first - last).{' '}
                        <br />
                        Maximum of 5 pages allowed for a multi-page drawing note.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        updateImageTemplateDialogOpen(false);
                    }}
                    color='secondary'>
                    Cancel
                </Button>

                {(!groupTemplates || !groupTemplates.length) && (
                    <form
                        name='uploadForm'
                        style={{
                            padding: 10,
                        }}>
                        <label htmlFor='file-input'>
                            <Button component='span' color='default' startIcon={<CloudUploadIcon />} variant='contained' size='small'>
                                Upload
                            </Button>
                            <input
                                style={{ display: 'none' }}
                                id='file-input'
                                type='file'
                                accept='.jpg,.jpeg,.png'
                                multiple
                                onChange={(event) => onUploadTemplate(event.target.files)}
                            />
                        </label>
                    </form>
                )}
            </DialogActions>
        </Dialog>
    );
};

export const templateListQuery = gql`
    query templateList($practiceId: ID!) {
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                templates {
                    id
                    name
                    deletedAt
                    pageNumber
                    file {
                        id
                    }
                    group {
                        id
                    }
                }
            }
        }
    }
`;

const upsertTemplateMutation = gql`
    mutation upsertTemplate(
        $id: ID
        $practice: ID!
        $name: String
        $encodedFile: String
        $deletedAt: DateTime
        $pageNumber: Int
        $groupId: ID
        $sharableTemplate: Boolean
        $templateCategory: [ID]
    ) {
        upsertTemplate(
            id: $id
            practice: $practice
            name: $name
            encodedFile: $encodedFile
            deletedAt: $deletedAt
            pageNumber: $pageNumber
            group: $groupId
            sharableTemplate: $sharableTemplate
            templateCategory: $templateCategory
        ) {
            template {
                id
                name
                templateCategory
                sharableTemplate
                file {
                    id
                    encodedFile
                }
            }
        }
    }
`;

const upsertGroupMutation = gql`
    mutation upsertGroup($practiceId: ID!) {
        upsertGroup(practice: $practiceId) {
            group {
                id
            }
        }
    }
`;
const localHandlers = {
    onUploadTemplate:
        ({ onCreateGroup, onCreateTemplate, practiceId, subcategoryIdToCreate }) =>
        async (files) => {
            let groupId = null;
            if (files.length > 1) {
                const response = await onCreateGroup({ practiceId });
                groupId = response.data.upsertGroup.group.id;
            }
            const filesArray = Array.from(files);
            filesArray.forEach((file, i) => onCreateTemplate(file, i + 1, groupId));
        },
};

const apiHandlers = {
    onCreateGroup:
        ({ upsertGroup, updateFileLoading, practiceId }) =>
        async () => {
            updateFileLoading(true);

            let response;

            try {
                response = await upsertGroup({ practiceId });
                updateFileLoading(false);
            } catch (error) {
                console.log('API Error: ', error); // eslint-disable-line
                updateFileLoading(false);
            }

            return response;
        },
    onCreateTemplate:
        ({ upsertTemplate, updateFileLoading, practiceId, subcategoryIdToCreate, onAdded, updateImageTemplateDialogOpen }) =>
        async (file, pageNumber, groupId) => {
            updateFileLoading(true);

            const reader = new FileReader();
            const sharableTemplate = true;

            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                // removes mime type prefix from reader.result.
                let fileData = reader.result.split(',')[1];
                await Jimp.read(reader.result)
                    .then((image) => {
                        // Do stuff with the image.
                        const maxSize = 2048;
                        if (image.getWidth() > image.getHeight()) {
                            if (image.getWidth() > maxSize) {
                                image.resize(maxSize, Jimp.AUTO, Jimp.RESIZE_BEZIER);
                            }
                        } else if (image.getHeight() > maxSize) {
                            image.resize(maxSize, Jimp.AUTO, Jimp.RESIZE_BEZIER);
                        }
                        if (image) {
                            image.getBase64Async(image.getMIME()).then((base64Value) => {
                                if (base64Value.includes(',')) {
                                    fileData = base64Value.split(',')[1];
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        // Handle an exception.
                        console.log(`Resize image Error: ${err}`);
                    });
                try {
                    await upsertTemplate({
                        variables: {
                            name: file.name,
                            practice: practiceId,
                            encodedFile: fileData,
                            pageNumber,
                            groupId,
                            sharableTemplate,
                            templateCategory: subcategoryIdToCreate ? [subcategoryIdToCreate] : [],
                        },
                        refetchQueries: [
                            {
                                query: templateListQuery,
                                variables: { practiceId },
                            },
                        ],
                    });
                    await onAdded();
                    updateFileLoading(false);
                    updateImageTemplateDialogOpen(false);
                } catch (error) {
                    console.log('API Error: ', error); // eslint-disable-line
                    updateFileLoading(false);
                }
            };
        },
};

const styles = {
    card: {
        maxWidth: 1000,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
    },
};

export default compose(
    connect(({ practice }) => ({
        practiceId: practice.id,
    })),
    withStyles(styles),
    withState('fileLoading', 'updateFileLoading', false),
    withState('templateFiles', 'updateTemplateFiles', []),
    graphql(upsertTemplateMutation, { name: 'upsertTemplate' }),
    graphql(upsertGroupMutation, { name: 'upsertGroup' }),
    withHandlers(apiHandlers),
    withHandlers(localHandlers)
)(ImageTemplateDialog);

import React from 'react';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import { withTheme } from 'react-jsonschema-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import Parser from 'html-react-parser';
import { graphql } from '@apollo/client/react/hoc';

import { Button, Grid, Paper } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import SaveIcon from '@material-ui/icons/Save';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';

import ErrorMessage from '../../components/ErrorMessage';
import AppBarMenu from '../../components/AppBarMenu';
import eventBus from '../../components/EventBus';
import AddFieldFormComp from './add-form';
import {
    defaultMindbodySchema,
    defaultMindbodyUiSchema,
    defaultSchema,
    defaultSchemaFormData,
    defaultUiSchema,
    deleteRemovedField,
    formBuilderUiSchema,
    uiFieldTemplate,
} from './schemas';
import { GET_CONSENT_FORM, NEW_CONSENT_FORM } from './graphql';
import Loading from '../../components/Loading';

const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
};

const Form = withTheme(MuiTheme);

const themeConsentForm = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#233d4d !important',
            },
        },
        MuiButton: {
            root: {
                backgroundColor: '#233d4d !important',
            },
            label: {
                color: '#fff',
            },
        },
        MuiInputLabel: {
            formControl: {
                position: 'static',
                transform: 'translate(0) scale(1) !important',
                color: '#000 !important',
                textTransform: 'capitalize',
            },
        },
        MuiInputBase: {
            root: {
                marginTop: '5px !important',
                '&:before': {
                    content: 'none !important',
                },
                '&:after': {
                    content: 'none !important',
                },
            },
            input: {
                border: '1px solid rgba(0, 0, 0, 0.42)',
                padding: '6px 5px 7px',
            },
            inputMultiline: {
                padding: '5px !important',
            },
        },
        MuiGrid: {
            root: {
                '&.sigBoxWrap': {
                    width: 'auto',
                    border: '1px solid rgba(0, 0, 0, 0.42)',
                    margin: '0 auto',
                },
                '& .yesno-container': {
                    '& h5': {
                        fontSize: '1rem !important',
                    },
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: '#000 !important',
                textTransform: 'capitalize',
                paddingRight: '40px',
            },
        },
        MuiFormControlLabel: {
            label: {
                textTransform: 'capitalize',
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#233d4d !important',
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
        },
    },
});

const themeAddFields = createTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
        },
    },
});

const ConsentFormBuilder = ({
    classes,
    dispatch,
    error,
    loading,
    builderMessage,
    updateBuilderMessage,
    updateSchema,
    updateUiSchema,
    uiSchema,
    schema,
    schemaFormData,
    updateSchemaFormData,
    history,
    location,
    match,
    updateAnchorEl,
    anchorEl,
    formKey,
    consentFormData,
    updateConsentFormData,
    isConsentFormSubscription,
    practiceId,
    sharableTemplate,
    templateCategory,
    updateFormKey,
    onDuplicate,
    hasIntegration,
}) => {
    const [upsertConsentForm, { error: newConsentFormError }] = useMutation(NEW_CONSENT_FORM, {
        onCompleted: (res) => {
            if (res.newConsentForm) {
                window.location.reload();
            }
        },
    });

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container direction='row' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                <Grid className={classes.consentForm} container direction='row' wrap='nowrap' item xs={12} sm={12} md={11} lg={11} xl={11}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <Paper className={classes.paperWrap}>
                            <ThemeProvider theme={themeConsentForm}>
                                {newConsentFormError && <ErrorMessage className={classes.error} error={newConsentFormError} />}
                                {builderMessage.message && (
                                    <Alert className={classes.Alert} severity='success'>
                                        <span style={{ display: 'inline-block' }}>{Parser(builderMessage.message)}</span>
                                    </Alert>
                                )}

                                <Form
                                    noValidate
                                    schema={schema}
                                    uiSchema={uiSchema}
                                    onChange={(event) => {
                                        consentFormData = event.formData;
                                    }}
                                    FieldTemplate={uiFieldTemplate}
                                    formData={consentFormData}
                                    showErrorList
                                    noHtml5Validate
                                    transformErrors={transformErrors}
                                    onSubmit={(d, e) => {
                                        uiSchema.formName = consentFormData.formName;
                                        // Delete removed field from schema
                                        schemaFormData = deleteRemovedField(schemaFormData, schema);
                                        schema = deleteRemovedField(schema, schema);

                                        schemaFormData.title = consentFormData.formName;
                                        // Update form name
                                        schema.title = consentFormData.formName;
                                        // push to server
                                        upsertConsentForm({
                                            variables: {
                                                key: formKey,
                                                title: consentFormData.formName,
                                                allow_registration: consentFormData.allow_registration,
                                                fields: JSON.stringify(schemaFormData.properties),
                                                schema: JSON.stringify(schemaFormData),
                                                uiSchema: JSON.stringify(uiSchema),
                                                practice_id: practiceId,
                                                sharableTemplate: !!sharableTemplate,
                                                templateCategory,
                                            },
                                        }).then((res) => {
                                            updateFormKey(res.data.upsertConsentForm.form.key);

                                            updateConsentFormData({
                                                formName: res.data.upsertConsentForm.form.title,
                                                allow_registration: res.data.upsertConsentForm.form.allow_registration,
                                            });

                                            builderMessage.message = `Action success! You can view consent form at <a style="text-decoration: underline;" target="_blank" href="/consent-form/${res.data.upsertConsentForm.form.key}">here</a>`;
                                            updateBuilderMessage(builderMessage);
                                        });
                                    }}
                                    onError={(e) => {
                                        console.log('error on submit');
                                        console.log(e);
                                    }}>
                                    <Grid style={{ marginTop: '20px' }}>
                                        <Button className={classes.saveFormButton} variant='contained' color='primary' type='submit'>
                                            <SaveIcon /> &nbsp; Save Form
                                        </Button>
                                        <Button className={classes.duplicateFormButton} variant='contained' color='primary' onClick={onDuplicate}>
                                            <FontAwesomeIcon icon={faCopy} /> &nbsp; Duplicate Form
                                        </Button>

                                        <Button
                                            onClick={(event) => {
                                                updateConsentFormData(consentFormData);
                                                updateAnchorEl(event.currentTarget);
                                            }}
                                            color='secondary'
                                            variant='contained'
                                            className={classes.addFieldButton}>
                                            <ControlPointIcon /> &nbsp; Add Field
                                        </Button>
                                    </Grid>
                                </Form>
                            </ThemeProvider>
                        </Paper>
                    )}
                </Grid>

                <Grid container direction='row' wrap='nowrap' item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <ThemeProvider theme={themeAddFields}>
                        <Modal
                            aria-labelledby='more-info'
                            aria-describedby='more-info'
                            className={classes.modal}
                            open={Boolean(anchorEl)}
                            onClose={() => updateAnchorEl(null)}
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            disableBackdropClick
                            disableEscapeKeyDown>
                            <Fade in={Boolean(anchorEl)} style={{ backgroundColor: '#ffffff' }}>
                                <div className={classes.paper}>
                                    <AddFieldFormComp
                                        updateAnchorEl={updateAnchorEl}
                                        schema={schema}
                                        updateSchema={updateSchema}
                                        uiSchema={uiSchema}
                                        updateUiSchema={updateUiSchema}
                                        schemaFormData={schemaFormData}
                                        updateSchemaFormData={updateSchemaFormData}
                                    />
                                </div>
                            </Fade>
                        </Modal>
                    </ThemeProvider>
                </Grid>
            </Grid>
        </AppBarMenu>
    );
};

function transformErrors(errors) {
    console.log('errors', errors);
    errors = errors.filter((error) => error.message !== 'should be array');
    console.log(errors);
    // if (formData.pass1 !== formData.pass2) {
    //   errors.pass2.addError("Passwords don't match");
    // }
    return errors;
}

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    section: {
        maxWidth: 448,
    },
    consentForm: {
        paddingRight: 10,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperWrap: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        width: '100%',
        padding: '20px 40px',
    },
    paper: {
        backgroundColor: '#f0f0f0',
        borderTop: '10px solid #233d4d',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
        padding: '20px 40px',
        outline: 'none',
        width: '700px',
        maxHeight: '500px',
        overflow: 'scroll',
    },
    saveFormButton: {
        float: 'left',
    },
    duplicateFormButton: {
        float: 'left',
        marginLeft: '20px',
    },
    addFieldButton: {
        float: 'right',
    },
    datePickerConsent: {
        width: '250px',
    },
    Alert: {
        MuiAlert: {
            display: 'inline-block',
        },
    },
};

export default compose(
    connect(({ user, practice, integration }) => ({
        practiceId: practice.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
        hasIntegration: integration.id !== undefined,
    })),
    withStyles(styles),
    withProps(({ match, location }) => ({
        formKeyOriginal: match && match.params ? match.params.formKey : null,
    })),
    withState('loading', 'updateLoading', true),
    withState('formKey', 'updateFormKey', ''),
    withState('schema', 'updateSchema', ({ hasIntegration }) => _.cloneDeep(hasIntegration ? defaultMindbodySchema : defaultSchema)),
    withState('builderMessage', 'updateBuilderMessage', {
        message: undefined,
    }),
    withState('schemaFormData', 'updateSchemaFormData', defaultSchemaFormData),
    withState('uiSchema', 'updateUiSchema', ({ hasIntegration }) => _.cloneDeep(hasIntegration ? defaultMindbodyUiSchema : defaultUiSchema)),
    withState('anchorEl', 'updateAnchorEl', null),
    withState('consentFormData', 'updateConsentFormData', {}),
    graphql(NEW_CONSENT_FORM, { name: 'onNewConsentForm' }),
    graphql(GET_CONSENT_FORM, {
        name: 'getFormQuery',
        options: ({
            formKey,
            schema,
            hasIntegration,
            uiSchema,
            updateSchema,
            schemaFormData,
            updateSchemaFormData,
            schemaUiFormData,
            updateConsentFormData,
            updateLoading,
            updateUiSchema,
        }) => ({
            variables: {
                key: formKey,
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                if (data.getConsentForm.form) {
                    schemaFormData = JSON.parse(data.getConsentForm.form.schema);
                    const formUiSchema = JSON.parse(data.getConsentForm.form.uiSchema);
                    uiSchema = formUiSchema || uiSchema;
                    updateSchemaFormData(schemaFormData);
                    schema.required = [];

                    schema.properties = _.merge(schema.properties, schemaFormData.properties);

                    schema.title = schemaFormData.title = data.getConsentForm.form.title;

                    if (Object.keys(schema.properties).length > 0) {
                        for (const fieldKey in schema.properties) {
                            if (!uiSchema['ui:order']) {
                                uiSchema['ui:order'] = ['*'];
                            }
                            if (uiSchema['ui:order'].indexOf(fieldKey) === -1) {
                                uiSchema['ui:order'].push(fieldKey);
                            }
                            if ('original_type' in schema.properties[fieldKey] && schema.properties[fieldKey].original_type in formBuilderUiSchema) {
                                uiSchema[fieldKey] = formBuilderUiSchema[schema.properties[fieldKey].original_type];
                            }

                            if ('required' in schema.properties[fieldKey]) {
                                schema.required.push(fieldKey);
                                delete schema.properties[fieldKey].required;
                            }
                        }
                    }
                    updateSchema(schema);
                    updateUiSchema(uiSchema);

                    updateConsentFormData({
                        formName: data.getConsentForm.form.title,
                        allow_registration: data.getConsentForm.form.allow_registration,
                    });
                } else {
                    updateUiSchema(_.cloneDeep(hasIntegration ? defaultMindbodyUiSchema : defaultUiSchema));
                    updateSchema(_.cloneDeep(hasIntegration ? defaultMindbodySchema : defaultSchema));
                    updateSchemaFormData(defaultSchemaFormData);
                }
                updateLoading(false);
            },
        }),
    }),
    withHandlers({
        onDuplicate:
            ({
                schemaFormData,
                uiSchema,
                newConsentForm,
                consentFormData,
                sharableTemplate,
                templateCategory,
                updateFormKey,
                builderMessage,
                updateBuilderMessage,
                schema,
                practiceId,
                history,
                onNewConsentForm,
            }) =>
            async () => {
                console.log('onDuplicate');
                uiSchema.formName = consentFormData.formName;
                // Delete removed field from schema
                schemaFormData = deleteRemovedField(schemaFormData, schema);
                schema = deleteRemovedField(schema, schema);

                schemaFormData.title = consentFormData.formName;
                // Update form name
                schema.title = consentFormData.formName;
                const response = await onNewConsentForm({
                    variables: {
                        title: consentFormData.formName,
                        allow_registration: consentFormData.allow_registration,
                        fields: JSON.stringify(schemaFormData.properties),
                        schema: JSON.stringify(schemaFormData),
                        uiSchema: JSON.stringify(uiSchema),
                        practice_id: practiceId,
                        sharableTemplate: !!sharableTemplate,
                        templateCategory,
                    },
                });
                // push to server

                updateFormKey(response.data.upsertConsentForm.form.key);
                builderMessage.message = `Duplicated successfully! You can view consent form at <a style="text-decoration: underline;" target="_blank" href="/consent-form/${response.data.upsertConsentForm.form.key}">here</a>`;
                updateBuilderMessage(builderMessage);
                history.replace(`/consent-form-builder/${response.data.upsertConsentForm.form.key}`);
            },
    }),
    lifecycle({
        componentDidMount() {
            const { updateSchemaFormData, updateBuilderMessage, updateUiSchema, updateSchema, updateFormKey, formKeyOriginal, hasIntegration } =
                this.props;
            if (formKeyOriginal) {
                updateFormKey(formKeyOriginal);
            }
            if (!formKeyOriginal) {
                updateSchemaFormData({
                    title: 'Consent Form Builder',
                    type: 'object',
                    required: [],
                    properties: {},
                });
                updateUiSchema(_.cloneDeep(hasIntegration ? defaultMindbodyUiSchema : defaultUiSchema));
                updateSchema(_.cloneDeep(hasIntegration ? defaultMindbodySchema : defaultSchema));
                updateBuilderMessage({ message: undefined });
            }
            eventBus.on('CONSENT_FORM_FIELD_ORDER_UP', (data) => {
                const { uiSchema, updateUiSchema } = this.props;
                const cloneUiSchema = _.cloneDeep(uiSchema);
                const order = cloneUiSchema['ui:order'];
                const originalPos = order.indexOf(data.id);
                if (originalPos === -1 || order[originalPos - 1] === 'allow_registration' || order[originalPos - 1] === '*') {
                    return;
                }
                arrayMove(order, originalPos, originalPos - 1);
                cloneUiSchema['ui:order'] = order;
                updateUiSchema(cloneUiSchema);
            });
            eventBus.on('CONSENT_FORM_FIELD_ORDER_DOWN', (data) => {
                const { uiSchema, updateUiSchema } = this.props;
                const cloneUiSchema = _.cloneDeep(uiSchema);
                const order = cloneUiSchema['ui:order'];
                const originalPos = order.indexOf(data.id);
                if (originalPos !== -1 && originalPos !== order.length - 1) {
                    arrayMove(order, originalPos, originalPos + 1);
                    cloneUiSchema['ui:order'] = order;
                    updateUiSchema(cloneUiSchema);
                }
            });
            eventBus.on('CONSENT_FORM_FIELD_UPDATE_TITLE', (data) => {
                const { schema, updateSchema, schemaFormData, updateSchemaFormData } = this.props;
                if (schemaFormData.properties && schemaFormData.properties[data.id]) {
                    const schemaFormDataClone = _.cloneDeep(schemaFormData);
                    schemaFormDataClone.properties[data.id].title = data.value;
                    updateSchemaFormData(schemaFormDataClone);
                }
                if (schema.properties && schema.properties[data.id]) {
                    const schemaClone = _.cloneDeep(schema);
                    schemaClone.properties[data.id].title = data.value;
                    updateSchema(schemaClone);
                }
            });
            eventBus.on('CONSENT_FORM_FIELD_CHANGE_IMAGE', (data) => {
                console.log('CONSENT_FORM_FIELD_CHANGE_IMAGE', data);
                const { schema, updateSchema, schemaFormData, updateSchemaFormData } = this.props;

                if (schemaFormData.properties && schemaFormData.properties[data.id]) {
                    const schemaFormDataClone = _.cloneDeep(schemaFormData);
                    schemaFormDataClone.properties[data.id].templateId = data.templateId;
                    schemaFormDataClone.properties[data.id].groupedTemplates = data.groupedTemplates;
                    schemaFormDataClone.properties[data.id].fileId = data.fileId;
                    updateSchemaFormData(schemaFormDataClone);
                }
                if (schema.properties && schema.properties[data.id]) {
                    const schemaClone = _.cloneDeep(schema);
                    schemaClone.properties[data.id].templateId = data.templateId;
                    schemaClone.properties[data.id].groupedTemplates = data.groupedTemplates;
                    schemaClone.properties[data.id].fileId = data.fileId;
                    updateSchema(schemaClone);
                }
            });
            eventBus.on('CONSENT_FORM_FIELD_UPDATE_REQUIRED', (data) => {
                console.log('CONSENT_FORM_FIELD_UPDATE_REQUIRED', data);
                const { schema, updateSchema, schemaFormData, updateSchemaFormData } = this.props;
                if (schemaFormData.properties && schemaFormData.properties[data.id]) {
                    const schemaFormDataClone = _.cloneDeep(schemaFormData);
                    schemaFormDataClone.properties[data.id].required = data.required;
                    updateSchemaFormData(schemaFormDataClone);
                }
                if (schema.properties && schema.properties[data.id]) {
                    const schemaClone = _.cloneDeep(schema);
                    schemaClone.properties[data.id].required = data.required;
                    if (data.required) {
                        schemaClone.required.push(data.id);
                    } else {
                        schemaClone.required = schema.required.filter(function (item) {
                            return item !== data.id;
                        });
                    }

                    updateSchema(schemaClone);
                }
            });
            eventBus.on('CONSENT_FORM_FIELD_UPDATE_CHECKBOXES_LABELS', (data) => {
                const { schema, updateSchema, schemaFormData, updateSchemaFormData } = this.props;
                if (
                    schemaFormData.properties &&
                    schemaFormData.properties[data.id] &&
                    schemaFormData.properties[data.id].original_type === 'multipleCheckboxes'
                ) {
                    schemaFormData.properties[data.id].items.enum = data.items;
                    updateSchemaFormData(schemaFormData);
                }
                if (schema.properties && schema.properties[data.id] && schema.properties[data.id].original_type === 'multipleCheckboxes') {
                    schema.properties[data.id].items.enum = data.items;
                    updateSchema(schema);
                }
            });
        },
        componentWillUnmount() {
            eventBus.remove('CONSENT_FORM_FIELD_ORDER_UP');
            eventBus.remove('CONSENT_FORM_FIELD_ORDER_DOWN');
            eventBus.remove('CONSENT_FORM_FIELD_UPDATE_TITLE');
            eventBus.remove('CONSENT_FORM_FIELD_UPDATE_CHECKBOXES_LABELS');
            eventBus.remove('CONSENT_FORM_FIELD_UPDATE_REQUIRED');
            eventBus.remove('CONSENT_FORM_FIELD_CHANGE_IMAGE');
        },
    })
)(ConsentFormBuilder);

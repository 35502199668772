import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useQuery } from '@apollo/client';

import {
  Button,
  Paper,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import BaseSettingsPage from './Base';
import { WARM_PINK } from '../../style/constants';
import { FETCH_LOCATION, LIST_INVENTORY_ITEMS } from './gql';
import Loading from '../../components/Loading';
import InventoryItemDialog from './Inventory/Dialog';
import SimpleTextSearch from '../../components/SimpleTextSearch';
import SimpleDropdownFilter from '../../components/SimpleDropdownFilter';
import { formatMoney } from '../../utility';
import Restricted from '../../components/Restricted';

const INITIAL_STATE = {
  category: '',
  code: '',
  description: '',
  isTaxable: true,
  price: null,
  stock: null,
  status: 'ACTIVE',
  taxRateIds: [],
};

const InventorySettings = ({ classes, locationId, practiceEncodedId, user }) => {
  const [selectedInventoryItem, setSelectedInventoryItem] = useState(
    INITIAL_STATE
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredInventoryItems, setFilteredInventoryItems] = useState([]);
  const [allInventoryItems, setAllInventoryItems] = useState([]);
  const [filterBy, setFilterBy] = useState('ACTIVE');
  const [taxRates, setTaxRates] = useState([]);

  const { loading } = useQuery(LIST_INVENTORY_ITEMS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.listInventoryItem) {
        setAllInventoryItems(res.listInventoryItem || []);
        const activeItems = res.listInventoryItem.filter(
          item => item.status === 'ACTIVE'
        );
        setFilteredInventoryItems(activeItems || []);
      }
    },
    variables: {
      locationId,
    },
  });

  useQuery(FETCH_LOCATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.fetchLocation && res.fetchLocation.length) {
        const { taxRates: newTaxRates } = res.fetchLocation[0];
        setTaxRates(newTaxRates);
      }
    },
    variables: {
      id: locationId,
      practiceEncodedId,
    },
  });

  const handleToggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  useEffect(() => {
    const filteredItems = allInventoryItems.filter(
      item => item.status === filterBy
    );
    setFilteredInventoryItems(filteredItems);
  }, [filterBy]);

  return (
    <BaseSettingsPage>
      <div className={classes.header}>
        <Typography variant="subtitle1" className={classes.subheading}>
          Inventory & Services
        </Typography>

        <Restricted isAllowedTo="upsertInventory">
          <Button
            className={classes.redButton}
            variant="contained"
            onClick={() => {
              setSelectedInventoryItem(INITIAL_STATE);
              handleToggleDialog();
            }}
          >
            ADD NEW
          </Button>
        </Restricted>
      </div>
      <Grid className={classes.subSection}>
        <TableContainer className={classes.container} component={Paper}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-around' }}
            pr={10}
            flexDirection="row"
          >
            <Box>
              <SimpleTextSearch
                searchItems={allInventoryItems}
                setSearchItems={setFilteredInventoryItems}
                searchKeys={['code', 'description', 'category']}
              />
            </Box>
            <Box>
              <SimpleDropdownFilter
                filterName="Inventory Status"
                filterCategories={['ACTIVE', 'ARCHIVED']}
                status={filterBy}
                setStatus={setFilterBy}
              />
            </Box>
          </Box>

          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                !!filteredInventoryItems.length &&
                filteredInventoryItems.map(item => {
                  const {
                    category,
                    code,
                    description,
                    price,
                    status,
                    // stock,
                  } = item;
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        setSelectedInventoryItem(item);
                        handleToggleDialog();
                      }}
                      key={`table-row-${item.id}`}
                    >
                      <TableCell>{code}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>{category}</TableCell>
                      <TableCell>{formatMoney(price, user.location.currency.currency)}</TableCell>
                      <TableCell>{status}</TableCell>
                      {/* <TableCell>{stock}</TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {isDialogOpen && (
        <Restricted isAllowedTo="upsertInventory">
          <InventoryItemDialog
            handleToggleDialog={handleToggleDialog}
            isDialogOpen={isDialogOpen}
            selectedInventoryItem={selectedInventoryItem}
            setSelectedInventoryItem={setSelectedInventoryItem}
            taxRates={taxRates}
          />
        </Restricted>
      )}
    </BaseSettingsPage>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 36,
    paddingBottom: 8,
    width: '100%',
  },
  subheading: {
    fontWeight: 'bold',
  },
  colorIcon: {
    width: 20,
    height: 20,
    border: 'solid 1px #e0e0e0',
  },
  subSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  redButton: {
    height: 36,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    user,
    locationId: user.currentLocationId,
    practiceEncodedId: practice.id,
  }))
)(InventorySettings);

// action types

export const NOTES_CHECKED = 'NOTES_CHECKED';
export const NOTES_CLEARED = 'NOTES_CLEARED';
export const NOTES_SIGN_OFF = 'NOTES_SIGN_OFF';
export const NOTES_VIEW = 'NOTES_VIEW';

// action creators

export const notesChecked = checkedNotes => ({
  type: NOTES_CHECKED,
  checkedNotes,
});

export const notesView = view => ({
  type: NOTES_VIEW,
  view,
});

export const notesSignOff = signOff => ({
  type: NOTES_SIGN_OFF,
  signOff,
});

export const notesCleared = () => ({ type: NOTES_CLEARED });

import React from 'react';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';

import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import NotesSidebarContainer from './notesSidebarContainer';
import { SECONDARY, WHITE } from '../../style/constants';
import NotesSidebarItem from '../NotesSidebarItem';
import Loading from '../Loading';

const NotesSidebar = ({ classes, isSidebarOpen, updateSidebar, notes, status, hasNextPage, onListEnd }) => {
    return (
        <div className={`${classes.sideNotesBarFake} ${isSidebarOpen ? classes.sidebarExpandFake : classes.sidebarCollapseFake}`}>
            <Paper className={`${classes.sideNotesBar} ${isSidebarOpen ? classes.sidebarExpand : classes.sidebarCollapse}`}>
                {(() => {
                    if (status && status.loading) {
                        return <Loading height={100} />;
                    }
                    return (
                        <InfiniteScroll
                            pageStart={0}
                            hasMore={hasNextPage}
                            loader={<Loading key={1} height={100} />}
                            loadMore={onListEnd && notes.length < 40}
                            className={classes.infiniteScroll}>
                            <IconButton
                                size='small'
                                className={`${classes.buttonIcon} ${isSidebarOpen ? classes.buttonCollapse : classes.buttonExpand}`}
                                onClick={() => {
                                    updateSidebar((prev) => !prev);
                                }}>
                                <ExpandMoreIcon />
                            </IconButton>
                            <Grid className={classes.itemsContainer}>
                                {notes &&
                                    notes.map((note) => (
                                        <React.Fragment key={note.id}>
                                            <NotesSidebarItem note={note} />
                                            <Divider className={classes.divider} />
                                        </React.Fragment>
                                    ))}
                            </Grid>
                        </InfiniteScroll>
                    );
                })()}
            </Paper>
        </div>
    );
};

const style = {
    sideNotesBar: {
        position: 'fixed',
        height: ({ showBreadCrumbs }) => {
            return showBreadCrumbs ? window.innerHeight - 32 : '100vh';
        },
        right: 0,
        paddingTop: ({ isDrawing }) => {
            return isDrawing ? 10 : 64;
        },
        background: WHITE,
        transition: 'width 0.5s ease',
    },
    sidebarExpand: {
        width: '15%',
    },
    sidebarCollapse: {
        width: 0,
    },
    sideNotesBarFake: {
        height: ({ showBreadCrumbs }) => {
            return showBreadCrumbs ? window.innerHeight - 32 : '100vh';
        },
        transition: 'all 0.5s ease',
        position: ({ isDrawing }) => {
            return isDrawing ? 'fixed' : null;
        },
        zIndex: 999,
    },
    sidebarExpandFake: {
        marginLeft: '15%',
    },
    sidebarCollapseFake: {
        marginLeft: 0,
    },
    buttonIcon: {
        position: 'absolute',
        right: '100%',
        top: ({ isDrawing }) => {
            return isDrawing ? '30px' : '70px';
        },
        background: 'rgba(255, 87, 122, 0.48)',
        color: WHITE,
        zIndex: 1,
        borderRadius: 0,
        '&:hover': {
            background: SECONDARY,
        },
    },
    buttonCollapse: {
        transform: 'rotate(-90deg)',
        borderRadius: '25px 25px 0 0',
    },
    buttonExpand: {
        transform: 'rotate(90deg)',
        borderRadius: '0 0 25px 25px',
    },
    infiniteScroll: {
        overflow: 'auto',
        height: '100%',
    },
};

export default compose(NotesSidebarContainer, withStyles(style))(NotesSidebar);

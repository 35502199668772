import { branch, compose, withHandlers, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import queryString from 'query-string';

import uniqBy from 'lodash/uniqBy';

import { notesChecked, notesCleared, notesSignOff, notesView } from '../../data/redux/actions/notes';

import { textNotePreviewQuery } from '../TextNotePreview/textNotePreviewContainer';
import { formNotePreviewQuery } from '../FormNotePreview/formNotePreviewContainer';
import { drawingNotePreviewQuery } from '../DrawingNotePreview/drawingNotePreviewContainer';

export const GET_NOTES = gql`
    query notes(
        $patientId: ID!
        $episodeId: ID
        $categories: [ID]
        $sortBy: SortBy
        $sortDirection: SortDirection
        $limit: Int
        $offset: Int
        $mediaOnly: Boolean
        $noGroup: Boolean
    ) {
        node(id: $patientId) {
            __typename
            id
            ... on Patient {
                notes(
                    episodeId: $episodeId
                    categories: $categories
                    sortBy: $sortBy
                    sortDirection: $sortDirection
                    limit: $limit
                    offset: $offset
                    mediaOnly: $mediaOnly
                    noGroup: $noGroup
                ) {
                    nodes {
                        id
                        title
                        episodes {
                            id
                        }
                        ... on DrawingNote {
                            group {
                                id
                            }
                        }
                        isSignedOff
                        signedOffTime
                        createdAt
                        updatedAt
                        author {
                            id
                            name
                        }
                        __typename
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
        }
    }
`;

export const SIGN_OFF_NOTES = gql`
    mutation signOffNotes($noteIds: [ID!]) {
        signOffNotes(noteIds: $noteIds) {
            signedOffTime
        }
    }
`;

const initialSort = {
    by: 'UPDATED_AT',
    title: 'Updated',
    direction: 'DESC',
};

const NotesContainer = compose(
    withRouter,
    connect(({ notes }) => ({
        checkedNotes: notes.checkedNotes,
        signOff: notes.signOff,
        view: notes.view,
    })),
    withState('sort', 'updateSort', initialSort),
    withState('categories', 'updateCategories', []),
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
    })),
    withProps(({ episodeId, attachments }) => {
        switch (episodeId) {
            case undefined:
                return { heading: attachments ? 'All Attachments' : 'All Notes' };
            case null:
                return {
                    heading: attachments ? 'Untagged Attachments' : 'Untagged Notes',
                };
            default:
                return {
                    heading: attachments ? 'Episode Attachments' : 'Notes in Episode',
                };
        }
    }),
    graphql(SIGN_OFF_NOTES, { name: 'signOffMutation' }),
    branch(
        ({ patientId, episodeId }) => patientId && episodeId !== 'new',
        compose(
            graphql(GET_NOTES, {
                name: 'query',
                options: ({ categories, episodeId, patientId, sort, attachments }) => ({
                    variables: {
                        patientId,
                        episodeId,
                        categories,
                        sortBy: sort.by,
                        sortDirection: sort.direction,
                        offset: 0,
                        limit: 14,
                        mediaOnly: attachments,
                        noGroup: true,
                    },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    setVariables: query.networkStatus === 2,
                    fetching: query.networkStatus === 3,
                    refetching: query.networkStatus === 4,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                ({ status }) => status.success || status.fetching || status.refetching,
                withProps(({ query }) => ({
                    notes: query.node.notes.nodes,
                    hasNextPage: query.node.notes.pageInfo.hasNextPage,
                }))
            )
        )
    ),
    withHandlers({
        onAllowSignOff:
            ({ dispatch }) =>
            () => {
                dispatch(notesSignOff(true));
            },
        onCancelSignOff:
            ({ dispatch }) =>
            () => {
                dispatch(notesSignOff(false));
                dispatch(notesChecked([]));
            },
        onConfirmSignOff:
            ({ checkedNotes, signOffMutation, dispatch }) =>
            async () => {
                const checkedNotesIds = checkedNotes.map((note) => note.id);

                if (checkedNotesIds.length !== 0) {
                    try {
                        await signOffMutation({
                            variables: {
                                noteIds: checkedNotesIds,
                            },
                            refetchQueries: checkedNotes.map((note) => {
                                let nodeQuery;
                                switch (note.__typename) {
                                    case 'TextNote':
                                        nodeQuery = textNotePreviewQuery;
                                        break;
                                    case 'FormNote':
                                        nodeQuery = formNotePreviewQuery;
                                        break;
                                    case 'DrawingNote':
                                        nodeQuery = drawingNotePreviewQuery;
                                        break;
                                    default:
                                        return null;
                                }
                                const refetch = {
                                    query: nodeQuery,
                                    variables: { noteId: note.id },
                                };
                                return refetch;
                            }),
                        });
                    } catch (error) {
                        console.log('Error: ', error); // eslint-disable-line
                    }
                }

                dispatch(notesSignOff(false));
                dispatch(notesCleared());
            },
        onListEnd:
            ({ status, query }) =>
            () => {
                if (!status.fetching && !status.refetching) {
                    query.fetchMore({
                        variables: {
                            offset: query.node.notes.nodes.length,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const combinedItems = [...previousResult.node.notes.nodes, ...fetchMoreResult.node.notes.nodes];
                            const filteredResults = uniqBy(combinedItems, (item) => item.id);

                            return {
                                node: {
                                    ...previousResult.node,
                                    notes: {
                                        ...previousResult.node.notes,
                                        nodes: filteredResults,
                                        pageInfo: {
                                            ...previousResult.node.notes.pageInfo,
                                            ...fetchMoreResult.node.notes.pageInfo,
                                        },
                                    },
                                },
                            };
                        },
                    });
                }
            },
        onRefetch:
            ({ query }) =>
            () =>
                query.refetch(),
        onSortBy:
            ({ sort, updateSort }) =>
            (by) =>
                updateSort({
                    by,
                    title: () => {
                        switch (by) {
                            case 'TITLE':
                                return 'Title';
                            case 'CREATED_AT':
                                return 'Created';
                            case 'UPDATED_AT':
                                return 'Updated';
                            case 'SIGNED_OFF_TIME':
                                return 'Signed';
                            default:
                                return null;
                        }
                    },
                    direction: sort.by === by && sort.direction === 'DESC' ? 'ASC' : 'DESC',
                }),
        onSortDirection:
            ({ sort, updateSort }) =>
            (newSortDirection) => {
                if (newSortDirection && newSortDirection !== sort.direction)
                    updateSort({
                        ...sort,
                        direction: newSortDirection,
                    });
            },
        onToggleView:
            ({ dispatch, view }) =>
            (newView) => {
                if (newView && newView !== view) dispatch(notesView(newView));
            },
    })
);

export default NotesContainer;

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect, useSelector } from 'react-redux';
import { ARRIVAL_RED, ERROR } from '../../style/constants';
import { LIST_INVOICES, UPSERT_INVOICE } from '../InvoicingPage/gql';

const UpdateInvoiceDialog = ({
    classes,
    currentLocationId,
    existingElement,
    invoiceAccountOptions,
    invoiceId,
    onCloseDialog,
    practiceId,
    showDialog,
}) => {
    const encodedPatientId = useSelector((state) => state.patient.id);
    const [invalidInput, setInvalidInput] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [invoiceElement, setInvoiceElement] = useState(existingElement);
    const [isUniqueInput, setIsUniqueInput] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const requiredFields = ['dateDue', 'invoiceAccount'];

    const [upsertInvoice] = useMutation(UPSERT_INVOICE, {
        onCompleted: () => {
            onCloseDialog();
            window.location.reload(true);
        },
        refetchQueries: [
            {
                query: LIST_INVOICES,
                variables: {
                    id: invoiceId,
                    practiceId,
                },
            },
        ],
    });

    const validateChanges = () => {
        const invalidDetected = [];
        requiredFields.forEach((key) => {
            const variable = invoiceElement[key];
            if (!variable) {
                invalidDetected.push(key);
            }
        });
        setIsUniqueInput(!_.isEqual(invoiceElement, existingElement));
        return setInvalidInput(invalidDetected);
    };

    useEffect(() => {
        validateChanges();
    }, [invoiceElement]);

    const onConfirmDelete = () => {
        upsertInvoice({
            variables: {
                id: invoiceId,
                dateDue: invoiceElement.dateDue,
                encodedPatientId,
                invoiceAccountId: invoiceElement.invoiceAccount.id,
                status: 'ARCHIVED',
            },
        }).then(() => {
            onCloseDialog();
        });
    };

    const onSave = async () => {
        setShowErrors(true);
        if (!invalidInput.length) {
            await upsertInvoice({
                variables: {
                    ...invoiceElement,
                    encodedPatientId,
                    locationId: currentLocationId,
                    practiceId,
                    invoiceAccountId: invoiceElement.invoiceAccount.id,
                },
            });
        }
    };

    const handleAccountSelect = (acc) => {
        setInvoiceElement({
            ...invoiceElement,
            invoiceAccount: acc,
        });
    };

    const handleStatusClick = (event) => {
        setInvoiceElement({
            ...invoiceElement,
            status: event.target.value,
        });
    };

    const handleTextChange = (event) => {
        setInvoiceElement({
            ...invoiceElement,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Dialog open={showDialog} onClose={onCloseDialog} className={classes.dialogContainer}>
            <DialogTitle id='form-dialog-title'>Edit Invoice Details</DialogTitle>

            <DialogContent>
                <TextField
                    error={showErrors && invalidInput.includes('dateDue')}
                    fullWidth
                    id='invoice-due-date'
                    label='Invoice Due'
                    margin='normal'
                    autoComplete='off'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        const clonedInvoiceElement = {
                            ...invoiceElement,
                            dateDue: event.target.value,
                        };
                        setInvoiceElement(clonedInvoiceElement);
                    }}
                    type='date'
                    value={invoiceElement.dateDue || ''}
                />

                <TextField
                    error={showErrors && invalidInput.includes('invoiceAccount')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    autoComplete='off'
                    id='bill-to'
                    label='Bill To'
                    select
                    value={_.get(invoiceElement, 'invoiceAccount.id', 'default')}>
                    <MenuItem value='default'>Select an account</MenuItem>
                    {invoiceAccountOptions.length &&
                        invoiceAccountOptions.map((account) => (
                            <MenuItem key={account.id} value={account.id} onClick={() => handleAccountSelect(account)}>
                                {account.billingName}
                            </MenuItem>
                        ))}
                </TextField>

                <TextField fullWidth label='Status' autoComplete='off' onChange={handleStatusClick} select value={invoiceElement.status}>
                    <MenuItem value='DRAFT'>Draft</MenuItem>
                    <MenuItem value='OUTSTANDING'>Outstanding</MenuItem>
                    {/* <MenuItem value="OVERDUE">Overdue</MenuItem> */}
                    <MenuItem value='PAID'>Paid</MenuItem>
                </TextField>

                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    label='Invoice Notes'
                    autoComplete='off'
                    name='notes'
                    onChange={handleTextChange}
                    placeholder='This will not appear on the final invoice; for internal use only'
                    value={_.get(invoiceElement, 'notes', '')}
                />

                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    label='Invoice Comments'
                    name='comments'
                    autoComplete='off'
                    onChange={handleTextChange}
                    placeholder='This will appear on the final invoice in the comments field'
                    value={_.get(invoiceElement, 'comments', '')}
                />
            </DialogContent>

            <DialogActions>
                {existingElement && (
                    <div className={classes.buttonContainer}>
                        {confirmDelete ? (
                            <>
                                <Button variant='outlined' className={classes.button} onClick={() => setConfirmDelete(false)}>
                                    UNDO
                                </Button>
                                <Button variant='outlined' className={classes.redButton} onClick={onConfirmDelete}>
                                    CONFIRM
                                </Button>
                            </>
                        ) : (
                            <Button color='secondary' className={classes.deleteButton} onClick={() => setConfirmDelete(true)}>
                                DELETE
                            </Button>
                        )}
                    </div>
                )}

                <div className={classes.buttonContainer}>
                    <Button color='primary' onClick={onCloseDialog}>
                        Cancel
                    </Button>

                    <Button
                        color='primary'
                        onClick={onSave}
                        disabled={showErrors && (invalidInput.length > 0 || _.isEqual(invoiceElement, {}) || !isUniqueInput)}>
                        SAVE
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    fieldContainer: {
        alignElements: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    buttonFieldContainer: {
        flexDirection: 'row-reverse',
        alignElements: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    title: {
        width: 100,
        marginTop: 16,
    },
    field: {
        width: 240,
    },
    dialogContainer: {
        width: '100%',
    },
    buttonContainer: {
        '& button': {
            marginLeft: 10,
        },
    },
    redButton: {
        width: 100,
        height: 40,
        color: ARRIVAL_RED,
    },
    button: {
        width: 100,
        height: 40,
    },
    deleteButton: {
        marginRight: 'auto',
        color: ERROR,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, practice }) => ({
        currentLocationId: user.currentLocationId,
        practiceId: practice.id,
    }))
)(UpdateInvoiceDialog);

import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Paper, Tooltip } from '@material-ui/core';
import { PRIMARY } from '../../style/constants';

import EpisodeListContainer from './episodeListContainer';

import Loading from '../Loading';
import EmptyData from '../EmptyData';

const EpisodeList = ({ classes, error, hasNextPage, onAllNotes, onEpisode, onListEnd, onUntaggedNotes, patientId, episodes, status }) => {
    /* if (!episodes || episodes.length === 0) return <EmptyAccordion status={status} typeEpisode />; */

    return (
        <Paper className={classes.episodesPaper}>
            <InfiniteScroll
                className={classes.infinite}
                pageStart={0}
                hasMore={hasNextPage && episodes.length < 40}
                useWindow={false}
                loader={<Loading key={1} height={100} />}
                loadMore={onListEnd}>
                {(() => {
                    if (!patientId) return <EmptyData text='Please select or create a new patient' />;
                    // if (status.loading || status.refetching || status.fetching)
                    //   return <Loading height={100} />;
                    // if (status.error) return <ErrorMessage error={error} />;

                    return (
                        <List disablePadding>
                            <ListItem className={classes.buttons} divider disableGutters>
                                <Button color='primary' onClick={onUntaggedNotes}>
                                    Untagged
                                </Button>
                                <Tooltip title='Display all Patient Notes'>
                                    <Button color='primary' onClick={onAllNotes}>
                                        All
                                    </Button>
                                </Tooltip>
                            </ListItem>
                            {(!episodes || (episodes && episodes.length === 0)) && <EmptyData text='Please create a new episode' />}
                            {episodes &&
                                episodes.map((episode) => (
                                    <ListItem
                                        key={episode.id}
                                        button
                                        divider
                                        onClick={() => onEpisode(episode)}
                                        TouchRippleProps={{ classes: { root: classes.ripple } }}>
                                        <ListItemText
                                            className={classes.label}
                                            secondary={`${Moment(episode.startDate).format('ll')} - ${
                                                episode.endDate ? Moment(episode.endDate).format('ll') : 'In Progress'
                                            }`}
                                        />
                                        <ListItemText
                                            className={classes.title}
                                            disableTypography
                                            primary={
                                                <Typography variant='subtitle1'>
                                                    <strong>{episode.name}</strong>
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    );
                })()}
            </InfiniteScroll>
        </Paper>
    );
};

const styles = {
    episodesPaper: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    infinite: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        height: ({ height }) => height,
    },
    label: {
        flex: 0.5,
    },
    title: {
        flex: 1,
    },
    ripple: {
        color: PRIMARY,
    },
    buttons: {
        justifyContent: 'flex-end',
        padding: 4,
    },
};

export default compose(EpisodeListContainer, withStyles(styles))(EpisodeList);

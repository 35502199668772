import { branch, compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Moment from 'moment';

import { episodeSelected } from '../../data/redux/actions/episode';
import { practiceRefetchToggle } from '../../data/redux/actions/practice';

import { episodeListQuery } from '../EpisodeList/episodeListContainer';

const episodeNodeQuery = gql`
    query episodeNodeQuery($episodeId: ID!) {
        node(id: $episodeId) {
            __typename
            id
            ... on Episode {
                name
                startDate
                endDate
            }
        }
    }
`;

const upsertEpisodeMutation = gql`
    mutation upsertEpisode($episodeId: ID, $allowUpdate: Boolean, $patientId: ID!, $name: String!, $startDate: Date!, $endDate: Date) {
        upsertEpisode(id: $episodeId, allowUpdate: $allowUpdate, patient: $patientId, name: $name, startDate: $startDate, endDate: $endDate) {
            episode {
                id
                name
                startDate
                endDate
                patient {
                    id
                }
            }
        }
    }
`;

const initialForm = {
    name: '',
    startDate: new Date(),
    endDate: null,
};

const initialState = {
    editing: false,
    loading: false,
    error: false,
};

const handlers = {
    onCancelEndDate:
        ({ form, updateForm }) =>
        () => {
            updateForm({ ...form, endDate: null });
        },
    onChangeName:
        ({ form, updateForm }) =>
        (name) =>
            updateForm({ ...form, name }),
    onSelectEndDate:
        ({ form, updateForm }) =>
        (endDate) =>
            updateForm({ ...form, endDate }),
    onNewEndDate:
        ({ form, updateForm }) =>
        () =>
            updateForm({ ...form, endDate: form.startDate }),
    onSelectStartDate:
        ({ form, updateForm }) =>
        (startDate) => {
            updateForm({
                ...form,
                startDate,
            });
        },
    onEdit:
        ({ state, updateState, isConsentFormSubscription }) =>
        () => {
            if (isConsentFormSubscription) {
                updateState({
                    ...state,
                    error: {
                        message: 'Please upgrade to full version plan to use this function.',
                    },
                });
                return;
            }
            updateState({ ...state, editing: !state.editing, error: false });
        },
    onSave:
        ({ form, mutate, episodeId, patientId, state, updateState, history, dispatch, isConsentFormSubscription }) =>
        async () => {
            if (isConsentFormSubscription) {
                updateState({
                    ...state,
                    error: {
                        message: 'Please upgrade to full version plan to use this function.',
                    },
                });
                return;
            }
            updateState({ ...state, loading: true });

            const newOptions = {
                variables: {
                    episodeId,
                    allowUpdate: false,
                    patientId,
                    name: form.name,
                    startDate: Moment(form.startDate).format('YYYY-MM-DD'),
                    endDate: form.endDate ? Moment(form.endDate).format('YYYY-MM-DD') : null,
                },
                refetchQueries: [
                    {
                        query: episodeListQuery,
                        variables: { patientId },
                    },
                ],
            };

            const editOptions = {
                variables: {
                    episodeId,
                    allowUpdate: true,
                    patientId,
                    name: form.name,
                    startDate: Moment(form.startDate).format('YYYY-MM-DD'),
                    endDate: form.endDate ? Moment(form.endDate).format('YYYY-MM-DD') : null,
                },
                refetchQueries: [
                    {
                        query: episodeNodeQuery,
                        variables: { episodeId },
                    },
                ],
            };

            try {
                const response = await mutate(episodeId ? editOptions : newOptions);
                dispatch(practiceRefetchToggle('refetchEpisodeList', true));
                updateState({ ...state, loading: false, editing: false, error: false });

                dispatch(
                    episodeSelected(
                        response.data.upsertEpisode.episode.id,
                        response.data.upsertEpisode.episode.name,
                        response.data.upsertEpisode.episode.startDate,
                        response.data.upsertEpisode.episode.endDate
                    )
                );

                if (!episodeId) {
                    history.replace({
                        pathname: `/patient/${patientId}/notes`,
                        search: `?episodeId=${response.data.upsertEpisode.episode.id}`,
                    });
                }
            } catch (error) {
                updateState({ ...state, loading: false, error });
            }
        },
};

const EpisodeContainer = compose(
    withRouter,
    connect(({ practice, user }) => ({
        practiceId: practice.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId === 'new' ? undefined : queryString.parse(location.search).episodeId,
    })),
    withState('form', 'updateForm', initialForm),
    withState('state', 'updateState', initialState),
    branch(
        ({ episodeId }) => episodeId,
        compose(
            graphql(episodeNodeQuery, {
                name: 'query',
                options: ({ episodeId }) => ({
                    variables: { episodeId },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    setVariables: query.networkStatus === 2,
                    refetching: query.networkStatus === 4,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                (props) => props.status.success,
                withProps(({ query }) => ({
                    name: query.node.name,
                    startDate: query.node.startDate,
                    endDate: query.node.endDate,
                }))
            )
        )
    ),
    graphql(upsertEpisodeMutation),
    withHandlers(handlers),
    lifecycle({
        componentDidMount() {
            const { episodeId, status, name, startDate, endDate, updateForm, state, updateState } = this.props;

            if (!episodeId) {
                updateState({ ...state, editing: true });
            }

            if (status && status.success) {
                updateForm({
                    name,
                    startDate: new Date(startDate),
                    endDate: endDate ? new Date(endDate) : null,
                });
            }
        },
        componentWillReceiveProps() {
            const { episodeId, updateForm, state, updateState } = this.props;

            if (!episodeId && state.editing === false) {
                updateState({ ...state, editing: true });
                updateForm(initialForm);
            }
        },
    })
);

export default EpisodeContainer;

import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

import get from 'lodash/get';
import debounce from 'lodash/debounce';

import _ from 'lodash';
import { noteUpsert } from '../../data/redux/actions/note';

const textNoteQuery = gql`
    query textNote($noteId: ID!) {
        node(id: $noteId) {
            id
            __typename
            ... on TextNote {
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                categories {
                    id
                }
                author {
                    id
                    name
                }
                createdAt
                title
                text
                isSignedOff
                signedOffTime
                patient {
                    id
                    name
                }
            }
        }
    }
`;

const signOffNotesMutation = gql`
    mutation signOffNotes($noteIds: [ID!]) {
        signOffNotes(noteIds: $noteIds) {
            signedOffTime
        }
    }
`;

const signOnNoteMutation = gql`
    mutation signOnNote($noteId: ID!) {
        signOnNote(noteId: $noteId) {
            signedOffTime
        }
    }
`;

const upsertTextNoteMutation = gql`
    mutation upsertTextNote(
        $noteId: ID
        $allowUpdate: Boolean
        $userId: ID!
        $patientId: ID!
        $episodes: [ID]
        $title: String
        $text: String
        $deletedAt: DateTime
    ) {
        upsertTextNote(
            id: $noteId
            allowUpdate: $allowUpdate
            author: $userId
            patient: $patientId
            episodes: $episodes
            title: $title
            text: $text
            deletedAt: $deletedAt
        ) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
            }
        }
    }
`;

const duplicateTextNoteMutation = gql`
    mutation duplicateTextNote($existingTextNoteId: ID, $author: ID!) {
        duplicateTextNote(existingTextNoteId: $existingTextNoteId, author: $author) {
            note {
                id
                __typename
                title
                isSignedOff
                signedOffTime
            }
        }
    }
`;

const tagNoteMutation = gql`
    mutation tagNote($noteId: ID!, $categories: [ID], $episodes: [ID]) {
        tagNote(id: $noteId, episodes: $episodes, categories: $categories) {
            note {
                id
                __typename
                episodes {
                    id
                    name
                    startDate
                    endDate
                }
                categories {
                    id
                }
                title
            }
        }
    }
`;

const initialState = {
    id: undefined,
    categoryIdsArray: [],
    episodeIdsArray: [],
    title: undefined,
    text: undefined,
    loading: false,
    error: false,
    isSignedOff: false,
    signedOffTime: undefined,
    createdAt: undefined,
    preventQuery: false,
    autoFocus: 'text',
    episodes: [],
    author: undefined,
    isAuthor: true,
    patientName: undefined,
};

const handlers = {
    onDuplicate:
        ({ history, userId, patientId, duplicateTextNote, form, updateForm }) =>
        async () => {
            updateForm({
                ...form,
                loading: true,
            });

            try {
                const response = await duplicateTextNote({
                    variables: {
                        existingTextNoteId: form.id,
                        author: userId,
                    },
                });

                updateForm({
                    ...form,
                    loading: false,
                    error: false,
                });

                history.push(`/patient/${patientId}/note/text/${response.data.duplicateTextNote.note.id}`);
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSave:
        ({ dispatch, history, userId, patientId, upsertTextNote, form, updateForm }) =>
        async () => {
            if (!form.id) {
                updateForm({
                    ...form,
                    loading: true,
                });
            } else {
                updateForm({
                    ...form,
                    loading: true,
                    preventQuery: true,
                });
            }

            const newOptions = {
                variables: {
                    allowUpdate: false,
                    userId,
                    patientId,
                    episodes: form.episodeIdsArray,
                    categories: form.categoryIdsArray,
                    title: form.title || 'Untitled Text Note',
                    text: form.text,
                },
            };

            const editOptions = {
                variables: {
                    noteId: form.id,
                    allowUpdate: true,
                    userId,
                    patientId,
                    episodes: form.episodeIdsArray,
                    categories: form.categoryIdsArray,
                    title: form.title || 'Untitled Text Note',
                    text: form.text,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    upsertTextNote: {
                        __typename: 'NewTextNotePayload',
                        note: {
                            __typename: 'TextNote',
                            id: form.id,
                            isSignedOff: form.isSignedOff,
                            signedOffTime: form.signedOffTime,
                            title: form.title,
                            text: form.text,
                            patient: {
                                __typename: 'Patient',
                                id: patientId,
                                name: form.patientName,
                            },
                            author: {
                                __typename: 'User',
                                id: form.author && form.author.id,
                                name: form.author && form.author.name,
                            },
                            episodes: form.episodes.map((episode) => ({
                                __typename: 'Episode',
                                id: episode.id,
                                name: episode.name,
                                startDate: episode.startDate,
                                endDate: episode.endDate,
                            })),
                            categories: form.categoryIdsArray.map((categoryId) => ({
                                __typename: 'Category',
                                id: categoryId,
                            })),
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: textNoteQuery,
                        variables: { noteId: form.id },
                    },
                ],
            };

            try {
                const response = await upsertTextNote(form.id ? editOptions : newOptions);

                dispatch(
                    noteUpsert({
                        __typename: response.data.upsertTextNote.note.__typename,
                        title: response.data.upsertTextNote.note.title,
                        isSignedOff: response.data.upsertTextNote.note.isSignedOff,
                        signedOffTime: response.data.upsertTextNote.note.signedOffTime,
                    })
                );

                if (!form.id) {
                    history.replace(`/patient/${patientId}/note/text/${response.data.upsertTextNote.note.id}`);
                }

                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error: false,
                    preventQuery: true,
                    id: response.data.upsertTextNote.note.id,
                    title: prevForm.title || 'Untitled Text Note',
                }));
            } catch (error) {
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    error,
                }));
            }
        },
    onSign:
        ({ dispatch, form, signOffNotes, signOnNote, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                let response;
                if (form.isSignedOff) {
                    response = await signOnNote({
                        variables: {
                            noteId: form.id,
                        },
                        refetchQueries: [
                            {
                                query: textNoteQuery,
                                variables: { noteId: form.id },
                            },
                        ],
                    });
                } else {
                    response = await signOffNotes({
                        variables: {
                            noteIds: [form.id],
                        },
                        refetchQueries: [
                            {
                                query: textNoteQuery,
                                variables: { noteId: form.id },
                            },
                        ],
                    });
                }

                const signedTime = form.isSignedOff ? response.data.signOnNote.signedOffTime : response.data.signOffNotes.signedOffTime;
                updateForm((prevForm) => ({
                    ...prevForm,
                    loading: false,
                    isSignedOff: !prevForm.isSignedOff,
                    signedOffTime: signedTime,
                    error: false,
                }));
                dispatch(
                    noteUpsert({
                        isSignedOff: Boolean(signedTime),
                        signedOffTime: signedTime,
                    })
                );
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onTag:
        ({ form, tagNote, updateForm }) =>
        async () => {
            updateForm({ ...form, loading: true });

            try {
                const response = await tagNote({
                    variables: {
                        noteId: form.id,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                    },
                });

                updateForm({
                    ...form,
                    loading: false,
                    error: false,
                    episodes: response.data.tagNote.note.episodes,
                });
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
    onArchiveNote:
        ({ form, userId, patientId, updateForm, upsertTextNote, history }) =>
        async () => {
            updateForm({ ...form, loading: true });
            try {
                await upsertTextNote({
                    variables: {
                        noteId: form.id,
                        allowUpdate: true,
                        userId,
                        patientId,
                        episodes: form.episodeIdsArray,
                        categories: form.categoryIdsArray,
                        title: form.title || 'Untitled Text Note',
                        text: form.text,
                        deletedAt: new Date(),
                    },
                });
                updateForm({ ...form, loading: false, error: false });
                history.goBack();
            } catch (error) {
                updateForm({ ...form, loading: false, error });
            }
        },
};

const TextNoteContainer = compose(
    withRouter,
    connect(({ user }) => ({
        isClinician: user.roles.includes('CLINICIAN'),
        isReceptionist: user.roles.includes('RECEPTIONIST'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
        userId: user.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
    })),
    withState('form', 'updateForm', initialState),
    withState('cursor', 'updateCursor', null),
    withProps(({ form, match, location, isClinician, isAdmin, nodeIdForce, canEditForce, isConsentFormSubscription }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
        noteId: nodeIdForce || match.params.noteId,
        initialSave: form.loading && !form.id,
        isHideAction: isConsentFormSubscription,
        canEdit:
            canEditForce !== undefined
                ? canEditForce
                : !form.isSignedOff && (isClinician || isAdmin) && form.isAuthor && Boolean(form.id) && !isConsentFormSubscription,
    })),
    graphql(upsertTextNoteMutation, { name: 'upsertTextNote' }),
    graphql(duplicateTextNoteMutation, { name: 'duplicateTextNote' }),
    graphql(signOffNotesMutation, { name: 'signOffNotes' }),
    graphql(signOnNoteMutation, { name: 'signOnNote' }),
    graphql(tagNoteMutation, { name: 'tagNote' }),
    branch(
        ({ noteId, form }) => noteId && !form.preventQuery,
        compose(
            graphql(textNoteQuery, {
                name: 'query',
                options: ({ noteId }) => ({
                    variables: { noteId },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    success: query.networkStatus === 7 && Boolean(query.node),
                    error: query.networkStatus === 8,
                },
            })),
            branch(
                ({ status }) => status.success,
                withProps(({ query }) => ({
                    __typename: query.node.__typename,
                    id: query.node.id,
                    createdAt: query.node.createdAt,
                    episodeIdsArray: query.node.episodes && query.node.episodes.map((episode) => episode.id),
                    categoryIdsArray: query.node.categories && query.node.categories.map((category) => category.id),
                    title: query.node.title,
                    text: query.node.text,
                    isSignedOff: query.node.isSignedOff,
                    signedOffTime: query.node.signedOffTime,
                    patientId: _.get(query, 'node.patient.id'),
                    patientName: _.get(query, 'node.patient.name'),
                    author: query.node.author,
                    episodes: query.node.episodes,
                }))
            )
        )
    ),
    withHandlers(handlers),
    withPropsOnChange(['onSave'], ({ onSave }) => ({
        onSave: debounce(onSave, 1000),
    })),
    lifecycle({
        async componentDidMount() {
            const {
                id,
                noteId,
                episodeId,
                episodeIdsArray,
                categoryIdsArray,
                title,
                text,
                form,
                updateForm,
                status,
                isSignedOff,
                signedOffTime,
                createdAt,
                author,
                userId,
                patientName,
                episodes,
                onSave,
            } = this.props;

            if (!noteId) {
                await updateForm({
                    ...form,
                    episodeIdsArray: episodeId ? [episodeId] : [],
                });
                onSave();
            } else if (status && status.success) {
                updateForm({
                    ...form,
                    id,
                    episodeIdsArray,
                    categoryIdsArray,
                    title,
                    text,
                    isSignedOff,
                    signedOffTime,
                    createdAt,
                    author,
                    isAuthor: userId === get(author, 'id'),
                    patientName,
                    episodes,
                });
            }
        },
    })
);

export default TextNoteContainer;

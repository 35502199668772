import { gql } from '@apollo/client';
// query {
//   requestReport(
//     practiceEncodedId: "UHJhY3RpY2U6Yjg3MTE0NzItYTYzNS00ZGJkLTljNWItNzQxZmM5ZTQxNGM1"
//     start: "2022-01-11"
//     end: "2022-11-11"
//   ) {
//     file
//     url
//     sentTotal
//     paymentsTotal
//     uncollectedTotal
//   }
// }

export const FETCH_TRANSACTIONS = gql`
    query fetchPaymentReport($start: DateTime!, $end: DateTime!, $locationId: ID!, $practiceEncodedId: ID!, $timezone: String!) {
        fetchPaymentReport(start: $start, end: $end, locationId: $locationId, practiceEncodedId: $practiceEncodedId, timezone: $timezone)
    }
`;

export const FETCH_LOCATION_CLINICIANS = gql`
    query userList($practiceEncodedId: ID!) {
        node(id: $practiceEncodedId) {
            __typename
            id
            ... on Practice {
                users(clinicianOnly: true) {
                    id
                    name
                    email
                    roles
                    locations {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const FETCH_DAILY_APPOINTMENTS = gql`
    query fetchAppointmentReport($clinicianEncodedId: ID!, $start: DateTime!, $end: DateTime!, $timezone: String!) {
        fetchAppointmentReport(clinicianEncodedId: $clinicianEncodedId, start: $start, end: $end, timezone: $timezone)
    }
`;

export const REQUEST_EMAIL_LIST = gql`
    query requestEmailList($practiceEncodedId: ID!) {
        node(id: $practiceEncodedId) {
            __typename
            id
            ... on Practice {
                patients {
                    nodes {
                        name
                        email
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
        }
    }
`;

export const REQUEST_BIRTHDAY_LIST = gql`
    query requestEmailList($practiceEncodedId: ID!, $birthdayMonth: String!) {
        node(id: $practiceEncodedId) {
            __typename
            id
            ... on Practice {
                patients(birthdayMonth: $birthdayMonth, order: [{ sortBy: DOB, sortDirection: ASC }]) {
                    nodes {
                        name
                        dob
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
        }
    }
`;

export const REQUEST_REPORT = gql`
    query requestReport($start: DateTime, $end: DateTime, $clinicianId: ID, $locationId: ID) {
        requestReport(start: $start, end: $end, clinicianId: $clinicianId, locationId: $locationId) {
            file
            url
            sentTotal
            paymentsTotal
            uncollectedTotal
            invoiceItemsArray {
                code
                comments
                sum
                name
                invoiceAccount {
                    billingName
                }
            }
        }
    }
`;

import React from 'react';

import { compose } from 'recompose';

import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CreatePatient = ({ classes, handleChange }) => {
  return (
    <Grid className={classes.container} container spacing={2}>
      <TextField
        className={classes.field}
        autoComplete="off"
        placeholder="Patient First Name*"
        onChange={event => handleChange({ firstName: event.target.value })}
      />
      <TextField
        className={classes.field}
        autoComplete="off"
        placeholder="Patient Last Name*"
        onChange={event => handleChange({ lastName: event.target.value })}
      />
      <TextField
        className={classes.field}
        autoComplete="off"
        placeholder="Email"
        onChange={event => handleChange({ email: event.target.value })}
      />
      <TextField
        className={classes.field}
        autoComplete="off"
        placeholder="Phone"
        onChange={event => handleChange({ phone: event.target.value })}
      />
      <div className={classes.dobField}>
        <TextField
          id="appt-patient-dob"
          placeholder="DOB"
          autoComplete="off"
          className={classes.calendar}
          type="date"
          InputProps={{
            startAdornment: (
              <div className={classes.adornment} position="start">
                DOB*
              </div>
            ),
          }}
          onChange={event =>
            handleChange({
              dob: event.target.value,
            })
          }
        />
      </div>
    </Grid>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 2,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  field: {
    display: 'flex',
    width: 369,
    height: 40,
    marginBottom: 8,
  },
  dobField: {
    display: 'flex',
    alignContent: 'center',
    width: 369,
    height: 40,
    marginBottom: 8,
  },
  adornment: {
    marginRight: 8,
  },
  calendar: {
    width: 369,
    height: 40,
  },
};

export default compose(withStyles(styles))(CreatePatient);

import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { NavLink } from 'react-router-dom';

import { Grid, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { WARM_PINK, WILD_SAND } from '../../style/constants';
import { navCalendar, navForm, navHelp, navInvoice, navProfile, navReport, navSettings, navTemplate, navWhatsNew } from '../../images/NavBar';

import PoweredByMindbodyLogo from '../../images/Integrations/powered-by-mindbody.svg';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Markdown from 'react-markdown';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useLazyQuery } from '@apollo/client';
import { WHATS_NEW_QUERY } from './gql';

const NavBar = ({ classes, practice, hasIntegration }) => {
    const [showWhatsNew, setShowWhatsNew] = React.useState(false);
    const [whatsNew, setWhatsNew] = React.useState([]);

    useEffect(() => {
        getWhatsNew();
    }, []);

    const [getWhatsNew] = useLazyQuery(WHATS_NEW_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.whatsNewQuery) {
                setWhatsNew(res.whatsNewQuery.whatsNew);
                if (res.whatsNewQuery.hasUnread) {
                    setShowWhatsNew(true);
                }
            }
        },
    });

    const openWhatsNew = () => {
        setShowWhatsNew(true);
    };

    const closeWhatsNew = () => {
        setShowWhatsNew(false);
    };

    const renderWhatsNewContent = (whatsNewItem) => {
        const releaseDateText = moment(whatsNewItem.releaseDate).format('YYYY-MM-DD');
        return (
            <div className={classes.whatsNewContentContainer}>
                <Typography className={[classes.whatsNewContent, classes.whatsNewReleaseDate]}>Release {releaseDateText}</Typography>
                <Markdown className={classes.whatsNewContent}>{whatsNewItem.content}</Markdown>
            </div>
        );
    };

    return (
        <div className={practice.locations.length > 0 ? classes.main : classes.noLocation}>
            {practice.locations.some(function (practiceLocation) {
                return practiceLocation.integration !== null && practiceLocation.integration !== undefined;
            }) && <img src={PoweredByMindbodyLogo} width={100} className={classes.poweredByMindbody} alt='Powered by MindBody' />}
            <Grid className={classes.bar} container>
                <Tooltip title='Diary' key={`navbar-tab-link-diary`}>
                    <div style={{ background: window.location.pathname.includes('diary') ? WARM_PINK : 'white' }}>
                        <NavLink to={'/diary'}>
                            <Grid className={classes.iconLink} item>
                                <img src={navCalendar} alt={'Diary'} />
                            </Grid>
                        </NavLink>
                    </div>
                </Tooltip>

                <Tooltip title='Patient' key={`navbar-tab-link-patient`}>
                    <div style={{ background: window.location.pathname.includes('patient') ? WARM_PINK : 'white' }}>
                        <NavLink to={'/patient-dashboard'}>
                            <Grid className={classes.iconLink} item>
                                <img src={navProfile} alt={'Patient Dashboard'} />
                            </Grid>
                        </NavLink>
                    </div>
                </Tooltip>

                <Tooltip title='Invoices' key={`navbar-tab-link-invoices`}>
                    <div style={{ background: window.location.pathname.includes('invoices') ? WARM_PINK : 'white' }}>
                        <NavLink to={'/invoices'}>
                            <Grid className={classes.iconLink} item>
                                <img src={navInvoice} alt={'Invoices'} />
                            </Grid>
                        </NavLink>
                    </div>
                </Tooltip>

                <Tooltip title='Consent Form Dashboard' key={`navbar-tab-link-form`}>
                    <div style={{ background: window.location.pathname.includes('consent-form') ? WARM_PINK : 'white' }}>
                        <NavLink to={'/consent-form-dashboard'}>
                            <Grid className={classes.iconLink} item>
                                <img src={navForm} alt={'Consent Form Dashboard'} />
                            </Grid>
                        </NavLink>
                    </div>
                </Tooltip>

                <Tooltip title='Templates' key={`navbar-tab-link-templates`}>
                    <div style={{ background: window.location.pathname.includes('templates') ? WARM_PINK : 'white' }}>
                        <NavLink to={'/templates'}>
                            <Grid className={classes.iconLink} item>
                                <img src={navTemplate} alt={'Templates'} style={{ width: 26, height: 26 }} />
                                <span className={classes.notifyBadge}>NEW</span>
                            </Grid>
                        </NavLink>
                    </div>
                </Tooltip>

                {!hasIntegration && (
                    <Tooltip title='Reporting' key={`navbar-tab-link-reports`}>
                        <div style={{ background: window.location.pathname.includes('reports') ? WARM_PINK : 'white' }}>
                            <NavLink to={'/reports'}>
                                <Grid className={classes.iconLink} item>
                                    <img src={navReport} alt={'Reporting'} />
                                </Grid>
                            </NavLink>
                        </div>
                    </Tooltip>
                )}

                <Tooltip title='Settings' key={`navbar-tab-link-settings`}>
                    <div style={{ background: window.location.pathname.includes('settings') ? WARM_PINK : 'white' }}>
                        <NavLink to={'/settings'}>
                            <Grid className={classes.iconLink} item>
                                <img src={navSettings} alt={'Settings'} />
                            </Grid>
                        </NavLink>
                    </div>
                </Tooltip>

                <div className={classes.whatsNew}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href='#' onClick={openWhatsNew}>
                        <Grid className={classes.iconLink} key='navbar-tab-whats-new' item>
                            <img src={navWhatsNew} alt='Whats New' />
                        </Grid>
                    </a>
                </div>

                <div className={classes.helpLink}>
                    <a href='https://cliniknote.com/cliniknote-knowledge-base/' target='_blank' rel='noopener noreferrer'>
                        <Grid className={classes.iconLink} key='navbar-tab-link-help' item>
                            <img src={navHelp} alt='Clinik Note Help' />
                        </Grid>
                    </a>
                </div>
            </Grid>
            <Modal open={showWhatsNew}>
                <Paper className={classes.whatsNewContainer}>
                    <div className={classes.whatsNewHeader}>
                        <Typography variant='heading'>What&apos;s New</Typography>
                        <IconButton aria-label='close' className={classes.whatsNewCloseButton} onClick={closeWhatsNew}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {whatsNew.map(renderWhatsNewContent)}
                </Paper>
            </Modal>
        </div>
    );
};

const styles = {
    main: {
        position: 'fixed',
        top: 56,
        left: 0,
        bottom: 0,
        zIndex: 10,
        paddingTop: 32,
        borderRight: `1px solid ${WILD_SAND}`,
    },
    noLocation: {
        position: 'fixed',
        top: 28,
        left: 0,
        bottom: 0,
        zIndex: 10,
        paddingTop: 32,
        borderRight: `1px solid ${WILD_SAND}`,
    },
    bar: {
        display: 'flex',
        flexDirection: 'column',
        width: 72,
        height: 'calc(100vh - 96px)',
        background: 'white',
    },
    iconLink: {
        position: 'relative',
        padding: 24,
        '&:hover': {
            background: WARM_PINK,
        },
    },
    iconLinkDisabled: {
        padding: 24,
        opacity: 0.5,
        '&:hover': {
            background: WILD_SAND,
        },
    },
    highlightedIconLink: {
        padding: 24,
        background: WARM_PINK,
    },
    whatsNew: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
    helpLink: {
        alignSelf: 'flex-end',
    },
    poweredByMindbody: {
        position: 'absolute',
        left: 'calc(100vw - 20px - 100px)',
        bottom: 10,
    },
    notifyBadge: {
        position: 'absolute',
        right: '5px',
        top: '45px',
        background: '#48c85b',
        textAlign: 'center',
        borderRadius: '30px 30px 30px 30px',
        color: 'white',
        padding: '2px 5px',
        fontSize: '10px',
    },
    whatsNewContainer: {
        borderRadius: '25px',
        width: '50%',
        height: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '115px',
    },
    whatsNewHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
        backgroundColor: '#233d4d',
        color: 'white',
        paddingLeft: '25px',
        paddingTop: '15px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        fontSize: '18px',
    },
    whatsNewCloseButton: {
        alignSelf: 'flex-end',
        color: 'white',
    },
    whatsNewContentContainer: {
        marginTop: '15px',
        marginBottom: '50px',
    },
    whatsNewReleaseDate: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    whatsNewContent: {
        paddingLeft: '25px',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, integration }) => ({
        practice,
        hasIntegration: integration.id !== undefined,
    }))
)(NavBar);

import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';

import {
    Button,
    Grid,
    Snackbar,
    Tooltip,
    ButtonGroup,
    Popper,
    Paper,
    MenuItem,
    Grow,
    MenuList,
    ClickAwayListener,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { REQUEST_PDF_EXPORT } from '../InvoicingPage/gql';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import { BOULDER, WILD_SAND, WARM_PINK } from '../../style/constants';

const PdfControls = ({ classes, invoiceDetails, readyToGenerate, upsertInvoice, listInvoiceItems }) => {
    const [successMessage, setSuccessMessage] = useState({ to: [''] });

    const [requestPdf, { loading, error }] = useMutation(REQUEST_PDF_EXPORT, {
        onCompleted: (res) => {
            if (res.requestPdfExport.file) {
                const b64Data = res.requestPdfExport.file.split(',')[1];

                const byteCharacters = atob(b64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i += 1) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const blobUrl = URL.createObjectURL(blob);

                // create <a> tag dinamically
                const fileLink = document.createElement('a');
                fileLink.href = blobUrl;

                // it forces the name of the downloaded file
                fileLink.download = res.requestPdfExport.filename;

                // triggers the click event
                fileLink.click();
            } else if (res.requestPdfExport.emailResponse) {
                setSuccessMessage(JSON.parse(res.requestPdfExport.emailResponse));
            }
        },
    });

    const MENU = [
        {
            title: 'Invoice',
            hidden: ['PAID', 'DRAFT'].includes(invoiceDetails.status),
            open: false,
            options: [
                {
                    title: 'Generate',
                    toolTipTitle: 'This invoice is missing an Invoice Account or Due Date',
                    toolTipdisableHoverListener: readyToGenerate,
                    action: () => {
                        upsertInvoice();
                    },
                    show: invoiceDetails.status === 'DRAFT',
                    disabled: !readyToGenerate,
                },
                {
                    title: 'Email',
                    toolTipTitle: '',
                    toolTipdisableHoverListener: null,
                    action: () => {
                        requestPdf({
                            variables: {
                                invoiceId: invoiceDetails.id,
                                invoiceDate: moment().format(),
                                emailPdf: true,
                            },
                        });
                    },
                    show: invoiceDetails.status === 'OUTSTANDING',
                    disabled: false,
                },
                {
                    title: 'Download',
                    toolTipTitle: '',
                    toolTipdisableHoverListener: null,
                    action: () => {
                        requestPdf({
                            variables: {
                                invoiceId: invoiceDetails.id,
                                invoiceDate: moment().format(),
                                emailPdf: false,
                            },
                        });
                    },
                    show: invoiceDetails.status === 'OUTSTANDING',
                    disabled: false,
                },
            ],
            anchorRef: React.useRef(null),
        },
        {
            title: 'Receipt',
            hidden: ['DRAFT'].includes(invoiceDetails.status),
            open: false,
            options: [
                {
                    title: 'Email',
                    toolTipTitle: '',
                    toolTipdisableHoverListener: null,
                    action: () => {
                        requestPdf({
                            variables: {
                                invoiceId: invoiceDetails.id,
                                invoiceDate: moment().format(),
                                emailPdf: true,
                                isReceipt: true,
                            },
                        });
                    },
                    show: ['OUTSTANDING', 'PAID'].includes(invoiceDetails.status),
                    disabled: false,
                },
                {
                    title: 'Download',
                    toolTipTitle: '',
                    toolTipdisableHoverListener: null,
                    action: () => {
                        requestPdf({
                            variables: {
                                invoiceId: invoiceDetails.id,
                                invoiceDate: moment().format(),
                                emailPdf: false,
                                isReceipt: true,
                            },
                        });
                    },
                    show: ['OUTSTANDING', 'PAID'].includes(invoiceDetails.status),
                    disabled: false,
                },
            ],
            anchorRef: React.useRef(null),
        },
    ];

    const [menuGroups, setMenuGroups] = React.useState(MENU);

    useEffect(() => {
        setMenuGroups(MENU);
    }, [invoiceDetails.status]);

    const handleClick = () => {};

    const handleToggle = (index) => {
        const newMenuGroups = menuGroups.map((menuGroup, i) => {
            if (index === i) {
                menuGroup.open = !menuGroup.open;
            }
            return menuGroup;
        });
        setMenuGroups(newMenuGroups);
    };

    const handleClose = (index) => {
        const newMenuGroups = menuGroups.map((menuGroup, i) => {
            if (index === i) {
                menuGroup.open = false;
            }
            return menuGroup;
        });
        setMenuGroups(newMenuGroups);
    };

    const handleOutstandingStatus = () => {
        upsertInvoice().then((res) => {
            listInvoiceItems({
                variables: {
                    id: invoiceDetails.id,
                },
            });
        });
    };

    if (loading) return <Loading />;
    if (error) return <ErrorMessage error={error} />;

    return (
        <Grid>
            <div className={classes.flexMenu}>
                {invoiceDetails.status === 'DRAFT' && (
                    <div className={classes.buttonContainer}>
                        <Typography>This invoice is still in draft and not ready for issue.</Typography>
                        <Tooltip title='Changes the Invoice Status to Outstanding (Ready to be sent to the Patient)'>
                            <Button className={classes.warmPinkButton} onClick={handleOutstandingStatus}>
                                Mark as Outstanding
                            </Button>
                        </Tooltip>
                    </div>
                )}
                {menuGroups.map((item, idx) => {
                    if (item.hidden) return null;
                    return (
                        <span key={item.title}>
                            <ButtonGroup ref={item.anchorRef} aria-label='split button' className={classes.pinkbg}>
                                <Button onClick={handleClick} className={classes.whiteText}>
                                    {item.title}
                                </Button>
                                <Button
                                    size='small'
                                    aria-controls={item.open ? `split-button-menu-${idx}` : undefined}
                                    aria-expanded={item.open ? 'true' : undefined}
                                    aria-label='select merge strategy'
                                    aria-haspopup='menu'
                                    onClick={() => handleToggle(idx)}
                                    className={classes.whiteText}>
                                    <ArrowDropDownIcon />
                                </Button>
                            </ButtonGroup>
                            <Popper
                                sx={{
                                    zIndex: 1,
                                }}
                                open={item.open}
                                anchorEl={item.anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => handleClose(idx)}>
                                                <MenuList id={`split-button-menu-${idx}`} autoFocusItem>
                                                    {item.options.map((option, index) => (
                                                        <span key={option.title}>
                                                            {option.show && (
                                                                <Tooltip
                                                                    title={option.toolTipTitle}
                                                                    disableHoverListener={option.toolTipdisableHoverListener}>
                                                                    <MenuItem key={option.title} disabled={option.disabled} onClick={option.action}>
                                                                        {option.title}
                                                                    </MenuItem>
                                                                </Tooltip>
                                                            )}
                                                        </span>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </span>
                    );
                })}
            </div>
            <Snackbar
                open={successMessage.from}
                autoHideDuration={3000}
                message={`Email sent from ${successMessage.from} to ${_.join(successMessage.to)}`}
            />
        </Grid>
    );
};

const styles = {
    main: {
        display: 'flex',
        justifyContent: 'center',
        height: () => window.innerWidth > 600 && '100%',
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 66,
        paddingRight: 22,
    },
    clickableRow: {
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftColumn: {
        width: 370,
    },
    rightColumn: {
        marginLeft: 30,
        // TODO: change to flex
        width: 'calc(100% - 370px)',
    },
    subheading: {
        fontWeight: 'bold',
        paddingTop: 36,
        paddingBottom: 8,
    },
    patientSearchContainer: {
        marginTop: 8,
    },
    invoiceDetailContainer: {
        marginTop: 8,
        padding: 12,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    noDividerCell: {
        paddingBottom: '0px !important',
        borderBottom: 'none',
    },
    greyButton: {
        width: 144,
        height: 40,
        color: BOULDER,
        backgroundColor: WILD_SAND,
    },
    warmPinkButton: {
        padding: 10,
        height: 40,
        background: WARM_PINK,
        color: 'white',
        marginLeft: 12,
    },
    pinkbg: {
        backgroundColor: WARM_PINK,
        color: 'white',
    },
    whiteText: {
        color: 'white',
    },
    flexMenu: {
        display: 'flex',
        gap: 10,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, patient, practice }) => ({
        currentLocationId: user.currentLocationId,
        userId: user.id,
        practiceId: practice.id,
        selectedPatient: patient,
        encodedPatientId: patient.id,
        patientName: patient.name,
    }))
)(PdfControls);

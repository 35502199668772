import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import ErrorMessage from '../ErrorMessage';
import EmptyData from '../EmptyData';
import Loading from '../Loading';
import TextNotePreview from '../TextNotePreview';
import DrawingNotePreview from '../DrawingNotePreview';
import GroupDrawingNotePreview from '../GroupDrawingNotePreview';
import FormNotePreview from '../FormNotePreview';

const NotesListPreview = ({
  classes,
  error,
  hasNextPage,
  notes,
  onListEnd,
  status,
}) => (
  <InfiniteScroll
    className={classes.infinite}
    pageStart={0}
    hasMore={hasNextPage}
    useWindow={false}
    loader={<Loading key={1} height={100} />}
    loadMore={onListEnd}
  >
    {(() => {
      if (status.loading || status.setVariables || status.refetching)
        return <Loading height={100} />;
      if (status.success && notes.length === 0)
        return (
          <Paper>
            <EmptyData text="There are no notes available" />
          </Paper>
        );
      if (status.error)
        return (
          <Paper>
            <ErrorMessage error={error} />
          </Paper>
        );

      return (
        notes &&
        notes.map(note => {
          if (get(note, 'group.id'))
            return (
              <GroupDrawingNotePreview
                key={note.group.id}
                groupId={note.group.id}
              />
            );

          switch (note.__typename) {
            case 'TextNote':
              return <TextNotePreview key={note.id} noteId={note.id} />;
            case 'DrawingNote':
              return <DrawingNotePreview key={note.id} noteId={note.id} />;
            case 'FormNote':
              return <FormNotePreview key={note.id} noteId={note.id} />;
            default:
              return null;
          }
        })
      );
    })()}
  </InfiniteScroll>
);

const styles = {
  infinite: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default withStyles(styles)(NotesListPreview);

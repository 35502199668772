import React from 'react';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Modal, Paper, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import { PRIMARY, SECONDARY } from '../../style/constants';
import NotesSidebarItemContainer from './notesSidebarItemContainer';
import TextNote from '../TextNote';
import DrawingNote from '../DrawingNote';
import FormNote from '../FormNote';
import GroupDrawingNote from '../GroupDrawingNote';

const NotesListItem = ({ classes, icon, isAuth, note, isOpenModal, setModalState }) => (
    <Grid>
        {isOpenModal && (
            <Modal
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                open={isOpenModal}
                onClose={() => setModalState(false)}
                className={classes.modal}>
                <Paper elevation={2} className={classes.paper} tabIndex='false'>
                    <IconButton className={classes.cancelIcon} onClick={() => setModalState(false)}>
                        <CancelIcon />
                    </IconButton>
                    {(() => {
                        switch (note.__typename) {
                            case 'TextNote':
                                return <TextNote isHideAction nodeIdForce={note.id} canEditForce={false} />;
                            case 'DrawingNote':
                                if (note.group) {
                                    return <GroupDrawingNote isHideAction groupIdForce={note.group.id} canEditForce={false} />;
                                }
                                return <DrawingNote isHideAction noteIdForce={note.id} canEditForce={false} />;
                            case 'FormNote':
                                return <FormNote isHideAction noteIdForce={note.id} canEditForce={false} />;
                            default:
                                return null;
                        }
                    })()}
                </Paper>
            </Modal>
        )}
        <ListItem button disabled={!isAuth} onClick={() => setModalState(true)} className={classes.listItem}>
            <FontAwesomeIcon className={classes.icon} icon={icon} />
            <Grid container item zeroMinWidth>
                <ListItemText
                    className={classes.title}
                    primaryTypographyProps={{
                        className: classes.titlePrimary,
                    }}
                    primary={<Typography noWrap>{note.title}</Typography>}
                    secondary={
                        <Typography variant='body2' className={classes.body2} noWrap>
                            {Moment(note.updatedAt).format('llll')}
                        </Typography>
                    }
                />
            </Grid>
        </ListItem>
    </Grid>
);

const styles = {
    checkboxRoot: {
        width: 24,
        height: 24,
        marginRight: 8,
        '& > span > svg': {
            width: '0.8em',
            height: '0.8em',
        },
    },
    icon: {
        color: SECONDARY,
        fontSize: '1.45em',
        width: '0.75em !important',
        marginRight: 12,
    },
    title: {
        flexGrow: 1,
        padding: 0,
    },
    titlePrimary: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    secondaryText: {
        flex: 'none',
        flexBasis: 112,
        padding: 0,
        paddingLeft: 14,
    },
    signedOffText: {
        color: 'green',
    },
    ripple: {
        color: PRIMARY,
    },
    body2: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    paper: {
        maxHeight: '80%',
        overflow: 'auto',
        padding: 25,
        position: 'absolute',
        width: '60%',
        background: '#f5f5f5',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    cancelIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: SECONDARY,
    },
};

export default compose(NotesSidebarItemContainer, withStyles(styles))(NotesListItem);

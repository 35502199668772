import React from 'react';
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { practiceSearched } from '../../data/redux/actions/practice';

const SearchBar = ({
  classes,
  barText,
  updateBarText,
  onSearch,
  includeArchived = false,
  showIncludeArchived = true,
}) => (
  <ListItem
    component="form"
    onSubmit={e => {
      e.preventDefault();
      onSearch(barText, includeArchived);
    }}
    className={classes.wrapper}
    divider
  >
    {!!showIncludeArchived && (
      <div className={classes.checkboxContainer}>
        <Checkbox
          onChange={e => onSearch(barText, e.target.checked)}
          checked={includeArchived}
        />{' '}
        Include Archived
      </div>
    )}
    <TextField
      className={classes.search}
      value={barText || ''}
      autoComplete="off"
      placeholder="Search"
      onChange={event => {
        updateBarText(event.target.value);
        onSearch(event.target.value, includeArchived);
      }}
    />
  </ListItem>
);

const styles = {
  wrapper: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 11,
  },
  search: {
    flex: 1,
    width: '100%',
  },
  iconButton: {
    marginLeft: 4,
  },
  checkboxContainer: {
    alignSelf: 'flex-end',
  },
};

export default compose(
  connect(({ practice }) => ({
    searchText: practice.searchText,
    includeArchived: practice.includeArchived,
  })),
  withStyles(styles),
  withState('barText', 'updateBarText', undefined),
  withHandlers({
    onSearch: ({ dispatch }) => (value, includeArchived) =>
      dispatch(practiceSearched(value, includeArchived)),
  }),
  withPropsOnChange(['onSearch'], ({ onSearch }) => ({
    onSearch: debounce(onSearch, 500),
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { searchText, updateBarText } = this.props;

      if (!searchText && searchText !== prevProps.searchText) {
        updateBarText(undefined);
      }
    },
  })
)(SearchBar);

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import { Button, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { SEND_CONSENT_FORM } from '../../ConsentFormList/gql';
import { BOULDER, WARM_PINK, WHITE } from '../../../style/constants';
import SearchBar from '../../../components/SearchBar';
import { isValidEmail } from '../../../utility/string';
import PatientSearch from './PatientSearch';
import { practiceSearched } from '../../../data/redux/actions/practice';

const EnterEmailScreen = ({ classes, form, setForm, locationId, setResponseMessage }) => {
    const [isValid, setIsValid] = useState(true);
    const [emailAddress, setEmailAddress] = useState('');
    const dispatch = useDispatch();

    const handleEmailSelect = (email) => {
        setEmailAddress(email);
    };

    const handleSend = () => {
        sendConsentForm();
    };

    const [sendConsentForm, { loading, error }] = useMutation(SEND_CONSENT_FORM, {
        onCompleted: (result) => {
            if (result.sendConsentForm.success) {
                setResponseMessage({
                    ...form,
                    message: "Consent Form Successfully sent to patient's email address",
                });
            } else {
                setResponseMessage({
                    ...form,
                    message: 'Consent Form could not be sent',
                    severity: 'error',
                });
            }
        },
        variables: {
            emailAddress: _.get(form, 'patient.id') ? '' : emailAddress,
            formKey: form.key,
            patientId: _.get(form, 'patient.id') ? _.get(form, 'patient.id') : '',
            subject: 'Please fill out the attached form',
            locationId,
            // htmlContent: ,
            text:
                'Please click the following link:' +
                `${process.env.REACT_APP_BASE_URL || 'https://staging.app.cliniknote.com'}/consent-form/${form.key}/${_.get(form, 'patient.id', '')}`,
        },
    });

    useEffect(() => {
        if (emailAddress) {
            setIsValid(isValidEmail(emailAddress));
            setForm({ ...form, patient: null });
        }
    }, [emailAddress]);

    useEffect(() => {
        dispatch(practiceSearched(''));
    }, []);

    return (
        <>
            <DialogContent className={classes.contentContainer}>
                <DialogContentText id='alert-dialog-description'>Pick a Patient to send to or directly enter the Email Address:</DialogContentText>
                <SearchBar />
                <PatientSearch form={form} handleEmailSelect={handleEmailSelect} patientId={_.get(form, 'patient.id')} setForm={setForm} />
            </DialogContent>
            <DialogActions>
                {error && <DialogContentText>{error.message}</DialogContentText>}
                {loading && <DialogContentText>Loading...</DialogContentText>}
                <Button onClick={handleSend} color='primary' disabled={!form.key || !isValid || (!emailAddress && !form.patient)}>
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
};

const styles = {
    contentContainer: {
        height: 500,
    },
    field: {
        marginBottom: 8,
    },
    modal: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    redToggleButton: {
        marginLeft: 4,
        padding: 8,
        height: 34,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
        '&:hover': {
            color: BOULDER,
        },
    },
    selector: {},
    whiteButton: {
        background: WHITE,
        color: BOULDER,
    },
};

export default compose(
    connect(({ user, practice }) => ({
        locationId: user.currentLocationId,
        practiceId: practice.id,
    })),
    withStyles(styles)
)(EnterEmailScreen);

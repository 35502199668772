import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';

import {
  Button,
  Paper,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import UPSERT_INVOICE from './gql';
import { WARM_PINK } from '../../style/constants';
import SelectedPatientCard from '../InvoicingPage/SelectedPatientCard';

const NewInvoiceForm = ({ classes, locationId, practiceId }) => {
  const [form, setForm] = useState({
    locationId,
    status: 'DRAFT',
  });
  const [upsertInvoice] = useMutation(UPSERT_INVOICE, {
    onCompleted: res => {
      if (res.upsertInvoice && res.upsertInvoice.id) {
        window.location.href = `/invoices/${res.upsertInvoice.id}`;
      }
    },
  });

  return (
    <div className={classes.leftColumn}>
      <Typography className={classes.subheading} variant="subtitle1">
        Patient
      </Typography>
      <Paper className={classes.patientSearchContainer}>
        <SelectedPatientCard form={form} setForm={setForm} />
      </Paper>

      {!!form.patient && (
        <>
          <Typography className={classes.subheading} variant="subtitle1">
            Invoice Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Invoice Due Date</TableCell>
                  <TableCell>
                    <TextField
                      className={classes.field}
                      type="date"
                      autoComplete="off"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={event => {
                        setForm({ ...form, dateDue: event.target.value });
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            className={classes.redButton}
            variant="contained"
            onClick={async () => {
              await upsertInvoice({
                variables: {
                  dateDue:
                    form.dateDue ||
                    moment()
                      .add(1, 'month')
                      .format('YYYY-MM-DD'),
                  invoiceAccountId: form.account.id,
                  locationId,
                  practiceId,
                },
              });
            }}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
};

const styles = {
  leftColumn: {
    width: '50%',
  },
  subheading: {
    fontWeight: 'bold',
    paddingTop: 36,
    paddingBottom: 8,
  },
  patientSearchContainer: {
    marginTop: 8,
  },
  field: {
    width: 240,
  },
  redButton: {
    marginTop: 16,
    width: 130,
    height: 36,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
  },
};

export default compose(
  withStyles(styles),
  connect(({ practice, user }) => ({
    locationId: user.currentLocationId,
    practiceId: practice.id,
  }))
)(NewInvoiceForm);

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const CheckboxesSectionPreview = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onChangeCheckbox,
  onFocus,
  onSave,
  title,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  checkboxesData,
}) => {
  let preparedCheckboxesData = checkboxesData;
  if (!Array.isArray(checkboxesData)) {
    preparedCheckboxesData = JSON.parse(checkboxesData);
  }
  return (
    <Grid container direction="column">
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {preparedCheckboxesData &&
        Array.isArray(preparedCheckboxesData) &&
        preparedCheckboxesData.map((checkboxData, checkboxIndex) => {
          return (
            <div>
              <Checkbox
                key={checkboxIndex}
                InputProps={{
                  classes: {
                    disabled: true,
                  },
                }}
                disabled
                className={classes.inputText}
                checked={checkboxData.value || false}
                fullWidth
              />
              <label>{checkboxData.title}</label>
            </div>
          );
        })}
    </Grid>
  );
};

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles)
)(CheckboxesSectionPreview);

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import React from 'react';

export default function DeleteConfirmationDialog({
  isOpen,
  onClose,
  onConfirm,
  currentUser,
  form,
}) {
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{' '}
            {currentUser.email === form.email ? 'yourself' : form.name} from
            the system?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="danger">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

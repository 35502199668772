/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { hasPermission } from '../../utility/permissions';

const Restricted = ({ children, isAllowedTo, roles }) => {
  if (hasPermission(roles, isAllowedTo)) {
    return children;
  }
  return null;
};

export default compose(
  connect(({ user }) => ({
    roles: user.roles,
  }))
)(Restricted);

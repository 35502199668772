// action types

export const FORM_TEMPLATE_UPSERT = 'FORM_TEMPLATE_UPSERT';
export const FORM_TEMPLATE_CLEARED = 'FORM_TEMPLATE_CLEARED';

// action creators

export const formTemplateUpsert = ({ id, name, sections, rows }) => ({
  type: FORM_TEMPLATE_UPSERT,
  id,
  name,
  sections,
  rows,
});

export const formTemplateCleared = () => ({ type: FORM_TEMPLATE_CLEARED });

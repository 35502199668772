import React, { Fragment } from 'react';
import { branch, compose, lifecycle, renderComponent, withProps, withState } from 'recompose';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { formTemplateCleared } from '../../data/redux/actions/formTemplate';
import { noteCleared } from '../../data/redux/actions/note';
import { notesCleared } from '../../data/redux/actions/notes';

import AppBarMenu from '../../components/AppBarMenu';
import Episode from '../../components/Episode';
import FloatingActionButtons from '../../components/FloatingActionButtons';
import Notes from '../../components/Notes';
import ConsentFormList from '../ConsentFormList';

const NotesPage = ({ classes, episodeId, location, match, patientId, openFormDialog, updateOpenFormDialog }) => (
    <AppBarMenu>
        <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
            {episodeId && (
                <Fragment>
                    <Typography variant='subtitle1' className={classes.subheading}>
                        Selected Episode
                    </Typography>
                    <Episode />
                </Fragment>
            )}
            {episodeId !== 'new' && <Notes attachments />}
            <Notes location={location} match={match} />
        </Grid>
        {episodeId !== 'new' && (
            <FloatingActionButtons
                episode
                textNote
                mediaNote
                formNote
                consentFormList
                webDrawingNote
                onOpenConsentFormList={() => {
                    updateOpenFormDialog(true);
                }}
            />
        )}
        <Dialog open={openFormDialog} onClose={() => updateOpenFormDialog(false)} maxWidth='md' fullWidth>
            <DialogTitle disableTypography id='alert-dialog-title'>
                <Typography variant='h6' style={{ width: '60%', float: 'left' }}>
                    Consent Forms
                </Typography>
                <IconButton aria-label='close' style={{ float: 'right' }} onClick={() => updateOpenFormDialog(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ConsentFormList patientId={patientId} />
            </DialogContent>
        </Dialog>
    </AppBarMenu>
);

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
    },
    subheading: {
        paddingBottom: 8,
        fontWeight: 'bold',
    },
};

export default compose(
    withStyles(styles),
    withProps(({ match, location }) => ({
        patientId: match.params.patientId,
        episodeId: queryString.parse(location.search).episodeId,
    })),
    branch(
        ({ patientId }) => patientId === 'undefined',
        renderComponent(() => <Redirect to={{ pathname: '/patient/select/notes', search: '?episodeId=new' }} />)
    ),
    withState('openFormDialog', 'updateOpenFormDialog', false),
    lifecycle({
        componentDidMount() {
            this.props.dispatch(formTemplateCleared());
            this.props.dispatch(noteCleared());
            this.props.dispatch(notesCleared());
        },
    })
)(NotesPage);

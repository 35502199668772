import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  DialogActions,
  Button,
} from '@material-ui/core';
import { compose } from 'recompose';

const AppointmentTimeSlotDialog = ({
  appointmentSlot = '',
  setAppointmentSlot,
  handleClose,
  handleSave,
}) => {
  const slotOptions = [
    { value: 15, label: '15 mins' },
    { value: 20, label: '20 mins' },
    { value: 30, label: '30 mins' },
  ];

  const handleClick = event => {
    const newSlot = event.currentTarget.value;
    setAppointmentSlot(newSlot);
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>Appointment Slot</DialogTitle>

      <DialogContent>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Select Time Slot:
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={appointmentSlot.toString()}
            name="radio-buttons-group"
            onChange={handleClick}
          >
            {slotOptions.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value.toString()}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose()(AppointmentTimeSlotDialog);

import { compose, withProps, branch } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';

const GET_FORM_TEMPLATE_LIST = gql`
  query formTemplateList($practiceId: ID!) {
    node(id: $practiceId) {
      __typename
      id
      ... on Practice {
        formTemplates {
          id
          name
          rows
          sections {
            id
            type
            title
            rowIndexes
            columnIndexes
            ... on DrawingSection {
              pageNumber
              group {
                id
              }
              template {
                id
                pageNumber
                file {
                  id
                  #encodedFile
                }
                group {
                  id
                }
              }
            }
            ... on CheckboxesSection {
              checkboxesData
            }
            ... on SpreadsheetSection {
              spreadsheetData
            }
          }
        }
      }
    }
  }
`;

const FormTemplateListContainer = compose(
  withRouter,
  connect(({ practice }) => ({
    practiceId: practice.id,
  })),
  graphql(GET_FORM_TEMPLATE_LIST, {
    name: 'query',
    options: ({ practiceId }) => ({
      variables: { practiceId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withProps(({ location, query }) => ({
    atSelectFormTemplate: location.pathname.includes('/note/form'),
    error: query.error,
    status: {
      loading: query.networkStatus === 1,
      success: query.networkStatus === 7 && Boolean(query.node),
      error: query.networkStatus === 8,
    },
  })),
  branch(
    ({ status }) => status.success,
    withProps(({ query }) => ({
      formTemplates: query.node.formTemplates,
    }))
  )
);

export default FormTemplateListContainer;

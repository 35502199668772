import React from 'react';
import { compose } from 'recompose';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import BaseSettingsPage from './Base';
import IntegrationTable from '../../components/IntegrationTable';

const IntegrationSettings = ({ classes, locationId, practiceEncodedId, isOwner }) => {
    return (
        <BaseSettingsPage>
                <div className={classes.header}>
                    <Typography variant='subtitle1' className={classes.subheading}>
                        Mindbody
                    </Typography>
                    <IntegrationTable />
                </div>
        </BaseSettingsPage>
    );
};

const styles = {
    main: {
        display: 'flex',
        justifyContent: 'center',
        height: () => window.innerWidth > 600 && '100%',
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 88,
        paddingRight: 44,
    },
    content: {
        paddingTop: 56,
    },
    contentContainer: {
        padding: 32,
    },
    subheading: {
        paddingTop: 8,
        paddingBottom: 8,
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
    },
    warningMessage: {
        width: '100%',
        textAlign: 'center',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        user,
        isOwner: user.roles.includes('OWNER'),
        locationId: user.currentLocationId,
        practiceEncodedId: practice.id,
    }))
)(IntegrationSettings);

import { gql } from '@apollo/client';

export const RESCHEDULE_EVENT = gql`
    mutation rescheduleEvent($id: ID!, $start: DateTime!, $end: DateTime!, $userId: ID) {
        rescheduleEvent(id: $id, start: $start, end: $end, userId: $userId) {
            success
        }
    }
`;

export const USER_QUERY = gql`
    query userQuery {
        viewer {
            name
            id
            practice {
                name
                id
            }
            roles
            isSubscriptionValid
            isConsentFormSubscription
            locations {
                id
                name
                integration {
                    id
                }
            }
            location {
                id
                abn
                currency {
                    currency
                    symbol
                    formattedCurrency
                }
            }
        }
    }
`;

export const FETCH_CLINICIAN = gql`
    query fetchClinician($id: ID, $locationId: ID) {
        fetchClinician(id: $id, locationId: $locationId) {
            id
            locationId
            name
            availability
            blockedOut
        }
    }
`;

export const FETCH_LOCATIONS = gql`
    query fetchLocation($practiceEncodedId: ID!) {
        fetchLocation(practiceEncodedId: $practiceEncodedId) {
            id
            appointmentTimeSlot
            name
            paymentLine1
            paymentLine2
            paymentLine3
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            publicHolidays
            integration {
                id
                status
                syncStatus
                siteId
                locationId
                metadata
            }
        }
    }
`;

export const LIST_APPOINTMENTS = gql`
    query listAppointments($start: DateTime, $appointmentId: ID, $locationId: ID!) {
        fetchClinician(locationId: $locationId) {
            id
            name
            availability
            blockedOut
        }
        fetchLocation(id: $locationId) {
            id
            practice {
                id
            }
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            activeClosures
        }
        listEvents(start: $start, id: $appointmentId, locationId: $locationId) {
            id
            notes
            start
            end
            title
            clinician {
                id
                name
            }
        }
        listAppointments(start: $start, id: $appointmentId, locationId: $locationId) {
            id
            appointmentType {
                id
                name
                color
                code
                duration
                price
            }
            patients {
                id
                name
                email
                mobile
                phone
                dob
                alerts {
                    type
                    text
                }
            }
            start
            end
            notes
            clinician {
                id
                name
            }
            checkedIn
            invoiced
            newPatient
            integrationMetadata
        }
    }
`;

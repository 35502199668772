import Moment from 'moment';

export const getThreeMonthsArray = startingDateStr => {
  const prevMonth = Moment(startingDateStr)
    .subtract(1, 'month')
    .format('YYYY-MM-DD');
  const currentMonth = Moment(startingDateStr).format('YYYY-MM-DD');
  const nextMonth = Moment(startingDateStr)
    .add(1, 'month')
    .format('YYYY-MM-DD');
  return [prevMonth, currentMonth, nextMonth];
};

export const aMonthBefore = (before, todayStr) =>
  before
    ? Moment(todayStr)
        .subtract(1, 'month')
        .format('YYYY-MM-DD')
    : Moment(todayStr)
        .add(1, 'month')
        .format('YYYY-MM-DD');

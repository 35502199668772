import { StyleSheet } from '@react-pdf/renderer';

export const GREY = '#555555';
export const BLACK = '#000000';

const PAGE_PADDING = 30;

export const styles = StyleSheet.create({
  page: {
    paddingHorizontal: PAGE_PADDING,
    paddingTop: 110,
    paddingBottom: 40,
  },
  pageCompact: {
    paddingHorizontal: PAGE_PADDING,
    paddingTop: 44,
    paddingBottom: 44,
  },
  header: {
    position: 'absolute',
    top: 20,
    left: PAGE_PADDING,
    right: PAGE_PADDING,
    fontSize: 8,
    lineHeight: 1.3,
    color: GREY,
    borderBottom: `1pt solid ${GREY}`,
  },
  episodeName: {
    marginTop: 10,
    marginBottom: 5,
  },
  logo: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 200,
    height: 40,
    objectFit: 'scale-down',
  },
  userName: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 140,
    height: 40,
    objectFit: 'scale-down',
    textAlign: 'right',
    fontWeight: '600',
  },
  footer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: PAGE_PADDING,
    left: PAGE_PADDING,
    right: PAGE_PADDING,
    paddingTop: 5,
    fontSize: 8,
    color: GREY,
    borderTop: `1pt solid ${GREY}`,
  },
  note: {
    fontSize: 10,
    paddingBottom: 20,
  },
  noteTitle: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  noteMeta: {
    flexDirection: 'row',
    fontSize: 8,
    lineHeight: 2.5,
  },
  noteDetail: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  textNote: {
    lineHeight: 1.5,
  },
  mediaNote: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mediaImage: {
    maxHeight: 500,
    maxWidth: 400,
    objectFit: 'scale-down',
  },
  formSection: {
    paddingBottom: 20,
  },
  formSectionTitle: {
    fontSize: 11,
    lineHeight: 2,
  },
  formSectionText: {
    fontSize: 9,
  },
  formSectionDrawing: {
    width: '100%',
    height: 500,
  },
  formSectionImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 600,
    width: 535,
    objectFit: 'scale-down',
    alignSelf: 'center',
  },
  drawingNote: {
    width: '100%',
    height: '100%',
  },
  drawingImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 600,
    width: 535,
    objectFit: 'scale-down',
    alignSelf: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  row: {
    // marginBottom: 10,
    // marginRight: 10,
    flexDirection: 'row',
    alignItems: 'start',
    width: '100%',
    border: '1 solid #ccc',
    borderBottom: 0,
  },
  line: {
    borderBottom: '1 solid #ccc',
    height: '1',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  description: {
    width: '50',
    height: '50',
    wordBreak: 'break-all',
    borderRight: '1 solid #ccc',
  },
  sheetContainer: {
    marginBottom: '5',
  },
});

import React from 'react';
import { compose, withState } from 'recompose';
import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { withStyles } from '@material-ui/core/styles';
import { WHITE } from '../../style/constants';
import DrawingSVG from '../DrawingSVG';
import EmptyData from '../EmptyData';
import WebDrawingNote from '../WebDrawingNote';

const DrawingSectionTransition = React.forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DrawingSection = ({
  classes,
  section,
  openDialog,
  updateOpenDialog,
  openDrawingDialog,
  updateOpenDrawingDialog,
  groupedSections,
  pageNumber,
  updatePageNumber,
  onSave,
  onChangeDrawingSection,
}) => (
  <Grid item xs>
    <Grid
      role="button"
      onKeyDown={null}
      tabIndex={0}
      className={classes.content}
      onClick={
        section.drawingData || get(section, 'template.file.encodedFile')
          ? () => {
              updateOpenDialog(true);
            }
          : () => {
              updateOpenDrawingDialog(true);
            }
      }
    >
      <Typography variant="body1" className={classes.title}>
        {section.title}
      </Typography>
      {(() => {
        if (section.drawingData || get(section, 'template.file.encodedFile'))
          return (
            <DrawingSVG
              sectionId={get(section, 'id') || ''}
              className={classes.drawing}
              drawingData={section.drawingData}
              templateData={get(section, 'template.file.encodedFile')}
            />
          );

        return (
          <Grid className={classes.emptyData}>
            <EmptyData text="No Drawing Input" />
          </Grid>
        );
      })()}
    </Grid>
    <Dialog
      open={openDialog}
      onClose={() => updateOpenDialog(false)}
      maxWidth="md"
      fullWidth
      TransitionComponent={DrawingSectionTransition}
      scroll="paper"
    >
      <DialogTitle id="customized-dialog-title">
        {get(section, 'title') || 'Drawing section'}
      </DialogTitle>
      <DialogContent dividers>
        {groupedSections && (
          <Grid className={classes.dialogBar}>
            <IconButton
              disabled={pageNumber === 0}
              onClick={() => {
                updatePageNumber(pageNumber - 1);
              }}
            >
              <ChevronLeft />
            </IconButton>
            <Typography variant="body1" className={classes.pageText}>
              Page {pageNumber + 1} of {groupedSections.length}
            </Typography>
            <IconButton
              disabled={pageNumber === groupedSections.length - 1}
              onClick={() => {
                updatePageNumber(pageNumber + 1);
              }}
            >
              <ChevronRight />
            </IconButton>
          </Grid>
        )}
        <DrawingSVG
          className={classes.drawingFullWidth}
          sectionId={get(section, 'id') || ''}
          drawingData={
            groupedSections
              ? groupedSections[pageNumber].drawingData
              : section.drawingData
          }
          templateData={
            groupedSections
              ? groupedSections[pageNumber].template.file.encodedFile
              : get(section, 'template.file.encodedFile')
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            updateOpenDialog(false);
            updateOpenDrawingDialog(true);
          }}
        >
          Edit
        </Button>
        <Button color="secondary" onClick={() => updateOpenDialog(false)}>
          close
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={openDrawingDialog}
      fullScreen
      TransitionComponent={DrawingSectionTransition}
      disableEnforceFocus
    >
      <WebDrawingNote
        formNoteSection={section}
        formNoteGroupedSections={groupedSections}
        onCloseDrawingFormNote={() => {
          updateOpenDrawingDialog(false);
        }}
        onSaveDrawingFormNote={async (updatedSection, isGroup) => {
          // Grouped drawing has been saved before
          if (!isGroup) {
            await onChangeDrawingSection(updatedSection, isGroup, section);
          }
          onSave();
          updateOpenDrawingDialog(false);
        }}
      />
    </Dialog>
  </Grid>
);

const styles = {
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    outline: 'none',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    paddingBottom: 6,
  },
  drawing: {
    height: 212,
    width: 212,
  },
  drawingFullWidth: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  dialogBar: {
    zIndex: 999,
    backgroundColor: WHITE,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageText: {
    padding: [0, 12],
  },
  emptyData: {
    border: [1, 'solid', 'lightgray'],
    height: 200,
    width: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default compose(
  withStyles(styles),
  withState('openDialog', 'updateOpenDialog', false),
  withState('openDrawingDialog', 'updateOpenDrawingDialog', false),
  withState('pageNumber', 'updatePageNumber', 0)
)(DrawingSection);

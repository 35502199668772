import React from 'react';
import { compose } from 'recompose';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import People from '@material-ui/icons/People';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenuContainer from './appBarMenuContainer';
import SideBar from '../SideBar';
import UserPopover from '../UserPopover';
import NavBar from './NavBar';
import { PRIMARY, WHITE } from '../../style/constants';
import ChangeLocationDialog from './ChangeLocationDialog';
import { faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinkWithDisable = ({ children, disabled, to, ...props }) => (
    <Link {...props} component={disabled ? null : RouterLink} underline={disabled ? 'none' : 'hover'} to={disabled ? null : to}>
        {children}
    </Link>
);

const AppBarMenu = ({
    atDiary,
    atSettings,
    atDashboard,
    atEpisode,
    atFormTemplate,
    atSelectFormTemplate,
    atSelectPatient,
    atNote,
    atPatient,
    atUserPractice,
    children,
    classes,
    episodeId,
    episodeName,
    formTemplateName,
    isLoggedIn,
    locationName,
    noteTitle,
    onBack,
    onEpisodeList,
    onPatientList,
    patientId,
    patientName,
    practiceName,
    showBreadCrumbs,
    showEpisodeList,
    showPatientList,
    showSideBar,
    userId,
    userName,
}) => (
    <div className={classes.navContainer}>
        <div className={classes.menuContainer}>
            <AppBar component='nav' classes={{ root: classes.appBar }}>
                <Toolbar className={classes.bar}>
                    <Grid container wrap='nowrap' justifyContent='center' alignItems='center'>
                        <Grid className={classes.leftButtonsWrapper}>
                            {!atDashboard && !atDiary && !atSettings && (
                                <IconButton className={classes.leftButtons} color='inherit' aria-label='Back' onClick={onBack}>
                                    <ArrowBack />
                                </IconButton>
                            )}
                            {showSideBar && (
                                <React.Fragment>
                                    <IconButton className={classes.leftButtons} color='inherit' aria-label='Patient List' onClick={onPatientList}>
                                        {showPatientList ? <People /> : <PeopleOutline />}
                                    </IconButton>
                                    <IconButton
                                        className={showEpisodeList ? classes.leftButtons : classes.leftButtonsEmptyCalendar}
                                        color='inherit'
                                        aria-label='Episode List'
                                        onClick={onEpisodeList}>
                                        {showEpisodeList ? <FontAwesomeIcon icon={faFolderOpen} /> : <FontAwesomeIcon icon={faFolder} />}
                                    </IconButton>
                                </React.Fragment>
                            )}
                        </Grid>
                        <Grid container wrap='nowrap' alignItems='center' className={classes.titleWrapper}>
                            <Link variant='h6' underline='none' component={RouterLink} to='/' className={classes.white}>
                                Clinik Note
                            </Link>
                        </Grid>
                        {userId && <UserPopover />}
                    </Grid>
                </Toolbar>
                {isLoggedIn && showBreadCrumbs && (
                    <Toolbar className={classes.breadcrumbsToolbar}>
                        <Breadcrumbs
                            className={classes.breadcrumbsWrapper}
                            separator={<NavigateNextIcon className={classes.white} fontSize='small' />}
                            aria-label='Breadcrumbs'>
                            <ChangeLocationDialog />
                            {atUserPractice && (
                                <Typography variant='body2' className={classes.white}>
                                    {userName}
                                </Typography>
                            )}
                            {atFormTemplate && formTemplateName && (
                                <Typography variant='body2' className={classes.white}>
                                    {formTemplateName}
                                </Typography>
                            )}
                            {atSelectPatient && (
                                <Typography variant='body2' className={classes.white}>
                                    Select Patient
                                </Typography>
                            )}
                            {atSelectFormTemplate && (
                                <Typography variant='body2' className={classes.white}>
                                    Select Form Template
                                </Typography>
                            )}
                            {!atFormTemplate && !atSelectPatient && patientId && patientName && (
                                <LinkWithDisable variant='body2' className={classes.white} disabled={atPatient} to={`/patient/${patientId}`}>
                                    {patientName}
                                </LinkWithDisable>
                            )}
                            {!atFormTemplate && !atSelectPatient && episodeName && (
                                <LinkWithDisable
                                    variant='body2'
                                    className={classes.white}
                                    disabled={atEpisode}
                                    to={
                                        // eslint-disable-next-line no-nested-ternary
                                        episodeId
                                            ? `/patient/${patientId}/notes?episodeId=${episodeId}`
                                            : episodeId === null
                                              ? `/patient/${patientId}/notes?episodeId`
                                              : `/patient/${patientId}/notes`
                                    }>
                                    {episodeName}
                                </LinkWithDisable>
                            )}
                            {atNote && noteTitle && (
                                <Typography variant='body2' className={classes.white}>
                                    {noteTitle}
                                </Typography>
                            )}
                        </Breadcrumbs>
                    </Toolbar>
                )}
            </AppBar>
            <Grid className={showBreadCrumbs ? classes.breadcrumbsPadding : classes.noBreadCrumbsPadding}>
                {showSideBar ? <SideBar>{children}</SideBar> : children}
            </Grid>
        </div>
        <NavBar />
    </div>
);

const styles = {
    bar: {
        minHeight: 56,
    },
    appBar: {
        backgroundColor: PRIMARY,
    },
    breadcrumbsToolbar: {
        minHeight: 'unset',
    },
    breadcrumbsPadding: {
        paddingTop: 32,
        width: '100%',
        height: '100%',
    },
    noBreadcrumbsPadding: {
        height: '100%',
    },
    breadcrumbsWrapper: {
        paddingBottom: 8,
        color: WHITE,
    },
    leftButtonsWrapper: {
        marginRight: 'auto',
    },
    leftButtons: {
        marginRight: 6,
    },
    leftButtonsEmptyCalendar: {
        marginRight: 6,
        '& svg': {
            width: '0.88em',
            height: '0.88em',
            marginLeft: 1.4,
        },
    },
    titleWrapper: {
        position: 'absolute',
        width: 'unset',
    },
    white: {
        color: WHITE,
    },
};

export default compose(AppBarMenuContainer, withStyles(styles))(AppBarMenu);

import { gql } from '@apollo/client';

export const LIST_MINDBODY_INVOICES = gql`
    query fetchMindbodyInvoices($encodedPracticeId: String) {
        fetchMindbodyInvoices(encodedPracticeId: $encodedPracticeId) {
            id
            invoiceNumber
            patientId
            invoiceUri
            practiceId
            locationId
            patientName
            invoiceDate
            totalAmount
            redeemedAmount
        }
    }
`;

export const GET_MINDBODY_INVOICE_URL = gql`
    query getMindbodyInvoiceUrl($invoiceId: ID) {
        getMindbodyInvoiceUrl(invoiceId: $invoiceId) {
            url
        }
    }
`;

export const SEND_MINDBODY_INVOICE_EMAIL = gql`
    query sendMindbodyInvoiceEmail($invoiceId: ID) {
        sendMindbodyInvoiceEmail(invoiceId: $invoiceId) {
            success
        }
    }
`;

export const FETCH_INTEGRATION_FOR_LOCATION = gql`
    query fetchIntegrationForLocation($encodedPracticeId: ID, $locationId: ID) {
        fetchIntegrationForLocation(encodedPracticeId: $encodedPracticeId, locationId: $locationId) {
            id
            practiceId
            siteId
            locationId
            type
            status
            syncStatus
            metadata
        }
    }
`;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    IconButton,
} from '@material-ui/core';

import { Add, Delete } from '@material-ui/icons';
import Restricted from '../../../components/Restricted';
import { WARM_PINK } from '../../../style/constants';
import { UPSERT_LOCATION } from './gql';
import { hasPermission } from '../../../utility/permissions';
import CustomTooltip from '../../../components/CustomTooltip';

const PublicHolidays = ({
    classes,
    canEdit,
    locationId,
    practiceEncodedId,
    currentClosures,
    setCurrentClosures,
    fetchedClosures,
    setFetchedClosures,
}) => {
    const [showDialog, setShowDialog] = useState(false);

    const [updateLocation] = useMutation(UPSERT_LOCATION, {
        onCompleted: (res) => {
            const closuresResult = _.get(res, 'upsertLocation.closures');
            if (closuresResult) {
                const days = JSON.parse(closuresResult);
                setCurrentClosures(days);
                setFetchedClosures(days);
                handleToggle();
            }
        },
    });

    const handleAddRow = () => {
        const newClosures = [...currentClosures];
        newClosures.push({ date: '', name: '' });
        setCurrentClosures(newClosures);
    };

    const handleCancelModal = () => {
        setCurrentClosures(fetchedClosures);
        setShowDialog(false);
    };

    const handleToggle = () => {
        if (canEdit) {
            setShowDialog(!showDialog);
        }
    };

    const handleSubmit = () => {
        const timezonedClosures = currentClosures.map((closure) => {
            const start = moment(closure.date).startOf('day').toISOString();
            const end = moment(closure.date).endOf('day').toISOString();
            return { ...closure, start, end };
        });
        const closures = JSON.stringify(timezonedClosures);

        updateLocation({
            variables: {
                id: locationId,
                practiceEncodedId,
                closures,
            },
        }).then(() => {
            setShowDialog(false);
        });
    };

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headingContainer}>
                    <Typography variant='subtitle1' className={classes.subheading}>
                        Closures
                    </Typography>
                    <CustomTooltip title='You can add additional days where your Location is closed in addition to the Public Holidays.' />
                </div>

                <Restricted isAllowedTo='upsertLocation'>
                    <Button className={classes.redButton} variant='contained' onClick={handleToggle} disabled={!locationId}>
                        Update Closures
                    </Button>
                </Restricted>
            </div>
            <Paper className={classes.paper}>
                {!locationId && (
                    <Typography variant='body1' className={classes.noLocation}>
                        Please assign a location to this practice to add closures.
                    </Typography>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentClosures &&
                            !!currentClosures.length &&
                            currentClosures.map((holiday) => {
                                if (!holiday.disabled) {
                                    return (
                                        <TableRow hover key={`${holiday.date}-${holiday.name}`} onClick={handleToggle}>
                                            <TableCell>{moment(holiday.date).format('dddd, MMMM Do YYYY')}</TableCell>
                                            <TableCell>{holiday.name}</TableCell>
                                        </TableRow>
                                    );
                                }
                                return null;
                            })}
                    </TableBody>
                </Table>
            </Paper>

            <Dialog open={showDialog} onClose={handleCancelModal} maxWidth='md'>
                <DialogTitle>
                    <Grid container>
                        <Grid className={classes.dialogTitle} item>
                            <Typography variant='h6'>Closures</Typography>
                            <IconButton onClick={handleAddRow}>
                                <Add />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container>
                        <Table className={classes.table} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Name</TableCell>
                                    <TableCell align='left'>Date</TableCell>
                                    <TableCell>Delete?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!currentClosures.length &&
                                    currentClosures.map((item, currentIndex) => {
                                        const { name, date } = item;

                                        const handleChange = (e) => {
                                            const clonedHolidays = [...currentClosures];
                                            clonedHolidays[currentIndex][e.target.name] = e.target.value;
                                            setCurrentClosures(clonedHolidays);
                                        };

                                        const handleRemove = () => {
                                            const clonedHolidays = [...currentClosures];
                                            clonedHolidays.splice(currentIndex, 1);
                                            setCurrentClosures(clonedHolidays);
                                        };

                                        return (
                                            <TableRow hover key={`table-row-${date}`}>
                                                <TableCell>
                                                    <TextField defaultValue={name} autoComplete='off' name='name' onChange={handleChange} />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        defaultValue={date}
                                                        autoComplete='off'
                                                        name='date'
                                                        onChange={handleChange}
                                                        type='date'
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton className={classes.icon} onClick={handleRemove}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color='primary' onClick={handleCancelModal}>
                        Cancel
                    </Button>

                    <Restricted isAllowedTo='upsertLocation'>
                        <Button color='primary' onClick={handleSubmit}>
                            SAVE
                        </Button>
                    </Restricted>
                </DialogActions>
            </Dialog>
        </>
    );
};

const styles = {
    paper: {
        backgroundColor: 'white !important',
        maxHeight: 500,
        overflow: 'auto',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingBottom: 8,
    },
    subheading: {
        fontWeight: 'bold',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    dialogContent: {
        width: 650,
    },
    redButton: {
        height: 36,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
    },
    dropdownOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > *:not(:last-child)': {
            marginRight: 8,
        },
        width: '600px',
        borderBottom: '1px solid #c7c7c7',
    },
    optionDetails: {
        width: 180,
    },
    optionDetailsShort: {
        width: 40,
    },
    noLocation: {
        padding: 16,
        color: WARM_PINK,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        canEdit: hasPermission(user.roles, 'upsertLocation'),
        locationName: user.currentLocationName,
        practiceEncodedId: practice.id,
        userId: user.id,
        locationId: user.currentLocationId,
    }))
)(PublicHolidays);

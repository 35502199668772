// action types

export const PATIENT_SELECTED = 'PATIENT_SELECTED';
export const PATIENT_CLEARED = 'PATIENT_CLEARED';

// action creators

export const patientSelected = ({ id, dob, name, alerts, ...props }) => {
    console.log('patientSelected', id, dob, name, alerts, props);
    return {
        type: PATIENT_SELECTED,
        id,
        dob,
        name,
        alerts,
    };
};

export const patientCleared = () => ({ type: PATIENT_CLEARED });

import React from 'react';
import { compose } from 'recompose';

import { Button, Typography, TextField, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { WARM_PINK } from '../../style/constants';
import ToggleButtonGroup from './ToggleButtonGroup';
import LoginModal from './LoginModal';

const AppointmentDetails = ({
  classes,
  booking,
  setBooking,
  setActiveTab,
  showLoginDialog,
  setShowLoginDialog,
  appointmentTypes,
}) => (
  <div className={classes.contentContainer}>
    <Grid className={classes.headingContent}>
      <Grid item xs={6} sm={6}>
        <Typography variant="h5" component="h1">
          Appointment Details
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} className={classes.loginButtonContainer}>
        <LoginModal
          booking={booking}
          setBooking={setBooking}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      </Grid>
    </Grid>

    <div className={classes.fieldContainer}>
      <Typography className={classes.title} variant="body2">
        What type of appointment do you need?*
      </Typography>

      <ToggleButtonGroup
        optionKey="type"
        booking={booking}
        setBooking={setBooking}
        options={appointmentTypes}
        breakpts={[12, 12]}
      />
    </div>

    <div className={classes.fieldContainer}>
      <Typography
        className={classes.title}
        variant="body2"
        style={{ marginBottom: '0.5rem' }}
      >
        Do you have a specific issue?
      </Typography>

      <TextField
        className={classes.field}
        id="standard-full-width"
        autoComplete="off"
        multiline
        rows={2}
        onChange={event =>
          setBooking({ ...booking, notes: event.target.value })
        }
        value={booking.notes || ''}
        placeholder="Add Notes e.g. Back pain"
      />
    </div>

    <div className={classes.fieldContainer}>
      <Button
        className={classes.nextButton}
        variant="contained"
        color="secondary"
        onClick={() => setActiveTab(2)}
        disabled={!booking.type}
      >
        Next
      </Button>
    </div>
  </div>
);

const styles = {
  contentContainer: {
    padding: '32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    border: 'solid 1px #e6e6e6',
    background: '#ffffff',
    justifyContent: 'start',
    width: '100%',
    height: '100%',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  loginButtonContainer: {
    alignSelf: 'flex-end',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  headingContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px !important',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  nextButton: {
    width: 'auto',
    height: 40,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    alignSelf: 'flex-end',
  },
};

export default compose(withStyles(styles))(AppointmentDetails);

import { gql } from '@apollo/client';

export const FETCH_LOCATION = gql`
  query fetchLocation($id: ID!) {
    fetchLocation(id: $id) {
      id
      abn
      address
      appointmentTimeSlot
      email
      fax
      paymentLine1
      paymentLine2
      paymentLine3
      phone
      mon
      tue
      wed
      thu
      fri
      sat
      sun
      closures
      publicHolidays
      taxRates {
        id
        name
        desc
        ratePercent
      }
    }
  }
`;

export const FETCH_PRACTICE = gql`
  query fetchPractice {
    fetchPractice {
      id
      name
      phone
      address
      email
      abn
      logo {
        id
        encodedFile
      }
    }
  }
`;

export const LIST_APPOINTMENT_TYPES = gql`
  query listAppointmentTypes($locationId: ID!) {
    listAppointmentTypes(locationId: $locationId) {
      id
      code
      desc
      name
      color
      price
      duration
      taxRateIds
      taxRates {
        id
        name
        rate
        ratePercent
        desc
      }
      integrationMetadata
    }
  }
`;

export const LIST_INVENTORY_ITEMS = gql`
  query listInventoryItem($locationId: ID!, $status: InventoryItemState) {
    listInventoryItem(locationId: $locationId, status: $status) {
      id
      category
      code
      description
      price
      status
      stock
      taxRateIds
    }
  }
`;

export const UPSERT_INVENTORY_ITEM = gql`
  mutation upsertInventoryItem(
    $id: ID
    $category: String
    $code: String
    $description: String
    $locationId: ID!
    $practiceEncodedId: ID!
    $price: Float
    $stock: Int
    $taxables: [ID]
  ) {
    upsertInventoryItem(
      category: $category
      code: $code
      description: $description
      id: $id
      locationId: $locationId
      practiceEncodedId: $practiceEncodedId
      price: $price
      stock: $stock
      taxables: $taxables
    ) {
      id
      category
      code
      description
      price
      status
      stock
    }
  }
`;

export const DELETE_INVENTORY_ITEM = gql`
  mutation deleteInventoryItem(
    $id: ID!
    $locationId: ID!
    $practiceEncodedId: ID!
    $status: InventoryItemState
  ) {
    deleteInventoryItem(
      id: $id
      locationId: $locationId
      practiceEncodedId: $practiceEncodedId
      status: $status
    ) {
      id
      status
    }
  }
`;

export const UPDATE_PRACTICE = gql`
  mutation updatePractice(
    $id: ID!
    $abn: String
    $address: String
    $email: String
    $name: String
    $flexibleFieldHeader1: String
    $flexibleFieldHeader2: String
    $flexibleFieldHeader3: String
    $flexibleFieldHeader4: String
    $flexibleFieldHeader5: String
    $phone: String
  ) {
    updatePractice(
      id: $id
      abn: $abn
      address: $address
      email: $email
      name: $name
      flexibleFieldHeader1: $flexibleFieldHeader1
      flexibleFieldHeader2: $flexibleFieldHeader2
      flexibleFieldHeader3: $flexibleFieldHeader3
      flexibleFieldHeader4: $flexibleFieldHeader4
      flexibleFieldHeader5: $flexibleFieldHeader5
      phone: $phone
    ) {
      practice {
        id
        abn
        address
        email
        name
        flexibleFieldHeader1
        flexibleFieldHeader2
        flexibleFieldHeader3
        flexibleFieldHeader4
        flexibleFieldHeader5
        phone
      }
    }
  }
`;

export const UPSERT_LOCATION = gql`
  mutation upsertLocation(
    $id: ID!
    $name: String
    $practiceEncodedId: ID
    $mon: [String]
    $tue: [String]
    $wed: [String]
    $thu: [String]
    $fri: [String]
    $sat: [String]
    $sun: [String]
    $publicHolidays: String
    $appointmentTimeSlot: Int
    $paymentLine1: String
    $paymentLine2: String
    $paymentLine3: String
    $phone: String
  ) {
    upsertLocation(
      id: $id
      name: $name
      practiceEncodedId: $practiceEncodedId
      mon: $mon
      tue: $tue
      wed: $wed
      thu: $thu
      fri: $fri
      sat: $sat
      sun: $sun
      publicHolidays: $publicHolidays
      appointmentTimeSlot: $appointmentTimeSlot
      paymentLine1: $paymentLine1
      paymentLine2: $paymentLine2
      paymentLine3: $paymentLine3
      phone: $phone
    ) {
      id
      name
      appointmentTimeSlot
      paymentLine1
      paymentLine2
      paymentLine3
      mon
      tue
      wed
      thu
      fri
      sat
      sun
      publicHolidays
    }
  }
`;

import React from 'react';


import { withStyles } from '@material-ui/core/styles';

const FlashMessage = ({ className, classes, message }) => {
  let newText = message;

  if (message && message.message) {
    newText = message.message;
    if (
      newText.includes('GraphQL error: ') ||
      newText.includes('Network error: ')
    ) {
      newText = message.message.slice(15);
    }
  } else if (!(typeof message === 'string' || React.isValidElement(message))) {
    newText =
      'There has been an unexpected error, please reload the page or restart the app';
  }

  return <div className={`${classes.text} ${className}`}>{newText}</div>;
};

const styles = {
  text: {
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: 20,
    padding: '.75rem 1.25rem',
    border: '1px solid transparent',
    borderRadius: '.25rem',
    color: ({ success }) => (success ? '#155724' : '#721c24'),
    backgroundColor: ({ success }) => (success ? '#d4edda' : '#f8d7da'),
    borderColor: ({ success }) => (success ? '#c3e6cb' : '#f5c6cb'),
  },
};

export default withStyles(styles)(FlashMessage);

import React, { useState } from 'react';
import { compose } from 'recompose';
import moment from 'moment';

import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import client from '../../data/apollo/client';
import { WARM_PINK } from '../../style/constants';
import { UPSERT_PUBLIC_USER } from './gqlQueries';
import Logo from '../../images/clinik-note-logo-pink.svg';
import LinkPatientScreen from './PublicUsers/LinkPatientScreen';
import LoginModal from './LoginModal';
import ErrorMessage from '../../components/ErrorMessage';

const BookingSummary = ({ classes, booking, setBooking, user, showLoginDialog, setShowLoginDialog, locationId }) => {
    const { patient, patientId, type, time } = booking;
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [showPassword, setShowPassword] = useState(false);
    const [statusLinking, setStatusLinking] = useState();

    const updatePublicUser = () => {
        const variables = {
            name: `${patient.firstName} ${patient.lastName}`,
            email: patient.email,
            password,
            patients: patientId ? [patientId] : [],
            location: locationId,
        };

        client
            .mutate({
                mutation: UPSERT_PUBLIC_USER,
                variables,
            })
            .then(() => setStatusLinking('complete'))
            .catch((err) => setStatusLinking(err));
    };

    if (user.email) {
        return <LinkPatientScreen booking={booking} user={user} />;
    }

    if (statusLinking === 'complete') {
        return (
            <>
                <div className={classes.contentContainer}>
                    <div className={classes.titleContainer}>
                        <img src={Logo} className={classes.logo} alt='Clinik Note Logo' />

                        <Typography variant='h5' component='h1'>
                            Booking Confirmed
                        </Typography>
                        <Grid className={classes.textFieldGroup} container>
                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    Booked {type ? type.desc : ''} <strong>{moment(time.code).format('llll')}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={classes.formContainer}>
                    <div className={classes.fieldContainer}>
                        <Grid className={classes.textFieldGroup} container>
                            <Grid item xs={12} sm={12}>
                                <Typography>Patient Details successfully saved for next time!</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={classes.contentContainer}>
                <div className={classes.titleContainer}>
                    <img src={Logo} className={classes.logo} alt='Clinik Note Logo' />

                    <Typography variant='h5' component='h1'>
                        Booking Confirmed
                    </Typography>
                    <Grid className={classes.textFieldGroup} container>
                        <Grid item xs={12} sm={12}>
                            <Typography>
                                Booked {type.desc || ''} <strong>{moment(time.code).format('llll')}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.formContainer}>
                <div className={classes.fieldContainer}>
                    <Grid className={classes.textFieldGroup} container>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.formText}>Book faster next time</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle2' color='textSecondary' className={classes.formText}>
                                Enter a password to save your details
                            </Typography>
                            <Typography variant='subtitle2' color='textSecondary' className={classes.formText}>
                                Passwords should be at least 8 characters long
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                // MAX TO DO: EYE HIDE ICON FOR PASSWORD FIELD
                                type={showPassword ? 'text' : 'password'}
                                className={classes.field}
                                autoComplete='off'
                                placeholder='Password'
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                // MAX TO DO: EYE HIDE ICON FOR PASSWORD FIELD
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='off'
                                className={classes.field}
                                placeholder='Confirm Password'
                                onChange={(event) => setConfirmPassword(event.target.value)}
                            />
                        </Grid>
                        {statusLinking && statusLinking !== 'complete' && <ErrorMessage error={statusLinking} />}
                        <Grid item xs={12} sm={12}>
                            <div className={classes.buttonGroup}>
                                <Button
                                    className={classes.formButton}
                                    variant='contained'
                                    color='secondary'
                                    // MAX TO DO: error messages for invalid password
                                    disabled={!password || password.length < 8 || password !== confirmPassword}
                                    onClick={updatePublicUser}>
                                    Create Account
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.formContainer}>
                <div className={classes.fieldContainer}>
                    <Grid className={classes.textFieldGroup} container>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.formText}>Already have a ClinikNote account?</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.formText} variant='subtitle2' color='textSecondary'>
                                Sign in to add this booking to your history
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div className={classes.buttonGroup}>
                                <LoginModal
                                    booking={booking}
                                    setBooking={setBooking}
                                    setShowLoginDialog={setShowLoginDialog}
                                    showLoginDialog={showLoginDialog}
                                    noTooltip
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

const styles = {
    contentContainer: {
        padding: '32px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'start',
        border: 'solid 1px #e6e6e6',
        background: '#ffffff',
        width: '100%',
        height: '100%',
        '& > *:not(:last-child)': {
            marginBottom: 40,
        },
    },
    formContainer: {
        padding: '0 32px 32px 32px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
        border: 'solid 0.5px #e6e6e6',
        '& > *:not(:last-child)': {
            marginBottom: 40,
        },
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    textFieldGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
    },
    formButton: {
        width: 'auto',
        height: 40,
        color: '#ffffff',
        backgroundColor: WARM_PINK,
        alignSelf: 'flex-end',
    },
    logo: {
        marginBottom: 22,
        height: 89,
    },
    formText: {
        marginBottom: '1rem',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '1rem',
    },
    field: {
        display: 'flex',
        alignContent: 'center',
        width: 253,
        height: 40,
        marginBottom: 8,
    },
};

export default compose(withStyles(styles))(BookingSummary);

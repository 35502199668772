import React from 'react';
import { compose } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Badge, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';

import { withStyles } from '@material-ui/core/styles';
import { SECONDARY_BLACK } from '../../style/constants';

import UsersTableContainer from './usersTableContainer';

import MindbodyLogo from '../../images/Integrations/mindbody-logo.svg';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import UserService from '../../services/UserService';
import IntegrationService from '../../services/IntegrationService';

const COLUMN_HEADERS = [
    { id: 'name', checkbox: false, label: 'Name' },
    { id: 'admin', checkbox: true, label: 'Admin' },
    { id: 'clinician', checkbox: true, label: 'Clinician' },
    { id: 'receptionist', checkbox: true, label: 'Receptionist' },
    { id: 'isActive', checkbox: true, label: 'Active' },
    { id: 'locations', label: 'Locations' },
];

const UsersTable = ({ classes, status, error, onUser, users, anchorEl, selectedRole, onOpen, onClose }) => (
    <Paper className={classes.paper}>
        {(() => {
            if (status.loading) return <Loading height={105} />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby='tableTitle'>
                        <TableHead>
                            <TableRow>
                                {COLUMN_HEADERS.map((column) => (
                                    <TableCell key={column.id}>
                                        {column.label}
                                        {column.checkbox && (
                                            <FontAwesomeIcon
                                                onClick={(event) => {
                                                    onOpen(event, column.label);
                                                }}
                                                size='lg'
                                                className={classes.permissionInfo}
                                                onMouseEnter={(event) => onOpen(event, column.label)}
                                                onMouseLeave={onClose}
                                                icon={faInfoCircle}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow hover className={classes.tableRow} key={user.id} onClick={() => onUser(user)}>
                                    <TableCell component='th' scope='row'>
                                        {user.name}
                                        <br />
                                        {IntegrationService.hasIntegrationMetadata(user) && (
                                            <Badge
                                                badgeContent={<img src={MindbodyLogo} height={15} alt='Imported from MindBody' />}
                                                className={classes.userBadge}
                                                color='transparent'
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell className={classes.center}>
                                        <Checkbox disabled checked={user.roles.includes('ADMIN')} />
                                    </TableCell>
                                    <TableCell className={classes.center}>
                                        <Checkbox disabled checked={user.roles.includes('CLINICIAN')} />
                                    </TableCell>
                                    <TableCell className={classes.center}>
                                        <Checkbox disabled checked={user.roles.includes('RECEPTIONIST')} />
                                    </TableCell>
                                    <TableCell className={classes.center}>
                                        <Checkbox disabled checked={user.isActive} />
                                    </TableCell>
                                    <TableCell>
                                        {user.locations &&
                                            user.locations.map((location) => (
                                                <Chip color='secondary' className={classes.chip} label={location.name} />
                                            ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Typography style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}>
                        Total Licensed Users: {UserService.NumberOfLicensedUsers(users)}
                    </Typography>
                    <Popover
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverPaper,
                        }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={onClose}>
                        <div className={classes.popoverInfo}>
                            <strong>{selectedRole} Permissions:</strong>
                            <br />
                            <ul>
                                {(() => {
                                    if (selectedRole === 'Owner')
                                        return (
                                            <div>
                                                <li>Edit Practice</li>
                                                <li>Create & Edit Users</li>
                                                <li>Create & Edit Notes</li>
                                            </div>
                                        );
                                    if (selectedRole === 'Admin')
                                        return (
                                            <div>
                                                <li>Edit Practice</li>
                                                <li>Create & Edit Users</li>
                                                <li>Create & Edit Notes</li>
                                            </div>
                                        );
                                    if (selectedRole === 'Clinician')
                                        return (
                                            <div>
                                                <li>Create & Edit Notes</li>
                                            </div>
                                        );
                                    return null;
                                })()}
                                <div>
                                    <li>Create & Edit Episodes</li>
                                    <li>Create & Edit Patients</li>
                                    <li>Create & Edit Form Templates</li>
                                    <li>Create & Edit Attachments</li>
                                    <li>Organise Notes by Categories</li>
                                    <li>Organise Notes by Episodes</li>
                                    <li>Create & Edit Consent Form</li>
                                </div>
                            </ul>
                        </div>
                    </Popover>
                </div>
            );
        })()}
    </Paper>
);

const styles = {
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeaderCheckbox: {
        padding: 16,
    },
    tableRow: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverPaper: {
        padding: 12,
    },
    popoverInfo: {
        color: SECONDARY_BLACK,
        fontSize: 12,
    },
    permissionInfo: {
        marginLeft: 0,
    },
    center: {
        textAlign: 'center',
    },
    userBadge: {
        marginLeft: 45,
        marginTop: -5,
    },
    chip: {
        marginRight: 10,
    },
};

export default compose(withStyles(styles), UsersTableContainer)(UsersTable);

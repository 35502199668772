import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import { withStyles } from '@material-ui/styles';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';

const SpreadSheetSection = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onFocus,
  onSave,
  section,
  onChange,
}) => {
  let spreadsheet;
  const { spreadsheetData } = section;
  return (
    <div>
      <SpreadsheetComponent
        allowsave
        ref={ssObj => {
          spreadsheet = ssObj;
        }}
        created={() => {
          if (spreadsheetData && spreadsheetData.jsonObject) {
            if (spreadsheet)
              spreadsheet.openFromJson({ file: spreadsheetData.jsonObject });
          }
        }}
        cellSave={() => {
          spreadsheet.saveAsJson().then(Json => {
            onChange(Json);
          });
        }}
      />
    </div>
  );
};

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(
  connect(({ user }) => ({
    isClinician: user.roles.includes('CLINICIAN'),
    isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
  })),
  withStyles(styles),
  withHandlers({
    onChange: ({ section, onSave, onChangeSpreadsheetData }) => async data => {
      onChangeSpreadsheetData(data, section);
      onSave();
    },
  })
)(SpreadSheetSection);

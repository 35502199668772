import React, { createRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';

const PdfContainer = props => {
  const bodyRef = createRef();
  const createPdf = () => props.createPdf(bodyRef.current, props.subId);
  return (
    <section className="pdf-container">
      <section className="pdf-body" ref={bodyRef}>
        {props.children}
      </section>

      <Box
        pt={4}
        style={{
          marginBottom: '40px',
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ padding: '20px' }}
        >
          <Button
            style={{ float: 'left' }}
            color="primary"
            variant="outlined"
            onClick={createPdf}
          >
            <Icon>picture_as_pdf</Icon>
          </Button>

          <Button
            style={{ float: 'right' }}
            color="primary"
            variant="outlined"
            onClick={e => {
              props.anchorEl[props.subId] = null;
              props.updateAnchorEl(props.anchorEl);
            }}
          >
            Close
          </Button>
        </Typography>
      </Box>
    </section>
  );
};

export default PdfContainer;

import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
// import DrawingSVG from '../../components/DrawingSVG';

const SubmissionDetailDialog = ({ classes, dialogOpen, handleClose, markAsRead, practiceId, selectedSubmission }) => {
    const handleMarkUnread = () => {
        markAsRead({
            variables: {
                practiceId,
                submissionId: selectedSubmission.id,
                readAt: '',
            },
        }).then(() => {
            handleClose();
        });
    };

    return (
        <Dialog open={dialogOpen} onClose={handleClose} className={classes.dialogContainer}>
            <DialogTitle id='form-dialog-title'>{selectedSubmission.form.title}</DialogTitle>

            <DialogContent className={classes.contentContainer} dividers>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'>Question</TableCell>
                            <TableCell align='left'>Answer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedSubmission.details.map((item, key) => {
                            if (item.type !== 'label') {
                                return (
                                    <TableRow key={item.name}>
                                        <TableCell align='left'>{item.title}</TableCell>
                                        <TableCell align='left'>
                                            {(() => {
                                                if (item.type === 'signature') {
                                                    return <img alt='' src={item.value} width={100} height={75} />;
                                                }
                                                if (item.type === 'date') {
                                                    return moment(item.value).format('YYYY-MM-DD');
                                                }
                                                if (item.type === 'yesNo') {
                                                    return _.upperFirst(item.value);
                                                }
                                                return item.value;
                                            })()}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                            return null;
                        })}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                <div className={classes.buttonContainer}>
                    <Button color='primary' onClick={handleClose}>
                        Back
                    </Button>

                    {selectedSubmission.readAt && (
                        <Button color='primary' onClick={handleMarkUnread}>
                            Mark as Unread
                        </Button>
                    )}

                    {/* <Button
            color="primary"
            onClick={async () => {
              await updateAppointmentType({ ...appointmentType });
              onCloseDialog();
            }}
          >
            Download as PDF
          </Button> */}
                </div>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    contentContainer: {
        width: 590,
        height: 700,
    },
    fieldContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    buttonFieldContainer: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 540,
    },
    title: {
        width: 100,
        marginTop: 16,
    },
    field: {
        width: 240,
    },
    dialogContainer: {
        width: '100%',
    },
    buttonContainer: {
        '& button': {
            marginLeft: 10,
        },
    },
};

export default compose(withStyles(styles))(SubmissionDetailDialog);

import React from 'react';

import { compose, withState } from 'recompose';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const MorePopover = ({ anchorEl, updateAnchorEl, display, disabled }) => (
  <div>
    <IconButton
      disabled={disabled}
      onClick={event => {
        updateAnchorEl(event.currentTarget);
      }}
      aria-label="More"
      color="inherit"
    >
      <MoreHoriz />
    </IconButton>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        updateAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      elevation={2}
    >
      <div
        role="button"
        tabIndex={-1}
        onKeyPress={null}
        onClick={() => updateAnchorEl(null)}
      >
        {display}
      </div>
    </Popover>
  </div>
);

export default compose(withState('anchorEl', 'updateAnchorEl', null))(
  MorePopover
);

import AppBarMenu from '../../components/AppBarMenu';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { BOULDER, WARM_PINK, WILD_SAND } from '../../style/constants';
import { Link } from 'react-router-dom';
import FormTemplateList from '../../components/FormTemplateList';
import TemplateLibrary from '../TemplateLibrary';
import DrawingTemplatePage from '../../components/DrawingTemplateList/drawingTemplatePage';

const TemplatePage = ({ classes, isAdmin }) => {
    const [activeTab, setActiveTab] = useState('form');

    useEffect(() => {
        const path = window.location.pathname.split('/')[2];
        if (path) {
            setActiveTab(path);
        }
    }, []);

    const handleAddNewFormTemplate = () => {
        window.location.href = '/formTemplate';
    };

    /* const handleTemplateLibraryClick = () => {}; */

    /* const handleAddNew = () => {}; */

    const renderFormTemplates = () => {
        if (activeTab !== 'form') return;
        return (
            <>
                <div className={classes.formTemplateHeader}>
                    <span>
                        <Button className={classes.redButton} variant='contained' onClick={handleAddNewFormTemplate}>
                            ADD NEW
                        </Button>
                    </span>
                </div>
                <Grid className={classes.subSection}>
                    <FormTemplateList />
                </Grid>
            </>
        );
    };

    const renderTemplateLibrary = () => {
        if (activeTab !== 'library') return;
        return (
            <>
                <Grid className={classes.subSection}>
                    <TemplateLibrary />
                </Grid>
            </>
        );
    };

    const renderDrawingTemplates = () => {
        if (activeTab !== 'drawing') return;
        return (
            <>
                <Grid className={classes.subSection}>
                    <DrawingTemplatePage />
                </Grid>
            </>
        );
    };

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                <Grid className={classes.header} container>
                    <Grid className={classes.bar} container spacing={6}>
                        <Grid key={`setting-tab-bar-button-forms`} item className={classes.tabItem}>
                            <Link to={'/templates/form'}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={activeTab === 'form' ? classes.activeTab : classes.regularTab}>
                                    Form Templates
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid key={`setting-tab-bar-button-drawing`} item className={classes.tabItem}>
                            <Link to={'/templates/drawing'}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={activeTab === 'drawing' ? classes.activeTab : classes.regularTab}>
                                    Drawing Templates
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid key={`setting-tab-bar-button-library`} item className={classes.tabItem}>
                            <Link to={'/templates/library'}>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={activeTab === 'library' ? classes.activeTab : classes.regularTab}>
                                    Template Library
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid component='main' container justifyContent='center'>
                        <Grid className={classes.content} container direction='column' wrap='nowrap' item xs={12} sm={11} md={12} lg={12} xl={12}>
                            {renderFormTemplates()}
                            {renderTemplateLibrary()}
                            {renderDrawingTemplates()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    content: {
        paddingTop: 28,
    },
    header: {
        display: 'flex',
        paddingBottom: 56,
        width: '100%',
    },
    templateLibraryHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    bar: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
    },
    activeTab: {
        color: WARM_PINK,
        borderBottom: `3px solid ${WARM_PINK}`,
    },
    regularTab: {
        color: 'black',
    },
    tabItem: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },

    formTemplateHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 36,
        paddingBottom: 8,
        width: '100%',
    },
    redButton: {
        height: 36,
        color: WARM_PINK,
        backgroundColor: WILD_SAND,
    },
    greyButton: {
        marginRight: 8,
        height: 36,
        color: BOULDER,
        backgroundColor: WILD_SAND,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user }) => ({
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('OWNER'),
    }))
)(TemplatePage);

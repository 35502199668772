import React from 'react';
import queryString from 'query-string';
import { compose, withState, lifecycle } from 'recompose';

import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { practiceSelected } from '../../data/redux/actions/practice';
import { userAuthenticated, userSelected } from '../../data/redux/actions/user';

const userQuery = gql`
    query userQuery {
        viewer {
            name
            id
            practice {
                name
                id
            }
            roles
            isSubscriptionValid
            isConsentFormSubscription
        }
    }
`;

const AuthenticatedWebView = (props) => {
    const { location, user } = props;
    React.useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data && (typeof event.data === 'string' || event.data instanceof String) && event.data.search('originatingScript') === -1) {
                // updateStateUser({token: event.data});
            }
        });
    }, []);
    if (user && user.email) {
        const queryParams = queryString.parse(location.search);
        return <Redirect to={queryParams.redirectUrl} />;
    }
    return <div></div>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchAuthenticateUser: async (stateUser, client) => {
            dispatch(userAuthenticated(stateUser.email, stateUser.token));
        },
        dispatchUserSelected: async (viewer) => {
            dispatch(
                userSelected({
                    id: viewer.id,
                    name: viewer.name,
                    roles: viewer.roles,
                    isSubscriptionValid: viewer.isSubscriptionValid,
                    isConsentFormSubscription: viewer.isConsentFormSubscription,
                })
            );
        },
        dispatchPracticeSelected: async (viewer) => {
            dispatch(
                practiceSelected({
                    id: viewer.practice.id,
                    name: viewer.practice.name,
                })
            );
        },
    };
};

export default compose(
    withApollo,
    connect(
        ({ user, practice }) => ({
            user,
            practiceId: practice.id,
        }),
        mapDispatchToProps
    ),
    withState('stateUser', 'updateStateUser', { id: '', email: '', token: '' }),
    lifecycle({
        async componentDidMount() {
            const { stateUser, updateStateUser } = this.props;
            const queryParams = queryString.parse(this.props.location.search);
            if (!stateUser.email && queryParams.email) {
                updateStateUser({
                    email: queryParams.email,
                    token: stateUser.token || queryParams.token,
                });
            }
            if (!stateUser.token && queryParams.token) {
                updateStateUser({
                    email: stateUser.email || queryParams.token,
                    token: queryParams.token,
                });
            }
        },
        async componentDidUpdate() {
            const { client, stateUser, updateStateUser, dispatchAuthenticateUser, user, dispatchUserSelected, dispatchPracticeSelected } = this.props;
            if (!user.id) {
                if (stateUser.email && stateUser.token) {
                    dispatchAuthenticateUser(stateUser, client);
                }
                const { data } = await client.query({
                    query: userQuery,
                    fetchPolicy: 'no-cache',
                });

                // const { dispatch, status, query, userId } = this.props;
                if (data) {
                    const { viewer } = data;
                    //
                    if (viewer) {
                        dispatchUserSelected(viewer);
                        dispatchPracticeSelected(viewer);
                        updateStateUser({
                            email: stateUser.email,
                            token: stateUser.token,
                            id: viewer.id,
                        });
                    }
                }
            }
        },
    })
)(AuthenticatedWebView);

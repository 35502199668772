import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { ERROR } from '../../style/constants';
import UserDialogContainer from './userDialogContainer';
import ActivateUserDialog from '../ActivateUserDialog/activateUserDialog';
import ErrorMessage from '../ErrorMessage';
import IntegrationService from '../../services/IntegrationService';
import { FETCH_LOCATIONS, TRANSFER_OWNERSHIP } from './gql';
import { userListQuery } from '../UsersTable/usersTableContainer';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import ConfirmActionDialog from '../ConfirmActionDialog';
import { GET_PRACTICE_SUBSCRIPTION } from '../../pages/SubscriptionPage/gql';
import Logger from '../../services/Logger';
import UserService from '../../services/UserService';

const UserDialog = ({
    classes,
    form,
    onArchiveUser,
    onCheckRole,
    onClose,
    onSave,
    onToggleShowPassword,
    practiceEncodedId,
    practiceName,
    practiceId,
    updateForm,
    userDialogOpen,
    user,
    isOwner,
    isAdmin,
}) => {
    const [locationOptions, setLocationOptions] = useState([]);
    const [confirmTransfer, setConfirmTransfer] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isTransferringOwnership, setIsTransferringOwnership] = useState(form.roles.includes('OWNER'));
    const [allUsers, setAllUsers] = useState([]);
    const [savingOrActivating, setSavingOrActivating] = useState('');
    const [isLegacyPlan, setIsLegacyPlan] = useState(false);
    const [subscription, setSubscription] = useState({});

    const activeUserThresholds = [1, 5, 10, 20];

    const tokenIsLegacy = (planToken) => {
        if (planToken.includes('plan_')) return true;
        // noinspection RedundantIfStatementJS
        if (planToken.includes('price_')) return true;

        return false;
    };

    const [transferOwnership] = useMutation(TRANSFER_OWNERSHIP, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            window.location.reload(true);
        },
    });

    const [getPracticeSubscription] = useLazyQuery(GET_PRACTICE_SUBSCRIPTION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.getPracticeSubscription) {
                if (res.getPracticeSubscription.stripeSubscription) {
                    setIsLegacyPlan(tokenIsLegacy(res.getPracticeSubscription.stripeSubscription.planToken));
                    setSubscription(res.getPracticeSubscription.stripeSubscription);
                } else {
                    setSubscription({});
                }
            }
        },
        variables: {
            practiceId: practiceEncodedId,
        },
    });

    useQuery(FETCH_LOCATIONS, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.fetchLocation) {
                setLocationOptions(res.fetchLocation);
            }
        },
        variables: {
            practiceEncodedId,
        },
    });

    const [getAllUsers] = useLazyQuery(userListQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.node && res.node.users) {
                setAllUsers(res.node.users);
            }
        },
        variables: {
            practiceId: practiceEncodedId,
        },
    });

    useEffect(() => {
        getAllUsers().then(() => {});
        getPracticeSubscription().then(() => {});
    }, []);

    const handleLocationCheck = (event, checked) => {
        const currentUsersLocations = form.locations || [];
        if (checked) {
            const copiedForm = [...currentUsersLocations];
            copiedForm.push(event.target.value);
            updateForm({ ...form, locations: copiedForm });
        } else {
            const indexToRemove = currentUsersLocations.indexOf(event.target.value);
            const copiedForm = [...currentUsersLocations];
            copiedForm.splice(indexToRemove, 1);
            updateForm({ ...form, locations: copiedForm });
        }
    };

    const handleCloseReset = () => {
        setIsTransferringOwnership(false);
        setConfirmTransfer(false);
        onClose();
    };

    const handleSave = async () => {
        setSavingOrActivating('SAVING');
        const planWillChange = checkForPlanChange(false);

        if (!planWillChange) {
            await checkForOwnershipTransfer();
        }
    };

    const checkForOwnershipTransfer = async () => {
        if (confirmTransfer || !isTransferringOwnership) {
            await onSave();
            if (isTransferringOwnership && form.id) {
                await transferOwnership({
                    variables: {
                        practiceEncodedId,
                        userId: form.id,
                    },
                });
            }
        } else {
            setConfirmTransfer(true);
        }
    };

    const [dialogState, setDialogState] = useState('');
    const activateUser = async () => {
        setSavingOrActivating('ACTIVATING');
        const planWillChange = checkForPlanChange(true);
        if (form.isActive) {
            await updateForm({ ...form, isActive: false });
            await onSave();
        } else if (!planWillChange) {
            await updateForm({ ...form, isActive: true });
            await onSave();
            if (IntegrationService.hasIntegrationMetadata(form)) {
                await IntegrationService.InitiateSyncForStaff(practiceId, form.id);
            }
        }
        await getAllUsers();
    };

    const confirmDeleteUser = async () => {
        await onArchiveUser();
        setShowDeleteConfirmation(false);
    };

    const userIsReceptionistOnly = (u) => {
        if (u.roles.includes('OWNER')) {
            return false;
        }
        if (u.roles.includes('ADMIN')) {
            return false;
        }
        if (u.roles.includes('CLINICIAN')) {
            return false;
        }
        return true;
    };

    const userBeingModifiedHasNotGottenNewPermissions = () => {
        const oldUser = allUsers.find((u) => u.id === form.id);
        if (!oldUser) return false;

        if (userIsReceptionistOnly(oldUser)) {
            if (form.roles.includes('OWNER') || form.roles.includes('ADMIN') || form.roles.includes('CLINICIAN')) {
                return false;
            }
        }
        return true;
    };

    /*
    We need the isActivating flag because there is a race condition when trying to read the savingOrActivating state
     */
    const checkForPlanChange = (isActivating) => {
        Logger.LogMessage(`Checking for plan change`);
        if (!subscription || !subscription.planToken) {
            Logger.LogMessage('User is on a trial subscription or cancelled plan');
            return false;
        }

        if (userBeingModifiedHasNotGottenNewPermissions() && !isActivating) {
            Logger.LogMessage('User is not getting new permissions');
            return false;
        }
        if (isLegacyPlan) {
            Logger.LogMessage('Practice is on a legacy plan');
            return false;
        }
        if (userIsReceptionistOnly(form)) {
            Logger.LogMessage('User is a receptionist only');
            return false;
        }
        if (form.isActive && isActivating) {
            Logger.LogMessage('User is being deactivated');
            return false;
        }
        if (!isActivating && !form.isActive) {
            Logger.LogMessage('User is not active');
            return false;
        }

        const totalActiveUsers = allUsers.reduce((accumulator, u) => {
            // eslint-disable-next-line no-param-reassign
            const newAccumulation = u.isActive && UserService.IsLicenseableUser(u) ? 1 : 0;
            return accumulator + newAccumulation;
        }, 0);

        Logger.LogMessage(`totalActiveUsers: ${totalActiveUsers}`);

        // Check thresholds
        for (let i = 0; i < activeUserThresholds.length; i += 1) {
            Logger.LogMessage(`Checking threshold: ${activeUserThresholds[i]}`);
            if (totalActiveUsers === activeUserThresholds[i]) {
                // Alert user that their plan will change
                if (isOwner || isAdmin) {
                    setDialogState('activateUserDialog');
                    return true;
                }
                setDialogState('onlyOwnerCanUpdatePlan');
            }
        }
        return false;
    };
    const processActivateUserDialog = async (action) => {
        if (action === 'confirm' && savingOrActivating === 'ACTIVATING') {
            await updateForm({ ...form, isActive: true });
            await onSave();
            if (IntegrationService.hasIntegrationMetadata(form)) {
                await IntegrationService.InitiateSyncForStaff(practiceId, form.id);
            }
            await getAllUsers();
            setDialogState('');
            return;
        }

        if (action === 'confirm' && savingOrActivating === 'SAVING') {
            await checkForOwnershipTransfer();
        }
        setDialogState('');
    };

    return (
        <>
            <Dialog open={userDialogOpen} onClose={handleCloseReset}>
                <DialogTitle id='form-dialog-title'>{form.id ? 'Edit User' : `New User for ${practiceName}`}</DialogTitle>
                <DialogContent>
                    {!form.id && (
                        <DialogContentText className={classes.contentText}>Please fill out all fields below to create a new user.</DialogContentText>
                    )}
                    <TextField
                        id='name'
                        margin='normal'
                        label='Name'
                        autoFocus
                        autoComplete='off'
                        fullWidth
                        value={form.name || ''}
                        disabled={IntegrationService.objectIsLocked(form)}
                        onChange={(e) => updateForm({ ...form, name: e.target.value })}
                    />
                    <TextField
                        margin='normal'
                        id='email'
                        label='Email Address'
                        autoComplete='off'
                        type='email'
                        value={form.email || ''}
                        disabled={IntegrationService.objectIsLocked(form)}
                        onChange={(e) => updateForm({ ...form, email: e.target.value })}
                        fullWidth
                    />
                    {!form.id && (
                        <>
                            <TextField
                                margin='normal'
                                id='password'
                                autoComplete='off'
                                label='Password'
                                type={form.showPassword ? 'text' : 'password'}
                                value={form.password || ''}
                                onChange={(e) => updateForm({ ...form, password: e.target.value })}
                                fullWidth
                                error={Boolean(form.password && form.confirmPassword && form.confirmPassword !== form.password)}
                                helperText={
                                    Boolean(form.password && form.confirmPassword && form.confirmPassword !== form.password) &&
                                    'Passwords do not match'
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='Toggle password visibility'
                                                onClick={onToggleShowPassword}
                                                onMouseDown={(e) => e.preventDefault()}>
                                                {form.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin='normal'
                                id='confirmPassword'
                                autoComplete='off'
                                label='Confirm Password'
                                type={form.showPassword ? 'text' : 'password'}
                                value={form.confirmPassword || ''}
                                onChange={(e) => updateForm({ ...form, confirmPassword: e.target.value })}
                                fullWidth
                                error={Boolean(form.password && form.confirmPassword && form.confirmPassword !== form.password)}
                                helperText={
                                    Boolean(form.password && form.confirmPassword && form.confirmPassword !== form.password) &&
                                    'Passwords do not match'
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='Toggle password visibility'
                                                onClick={onToggleShowPassword}
                                                onMouseDown={(e) => e.preventDefault()}>
                                                {form.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    )}
                    <FormControl component='fieldset' fullWidth>
                        <FormLabel component='legend' className={classes.formLabel}>
                            Assign Roles
                        </FormLabel>
                        <FormGroup row>
                            {!!form.id && (
                                <Tooltip title='Owners have unrestricted access to all functions. Only one Owner per Practice.'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isTransferringOwnership || form.roles.includes('OWNER')}
                                                value='OWNER'
                                                onChange={() => setIsTransferringOwnership(!isTransferringOwnership)}
                                            />
                                        }
                                        label='Owner'
                                    />
                                </Tooltip>
                            )}
                            <FormControlLabel
                                control={<Checkbox checked={form.roles.includes('ADMIN')} value='ADMIN' onChange={() => onCheckRole('ADMIN')} />}
                                label='Admin'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={form.roles.includes('CLINICIAN')}
                                        value='CLINICIAN'
                                        disabled={IntegrationService.objectIsLocked(form)}
                                        onChange={() => onCheckRole('CLINICIAN')}
                                    />
                                }
                                label='Clinician'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={form.roles.includes('RECEPTIONIST')}
                                        value='RECEPTIONIST'
                                        onChange={() => onCheckRole('RECEPTIONIST')}
                                    />
                                }
                                label='Receptionist'
                            />
                        </FormGroup>
                    </FormControl>
                    {!!locationOptions && !!locationOptions.length && (
                        <FormControl component='fieldset' fullWidth>
                            <FormLabel component='legend' className={classes.formLabel}>
                                Assign Locations
                            </FormLabel>
                            <FormGroup row>
                                {locationOptions.map((location) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.locations && form.locations.includes(location.id)}
                                                name={location.id}
                                                value={location.id}
                                                onChange={handleLocationCheck}
                                            />
                                        }
                                        key={location.id}
                                        label={location.name}
                                    />
                                ))}
                                {(isOwner || isAdmin) && form.id && (
                                    <FormControl component='fieldset' fullWidth>
                                        <FormLabel component='legend' className={classes.formLabel}>
                                            <FormGroup row>
                                                <br />
                                                <Button variant='contained' onClick={activateUser} className={classes.practiceButton}>
                                                    {form.isActive ? 'Deactivate' : 'Activate'}
                                                </Button>
                                            </FormGroup>
                                        </FormLabel>
                                    </FormControl>
                                )}
                            </FormGroup>
                        </FormControl>
                    )}
                    {form.error && <ErrorMessage error={form.error} />}
                </DialogContent>
                <DialogActions>
                    {form.id && user && !IntegrationService.objectIsLocked(form) && !form.roles.includes('OWNER') && (
                        <Button color='secondary' className={classes.deleteButton} onClick={() => setShowDeleteConfirmation(true)}>
                            Delete User?
                        </Button>
                    )}
                    <Button onClick={handleCloseReset} color='primary'>
                        Cancel
                    </Button>
                    <Button disabled={!form.locations || !form.locations.length} onClick={handleSave} color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <DeleteConfirmationDialog
                isOpen={showDeleteConfirmation}
                onClose={() => {
                    setShowDeleteConfirmation(false);
                }}
                currentUser={user}
                form={form}
                onConfirm={() => {
                    confirmDeleteUser();
                }}
            />
            <Dialog open={userDialogOpen && confirmTransfer} onClose={handleCloseReset}>
                <DialogTitle>Confirm Transfer</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Are you sure you want to transfer ownership of this practice to ${form.name}?`}</DialogContentText>
                    <DialogContentText>You will lose all Owner privileges and will need to re-enable yourself if you proceed.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReset} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color='primary'>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <ActivateUserDialog onCloseDialog={(action) => processActivateUserDialog(action)} showDialog={dialogState === 'activateUserDialog'} />
            <ConfirmActionDialog
                open={dialogState === 'onlyOwnerCanUpdatePlan'}
                text='Creating or activating this user will incur a plan change resulting in additional costs. Only an owner can perform this action'
                onClose={() => {
                    setDialogState('');
                }}
                onConfirm={async () => {
                    setDialogState('');
                }}
                cancelText=''
                confirmText='Ok'
            />
        </>
    );
};

const styles = {
    contentText: {
        paddingBottom: 8,
    },
    formLabel: {
        paddingTop: 24,
        paddingBottom: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    deleteButton: {
        marginRight: 'auto',
        color: ERROR,
    },
};

export default compose(
    withStyles(styles),
    connect(({ user }) => ({
        isOwner: user.roles.includes('OWNER'),
        isAdmin: user.roles.includes('ADMIN'),
    })),
    UserDialogContainer
)(UserDialog);

import React from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import BasePatientPage from './Base';
import InvoiceAccountDashboard from '../../components/Patient/InvoiceAccountDashboard';

const AccountsPage = ({ match }) => (
  <BasePatientPage encodedPatientId={match.params.patientId} match={match}>
    <InvoiceAccountDashboard encodedPatientId={match.params.patientId} />
  </BasePatientPage>
);

const styles = {};

export default compose(withStyles(styles))(AccountsPage);

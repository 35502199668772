import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ErrorMessage from '../../../components/ErrorMessage';
import { WARM_PINK } from '../../../style/constants';
import { FETCH_DAILY_APPOINTMENTS } from '../gql';
import CsvDownload from '../../../components/CsvDownload';

const AppointmentReport = ({
  classes,
  clinicianOptions,
  currentLocationId,
  practiceEncodedId,
  userId,
}) => {
  const [clinicianEncodedId, setClinicianEncodedId] = useState(userId);
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState('');
  const timezone = moment.tz.guess();

  const [requestAppointments, { error }] = useLazyQuery(
    FETCH_DAILY_APPOINTMENTS,
    {
      variables: {
        clinicianEncodedId,
        start: moment()
          .startOf('day')
          .toISOString(),
        end: moment()
          .endOf('day')
          .toISOString(),
        timezone,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: res => {
        setCsvData([]);
        setCsvName('');
        const data = _.get(res, 'fetchAppointmentReport', []);
        if (data.length) {
          setCsvData(JSON.parse(data));
          setCsvName(
            `Appointments Report - ${moment().format('YYYY-MM-DD-HH:mm:ss')}`
          );
        }
        return null;
      },
    }
  );

  const handleRequestAppointments = () => {
    requestAppointments();
  };

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Grid item xs={3}>
        <Typography className={classes.descriptor}>Select Clinician</Typography>
      </Grid>
      <Grid item xs={9}>
        <Select className={classes.field} defaultValue={clinicianEncodedId}>
          {clinicianOptions.map(user => (
            <MenuItem
              key={user}
              value={user.id}
              onClick={() => setClinicianEncodedId(user.id)}
            >
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={9}>
        <Button
          className={classes.warmPinkButton}
          onClick={handleRequestAppointments}
        >
          Generate Report
        </Button>
      </Grid>

      {csvData.length > 0 && csvName && (
        <CsvDownload data={csvData} filename={csvName} separator="," />
      )}
    </>
  );
};

const styles = {
  descriptor: {
    marginTop: 6,
  },
  field: {
    width: '100%',
  },
  warmPinkButton: {
    padding: 10,
    height: 40,
    background: WARM_PINK,
    color: 'white',
    marginTop: 12,
  },
};

export default compose(
  withStyles(styles),
  connect(({ user, practice }) => ({
    currentLocationId: user.currentLocationId,
    practiceEncodedId: practice.id,
    userId: user.id,
  }))
)(AppointmentReport);

export class UserService {
    static NumberOfLicensedUsers(users: any[]): number {
        let licensedUsers = 0;
        for (let i = 0; i < users.length; i += 1) {
            if (users[i].isActive && this.IsLicenseableUser(users[i])) {
                licensedUsers += 1;
            }
        }

        return licensedUsers;
    }

    static IsLicenseableUser = (user: any) => {
        if (user.roles.includes('OWNER')) {
            return true;
        }
        if (user.roles.includes('ADMIN')) {
            return true;
        }
        if (user.roles.includes('CLINICIAN')) {
            return true;
        }
        return false;
    };
}

export default UserService;

import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { IntegrationErrorDialog } from '../../components/IntegrationsDialogs';
import { FETCH_INTEGRATION_FOR_LOCATION, GET_MINDBODY_INVOICE_URL, SEND_MINDBODY_INVOICE_EMAIL } from './gql';
import SearchBar from '../InvoicingPage/SearchBar';
import Logger from '../../services/Logger';
import FlashMessage from '../../components/FlashMessage';

const columnData = [
    { id: 'invoiceDate', label: 'Date' },
    { id: 'id', label: 'Invoice #' },
    { id: 'patientName', label: 'Patient Name' },
    { id: 'totalAmount', label: 'Amount' },
    { id: 'redeemedAmount', label: 'Redeemed' },
    { id: 'buttons', label: '' },
];

const MindbodyInvoiceTable = ({ classes, data, practiceId, locationId, hideSyncInvoices, hideSearchBar, location }) => {
    const [dialogState, setDialogState] = useState('');
    /*const [isLoading, setIsLoading] = useState(false);*/
    const [filteredData, setFilteredData] = useState(data);
    const [integration, setIntegration] = useState(null);
    const [invoiceMutex, setInvoiceMutex] = useState(null);

    /*const { REACT_APP_SHOW_ADMIN_FUNCTIONS } = process.env;*/

    const [openInvoice] = useLazyQuery(GET_MINDBODY_INVOICE_URL, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.getMindbodyInvoiceUrl) {
                window.open(res.getMindbodyInvoiceUrl.url, '_blank');
            }
        },
    });

    const [emailInvoice] = useLazyQuery(SEND_MINDBODY_INVOICE_EMAIL, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.sendMindbodyInvoiceEmail) {
                Logger.LogMessage(`Invoice send status: ${res.sendMindbodyInvoiceEmail.success}`);
            }
        },
    });

    const [fetchIntegrationForLocation] = useLazyQuery(FETCH_INTEGRATION_FOR_LOCATION, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.fetchIntegrationForLocation) {
                setIntegration(res.fetchIntegrationForLocation);
                const integrationMetadata = JSON.parse(integration.metadata);
                if (invoiceMutex && invoiceMutex.InvoicesSyncing === true && integrationMetadata.InvoiceMutex.InvoicesSyncing === false) {
                    window.location.reload(true);
                }
                setInvoiceMutex(integrationMetadata.InvoiceMutex);
            }
        },
        variables: {
            encodedPracticeId: practiceId,
            locationId: locationId,
        },
    });

    useEffect(() => {
        fetchIntegrationForLocation();
        const interval = setInterval(() => {
            fetchIntegrationForLocation();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const onSelectInvoice = async (invoiceId) => {
        await openInvoice({
            variables: {
                invoiceId,
            },
        });
    };

    const onEmailInvoice = async (invoiceId) => {
        const invoiceIndex = data.findIndex((invoice) => invoice.id === invoiceId);
        const filteredInvoiceIndex = filteredData.findIndex((invoice) => invoice.id === invoiceId);
        data[invoiceIndex].loading = true;
        filteredData[filteredInvoiceIndex].loading = true;
        setFilteredData([...filteredData]);
        try {
            await emailInvoice({
                variables: {
                    invoiceId,
                },
            });
        } catch {
            data[invoiceIndex].loading = false;
            filteredData[filteredInvoiceIndex].loading = false;
            setFilteredData([...filteredData]);
        }
        data[invoiceIndex].loading = false;
        data[invoiceIndex].emailSent = true;
        filteredData[filteredInvoiceIndex].emailSent = true;
        filteredData[filteredInvoiceIndex].loading = false;
        setFilteredData([...filteredData]);
    };

    /*    const syncInvoices = async () => {
        setIsLoading(true);
        setDialogState('show');

        await IntegrationService.ReSyncInvoices(integration.id);
    };*/

    const formatDate = (date) => {
        const locale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
        return new Date(date).toLocaleDateString(locale);
    };

    /*    const syncButtonText = () => {
        if (integration === null) {
            return 'Loading...';
        }
        if (isLoading) {
            return 'Syncing invoices...';
        }
        if (invoiceMutex && invoiceMutex.InvoicesSyncing === true) {
            if (isLoading === true) setIsLoading(false);
            return 'Syncing invoices...';
        }

        return 'Sync Invoices';
    };*/

    /*    const invoiceProgress = () => {
        if (invoiceMutex && invoiceMutex.InvoicesSyncing === true) {
            const syncProgress = invoiceMutex.InvoiceProgress.indexOf('/') > -1 ? invoiceMutex.InvoiceProgress.split('/')[0] : '1';
            const syncTotal = invoiceMutex.InvoiceProgress.indexOf('/') > -1 ? invoiceMutex.InvoiceProgress.split('/')[1] : '1';
            const progress = (parseInt(syncProgress) / parseInt(syncTotal)) * 100;
            return (
                <div>
                    <Typography>Sync in progress...</Typography>
                    <br />
                    <LinearProgress variant='determinate' value={progress} />
                </div>
            );
        }
        return null;
    };*/

    return (
        <div className={classes.tableWrapper}>
            {location.abn === null || location.abn === undefined || location.abn.trim() === '' ? (
                <FlashMessage
                    message={<div>Mindbody Invoicing is currently paused as you have not added a business ID to your current location</div>}
                />
            ) : null}

            <div>
                {!hideSearchBar && (
                    <SearchBar data={data} setFilteredData={setFilteredData} showStatusFilter={false} width={'266px'} noMargin={true} />
                )}
            </div>

            <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table} aria-labelledby='tableTitle'>
                    <TableHead>
                        <TableRow>
                            {columnData.map((column) => (
                                <TableCell key={column.id}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData &&
                            filteredData.map((invoice) => {
                                return (
                                    <TableRow className={classes.tableRow} hover key={`${invoice.id}${invoice.loading}${invoice.emailSent}`}>
                                        <TableCell>{formatDate(invoice.invoiceDate)}</TableCell>
                                        <TableCell>{invoice.invoiceNumber}</TableCell>
                                        <TableCell>{invoice.patientName}</TableCell>
                                        <TableCell>${(Math.round(invoice.totalAmount * 100) / 100).toFixed(2)}</TableCell>
                                        <TableCell>${(Math.round(invoice.redeemedAmount * 100) / 100).toFixed(2)}</TableCell>
                                        <TableCell align='right'>
                                            <Button
                                                onClick={() => onSelectInvoice(invoice.id)}
                                                variant='contained'
                                                color='secondary'
                                                className={classes.invoiceButton}>
                                                Download
                                            </Button>
                                            <Button
                                                onClick={() => onEmailInvoice(invoice.id)}
                                                disabled={invoice.emailSent || invoice.loading}
                                                variant='contained'
                                                color='secondary'
                                                className={classes.invoiceButton}>
                                                {!invoice.loading && !invoice.emailSent ? 'E-mail' : null}
                                                {!invoice.loading && invoice.emailSent ? 'Sent' : null}
                                                {invoice.loading ? <CircularProgress size={25} /> : null}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <IntegrationErrorDialog
                onCloseDialog={() => {
                    setDialogState('');
                }}
                showDialog={dialogState === 'show'}
                dialogText='Invoices have started syncing. Please allow a few minutes for the sync to complete and then refresh the page.'
                dialogTitle='Sync Invoices'
            />
        </div>
    );
};

const styles = {
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeaderCheckbox: {
        padding: 16,
    },
    invoiceButton: {
        marginLeft: 8,
        marginRight: 8,
        width: '110px',
    },
};

export default compose(
    withStyles(styles),
    connect(({ practice, user }) => ({
        practiceId: practice.id,
        locationId: user.currentLocationId,
        location: user.location,
    }))
)(MindbodyInvoiceTable);

import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenu from '../../components/AppBarMenu';
import './calendarPage.css';
import Diary from './Diary';
import { FETCH_LOCATIONS, USER_QUERY } from './gqlQueries';
import { userSelected } from '../../data/redux/actions/user';
import { practiceSelected } from '../../data/redux/actions/practice';
import { integrationSelected } from '../../data/redux/actions/integration';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Base = ({ classes, userId, practiceId }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        });
    }, [location]);

    useQuery(USER_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res.viewer) {
                const { viewer } = res;
                dispatch(
                    userSelected({
                        id: viewer.id,
                        currentLocationId: _.get(viewer, 'locations.0.id'),
                        currentLocationName: _.get(viewer, 'locations.0.name'),
                        locationOptions: _.get(viewer, 'locations', []),
                        location: _.get(viewer, 'location', {}),
                        name: viewer.name,
                        roles: viewer.roles,
                        isSubscriptionValid: viewer.isSubscriptionValid,
                        isConsentFormSubscription: viewer.isConsentFormSubscription,
                    })
                );
                dispatch(
                    practiceSelected({
                        id: viewer.practice.id,
                        name: viewer.practice.name,
                    })
                );
                dispatch(
                    integrationSelected({
                        id: _.get(viewer, 'locations.0.integration.id'),
                    })
                );
            }
        },
    });

    useQuery(FETCH_LOCATIONS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            if (res.fetchLocation) {
                dispatch(
                    practiceSelected({
                        locations: res.fetchLocation,
                    })
                );
            }
        },
        variables: {
            practiceEncodedId: practiceId,
        },
    });

    return (
        <AppBarMenu dispatch={dispatch}>
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item>
                {userId && <Diary />}
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 64,
        paddingLeft: 91,
        paddingRight: 0,
        paddingBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && 'calc(100vh - 32px)',
        background: 'white',
    },
};

export default compose(
    withStyles(styles),
    connect(({ user, practice }) => ({
        practiceName: practice.name,
        userId: user.id,
        practiceId: practice.id,
    }))
)(Base);

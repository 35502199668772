import React from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import PDFDocument from './PDFDocument';

const EpisodeInPDF = ({ data }) => {
  if (!data) return null;
  const fileName = `${data.patient.name} - ${data.episode.name}.pdf`;
  return (
    <PDFDownloadLink document={<PDFDocument data={data} />} fileName={fileName}>
      {({ url, loading, error: err, ...rest }) => {
        if (url) window.open(url, '_blank');
        if (loading) return <Loading />;
        if (err) return <ErrorMessage error={err} />;
        return <Button>Download PDF</Button>;
      }}
    </PDFDownloadLink>
  );
};

export default EpisodeInPDF;

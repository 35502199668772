import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

import { PRIMARY, SECONDARY } from '../../style/constants';
import NotesItemContainer from './notesItemContainer';
import { Tooltip } from '@material-ui/core';

const NotesListItem = ({ attachments, classes, icon, isAuthor, isAuth, note, showCheckBox, checkedNotesMatch, onCheckNote, onLinkPress }) => {
    const authorInitials = () => {
        const noteAuthorParts = note.author.name.split(' ');

        if (noteAuthorParts.length === 1) return noteAuthorParts[0].substring(0, 1).toUpperCase();
        if (noteAuthorParts.length > 1)
            return `${noteAuthorParts[0].substring(0, 1).toUpperCase()}${noteAuthorParts[noteAuthorParts.length - 1].substring(0, 1).toUpperCase()}`;

        return '?';
    };

    return (
        <ListItem
            button
            onClick={onLinkPress}
            disabled={showCheckBox && (!isAuthor || !isAuth)}
            TouchRippleProps={{
                classes: { root: classes.ripple },
            }}>
            {!attachments && showCheckBox && isAuthor && isAuth && (
                <Checkbox
                    classes={{
                        root: classes.checkboxRoot,
                    }}
                    inputProps={{
                        root: classes.checkboxInputRoot,
                    }}
                    checked={checkedNotesMatch}
                    onChange={() => onCheckNote()}
                />
            )}
            <FontAwesomeIcon className={classes.icon} icon={icon} />
            <Grid container wrap='nowrap'>
                <ListItemText
                    className={classes.title}
                    primaryTypographyProps={{
                        className: classes.titlePrimary,
                    }}
                    primary={note.title}
                />
                {!attachments && (
                    <ListItemText
                        className={classes.secondaryText}
                        secondaryTypographyProps={{
                            className: note.isSignedOff ? classes.signedOffText : null,
                        }}
                        secondary={note.isSignedOff ? Moment(note.signedOffTime).format('ll') : '-------------------'}
                    />
                )}
                <ListItemText className={classes.secondaryText} secondary={Moment(note.createdAt).format('ll')} />
                <ListItemText className={classes.secondaryText} secondary={Moment(note.updatedAt).format('ll')} />
                <ListItemText
                    className={classes.authorProfilePic}
                    primary={
                        <Tooltip title={note.author.name} placement='bottom-start' enterDelay={300}>
                            <Avatar rounded className={classes.avatar} containerStyle={{ backgroundColor: 'gray' }}>
                                {authorInitials()}
                            </Avatar>
                        </Tooltip>
                    }
                />
            </Grid>
        </ListItem>
    );
};

const styles = {
    checkboxRoot: {
        width: 24,
        height: 24,
        marginRight: 8,
        '& > span > svg': {
            width: '0.8em',
            height: '0.8em',
        },
    },
    icon: {
        color: SECONDARY,
        fontSize: '1.45em',
        width: '0.75em !important',
        marginRight: 12,
    },
    title: {
        flexGrow: 1,
        padding: 0,
    },
    avatar: {
        height: 30,
        width: 30,
        fontSize: '0.9rem',
    },
    authorProfilePic: {
        flex: 'none',
        flexBasis: 112,
        flexDirection: 'row',
        padding: 0,
        paddingLeft: 30,
        paddingRight: 20,
    },
    titlePrimary: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    secondaryText: {
        flex: 'none',
        flexBasis: 112,
        padding: 0,
        paddingLeft: 14,
    },
    signedOffText: {
        color: 'green',
    },
    ripple: {
        color: PRIMARY,
    },
};

export default compose(NotesItemContainer, withStyles(styles))(NotesListItem);

import { gql } from '@apollo/client';

export const LOAD_LIST_CONSENT_FORM = gql`
  query listConsentForm($practice_id: String) {
    listConsentForm(practice_id: $practice_id) {
      forms {
        title
        description
        key
        state
        schema
        uiSchema
        submissions_count
        allow_registration
        fields {
          type
          title
          name
          is_required
        }
      }
    }
  }
`;

export const LIST_SUBMISSIONS_BY_PRACTICE = gql`
  query listSubmissionsByPractice($practiceId: ID) {
    listSubmissionsByPractice(practiceId: $practiceId) {
      submissions {
        id
        createdAt
        details {
          title
          type
          name
          value
        }
        form {
          title
        }
        patient_name
        readAt
      }
      pageInfo {
        hasNextPage
        offset
      }
    }
  }
`;

export const LOAD_CONSENT_FORM = gql`
  query getConsentForm($key: String!) {
    getConsentForm(key: $key) {
      form {
        title
        description
        key
        state
        schema
        uiSchema
        submissions_count

        fields {
          type
          title
          name
          is_required
        }
      }
    }
  }
`;

export const LOAD_CONSENT_FORM_SUBMISSIONS = gql`
  query listConsentFormSubmissions($key: String!, $limit: Int, $offset: Int) {
    listConsentFormSubmissions(key: $key, limit: $limit, offset: $offset) {
      submissions {
        id
        createdAt
        patient_id
        patient_name
        details {
          title
          type
          name
          value
        }
      }
      pageInfo {
        hasNextPage
        offset
      }
    }
  }
`;

export const MARK_FORM_SUBMISSION_AS_READ = gql`
  mutation upsertFormSubmission(
    $submissionId: ID!
    $practiceId: ID!
    $readAt: String!
  ) {
    upsertFormSubmission(
      submissionId: $submissionId
      practiceId: $practiceId
      readAt: $readAt
    ) {
      success
    }
  }
`;

export const REMOVE_CONSENT_FORM = gql`
  mutation removeConsentForm($key: String!, $practice_id: String) {
    removeConsentForm(key: $key, practice_id: $practice_id) {
      success
      forms {
        title
        description
        key
        state
        schema
        uiSchema
        submissions_count
        submissions {
          createdAt
          details {
            type
            name
            value
          }
        }
        fields {
          type
          title
          name
          is_required
        }
      }
    }
  }
`;

export const SEND_CONSENT_FORM = gql`
  mutation sendConsentForm(
    $emailAddress: String
    $formKey: String!
    $htmlContent: String
    $locationId: ID!
    $patientId: ID
    $subject: String!
    $text: String!
  ) {
    sendConsentForm(
      emailAddress: $emailAddress
      formKey: $formKey
      htmlContent: $htmlContent
      locationId: $locationId
      patientId: $patientId
      subject: $subject
      text: $text
    ) {
      success
    }
  }
`;

import { branch, compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';
import Jimp from 'jimp';

export const templateListQuery = gql`
    query templateList($practiceId: ID!) {
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                templates {
                    id
                    name
                    deletedAt
                    pageNumber
                    file {
                        id
                    }
                    group {
                        id
                    }
                }
            }
        }
    }
`;

const upsertTemplateMutation = gql`
    mutation upsertTemplate($id: ID, $practice: ID!, $name: String, $encodedFile: String, $deletedAt: DateTime, $pageNumber: Int, $groupId: ID) {
        upsertTemplate(
            id: $id
            practice: $practice
            name: $name
            encodedFile: $encodedFile
            deletedAt: $deletedAt
            pageNumber: $pageNumber
            group: $groupId
        ) {
            template {
                id
                name
                file {
                    id
                    encodedFile
                }
            }
        }
    }
`;

const upsertGroupMutation = gql`
    mutation upsertGroup($practiceId: ID!) {
        upsertGroup(practice: $practiceId) {
            group {
                id
            }
        }
    }
`;

const alertDialogState = {
    show: false,
    selectedTemplate: undefined,
};

const localHandlers = {
    onUploadTemplate:
        ({ practiceId, onCreateGroup, onCreateTemplate, updateShowMaxPageLimit }) =>
        async (files) => {
            if (files.length > 5) {
                updateShowMaxPageLimit(true);
                return;
            }

            let groupId = null;
            if (files.length > 1) {
                const response = await onCreateGroup({ practiceId });
                groupId = response.data.upsertGroup.group.id;
            }

            const filesArray = Array.from(files);

            filesArray.forEach((file, i) => onCreateTemplate(file, i + 1, groupId));
        },

    getFileEncodedFromFileId:
        ({ templateFiles }) =>
        (fileId) => {
            if (fileId) {
                console.log(templateFiles);
                const filteredFile = templateFiles.filter((file) => file.id === fileId);
                if (filteredFile.length > 0) {
                    return filteredFile[0].encodedFile;
                }
            }
            return undefined;
        },
};

const apiHandlers = {
    onArchiveTemplate:
        ({ practiceId, upsertTemplate, updateFileLoading }) =>
        async (templates) => {
            updateFileLoading(true);

            templates.map(async (template) => {
                try {
                    await upsertTemplate({
                        variables: {
                            name: template.name,
                            id: template.id,
                            practice: practiceId,
                            deletedAt: new Date(),
                            pageNumber: template.pageNumber,
                        },
                        refetchQueries: [
                            {
                                query: templateListQuery,
                                variables: { practiceId },
                            },
                        ],
                    });
                    updateFileLoading(false);
                } catch (error) {
                    console.log('API Error: ', error); // eslint-disable-line
                    updateFileLoading(false);
                }
            });
        },
    onCreateGroup:
        ({ upsertGroup, practiceId, updateFileLoading }) =>
        async () => {
            updateFileLoading(true);

            let response;

            try {
                response = await upsertGroup({ practiceId });
                updateFileLoading(false);
            } catch (error) {
                console.log('API Error: ', error); // eslint-disable-line
                updateFileLoading(false);
            }

            return response;
        },
    onCreateTemplate:
        ({ upsertTemplate, practiceId, updateFileLoading }) =>
        async (file, pageNumber, groupId) => {
            updateFileLoading(true);

            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                // removes mime type prefix from reader.result.
                var fileData = reader.result.split(',')[1];
                await Jimp.read(reader.result)
                    .then((image) => {
                        // Do stuff with the image.
                        const maxSize = 2048;
                        if (image.getWidth() > image.getHeight()) {
                            if (image.getWidth() > maxSize) {
                                image.resize(maxSize, Jimp.AUTO, Jimp.RESIZE_BEZIER);
                            }
                        } else {
                            if (image.getHeight() > maxSize) {
                                image.resize(maxSize, Jimp.AUTO, Jimp.RESIZE_BEZIER);
                            }
                        }
                        if (image) {
                            image.getBase64Async(image.getMIME()).then((base64Value) => {
                                if (base64Value.includes(',')) {
                                    fileData = base64Value.split(',')[1];
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        // Handle an exception.
                        console.log('Resize image Error: ' + err);
                    });
                try {
                    await upsertTemplate({
                        variables: {
                            name: file.name,
                            practice: practiceId,
                            encodedFile: fileData,
                            pageNumber,
                            groupId,
                        },
                        refetchQueries: [
                            {
                                query: templateListQuery,
                                variables: { practiceId },
                            },
                        ],
                    });
                    updateFileLoading(false);
                } catch (error) {
                    console.log('API Error: ', error); // eslint-disable-line
                    updateFileLoading(false);
                }
            };
        },
};

export const BLANK_TEMPLATE_ID = 'blankId';

const blankTemplate = {
    id: BLANK_TEMPLATE_ID,
    name: 'Blank',
    file: {
        id: undefined,
        encodedFile: undefined,
    },
};

const DrawingTemplateListContainer = compose(
    connect(({ practice, section }) => ({
        practiceId: practice.id,
        newSectionRowNumber: section.rowNumber,
    })),
    graphql(templateListQuery, {
        name: 'query',
        options: ({ practiceId }) => ({
            variables: { practiceId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withState('fileLoading', 'updateFileLoading', false),
    withState('alertDialog', 'updateAlertDialog', alertDialogState),
    withState('colSize', 'updateColSize', 3),
    withState('showMaxPageLimit', 'updateShowMaxPageLimit', false),
    withState('templateFiles', 'updateTemplateFiles', []),
    graphql(upsertTemplateMutation, { name: 'upsertTemplate' }),
    graphql(upsertGroupMutation, { name: 'upsertGroup' }),
    withHandlers(apiHandlers),
    withHandlers(localHandlers),
    withProps(({ query }) => ({
        error: query.error,
        status: {
            loading: query.networkStatus === 1,
            success: query.networkStatus === 7 && Boolean(query.node),
            error: query.networkStatus === 8,
        },
    })),
    branch(
        ({ status }) => status.success,
        withProps(({ query, shouldHideBlankTemplate }) => {
            if (shouldHideBlankTemplate) {
                return {
                    templates: [...query.node.templates],
                };
            }
            return {
                templates: [blankTemplate, ...query.node.templates],
            };
        })
    ),
    lifecycle({
        componentDidMount() {
            if (window.innerWidth < 600) this.props.updateColSize(1);
            if (window.innerWidth >= 600) this.props.updateColSize(2);
            if (window.innerWidth >= 960) this.props.updateColSize(3);
        },
    })
);

export default DrawingTemplateListContainer;

import React from 'react';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const TableSection = ({
  autoFocus,
  classes,
  initialSave,
  isSignedOff,
  onFocus,
  onSave,
  section,
  isAuthor,
  isAdmin,
  isClinician,
  canEdit,
  onChange,
}) => (
  <Grid container direction="column">
    <Typography variant="body1" className={classes.title}>
      {section.title}
    </Typography>
    <Typography>This is Table Section Preview</Typography>
  </Grid>
);

const styles = {
  disabled: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 6,
    marginBottom: 34,
  },
  inputText: {
    flex: 1,
    paddingBottom: 16,
  },
  wrapper: {
    flex: 1,
  },
};

export default compose(withStyles(styles))(TableSection);

import React from 'react';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import filter from 'lodash/filter';
import range from 'lodash/range';
import get from 'lodash/get';

import FormNoteContainer from './formNoteContainer';

import CategoryListPopover from '../CategoryListPopover';
import DeleteNoteDialog from '../DeleteNoteDialog';
import DrawingSection from '../DrawingSection';
import DuplicateDialog from '../DuplicateDialog';
import EpisodePopover from '../EpisodePopover';
import ErrorMessage from '../ErrorMessage';
import FormTemplateList from '../FormTemplateList';
import Loading from '../Loading';
import MoreInfoPopover from '../MoreInfoPopover';
import SignOffDialog from '../SignOffDialog';
import TextSection from '../TextSection';
import ErrorAlertDialog from '../ErrorAlertDialog';
import CheckboxesSection from '../CheckboxesSection';
import YesNoSection from '../YesNoSection';
import NumberScale10Section from '../NumberScale10Section';
import NumberScale100Section from '../NumberScale100Section';
import PrintNote from '../PrintNote';
import SpreadSheetSection from '../SpreadSheetSection';

const FormNote = ({
    classes,
    error,
    form,
    formTemplateId,
    initialSave,
    isClinician,
    isReceptionist,
    isAdmin,
    noteId,
    onDuplicate,
    onChangeText,
    onSave,
    onSign,
    onTag,
    status = {},
    updateForm,
    canEdit,
    onArchiveNote,
    onChangeDrawingSection,
    isHideAction,
    onChangeCheckbox,
    onChangeYesNoValue,
    onChangeScale10Value,
    onChangeScale100Value,
    onChangeSpreadsheetData,
    cursors,
    hidePrintPdf,
}) => {
    if (!noteId && !formTemplateId)
        return (
            <Grid container wrap='nowrap' direction='column'>
                <Typography variant='subtitle1' className={classes.selectHeading}>
                    Select Form Template
                </Typography>
                <FormTemplateList formNotePage />
            </Grid>
        );

    return (
        <Grid>
            <div className={classes.titleRow}>
                <TextField
                    id='title'
                    autoComplete='off'
                    label='Title'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        classes: {
                            disabled: classes.disabled,
                        },
                    }}
                    autoFocus={form.autoFocus === 'title'}
                    disabled={!canEdit || initialSave || status.loading}
                    placeholder='Untitled Form Note'
                    className={classes.inputTitle}
                    value={form.title || ''}
                    onChange={async (event) => {
                        let shouldSave = false;
                        if (event.target.value) shouldSave = true;
                        await updateForm({
                            ...form,
                            title: event.target.value,
                            autoFocus: 'title',
                        });
                        if (shouldSave) onSave();
                    }}
                />
                {isHideAction || (
                    <Grid className={classes.buttons}>
                        {form.loading && <Typography variant='body2'>Saving ...</Typography>}
                        {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                            <EpisodePopover
                                noteId={form.id}
                                selectedEpisodes={form.episodeIdsArray}
                                onTag={onTag}
                                loading={status.loading}
                                updateEpisodes={(episodeIdsArray) => {
                                    updateForm({ ...form, episodeIdsArray });
                                }}
                            />
                        )}
                        {((form.isAuthor && isClinician) || isReceptionist || isAdmin) && (
                            <CategoryListPopover
                                noteId={form.id}
                                selectedCategories={form.categoryIdsArray}
                                onTag={onTag}
                                loading={status.loading}
                                updateCategories={(categoryIdsArray) =>
                                    updateForm({
                                        ...form,
                                        categoryIdsArray: categoryIdsArray || [],
                                    })
                                }
                            />
                        )}
                        {((form.isAuthor && (isClinician || isAdmin)) || form.isSignedOff) && (
                            <SignOffDialog
                                noteId={form.id}
                                isSignedOff={form.isSignedOff}
                                signedOffTime={form.signedOffTime}
                                onSign={onSign}
                                loading={status.loading}
                                authorName={form.authorName}
                            />
                        )}
                        {(isClinician || isAdmin) && <DuplicateDialog noteId={form.id} loading={status.loading} onDuplicate={onDuplicate} />}
                        <MoreInfoPopover
                            authorName={form.author && form.author.name}
                            createdAt={form.createdAt}
                            episodes={form.episodes}
                            loading={status.loading}
                            noteId={form.id}
                            patientName={form.patientName}
                            signedOffTime={form.signedOffTime}
                        />
                        {form.isAuthor && (isClinician || isAdmin) && (
                            <DeleteNoteDialog
                                isSignedOff={form.isSignedOff}
                                loading={status.loading}
                                noteId={form.id}
                                onArchiveNote={() => onArchiveNote()}
                            />
                        )}

                        {form.isAuthor && (isClinician || isAdmin) && !hidePrintPdf && (
                            <PrintNote form={form} typename='FormNote' loading={status.loading} noteId={form.id} />
                        )}
                    </Grid>
                )}
            </div>
            <Paper className={classes.paper}>
                {(() => {
                    if (status.loading) return <Loading />;
                    if (status.error) return <ErrorMessage error={error} />;

                    if (form.rows) {
                        const rowsRange = range(1, form.rows + 1);

                        return (
                            <Grid container direction='column'>
                                {form.error && !form.loading && <ErrorAlertDialog error={form.error} />}
                                {rowsRange.map((rowNumber) => (
                                    <Grid key={rowNumber} container direction='row' wrap='nowrap' className={classes.row}>
                                        {filter(form.sections, {
                                            rowIndexes: [rowNumber],
                                        }).map((section, index) =>
                                            (() => {
                                                if (get(section, 'template.pageNumber') > 1) return null;
                                                return (
                                                    <Grid key={section.id || section.type} className={classes.column} item>
                                                        {(() => {
                                                            switch (section.type) {
                                                                case 'TEXT': {
                                                                    const selectionStart = cursors[index];
                                                                    return (
                                                                        <TextSection
                                                                            selectionStart={selectionStart}
                                                                            cursors={cursors}
                                                                            isAuthor={form.isAuthor}
                                                                            autoFocus={form.autoFocus}
                                                                            canEdit={canEdit}
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            initialSave={initialSave}
                                                                            isSignedOff={form.isSignedOff}
                                                                            onChangeText={onChangeText}
                                                                            onFocus={() =>
                                                                                updateForm({
                                                                                    ...form,
                                                                                    autoFocus: section.rowIndexes
                                                                                        .concat(section.columnIndexes)
                                                                                        .join(),
                                                                                })
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                                case 'YESNO':
                                                                    return (
                                                                        <YesNoSection
                                                                            isAuthor={form.isAuthor}
                                                                            autoFocus={form.autoFocus}
                                                                            canEdit={canEdit}
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            initialSave={initialSave}
                                                                            isSignedOff={form.isSignedOff}
                                                                            onChangeYesNoValue={onChangeYesNoValue}
                                                                        />
                                                                    );
                                                                case 'NUMBERSCALE10':
                                                                    return (
                                                                        <NumberScale10Section
                                                                            isAuthor={form.isAuthor}
                                                                            autoFocus={form.autoFocus}
                                                                            canEdit={canEdit}
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            initialSave={initialSave}
                                                                            isSignedOff={form.isSignedOff}
                                                                            onChangeScale10Value={onChangeScale10Value}
                                                                        />
                                                                    );
                                                                case 'NUMBERSCALE100':
                                                                    return (
                                                                        <NumberScale100Section
                                                                            isAuthor={form.isAuthor}
                                                                            autoFocus={form.autoFocus}
                                                                            canEdit={canEdit}
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            initialSave={initialSave}
                                                                            isSignedOff={form.isSignedOff}
                                                                            onChangeScale100Value={onChangeScale100Value}
                                                                        />
                                                                    );
                                                                case 'SPREADSHEET':
                                                                    return (
                                                                        <SpreadSheetSection
                                                                            isAuthor={form.isAuthor}
                                                                            autoFocus={form.autoFocus}
                                                                            canEdit={canEdit}
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            initialSave={initialSave}
                                                                            isSignedOff={form.isSignedOff}
                                                                            onChangeSpreadsheetData={onChangeSpreadsheetData}
                                                                        />
                                                                    );
                                                                case 'CHECKBOXES':
                                                                    return (
                                                                        <CheckboxesSection
                                                                            isAuthor={form.isAuthor}
                                                                            autoFocus={form.autoFocus}
                                                                            canEdit={canEdit}
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            initialSave={initialSave}
                                                                            isSignedOff={form.isSignedOff}
                                                                            onChangeCheckbox={onChangeCheckbox}
                                                                            onFocus={() =>
                                                                                updateForm({
                                                                                    ...form,
                                                                                    autoFocus: section.rowIndexes
                                                                                        .concat(section.columnIndexes)
                                                                                        .join(),
                                                                                })
                                                                            }
                                                                            checkboxesData={section.checkboxesData}
                                                                        />
                                                                    );
                                                                case 'DRAWING':
                                                                    return (
                                                                        <DrawingSection
                                                                            section={section}
                                                                            onSave={onSave}
                                                                            groupedSections={
                                                                                get(section, 'group.id') &&
                                                                                filter(
                                                                                    form.sections,
                                                                                    (item) => get(item, 'group.id') === get(section, 'group.id')
                                                                                )
                                                                            }
                                                                            onChangeDrawingSection={onChangeDrawingSection}
                                                                        />
                                                                    );
                                                                default:
                                                                    return null;
                                                            }
                                                        })()}
                                                    </Grid>
                                                );
                                            })()
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        );
                    }

                    return <ErrorMessage text='Please try and load the form template again' />;
                })()}
            </Paper>
        </Grid>
    );
};

const styles = {
    disabled: {
        color: 'black',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 18,
    },
    selectHeading: {
        fontWeight: 'bold',
        paddingBottom: 8,
    },
    paper: {
        marginBottom: 36,
        padding: 20,
        overflow: 'auto',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 16,
    },
    inputTitle: {
        flexBasis: '65%',
        fontWeight: 'bold',
    },
    row: {
        padding: [12, 0],
    },
    column: {
        flex: 1,
        padding: 6,
    },
    formTemplateWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
};

export default compose(FormNoteContainer, withStyles(styles))(FormNote);

import { EPISODE_SELECTED, EPISODE_CLEARED } from '../actions/episode';

const episodeDefaultState = {
  id: undefined,
  name: undefined,
  startDate: undefined,
  endDate: undefined,
};

const episodeReducer = (state = episodeDefaultState, action) => {
  switch (action.type) {
    case EPISODE_SELECTED:
      return {
        ...state,
        id: action.id,
        name: action.name,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case EPISODE_CLEARED:
      return {
        id: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
      };
    default:
      return state;
  }
};

export default episodeReducer;

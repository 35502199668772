import React, { useEffect } from 'react';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import FloatingActionButtons from '../../components/FloatingActionButtons';
import DashboardPageContainer from './dashboardPageContainer';

import AppBarMenu from '../../components/AppBarMenu';
import ErrorMessage from '../../components/ErrorMessage';
import Loading from '../../components/Loading';
import PatientList from '../../components/PatientList/patientList';
import SearchBar from '../../components/SearchBar';
import UnsignedNotesList from '../../components/UnsignedNotesList';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const DashboardPage = ({ classes, error, history, isClinician, status }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        });
    }, [location]);

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container justifyContent='center'>
                {(() => {
                    if (status.loading) return <Loading overlay />;
                    if (status.error) return <ErrorMessage error={error} />;

                    return (
                        <Grid
                            className={classes.content}
                            justifyContent='center'
                            container
                            item
                            spacing={5}
                            direction={window.innerWidth > 600 ? 'row' : 'column'}>
                            <Grid container direction='column' wrap='nowrap' className={classes.section} item xs={12} sm={8} md={8} lg={8} xl={6}>
                                <Typography variant='subtitle1' className={classes.subheading}>
                                    Patients
                                </Typography>
                                <Paper className={classes.paper}>
                                    <SearchBar />
                                    <PatientList />
                                </Paper>
                            </Grid>
                            {isClinician && (
                                <Grid container direction='column' wrap='nowrap' className={classes.section} item sm={4} md={4} lg={4} xl={6}>
                                    <Typography variant='subtitle1' className={classes.subheading}>
                                        Notes in Progress / Not signed off
                                    </Typography>
                                    <Paper className={classes.paper}>
                                        {/* TODO: This is infinitely fetching */}
                                        <UnsignedNotesList history={history} />
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    );
                })()}
                <FloatingActionButtons patient episode textNote mediaNote formNote webDrawingNote consentFormDashboard />
            </Grid>
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 64,
        paddingBottom: 64,
        paddingLeft: 92,
        paddingRight: 44,
    },
    section: {
        flex: 1,
        padding: 20,
    },
    content: {
        paddingTop: 28,
    },
    subheading: {
        fontWeight: 'bold',
        paddingBottom: 8,
        color: '#333',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
};

DashboardPage.defaultProps = {
    error: undefined,
};

export default compose(DashboardPageContainer, withStyles(styles))(DashboardPage);

import React from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AppBarMenu from '../../components/AppBarMenu';
import FormTemplate from '../../components/FormTemplate';

const FormTemplatePage = ({ classes }) => (
  <AppBarMenu>
    <Grid
      component="main"
      container
      direction="column"
      alignItems="center"
      className={classes.main}
    >
      <Grid
        component="section"
        container
        direction="column"
        wrap="nowrap"
        item
        xs={12}
        sm={12}
        md={10}
        lg={10}
        xl={10}
      >
        <FormTemplate />
      </Grid>
    </Grid>
  </AppBarMenu>
);

const styles = {
  main: {
    paddingTop: 92,
    paddingLeft: 88,
    paddingRight: 44,
    paddingBottom: 44,
  },
};

export default withStyles(styles)(FormTemplatePage);

import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';

import { Button, LinearProgress, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import { WARM_PINK, BOULDER } from '../../style/constants';
import ErrorMessage from '../../components/ErrorMessage';

const ConfirmationScreen = ({
  classes,
  booking,
  createAppointmentHandler,
  status,
  setActiveTab,
}) => {
  const { patient, type, time, notes, newPatient } = booking;
  const start = _.get(time, ['code']);
  const duration = _.get(type, ['duration']);
  const payload = {
    clinicianEncodedId: _.get(time, ['slotDetails', 'clinician', 'id']),
    start,
    end: moment(start)
      .add(duration, 'minutes')
      .toISOString(),
    notes,
    firstName: _.get(patient, ['firstName']),
    lastName: _.get(patient, ['lastName']),
    email: _.get(patient, ['email']),
    phone: _.get(patient, ['phone']),
    dob: _.get(patient, ['dob']),
    newPatient,
    appointmentType: _.get(type, ['id']),
    offset: '+11:00',
  };

  return (
    <div className={classes.contentContainer}>
      <Grid className={classes.headingContent} container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h5" component="h1">
            Confirm your details
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.fieldContainer}>
        <div className={classes.textFieldGroup}>
          <Grid className={classes.textFieldGroup} container>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>
                Booking {type.desc || ''}
                {':'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>
                {moment(time.code).format('llll')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>Clinician:</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>
                {_.get(time, ['slotDetails', 'clinician', 'name'])}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>Patient Name:</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>
                {payload.firstName}{" "}{payload.lastName}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>Email:</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>{payload.email}</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>Phone Number:</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>{payload.phone}</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>Date of Birth:</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography className={classes.field}>
                {moment(payload.dob).format('LL')}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Typography>Please confirm your booking details</Typography>
      </div>

      {status && status !== 'inflight' && <ErrorMessage error={status} />}

      {status === 'inflight' ? (
        <LinearProgress />
      ) : (
        <div className={classes.buttonGroup}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="secondary"
            onClick={() => setActiveTab(3)}
          >
            Back
          </Button>

          <Button
            className={classes.nextButton}
            variant="contained"
            color="secondary"
            disabled={status}
            onClick={() => createAppointmentHandler(payload)}
          >
            Confirm
          </Button>
        </div>
      )}
    </div>
  );
};

const styles = {
  contentContainer: {
    padding: '32px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    height: '100%',
    border: 'solid 1px #e6e6e6',
    background: '#ffffff',
    '& > *:not(:last-child)': {
      marginBottom: 40,
    },
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textFieldGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  field: {
    display: 'flex',
    alignContent: 'center',
    width: 253,
    height: 40,
    marginBottom: 8,
  },
  dobField: {
    display: 'flex',
    alignContent: 'center',
    width: 253,
    height: 40,
    marginBottom: 8,
  },
  adornment: {
    marginRight: 8,
  },
  calendar: {
    width: 253,
    height: 40,
  },
  loginButtonContainer: {
    alignSelf: 'flex-end',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  headingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  backButton: {
    width: 'auto',
    height: 40,
    color: BOULDER,
    backgroundColor: '#f5f5f5',
    alignSelf: 'flex-start',
  },
  nextButton: {
    width: 'auto',
    height: 40,
    color: '#ffffff',
    backgroundColor: WARM_PINK,
    alignSelf: 'flex-end',
  },
};

export default compose(withStyles(styles))(ConfirmationScreen);

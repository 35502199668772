import React, { useState } from 'react';
import crypto from 'crypto';
import lodash from 'lodash';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';

import IconButton from '@material-ui/core/IconButton';
import Collections from '@material-ui/icons/Collections';
import Dialog from '@material-ui/core/Dialog';

import DrawingTemplateList from '../../components/DrawingTemplateList';
import EditorConvertToHTML from './rte';
import { formBuilderUiSchema } from './schemas';

const onError = errors => console.log(errors);

const schemaAddField = {
  title: 'Add Field',
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      title: 'Select field',
      type: 'string',
      enum: [
        'string',
        'yesNo',
        'signature',
        'multiLines',
        'date',
        'email',
        'checkbox',
        'label',
        'multipleCheckboxes',
        'scale10',
        'scale100',
        'image',
      ], // field types
      enumNames: [
        'Single line text box',
        'Yes/No question?',
        'Signature Box',
        'Multiple lines text box',
        'Date',
        'Email',
        'Check box with label',
        'Label',
        'Multiple Checkboxes',
        'Scale 1-10',
        'Scale 0-100',
        'Image template',
      ],
    },
    position: {
      title: 'Position',
      type: 'number',
    },
  },
  dependencies: {
    type: {
      oneOf: [
        {
          properties: {
            type: {
              enum: ['label'],
            },
            description: { type: 'string', title: 'Description', default: '' },
          },
        },
        {
          properties: {
            type: {
              enum: ['image'],
            },
            title: { type: 'string', title: 'Label of field', default: '' },
            imageTemplate: {
              type: 'string',
              title: 'Choose template',
              default: '',
            },
          },
        },
        {
          properties: {
            type: {
              enum: [
                'string',
                'signature',
                'multiLines',
                'date',
                'email',
                'checkbox',
                'scale10',
                'scale100',
              ],
            },
            title: { type: 'string', title: 'Label of field', default: '' },
            required: {
              type: 'boolean',
              title: 'Field is required?',
              default: false,
            },
          },
          required: ['title'],
        },
        {
          properties: {
            type: {
              enum: ['yesNo'],
            },
            title: { type: 'string', title: 'Label of field', default: '' },
            include_explain: {
              type: 'boolean',
              title: 'Include explain?',
              default: false,
            },
            required: {
              type: 'boolean',
              title: 'Field is required?',
              default: false,
            },
          },
          required: ['title'],
        },
        {
          properties: {
            type: {
              enum: ['multipleCheckboxes'],
            },
            title: { type: 'string', title: 'Label of field' },
            items: {
              type: 'array',
              title: '',
              items: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                  },
                  // checkbox: {
                  //   type: 'boolean',
                  //   title: ''
                  // }
                },
              },
            },
          },
        },
      ],
    },
  },
};

const uiSchemaField = {
  'ui:order': ['*', 'position'],
  type: {
    'ui:widget': 'select',
  },
  items: {
    'ui:widget': 'checkboxes',
    'ui:options': {
      inline: true,
    },
  },
  scale10: {
    'ui:widget': 'range',
  },
  scale100: {
    'ui:widget': 'range',
  },
  description: {
    'ui:widget': props => {
      return <EditorConvertToHTML fieldProps={props} />;
    },
  },
  imageTemplate: {
    'ui:widget': props => {
      const [templateDialog, updateTemplateDialog] = useState(false);
      const [templateDataTmp, updateTemplateData] = useState(null);
      const marginBottom = {
        marginBottom: 5,
        paddingRight: '40px',
      };
      const { label, required } = props;
      return (
        <div>
          <div>
            <label style={marginBottom}>
              {label}
              {(() => {
                if (required) {
                  return (
                    <span className="MuiFormLabel-asterisk-458 MuiInputLabel-asterisk-444">
                      *
                    </span>
                  );
                }
              })()}
            </label>
          </div>
          {templateDataTmp && (
            <img
              style={{ height: 256, width: 224 }}
              src={`data:image/png;base64,${templateDataTmp}`}
              alt={'Drawing'}
            />
          )}
          <IconButton
            onClick={() => updateTemplateDialog(true)}
            aria-label="More"
            color="inherit"
          >
            <Collections />
          </IconButton>
          <Dialog
            open={templateDialog}
            onClose={() => updateTemplateDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DrawingTemplateList
              notShowGroup={true}
              onSelectTemplate={(
                templateId,
                templateData,
                groupedTemplates,
                fileId
              ) => {
                props.onChange(fileId);
                updateTemplateData(templateData);
                // eventBus.dispatch('CONSENT_FORM_FIELD_CHANGE_IMAGE', {
                //   id: props.schema.name,
                //   templateId,
                //   groupedTemplates,
                //   fileId
                // });
              }}
              onClose={() => updateTemplateDialog(false)}
            />
          </Dialog>
        </div>
      );
    },
  },
};

const Form = withTheme(MuiTheme);

export default function AddFieldFormComp({
  updateAnchorEl,
  schema,
  updateSchema,
  uiSchema,
  updateUiSchema,
  schemaFormData,
  updateSchemaFormData,
}) {
  return (
    <Form
      schema={schemaAddField}
      uiSchema={uiSchemaField}
      showErrorList={false}
      onSubmit={({ formData }, e) => {
        e.preventDefault();
        console.log('formData', formData);
        // if ('description' in formData && formData.description) {
        //   formData.title = formData.description;
        // }
        let hash;
        if ('description' in formData && formData.description) {
          hash = crypto
            .createHash('md5')
            .update(formData.description)
            .digest('hex');
        }

        if ('title' in formData && formData.title) {
          hash = crypto
            .createHash('md5')
            .update(formData.title)
            .digest('hex');
        }

        const fieldName = `${String(hash).substring(0, 12)}-${moment()
          .utc()
          .valueOf()}`;

        const cloneuiSchema = lodash.cloneDeep(uiSchema);
        // Update ui schema here
        if (formData.type in formBuilderUiSchema) {
          cloneuiSchema[fieldName] = formBuilderUiSchema[formData.type];
          if (formData.position) {
            let count = 0;
            ['*', 'formName', 'allow_registration'].forEach(item => {
              if (cloneuiSchema['ui:order'].indexOf(item) > -1) {
                count++;
              }
            });
            cloneuiSchema['ui:order'].splice(
              formData.position + count - 1,
              0,
              fieldName
            );
          } else {
            cloneuiSchema['ui:order'].push(fieldName);
          }
          // write more her
          updateUiSchema(cloneuiSchema);
        }
        formData.default = '';
        // if (
        //   'type' in formData &&
        //   formData.type !== 'yesNo' &&
        //   formData.type !== 'checkbox'
        // ) {
        //   formData.default = '';
        // }

        formData.original_type = formData.type;
        formData.name = fieldName;

        if ('type' in formData) {
          if (
            formData.type === 'signature' ||
            formData.type === 'multiLines' ||
            formData.type === 'date' ||
            formData.type === 'email' ||
            formData.type === 'label'
          ) {
            formData.type = 'string';
          }

          if (formData.type === 'checkbox') {
            formData.type = 'boolean';
            formData.default = false;
          }
          if (formData.type === 'yesNo') {
            formData.type = 'object';
            formData.properties = {
              yesNoValue: {
                type: 'boolean',
                default: false,
                title: '',
              },
            };
            if (formData.include_explain) {
              formData.properties['reason'] = {
                type: 'string',
                title: 'please explain',
              };
            }
          }

          if (formData.type === 'multipleCheckboxes') {
            formData.type = 'array';
            const items = formData.items
              ? formData.items.map(item => item.name)
              : [];
            formData.items = {
              type: 'string',
              enum: items,
            };
            formData.uniqueItems = true;
            delete formData.default;
          }
          if (formData.type === 'scale10') {
            formData.type = 'number';
            formData.minimum = 1;
            formData.maximum = 10;
            formData.default = 1;
            formData.multipleOf = 0.1;
          }
          if (formData.type === 'scale100') {
            formData.type = 'number';
            formData.minimum = 0;
            formData.maximum = 100;
            formData.default = 0;
          }
          if (formData.type === 'image') {
            formData.type = 'string';
            // formData.templateData = templateDataTmp;
            formData.default = '';
          }
        }

        const cloneSchema = lodash.cloneDeep(schema);

        if ('required' in formData && formData.required === true) {
          cloneSchema.required.push(fieldName);
        }

        cloneSchema.properties[fieldName] = lodash.omit(formData, ['required']);

        const cloneFormData = lodash.cloneDeep(formData);

        if (cloneFormData.original_type === 'label') {
          cloneFormData.title = cloneFormData.description;
        }

        schemaFormData.properties[fieldName] = cloneFormData;

        updateSchemaFormData(schemaFormData);

        updateSchema(cloneSchema);

        updateAnchorEl(null);
      }}
      onError={onError}
    >
      <div style={{ width: '100%' }}>
        <Button
          style={{ float: 'right' }}
          variant="contained"
          type="submit"
          color="primary"
          onClose={() => updateAnchorEl(null)}
        >
          Add field
        </Button>

        <Button
          style={{ float: 'left' }}
          variant="contained"
          onClick={() => updateAnchorEl(null)}
        >
          Close
        </Button>
      </div>
    </Form>
  );
}

import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import PatientContainer from './patientContainer';
import { KEYS, LEFT_COLUMN_KEYS, RIGHT_COLUMN_KEYS } from './utility';
import * as PropTypes from 'prop-types';
import IntegrationService from '../../services/IntegrationService';

const Patient = ({ classes, error, form, noChangeInData, onEdit, onSave, savedPatient, status, updateForm, hasIntegration }) => {
    const renderRightColumn = (key) => {
        if (key.hideForIntegrations === true && hasIntegration) return null;

        return (
            <ListItem key={key.id} className={classes.detailItem}>
                <ListItemText className={classes.detailLabel} secondary={key.label || _.get(form, [key.id])} />
                {key.isFlexible ? (
                    <ListItemText
                        className={classes.detail}
                        disableTypography
                        primary={
                            <Typography variant='subtitle1'>
                                <strong>{form[key.dataId]}</strong>
                            </Typography>
                        }
                    />
                ) : (
                    <ListItemText
                        className={classes.detail}
                        disableTypography
                        primary={
                            <Typography variant='subtitle1'>
                                <strong>{form[key.id]}</strong>
                            </Typography>
                        }
                    />
                )}
            </ListItem>
        );
    };

    return (
        <Paper className={classes.paper}>
            {form.loading && <Loading linear />}
            {(() => {
                if (!status || status.loading || status.setVariables || status.refetching) return <Loading />;
                if (status.error) return <ErrorMessage error={error} />;

                const handleToggle = () => {
                    if (form.editing) {
                        onSave();
                    } else {
                        onEdit();
                    }
                };

                return (
                    <Grid container direction='column' justifyContent='center' wrap='nowrap' className={form.loading ? classes.disabled : null}>
                        <List disablePadding>
                            {!IntegrationService.objectIsLocked(form) && (
                                <ListItem className={classes.buttonItem} divider disableGutters>
                                    <div>
                                        {form.editing ? (
                                            <Button disabled={form.loading || !form.firstName || !form.dob} onClick={onEdit}>
                                                Back
                                            </Button>
                                        ) : (
                                            <Button
                                                disabled={form.loading || !form.firstName || !form.dob}
                                                onClick={() => {
                                                    onSave(!form.isArchived);
                                                }}>
                                                {form.isArchived ? 'Unarchive' : 'Archive'}
                                            </Button>
                                        )}
                                        <Button disabled={form.loading || !form.firstName || !form.dob} onClick={handleToggle}>
                                            {form.editing ? 'Done' : 'Edit'}
                                        </Button>
                                    </div>
                                </ListItem>
                            )}
                            {form.editing ? (
                                <>
                                    {!savedPatient.firstName && !savedPatient.lastName ? (
                                        <ListItem className={classes.detailItemEditing}>
                                            <ListItemText className={classes.detailLabel} secondary='Name' />
                                            <TextField
                                                id='name'
                                                autoComplete='off'
                                                autoFocus
                                                disabled={form.firstName || form.lastName}
                                                className={classes.detailInputName}
                                                value={form.name || ''}
                                                onChange={(e) => updateForm({ ...form, name: e.target.value })}
                                            />
                                        </ListItem>
                                    ) : null}
                                    {KEYS.map((key) => {
                                        return (
                                            <ListItem key={key.id} className={classes.detailItemEditing}>
                                                <ListItemText className={classes.detailLabel} secondary={key.label || _.get(form, [key.id])} />
                                                {key.id === 'dob' ? (
                                                    <TextField
                                                        id={key.id}
                                                        autoComplete='off'
                                                        type='date'
                                                        className={classes.detailInput}
                                                        value={Moment(form.dob).format('YYYY-MM-DD')}
                                                        onChange={(e) =>
                                                            updateForm({
                                                                ...form,
                                                                [key.id]: e.target.value,
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    <TextField
                                                        id={key.id}
                                                        autoComplete='off'
                                                        className={classes.detailInput}
                                                        value={(key.isFlexible ? form[key.dataId] : form[key.id]) || ''}
                                                        onChange={(e) => {
                                                            const formKey = key.isFlexible ? key.dataId : key.id;
                                                            updateForm({ ...form, [formKey]: e.target.value });
                                                        }}
                                                    />
                                                )}
                                            </ListItem>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {!savedPatient.firstName && !savedPatient.lastName ? (
                                                <ListItem className={classes.detailItem}>
                                                    <ListItemText className={classes.detailLabel} secondary='Name' />
                                                    <ListItemText
                                                        disableTypography
                                                        className={classes.detail}
                                                        primary={
                                                            <Typography variant='subtitle1'>
                                                                <strong>{form.name}</strong>
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            ) : null}
                                            {LEFT_COLUMN_KEYS.map((key) => {
                                                return (
                                                    <ListItem key={key.id} className={classes.detailItem}>
                                                        <ListItemText
                                                            className={classes.detailLabel}
                                                            secondary={key.label || _.get(form, [key.id])}
                                                        />
                                                        {key.id === 'dob' ? (
                                                            <ListItemText
                                                                className={classes.detail}
                                                                primary={
                                                                    <Typography variant='subtitle1'>
                                                                        <strong>{Moment(form.dob).format('D MMMM YYYY')}</strong>
                                                                    </Typography>
                                                                }
                                                            />
                                                        ) : (
                                                            <ListItemText
                                                                disableTypography
                                                                className={classes.detail}
                                                                primary={
                                                                    <Typography variant='subtitle1'>
                                                                        <strong>{form[key.id]}</strong>
                                                                    </Typography>
                                                                }
                                                            />
                                                        )}
                                                    </ListItem>
                                                );
                                            })}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {RIGHT_COLUMN_KEYS.map((key) => {
                                                return renderRightColumn(key);
                                            })}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </List>
                    </Grid>
                );
            })()}
        </Paper>
    );
};

Patient.propTypes = {
    classes: PropTypes.any,
    error: PropTypes.any,
    form: PropTypes.any,
    noChangeInData: PropTypes.any,
    onEdit: PropTypes.any,
    onSave: PropTypes.any,
    savedPatient: PropTypes.any,
    status: PropTypes.any,
    updateForm: PropTypes.any,
};

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: 157,
        marginBottom: 36,
    },
    disabled: {
        opacity: 0.5,
    },
    buttonItem: {
        justifyContent: 'flex-end',
        padding: 4,
    },
    datePicker: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
    },
    detailItem: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    detailItemEditing: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    detailLabel: {
        flex: 0.5,
    },
    detail: {
        flex: 1,
    },
    detailInput: {
        flex: 1,
        paddingLeft: 8,
    },
    detailInputName: {
        flex: 1,
        paddingLeft: 8,
        fontWeight: 'bold',
    },
    detailInputDate: {
        flex: 1,
        height: 32,
        paddingLeft: 8,
        '& > div': {
            display: 'flex',
            zIndex: 9999,
        },
        '& > div:first-child > div:first-child': {
            flex: 1,
        },
    },
};

export default compose(
    withStyles(styles),
    connect(({ integration }) => ({
        hasIntegration: integration.id !== undefined,
    })),
    PatientContainer
)(Patient);

import React from 'react';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import WebDrawingNoteContainer from './webDrawingNoteContainer';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import WebDrawingTool from '../../pages/WebDrawingTool';
// CZ: 173 - commented out because of cyclic dependency
// import NotesSidebar from '../NotesSidebar';

const WebDrawingNote = ({
    classes,
    error,
    status,
    templateData,
    saveNoteAction,
    drawingNoteData,
    listUserPreferences,
    formNoteSection,
    formNoteGroupedSections,
    onCloseDrawingFormNote,
    onSaveDrawingFormNote,
}) => (
    <div>
        {(() => {
            if (status.loading)
                return (
                    <Grid container direction='column'>
                        <Paper className={classes.paper}>
                            <Loading />
                        </Paper>
                    </Grid>
                );
            if (status.error) return <ErrorMessage text={error.message} />;
            return (
                <Grid id='drawing-root' container spacing={1} style={{ paddingRight: '25px' }}>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <WebDrawingTool
                                    isBackBtnVisible
                                    templateData={templateData}
                                    drawingNoteData={drawingNoteData}
                                    saveAction={saveNoteAction}
                                    listUserPreferences={listUserPreferences}
                                    formNoteSection={formNoteSection}
                                    formNoteGroupedSections={formNoteGroupedSections}
                                    onCloseDrawingFormNote={onCloseDrawingFormNote}
                                    onSaveDrawingFormNote={onSaveDrawingFormNote}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    {/* <NotesSidebar isDrawing /> */}
                </Grid>
            );
        })()}
    </div>
);

const styles = {
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 18,
    },
    paper: {
        position: 'relative',
        marginBottom: 36,
        padding: 20,
        maxWidth: '100%',
    },
    disabled: {
        opacity: 0.5,
    },
    disabledTextField: {
        color: 'black',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 16,
    },
    inputTitle: {
        flexBasis: '65%',
        fontWeight: 'bold',
    },
    drawing: {
        height: '100%',
        width: '100%',
    },
    emptyData: {
        border: [1, 'solid', 'lightgray'],
        height: '100%',
        width: '100%',
    },
};

export default compose(WebDrawingNoteContainer, withStyles(styles))(WebDrawingNote);

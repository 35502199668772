import {
  INTEGRATION_SELECTED,
  INTEGRATION_CLEARED
} from '../actions/integration';

const integrationDefaultState = {
  id: undefined
};

const integrationReducer = (state = integrationDefaultState, action) => {
  switch (action.type) {
    case INTEGRATION_SELECTED:
      return {
        ...state,
        id: action.id,
      };
    case INTEGRATION_CLEARED:
      return integrationDefaultState;
    default:
      return state;
  }
};

export default integrationReducer;

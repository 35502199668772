import { gql } from '@apollo/client';

export const NEW_CONSENT_FORM = gql`
  mutation upsertConsentForm(
    $key: String
    $title: String
    $description: String
    $fields: String
    $schema: String!
    $uiSchema: String!
    $allow_registration: Boolean
    $practice_id: String
    $sharableTemplate: Boolean
    $templateCategory: [ID]
  ) {
    upsertConsentForm(
      key: $key
      title: $title
      description: $description
      fields: $fields
      schema: $schema
      uiSchema: $uiSchema
      allow_registration: $allow_registration
      practice_id: $practice_id
      sharableTemplate: $sharableTemplate
      templateCategory: $templateCategory
    ) {
      form {
        title
        description
        key
        state
        schema
        uiSchema
        allow_registration
        practice_id
        fields {
          type
          title
          name
          is_required
        }
      }
    }
  }
`;

export const GET_CONSENT_FORM = gql`
  query getConsentForm($key: String) {
    getConsentForm(key: $key) {
      form {
        title
        description
        key
        state
        schema
        uiSchema
        submissions_count
        allow_registration
        practice_id
        fields {
          type
          title
          name
          is_required
        }
      }
    }
  }
`;

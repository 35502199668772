import React from 'react';
import { branch, compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ImageIcon from '@material-ui/icons/Image';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DoneIcon from '@material-ui/icons/Done';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import AppBarMenu from '../../components/AppBarMenu';
import Loading from '../../components/Loading';
import SubCategoryDialog from './SubCategoryDialog';
import PreCreateTemplateDialog from './PreCreateTemplateDialog';
import ImageTemplateDialog from './ImageTemplateDialog';
import ConsentFormTemplateDialog from './ConsentFormTemplateDialog';
import FormNoteTemplateDialog from './FormNoteTemplateDialog';
import TemplateImage from '../../components/TemplateImage/templateImage';
import ConfirmActionDialog from '../../components/ConfirmActionDialog';
import { formTemplateUpsert } from '../../data/redux/actions/formTemplate';
import FormNotePlaceholder from '../../images/form-note-placeholder.png';
import ConsentFormPlaceholder from '../../images/consent-form-placeholder.jpg';

export const templateListQuery = gql`
    query templateListQuery($templateCategoryId: [ID]) {
        listSharableTemplates(templateCategoryId: $templateCategoryId) {
            id
            name
            file {
                id
            }
            group {
                id
            }
            pageNumber
            sharableTemplate
        }
    }
`;

export const formTemplateListQuery = gql`
    query formTemplateListQuery($templateCategoryId: [ID]) {
        listSharableFormTemplates(templateCategoryId: $templateCategoryId) {
            id
            name
            sections {
                id
                type
                title
                rowIndexes
                columnIndexes
                ... on DrawingSection {
                    pageNumber
                    group {
                        id
                    }
                    template {
                        id
                        pageNumber
                        file {
                            id
                            encodedFile
                        }
                        group {
                            id
                        }
                    }
                }
                ... on CheckboxesSection {
                    checkboxesData
                }
                ... on SpreadsheetSection {
                    spreadsheetData
                }
            }
            rows
            columns
            sharableTemplate
            templateCategory
        }
    }
`;

export const consentFormTemplateListQuery = gql`
    query consentFormTemplateListQuery($templateCategoryId: [ID]) {
        listSharableConsentFormTemplates(templateCategoryId: $templateCategoryId) {
            forms {
                id
                title
                description
                key
                state
                schema
                uiSchema
                submissions_count
                allow_registration
                practice_id
                fields {
                    type
                    title
                    name
                    is_required
                }
            }
        }
    }
`;

const upsertTemplateMutation = gql`
    mutation upsertTemplate($id: ID, $practice: ID!, $name: String, $encodedFile: String, $deletedAt: DateTime, $pageNumber: Int, $groupId: ID) {
        upsertTemplate(
            id: $id
            practice: $practice
            name: $name
            encodedFile: $encodedFile
            deletedAt: $deletedAt
            pageNumber: $pageNumber
            group: $groupId
        ) {
            template {
                id
                name
                file {
                    id
                    encodedFile
                }
            }
        }
    }
`;

const upsertFormTemplateMutation = gql`
    mutation upsertFormTemplate(
        $id: ID
        $deletedAt: DateTime
        $practiceId: ID!
        $sections: [FormTemplateSectionInput]!
        $allowUpdate: Boolean
        $name: String
        $rows: Int
        $columns: Int
    ) {
        upsertFormTemplate(
            id: $id
            deletedAt: $deletedAt
            practiceId: $practiceId
            sections: $sections
            allowUpdate: $allowUpdate
            name: $name
            rows: $rows
            columns: $columns
        ) {
            formTemplate {
                id
                name
            }
        }
    }
`;

const removeConsentFormMutation = gql`
    mutation removeConsentForm($key: String!, $practice_id: String) {
        removeConsentForm(key: $key, practice_id: $practice_id) {
            success
            forms {
                title
                description
                key
                state
                schema
                uiSchema
                submissions_count
                submissions {
                    createdAt
                    details {
                        type
                        name
                        value
                    }
                }
                fields {
                    type
                    title
                    name
                    is_required
                }
            }
        }
    }
`;

const NewTemplateCard = ({ onNewTemplate, classes }) => {
    return (
        <Card className={classes.card}>
            <CardActionArea onClick={onNewTemplate}>
                <CardContent>
                    <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' style={{ minHeight: 335 }}>
                        <Grid item>
                            <Icon style={{ fontSize: 40 }}>add_circle</Icon>
                        </Grid>
                        <Grid item>
                            <Typography>Create new template</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const TemplateCard = ({
    classes,
    getFileEncodedFromFileId,
    updateTemplateFiles,
    templateFiles,
    template,
    onCopyDrawingTemplate,
    updateAlertDialog,
    isSuperAdmin,
    groupTemplates,
    updatePreviewTemplate,
    updateImageTemplateDialogOpen,
}) => {
    return (
        <Card className={classes.card}>
            <CardActionArea
                onClick={(e) => {
                    e.stopPropagation();
                    updatePreviewTemplate(groupTemplates);
                    updateImageTemplateDialogOpen(true);
                }}>
                <TemplateImage
                    fileId={template.file.id}
                    height={240}
                    onImageLoaded={(imageData) => {
                        if (templateFiles.filter((file) => file.id === imageData.id).length === 0) updateTemplateFiles([...templateFiles, imageData]);
                    }}
                />

                <CardContent>
                    <Typography gutterBottom variant='h6' component='h2'>
                        {template.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                {isSuperAdmin && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            updateAlertDialog({
                                show: true,
                                selectedTemplate: groupTemplates,
                                type: 0,
                            });
                        }}
                        size='small'
                        variant='outlined'
                        color='secondary'>
                        Delete
                    </Button>
                )}
                <Button onClick={() => onCopyDrawingTemplate(template.id)} size='small' variant='outlined' color='primary'>
                    Get
                </Button>
            </CardActions>
        </Card>
    );
};

const FormTemplateCard = ({
    classes,
    isSuperAdmin,
    formTemplate,
    onCopyFormTemplate,
    updateAlertDialog,
    updatePreviewFormTemplate,
    updateFormNoteTemplateDialogOpen,
    dispatch,
}) => {
    return (
        <Card className={classes.card}>
            <CardActionArea
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                        formTemplateUpsert({
                            id: formTemplate.id,
                            name: formTemplate.name,
                            sections: formTemplate.sections,
                            rows: formTemplate.rows,
                        })
                    );
                    updatePreviewFormTemplate(formTemplate);
                    updateFormNoteTemplateDialogOpen(true);
                }}>
                <CardMedia className={classes.media} image={FormNotePlaceholder} title={formTemplate.name} />
                <CardContent>
                    <Typography gutterBottom variant='h6' component='h2'>
                        {formTemplate.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                {isSuperAdmin && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            updateAlertDialog({
                                show: true,
                                selectedTemplate: [formTemplate],
                                type: 1,
                            });
                        }}
                        size='small'
                        variant='outlined'
                        color='secondary'>
                        Delete
                    </Button>
                )}
                {/* <Button size="small" variant="outlined" color="primary"> */}
                {/* Edit */}
                {/* </Button> */}
                <Button onClick={() => onCopyFormTemplate(formTemplate.id)} size='small' variant='outlined' color='primary'>
                    Get
                </Button>
            </CardActions>
        </Card>
    );
};

const ConsentFormTemplateCard = ({
    classes,
    consentFormTemplate,
    onCopyConsentFormTemplate,
    updateAlertDialog,
    updatePreviewConsentFormTemplate,
    updateConsentFormTemplateDialogOpen,
    isSuperAdmin,
}) => {
    return (
        <Card className={classes.card}>
            <CardActionArea
                onClick={(e) => {
                    e.stopPropagation();
                    updatePreviewConsentFormTemplate(consentFormTemplate);
                    updateConsentFormTemplateDialogOpen(true);
                }}>
                <CardMedia className={classes.media} image={ConsentFormPlaceholder} title={consentFormTemplate.title} />
                <CardContent>
                    <Typography gutterBottom variant='h6' component='h2'>
                        {consentFormTemplate.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                {isSuperAdmin && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            updateAlertDialog({
                                show: true,
                                selectedTemplate: [consentFormTemplate],
                                type: 2,
                            });
                        }}
                        size='small'
                        variant='outlined'
                        color='secondary'>
                        Delete
                    </Button>
                )}
                {/* <Button size="small" variant="outlined" color="primary"> */}
                {/* Edit */}
                {/* </Button> */}
                <Button onClick={() => onCopyConsentFormTemplate(consentFormTemplate.id)} size='small' variant='outlined' color='primary'>
                    Get
                </Button>
            </CardActions>
        </Card>
    );
};

const TemplateCategories = ({ classes, selectedTemplateType, updateSelectedTemplateType }) => {
    return (
        <div className={classes.categoryContainer}>
            <Chip
                icon={<ImageIcon fontSize='small' />}
                label='Drawing images'
                clickable
                color='primary'
                deleteIcon={<DoneIcon />}
                className={classes.categoryChip}
                variant={selectedTemplateType === 0 ? 'default' : 'outlined'}
                onClick={() => {
                    if (selectedTemplateType === 0) {
                        updateSelectedTemplateType(undefined);
                    } else {
                        updateSelectedTemplateType(0);
                    }
                }}
            />
            <Chip
                icon={<ViewQuiltIcon fontSize='small' />}
                label='Form templates'
                clickable
                color='primary'
                deleteIcon={<DoneIcon />}
                className={classes.categoryChip}
                variant={selectedTemplateType === 1 ? 'default' : 'outlined'}
                onClick={() => {
                    if (selectedTemplateType === 1) {
                        updateSelectedTemplateType(undefined);
                    } else {
                        updateSelectedTemplateType(1);
                    }
                }}
            />
            <Chip
                icon={<FormatListBulletedIcon fontSize='small' />}
                label='Consent form templates'
                clickable
                color='primary'
                deleteIcon={<DoneIcon />}
                className={classes.categoryChip}
                variant={selectedTemplateType === 2 ? 'default' : 'outlined'}
                onClick={() => {
                    if (selectedTemplateType === 2) {
                        updateSelectedTemplateType(undefined);
                    } else {
                        updateSelectedTemplateType(2);
                    }
                }}
            />
        </div>
    );
};

const TemplateSubCategories = ({
    classes,
    updateSubCategoryDialog,
    subcategories,
    menuAnchorEl,
    updateMenuAnchorEl,
    onDeleteSubcategory,
    onEditSubcategory,
    contextMenuOnSubCategory,
    updateContextMenuOnSubCategory,
    filteredCategories,
    updateFilteredCategories,
    refetchData,
    isSuperAdmin,
}) => {
    return (
        <Box display='flex' p={2} flexDirection='row' width='100%'>
            {isSuperAdmin && (
                <Chip
                    className={classes.categoryChip}
                    variant='outlined'
                    key={-1}
                    label='New Subcategory'
                    icon={<AddCircleIcon fontSize='small' />}
                    onClick={() => {
                        updateSubCategoryDialog(true);
                    }}
                    clickable
                />
            )}

            {subcategories &&
                subcategories.map((subcategory) => (
                    <Chip
                        variant={filteredCategories.find((c) => c.id === subcategory.id) ? 'default' : 'outlined'}
                        color='primary'
                        className={classes.categoryChip}
                        key={subcategory.id}
                        label={subcategory.name}
                        clickable
                        deleteIcon={<MoreVertIcon value={subcategory.id} />}
                        onClick={async () => {
                            const categories = [...filteredCategories];
                            const had = categories.find((c) => c.id === subcategory.id);
                            let newFilteredCategories = [];
                            if (had) {
                                newFilteredCategories = categories.filter((c) => c.id !== subcategory.id);
                                updateFilteredCategories(newFilteredCategories);
                            } else {
                                categories.push(subcategory);
                                updateFilteredCategories(categories);
                                newFilteredCategories = categories;
                            }
                            await refetchData(undefined, newFilteredCategories);
                        }}
                        onDelete={(e) => {
                            updateContextMenuOnSubCategory(subcategory.id);
                            updateMenuAnchorEl(e.target);
                        }}
                    />
                ))}
            {isSuperAdmin && (
                <Menu
                    id='long-menu'
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={() => {
                        updateMenuAnchorEl(undefined);
                    }}
                    PaperProps={{
                        style: {
                            width: '20ch',
                        },
                    }}>
                    <MenuItem
                        key='Edit'
                        onClick={({ e }) => {
                            console.log('On edit subcategory');
                            updateMenuAnchorEl(undefined);
                            onEditSubcategory(contextMenuOnSubCategory);
                        }}>
                        Edit
                    </MenuItem>
                    <MenuItem
                        key='Delete'
                        onClick={({ e }) => {
                            console.log('On delete subcategory');
                            updateMenuAnchorEl(undefined);
                            onDeleteSubcategory(contextMenuOnSubCategory);
                        }}>
                        Delete
                    </MenuItem>
                </Menu>
            )}
        </Box>
    );
};

const TemplateLibrary = ({
    classes,
    loading,
    showLoading,
    onNewTemplate,
    subCategoryDialog,
    updateSubCategoryDialog,
    onAddNewSubCategory,
    updateCategorySelection,
    categorySelection,
    onCreateTemplateCategorySelected,
    subcategories,
    menuAnchorEl,
    updateMenuAnchorEl,
    contextMenuOnSubCategory,
    updateContextMenuOnSubCategory,
    onDeleteSubcategory,
    onEditSubcategory,
    onCreateNewSubCategory,
    editingSubcategory,
    filteredCategories,
    updateFilteredCategories,
    selectedTemplateType,
    updateSelectedTemplateType,
    imageTemplateDialogOpen,
    updateImageTemplateDialogOpen,
    subcategoryIdToCreate,
    consentFormTemplateDialogOpen,
    updateConsentFormTemplateDialogOpen,
    formNoteTemplateDialogOpen,
    updateFormNoteTemplateDialogOpen,
    drawingTemplates,
    getFileEncodedFromFileId,
    templateFiles,
    updateTemplateFiles,
    onCopyDrawingTemplate,
    onCopyConsentFormTemplate,
    onCopyFormTemplate,
    onCloseFormTemplate,
    formTemplatesState,
    consentFormTemplatesState,
    onCloseConsentFormTemplate,
    refetchData,
    drawingTemplatesState,
    alertDialog,
    updateAlertDialog,
    onArchiveTemplate,
    previewTemplate,
    previewFormTemplate,
    previewConsentFormTemplate,
    updatePreviewFormTemplate,
    updatePreviewConsentFormTemplate,
    isSuperAdmin,
    barText,
    updateBarText,
    onSearch,
    dispatch,
    updatePreviewTemplate,
}) => {
    return (
        <AppBarMenu>
            {(loading || showLoading) && <Loading transparent />}
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth='xl' className={classes.container}>
                    <Paper variant='outlined' className={classes.banner}>
                        <div className={classes.bannerContainer}>
                            <Typography variant='h6' gutterBottom>
                                Template library
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                Search for a template – drawing image, form template or consent form and simply press GET to upload it to your
                                account. Use the quick selection boxes and subcategories to preview any template or type in the search bar a specific
                                term to search relevant templates to preview and upload to your account ready to use eg. Body chart.
                            </Typography>

                            <Box display='flex' pt={0} pl={0} pb={1} flexDirection='row' width='100%'>
                                <Box width='75%'>
                                    <TemplateCategories
                                        classes={classes}
                                        selectedTemplateType={selectedTemplateType}
                                        updateSelectedTemplateType={updateSelectedTemplateType}
                                    />
                                </Box>
                            </Box>

                            <Box width='100%'>
                                <TextField
                                    style={{ width: '100%' }}
                                    label='Search'
                                    autoComplete='off'
                                    value={barText || ''}
                                    placeholder='Search'
                                    onChange={(event) => {
                                        updateBarText(event.target.value);
                                        onSearch(event.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <TemplateSubCategories
                                classes={classes}
                                updateSubCategoryDialog={updateSubCategoryDialog}
                                subcategories={subcategories}
                                updateMenuAnchorEl={updateMenuAnchorEl}
                                menuAnchorEl={menuAnchorEl}
                                onDeleteSubcategory={onDeleteSubcategory}
                                onEditSubcategory={onEditSubcategory}
                                updateContextMenuOnSubCategory={updateContextMenuOnSubCategory}
                                contextMenuOnSubCategory={contextMenuOnSubCategory}
                                updateFilteredCategories={updateFilteredCategories}
                                filteredCategories={filteredCategories}
                                refetchData={refetchData}
                                isSuperAdmin={isSuperAdmin}
                            />
                        </div>
                    </Paper>

                    <Grid item xs={12}>
                        <Grid container justifyContent='flex-start' spacing={2}>
                            {isSuperAdmin && (
                                <Grid xs={3}>
                                    <NewTemplateCard classes={classes} onNewTemplate={onNewTemplate} />
                                </Grid>
                            )}
                            {(selectedTemplateType === 0 || selectedTemplateType === undefined) &&
                                drawingTemplatesState &&
                                drawingTemplatesState.map((record) => {
                                    const groupTemplates =
                                        drawingTemplates && record.group
                                            ? drawingTemplates.filter((template) => template.group && template.group.id === record.group.id)
                                            : [record];
                                    return (
                                        (!record.group || record.pageNumber === 1) && (
                                            <Grid key={record.id} item xs={3}>
                                                <TemplateCard
                                                    isSuperAdmin={isSuperAdmin}
                                                    classes={classes}
                                                    template={record}
                                                    getFileEncodedFromFileId={getFileEncodedFromFileId}
                                                    updateTemplateFiles={updateTemplateFiles}
                                                    templateFiles={templateFiles}
                                                    onCopyDrawingTemplate={onCopyDrawingTemplate}
                                                    updateAlertDialog={updateAlertDialog}
                                                    groupTemplates={groupTemplates}
                                                    updatePreviewTemplate={updatePreviewTemplate}
                                                    updateImageTemplateDialogOpen={updateImageTemplateDialogOpen}
                                                />
                                            </Grid>
                                        )
                                    );
                                })}
                            {(selectedTemplateType === 1 || selectedTemplateType === undefined) &&
                                formTemplatesState &&
                                formTemplatesState.map((record) => (
                                    <Grid key={record.id} item xs={3}>
                                        <FormTemplateCard
                                            dispatch={dispatch}
                                            isSuperAdmin={isSuperAdmin}
                                            classes={classes}
                                            formTemplate={record}
                                            onCopyFormTemplate={onCopyFormTemplate}
                                            updateAlertDialog={updateAlertDialog}
                                            updatePreviewFormTemplate={updatePreviewFormTemplate}
                                            updateFormNoteTemplateDialogOpen={updateFormNoteTemplateDialogOpen}
                                        />
                                    </Grid>
                                ))}
                            {(selectedTemplateType === 2 || selectedTemplateType === undefined) &&
                                consentFormTemplatesState &&
                                consentFormTemplatesState.map((record) => (
                                    <Grid key={record.id} item xs={3}>
                                        <ConsentFormTemplateCard
                                            isSuperAdmin={isSuperAdmin}
                                            classes={classes}
                                            consentFormTemplate={record}
                                            onCopyConsentFormTemplate={onCopyConsentFormTemplate}
                                            updateAlertDialog={updateAlertDialog}
                                            updatePreviewConsentFormTemplate={updatePreviewConsentFormTemplate}
                                            updateConsentFormTemplateDialogOpen={updateConsentFormTemplateDialogOpen}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            <PreCreateTemplateDialog
                classes={classes}
                categorySelection={categorySelection}
                updateCategorySelection={updateCategorySelection}
                onCreateTemplateCategorySelected={onCreateTemplateCategorySelected}
                subcategories={subcategories}
            />

            <SubCategoryDialog
                updateSubCategoryDialog={updateSubCategoryDialog}
                subCategoryDialog={subCategoryDialog}
                onAddNewSubCategory={onAddNewSubCategory}
                onCreateNewSubCategory={onCreateNewSubCategory}
                editingSubcategory={editingSubcategory}
            />

            <ImageTemplateDialog
                imageTemplateDialogOpen={imageTemplateDialogOpen}
                updateImageTemplateDialogOpen={updateImageTemplateDialogOpen}
                subcategoryIdToCreate={subcategoryIdToCreate}
                onAdded={async () => {
                    await refetchData(0);
                }}
                groupTemplates={previewTemplate}
            />
            <ConsentFormTemplateDialog
                consentFormTemplateDialogOpen={consentFormTemplateDialogOpen}
                updateConsentFormTemplateDialogOpen={updateConsentFormTemplateDialogOpen}
                subcategoryIdToCreate={subcategoryIdToCreate}
                onCloseConsentFormTemplate={onCloseConsentFormTemplate}
                consentFormTemplate={previewConsentFormTemplate}
            />
            <FormNoteTemplateDialog
                formNoteTemplateDialogOpen={formNoteTemplateDialogOpen}
                updateFormNoteTemplateDialogOpen={updateFormNoteTemplateDialogOpen}
                subcategoryIdToCreate={subcategoryIdToCreate}
                onCloseFormTemplate={onCloseFormTemplate}
                formTemplate={previewFormTemplate}
            />
            <ConfirmActionDialog
                open={alertDialog.show}
                text='Are you sure you want to delete template?'
                onClose={() =>
                    updateAlertDialog({
                        show: false,
                        selectedTemplate: undefined,
                        type: undefined,
                    })
                }
                onConfirm={() => onArchiveTemplate(alertDialog.selectedTemplate, alertDialog.type)}
                confirmText='Delete'
            />
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    banner: {
        innerWidth: '100%',
        /* height: 200, */
    },
    bannerContainer: {
        padding: 20,
    },
    card: {
        maxWidth: 290,
    },
    media: {
        height: 240,
    },
    addNew: {
        height: 300,
    },
    categoryContainer: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    categoryChip: {
        marginRight: 10,
    },
    categoryChipDialog: {
        margin: 5,
    },
    cardImage: {
        maxHeight: 240,
    },
};

const subcategoryQuery = gql`
    query subcategoryQuery {
        listLibrarySubcategory {
            id
            name
            key
            deletedAt
        }
    }
`;

const upsertSubcategoryMutation = gql`
    mutation upsertLibraryCategory($id: ID, $key: String, $name: String, $deletedAt: DateTime) {
        upsertLibraryCategory(id: $id, name: $name, key: $key, deletedAt: $deletedAt) {
            libraryCategory {
                id
                name
                key
                deletedAt
            }
        }
    }
`;

const copyDrawingTemplateMutation = gql`
    mutation copyDrawingTemplateMutation($id: ID) {
        copyDrawingTemplate(id: $id) {
            template {
                id
                name
                file {
                    id
                }
                group {
                    id
                }
                pageNumber
            }
        }
    }
`;

const copyFormTemplateMutation = gql`
    mutation copyFormTemplateMutation($id: ID) {
        copyFormTemplate(id: $id) {
            formTemplate {
                id
                name
                sections {
                    id
                    type
                    title
                    rowIndexes
                    columnIndexes
                    ... on DrawingSection {
                        pageNumber
                        group {
                            id
                        }
                        template {
                            id
                            pageNumber
                            file {
                                id
                                #encodedFile
                            }
                            group {
                                id
                            }
                        }
                    }
                    ... on CheckboxesSection {
                        checkboxesData
                    }
                    ... on SpreadsheetSection {
                        spreadsheetData
                    }
                }
                rows
                columns
            }
        }
    }
`;

const copyConsentFormTemplateMutation = gql`
    mutation copyConsentFormTemplateMutation($id: ID, $practice_id: String) {
        copyConsentFormTemplate(id: $id, practice_id: $practice_id) {
            consentForm {
                title
                description
                key
                state
                schema
                uiSchema
                submissions_count
                allow_registration
                practice_id
                fields {
                    type
                    title
                    name
                    is_required
                }
            }
        }
    }
`;

const refetchDataHanlders = {
    refetchData:
        ({ updateShowLoading, updateConsentFormTemplates, updateFormTemplates, updateTemplates, filteredCategories, client }) =>
        async (type = undefined, newFilteredCategories = []) => {
            updateShowLoading(true);
            if (!newFilteredCategories) {
                newFilteredCategories = filteredCategories;
            }
            const templateCategoryId = newFilteredCategories.map((cat) => cat.id);
            if (type === undefined || type === 0) {
                const { data } = await client.query({
                    query: templateListQuery,
                    variables: { templateCategoryId },
                    fetchPolicy: 'no-cache',
                });
                if (data && Boolean(data.listSharableTemplates)) {
                    updateTemplates(data.listSharableTemplates);
                }
            }
            if (type === undefined || type === 1) {
                const { data } = await client.query({
                    query: formTemplateListQuery,
                    variables: { templateCategoryId },
                    fetchPolicy: 'no-cache',
                });
                if (data && Boolean(data.listSharableFormTemplates)) {
                    updateFormTemplates(data.listSharableFormTemplates);
                }
            }
            if (type === undefined || type === 2) {
                const { data } = await client.query({
                    query: consentFormTemplateListQuery,
                    variables: { templateCategoryId },
                    fetchPolicy: 'no-cache',
                });
                if (data && Boolean(data.listSharableConsentFormTemplates)) {
                    updateConsentFormTemplates(data.listSharableConsentFormTemplates.forms);
                }
            }

            updateShowLoading(false);
        },
};

const localHandlers = {
    onNewTemplate:
        ({ updateCategorySelection }) =>
        () => {
            updateCategorySelection(true);
        },
    onAddNewSubCategory:
        ({ updateSubCategoryDialog }) =>
        () => {
            updateSubCategoryDialog(false);
        },
    onCreateTemplateCategorySelected:
        ({
            updateCategorySelection,
            updateImageTemplateDialogOpen,
            updateSubcategoryIdToCreate,
            updateConsentFormTemplateDialogOpen,
            updateFormNoteTemplateDialogOpen,
            updatePreviewTemplate,
            updatePreviewFormTemplate,
            updatePreviewConsentFormTemplate,
        }) =>
        (categoryType, subCategoryId) => {
            console.log('navigate to cate template');
            updateCategorySelection(false);
            updateSubcategoryIdToCreate(subCategoryId);

            switch (categoryType) {
                case 0:
                    updateImageTemplateDialogOpen(true);
                    updatePreviewTemplate([]);
                    break;
                case 1:
                    updateFormNoteTemplateDialogOpen(true);
                    updatePreviewFormTemplate({});
                    break;
                case 2:
                    updateConsentFormTemplateDialogOpen(true);
                    updatePreviewConsentFormTemplate({});
                    break;
                default:
            }
        },
    onCreateNewSubCategory:
        ({ upsertSubcategory, updateSubCategoryDialog, updateShowLoading, updateEditingSubcategory }) =>
        async (subCategoryName, editingCateId) => {
            const variables = {
                key: subCategoryName,
                name: subCategoryName,
                deleteAt: null,
            };
            if (editingCateId) {
                variables.id = editingCateId;
            }

            const options = {
                variables,
                refetchQueries: [
                    {
                        query: subcategoryQuery,
                        variables: {},
                    },
                ],
            };
            updateShowLoading(true);
            await upsertSubcategory(options);
            updateSubCategoryDialog(false);
            updateEditingSubcategory(undefined);
            updateShowLoading(false);
        },

    onDeleteSubcategory:
        ({ upsertSubcategory, contextMenuOnSubCategory, updateContextMenuOnSubCategory, subcategories, updateShowLoading }) =>
        async () => {
            if (window.confirm('Are you sure you wish to delete this item?')) {
                const selectedSubCates = subcategories.filter((subcate) => subcate.id === contextMenuOnSubCategory);
                if (selectedSubCates.length > 0) {
                    const selectedSubCate = selectedSubCates[0];
                    const options = {
                        variables: {
                            id: contextMenuOnSubCategory,
                            key: selectedSubCate.key,
                            name: selectedSubCate.name,
                            deletedAt: new Date(),
                        },
                        refetchQueries: [
                            {
                                query: subcategoryQuery,
                                variables: {},
                            },
                        ],
                    };
                    updateShowLoading(true);
                    await upsertSubcategory(options);
                    updateShowLoading(false);
                    updateContextMenuOnSubCategory(undefined);
                }
            }
        },
    onEditSubcategory:
        ({ contextMenuOnSubCategory, updateSubCategoryDialog, subcategories, updateEditingSubcategory }) =>
        () => {
            const selectedSubCates = subcategories.filter((subcate) => subcate.id === contextMenuOnSubCategory);
            if (selectedSubCates.length > 0) {
                const selectedSubCate = selectedSubCates[0];
                updateEditingSubcategory(selectedSubCate);
                updateSubCategoryDialog(true);
            }
        },
    getFileEncodedFromFileId:
        ({ templateFiles }) =>
        (fileId) => {
            if (fileId) {
                const filteredFile = templateFiles.filter((file) => file.id === fileId);
                if (filteredFile.length > 0) {
                    return filteredFile[0].encodedFile;
                }
            }
            return undefined;
        },
    onCopyDrawingTemplate:
        ({ copyDrawingTemplate, updateShowLoading }) =>
        async (templateId) => {
            if (templateId) {
                const variables = {
                    id: templateId,
                };
                const options = {
                    variables,
                };
                updateShowLoading(true);
                await copyDrawingTemplate(options);
                updateShowLoading(false);
                alert('Get drawing template successfully!');
            }
        },
    onCopyFormTemplate:
        ({ copyFormTemplate, updateShowLoading }) =>
        async (templateId) => {
            if (templateId) {
                const variables = {
                    id: templateId,
                };
                const options = {
                    variables,
                };
                updateShowLoading(true);
                await copyFormTemplate(options);
                updateShowLoading(false);
                alert('Get form note template successfully!');
            }
        },
    onCopyConsentFormTemplate:
        ({ copyConsentFormTemplate, updateShowLoading, practiceId }) =>
        async (templateId) => {
            if (templateId) {
                const variables = {
                    id: templateId,
                    practice_id: practiceId,
                };
                const options = {
                    variables,
                };
                updateShowLoading(true);
                await copyConsentFormTemplate(options);
                updateShowLoading(false);
                alert('Get consent form template successfully!');
            }
        },
    onCloseFormTemplate:
        ({ refetchData }) =>
        async () => {
            await refetchData(1);
        },
    onCloseConsentFormTemplate:
        ({ refetchData }) =>
        async () => {
            await refetchData(2);
        },
    onArchiveTemplate:
        ({ practiceId, upsertTemplate, refetchData, upsertFormTemplate, removeConsentForm }) =>
        async (templates, type) => {
            if (type === 0) {
                templates.map(async (template) => {
                    try {
                        await upsertTemplate({
                            variables: {
                                name: template.name,
                                id: template.id,
                                practice: practiceId,
                                deletedAt: new Date(),
                                pageNumber: template.pageNumber,
                            },
                        });
                        await refetchData(0);
                    } catch (error) {
                        console.log('API Error: ', error); // eslint-disable-line
                    }
                });
            }
            if (type === 1) {
                templates.map(async (template) => {
                    try {
                        await upsertFormTemplate({
                            variables: {
                                id: template.id,
                                deletedAt: new Date(),
                                practiceId,
                                sections: [],
                                allowUpdate: true,
                                name: template.name,
                                rows: template.rows,
                                columns: template.columns,
                            },
                        });
                        await refetchData(1);
                    } catch (error) {
                        console.log('API Error: ', error); // eslint-disable-line
                    }
                });
            }
            if (type === 2) {
                templates.map(async (template) => {
                    try {
                        await removeConsentForm({
                            variables: {
                                key: template.key,
                                practice_id: practiceId,
                            },
                        });
                        await refetchData(2);
                    } catch (error) {
                        console.log('API Error: ', error); // eslint-disable-line
                    }
                });
            }
        },
};

export default compose(
    withApollo,
    connect(({ practice, user }) => ({
        practiceId: practice.id,
        isConsentFormSubscription: user.isConsentFormSubscription,
        isSuperAdmin: [
            'jack@cliniknote.com',
            'demo@cliniknote.com',
            'info@lanecovephysio.com.au',
            'info@condorsolutions.com.au',
            'dev@condorsolutions.com.au',
            'ben.tolliday@dreamlax.net',
        ].includes(user.email.toLowerCase()),
    })),
    withStyles(styles),
    withState('showLoading', 'updateShowLoading', false),
    withState('alertDialog', 'updateAlertDialog', {
        show: false,
        selectedTemplate: undefined,
        type: undefined,
    }),
    withState('imageUploader', 'updateImageUploader', false),
    withState('menuAnchor', 'updateMenuAnchor', null),
    withState('subCategoryDialog', 'updateSubCategoryDialog', false),
    withState('categorySelection', 'updateCategorySelection', false),
    withState('menuAnchorEl', 'updateMenuAnchorEl', undefined),
    withState('contextMenuOnSubCategory', 'updateContextMenuOnSubCategory', undefined),
    withState('editingSubcategory', 'updateEditingSubcategory', undefined),

    withState('filteredCategories', 'updateFilteredCategories', []),
    withState('selectedTemplateType', 'updateSelectedTemplateType', undefined),

    withState('imageTemplateDialogOpen', 'updateImageTemplateDialogOpen', false),
    withState('consentFormTemplateDialogOpen', 'updateConsentFormTemplateDialogOpen', false),
    withState('formNoteTemplateDialogOpen', 'updateFormNoteTemplateDialogOpen', false),

    withState('subcategoryIdToCreate', 'updateSubcategoryIdToCreate', undefined),
    withState('previewTemplate', 'updatePreviewTemplate', {}),
    withState('previewFormTemplate', 'updatePreviewFormTemplate', {}),
    withState('previewConsentFormTemplate', 'updatePreviewConsentFormTemplate', {}),
    withState('templateFiles', 'updateTemplateFiles', []),
    withState('formTemplatesState', 'updateFormTemplates', []),
    withState('consentFormTemplatesState', 'updateConsentFormTemplates', []),
    withState('drawingTemplatesState', 'updateTemplates', []),
    graphql(upsertSubcategoryMutation, { name: 'upsertSubcategory' }),
    graphql(copyDrawingTemplateMutation, { name: 'copyDrawingTemplate' }),
    graphql(copyFormTemplateMutation, { name: 'copyFormTemplate' }),
    graphql(copyConsentFormTemplateMutation, { name: 'copyConsentFormTemplate' }),
    graphql(upsertTemplateMutation, { name: 'upsertTemplate' }),
    graphql(upsertFormTemplateMutation, { name: 'upsertFormTemplate' }),
    graphql(removeConsentFormMutation, { name: 'removeConsentForm' }),
    graphql(subcategoryQuery, {
        name: 'query',
        options: () => ({
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withProps(({ query }) => ({
        error: query.error,
        loading: true,
        status: {
            loading: query.networkStatus === 1,
            success: query.networkStatus === 7 && Boolean(query.listLibrarySubcategory),
            error: query.networkStatus === 8,
        },
    })),
    branch(
        ({ status }) => status.success,
        withProps(({ query }) => {
            return {
                subcategories: query.listLibrarySubcategory,
                loading: false,
            };
        })
    ),
    withHandlers(refetchDataHanlders),
    withHandlers(localHandlers),
    graphql(templateListQuery, {
        name: 'templateListQuery',
        options: () => ({
            variables: {},
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withProps(({ templateListQuery }) => ({
        error: templateListQuery.error,
        templateStatus: {
            loading: templateListQuery.networkStatus === 1,
            success: templateListQuery.networkStatus === 7 && Boolean(templateListQuery.listSharableTemplates),
            error: templateListQuery.networkStatus === 8,
        },
    })),
    branch(
        ({ templateStatus }) => templateStatus.success,
        withProps(({ templateListQuery }) => {
            return {
                drawingTemplates: templateListQuery.listSharableTemplates,
                loading: false,
            };
        })
    ),
    graphql(formTemplateListQuery, {
        name: 'formTemplateListQuery',
        options: () => ({
            variables: {},
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withProps(({ formTemplateListQuery }) => ({
        error: formTemplateListQuery.error,
        formTemplateStatus: {
            loading: formTemplateListQuery.networkStatus === 1,
            success: formTemplateListQuery.networkStatus === 7 && Boolean(formTemplateListQuery.listSharableFormTemplates),
            error: formTemplateListQuery.networkStatus === 8,
        },
    })),
    branch(
        ({ formTemplateStatus }) => formTemplateStatus.success,
        withProps(({ formTemplateListQuery }) => ({
            formTemplates: formTemplateListQuery.listSharableFormTemplates,
            loading: false,
        }))
    ),
    graphql(consentFormTemplateListQuery, {
        name: 'consentFormTemplateListQuery',
        options: () => ({
            variables: {},
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withProps(({ consentFormTemplateListQuery }) => ({
        error: consentFormTemplateListQuery.error,
        consentFormStatus: {
            loading: consentFormTemplateListQuery.networkStatus === 1,
            success: consentFormTemplateListQuery.networkStatus === 7 && Boolean(consentFormTemplateListQuery.listSharableConsentFormTemplates.forms),
            error: consentFormTemplateListQuery.networkStatus === 8,
        },
    })),
    branch(
        ({ consentFormStatus }) => consentFormStatus.success,
        withProps(({ consentFormTemplateListQuery }) => ({
            consentFormTemplates: consentFormTemplateListQuery.listSharableConsentFormTemplates.forms,
            loading: false,
        }))
    ),
    withState('barText', 'updateBarText', undefined),
    withHandlers({
        onSearch:
            ({ drawingTemplates, formTemplates, consentFormTemplates, updateTemplates, updateFormTemplates, updateConsentFormTemplates }) =>
            (value) => {
                const drawingTemplatesUpdate = drawingTemplates.filter((template) => template.name.toLowerCase().includes(value.toLowerCase()));
                const formTemplatesUpdate = formTemplates.filter((template) => template.name.toLowerCase().includes(value.toLowerCase()));
                const consentFormTemplatesUpdate = consentFormTemplates.filter((template) =>
                    template.title.toLowerCase().includes(value.toLowerCase())
                );
                updateTemplates(drawingTemplatesUpdate);
                updateFormTemplates(formTemplatesUpdate);
                updateConsentFormTemplates(consentFormTemplatesUpdate);
            },
    }),
    withPropsOnChange(['onSearch'], ({ onSearch }) => ({
        onSearch: debounce(onSearch, 500),
    })),
    lifecycle({
        componentDidMount() {
            const { consentFormTemplates, formTemplates, drawingTemplates, updateTemplates, updateFormTemplates, updateConsentFormTemplates } =
                this.props;
            updateTemplates(drawingTemplates);
            updateFormTemplates(formTemplates);
            updateConsentFormTemplates(consentFormTemplates);
        },
    })
)(TemplateLibrary);

import React, { useState } from 'react';
import queryString from 'query-string';

import TemplateImage from '../../components/TemplateImage/templateImage';
import WebDrawingTool from '.';
// import Loading from '../../components/Loading';

const DrawingCanvasContainerWithFile = (props) => {
    const [templateDataTmp, updateTemplateData] = useState(null);
    const [drawingData, updateDrawingData] = useState(null);
    let queryParams = queryString.parse(props.location.search);
    React.useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data && (typeof event.data === 'string' || event.data instanceof String) && event.data.search('originatingScript') === -1) {
                console.log('message from RN', event.data);
                updateDrawingData(event.data);
            }
        });
    }, []);

    return (
        <div>
            {queryParams && queryParams.fileId && (
                <div style={{ display: 'none' }}>
                    <TemplateImage
                        fileId={queryParams.fileId}
                        onImageLoaded={(imageData) => {
                            updateTemplateData(imageData.encodedFile);
                        }}
                    />
                </div>
            )}
            {(drawingData || templateDataTmp) && (
                <WebDrawingTool
                    notShowAddPage={true}
                    isInConsentForm={true}
                    isStandalone={true}
                    isBackBtnVisible={false} // for iPad app
                    templateData={
                        templateDataTmp
                            ? {
                                  file: {
                                      id: queryParams.fileId,
                                      encodedFile: templateDataTmp,
                                  },
                                  name: 'test',
                              }
                            : null
                    }
                    onCloseDrawingFormNote={() => {}}
                    onSaveDrawingFormNote={() => {}}
                    drawingNoteData={
                        drawingData
                            ? {
                                  drawingData,
                                  id: queryParams.fileId,
                                  title: 'test',
                              }
                            : null
                    }
                    saveAction={(data) => {
                        if (Array.isArray(data)) {
                            if (data.length === 1) {
                                let drawingData = data[0];
                                drawingData = JSON.stringify(drawingData.data);
                                console.log('drawingData', drawingData);
                                updateDrawingData(drawingData);
                                if (window.ReactNativeWebView) {
                                    window.ReactNativeWebView.postMessage(drawingData);
                                }
                            }
                        }
                        return true;
                    }}
                    enableClose={true}
                />
            )}
            {!templateDataTmp && !drawingData && (
                <WebDrawingTool
                    notShowAddPage={true}
                    isInConsentForm={true}
                    isBackBtnVisible={false} // for iPad app
                    templateData={null}
                    onCloseDrawingFormNote={() => {}}
                    onSaveDrawingFormNote={() => {}}
                    drawingNoteData={null}
                    saveAction={(data) => {
                        if (Array.isArray(data)) {
                            if (data.length === 1) {
                                let drawingData = data[0];
                                drawingData = JSON.stringify(drawingData.data);
                                updateDrawingData(drawingData);
                                if (window.ReactNativeWebView) {
                                    window.ReactNativeWebView.postMessage(drawingData);
                                }
                            }
                        }
                        return true;
                    }}
                    enableClose={true}
                />
            )}
        </div>
    );
};

export default DrawingCanvasContainerWithFile;

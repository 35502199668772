import {
  NOTES_CHECKED,
  NOTES_CLEARED,
  NOTES_SIGN_OFF,
  NOTES_VIEW,
} from '../actions/notes';

const notesDefaultState = {
  checkedNotes: [],
  signOff: false,
  view: 'list',
};

const notesReducer = (state = notesDefaultState, action) => {
  switch (action.type) {
    case NOTES_CHECKED:
      return {
        ...state,
        checkedNotes: action.checkedNotes,
      };
    case NOTES_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case NOTES_SIGN_OFF:
      return {
        ...state,
        signOff: action.signOff,
      };
    case NOTES_CLEARED:
      return {
        checkedNotes: [],
        signOff: false,
        view: state.view,
      };
    default:
      return state;
  }
};

export default notesReducer;

import React from 'react';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import md5 from 'js-md5';
import { fabric } from 'fabric-with-gestures';

import { withStyles } from '@material-ui/core/styles';

function decodeData(drawingData) {
    if (drawingData) {
        const json = JSON.parse(drawingData);

        return json;
    }

    return { lines: [], canvasSize: [0, 0] };
}

function lineToPath(line) {
    const locations = line.samples.map((sample) => {
        if (sample.lo !== undefined) {
            // Handle new drawing data
            return sample.lo;
        }
        return sample.location;
    });
    const [first, ...rest] = locations;

    const path = `M${first} L${rest.join(' ')}`;

    return path;
}

function convertColor(color) {
    const rgbDec = color.slice(0, -1);
    const alpha = color.slice(-1);

    const rgb = rgbDec.map((value) => Math.round(value * 255));

    return [...rgb, alpha];
}

function isEraserLine(line) {
    var rgba = convertColor(line.color);
    var lastChar = rgba.join().substr(rgba.join().length - 1);
    return lastChar === '0' || lastChar === 0;
}

const DrawingSVG = ({ classes, className, canvasSize, lines, templateData, drawingData, sectionId }) => {
    // Render web drawing note
    if (drawingData && JSON.parse(drawingData).rawCanvasJson) {
        return <div id='canvasWrapperSVG' className={`${classes.wrapper} ${className} canvasWrapperSVG-${sectionId}`}></div>;
    }

    var allLines = lines;
    // Remove first eraser lines
    for (var j = 0; j < allLines.length; j++) {
        let line = lines[j];
        let isEraser = isEraserLine(line);
        if (isEraser) {
            allLines.shift();
        } else {
            break;
        }
    }
    var findFirstEraser = false;
    var result = []; // [ [x,x,o] [x,o,o,o] ]
    var temp = [];
    var previousErasers = [];
    // After get clean allLines, let's separate it

    for (var i = 0; i < allLines.length; i++) {
        let line = lines[i];
        let isEraser = isEraserLine(line);
        var ok = false;
        if (!isEraser) {
            ok = true;
        } else {
            previousErasers.push(line);
        }

        if (findFirstEraser && !isEraser) {
            result.push(temp);
            temp = [];
            findFirstEraser = false;

            // Add above layer of eraser to below ones
            for (var x = 0; x < result.length - 1; x++) {
                var group = result[x];
                let g = group.concat(previousErasers);
                result[x] = g;
            }
            previousErasers = [];
        }

        if (isEraser) {
            findFirstEraser = true;
        }
        if (findFirstEraser) {
            ok = true;
        }

        if (ok) {
            temp.push(line);
        }

        if (i === allLines.length - 1) {
            result.push(temp);
            temp = [];
            findFirstEraser = false;

            // Add above layer of eraser to below ones
            for (var z = 0; z < result.length - 1; z++) {
                var group1 = result[z];
                let g = group1.concat(previousErasers);
                result[z] = g;
            }
            previousErasers = [];
        }
    }
    var total = result;
    var groupIndex = 0;

    // Render iPad drawing note
    return (
        <div className={`${classes.wrapper} ${className}`}>
            <svg className={`${classes.svg} ${className}`} viewBox={`0 0 ${canvasSize[0]} ${canvasSize[1]}`}>
                {total.map((group) => {
                    groupIndex++;
                    var gEraser = [];
                    var gDrawing = [];
                    group.forEach((line) => {
                        if (isEraserLine(line)) {
                            gEraser.push(line);
                        } else {
                            gDrawing.push(line);
                        }
                    });
                    return (
                        <g>
                            <defs>
                                <mask id={`mask-${groupIndex}`}>
                                    <rect x='0' y='0' width={canvasSize[0]} height={canvasSize[1]} fill='white' opacity='1' />
                                    {gEraser.map((line) => {
                                        const path = lineToPath(line);
                                        return <path d={path} stroke='black' strokeWidth='10' fill='none' />;
                                    })}
                                </mask>
                            </defs>

                            <g id='lines' mask={gEraser ? `url(#mask-${groupIndex})` : null}>
                                {gDrawing.map((line) => {
                                    const path = lineToPath(line);
                                    const key = md5(path);
                                    var rgba = convertColor(line.color);
                                    rgba = rgba.join();
                                    return <path key={key} d={path} stroke={`rgba(${rgba})`} strokeWidth={'2'} fill='none' />;
                                })}
                            </g>
                        </g>
                    );
                })}
            </svg>
            {templateData && <img className={`${classes.image} ${className}`} alt='Template' src={`data:image/png;base64,${templateData}`} />}
        </div>
    );
};

const styles = {
    wrapper: {
        position: 'relative',
        minWidth: 400,
    },
    svg: {
        zIndex: 2,
        position: ({ templateData }) => templateData && 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    image: {
        zIndex: 1,
    },
    'canvas-container': {
        margin: 0,
    },
};

export default compose(
    withStyles(styles),
    withProps(({ drawingData }) => {
        const { lines, canvasSize } = decodeData(drawingData);
        return {
            lines,
            canvasSize,
            drawingData,
        };
    }),
    withHandlers({
        onCanvasSizeChanged:
            ({ drawingData, canvasSize, sectionId }) =>
            () => {
                renderWebDrawing(drawingData, canvasSize, sectionId);
            },
    }),
    lifecycle({
        shouldComponentUpdate(nextProps) {
            return this.props.drawingData !== nextProps.drawingData || this.props.templateData !== nextProps.templateData;
        },
        componentDidUpdate() {
            const { drawingData, canvasSize, sectionId } = this.props;
            renderWebDrawing(drawingData, canvasSize, sectionId);
        },
        componentDidMount() {
            const {
                drawingData,
                canvasSize,
                sectionId,

                onCanvasSizeChanged,
            } = this.props;
            renderWebDrawing(drawingData, canvasSize, sectionId);
            window.addEventListener('resize', onCanvasSizeChanged);
        },
        componentWillUnmount() {
            const { onCanvasSizeChanged } = this.props;
            window.removeEventListener('resize', onCanvasSizeChanged);
        },
    })
)(DrawingSVG);

function renderWebDrawing(drawingData, canvasSize, sectionId) {
    if (drawingData) {
        var jsonTemplateData = JSON.parse(drawingData);

        var canvasWrappers = document.getElementsByClassName(`canvasWrapperSVG-${sectionId}`);

        var canvasWrapper = canvasWrappers && canvasWrappers.length > 0 ? canvasWrappers[canvasWrappers.length - 1] : undefined;
        if (canvasWrapper === undefined) return;

        canvasWrapper.innerHTML = '';
        var canvasElement = document.createElement('canvas');
        canvasWrapper.appendChild(canvasElement);

        if (jsonTemplateData.rawCanvasJson && canvasElement) {
            var canvas = new fabric.Canvas(canvasElement, {
                width: canvasSize[0],
                height: canvasSize[1],
                selection: false,
            });
            fabric.Object.prototype.selectable = false;
            fabric.Object.prototype.hoverCursor = 'default';
            canvas.loadFromJSON(jsonTemplateData.rawCanvasJson, function () {
                var scaleRatio = Math.min(canvasWrapper.offsetWidth / canvasSize[0], canvasWrapper.offsetHeight / canvasSize[1]);
                canvas.setDimensions({
                    width: canvas.getWidth() * scaleRatio,
                    height: canvas.getHeight() * scaleRatio,
                });
                canvas.setZoom(scaleRatio);
                canvasElement.parentNode.style.margin = 'auto 0';
                canvas.calcOffset();
            });
            canvas.renderAll();
        }
    }
}

import { branch, compose, lifecycle, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';

import { practiceRefetchToggle } from '../../data/redux/actions/practice';

const LIST_PATIENTS = gql`
    query patientListQuery($practiceId: ID!, $limit: Int, $offset: Int, $searchText: String, $includeArchived: Boolean) {
        node(id: $practiceId) {
            __typename
            id
            ... on Practice {
                patients(limit: $limit, offset: $offset, searchText: $searchText, includeArchived: $includeArchived) {
                    nodes {
                        name
                        firstName
                        middleName
                        lastName
                        email
                        id
                        phone
                        dob
                        invoiceAccounts {
                            id
                            status
                            billingName
                        }
                        alerts {
                            type
                            text
                        }
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
        }
    }
`;

const PatientListContainer = compose(
    connect(({ practice }) => ({
        practiceId: practice.id,
        searchText: practice.searchText,
        includeArchived: practice.includeArchived,
        refetchPatientList: practice.refetchPatientList,
    })),
    withRouter,
    branch(
        ({ practiceId }) => practiceId,
        compose(
            graphql(LIST_PATIENTS, {
                name: 'query',
                options: ({ practiceId, searchText, includeArchived }) => ({
                    variables: {
                        practiceId,
                        offset: 0,
                        limit: 20,
                        searchText,
                        includeArchived,
                    },
                    fetchPolicy: 'cache-and-network',
                }),
            }),
            withProps(({ query }) => ({
                error: query.error,
                status: {
                    loading: query.networkStatus === 1,
                    setVariables: query.networkStatus === 2,
                    fetching: query.networkStatus === 3,
                    refetching: query.networkStatus === 4,
                    success: query.networkStatus === 7 && Boolean(query.node),
                },
            })),
            branch(
                ({ status }) => status.fetching || status.success,
                withProps(({ query }) => ({
                    patients: query.node.patients.nodes,
                    hasNextPage: query.node.patients.pageInfo.hasNextPage,
                }))
            )
        )
    ),
    withHandlers({
        onListEnd:
            ({ status, query }) =>
            () => {
                if (!status.fetching && !status.refetching) {
                    query.fetchMore({
                        variables: {
                            offset: query.node.patients.nodes.length,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => ({
                            node: {
                                ...previousResult.node,
                                patients: {
                                    ...previousResult.node.patients,
                                    nodes: uniqBy([...previousResult.node.patients.nodes, ...fetchMoreResult.node.patients.nodes], (item) => item.id),
                                    pageInfo: {
                                        ...previousResult.node.patients.pageInfo,
                                        ...fetchMoreResult.node.patients.pageInfo,
                                    },
                                },
                            },
                        }),
                    });
                }
            },
        onRefetch:
            ({ query }) =>
            () =>
                query.refetch(),
    }),
    lifecycle({
        componentDidUpdate() {
            const { dispatch, refetchPatientList, onRefetch } = this.props;

            if (refetchPatientList) {
                onRefetch();
                dispatch(practiceRefetchToggle('refetchPatientList', false));
            }
        },
    })
);

export default PatientListContainer;

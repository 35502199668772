import { gql } from '@apollo/client';

export const FETCH_TAX_RATES = gql`
  query listTaxRates(
    $deleted: Boolean
    $locationId: ID!
    $practiceEncodedId: ID!
  ) {
    listTaxRates(
      deleted: $deleted
      locationId: $locationId
      practiceEncodedId: $practiceEncodedId
    ) {
      id
      desc
      name
      rate
      ratePercent
      location {
        id
        name
      }
      practiceEncodedId
    }
  }
`;

export const UPSERT_TAX_RATE = gql`
  mutation upsertTaxRate(
    $id: ID
    $desc: String
    $locationId: ID!
    $name: String
    $practiceEncodedId: String!
    $rate: Float
  ) {
    upsertTaxRate(
      id: $id
      desc: $desc
      locationId: $locationId
      name: $name
      practiceEncodedId: $practiceEncodedId
      rate: $rate
    ) {
      id
      desc
      name
      rate
    }
  }
`;

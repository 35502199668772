import React from 'react';

import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, ListItemText } from '@material-ui/core/';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ConfirmActionDialog from '../ConfirmActionDialog';

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MenuDropdown = ({ classes, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmAlert, setConfirmAlert] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        className={classes.button}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.dropdown}
      >
        {options.map(option => (
          <div key={`menu-list-item-${option.id}-element`}>
            <StyledMenuItem
              onClick={
                option.requiresConfirmation
                  ? () => {
                      setConfirmAlert(true);
                    }
                  : option.action
              }
            >
              <ListItemText primary={option.text} />
            </StyledMenuItem>

            {option.requiresConfirmation && (
              <ConfirmActionDialog
                open={confirmAlert}
                text={option.requiresConfirmation}
                onClose={() => setConfirmAlert(false)}
                onConfirm={async () => {
                  await option.action();
                  setConfirmAlert(false);
                }}
                confirmText="Delete"
              />
            )}
          </div>
        ))}
      </Menu>
    </div>
  );
};

const styles = {
  button: {
    padding: '5px 0px',
  },
  dropdown: {
    padding: '13px 20px 13px 12px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
};

export default compose(withStyles(styles))(MenuDropdown);

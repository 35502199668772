import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFileMedical, faTrash, faPaperPlane, faQrcode } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import _ from 'lodash';
import Loading from '../../components/Loading';
import DeleteConsentFormDialog from './DeleteDialog';
import { BOULDER, WHITE } from '../../style/constants';
import AppBarMenu from '../../components/AppBarMenu';
import { LOAD_LIST_CONSENT_FORM, LIST_SUBMISSIONS_BY_PRACTICE, MARK_FORM_SUBMISSION_AS_READ } from '../ConsentFormList/gql';
import SubmissionDetailDialog from './SubmissionDetailDialog';
import SendFormDialog from './SendFormDialog/Base';
import SimpleTextSearch from '../../components/SimpleTextSearch';
import SubmissionDialog from './SubmissionDialog';
import QrCodeModal from './QrCodeModal';

const ConsentFormDashboard = ({ classes, practiceId }) => {
    const [listForm, updateListForm] = useState([]);
    const [filteredListForm, updateFilteredListForm] = useState([]);
    const [listSubmissions, updateListSubmissions] = useState([]);
    const [filteredSubmissions, updateFilteredSubmissions] = useState([]);
    const [dialogOpen, updateDialogOpen] = useState(false);
    const [submissionDialogOpen, updateSubmissionDialogOpen] = useState(false);
    const [sendDialogOpen, updateSendDialogOpen] = useState(false);
    const [allowsRegistration, updateAllowsRegistration] = useState(false);
    const [successMessage, updateSuccessMessage] = useState('');
    const [error, updateError] = useState('');
    const [deleteRow, updateDeleteRow] = useState(null);
    const [viewBy, updateViewBy] = useState(sessionStorage.getItem('consentFormDashboardView') || 'form');
    const [selectedSubmission, updateSelectedSubmission] = useState(null);
    const [selectedFormKey, updateSelectedFormKey] = useState(null);
    const [showQrCode, updateShowQrCode] = useState(false);
    const [selectedConsentForm, setSelectedConsentForm] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('consentFormDashboardView', viewBy);
    }, [viewBy]);

    const { loading } = useQuery(LOAD_LIST_CONSENT_FORM, {
        variables: { practice_id: practiceId },
        fetchPolicy: 'cache-and-network',
        onCompleted: (response) => {
            const consentForms = _.orderBy(response.listConsentForm.forms, ['title'], ['asc']);
            updateListForm(consentForms);
            updateFilteredListForm(consentForms);
        },
    });

    const { patientFormLoading } = useQuery(LIST_SUBMISSIONS_BY_PRACTICE, {
        variables: { practiceId },
        fetchPolicy: 'cache-and-network',
        onCompleted: (response) => {
            let submissionsResult = response.listSubmissionsByPractice.submissions;
            submissionsResult = _.orderBy(submissionsResult, ['createdAt'], ['desc']);

            updateListSubmissions(submissionsResult);
            updateFilteredSubmissions(submissionsResult);
        },
    });

    const [markAsRead] = useMutation(MARK_FORM_SUBMISSION_AS_READ, {
        refetchQueries: [{ query: LIST_SUBMISSIONS_BY_PRACTICE, variables: { practiceId } }],
    });

    const handleSendToggle = (key) => {
        updateSelectedFormKey(key);
        updateSendDialogOpen(true);
    };

    const handleSubmissionToggle = (row) => {
        updateSelectedFormKey(row.key);
        updateSubmissionDialogOpen(true);
        updateAllowsRegistration(row.allow_registration);
    };

    const handleViewChange = (newViewBy) => {
        updateViewBy(newViewBy);
    };

    if (loading || patientFormLoading) return <Loading overlay />;

    const tableBodyElement = () => {
        if (viewBy === 'patient') {
            const handleRowSelect = (row) => {
                updateSelectedSubmission(row);
                if (!row.readAt) {
                    markAsRead({
                        variables: {
                            practiceId,
                            submissionId: row.id,
                            readAt: moment().toISOString(),
                        },
                    });
                }
            };

            return (
                <>
                    <TableRow>
                        <TableCell align='left' colSpan={3}>
                            <SimpleTextSearch
                                searchItems={listSubmissions}
                                setSearchItems={updateFilteredSubmissions}
                                searchKeys={['patient_name', 'form.title']}
                            />
                        </TableCell>
                    </TableRow>
                    {filteredSubmissions.map((row) => (
                        <TableRow key={row.id} onClick={() => handleRowSelect(row)} selected={!row.readAt}>
                            <TableCell component='th' scope='row'>
                                {moment(row.createdAt).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.patient_name}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.form.title}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            );
        }
        return (
            <>
                <TableRow>
                    <TableCell align='left' colSpan={12}>
                        <SimpleTextSearch searchItems={listForm} setSearchItems={updateFilteredListForm} searchKeys={['title']} />
                    </TableCell>
                </TableRow>
                {filteredListForm.map((row) => (
                    <TableRow key={row.key}>
                        <TableCell component='th' scope='row'>
                            {row.title}
                        </TableCell>
                        <TableCell align='right'>
                            <Tooltip title='Click here to view these Submissions'>
                                <Button href={`/consent-form-submissions/${row.key}`} target='_blank' color='default' variant='text'>
                                    <Typography variant='caption'>{`${row.submissions_count} Submissions`}</Typography>
                                </Button>
                            </Tooltip>
                        </TableCell>
                        <TableCell align='right'>
                            <Tooltip title='Send this to a Patient'>
                                <IconButton color='inherit' className={classes.iconButton} onClick={() => handleSendToggle(row.key)}>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </IconButton>
                            </Tooltip>
                            {/* <Button onClick={() => handleSendToggle(row.key)}>Send</Button> */}
                            <Tooltip title='Start new submission here'>
                                <IconButton color='inherit' className={classes.iconButton} onClick={() => handleSubmissionToggle(row)}>
                                    <FontAwesomeIcon icon={faFileMedical} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title='Edit this Consent Form'>
                                <IconButton color='inherit' className={classes.iconButton} href={`/consent-form-builder/${row.key}`}>
                                    <FontAwesomeIcon icon={faPen} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title='Delete this Consent Form'>
                                <IconButton
                                    color='inherit'
                                    className={classes.iconButton}
                                    onClick={() => {
                                        updateDeleteRow(row);
                                        updateDialogOpen(true);
                                    }}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title='Open QR Code for this Consent Form'>
                                <IconButton
                                    color='inherit'
                                    className={classes.iconButton}
                                    // href={`/consent-form-qr/${row.key}`}
                                    onClick={() => {
                                        setSelectedConsentForm(row);
                                        updateShowQrCode(true);
                                    }}>
                                    <FontAwesomeIcon icon={faQrcode} />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </>
        );
    };

    return (
        <AppBarMenu>
            <Grid component='main' className={classes.main} container direction='column' wrap='nowrap' item xs={12} sm={12} md={12} lg={10} xl={9}>
                <Container maxWidth='lg' className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Total Form */}
                        <Grid container justifyContent='space-between' alignItems='center' item>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography variant='subtitle1' className={classes.subheading}>
                                    Consent Forms
                                </Typography>
                            </Grid>

                            <Grid item xs={6} md={6} lg={6}>
                                <Grid className={classes.buttonToolbarContainer}>
                                    <Button className={classes.whiteButton} color='default' href='/library' variant='outlined'>
                                        Template Library
                                    </Button>
                                    <Button className={classes.whiteButton} color='default' href='consent-form-builder' variant='outlined'>
                                        Create Form
                                    </Button>
                                    <Grid className={classes.buttonContainer}>
                                        <Button disabled variant='text'>
                                            View by
                                        </Button>
                                        <ToggleButtonGroup value={viewBy}>
                                            <ToggleButton
                                                className={classes.whiteButton}
                                                color='default'
                                                value='patient'
                                                variant='outlined'
                                                onClick={() => handleViewChange('patient')}>
                                                Patient
                                            </ToggleButton>
                                            <ToggleButton
                                                className={classes.whiteButton}
                                                color='default'
                                                value='form'
                                                variant='outlined'
                                                onClick={() => handleViewChange('form')}>
                                                Form
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {loading && <Loading overlay />}
                            {successMessage && (
                                <Paper className={classes.paperAlert}>
                                    <Alert severity='success'>{successMessage}</Alert>
                                </Paper>
                            )}
                            {error && (
                                <Paper className={classes.paperAlert}>
                                    <Alert severity='error'>{error}</Alert>
                                </Paper>
                            )}
                            <Paper className={classes.paper}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow></TableRow>
                                        </TableHead>
                                        <TableBody>{tableBodyElement()}</TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            <DeleteConsentFormDialog
                updateDialogOpen={updateDialogOpen}
                dialogOpen={dialogOpen}
                row={deleteRow}
                practiceId={practiceId}
                updateSuccessMessage={updateSuccessMessage}
                updateListForm={updateFilteredListForm}
                updateError={updateError}
            />

            {selectedSubmission && (
                <SubmissionDetailDialog
                    dialogOpen={!!selectedSubmission}
                    selectedSubmission={selectedSubmission}
                    handleClose={() => updateSelectedSubmission(null)}
                    markAsRead={markAsRead}
                    practiceId={practiceId}
                />
            )}

            {!!selectedFormKey && sendDialogOpen && (
                <SendFormDialog
                    formKey={selectedFormKey}
                    formName={listForm.find((f) => f.key === selectedFormKey).title}
                    dialogOpen={sendDialogOpen}
                    setDialogOpen={updateSendDialogOpen}
                />
            )}

            {!!selectedFormKey && submissionDialogOpen && (
                <SubmissionDialog
                    allowsRegistration={allowsRegistration}
                    formKey={selectedFormKey}
                    formName={listForm.find((f) => f.key === selectedFormKey).title}
                    dialogOpen={submissionDialogOpen}
                    setDialogOpen={updateSubmissionDialogOpen}
                />
            )}

            {selectedConsentForm && selectedConsentForm.key !== undefined && (
                <QrCodeModal
                    open={showQrCode}
                    handleClose={() => {
                        updateShowQrCode(false);
                    }}
                    qrCode={selectedConsentForm}
                />
            )}
        </AppBarMenu>
    );
};

const styles = {
    main: {
        paddingTop: 92,
        paddingLeft: 88,
        paddingRight: 44,
        paddingBottom: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: () => window.innerWidth > 600 && '100%',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    leftButton: {
        position: 'absolute',
        left: 0,
        marginLeft: 12,
    },
    patientList: {
        maxWidth: 350,
        backgroundColor: WHITE,
        overflow: 'auto',
    },
    subheading: {
        paddingBottom: 8,
        fontWeight: 'bold',
    },
    episodesPaper: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    tableWrap: {
        marginBottom: 20,
    },
    totalForm: {
        padding: '20px',
    },
    totalSubmissions: {
        padding: '20px',
    },
    chartPaper: {
        padding: '20px',
    },
    paperAlert: {
        marginBottom: 16,
        textAlign: 'center',
    },
    buttonToolbarContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: 6,
        },
    },
    whiteButton: {
        background: WHITE,
        color: BOULDER,
    },
};

export default compose(
    connect(({ practice, user }) => ({
        practiceId: practice.id,
    })),
    withStyles(styles)
)(ConsentFormDashboard);

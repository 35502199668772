export const LEFT_COLUMN_KEYS = [
    {
        id: 'firstName',
        label: 'First Name',
    },
    {
        id: 'middleName',
        label: 'Middle Name',
    },
    {
        id: 'lastName',
        label: 'Last Name',
    },
    {
        id: 'phone',
        label: 'Landline',
    },
    {
        id: 'mobile',
        label: 'Mobile',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'dob',
        label: 'Date of Birth',
    },
];
export const RIGHT_COLUMN_KEYS = [
    {
        id: 'address',
        label: 'Address',
    },

    {
        id: 'flexibleFieldHeader1',
        dataId: 'flexibleFieldData1',
        isFlexible: true,
        hideForIntegrations: true,
    },
    {
        id: 'flexibleFieldHeader2',
        dataId: 'flexibleFieldData2',
        isFlexible: true,
        hideForIntegrations: true,
    },
    {
        id: 'flexibleFieldHeader3',
        dataId: 'flexibleFieldData3',
        isFlexible: true,
        hideForIntegrations: true,
    },
    {
        id: 'flexibleFieldHeader4',
        dataId: 'flexibleFieldData4',
        isFlexible: true,
        hideForIntegrations: true,
    },
    {
        id: 'flexibleFieldHeader5',
        dataId: 'flexibleFieldData5',
        isFlexible: true,
        hideForIntegrations: true,
    },
];

export const KEYS = [...LEFT_COLUMN_KEYS, ...RIGHT_COLUMN_KEYS];

import React from 'react';
import { compose } from 'recompose';
import Moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { PRIMARY, SECONDARY } from '../../style/constants';

import TextNotePreviewContainer from './textNotePreviewContainer';

import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';
import SignedOffInfo from '../SignedOffInfo';

const TextNotePreview = ({
    classes,
    status,
    error,
    patientId,
    noteId,
    createdAt,
    text,
    title,
    isSignedOff,
    signedOffTime,
    showCheckBox,
    checkedNotesMatch,
    onCheckNote,
    onLinkPress,
    isAuthor,
    isAuth,
    authorName,
}) => (
    <Paper className={classes.paper}>
        {(() => {
            if (status.loading) return <Loading />;
            if (status.error) return <ErrorMessage error={error} />;

            return (
                <div
                    role='link'
                    onClick={onLinkPress}
                    className={classes.link}
                    tabIndex={showCheckBox && (!isAuthor || !isAuth) ? null : 0}
                    onKeyDown={null}>
                    <ListItem
                        className={classes.listItem}
                        button
                        disabled={showCheckBox && (!isAuthor || !isAuth)}
                        TouchRippleProps={{ classes: { root: classes.ripple } }}>
                        <Grid item xs>
                            <Grid item xs container direction='row'>
                                {showCheckBox && isAuthor && isAuth && (
                                    <Checkbox
                                        className={classes.checkbox}
                                        classes={{
                                            root: classes.checked,
                                        }}
                                        checked={checkedNotesMatch}
                                        onChange={() => onCheckNote()}
                                    />
                                )}
                                <Grid item xs>
                                    <div className={classes.titleWrapper}>
                                        <FontAwesomeIcon icon={faFileAlt} color={SECONDARY} />
                                        <Typography variant='subtitle2' className={classes.title}>
                                            {title}
                                        </Typography>
                                    </div>
                                    <Typography variant='caption'>{Moment(createdAt).format('llll')}</Typography>
                                    <Typography variant='caption'>Created by {authorName}</Typography>
                                </Grid>
                                {isSignedOff ? <SignedOffInfo signedOffTime={signedOffTime} /> : null}
                            </Grid>
                            <Divider className={classes.divider} />
                            <Typography variant='body2'>{text}</Typography>
                        </Grid>
                    </ListItem>
                </div>
            );
        })()}
    </Paper>
);

const styles = {
    paper: {
        display: 'flex',
        minHeight: 89,
        marginBottom: 36,
    },
    link: {
        flex: 1,
    },
    listItem: {
        flex: 1,
    },
    checkbox: {
        paddingRight: 12,
    },
    checked: {
        '&$checked': {
            color: PRIMARY,
        },
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: 6,
    },
    divider: {
        marginTop: 12,
        marginBottom: 12,
    },
    ripple: {
        color: PRIMARY,
    },
};

export default compose(TextNotePreviewContainer, withStyles(styles))(TextNotePreview);
